import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_RESERVATION_SOURCES_LIST,
  GET_RESERVATION_SOURCES_LIST_SUCCESS,
  GET_RESERVATION_SOURCES_LIST_FAIL,
  GET_RESERVATION_SOURCES_PAGINATION,
  GET_RESERVATION_SOURCES_PAGINATION_SUCCESS,
  GET_RESERVATION_SOURCES_PAGINATION_FAIL,
  GET_RESERVATION_SOURCES_COLLECTION,
  GET_RESERVATION_SOURCES_COLLECTION_SUCCESS,
  GET_RESERVATION_SOURCES_COLLECTION_FAIL,
  GET_RESERVATION_SOURCES_SINGLE,
  GET_RESERVATION_SOURCES_SINGLE_SUCCESS,
  GET_RESERVATION_SOURCES_SINGLE_FAIL,
  CREATE_RESERVATION_SOURCES,
  CREATE_RESERVATION_SOURCES_SUCCESS,
  CREATE_RESERVATION_SOURCES_FAIL,
  CREATE_RESERVATION_SOURCES_RESET,
  UPDATE_RESERVATION_SOURCES,
  UPDATE_RESERVATION_SOURCES_SUCCESS,
  UPDATE_RESERVATION_SOURCES_FAIL,
  UPDATE_RESERVATION_SOURCES_RESET,
  REPLACE_RESERVATION_SOURCES,
  REPLACE_RESERVATION_SOURCES_SUCCESS,
  REPLACE_RESERVATION_SOURCES_FAIL,
  REPLACE_RESERVATION_SOURCES_RESET,
  REMOVE_RESERVATION_SOURCES,
  REMOVE_RESERVATION_SOURCES_SUCCESS,
  REMOVE_RESERVATION_SOURCES_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_RESERVATION_SOURCES_LIST,
    endPoint: "reservation-sources/list",
    mutationFail: GET_RESERVATION_SOURCES_LIST_FAIL,
    mutationSuccess: GET_RESERVATION_SOURCES_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_RESERVATION_SOURCES_PAGINATION,
    endPoint: "reservation-sources/paginated",
    mutationFail: GET_RESERVATION_SOURCES_PAGINATION_FAIL,
    mutationSuccess: GET_RESERVATION_SOURCES_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_RESERVATION_SOURCES_COLLECTION,
    endPoint: "reservation-sources/collected",
    mutationFail: GET_RESERVATION_SOURCES_COLLECTION_FAIL,
    mutationSuccess: GET_RESERVATION_SOURCES_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_RESERVATION_SOURCES_SINGLE,
    endPoint: "reservation-sources/single",
    mutationFail: GET_RESERVATION_SOURCES_SINGLE_FAIL,
    mutationSuccess: GET_RESERVATION_SOURCES_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_RESERVATION_SOURCES,
    actionReset: CREATE_RESERVATION_SOURCES_RESET,
    endPoint: "reservation-sources/create",
    mutationFail: CREATE_RESERVATION_SOURCES_FAIL,
    mutationSuccess: CREATE_RESERVATION_SOURCES_SUCCESS
  },
  editingRequest: {
    action: UPDATE_RESERVATION_SOURCES,
    actionReset: UPDATE_RESERVATION_SOURCES_RESET,
    endPoint: "reservation-sources/update",
    mutationFail: UPDATE_RESERVATION_SOURCES_FAIL,
    mutationSuccess: UPDATE_RESERVATION_SOURCES_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_RESERVATION_SOURCES,
    actionReset: REPLACE_RESERVATION_SOURCES_RESET,
    endPoint: "reservation-sources/replace",
    mutationFail: REPLACE_RESERVATION_SOURCES_FAIL,
    mutationSuccess: REPLACE_RESERVATION_SOURCES_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_RESERVATION_SOURCES,
    endPoint: "reservation-sources/delete",
    mutationFail: REMOVE_RESERVATION_SOURCES_FAIL,
    mutationSuccess: REMOVE_RESERVATION_SOURCES_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
