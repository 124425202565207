export const GET_AMENITIES_PAGINATION = "[AMENITIES] Pagination Get";
export const GET_AMENITIES_PAGINATION_SUCCESS = "[AMENITIES] Pagination Get Success";
export const GET_AMENITIES_PAGINATION_FAIL = "[AMENITIES] Pagination Get Fail";

export const GET_AMENITIES_COLLECTION = "[AMENITIES] Collection Get";
export const GET_AMENITIES_COLLECTION_SUCCESS = "[AMENITIES] Collection Get Success";
export const GET_AMENITIES_COLLECTION_FAIL = "[AMENITIES] Collection Get Fail";

export const GET_AMENITIES_SINGLE = "[AMENITIES] Single Get";
export const GET_AMENITIES_SINGLE_SUCCESS = "[AMENITIES] Single Get Success";
export const GET_AMENITIES_SINGLE_FAIL = "[AMENITIES] Single Get Fail";

export const CREATE_AMENITIES = "[AMENITIES] Create";
export const CREATE_AMENITIES_SUCCESS = "[AMENITIES] Create Success";
export const CREATE_AMENITIES_FAIL = "[AMENITIES] Create Fail";
export const CREATE_AMENITIES_RESET = "[AMENITIES] Create Reset";

export const UPDATE_AMENITIES = "[AMENITIES] Update";
export const UPDATE_AMENITIES_SUCCESS = "[AMENITIES] Update Success";
export const UPDATE_AMENITIES_FAIL = "[AMENITIES] Update Fail";
export const UPDATE_AMENITIES_RESET = "[AMENITIES] Update Reset";

export const REMOVE_AMENITIES = "[AMENITIES] Remove";
export const REMOVE_AMENITIES_SUCCESS = "[AMENITIES] Remove Success";
export const REMOVE_AMENITIES_FAIL = "[AMENITIES] Remove Fail";
