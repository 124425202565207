import action from "@/store/core/action";

import {
  GET_CARDS_DETAILS,
  GET_CARDS_DETAILS_SUCCESS,
  GET_CARDS_DETAILS_FAIL,
  CREATE_CARDS_API_USER,
  CREATE_CARDS_API_USER_SUCCESS,
  CREATE_CARDS_API_USER_FAIL,
  CREATE_CARDS_API_USER_RESET,
  GET_CARD_SECONDARY_USER,
  GET_CARD_SECONDARY_USER_SUCCESS,
  GET_CARD_SECONDARY_USER_FAIL,
  GET_CARD_UI,
  GET_CARD_UI_SUCCESS,
  GET_CARD_UI_FAIL,
  STORE_CARD,
  STORE_CARD_SUCCESS,
  STORE_CARD_FAIL,
  STORE_CARD_RESET,

  GET_API_USER,
  GET_API_USER_SUCCESS,
  GET_API_USER_FAIL,
  GET_API_USER_RESET,

  UPDATE_API_USER,
  UPDATE_API_USER_SUCCESS,
  UPDATE_API_USER_FAIL,
  UPDATE_API_USER_RESET,
} from "./actions";

const actionsProcessed = action.processMultiple([
  {
    name: 'getCardDetailsRequest',
    action: GET_CARDS_DETAILS,
    endPoint: 'cards/{ccs_token}/card-details/{api_user}',
    mutations: {
      fail: GET_CARDS_DETAILS_FAIL,
      success: GET_CARDS_DETAILS_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'createApiUserRequest',
    action: CREATE_CARDS_API_USER,
    actionReset: CREATE_CARDS_API_USER_RESET,
    endPoint: 'cards/api-user',
    mutations: {
      fail: CREATE_CARDS_API_USER_FAIL,
      success: CREATE_CARDS_API_USER_SUCCESS,
    },
  },
  {
    name: 'getCardSecondaryUser',
    action: GET_CARD_SECONDARY_USER,
    endPoint: 'cards/{ccs_token}/secondary-user',
    mutations: {
      fail: GET_CARD_SECONDARY_USER_FAIL,
      success: GET_CARD_SECONDARY_USER_SUCCESS,
    },
  },
  {
    name: 'getCardUIRequest',
    action: GET_CARD_UI,
    endPoint: 'cards/get-ui',
    mutations: {
      fail: GET_CARD_UI_FAIL,
      success: GET_CARD_UI_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'storeCardRequest',
    action: STORE_CARD,
    actionReset: STORE_CARD_RESET,
    endPoint: 'cards/store',
    mutations: {
      fail: STORE_CARD_FAIL,
      success: STORE_CARD_SUCCESS,
    },
  },
  {
    method: 'query',
    name: 'getApiUserRequest',
    action: GET_API_USER,
    actionReset: GET_API_USER_RESET,
    endPoint: 'cards/api-user/{id}',
    mutations: {
      fail: GET_API_USER_FAIL,
      success: GET_API_USER_SUCCESS,
    },
  },
  {
    method: 'put',
    name: 'updateApiUserRequest',
    action: UPDATE_API_USER,
    actionReset: UPDATE_API_USER_RESET,
    endPoint: 'cards/api-user/{id}',
    mutations: {
      fail: UPDATE_API_USER_FAIL,
      success: UPDATE_API_USER_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  ...actionsProcessed.state
};

const actions = {
  ...actionsProcessed.actions
};

const mutations = {
  ...actionsProcessed.mutations
};

export default {
  state,
  actions,
  mutations,
  getters
};
