import action from "@/store/core/action";

import {
  GET_RMT_DATA,
  GET_RMT_DATA_SUCCESS,
  GET_RMT_DATA_FAIL,
  UPDATE_RMT_DATA,
  UPDATE_RMT_DATA_SUCCESS,
  UPDATE_RMT_DATA_FAIL,
} from "./actions";

const actionsProcessed = action.processMultiple([
  {
    name: 'getDataRequest',
    action: GET_RMT_DATA,
    endPoint: 'rmt/data',
    mutations: {
      fail: GET_RMT_DATA_FAIL,
      success: GET_RMT_DATA_SUCCESS,
    },
  },
  {
    method: 'put',
    name: 'updateRequest',
    action: UPDATE_RMT_DATA,
    endPoint: 'rmt/update/{id}',
    mutations: {
      fail: UPDATE_RMT_DATA_FAIL,
      success: UPDATE_RMT_DATA_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  ...actionsProcessed.state,
};

const actions = {
  ...actionsProcessed.actions,
};

const mutations = {
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
