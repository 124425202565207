export const GET_STAT_ACCOUNTS_PAGINATION = "[STAT_ACCOUNTS] Pagination Get";
export const GET_STAT_ACCOUNTS_PAGINATION_SUCCESS = "[STAT_ACCOUNTS] Pagination Get Success";
export const GET_STAT_ACCOUNTS_PAGINATION_FAIL = "[STAT_ACCOUNTS] Pagination Get Fail";

export const GET_STAT_ACCOUNTS_COLLECTION = "[STAT_ACCOUNTS] Collection Get";
export const GET_STAT_ACCOUNTS_COLLECTION_SUCCESS = "[STAT_ACCOUNTS] Collection Get Success";
export const GET_STAT_ACCOUNTS_COLLECTION_FAIL = "[STAT_ACCOUNTS] Collection Get Fail";

export const GET_STAT_ACCOUNTS_SINGLE = "[STAT_ACCOUNTS] Single Get";
export const GET_STAT_ACCOUNTS_SINGLE_SUCCESS = "[STAT_ACCOUNTS] Single Get Success";
export const GET_STAT_ACCOUNTS_SINGLE_FAIL = "[STAT_ACCOUNTS] Single Get Fail";

export const CREATE_STAT_ACCOUNTS = "[STAT_ACCOUNTS] Create";
export const CREATE_STAT_ACCOUNTS_SUCCESS = "[STAT_ACCOUNTS] Create Success";
export const CREATE_STAT_ACCOUNTS_FAIL = "[STAT_ACCOUNTS] Create Fail";
export const CREATE_STAT_ACCOUNTS_RESET = "[STAT_ACCOUNTS] Create Reset";

export const UPDATE_STAT_ACCOUNTS = "[STAT_ACCOUNTS] Update";
export const UPDATE_STAT_ACCOUNTS_SUCCESS = "[STAT_ACCOUNTS] Update Success";
export const UPDATE_STAT_ACCOUNTS_FAIL = "[STAT_ACCOUNTS] Update Fail";
export const UPDATE_STAT_ACCOUNTS_RESET = "[STAT_ACCOUNTS] Update Reset";

export const REMOVE_STAT_ACCOUNTS = "[STAT_ACCOUNTS] Remove";
export const REMOVE_STAT_ACCOUNTS_SUCCESS = "[STAT_ACCOUNTS] Remove Success";
export const REMOVE_STAT_ACCOUNTS_FAIL = "[STAT_ACCOUNTS] Remove Fail";

export const VALIDATE_STAT_ACCOUNT = "[STAT_ACCOUNTS] Validate";
export const VALIDATE_STAT_ACCOUNT_SUCCESS = "[STAT_ACCOUNTS] Validate Success";
export const VALIDATE_STAT_ACCOUNT_FAIL = "[STAT_ACCOUNTS] Validate Fail";
export const VALIDATE_STAT_ACCOUNT_RESET = "[STAT_ACCOUNTS] Validate Reset";

export const MANUAL_SUBMIT_STAT_ACCOUNT = "[STAT_ACCOUNTS] Manual Submit";
export const MANUAL_SUBMIT_STAT_ACCOUNT_SUCCESS = "[STAT_ACCOUNTS] Manual Submit Success";
export const MANUAL_SUBMIT_STAT_ACCOUNT_FAIL = "[STAT_ACCOUNTS] Manual Submit Fail";

export const FILE_DOWNLOAD_STAT_ACCOUNT = "[STAT_ACCOUNTS] File Download";
