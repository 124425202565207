export const GET_BILLS_PAGINATION = "[BILLS] Pagination Get";
export const GET_BILLS_PAGINATION_SUCCESS = "[BILLS] Pagination Get Success";
export const GET_BILLS_PAGINATION_FAIL = "[BILLS] Pagination Get Fail";

export const GET_BILLS_COLLECTION = "[BILLS] Collection Get";
export const GET_BILLS_COLLECTION_SUCCESS = "[BILLS] Collection Get Success";
export const GET_BILLS_COLLECTION_FAIL = "[BILLS] Collection Get Fail";

export const GET_BILLS_SINGLE = "[BILLS] Single Get";
export const GET_BILLS_SINGLE_SUCCESS = "[BILLS] Single Get Success";
export const GET_BILLS_SINGLE_FAIL = "[BILLS] Single Get Fail";

export const CREATE_BILLS = "[BILLS] Create";
export const CREATE_BILLS_SUCCESS = "[BILLS] Create Success";
export const CREATE_BILLS_FAIL = "[BILLS] Create Fail";
export const CREATE_BILLS_RESET = "[BILLS] Create Reset";

export const UPDATE_BILLS = "[BILLS] Update";
export const UPDATE_BILLS_SUCCESS = "[BILLS] Update Success";
export const UPDATE_BILLS_FAIL = "[BILLS] Update Fail";
export const UPDATE_BILLS_RESET = "[BILLS] Update Reset";

export const REMOVE_BILLS = "[BILLS] Remove";
export const REMOVE_BILLS_SUCCESS = "[BILLS] Remove Success";
export const REMOVE_BILLS_FAIL = "[BILLS] Remove Fail";

export const SEND_BILLS = "[BILLS] Send";
export const SEND_BILLS_SUCCESS = "[BILLS] Send Success";
export const SEND_BILLS_FAIL = "[BILLS] Send Fail";

export const EXPORT_PDF_BILLS = "[BILLS] Export PDF";
