export const GET_PAYMENT_SOURCES_LIST = "[PAYMENT_SOURCES] List Get";
export const GET_PAYMENT_SOURCES_LIST_SUCCESS = "[PAYMENT_SOURCES] List Get Success";
export const GET_PAYMENT_SOURCES_LIST_FAIL = "[PAYMENT_SOURCES] List Get Fail";

export const GET_PAYMENT_SOURCES_PAGINATION = "[PAYMENT_SOURCES] Pagination Get";
export const GET_PAYMENT_SOURCES_PAGINATION_SUCCESS = "[PAYMENT_SOURCES] Pagination Get Success";
export const GET_PAYMENT_SOURCES_PAGINATION_FAIL = "[PAYMENT_SOURCES] Pagination Get Fail";

export const GET_PAYMENT_SOURCES_COLLECTION = "[PAYMENT_SOURCES] Collection Get";
export const GET_PAYMENT_SOURCES_COLLECTION_SUCCESS = "[PAYMENT_SOURCES] Collection Get Success";
export const GET_PAYMENT_SOURCES_COLLECTION_FAIL = "[PAYMENT_SOURCES] Collection Get Fail";

export const GET_PAYMENT_SOURCES_SINGLE = "[PAYMENT_SOURCES] Single Get";
export const GET_PAYMENT_SOURCES_SINGLE_SUCCESS = "[PAYMENT_SOURCES] Single Get Success";
export const GET_PAYMENT_SOURCES_SINGLE_FAIL = "[PAYMENT_SOURCES] Single Get Fail";

export const CREATE_PAYMENT_SOURCES = "[PAYMENT_SOURCES] Create";
export const CREATE_PAYMENT_SOURCES_SUCCESS = "[PAYMENT_SOURCES] Create Success";
export const CREATE_PAYMENT_SOURCES_FAIL = "[PAYMENT_SOURCES] Create Fail";
export const CREATE_PAYMENT_SOURCES_RESET = "[PAYMENT_SOURCES] Create Reset";

export const UPDATE_PAYMENT_SOURCES = "[PAYMENT_SOURCES] Update";
export const UPDATE_PAYMENT_SOURCES_SUCCESS = "[PAYMENT_SOURCES] Update Success";
export const UPDATE_PAYMENT_SOURCES_FAIL = "[PAYMENT_SOURCES] Update Fail";
export const UPDATE_PAYMENT_SOURCES_RESET = "[PAYMENT_SOURCES] Update Reset";

export const REMOVE_PAYMENT_SOURCES = "[PAYMENT_SOURCES] Remove";
export const REMOVE_PAYMENT_SOURCES_SUCCESS = "[PAYMENT_SOURCES] Remove Success";
export const REMOVE_PAYMENT_SOURCES_FAIL = "[PAYMENT_SOURCES] Remove Fail";
