export default [
  {
    name: "police-accounts",
    path: "/legal-compliance/police-accounts",
    component: () => import(/*webpackChunkName: "legal-compliance" */ "@/pages/police-accounts/Index"),
    meta: {
      title: 'titles.police_accounts'
    }
  },
  {
    name: "stat-accounts",
    path: "/legal-compliance/stat-accounts",
    component: () => import(/*webpackChunkName: "legal-compliance" */ "@/pages/stat-accounts/Index"),
    meta: {
      title: 'titles.stat_accounts'
    }
  },
  {
    name: "paytourist-accounts",
    path: "/legal-compliance/paytourist-accounts",
    component: () => import(/*webpackChunkName: "legal-compliance" */ "@/pages/paytourist-accounts/Index"),
    meta: {
      title: 'titles.paytourist_accounts'
    }
  },
  {
    name: "legal-compliance-requests",
    path: "/legal-compliance/list",
    component: () => import(/*webpackChunkName: "legal-compliance" */ "@/pages/legal-compliance-requests/Index"),
    meta: {
      title: 'titles.legal_compliance_requests'
    }
  },
  {
    name: "legal-compliance-city-tax",
    path: "/legal-compliance/city-tax-report",
    component: () => import(/*webpackChunkName: "legal-compliance" */ "@/pages/legal-compliance-requests/CityTaxReport"),
    meta: {
      title: 'titles.city_tax_report'
    }
  },
  // {
  //   name: "chekin-requests",
  //   path: "/legal-compliance/requests",
  //   component: () => import(/*webpackChunkName: "legal-compliance" */ "@/pages/chekin-requests/Index"),
  //   meta: {
  //     title: 'titles.chekin_requests'
  //   }
  // },
  // {
  //   name: "chekin-housings",
  //   path: "/legal-compliance/:id/housings",
  //   component: () => import(/*webpackChunkName: "legal-compliance" */ "@/pages/chekin-housings/Index"),
  //   meta: {
  //     breadcrumbs: [
  //       { text: 'titles.properties', to: 'properties', params: true },
  //       { text: 'titles.property_settings', to: 'property-settings' },
  //       { text: 'titles.property_bureaucracy', to: 'property-bureaucracy' },
  //     ],
  //     title: 'titles.chekin_housings'
  //   }
  // },
  {
    name: "reservation-contracts",
    path: "/legal-compliance/reservation-contracts",
    component: () => import(/*webpackChunkName: "reservations" */ "@/pages/reservation-contracts/Index"),
    meta: {
      title: 'titles.reservation_contracts'
    }
  },
];
