import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_QUOTE_REQUESTS_LIST,
  GET_QUOTE_REQUESTS_LIST_SUCCESS,
  GET_QUOTE_REQUESTS_LIST_FAIL,
  GET_QUOTE_REQUESTS_PAGINATION,
  GET_QUOTE_REQUESTS_PAGINATION_SUCCESS,
  GET_QUOTE_REQUESTS_PAGINATION_FAIL,
  GET_QUOTE_REQUESTS_COLLECTION,
  GET_QUOTE_REQUESTS_COLLECTION_SUCCESS,
  GET_QUOTE_REQUESTS_COLLECTION_FAIL,
  GET_QUOTE_REQUESTS_SINGLE,
  GET_QUOTE_REQUESTS_SINGLE_SUCCESS,
  GET_QUOTE_REQUESTS_SINGLE_FAIL,
  CREATE_QUOTE_REQUESTS,
  CREATE_QUOTE_REQUESTS_SUCCESS,
  CREATE_QUOTE_REQUESTS_FAIL,
  CREATE_QUOTE_REQUESTS_RESET,
  UPDATE_QUOTE_REQUESTS,
  UPDATE_QUOTE_REQUESTS_SUCCESS,
  UPDATE_QUOTE_REQUESTS_FAIL,
  UPDATE_QUOTE_REQUESTS_RESET,
  REPLACE_QUOTE_REQUESTS,
  REPLACE_QUOTE_REQUESTS_SUCCESS,
  REPLACE_QUOTE_REQUESTS_FAIL,
  REPLACE_QUOTE_REQUESTS_RESET,
  REMOVE_QUOTE_REQUESTS,
  REMOVE_QUOTE_REQUESTS_SUCCESS,
  REMOVE_QUOTE_REQUESTS_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_QUOTE_REQUESTS_LIST,
    endPoint: "quote-requests/list",
    mutationFail: GET_QUOTE_REQUESTS_LIST_FAIL,
    mutationSuccess: GET_QUOTE_REQUESTS_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_QUOTE_REQUESTS_PAGINATION,
    endPoint: "quote-requests/paginated",
    mutationFail: GET_QUOTE_REQUESTS_PAGINATION_FAIL,
    mutationSuccess: GET_QUOTE_REQUESTS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_QUOTE_REQUESTS_COLLECTION,
    endPoint: "quote-requests/collected",
    mutationFail: GET_QUOTE_REQUESTS_COLLECTION_FAIL,
    mutationSuccess: GET_QUOTE_REQUESTS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_QUOTE_REQUESTS_SINGLE,
    endPoint: "quote-requests/single",
    mutationFail: GET_QUOTE_REQUESTS_SINGLE_FAIL,
    mutationSuccess: GET_QUOTE_REQUESTS_SINGLE_SUCCESS
  },
  creationRequest: {
    isUpload: true,
    action: CREATE_QUOTE_REQUESTS,
    actionReset: CREATE_QUOTE_REQUESTS_RESET,
    endPoint: "quote-requests/create",
    mutationFail: CREATE_QUOTE_REQUESTS_FAIL,
    mutationSuccess: CREATE_QUOTE_REQUESTS_SUCCESS
  },
  editingRequest: {
    isUpload: true,
    action: UPDATE_QUOTE_REQUESTS,
    actionReset: UPDATE_QUOTE_REQUESTS_RESET,
    endPoint: "quote-requests/update",
    mutationFail: UPDATE_QUOTE_REQUESTS_FAIL,
    mutationSuccess: UPDATE_QUOTE_REQUESTS_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_QUOTE_REQUESTS,
    actionReset: REPLACE_QUOTE_REQUESTS_RESET,
    endPoint: "quote-requests/replace",
    mutationFail: REPLACE_QUOTE_REQUESTS_FAIL,
    mutationSuccess: REPLACE_QUOTE_REQUESTS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_QUOTE_REQUESTS,
    endPoint: "quote-requests/delete",
    mutationFail: REMOVE_QUOTE_REQUESTS_FAIL,
    mutationSuccess: REMOVE_QUOTE_REQUESTS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.state,
};

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
