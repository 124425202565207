import Vue from "vue";

import {
  money,
  toDateString,
  toDateTimeString,
  toDateFormat,
  toDateTimeFormat,
  dateDiffInDays,
} from '@/helpers/functions';

const FiltersService = {
  /**
   *
   */
  init() {
    this.initMoney();
    this.initDates();
  },

  /**
   *
   */
  initMoney() {
    Vue.filter('money', money);
  },

  /**
   *
   */
  initDates() {
    Vue.filter('toDateString', toDateString);
    Vue.filter('toDateTimeString', toDateTimeString);
    Vue.filter('toDateFormat', toDateFormat);
    Vue.filter('toDateTimeFormat', toDateTimeFormat);
    Vue.filter('dateDiffInDays', dateDiffInDays);
  }
};

export default FiltersService;
