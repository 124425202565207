import list from "@/store/core/list";

import {
  GET_VAT_TYPES_LIST,
  GET_VAT_TYPES_LIST_SUCCESS,
  GET_VAT_TYPES_LIST_FAIL
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_VAT_TYPES_LIST,
    endPoint: "vat-types/list",
    mutationFail: GET_VAT_TYPES_LIST_FAIL,
    mutationSuccess: GET_VAT_TYPES_LIST_SUCCESS
  }
});

const getters = {};

const state = {
  ...listProcessed.state
};

const actions = {
  ...listProcessed.actions
};

const mutations = {
  ...listProcessed.mutations
};

export default {
  state,
  actions,
  mutations,
  getters
};
