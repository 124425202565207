import ApiService from "@/services/api";

import list from "@/store/core/list";
import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_ROOM_TYPES_LIST,
  GET_ROOM_TYPES_LIST_SUCCESS,
  GET_ROOM_TYPES_LIST_FAIL,
  GET_ROOM_TYPES_PAGINATION,
  GET_ROOM_TYPES_PAGINATION_SUCCESS,
  GET_ROOM_TYPES_PAGINATION_FAIL,
  GET_ROOM_TYPES_COLLECTION,
  GET_ROOM_TYPES_COLLECTION_SUCCESS,
  GET_ROOM_TYPES_COLLECTION_FAIL,
  GET_ROOM_TYPES_SINGLE,
  GET_ROOM_TYPES_SINGLE_SUCCESS,
  GET_ROOM_TYPES_SINGLE_FAIL,
  CREATE_ROOM_TYPES,
  CREATE_ROOM_TYPES_SUCCESS,
  CREATE_ROOM_TYPES_FAIL,
  CREATE_ROOM_TYPES_RESET,
  UPDATE_ROOM_TYPES,
  UPDATE_ROOM_TYPES_SUCCESS,
  UPDATE_ROOM_TYPES_FAIL,
  UPDATE_ROOM_TYPES_RESET,
  REMOVE_ROOM_TYPES,
  REMOVE_ROOM_TYPES_SUCCESS,
  REMOVE_ROOM_TYPES_FAIL,
  GET_ROOM_TYPES_COLLECTION_2,
  GET_ROOM_TYPES_COLLECTION_2_SUCCESS,
  GET_ROOM_TYPES_COLLECTION_2_FAIL,
  GET_IMPORT_OCTORATE_RATES,
  GET_IMPORT_OCTORATE_RATES_SUCCESS,
  GET_IMPORT_OCTORATE_RATES_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_ROOM_TYPES_LIST,
    endPoint: "room-types/list",
    mutationFail: GET_ROOM_TYPES_LIST_FAIL,
    mutationSuccess: GET_ROOM_TYPES_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_ROOM_TYPES_PAGINATION,
    endPoint: "room-types/paginated",
    mutationFail: GET_ROOM_TYPES_PAGINATION_FAIL,
    mutationSuccess: GET_ROOM_TYPES_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_ROOM_TYPES_COLLECTION,
    endPoint: "room-types/collected",
    mutationFail: GET_ROOM_TYPES_COLLECTION_FAIL,
    mutationSuccess: GET_ROOM_TYPES_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_ROOM_TYPES_SINGLE,
    endPoint: "room-types/single",
    mutationFail: GET_ROOM_TYPES_SINGLE_FAIL,
    mutationSuccess: GET_ROOM_TYPES_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_ROOM_TYPES,
    actionReset: CREATE_ROOM_TYPES_RESET,
    endPoint: "room-types/create",
    mutationFail: CREATE_ROOM_TYPES_FAIL,
    mutationSuccess: CREATE_ROOM_TYPES_SUCCESS
  },
  editingRequest: {
    action: UPDATE_ROOM_TYPES,
    actionReset: UPDATE_ROOM_TYPES_RESET,
    endPoint: "room-types/update",
    mutationFail: UPDATE_ROOM_TYPES_FAIL,
    mutationSuccess: UPDATE_ROOM_TYPES_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_ROOM_TYPES,
    endPoint: "room-types/delete",
    mutationFail: REMOVE_ROOM_TYPES_FAIL,
    mutationSuccess: REMOVE_ROOM_TYPES_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    name: 'getImportOctorateRequest',
    action: GET_IMPORT_OCTORATE_RATES,
    endPoint: 'room-types/import/octorate/{propertyId}',
    mutations: {
      fail: GET_IMPORT_OCTORATE_RATES_FAIL,
      success: GET_IMPORT_OCTORATE_RATES_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  creationBulkRequest: {
    loading: false,
    success: false,
    fail: false,
    validation: {},
    response: {}
  },
  creationWizardRequest: {
    loading: false,
    success: false,
    fail: false,
    validation: {},
    response: {}
  },
  getCollection2Request: {
    loading: false,
    success: false,
    fail: false,
    collection: [],
    errors: {}
  },
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  [GET_ROOM_TYPES_COLLECTION_2](context, params) {
    context.state.getCollection2Request.loading = true;

    ApiService.query('room-types/collected', params)
      .then(response => {
        context.commit(
          GET_ROOM_TYPES_COLLECTION_2_SUCCESS,
          response.data.data
        );
      })
      .catch(response => {
        context.commit(GET_ROOM_TYPES_COLLECTION_2_FAIL, response);
      });
  },
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  [GET_ROOM_TYPES_COLLECTION_2_SUCCESS](state, data) {
    state.getCollection2Request = {
      loading: false,
      success: true,
      fail: false,
      collection: data.collection,
      errors: {}
    };
  },
  [GET_ROOM_TYPES_COLLECTION_2_FAIL](state, data) {
    state.getCollection2Request = {
      loading: false,
      success: false,
      fail: true,
      collection: [],
      errors: data.errors
    };
  },
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
