import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_RESERVATION_TASKS_LIST,
  GET_RESERVATION_TASKS_LIST_SUCCESS,
  GET_RESERVATION_TASKS_LIST_FAIL,
  GET_RESERVATION_TASKS_PAGINATION,
  GET_RESERVATION_TASKS_PAGINATION_SUCCESS,
  GET_RESERVATION_TASKS_PAGINATION_FAIL,
  GET_RESERVATION_TASKS_COLLECTION,
  GET_RESERVATION_TASKS_COLLECTION_SUCCESS,
  GET_RESERVATION_TASKS_COLLECTION_FAIL,
  GET_RESERVATION_TASKS_SINGLE,
  GET_RESERVATION_TASKS_SINGLE_SUCCESS,
  GET_RESERVATION_TASKS_SINGLE_FAIL,
  CREATE_RESERVATION_TASKS,
  CREATE_RESERVATION_TASKS_SUCCESS,
  CREATE_RESERVATION_TASKS_FAIL,
  CREATE_RESERVATION_TASKS_RESET,
  UPDATE_RESERVATION_TASKS,
  UPDATE_RESERVATION_TASKS_SUCCESS,
  UPDATE_RESERVATION_TASKS_FAIL,
  REPLACE_RESERVATION_TASKS,
  REPLACE_RESERVATION_TASKS_SUCCESS,
  REPLACE_RESERVATION_TASKS_FAIL,
  REPLACE_RESERVATION_TASKS_RESET,
  UPDATE_RESERVATION_TASKS_RESET,
  REMOVE_RESERVATION_TASKS,
  REMOVE_RESERVATION_TASKS_SUCCESS,
  REMOVE_RESERVATION_TASKS_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_RESERVATION_TASKS_LIST,
    endPoint: "reservation-tasks/list",
    mutationFail: GET_RESERVATION_TASKS_LIST_FAIL,
    mutationSuccess: GET_RESERVATION_TASKS_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_RESERVATION_TASKS_PAGINATION,
    endPoint: "reservation-tasks/paginated",
    mutationFail: GET_RESERVATION_TASKS_PAGINATION_FAIL,
    mutationSuccess: GET_RESERVATION_TASKS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_RESERVATION_TASKS_COLLECTION,
    endPoint: "reservation-tasks/collected",
    mutationFail: GET_RESERVATION_TASKS_COLLECTION_FAIL,
    mutationSuccess: GET_RESERVATION_TASKS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_RESERVATION_TASKS_SINGLE,
    endPoint: "reservation-tasks/single",
    mutationFail: GET_RESERVATION_TASKS_SINGLE_FAIL,
    mutationSuccess: GET_RESERVATION_TASKS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_RESERVATION_TASKS,
    actionReset: CREATE_RESERVATION_TASKS_RESET,
    endPoint: "reservation-tasks/create",
    mutationFail: CREATE_RESERVATION_TASKS_FAIL,
    mutationSuccess: CREATE_RESERVATION_TASKS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_RESERVATION_TASKS,
    actionReset: UPDATE_RESERVATION_TASKS_RESET,
    endPoint: "reservation-tasks/update",
    mutationFail: UPDATE_RESERVATION_TASKS_FAIL,
    mutationSuccess: UPDATE_RESERVATION_TASKS_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_RESERVATION_TASKS,
    actionReset: REPLACE_RESERVATION_TASKS_RESET,
    endPoint: "reservation-tasks/replace",
    mutationFail: REPLACE_RESERVATION_TASKS_FAIL,
    mutationSuccess: REPLACE_RESERVATION_TASKS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_RESERVATION_TASKS,
    endPoint: "reservation-tasks/delete",
    mutationFail: REMOVE_RESERVATION_TASKS_FAIL,
    mutationSuccess: REMOVE_RESERVATION_TASKS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([]);

const state = {
  getHousekeepingPaginationRequest: {
    loading: false,
    success: false,
    fail: false,
    collection: [],
    pagination: {
      page: 1
    },
    errors: {}
  },
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const getters = {};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
