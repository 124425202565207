export default [
  {
    name: "units",
    path: "/properties/:id/units",
    component: () => import(/*webpackChunkName: "properties" */ "@/pages/units/Index"),
    meta: {
      breadcrumbs: [
        { text: 'titles.properties', to: 'properties', params: true },
        { text: 'titles.property_settings', to: 'property-settings' },
        { text: 'titles.property_units_rates', to: 'property-units-rates' },
      ],
      title: 'titles.units'
    }
  }
];
