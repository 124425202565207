import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_COMPANY_PAYMENTS_PAGINATION,
  GET_COMPANY_PAYMENTS_PAGINATION_SUCCESS,
  GET_COMPANY_PAYMENTS_PAGINATION_FAIL,
  REMOVE_COMPANY_PAYMENTS,
  REMOVE_COMPANY_PAYMENTS_SUCCESS,
  REMOVE_COMPANY_PAYMENTS_FAIL,
  CHARGE_COMPANY_PAYMENTS,
  CHARGE_COMPANY_PAYMENTS_SUCCESS,
  CHARGE_COMPANY_PAYMENTS_FAIL,
  GET_COMPANY_PAYMENTS_SINGLE,
  GET_COMPANY_PAYMENTS_SINGLE_SUCCESS,
  GET_COMPANY_PAYMENTS_SINGLE_FAIL,
  CREATE_COMPANY_PAYMENTS,
  CREATE_COMPANY_PAYMENTS_SUCCESS,
  CREATE_COMPANY_PAYMENTS_FAIL,
  CREATE_COMPANY_PAYMENTS_RESET,
  UPDATE_COMPANY_PAYMENTS,
  UPDATE_COMPANY_PAYMENTS_SUCCESS,
  UPDATE_COMPANY_PAYMENTS_FAIL,
  UPDATE_COMPANY_PAYMENTS_RESET
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_COMPANY_PAYMENTS_PAGINATION,
    endPoint: "company-payments/paginated",
    mutationFail: GET_COMPANY_PAYMENTS_PAGINATION_FAIL,
    mutationSuccess: GET_COMPANY_PAYMENTS_PAGINATION_SUCCESS
  },
  getSingleRequest: {
    action: GET_COMPANY_PAYMENTS_SINGLE,
    endPoint: "company-payments/single",
    mutationFail: GET_COMPANY_PAYMENTS_SINGLE_FAIL,
    mutationSuccess: GET_COMPANY_PAYMENTS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_COMPANY_PAYMENTS,
    actionReset: CREATE_COMPANY_PAYMENTS_RESET,
    endPoint: "company-payments/create",
    mutationFail: CREATE_COMPANY_PAYMENTS_FAIL,
    mutationSuccess: CREATE_COMPANY_PAYMENTS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_COMPANY_PAYMENTS,
    actionReset: UPDATE_COMPANY_PAYMENTS_RESET,
    endPoint: "company-payments/update",
    mutationFail: UPDATE_COMPANY_PAYMENTS_FAIL,
    mutationSuccess: UPDATE_COMPANY_PAYMENTS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_COMPANY_PAYMENTS,
    endPoint: "company-payments/delete",
    mutationFail: REMOVE_COMPANY_PAYMENTS_FAIL,
    mutationSuccess: REMOVE_COMPANY_PAYMENTS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    method: 'post',
    name: 'chargeRequest',
    action: CHARGE_COMPANY_PAYMENTS,
    endPoint: 'company-payments/charge/{id}',
    mutations: {
      fail: CHARGE_COMPANY_PAYMENTS_FAIL,
      success: CHARGE_COMPANY_PAYMENTS_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
