import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_POINT_OF_SALES_LIST,
  GET_POINT_OF_SALES_LIST_SUCCESS,
  GET_POINT_OF_SALES_LIST_FAIL,
  GET_POINT_OF_SALES_PAGINATION,
  GET_POINT_OF_SALES_PAGINATION_SUCCESS,
  GET_POINT_OF_SALES_PAGINATION_FAIL,
  GET_POINT_OF_SALES_COLLECTION,
  GET_POINT_OF_SALES_COLLECTION_SUCCESS,
  GET_POINT_OF_SALES_COLLECTION_FAIL,
  GET_POINT_OF_SALES_SINGLE,
  GET_POINT_OF_SALES_SINGLE_SUCCESS,
  GET_POINT_OF_SALES_SINGLE_FAIL,
  CREATE_POINT_OF_SALES,
  CREATE_POINT_OF_SALES_SUCCESS,
  CREATE_POINT_OF_SALES_FAIL,
  CREATE_POINT_OF_SALES_RESET,
  UPDATE_POINT_OF_SALES,
  UPDATE_POINT_OF_SALES_SUCCESS,
  UPDATE_POINT_OF_SALES_FAIL,
  UPDATE_POINT_OF_SALES_RESET,
  REPLACE_POINT_OF_SALES,
  REPLACE_POINT_OF_SALES_SUCCESS,
  REPLACE_POINT_OF_SALES_FAIL,
  REPLACE_POINT_OF_SALES_RESET,
  REMOVE_POINT_OF_SALES,
  REMOVE_POINT_OF_SALES_SUCCESS,
  REMOVE_POINT_OF_SALES_FAIL,
  CONFIRM_POINT_OF_SALES,
  CONFIRM_POINT_OF_SALES_SUCCESS,
  CONFIRM_POINT_OF_SALES_FAIL,
  CONFIRM_POINT_OF_SALES_RESET,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_POINT_OF_SALES_LIST,
    endPoint: "point-of-sales/list",
    mutationFail: GET_POINT_OF_SALES_LIST_FAIL,
    mutationSuccess: GET_POINT_OF_SALES_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_POINT_OF_SALES_PAGINATION,
    endPoint: "point-of-sales/paginated",
    mutationFail: GET_POINT_OF_SALES_PAGINATION_FAIL,
    mutationSuccess: GET_POINT_OF_SALES_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_POINT_OF_SALES_COLLECTION,
    endPoint: "point-of-sales/collected",
    mutationFail: GET_POINT_OF_SALES_COLLECTION_FAIL,
    mutationSuccess: GET_POINT_OF_SALES_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_POINT_OF_SALES_SINGLE,
    endPoint: "point-of-sales/single",
    mutationFail: GET_POINT_OF_SALES_SINGLE_FAIL,
    mutationSuccess: GET_POINT_OF_SALES_SINGLE_SUCCESS
  },
  creationRequest: {
    isUpload: true,
    action: CREATE_POINT_OF_SALES,
    actionReset: CREATE_POINT_OF_SALES_RESET,
    endPoint: "point-of-sales/create",
    mutationFail: CREATE_POINT_OF_SALES_FAIL,
    mutationSuccess: CREATE_POINT_OF_SALES_SUCCESS
  },
  editingRequest: {
    isUpload: true,
    action: UPDATE_POINT_OF_SALES,
    actionReset: UPDATE_POINT_OF_SALES_RESET,
    endPoint: "point-of-sales/update",
    mutationFail: UPDATE_POINT_OF_SALES_FAIL,
    mutationSuccess: UPDATE_POINT_OF_SALES_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_POINT_OF_SALES,
    actionReset: REPLACE_POINT_OF_SALES_RESET,
    endPoint: "point-of-sales/replace",
    mutationFail: REPLACE_POINT_OF_SALES_FAIL,
    mutationSuccess: REPLACE_POINT_OF_SALES_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_POINT_OF_SALES,
    endPoint: "point-of-sales/delete",
    mutationFail: REMOVE_POINT_OF_SALES_FAIL,
    mutationSuccess: REMOVE_POINT_OF_SALES_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    method: 'post',
    name: 'confirmRequest',
    action: CONFIRM_POINT_OF_SALES,
    actionReset: CONFIRM_POINT_OF_SALES_RESET,
    endPoint: 'point-of-sales/confirm',
    mutations: {
      fail: CONFIRM_POINT_OF_SALES_FAIL,
      success: CONFIRM_POINT_OF_SALES_SUCCESS,
    },
  },
]);

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
