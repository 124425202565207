export const GET_ANNOUNCEMENTS_PAGINATION = "[ANNOUNCEMENTS] Pagination Get";
export const GET_ANNOUNCEMENTS_PAGINATION_SUCCESS = "[ANNOUNCEMENTS] Pagination Get Success";
export const GET_ANNOUNCEMENTS_PAGINATION_FAIL = "[ANNOUNCEMENTS] Pagination Get Fail";

export const GET_ANNOUNCEMENTS_COLLECTION = "[ANNOUNCEMENTS] Collection Get";
export const GET_ANNOUNCEMENTS_COLLECTION_SUCCESS = "[ANNOUNCEMENTS] Collection Get Success";
export const GET_ANNOUNCEMENTS_COLLECTION_FAIL = "[ANNOUNCEMENTS] Collection Get Fail";

export const GET_ANNOUNCEMENTS_SINGLE = "[ANNOUNCEMENTS] Single Get";
export const GET_ANNOUNCEMENTS_SINGLE_SUCCESS = "[ANNOUNCEMENTS] Single Get Success";
export const GET_ANNOUNCEMENTS_SINGLE_FAIL = "[ANNOUNCEMENTS] Single Get Fail";

export const CREATE_ANNOUNCEMENTS = "[ANNOUNCEMENTS] Create";
export const CREATE_ANNOUNCEMENTS_SUCCESS = "[ANNOUNCEMENTS] Create Success";
export const CREATE_ANNOUNCEMENTS_FAIL = "[ANNOUNCEMENTS] Create Fail";
export const CREATE_ANNOUNCEMENTS_RESET = "[ANNOUNCEMENTS] Create Reset";

export const UPDATE_ANNOUNCEMENTS = "[ANNOUNCEMENTS] Update";
export const UPDATE_ANNOUNCEMENTS_SUCCESS = "[ANNOUNCEMENTS] Update Success";
export const UPDATE_ANNOUNCEMENTS_FAIL = "[ANNOUNCEMENTS] Update Fail";
export const UPDATE_ANNOUNCEMENTS_RESET = "[ANNOUNCEMENTS] Update Reset";

export const REMOVE_ANNOUNCEMENTS = "[ANNOUNCEMENTS] Remove";
export const REMOVE_ANNOUNCEMENTS_SUCCESS = "[ANNOUNCEMENTS] Remove Success";
export const REMOVE_ANNOUNCEMENTS_FAIL = "[ANNOUNCEMENTS] Remove Fail";
