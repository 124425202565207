export const GET_CALENDAR_EVENTS_LIST = "[CALENDAR_EVENTS] List Get";
export const GET_CALENDAR_EVENTS_LIST_SUCCESS = "[CALENDAR_EVENTS] List Get Success";
export const GET_CALENDAR_EVENTS_LIST_FAIL = "[CALENDAR_EVENTS] List Get Fail";

export const GET_CALENDAR_EVENTS_PAGINATION = "[CALENDAR_EVENTS] Pagination Get";
export const GET_CALENDAR_EVENTS_PAGINATION_SUCCESS = "[CALENDAR_EVENTS] Pagination Get Success";
export const GET_CALENDAR_EVENTS_PAGINATION_FAIL = "[CALENDAR_EVENTS] Pagination Get Fail";

export const GET_CALENDAR_EVENTS_COLLECTION = "[CALENDAR_EVENTS] Collection Get";
export const GET_CALENDAR_EVENTS_COLLECTION_SUCCESS = "[CALENDAR_EVENTS] Collection Get Success";
export const GET_CALENDAR_EVENTS_COLLECTION_FAIL = "[CALENDAR_EVENTS] Collection Get Fail";

export const GET_CALENDAR_EVENTS_SINGLE = "[CALENDAR_EVENTS] Single Get";
export const GET_CALENDAR_EVENTS_SINGLE_SUCCESS = "[CALENDAR_EVENTS] Single Get Success";
export const GET_CALENDAR_EVENTS_SINGLE_FAIL = "[CALENDAR_EVENTS] Single Get Fail";

export const CREATE_CALENDAR_EVENTS = "[CALENDAR_EVENTS] Create";
export const CREATE_CALENDAR_EVENTS_SUCCESS = "[CALENDAR_EVENTS] Create Success";
export const CREATE_CALENDAR_EVENTS_FAIL = "[CALENDAR_EVENTS] Create Fail";
export const CREATE_CALENDAR_EVENTS_RESET = "[CALENDAR_EVENTS] Create Reset";

export const UPDATE_CALENDAR_EVENTS = "[CALENDAR_EVENTS] Update";
export const UPDATE_CALENDAR_EVENTS_SUCCESS = "[CALENDAR_EVENTS] Update Success";
export const UPDATE_CALENDAR_EVENTS_FAIL = "[CALENDAR_EVENTS] Update Fail";
export const UPDATE_CALENDAR_EVENTS_RESET = "[CALENDAR_EVENTS] Update Reset";

export const REPLACE_CALENDAR_EVENTS = "[CALENDAR_EVENTS] Replace";
export const REPLACE_CALENDAR_EVENTS_SUCCESS = "[CALENDAR_EVENTS] Replace Success";
export const REPLACE_CALENDAR_EVENTS_FAIL = "[CALENDAR_EVENTS] Replace Fail";
export const REPLACE_CALENDAR_EVENTS_RESET = "[CALENDAR_EVENTS] Replace Reset";

export const REMOVE_CALENDAR_EVENTS = "[CALENDAR_EVENTS] Remove";
export const REMOVE_CALENDAR_EVENTS_SUCCESS = "[CALENDAR_EVENTS] Remove Success";
export const REMOVE_CALENDAR_EVENTS_FAIL = "[CALENDAR_EVENTS] Remove Fail";