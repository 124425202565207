export const GET_RATE_HISTORY_PAGINATION = "[RATE_HISTORY] Pagination Get";
export const GET_RATE_HISTORY_PAGINATION_SUCCESS = "[RATE_HISTORY] Pagination Get Success";
export const GET_RATE_HISTORY_PAGINATION_FAIL = "[RATE_HISTORY] Pagination Get Fail";

export const GET_RATE_HISTORY_COLLECTION = "[RATE_HISTORY] Collection Get";
export const GET_RATE_HISTORY_COLLECTION_SUCCESS = "[RATE_HISTORY] Collection Get Success";
export const GET_RATE_HISTORY_COLLECTION_FAIL = "[RATE_HISTORY] Collection Get Fail";

export const GET_RATE_HISTORY_SINGLE = "[RATE_HISTORY] Single Get";
export const GET_RATE_HISTORY_SINGLE_SUCCESS = "[RATE_HISTORY] Single Get Success";
export const GET_RATE_HISTORY_SINGLE_FAIL = "[RATE_HISTORY] Single Get Fail";

export const REMOVE_RATE_HISTORY = "[RATE_HISTORY] Remove";
export const REMOVE_RATE_HISTORY_SUCCESS = "[RATE_HISTORY] Remove Success";
export const REMOVE_RATE_HISTORY_FAIL = "[RATE_HISTORY] Remove Fail";