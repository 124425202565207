export default [
  {
    name: "booking-networks",
    path: "/booking-engine-networks",
    component: () => import(/*webpackChunkName: "booking-networks" */ "@/pages/booking-networks/Index"),
    meta: {
      title: 'titles.booking_networks'
    }
  },
  {
    name: "booking-network-pages",
    path: "/booking-engine-networks/:id/pages",
    component: () => import(/*webpackChunkName: "booking-networks" */ "@/pages/booking-network-pages/Index"),
    props: true,
    meta: {
      breadcrumbs: [
        { text: 'titles.booking_networks', to: 'booking-networks', params: true }
      ],
      title: 'titles.booking_network_pages'
    }
  },
  {
    name: "booking-network-blocks",
    path: "/booking-engine-networks/:id/blocks",
    component: () => import(/*webpackChunkName: "booking-networks" */ "@/pages/booking-network-blocks/Index"),
    props: true,
    meta: {
      breadcrumbs: [
        { text: 'titles.booking_networks', to: 'booking-networks', params: true }
      ],
      title: 'titles.booking_network_blocks'
    }
  },
  {
    name: "booking-network-destinations",
    path: "/booking-engine-networks/:id/destinations",
    component: () => import(/*webpackChunkName: "booking-destinations" */ "@/pages/booking-network-destinations/Index"),
    props: true,
    meta: {
      breadcrumbs: [
        { text: 'titles.booking_networks', to: 'booking-networks', params: true }
      ],
      title: 'titles.booking_network_destinations'
    }
  },
];
