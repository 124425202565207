<template>
  <div class="py-4">
    <v-alert
      prominent
      type="warning"
      loading
      icon="mdi-fingerprint"
    >
      <v-row align="center">
        <v-col class="grow">
          <p :class="$isMobile() ? 'text-h5' : 'text-h4'">{{ $lang('auth.mfa.mfa_feature') }}</p>
          <!-- <v-skeleton-loader
            v-if="!$isMobile()"
            class="mx-auto"
            type="card"
          ></v-skeleton-loader> -->
          <p class="subtitle-1">
            {{ $lang('auth.mfa.mfa_feature_subtitle') }}
          </p>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="shrink">
          <v-btn @click="openUserEditing">{{ $lang('auth.mfa.activate') }}</v-btn>
        </v-col>
      </v-row>
    </v-alert>
    
    <v-dialog v-model="isOpenUserEditing" width="40rem">
      <user-editing
        v-if="isOpenUserEditing"
        :item-id="userData.id"
        @success="userUpdated"
        @cancel="closeUserEditing"
      />
    </v-dialog>
  </div>
</template>

<script>
import UserEditing from "@/pages/users/SimpleEditingForm";
export default {
  name: "CMfaRequired",
  components: {
    UserEditing,
  },
  data() {
    return {
      isOpenUserEditing: false,
    };
  },
  methods: {
    openUserEditing() {
      this.isOpenUserEditing = true;
    },
    userUpdated() {
      this.isOpenUserEditing = false;
    },
    closeUserEditing() {
      this.isOpenUserEditing = false;
    },
  },
};
</script>
