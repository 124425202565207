import formable from "@/mixins/formable";
import providerColors from "./provider-colors";

export default {
  mixins: [
    formable,
    providerColors,
  ],
  data() {
    return {
      form: {
        ok_home_session: {},
        switch_bot_credentials: {},
      },
    }
  },
};
