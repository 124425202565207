import Vue from "vue";
import Vuetify from "vuetify/lib";
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import DatetimePicker from 'vuetify-datetime-picker';
import '@mdi/font/css/materialdesignicons.css';
import googleConfig from '@/config/google';

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#424242',
        secondary: '#a9ddd6',
        accent: '#8c9eff',
        error: '#b71c1c',
        success: '#0ba869',
        admin: '#B3E5FC',
        channel: '#FDD835',
      },
    },
    icons: {
      iconfont: 'mdi',
    },
  }
};

const vuetify = new Vuetify(opts);

Vue.use(Vuetify);
Vue.use(DatetimePicker);
Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: googleConfig.api_key, // Can also be an object. E.g, for Google Maps Premium API, pass `{ client: <YOUR-CLIENT-ID> }`
  // version: '...', // Optional
  // language: '...', // Optional
  installComponents: true, // Optional (default: true) - false, if you want to locally install components
  vueGoogleMapsCompatibility: false, // Optional (default: false) - true, requires vue2-google-maps to be configured see https://github.com/xkjyeah/vue-google-maps
});

export default vuetify;
