<template>
  <v-select
    v-model="itemValue"
    :loading="loading"
    :label="label"
    :hint="hint"
    :hide-details="hideDetails"
    :error="!!errors.length"
    :error-messages="errors"
    :true-value="1"
    :false-value="0"
    :prepend-icon="prependIcon"
    :disabled="disabled"
    :readonly="readonly"
    :persistent-hint="persistentHint"
    :dense="dense"
    :items="items"
  />
</template>

<script>
  export default {
    name: "VListElements",
    props: {
      value: {
        default: null,
      },
      label: {
        type: String,
        default: null,
      },
      hint: {
        type: String,
        default: null,
      },
      hideDetails: {
        type: Boolean,
        default: false,
      },
      errors: {
        type: Array,
        required: false,
        default: () => {
          return [];
        }
      },
      loading: {
        type: Boolean,
        default: false,
      },
      prependIcon: {
        type: String,
        default: null,
      },
      appendIcon: {
        type: String,
        default: null,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      persistentHint: {
        type: Boolean,
        default: false,
      },
      dense: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        itemValue: 15,
        items: [
          15,
          30,
          50,
          100,
          200,
        ]
      }
    },
    beforeMount() {
      this.itemValue = this.value;
    },
    watch: {
      value() {
        this.itemValue = this.value;
      },
      itemValue(newVal) {
        this.$emit('input', newVal);
      }
    },
  }
</script>
