import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_INVENTORY_MOVEMENTS_LIST,
  GET_INVENTORY_MOVEMENTS_LIST_SUCCESS,
  GET_INVENTORY_MOVEMENTS_LIST_FAIL,
  GET_INVENTORY_MOVEMENTS_PAGINATION,
  GET_INVENTORY_MOVEMENTS_PAGINATION_SUCCESS,
  GET_INVENTORY_MOVEMENTS_PAGINATION_FAIL,
  GET_INVENTORY_MOVEMENTS_COLLECTION,
  GET_INVENTORY_MOVEMENTS_COLLECTION_SUCCESS,
  GET_INVENTORY_MOVEMENTS_COLLECTION_FAIL,
  GET_INVENTORY_MOVEMENTS_SINGLE,
  GET_INVENTORY_MOVEMENTS_SINGLE_SUCCESS,
  GET_INVENTORY_MOVEMENTS_SINGLE_FAIL,
  CREATE_INVENTORY_MOVEMENTS,
  CREATE_INVENTORY_MOVEMENTS_SUCCESS,
  CREATE_INVENTORY_MOVEMENTS_FAIL,
  CREATE_INVENTORY_MOVEMENTS_RESET,
  UPDATE_INVENTORY_MOVEMENTS,
  UPDATE_INVENTORY_MOVEMENTS_SUCCESS,
  UPDATE_INVENTORY_MOVEMENTS_FAIL,
  UPDATE_INVENTORY_MOVEMENTS_RESET,
  REPLACE_INVENTORY_MOVEMENTS,
  REPLACE_INVENTORY_MOVEMENTS_SUCCESS,
  REPLACE_INVENTORY_MOVEMENTS_FAIL,
  REPLACE_INVENTORY_MOVEMENTS_RESET,
  REMOVE_INVENTORY_MOVEMENTS,
  REMOVE_INVENTORY_MOVEMENTS_SUCCESS,
  REMOVE_INVENTORY_MOVEMENTS_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_INVENTORY_MOVEMENTS_LIST,
    endPoint: "inventory-movements/list",
    mutationFail: GET_INVENTORY_MOVEMENTS_LIST_FAIL,
    mutationSuccess: GET_INVENTORY_MOVEMENTS_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_INVENTORY_MOVEMENTS_PAGINATION,
    endPoint: "inventory-movements/paginated",
    mutationFail: GET_INVENTORY_MOVEMENTS_PAGINATION_FAIL,
    mutationSuccess: GET_INVENTORY_MOVEMENTS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_INVENTORY_MOVEMENTS_COLLECTION,
    endPoint: "inventory-movements/collected",
    mutationFail: GET_INVENTORY_MOVEMENTS_COLLECTION_FAIL,
    mutationSuccess: GET_INVENTORY_MOVEMENTS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_INVENTORY_MOVEMENTS_SINGLE,
    endPoint: "inventory-movements/single",
    mutationFail: GET_INVENTORY_MOVEMENTS_SINGLE_FAIL,
    mutationSuccess: GET_INVENTORY_MOVEMENTS_SINGLE_SUCCESS
  },
  creationRequest: {
    isUpload: true,
    action: CREATE_INVENTORY_MOVEMENTS,
    actionReset: CREATE_INVENTORY_MOVEMENTS_RESET,
    endPoint: "inventory-movements/create",
    mutationFail: CREATE_INVENTORY_MOVEMENTS_FAIL,
    mutationSuccess: CREATE_INVENTORY_MOVEMENTS_SUCCESS
  },
  editingRequest: {
    isUpload: true,
    action: UPDATE_INVENTORY_MOVEMENTS,
    actionReset: UPDATE_INVENTORY_MOVEMENTS_RESET,
    endPoint: "inventory-movements/update",
    mutationFail: UPDATE_INVENTORY_MOVEMENTS_FAIL,
    mutationSuccess: UPDATE_INVENTORY_MOVEMENTS_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_INVENTORY_MOVEMENTS,
    actionReset: REPLACE_INVENTORY_MOVEMENTS_RESET,
    endPoint: "inventory-movements/replace",
    mutationFail: REPLACE_INVENTORY_MOVEMENTS_FAIL,
    mutationSuccess: REPLACE_INVENTORY_MOVEMENTS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_INVENTORY_MOVEMENTS,
    endPoint: "inventory-movements/delete",
    mutationFail: REMOVE_INVENTORY_MOVEMENTS_FAIL,
    mutationSuccess: REMOVE_INVENTORY_MOVEMENTS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.state,
};

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
