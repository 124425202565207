export const GET_RESERVATION_META_LIST = "[RESERVATION_META] List Get";
export const GET_RESERVATION_META_LIST_SUCCESS = "[RESERVATION_META] List Get Success";
export const GET_RESERVATION_META_LIST_FAIL = "[RESERVATION_META] List Get Fail";

export const GET_RESERVATION_META_PAGINATION = "[RESERVATION_META] Pagination Get";
export const GET_RESERVATION_META_PAGINATION_SUCCESS = "[RESERVATION_META] Pagination Get Success";
export const GET_RESERVATION_META_PAGINATION_FAIL = "[RESERVATION_META] Pagination Get Fail";

export const GET_RESERVATION_META_COLLECTION = "[RESERVATION_META] Collection Get";
export const GET_RESERVATION_META_COLLECTION_SUCCESS = "[RESERVATION_META] Collection Get Success";
export const GET_RESERVATION_META_COLLECTION_FAIL = "[RESERVATION_META] Collection Get Fail";

export const GET_RESERVATION_META_SINGLE = "[RESERVATION_META] Single Get";
export const GET_RESERVATION_META_SINGLE_SUCCESS = "[RESERVATION_META] Single Get Success";
export const GET_RESERVATION_META_SINGLE_FAIL = "[RESERVATION_META] Single Get Fail";

export const CREATE_RESERVATION_META = "[RESERVATION_META] Create";
export const CREATE_RESERVATION_META_SUCCESS = "[RESERVATION_META] Create Success";
export const CREATE_RESERVATION_META_FAIL = "[RESERVATION_META] Create Fail";
export const CREATE_RESERVATION_META_RESET = "[RESERVATION_META] Create Reset";

export const UPDATE_RESERVATION_META = "[RESERVATION_META] Update";
export const UPDATE_RESERVATION_META_SUCCESS = "[RESERVATION_META] Update Success";
export const UPDATE_RESERVATION_META_FAIL = "[RESERVATION_META] Update Fail";
export const UPDATE_RESERVATION_META_RESET = "[RESERVATION_META] Update Reset";

export const REPLACE_RESERVATION_META = "[RESERVATION_META] Replace";
export const REPLACE_RESERVATION_META_SUCCESS = "[RESERVATION_META] Replace Success";
export const REPLACE_RESERVATION_META_FAIL = "[RESERVATION_META] Replace Fail";
export const REPLACE_RESERVATION_META_RESET = "[RESERVATION_META] Replace Reset";

export const REMOVE_RESERVATION_META = "[RESERVATION_META] Remove";
export const REMOVE_RESERVATION_META_SUCCESS = "[RESERVATION_META] Remove Success";
export const REMOVE_RESERVATION_META_FAIL = "[RESERVATION_META] Remove Fail";