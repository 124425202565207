export const GET_OCTORATE_CONNECTION_ROOM_TYPES_PAGINATION = "[OCTORATE_CONNECTION_ROOM_TYPES] Pagination Get";
export const GET_OCTORATE_CONNECTION_ROOM_TYPES_PAGINATION_SUCCESS = "[OCTORATE_CONNECTION_ROOM_TYPES] Pagination Get Success";
export const GET_OCTORATE_CONNECTION_ROOM_TYPES_PAGINATION_FAIL = "[OCTORATE_CONNECTION_ROOM_TYPES] Pagination Get Fail";

export const GET_OCTORATE_CONNECTION_ROOM_TYPES_COLLECTION = "[OCTORATE_CONNECTION_ROOM_TYPES] Collection Get";
export const GET_OCTORATE_CONNECTION_ROOM_TYPES_COLLECTION_SUCCESS = "[OCTORATE_CONNECTION_ROOM_TYPES] Collection Get Success";
export const GET_OCTORATE_CONNECTION_ROOM_TYPES_COLLECTION_FAIL = "[OCTORATE_CONNECTION_ROOM_TYPES] Collection Get Fail";

export const GET_OCTORATE_CONNECTION_ROOM_TYPES_SINGLE = "[OCTORATE_CONNECTION_ROOM_TYPES] Single Get";
export const GET_OCTORATE_CONNECTION_ROOM_TYPES_SINGLE_SUCCESS = "[OCTORATE_CONNECTION_ROOM_TYPES] Single Get Success";
export const GET_OCTORATE_CONNECTION_ROOM_TYPES_SINGLE_FAIL = "[OCTORATE_CONNECTION_ROOM_TYPES] Single Get Fail";

export const CREATE_OCTORATE_CONNECTION_ROOM_TYPES = "[OCTORATE_CONNECTION_ROOM_TYPES] Create";
export const CREATE_OCTORATE_CONNECTION_ROOM_TYPES_SUCCESS = "[OCTORATE_CONNECTION_ROOM_TYPES] Create Success";
export const CREATE_OCTORATE_CONNECTION_ROOM_TYPES_FAIL = "[OCTORATE_CONNECTION_ROOM_TYPES] Create Fail";
export const CREATE_OCTORATE_CONNECTION_ROOM_TYPES_RESET = "[OCTORATE_CONNECTION_ROOM_TYPES] Create Reset";

export const UPDATE_OCTORATE_CONNECTION_ROOM_TYPES = "[OCTORATE_CONNECTION_ROOM_TYPES] Update";
export const UPDATE_OCTORATE_CONNECTION_ROOM_TYPES_SUCCESS = "[OCTORATE_CONNECTION_ROOM_TYPES] Update Success";
export const UPDATE_OCTORATE_CONNECTION_ROOM_TYPES_FAIL = "[OCTORATE_CONNECTION_ROOM_TYPES] Update Fail";
export const UPDATE_OCTORATE_CONNECTION_ROOM_TYPES_RESET = "[OCTORATE_CONNECTION_ROOM_TYPES] Update Reset";

export const REMOVE_OCTORATE_CONNECTION_ROOM_TYPES = "[OCTORATE_CONNECTION_ROOM_TYPES] Remove";
export const REMOVE_OCTORATE_CONNECTION_ROOM_TYPES_SUCCESS = "[OCTORATE_CONNECTION_ROOM_TYPES] Remove Success";
export const REMOVE_OCTORATE_CONNECTION_ROOM_TYPES_FAIL = "[OCTORATE_CONNECTION_ROOM_TYPES] Remove Fail";

export const IMPORT_OCTORATE_CONNECTION_ROOM_TYPES = "[OCTORATE_CONNECTION_ROOM_TYPES] Import External Room Types";
export const IMPORT_OCTORATE_CONNECTION_ROOM_TYPES_SUCCESS = "[OCTORATE_CONNECTION_ROOM_TYPES] Import External Room Types Success";
export const IMPORT_OCTORATE_CONNECTION_ROOM_TYPES_FAIL = "[OCTORATE_CONNECTION_ROOM_TYPES] Import External Room Types Fail";

export const IMPORT_OCTORATE_CONNECTION_MAPPING = "[OCTORATE_MAPPING] Import Mapping";
export const IMPORT_OCTORATE_CONNECTION_MAPPING_SUCCESS = "[OCTORATE_MAPPING] Import Mapping Success";
export const IMPORT_OCTORATE_CONNECTION_MAPPING_FAIL = "[OCTORATE_MAPPING] Import Mapping Fail";

export const GET_MAPPING_COLLECTION = "[OCTORATE_MAPPING] Mapping Collection";
export const GET_MAPPING_COLLECTION_SUCCESS = "[OCTORATE_MAPPING] Mapping Collection Success";
export const GET_MAPPING_COLLECTION_FAIL = "[OCTORATE_MAPPING] Mapping Collection Fail";

export const CREATE_OCTORATE_CONNECTION_MAPPING = "[OCTORATE_CONNECTION_ROOM_TYPES] Create Mapping";
export const CREATE_OCTORATE_CONNECTION_MAPPING_SUCCESS = "[OCTORATE_CONNECTION_ROOM_TYPES] Create Mapping Success";
export const CREATE_OCTORATE_CONNECTION_MAPPING_FAIL = "[OCTORATE_CONNECTION_ROOM_TYPES] Create Mapping Fail";

export const REMOVE_OCTORATE_CONNECTION_MAPPING = "[OCTORATE_CONNECTION_ROOM_TYPES] Remove Mapping";
export const REMOVE_OCTORATE_CONNECTION_MAPPING_SUCCESS = "[OCTORATE_CONNECTION_ROOM_TYPES] Remove Mapping Success";
export const REMOVE_OCTORATE_CONNECTION_MAPPING_FAIL = "[OCTORATE_CONNECTION_ROOM_TYPES] Remove Mapping Fail";
