import action from "@/store/core/action";

import {
  MFA_GET,
  MFA_GET_SUCCESS,
  MFA_GET_FAIL,
  MFA_ENABLE,
  MFA_ENABLE_SUCCESS,
  MFA_ENABLE_FAIL,
  MFA_ENABLE_RESET,
  MFA_DISABLE,
  MFA_DISABLE_SUCCESS,
  MFA_DISABLE_FAIL,
  MFA_GOOGLE_INFO_GET,
  MFA_GOOGLE_INFO_GET_SUCCESS,
  MFA_GOOGLE_INFO_GET_FAIL,
} from "./actions";

const actionsProcessed = action.processMultiple([
  {
    name: 'getRequest',
    action: MFA_GET,
    endPoint: 'mfa/get',
    mutations: {
      fail: MFA_GET_FAIL,
      success: MFA_GET_SUCCESS,
    },
  },
  {
    method: 'put',
    name: 'enableRequest',
    action: MFA_ENABLE,
    actionReset: MFA_ENABLE_RESET,
    endPoint: 'mfa/enable',
    mutations: {
      fail: MFA_ENABLE_FAIL,
      success: MFA_ENABLE_SUCCESS,
    },
  },
  {
    method: 'put',
    name: 'disableRequest',
    action: MFA_DISABLE,
    endPoint: 'mfa/disable',
    mutations: {
      fail: MFA_DISABLE_FAIL,
      success: MFA_DISABLE_SUCCESS,
    },
  },
  {
    name: 'googleInfoRequest',
    action: MFA_GOOGLE_INFO_GET,
    endPoint: 'mfa/google-info',
    mutations: {
      fail: MFA_GOOGLE_INFO_GET_FAIL,
      success: MFA_GOOGLE_INFO_GET_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  ...actionsProcessed.state,
};

const actions = {
  ...actionsProcessed.actions,
};

const mutations = {
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
