import list from "@/store/core/list";
import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_PROPERTY_CLUSTERS_LIST,
  GET_PROPERTY_CLUSTERS_LIST_SUCCESS,
  GET_PROPERTY_CLUSTERS_LIST_FAIL,
  GET_PROPERTY_CLUSTERS_PAGINATION,
  GET_PROPERTY_CLUSTERS_PAGINATION_SUCCESS,
  GET_PROPERTY_CLUSTERS_PAGINATION_FAIL,
  GET_PROPERTY_CLUSTERS_COLLECTION,
  GET_PROPERTY_CLUSTERS_COLLECTION_SUCCESS,
  GET_PROPERTY_CLUSTERS_COLLECTION_FAIL,
  GET_PROPERTY_CLUSTERS_SINGLE,
  GET_PROPERTY_CLUSTERS_SINGLE_SUCCESS,
  GET_PROPERTY_CLUSTERS_SINGLE_FAIL,
  CREATE_PROPERTY_CLUSTERS,
  CREATE_PROPERTY_CLUSTERS_SUCCESS,
  CREATE_PROPERTY_CLUSTERS_FAIL,
  CREATE_PROPERTY_CLUSTERS_RESET,
  UPDATE_PROPERTY_CLUSTERS,
  UPDATE_PROPERTY_CLUSTERS_SUCCESS,
  UPDATE_PROPERTY_CLUSTERS_FAIL,
  UPDATE_PROPERTY_CLUSTERS_RESET,
  REMOVE_PROPERTY_CLUSTERS,
  REMOVE_PROPERTY_CLUSTERS_SUCCESS,
  REMOVE_PROPERTY_CLUSTERS_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_PROPERTY_CLUSTERS_LIST,
    endPoint: "property-clusters/list",
    mutationFail: GET_PROPERTY_CLUSTERS_LIST_FAIL,
    mutationSuccess: GET_PROPERTY_CLUSTERS_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_PROPERTY_CLUSTERS_PAGINATION,
    endPoint: "property-clusters/paginated",
    mutationFail: GET_PROPERTY_CLUSTERS_PAGINATION_FAIL,
    mutationSuccess: GET_PROPERTY_CLUSTERS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_PROPERTY_CLUSTERS_COLLECTION,
    endPoint: "property-clusters/collected",
    mutationFail: GET_PROPERTY_CLUSTERS_COLLECTION_FAIL,
    mutationSuccess: GET_PROPERTY_CLUSTERS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_PROPERTY_CLUSTERS_SINGLE,
    endPoint: "property-clusters/single",
    mutationFail: GET_PROPERTY_CLUSTERS_SINGLE_FAIL,
    mutationSuccess: GET_PROPERTY_CLUSTERS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_PROPERTY_CLUSTERS,
    actionReset: CREATE_PROPERTY_CLUSTERS_RESET,
    endPoint: "property-clusters/create",
    mutationFail: CREATE_PROPERTY_CLUSTERS_FAIL,
    mutationSuccess: CREATE_PROPERTY_CLUSTERS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_PROPERTY_CLUSTERS,
    actionReset: UPDATE_PROPERTY_CLUSTERS_RESET,
    endPoint: "property-clusters/update",
    mutationFail: UPDATE_PROPERTY_CLUSTERS_FAIL,
    mutationSuccess: UPDATE_PROPERTY_CLUSTERS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_PROPERTY_CLUSTERS,
    endPoint: "property-clusters/delete",
    mutationFail: REMOVE_PROPERTY_CLUSTERS_FAIL,
    mutationSuccess: REMOVE_PROPERTY_CLUSTERS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
