export default [
  {
    name: "documents",
    path: "/accounting/documents",
    component: () => import(/*webpackChunkName: "accounting" */ "@/pages/documents/Index"),
    meta: {
      title: 'titles.documents'
    }
  },
  {
    name: "feeds-register",
    path: "/accounting/feeds-register",
    component: () => import(/*webpackChunkName: "feeds-register" */ "@/pages/documents/FeedsRegister"),
    meta: {
      title: 'titles.feeds_register'
    }
  },
  {
    name: "owner-documents",
    path: "/accounting/owner-documents",
    component: () => import(/*webpackChunkName: "accounting" */ "@/pages/owner-documents/Index"),
    meta: {
      title: 'titles.owner_documents'
    }
  },
];
