<template>
  <span>
    <v-select
      v-model="ids"
      :items="items"
      :label="label"
      :multiple="multiple"
      :small-chips="smallChips"
      :deletable-chips="deletableChips"
      :hint="hint"
      :persistent-hint="persistentHint"
      :loading="loading"
      :prepend-icon="prependIcon"
      append-outer-icon="mdi-refresh"
      :clearable="clearable"
      :disabled="disabled"
      :readonly="readonly"
      :dark="dark"
      @change="change"
      @blur="blur"
      @click:append-outer="getItems()"
      :dense="dense"
      :error="error"
      :error-messages="!!errorMessages ? errorMessages : []"
    />
  </span>
</template>

<script>
import { mapState } from "vuex";
import { GET_COMPANY_LOCATIONS_LIST } from "@/store/company-locations/actions";

export default {
	name: "CCompanyLocationsSelect",
	props: {
		label: {
			type: String,
			required: false,
      default: ''
		},
		hint: {
			type: String,
			default: ""
		},
    persistentHint: {
      type: Boolean,
      default: false
    },
		value: {
			default: null
		},
		error: {
			default: false
		},
		errorMessages: {
			default: () => []
		},
		dense: {
			type: Boolean,
			default: false
		},
		prependIcon: {
			default: "mdi-map-marker"
		},
		clearable: {
			type: Boolean,
			default: false
		},
    multiple: {
      type: Boolean,
			default: false
    },
		disabled: {
			type: Boolean,
			default: false
		},
		readonly: {
			type: Boolean,
			default: false
		},
		dark: {
			type: Boolean,
			default: false
		},
    smallChips: {
      type: Boolean,
			default: false
    },
    deletableChips: {
      type: Boolean,
			default: false
    },
    withSelectionButtons: {
      type: Boolean,
      default: false
    },
    autoselect: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => {
        return {};
      }
    },
    companyId: {
      type: Number,
      required: false,
      default: null,
    }
	},
	data() {
		return {
			ids: null,
      selectedIds: [],
		};
	},
  beforeMount() {
    if (this.loading) {
      return;
    }

    this.getItems();
  },
  mounted() {
    this.ids = this.value;
  },
	methods: {
    getItems() {
      if(this.listRequest.loading || !this.companyId) {
        return;
      }
      
      let filters = this.options.filters || {};

      this.$store.dispatch(GET_COMPANY_LOCATIONS_LIST, {
        sortBy: 'name',
        order: 'asc',
        company_id: this.companyId,
        ...filters,
      });
    },
		change() {
			this.$emit("change");
		},
    blur() {
      this.$emit("blur");
    },
	},
  computed: {
    ...mapState({
      listRequest: state => state.companyLocations.getListRequest,
    }),
    items() {
      if (!this.listRequest.list) {
        return [];
      }

      return this.listRequest.list;
    },
    loading() {
      return this.listRequest.loading;
    },
    isListCached() {
      return this.listRequest.success;
    },
  },
  watch: {
    ids(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.ids = newVal;
    },
    companyId() {
      this.getItems();
    },
  }
};
</script>
