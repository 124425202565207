export default {
  enabled: process.env.VUE_APP_BROADCAST_ENABLED === 'true',
  pusher: {
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  },
  websocket: {
    hostname: process.env.VUE_APP_WEBSOCKET_HOST,
    forceTLS: process.env.VUE_APP_WEBSOCKET_TLS === 'true',
  }
}
