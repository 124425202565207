
import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_TICKET_MESSAGES_LIST,
  GET_TICKET_MESSAGES_LIST_SUCCESS,
  GET_TICKET_MESSAGES_LIST_FAIL,
  GET_TICKET_MESSAGES_PAGINATION,
  GET_TICKET_MESSAGES_PAGINATION_SUCCESS,
  GET_TICKET_MESSAGES_PAGINATION_FAIL,
  GET_TICKET_MESSAGES_COLLECTION,
  GET_TICKET_MESSAGES_COLLECTION_SUCCESS,
  GET_TICKET_MESSAGES_COLLECTION_FAIL,
  GET_TICKET_MESSAGES_SINGLE,
  GET_TICKET_MESSAGES_SINGLE_SUCCESS,
  GET_TICKET_MESSAGES_SINGLE_FAIL,
  CREATE_TICKET_MESSAGES,
  CREATE_TICKET_MESSAGES_SUCCESS,
  CREATE_TICKET_MESSAGES_FAIL,
  CREATE_TICKET_MESSAGES_RESET,
  UPDATE_TICKET_MESSAGES,
  UPDATE_TICKET_MESSAGES_SUCCESS,
  UPDATE_TICKET_MESSAGES_FAIL,
  UPDATE_TICKET_MESSAGES_RESET,
  REMOVE_TICKET_MESSAGES,
  REMOVE_TICKET_MESSAGES_SUCCESS,
  REMOVE_TICKET_MESSAGES_FAIL,
  READ_TICKET_MESSAGES,
  READ_TICKET_MESSAGES_SUCCESS,
  READ_TICKET_MESSAGES_FAIL
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_TICKET_MESSAGES_LIST,
    endPoint: "ticket-messages/list",
    mutationFail: GET_TICKET_MESSAGES_LIST_FAIL,
    mutationSuccess: GET_TICKET_MESSAGES_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_TICKET_MESSAGES_PAGINATION,
    endPoint: "ticket-messages/paginated",
    mutationFail: GET_TICKET_MESSAGES_PAGINATION_FAIL,
    mutationSuccess: GET_TICKET_MESSAGES_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_TICKET_MESSAGES_COLLECTION,
    endPoint: "ticket-messages/collected",
    mutationFail: GET_TICKET_MESSAGES_COLLECTION_FAIL,
    mutationSuccess: GET_TICKET_MESSAGES_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_TICKET_MESSAGES_SINGLE,
    endPoint: "ticket-messages/single",
    mutationFail: GET_TICKET_MESSAGES_SINGLE_FAIL,
    mutationSuccess: GET_TICKET_MESSAGES_SINGLE_SUCCESS
  },
  creationRequest: {
    isUpload: true,
    action: CREATE_TICKET_MESSAGES,
    actionReset: CREATE_TICKET_MESSAGES_RESET,
    endPoint: "ticket-messages/create",
    mutationFail: CREATE_TICKET_MESSAGES_FAIL,
    mutationSuccess: CREATE_TICKET_MESSAGES_SUCCESS
  },
  editingRequest: {
    isUpload: true,
    action: UPDATE_TICKET_MESSAGES,
    actionReset: UPDATE_TICKET_MESSAGES_RESET,
    endPoint: "ticket-messages/update",
    mutationFail: UPDATE_TICKET_MESSAGES_FAIL,
    mutationSuccess: UPDATE_TICKET_MESSAGES_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_TICKET_MESSAGES,
    endPoint: "ticket-messages/delete",
    mutationFail: REMOVE_TICKET_MESSAGES_FAIL,
    mutationSuccess: REMOVE_TICKET_MESSAGES_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    method: 'post',
    name: 'setReadMessagesRequest',
    action: READ_TICKET_MESSAGES,
    endPoint: 'ticket-messages/read-messages/{ticketId}',
    mutations: {
      fail: READ_TICKET_MESSAGES_FAIL,
      success: READ_TICKET_MESSAGES_SUCCESS,
    },
  },
]);

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
