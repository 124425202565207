import action from "@/store/core/action";
import crud from "@/store/core/crud";
import ApiService from "@/services/api";

import {
  GET_RATE_CALENDAR_DETAILED_DATA,
  GET_RATE_CALENDAR_DETAILED_DATA_SUCCESS,
  GET_RATE_CALENDAR_DETAILED_DATA_FAIL,
  GET_RATE_CALENDAR_COMPRESSED_DATA,
  GET_RATE_CALENDAR_COMPRESSED_DATA_SUCCESS,
  GET_RATE_CALENDAR_COMPRESSED_DATA_FAIL,
  GET_RATE_CALENDAR_CHILDREN_DATA,
  GET_RATE_CALENDAR_CHILDREN_DATA_LOADING,
  GET_RATE_CALENDAR_CHILDREN_DATA_SUCCESS,
  GET_RATE_CALENDAR_CHILDREN_DATA_FAIL,
  GET_RATE_CALENDAR_COLLECTION,
  GET_RATE_CALENDAR_COLLECTION_SUCCESS,
  GET_RATE_CALENDAR_COLLECTION_FAIL,
  CLEAR_RATE_CALENDAR_CHILDREN_DATA,
  CLEAR_RATE_CALENDAR_CHILDREN_DATA_SUCCESS,
  CREATE_RATE,
  CREATE_RATE_SUCCESS,
  CREATE_RATE_FAIL,
  CREATE_RATE_RESET,
  CREATE_RATE_BULK,
  CREATE_RATE_BULK_SUCCESS,
  CREATE_RATE_BULK_FAIL,
  CREATE_RATE_BULK_RESET,
  GET_RATE_CALENDAR_SINGLE,
  GET_RATE_CALENDAR_SINGLE_SUCCESS,
  GET_RATE_CALENDAR_SINGLE_FAIL,
} from "./actions";

const crudProcessed = crud.process({
  getCollectionRequest: {
    action: GET_RATE_CALENDAR_COLLECTION,
    endPoint: "/rate-calendar/collected",
    mutationFail: GET_RATE_CALENDAR_COLLECTION_FAIL,
    mutationSuccess: GET_RATE_CALENDAR_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_RATE_CALENDAR_SINGLE,
    endPoint: "/rate-calendar/single",
    mutationFail: GET_RATE_CALENDAR_SINGLE_FAIL,
    mutationSuccess: GET_RATE_CALENDAR_SINGLE_SUCCESS
  },
});

const actionsProcessed = action.processMultiple([
  {
    name: 'getDataRequest',
    action: GET_RATE_CALENDAR_DETAILED_DATA,
    endPoint: 'rate-calendar/detailed',
    mutations: {
      fail: GET_RATE_CALENDAR_DETAILED_DATA_FAIL,
      success: GET_RATE_CALENDAR_DETAILED_DATA_SUCCESS,
      successCallback(state, data) {
        if (data.pagination.page > 1) {
          data.collection = [
            ...state.getDataRequest.data.collection,
            ...data.collection,
          ];
        }

        state.getDataRequest = {
          loading: false,
          success: true,
          fail: false,
          data: data,
          errors: {},
        };
      }
    },
  },
  {
    name: 'getDataRequest',
    action: GET_RATE_CALENDAR_COMPRESSED_DATA,
    endPoint: 'rate-calendar/compressed',
    mutations: {
      fail: GET_RATE_CALENDAR_COMPRESSED_DATA_FAIL,
      success: GET_RATE_CALENDAR_COMPRESSED_DATA_SUCCESS,
      successCallback(state, data) {
        if (data.pagination.page > 1) {
          data.collection = [
            ...state.getDataRequest.data.collection,
            ...data.collection,
          ];
        }

        state.getDataRequest = {
          loading: false,
          success: true,
          fail: false,
          data: data,
          errors: {},
        };
      }
    },
  },
]);

const getters = {};

const state = {
  getChildrenRequest: {
    errors: {}
  },
  creationRequest: {
    loading: false,
    success: false,
    fail: false,
    validation: {},
    response: {}
  },
  creationBulkRequest: {
    loading: false,
    success: false,
    fail: false,
    validation: {},
    response: {}
  },
  ...actionsProcessed.state,
  ...crudProcessed.state,
};

const actions = {
  [GET_RATE_CALENDAR_CHILDREN_DATA](context, { id, params }) {
    context.commit(GET_RATE_CALENDAR_CHILDREN_DATA_LOADING, id);

    ApiService.query(`rate-calendar/${id}/children`, params)
      .then(response => {
        context.commit(
          GET_RATE_CALENDAR_CHILDREN_DATA_SUCCESS,
          {
            id,
            data: response.data.data
          }
        );
      })
      .catch(({ response }) => {
        context.commit(
          GET_RATE_CALENDAR_CHILDREN_DATA_FAIL,
          response.data.errors
        );
      });
  },
  [CLEAR_RATE_CALENDAR_CHILDREN_DATA](context, id) {
    context.commit(CLEAR_RATE_CALENDAR_CHILDREN_DATA_SUCCESS, id);
  },
  [CREATE_RATE](context, params) {
    context.state.creationRequest.loading = true;
    return new Promise((resolve, reject) => {
      ApiService.post('rate-calendar/create', params)
        .then(response => {
          context.commit(
            CREATE_RATE_SUCCESS,
            response.data.data
          );
          resolve(response.data);
        })
        .catch(({ response }) => {
          context.commit(
            CREATE_RATE_FAIL,
            response.data.errors
          );
          reject(response.data.errors);
        });
    });
  },
  [CREATE_RATE_RESET](context) {
    context.state.creationRequest = {
      loading: false,
      success: false,
      fail: false,
      validation: {},
      response: {}
    };
  },
  [CREATE_RATE_BULK](context, params) {
    context.state.creationBulkRequest.loading = true;
    return new Promise((resolve, reject) => {
      ApiService.post('rate-calendar/bulk-create', params)
        .then(response => {
          context.commit(
            CREATE_RATE_BULK_SUCCESS,
            response.data.data
          );
          resolve(response.data);
        })
        .catch(({ response }) => {
          context.commit(
            CREATE_RATE_BULK_FAIL,
            response.data.errors
          );
          reject(response.data.errors);
        });
    });
  },
  [CREATE_RATE_BULK_RESET](context) {
    context.state.creationBulkRequest = {
      loading: false,
      success: false,
      fail: false,
      validation: {},
      response: {}
    };
  },
  ...actionsProcessed.actions,
  ...crudProcessed.actions,
};

const mutations = {
  [GET_RATE_CALENDAR_CHILDREN_DATA_LOADING](state, id) {
    state.getDataRequest.data.collection.map((property) => {
      property.unit_categories.map((unit_category) => {
        unit_category.room_types = unit_category.room_types.filter((room_type) => {
          if (room_type.id === id) {
            Object.assign(room_type, {
              isLoading: true,
            });
          }
          return room_type;
        });
      });
    });
  },
  [GET_RATE_CALENDAR_CHILDREN_DATA_SUCCESS](state, { id, data }) {
    state.getDataRequest.data.collection.map((property) => {
      property.unit_categories.map((unit_category) => {
        unit_category.room_types.map((room_type, i) => {
          if (room_type.id === id) {
            Object.assign(room_type, {
              isLoaded: true,
              isLoading: false,
            });

            data.collection.map((item) => {
              item.parent_id = id;
            });

            unit_category.room_types.splice(i + 1, 0, ...data.collection);
          }
        });
      });
    });
  },
  [GET_RATE_CALENDAR_CHILDREN_DATA_FAIL](state, data) {

  },
  [CLEAR_RATE_CALENDAR_CHILDREN_DATA_SUCCESS](state, id) {
    state.getDataRequest.data.collection.map((property) => {
      property.unit_categories.map((unit_category) => {
        unit_category.room_types = unit_category.room_types.filter((room_type, i) => {
          if (id === room_type.id) {
            Object.assign(room_type, {
              isLoaded: false,
            });
          }

          return id !== room_type.parent_id;
        });
      });
    });
  },
  [CREATE_RATE_SUCCESS](state, data) {
    state.creationRequest = {
      loading: false,
      success: true,
      fail: false,
      validation: {},
      response: data
    };

    state.getDataRequest.data.collection.map((property) => {
      property.unit_categories.map((unit_category) => {
        unit_category.room_types.map((room_type) => {
          data.map((item) => {

            let isExisting = false;

            room_type.rates.map((rate) => {
              if (item.id === rate.id) {
                isExisting = true;
                Object.assign(rate, item);
              }
            });

            if (!isExisting && item.room_type_id === room_type.id) {
              room_type.rates.push(item);
            }
          });
        });
      });
    });
  },
  [CREATE_RATE_FAIL](state, data) {
    state.creationRequest = {
      loading: false,
      success: false,
      fail: true,
      validation: data,
      response: {}
    };
  },
  [CREATE_RATE_BULK_SUCCESS](state, data) {
    state.creationBulkRequest = {
      loading: false,
      success: true,
      fail: false,
      validation: {},
      response: data
    };
  },
  [CREATE_RATE_BULK_FAIL](state, data) {
    state.creationBulkRequest = {
      loading: false,
      success: false,
      fail: true,
      validation: data,
      response: {}
    };
  },
  ...actionsProcessed.mutations,
  ...crudProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
