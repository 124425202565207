export const GET_USER_SUPPORT_REQUESTS_LIST = "[USER_SUPPORT_REQUESTS] List Get";
export const GET_USER_SUPPORT_REQUESTS_LIST_SUCCESS = "[USER_SUPPORT_REQUESTS] List Get Success";
export const GET_USER_SUPPORT_REQUESTS_LIST_FAIL = "[USER_SUPPORT_REQUESTS] List Get Fail";

export const GET_USER_SUPPORT_REQUESTS_PAGINATION = "[USER_SUPPORT_REQUESTS] Pagination Get";
export const GET_USER_SUPPORT_REQUESTS_PAGINATION_SUCCESS = "[USER_SUPPORT_REQUESTS] Pagination Get Success";
export const GET_USER_SUPPORT_REQUESTS_PAGINATION_FAIL = "[USER_SUPPORT_REQUESTS] Pagination Get Fail";

export const GET_USER_SUPPORT_REQUESTS_COLLECTION = "[USER_SUPPORT_REQUESTS] Collection Get";
export const GET_USER_SUPPORT_REQUESTS_COLLECTION_SUCCESS = "[USER_SUPPORT_REQUESTS] Collection Get Success";
export const GET_USER_SUPPORT_REQUESTS_COLLECTION_FAIL = "[USER_SUPPORT_REQUESTS] Collection Get Fail";

export const GET_USER_SUPPORT_REQUESTS_SINGLE = "[USER_SUPPORT_REQUESTS] Single Get";
export const GET_USER_SUPPORT_REQUESTS_SINGLE_SUCCESS = "[USER_SUPPORT_REQUESTS] Single Get Success";
export const GET_USER_SUPPORT_REQUESTS_SINGLE_FAIL = "[USER_SUPPORT_REQUESTS] Single Get Fail";

export const CREATE_USER_SUPPORT_REQUESTS = "[USER_SUPPORT_REQUESTS] Create";
export const CREATE_USER_SUPPORT_REQUESTS_SUCCESS = "[USER_SUPPORT_REQUESTS] Create Success";
export const CREATE_USER_SUPPORT_REQUESTS_FAIL = "[USER_SUPPORT_REQUESTS] Create Fail";
export const CREATE_USER_SUPPORT_REQUESTS_RESET = "[USER_SUPPORT_REQUESTS] Create Reset";

export const UPDATE_USER_SUPPORT_REQUESTS = "[USER_SUPPORT_REQUESTS] Update";
export const UPDATE_USER_SUPPORT_REQUESTS_SUCCESS = "[USER_SUPPORT_REQUESTS] Update Success";
export const UPDATE_USER_SUPPORT_REQUESTS_FAIL = "[USER_SUPPORT_REQUESTS] Update Fail";
export const UPDATE_USER_SUPPORT_REQUESTS_RESET = "[USER_SUPPORT_REQUESTS] Update Reset";

export const REPLACE_USER_SUPPORT_REQUESTS = "[USER_SUPPORT_REQUESTS] Replace";
export const REPLACE_USER_SUPPORT_REQUESTS_SUCCESS = "[USER_SUPPORT_REQUESTS] Replace Success";
export const REPLACE_USER_SUPPORT_REQUESTS_FAIL = "[USER_SUPPORT_REQUESTS] Replace Fail";
export const REPLACE_USER_SUPPORT_REQUESTS_RESET = "[USER_SUPPORT_REQUESTS] Replace Reset";

export const REMOVE_USER_SUPPORT_REQUESTS = "[USER_SUPPORT_REQUESTS] Remove";
export const REMOVE_USER_SUPPORT_REQUESTS_SUCCESS = "[USER_SUPPORT_REQUESTS] Remove Success";
export const REMOVE_USER_SUPPORT_REQUESTS_FAIL = "[USER_SUPPORT_REQUESTS] Remove Fail";
