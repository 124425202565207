export const GET_BOOKING_SETTINGS_COLLECTION = "[BOOKING_SETTINGS] Collection Get";
export const GET_BOOKING_SETTINGS_COLLECTION_SUCCESS = "[BOOKING_SETTINGS] Collection Get Success";
export const GET_BOOKING_SETTINGS_COLLECTION_FAIL = "[BOOKING_SETTINGS] Collection Get Fail";

export const CREATE_BOOKING_SETTINGS = "[BOOKING_SETTINGS] Create";
export const CREATE_BOOKING_SETTINGS_SUCCESS = "[BOOKING_SETTINGS] Create Success";
export const CREATE_BOOKING_SETTINGS_FAIL = "[BOOKING_SETTINGS] Create Fail";
export const CREATE_BOOKING_SETTINGS_RESET = "[BOOKING_SETTINGS] Create Reset";

export const UPDATE_BOOKING_SETTINGS = "[BOOKING_SETTINGS] Update";
export const UPDATE_BOOKING_SETTINGS_SUCCESS = "[BOOKING_SETTINGS] Update Success";
export const UPDATE_BOOKING_SETTINGS_FAIL = "[BOOKING_SETTINGS] Update Fail";
export const UPDATE_BOOKING_SETTINGS_RESET = "[BOOKING_SETTINGS] Update Reset";

export const REMOVE_BOOKING_SETTINGS = "[BOOKING_SETTINGS] Remove";
export const REMOVE_BOOKING_SETTINGS_SUCCESS = "[BOOKING_SETTINGS] Remove Success";
export const REMOVE_BOOKING_SETTINGS_FAIL = "[BOOKING_SETTINGS] Remove Fail";
