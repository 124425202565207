import list from "@/store/core/list";
import crud from "@/store/core/crud";

import {
  GET_USER_SUPPORT_REQUESTS_LIST,
  GET_USER_SUPPORT_REQUESTS_LIST_SUCCESS,
  GET_USER_SUPPORT_REQUESTS_LIST_FAIL,
  GET_USER_SUPPORT_REQUESTS_PAGINATION,
  GET_USER_SUPPORT_REQUESTS_PAGINATION_SUCCESS,
  GET_USER_SUPPORT_REQUESTS_PAGINATION_FAIL,
  GET_USER_SUPPORT_REQUESTS_COLLECTION,
  GET_USER_SUPPORT_REQUESTS_COLLECTION_SUCCESS,
  GET_USER_SUPPORT_REQUESTS_COLLECTION_FAIL,
  GET_USER_SUPPORT_REQUESTS_SINGLE,
  GET_USER_SUPPORT_REQUESTS_SINGLE_SUCCESS,
  GET_USER_SUPPORT_REQUESTS_SINGLE_FAIL,
  CREATE_USER_SUPPORT_REQUESTS,
  CREATE_USER_SUPPORT_REQUESTS_SUCCESS,
  CREATE_USER_SUPPORT_REQUESTS_FAIL,
  CREATE_USER_SUPPORT_REQUESTS_RESET,
  UPDATE_USER_SUPPORT_REQUESTS,
  UPDATE_USER_SUPPORT_REQUESTS_SUCCESS,
  UPDATE_USER_SUPPORT_REQUESTS_FAIL,
  UPDATE_USER_SUPPORT_REQUESTS_RESET,
  REPLACE_USER_SUPPORT_REQUESTS,
  REPLACE_USER_SUPPORT_REQUESTS_SUCCESS,
  REPLACE_USER_SUPPORT_REQUESTS_FAIL,
  REPLACE_USER_SUPPORT_REQUESTS_RESET,
  REMOVE_USER_SUPPORT_REQUESTS,
  REMOVE_USER_SUPPORT_REQUESTS_SUCCESS,
  REMOVE_USER_SUPPORT_REQUESTS_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_USER_SUPPORT_REQUESTS_LIST,
    endPoint: "user-support-requests/list",
    mutationFail: GET_USER_SUPPORT_REQUESTS_LIST_FAIL,
    mutationSuccess: GET_USER_SUPPORT_REQUESTS_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_USER_SUPPORT_REQUESTS_PAGINATION,
    endPoint: "user-support-requests/paginated",
    mutationFail: GET_USER_SUPPORT_REQUESTS_PAGINATION_FAIL,
    mutationSuccess: GET_USER_SUPPORT_REQUESTS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_USER_SUPPORT_REQUESTS_COLLECTION,
    endPoint: "user-support-requests/collected",
    mutationFail: GET_USER_SUPPORT_REQUESTS_COLLECTION_FAIL,
    mutationSuccess: GET_USER_SUPPORT_REQUESTS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_USER_SUPPORT_REQUESTS_SINGLE,
    endPoint: "user-support-requests/single",
    mutationFail: GET_USER_SUPPORT_REQUESTS_SINGLE_FAIL,
    mutationSuccess: GET_USER_SUPPORT_REQUESTS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_USER_SUPPORT_REQUESTS,
    actionReset: CREATE_USER_SUPPORT_REQUESTS_RESET,
    endPoint: "user-support-requests/create",
    mutationFail: CREATE_USER_SUPPORT_REQUESTS_FAIL,
    mutationSuccess: CREATE_USER_SUPPORT_REQUESTS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_USER_SUPPORT_REQUESTS,
    actionReset: UPDATE_USER_SUPPORT_REQUESTS_RESET,
    endPoint: "user-support-requests/update",
    mutationFail: UPDATE_USER_SUPPORT_REQUESTS_FAIL,
    mutationSuccess: UPDATE_USER_SUPPORT_REQUESTS_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_USER_SUPPORT_REQUESTS,
    actionReset: REPLACE_USER_SUPPORT_REQUESTS_RESET,
    endPoint: "user-support-requests/replace",
    mutationFail: REPLACE_USER_SUPPORT_REQUESTS_FAIL,
    mutationSuccess: REPLACE_USER_SUPPORT_REQUESTS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_USER_SUPPORT_REQUESTS,
    endPoint: "user-support-requests/delete",
    mutationFail: REMOVE_USER_SUPPORT_REQUESTS_FAIL,
    mutationSuccess: REMOVE_USER_SUPPORT_REQUESTS_SUCCESS
  }
});

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
};

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
