import { mapState } from "vuex";

import { LOADING_SHOW, LOADING_HIDE } from "@/store/app/actions";

export default {
  data() {
    return {
      deferredPrompt: undefined,
    }
  },
  mounted() {
    if (typeof window !== undefined) {
      const installHandler = (event) => {
        if (this.isBeforeInstallPromptEvent(event)) {
          event.preventDefault();

          // Stash the event so it can be triggered later:
          this.deferredPrompt = event;
          // this.$emit("canInstall", event);
        }
      };

      window.addEventListener("beforeinstallprompt", installHandler);

      this.$once("hook:beforeDestroy", () => {
        window.removeEventListener("beforeinstallprompt", installHandler);
      });
    }
  },
  methods: {
    showAppLoading() {
      this.$store.dispatch(LOADING_SHOW);
    },
    hideAppLoading() {
      this.$store.dispatch(LOADING_HIDE);
    },
    isBeforeInstallPromptEvent(e) {
      return Boolean(e) && "prompt" in e;
    },
    promptInstall() {
      if (!this.deferredPrompt) return;

      // Show the prompt:
      this.deferredPrompt.prompt();

      // Wait for the user to respond to the prompt:
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }

        this.deferredPrompt = null;
      });
    }
  },
  computed: {
    ...mapState({
      appLoading: state => state.app.appLoading
    }),
  }
};
