export const GET_PROPERTIES_LIST = "[PROPERTIES] List Get";
export const GET_PROPERTIES_LIST_SUCCESS = "[PROPERTIES] List Get Success";
export const GET_PROPERTIES_LIST_FAIL = "[PROPERTIES] List Get Fail";

export const GET_PROPERTIES_PAGINATION = "[PROPERTIES] Pagination Get";
export const GET_PROPERTIES_PAGINATION_SUCCESS = "[PROPERTIES] Pagination Get Success";
export const GET_PROPERTIES_PAGINATION_FAIL = "[PROPERTIES] Pagination Get Fail";

export const GET_PROPERTIES_COLLECTION = "[PROPERTIES] Collection Get";
export const GET_PROPERTIES_COLLECTION_SUCCESS = "[PROPERTIES] Collection Get Success";
export const GET_PROPERTIES_COLLECTION_FAIL = "[PROPERTIES] Collection Get Fail";

export const GET_PROPERTIES_SINGLE = "[PROPERTIES] Single Get";
export const GET_PROPERTIES_SINGLE_SUCCESS = "[PROPERTIES] Single Get Success";
export const GET_PROPERTIES_SINGLE_FAIL = "[PROPERTIES] Single Get Fail";

export const CREATE_PROPERTIES = "[PROPERTIES] Create";
export const CREATE_PROPERTIES_SUCCESS = "[PROPERTIES] Create Success";
export const CREATE_PROPERTIES_FAIL = "[PROPERTIES] Create Fail";
export const CREATE_PROPERTIES_RESET = "[PROPERTIES] Create Reset";

export const UPDATE_PROPERTIES = "[PROPERTIES] Update";
export const UPDATE_PROPERTIES_SUCCESS = "[PROPERTIES] Update Success";
export const UPDATE_PROPERTIES_FAIL = "[PROPERTIES] Update Fail";
export const UPDATE_PROPERTIES_RESET = "[PROPERTIES] Update Reset";

export const REPLACE_PROPERTIES = "[PROPERTIES] Replace";
export const REPLACE_PROPERTIES_SUCCESS = "[PROPERTIES] Replace Success";
export const REPLACE_PROPERTIES_FAIL = "[PROPERTIES] Replace Fail";
export const REPLACE_PROPERTIES_RESET = "[PROPERTIES] Replace Reset";

export const REMOVE_PROPERTIES = "[PROPERTIES] Remove";
export const REMOVE_PROPERTIES_SUCCESS = "[PROPERTIES] Remove Success";
export const REMOVE_PROPERTIES_FAIL = "[PROPERTIES] Remove Fail";

export const RESEND_ACTIVATION = "[PROPERTIES] Resend Activation";
export const RESEND_ACTIVATION_SUCCESS = "[PROPERTIES] Resend Activation Success";
export const RESEND_ACTIVATION_FAIL = "[PROPERTIES] resend activation Fail";

export const TEST_SMTP_SETTINGS = "[PROPERTIES] Test Smtp Settings";
export const TEST_SMTP_SETTINGS_SUCCESS = "[PROPERTIES] Test Smtp Settings Success";
export const TEST_SMTP_SETTINGS_FAIL = "[PROPERTIES] Test Smtp Settings Fail";

export const REGENERATE_PROPERTY_RESERVATIONS_CITY_TAX = "[PROPERTIES] Regenerate Property Reservations Citytax";
export const REGENERATE_PROPERTY_RESERVATIONS_CITY_TAX_SUCCESS = "[PROPERTIES] Regenerate Property Reservations Citytax Success";
export const REGENERATE_PROPERTY_RESERVATIONS_CITY_TAX_FAIL = "[PROPERTIES] Regenerate Property Reservations Citytax Fail";
