export const GET_WORKERS_LIST = "[WORKERS] List Get";
export const GET_WORKERS_LIST_SUCCESS = "[WORKERS] List Get Success";
export const GET_WORKERS_LIST_FAIL = "[WORKERS] List Get Fail";

export const GET_WORKERS_PAGINATION = "[WORKERS] Pagination Get";
export const GET_WORKERS_PAGINATION_SUCCESS = "[WORKERS] Pagination Get Success";
export const GET_WORKERS_PAGINATION_FAIL = "[WORKERS] Pagination Get Fail";

export const GET_WORKERS_COLLECTION = "[WORKERS] Collection Get";
export const GET_WORKERS_COLLECTION_SUCCESS = "[WORKERS] Collection Get Success";
export const GET_WORKERS_COLLECTION_FAIL = "[WORKERS] Collection Get Fail";

export const GET_WORKERS_SINGLE = "[WORKERS] Single Get";
export const GET_WORKERS_SINGLE_SUCCESS = "[WORKERS] Single Get Success";
export const GET_WORKERS_SINGLE_FAIL = "[WORKERS] Single Get Fail";

export const CREATE_WORKERS = "[WORKERS] Create";
export const CREATE_WORKERS_SUCCESS = "[WORKERS] Create Success";
export const CREATE_WORKERS_FAIL = "[WORKERS] Create Fail";
export const CREATE_WORKERS_RESET = "[WORKERS] Create Reset";

export const UPDATE_WORKERS = "[WORKERS] Update";
export const UPDATE_WORKERS_SUCCESS = "[WORKERS] Update Success";
export const UPDATE_WORKERS_FAIL = "[WORKERS] Update Fail";
export const UPDATE_WORKERS_RESET = "[WORKERS] Update Reset";

export const REMOVE_WORKERS = "[WORKERS] Remove";
export const REMOVE_WORKERS_SUCCESS = "[WORKERS] Remove Success";
export const REMOVE_WORKERS_FAIL = "[WORKERS] Remove Fail";
