import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";
import ApiService from "@/services/api";

import {
  GET_POLICE_ACCOUNTS_PAGINATION,
  GET_POLICE_ACCOUNTS_PAGINATION_SUCCESS,
  GET_POLICE_ACCOUNTS_PAGINATION_FAIL,
  GET_POLICE_ACCOUNTS_COLLECTION,
  GET_POLICE_ACCOUNTS_COLLECTION_SUCCESS,
  GET_POLICE_ACCOUNTS_COLLECTION_FAIL,
  GET_POLICE_ACCOUNTS_SINGLE,
  GET_POLICE_ACCOUNTS_SINGLE_SUCCESS,
  GET_POLICE_ACCOUNTS_SINGLE_FAIL,
  CREATE_POLICE_ACCOUNTS,
  CREATE_POLICE_ACCOUNTS_SUCCESS,
  CREATE_POLICE_ACCOUNTS_FAIL,
  CREATE_POLICE_ACCOUNTS_RESET,
  UPDATE_POLICE_ACCOUNTS,
  UPDATE_POLICE_ACCOUNTS_SUCCESS,
  UPDATE_POLICE_ACCOUNTS_FAIL,
  UPDATE_POLICE_ACCOUNTS_RESET,
  REMOVE_POLICE_ACCOUNTS,
  REMOVE_POLICE_ACCOUNTS_SUCCESS,
  REMOVE_POLICE_ACCOUNTS_FAIL,
  VALIDATE_POLICE_ACCOUNT,
  VALIDATE_POLICE_ACCOUNT_SUCCESS,
  VALIDATE_POLICE_ACCOUNT_FAIL,
  VALIDATE_POLICE_ACCOUNT_RESET,
  GET_POLICE_ACCOUNT_RECEIPTS,
  GET_POLICE_ACCOUNT_RECEIPTS_SUCCESS,
  GET_POLICE_ACCOUNT_RECEIPTS_FAIL,
  GET_POLICE_ACCOUNT_RECEIPTS_RESET,
  DOWNLOAD_POLICE_ACCOUNT_RECEIPT,
  DOWNLOAD_POLICE_ACCOUNT_FILE,
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_POLICE_ACCOUNTS_PAGINATION,
    endPoint: "police-accounts/paginated",
    mutationFail: GET_POLICE_ACCOUNTS_PAGINATION_FAIL,
    mutationSuccess: GET_POLICE_ACCOUNTS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_POLICE_ACCOUNTS_COLLECTION,
    endPoint: "police-accounts/collected",
    mutationFail: GET_POLICE_ACCOUNTS_COLLECTION_FAIL,
    mutationSuccess: GET_POLICE_ACCOUNTS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_POLICE_ACCOUNTS_SINGLE,
    endPoint: "police-accounts/single",
    mutationFail: GET_POLICE_ACCOUNTS_SINGLE_FAIL,
    mutationSuccess: GET_POLICE_ACCOUNTS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_POLICE_ACCOUNTS,
    actionReset: CREATE_POLICE_ACCOUNTS_RESET,
    endPoint: "police-accounts/create",
    mutationFail: CREATE_POLICE_ACCOUNTS_FAIL,
    mutationSuccess: CREATE_POLICE_ACCOUNTS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_POLICE_ACCOUNTS,
    actionReset: UPDATE_POLICE_ACCOUNTS_RESET,
    endPoint: "police-accounts/update",
    mutationFail: UPDATE_POLICE_ACCOUNTS_FAIL,
    mutationSuccess: UPDATE_POLICE_ACCOUNTS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_POLICE_ACCOUNTS,
    endPoint: "police-accounts/delete",
    mutationFail: REMOVE_POLICE_ACCOUNTS_FAIL,
    mutationSuccess: REMOVE_POLICE_ACCOUNTS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    name: 'getValidateRequest',
    action: VALIDATE_POLICE_ACCOUNT,
    actionReset: VALIDATE_POLICE_ACCOUNT_RESET,
    endPoint: 'police-accounts/validate',
    mutations: {
      fail: VALIDATE_POLICE_ACCOUNT_FAIL,
      success: VALIDATE_POLICE_ACCOUNT_SUCCESS
    }
  },
  {
    name: 'getReceiptsRequest',
    action: GET_POLICE_ACCOUNT_RECEIPTS,
    actionReset: GET_POLICE_ACCOUNT_RECEIPTS_RESET,
    endPoint: 'police-accounts/receipts/{id}',
    mutations: {
      fail: GET_POLICE_ACCOUNT_RECEIPTS_FAIL,
      success: GET_POLICE_ACCOUNT_RECEIPTS_SUCCESS
    }
  }
]);

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  [DOWNLOAD_POLICE_ACCOUNT_RECEIPT](context, id) {
    ApiService.download(`police-accounts/receipts/download/${id}`, true);
  },
  [DOWNLOAD_POLICE_ACCOUNT_FILE](context, data) {
    ApiService.download(`police-accounts/file/download/${data.id}?from=${data.from}&to=${data.to}`, true);
  },
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
