import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_BOOKING_POLICY_COLLECTION,
  GET_BOOKING_POLICY_COLLECTION_FAIL,
  GET_BOOKING_POLICY_COLLECTION_SUCCESS,
  CREATE_BOOKING_POLICY,
  CREATE_BOOKING_POLICY_FAIL,
  CREATE_BOOKING_POLICY_RESET,
  CREATE_BOOKING_POLICY_SUCCESS,
  UPDATE_BOOKING_POLICY,
  UPDATE_BOOKING_POLICY_FAIL,
  UPDATE_BOOKING_POLICY_RESET,
  UPDATE_BOOKING_POLICY_SUCCESS,
  REMOVE_BOOKING_POLICY,
  REMOVE_BOOKING_POLICY_FAIL,
  REMOVE_BOOKING_POLICY_SUCCESS,
} from "./actions";

const crudProcessed = crud.process({
  getCollectionRequest: {
    action: GET_BOOKING_POLICY_COLLECTION,
    endPoint: "booking-policy/collected",
    mutationFail: GET_BOOKING_POLICY_COLLECTION_FAIL,
    mutationSuccess: GET_BOOKING_POLICY_COLLECTION_SUCCESS
  },
  creationRequest: {
    action: CREATE_BOOKING_POLICY,
    actionReset: CREATE_BOOKING_POLICY_RESET,
    endPoint: "booking-policy/create",
    mutationFail: CREATE_BOOKING_POLICY_FAIL,
    mutationSuccess: CREATE_BOOKING_POLICY_SUCCESS
  },
  editingRequest: {
    action: UPDATE_BOOKING_POLICY,
    actionReset: UPDATE_BOOKING_POLICY_RESET,
    endPoint: "booking-policy/update",
    mutationFail: UPDATE_BOOKING_POLICY_FAIL,
    mutationSuccess: UPDATE_BOOKING_POLICY_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_BOOKING_POLICY,
    endPoint: "booking-policy/delete",
    mutationFail: REMOVE_BOOKING_POLICY_FAIL,
    mutationSuccess: REMOVE_BOOKING_POLICY_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([]);

const actions = {
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
