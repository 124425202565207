<template>
	<div>
    <v-menu offset-y :disabled="readOnly">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :color="color"
          dark
          v-bind="attrs"
          v-on="on"
          :small="small"
          :x-small="xSmall"
          :block="block"
        >
          <v-icon v-if="!readOnly">mdi-menu-down</v-icon>
          <v-icon :small="small" :x-small="xSmall" class="mr-1">{{ getMealPlanIcon(item) }}</v-icon>
          {{ text }}
        </v-btn>
      </template>
      <v-list>
        <template v-for="(item, index) in $in('ConstMealPlans')">
          <v-list-item :key="index" @click="setValue(item.value)">
            <v-list-item-icon>
              <v-icon :small="small" :x-small="xSmall" :color="getMealPlanColor(item.value)">{{ getMealPlanIcon(item.value) }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import format from "@/mixins/meal-plans";
export default {
	name: "CMeatPlanMenu",
  mixins: [
    format
  ],
	props: {
    label: {
      type: String,
      default: '',
    },
		value: {
      default: null
    },
    small: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    }
	},
  data() {
    return {
      item: null,
    };
  },
  mounted() {
    this.item = this.value;
  },
  methods: {
    change() {
			this.$emit("change", this.item);
		},
    blur() {
      this.$emit("blur", this.item);
    },
    setValue(val) {
      this.item = val;
    }
  },
  computed: {
    color() {
      if (this.item === null) return;

      return this.$const('ConstMealPlans', 'ROOM_ONLY') === this.item ? 'error' : 'success';
    },
    text() {
      if (this.item === null) {
        return this.label;
      }

      return this.$constText('ConstMealPlans', this.item);
    }
  },
  watch: {
    item(newVal) {
      this.$emit('input', newVal);
    },
    value(val) {
      this.item = val;
    }
  }
}
</script>
