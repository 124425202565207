export const GET_POLICE_ACCOUNTS_PAGINATION = "[POLICE_ACCOUNTS] Pagination Get";
export const GET_POLICE_ACCOUNTS_PAGINATION_SUCCESS = "[POLICE_ACCOUNTS] Pagination Get Success";
export const GET_POLICE_ACCOUNTS_PAGINATION_FAIL = "[POLICE_ACCOUNTS] Pagination Get Fail";

export const GET_POLICE_ACCOUNTS_COLLECTION = "[POLICE_ACCOUNTS] Collection Get";
export const GET_POLICE_ACCOUNTS_COLLECTION_SUCCESS = "[POLICE_ACCOUNTS] Collection Get Success";
export const GET_POLICE_ACCOUNTS_COLLECTION_FAIL = "[POLICE_ACCOUNTS] Collection Get Fail";

export const GET_POLICE_ACCOUNTS_SINGLE = "[POLICE_ACCOUNTS] Single Get";
export const GET_POLICE_ACCOUNTS_SINGLE_SUCCESS = "[POLICE_ACCOUNTS] Single Get Success";
export const GET_POLICE_ACCOUNTS_SINGLE_FAIL = "[POLICE_ACCOUNTS] Single Get Fail";

export const CREATE_POLICE_ACCOUNTS = "[POLICE_ACCOUNTS] Create";
export const CREATE_POLICE_ACCOUNTS_SUCCESS = "[POLICE_ACCOUNTS] Create Success";
export const CREATE_POLICE_ACCOUNTS_FAIL = "[POLICE_ACCOUNTS] Create Fail";
export const CREATE_POLICE_ACCOUNTS_RESET = "[POLICE_ACCOUNTS] Create Reset";

export const UPDATE_POLICE_ACCOUNTS = "[POLICE_ACCOUNTS] Update";
export const UPDATE_POLICE_ACCOUNTS_SUCCESS = "[POLICE_ACCOUNTS] Update Success";
export const UPDATE_POLICE_ACCOUNTS_FAIL = "[POLICE_ACCOUNTS] Update Fail";
export const UPDATE_POLICE_ACCOUNTS_RESET = "[POLICE_ACCOUNTS] Update Reset";

export const REMOVE_POLICE_ACCOUNTS = "[POLICE_ACCOUNTS] Remove";
export const REMOVE_POLICE_ACCOUNTS_SUCCESS = "[POLICE_ACCOUNTS] Remove Success";
export const REMOVE_POLICE_ACCOUNTS_FAIL = "[POLICE_ACCOUNTS] Remove Fail";

export const VALIDATE_POLICE_ACCOUNT = "[POLICE_ACCOUNTS] Validate";
export const VALIDATE_POLICE_ACCOUNT_SUCCESS = "[POLICE_ACCOUNTS] Validate Success";
export const VALIDATE_POLICE_ACCOUNT_FAIL = "[POLICE_ACCOUNTS] Validate Fail";
export const VALIDATE_POLICE_ACCOUNT_RESET = "[POLICE_ACCOUNTS] Validate Reset";

export const GET_POLICE_ACCOUNT_RECEIPTS = "[POLICE_ACCOUNTS] Receipts";
export const GET_POLICE_ACCOUNT_RECEIPTS_SUCCESS = "[POLICE_ACCOUNTS] Receipts Success";
export const GET_POLICE_ACCOUNT_RECEIPTS_FAIL = "[POLICE_ACCOUNTS] Receipts Fail";
export const GET_POLICE_ACCOUNT_RECEIPTS_RESET = "[POLICE_ACCOUNTS] Receipts Reset";

export const DOWNLOAD_POLICE_ACCOUNT_RECEIPT = "[POLICE_ACCOUNTS] Download Receipt";

export const DOWNLOAD_POLICE_ACCOUNT_FILE = "[POLICE_ACCOUNTS] Download File";
