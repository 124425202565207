export default [
  {
    name: "unit-category-costs",
    path: "/properties/:id/unit-category-costs",
    component: () => import(/*webpackChunkName: "properties" */ "@/pages/unit-category-costs/Index"),
    meta: {
      breadcrumbs: [
        { text: 'titles.properties', to: 'properties', params: true },
        { text: 'titles.property_settings', to: 'property-settings' },
        { text: 'titles.property_cost_settings', to: 'property-cost-settings' },
      ],
      title: 'titles.unit_category_costs'
    }
  }
];
