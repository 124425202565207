import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";
import ApiService from "@/services/api";

import {
  GET_COMPANY_INVOICES_LIST,
  GET_COMPANY_INVOICES_LIST_SUCCESS,
  GET_COMPANY_INVOICES_LIST_FAIL,
  CREATE_COMPANY_INVOICES,
  CREATE_COMPANY_INVOICES_FAIL,
  CREATE_COMPANY_INVOICES_RESET,
  CREATE_COMPANY_INVOICES_SUCCESS,
  GET_COMPANY_INVOICES_COLLECTION,
  GET_COMPANY_INVOICES_COLLECTION_FAIL,
  GET_COMPANY_INVOICES_COLLECTION_SUCCESS,
  GET_COMPANY_INVOICES_SINGLE,
  GET_COMPANY_INVOICES_SINGLE_FAIL,
  GET_COMPANY_INVOICES_SINGLE_SUCCESS,
  REMOVE_COMPANY_INVOICES,
  REMOVE_COMPANY_INVOICES_FAIL,
  REMOVE_COMPANY_INVOICES_SUCCESS,
  UPDATE_COMPANY_INVOICES,
  UPDATE_COMPANY_INVOICES_FAIL,
  UPDATE_COMPANY_INVOICES_RESET,
  UPDATE_COMPANY_INVOICES_SUCCESS,
  GET_COMPANY_INVOICES_PAGINATION,
  GET_COMPANY_INVOICES_PAGINATION_FAIL,
  GET_COMPANY_INVOICES_PAGINATION_SUCCESS,
  GET_COMPANY_INVOICES_SERIAL_N,
  GET_COMPANY_INVOICES_SERIAL_N_SUCCESS,
  GET_COMPANY_INVOICES_SERIAL_N_FAIL,
  EXPORT_XML_COMPANY_INVOICES,
  EXPORT_PDF_COMPANY_INVOICES,
  COMPANY_INVOICE_SEND_TO_SDI,
  COMPANY_INVOICE_SEND_TO_SDI_SUCCESS,
  COMPANY_INVOICE_SEND_TO_SDI_FAIL,
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_COMPANY_INVOICES_PAGINATION,
    endPoint: "company-invoices/paginated",
    mutationFail: GET_COMPANY_INVOICES_PAGINATION_FAIL,
    mutationSuccess: GET_COMPANY_INVOICES_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_COMPANY_INVOICES_COLLECTION,
    endPoint: "company-invoices/collected",
    mutationFail: GET_COMPANY_INVOICES_COLLECTION_FAIL,
    mutationSuccess: GET_COMPANY_INVOICES_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_COMPANY_INVOICES_SINGLE,
    endPoint: "company-invoices/single",
    mutationFail: GET_COMPANY_INVOICES_SINGLE_FAIL,
    mutationSuccess: GET_COMPANY_INVOICES_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_COMPANY_INVOICES,
    actionReset: CREATE_COMPANY_INVOICES_RESET,
    endPoint: "company-invoices/create",
    mutationFail: CREATE_COMPANY_INVOICES_FAIL,
    mutationSuccess: CREATE_COMPANY_INVOICES_SUCCESS
  },
  editingRequest: {
    action: UPDATE_COMPANY_INVOICES,
    actionReset: UPDATE_COMPANY_INVOICES_RESET,
    endPoint: "company-invoices/update",
    mutationFail: UPDATE_COMPANY_INVOICES_FAIL,
    mutationSuccess: UPDATE_COMPANY_INVOICES_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_COMPANY_INVOICES,
    endPoint: "company-invoices/delete",
    mutationFail: REMOVE_COMPANY_INVOICES_FAIL,
    mutationSuccess: REMOVE_COMPANY_INVOICES_SUCCESS
  }
});

const listProcessed = list.process({
  getListRequest: {
    action: GET_COMPANY_INVOICES_LIST,
    endPoint: "company-invoices/list",
    mutationFail: GET_COMPANY_INVOICES_LIST_FAIL,
    mutationSuccess: GET_COMPANY_INVOICES_LIST_SUCCESS
  },
});

const actionsProcessed = action.processMultiple([
  {
    name: 'getSerialNRequest',
    action: GET_COMPANY_INVOICES_SERIAL_N,
    endPoint: 'company-invoices/serial-n',
    mutations: {
      fail: GET_COMPANY_INVOICES_SERIAL_N_FAIL,
      success: GET_COMPANY_INVOICES_SERIAL_N_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'sendInvoiceToSdi',
    action: COMPANY_INVOICE_SEND_TO_SDI,
    endPoint: 'company-invoices/send-to-sdi/{id}',
    mutations: {
      fail: COMPANY_INVOICE_SEND_TO_SDI_FAIL,
      success: COMPANY_INVOICE_SEND_TO_SDI_SUCCESS,
    },
  },
]);

const getters = {};

const actions = {
  [EXPORT_XML_COMPANY_INVOICES](context, id) {
    ApiService.download(`company-invoices/export-xml/${id}`, true);
  },
  [EXPORT_PDF_COMPANY_INVOICES](context, id) {
    ApiService.download(`company-invoices/export-pdf/${id}`, true);
  },
  ...crudProcessed.actions,
  ...listProcessed.actions,
  ...actionsProcessed.actions,
};

const state = {
  ...crudProcessed.state,
  ...listProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...crudProcessed.mutations,
  ...listProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
