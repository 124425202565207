<template>
    <div v-if="editor" id="editor-container">
      <v-row>
        <v-col>
          <ReplacementTagsMenu
            v-if="withReplacementTags"
            :replacementTagsArray="replacementTagsArray"
            @input="insertTextAtCursor"
          />
          <v-btn
            v-if="withInputs"
            small @click="addInput"
            class="ml-1"
          >
            {{ this.$lang('property-contracts.editor.add_input') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <menu-bar class="editor__header" :editor="editor"></menu-bar>
          <editor-content class="editor__content" :class="error ? 'editor-error' : ''" :editor="editor" />
          <p v-if="error" class="v-messages">
            <span class="error--text" v-for="(message, m) in errorMessages" :key="m">
              {{message}}
            </span>
          </p>
          <p v-if="hint" class="v-messages">
            <span class="primary--text">
              {{ hint }}
            </span>
          </p>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import {
  Editor,
  EditorContent,
} from "@tiptap/vue-2";

import ReplacementTagsMenu from './ReplacementTagsMenu.vue';


import StarterKit from '@tiptap/starter-kit';
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import FontFamily from "@tiptap/extension-font-family";
import Placeholder from '@tiptap/extension-placeholder';
import Link from '@tiptap/extension-link';
// import Image from '@tiptap/extension-image';
import Table from '@tiptap/extension-table';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
// import Color from '@tiptap/extension-color';
import ImageResize from 'tiptap-extension-resize-image';

import { CtaButton } from './CustomNodes/CtaButton';
import { Fullscreen } from './Extensions/Fullscreen';

import MenuBar from "./MenuBar";

export default {
  name: "Tiptap",
  components: {
    EditorContent,
    MenuBar,
    ReplacementTagsMenu,
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    error: {
      default: false
    },
    errorMessages: {
      default: () => []
    },
    allowBase64: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null,
    },
    propertyId: {
      type: Number,
      required: false
    },
    withReplacementTags: {
      type: Boolean,
      required: false,
      default: false
    },
    replacementTagsArray: {
      type: Array,
      required: false
    },
    withInputs: {
      type: Boolean,
      required: false,
      default: false
    },
    reservationId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      editor: null,
      inputCount: 1,
    };
  },
  mounted() {
    this.initEditor();
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    initEditor() {
      this.editor = new Editor({
        content: this.value,
        extensions: [
          StarterKit,
          TextAlign.configure({
            alignments: ['left', 'center', 'right', 'justify'],
            types: ['heading', 'paragraph']
          }),
          Link.configure({
            autolink: true,
            linkOnPaste: true,
          }),
          Placeholder.configure({
            placeholder: this.label,
          }),
          // Image.configure({
          //   inline: false,
          //   allowBase64: this.allowBase64,
          // }),
          Fullscreen,
          ImageResize,
          Table.configure({
            resizable: true,
          }),
          TableRow,
          TableHeader,
          TableCell,
          TextStyle,
          // Color,
          FontFamily,
          CtaButton,
        ],
        onUpdate: () => {
          const content = this.editor.getText() ? this.editor.getHTML() : '';
          this.$emit("input", content);
        }
      });
    },
    insertTextAtCursor(text) {
      const { state, view } = this.editor;
      const { from, to } = state.selection;
      view.dispatch(view.state.tr.insertText(text, from, to));
      view.focus();
    },
    addInput() {
      const fieldName = prompt(this.$lang('property-contracts.editor.placeholder'));
      const fieldSize = prompt(this.$lang('property-contracts.editor.size'));
      this.insertTextAtCursor(`[input:${this.getMaxInputNumber() + 1}:${fieldName}:${fieldSize}]`)
    },
    getMaxInputNumber() {
      const inputPattern = /\[input:(\d+):([^:]*):(\d+)\]/g;
      let match;
      let maxNumber = 0;
      const text = this.editor.getHTML();

      while ((match = inputPattern.exec(text)) !== null) {
        const number = parseInt(match[1], 10);
        if (number > maxNumber) {
          maxNumber = number;
        }
      }

      return maxNumber;
    }
  },
  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value;

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    }
  },
};
</script>

<style lang="scss">
#editor-container {
  position: relative;
}

#editor-container.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: white;
}

.editor {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.editor-error {
  border: 1px solid #b71c1c;
}
.editor__header {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  flex-wrap: wrap;
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.editor__content {
  flex: 1 1 auto;
}
.editor__footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.editor .menu-item {
  width: 1.75rem;
  height: 1.75rem;
  color: #0d0d0d;
  border: none;
  background-color: transparent;
  border-radius: 0.4rem;
  padding: 0.25rem;
  margin-right: 0.25rem;
}
.editor .menu-item svg {
  width: 100%;
  height: 100%;
  fill: currentColor;
}
.editor .menu-item.is-active,
.editor .menu-item:hover {
  color: #fff;
  background-color: #0d0d0d;
}
.editor .menu-item.is-disabled {
  color: #d1d5db;
}
.editor .divider {
  width: 2px;
  height: 1.25rem;
  background-color: rgba(0, 0, 0, 0.1);
  margin-left: 0.5rem;
  margin-right: 0.75rem;
}
.editor .bubble-menu {
  display: flex;
  background-color: #374151;
  padding: 0.2rem;
  border-radius: 0.5rem;
}
.editor .bubble-menu button {
  border: none;
  background: none;
  font-size: 0.85rem;
  font-weight: 500;
  padding: 0 0.2rem;
  color: #fff;
  opacity: 0.8;
}
.editor .bubble-menu button.is-active,
.editor .bubble-menu button:hover {
  opacity: 1;
}
.editor .floating-menu {
  display: flex;
  background-color: rgba(13, 13, 13, 0.06274509803921569);
  padding: 0.2rem;
  border: 0;
  border-radius: 0.5rem;
}
.editor .floating-menu button {
  border: none;
  background: none;
  font-size: 0.85rem;
  font-weight: 500;
  padding: 0 0.2rem;
  opacity: 0.6;
}
.editor .floating-menu button.is-active,
.editor .floating-menu button:hover {
  opacity: 1;
}
.ProseMirror {
  padding: 0 1rem;
  min-height: 6rem;
  max-height: 35rem;
  overflow-y: scroll;
  background-color: rgba(255, 255, 255);
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;

  > * + * {
    margin-top: 0.75em;
  }

  img {
    max-width: 100%;
    height: auto;

    &.ProseMirror-selectednode {
      outline: 3px solid #68CEF8;
    }
  }
}
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}
.image-resizer {
    display: inline-flex;
    position: relative;
    flex-grow: 0;
  }
.image-resizer .resize-trigger {
  position: absolute;
  right: -6px;
  bottom: -9px;
  opacity: 0;
  transition: opacity .3s ease;
  color: #3259a5;
}
.image-resizer:hover .resize-trigger {
  opacity: 1;
}
.tiptap {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px dashed #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0; right: 0; top: 0; bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
.email-container {
  background-color: grey;
  padding: 20px;
  display: flex;
  justify-content: center;
}
.email-content {
  background-color: white;
  padding: 20px;
  width: 600px;
}
</style>
