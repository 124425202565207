<template>
  <v-container fluid>
    <v-row>
      <v-col xs="12" md="6" offset-md="3">
        <v-card >
          <v-card-title class="error white--text">
            PAGE NOT FOUND
          </v-card-title>
          <v-card-text align="center">
            <v-icon x-large class="ma-4" color="error">mdi-alert</v-icon>
            <div class="title">The page you are looking for doesn't exist.</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" :to="{ name: 'dashboard' }">Go back to Dashboard</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "NotFound"
};
</script>

<style scoped></style>
