import ApiService from "@/services/api";
import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  INHERIT_BOOKING_PAYMENT_PROVIDERS,
  INHERIT_BOOKING_PAYMENT_PROVIDERS_FAIL,
  INHERIT_BOOKING_PAYMENT_PROVIDERS_SUCCESS,
  CREATE_BOOKING_PAYMENT_PROVIDERS,
  CREATE_BOOKING_PAYMENT_PROVIDERS_FAIL,
  CREATE_BOOKING_PAYMENT_PROVIDERS_RESET,
  CREATE_BOOKING_PAYMENT_PROVIDERS_SUCCESS,
  CREATE_BOOKING_PAYMENT_STRIPE_AUTH,
  CREATE_BOOKING_PAYMENT_STRIPE_AUTH_FAIL,
  CREATE_BOOKING_PAYMENT_STRIPE_AUTH_SUCCESS,
  GET_BOOKING_PAYMENT_PROVIDERS_COLLECTION,
  GET_BOOKING_PAYMENT_PROVIDERS_COLLECTION_FAIL,
  GET_BOOKING_PAYMENT_PROVIDERS_COLLECTION_SUCCESS,
  GET_BOOKING_PAYMENT_PROVIDERS_PAGINATION,
  GET_BOOKING_PAYMENT_PROVIDERS_PAGINATION_FAIL,
  GET_BOOKING_PAYMENT_PROVIDERS_PAGINATION_SUCCESS,
  GET_BOOKING_PAYMENT_PROVIDERS_SINGLE,
  GET_BOOKING_PAYMENT_PROVIDERS_SINGLE_FAIL,
  GET_BOOKING_PAYMENT_PROVIDERS_SINGLE_SUCCESS,
  GET_BOOKING_PAYMENT_STRIPE_AUTH_URL,
  GET_BOOKING_PAYMENT_STRIPE_AUTH_URL_FAIL,
  GET_BOOKING_PAYMENT_STRIPE_AUTH_URL_SUCCESS,
  REMOVE_BOOKING_PAYMENT_PROVIDERS,
  REMOVE_BOOKING_PAYMENT_PROVIDERS_FAIL,
  REMOVE_BOOKING_PAYMENT_PROVIDERS_SUCCESS,
  REMOVE_BOOKING_PAYMENT_STRIPE_AUTH,
  UPDATE_BOOKING_PAYMENT_PROVIDERS,
  UPDATE_BOOKING_PAYMENT_PROVIDERS_FAIL,
  UPDATE_BOOKING_PAYMENT_PROVIDERS_RESET,
  UPDATE_BOOKING_PAYMENT_PROVIDERS_SUCCESS,
  OVERRIDE_BOOKING_PAYMENT_PROVIDERS,
  OVERRIDE_BOOKING_PAYMENT_PROVIDERS_SUCCESS,
  OVERRIDE_BOOKING_PAYMENT_PROVIDERS_FAIL,
  REMOVE_BOOKING_PAYMENT_STRIPE_AUTH_SUCCESS,
  REMOVE_BOOKING_PAYMENT_STRIPE_AUTH_FAIL,
  POSITION_BOOKING_PAYMENT_PROVIDERS,
  POSITION_BOOKING_PAYMENT_PROVIDERS_SUCCESS,
  POSITION_BOOKING_PAYMENT_PROVIDERS_FAIL,
  CREATE_BOOKING_PAYMENT_STRIPE_TERMINAL_ACCESS_TOKEN,
  CREATE_BOOKING_PAYMENT_STRIPE_TERMINAL_ACCESS_TOKEN_SUCCESS,
  CREATE_BOOKING_PAYMENT_STRIPE_TERMINAL_ACCESS_TOKEN_FAIL,
  GET_BOOKING_PAYMENT_STRIPE_TERMINAL_LOCATIONS,
  GET_BOOKING_PAYMENT_STRIPE_TERMINAL_LOCATIONS_SUCCESS,
  GET_BOOKING_PAYMENT_STRIPE_TERMINAL_LOCATIONS_FAIL,
} from "@/store/booking-payment-providers/actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_BOOKING_PAYMENT_PROVIDERS_PAGINATION,
    endPoint: "booking-payment-providers/paginated",
    mutationFail: GET_BOOKING_PAYMENT_PROVIDERS_PAGINATION_FAIL,
    mutationSuccess: GET_BOOKING_PAYMENT_PROVIDERS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_BOOKING_PAYMENT_PROVIDERS_COLLECTION,
    endPoint: "booking-payment-providers/collected",
    mutationFail: GET_BOOKING_PAYMENT_PROVIDERS_COLLECTION_FAIL,
    mutationSuccess: GET_BOOKING_PAYMENT_PROVIDERS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_BOOKING_PAYMENT_PROVIDERS_SINGLE,
    endPoint: "booking-payment-providers/single",
    mutationFail: GET_BOOKING_PAYMENT_PROVIDERS_SINGLE_FAIL,
    mutationSuccess: GET_BOOKING_PAYMENT_PROVIDERS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_BOOKING_PAYMENT_PROVIDERS,
    actionReset: CREATE_BOOKING_PAYMENT_PROVIDERS_RESET,
    endPoint: "booking-payment-providers/create",
    mutationFail: CREATE_BOOKING_PAYMENT_PROVIDERS_FAIL,
    mutationSuccess: CREATE_BOOKING_PAYMENT_PROVIDERS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_BOOKING_PAYMENT_PROVIDERS,
    actionReset: UPDATE_BOOKING_PAYMENT_PROVIDERS_RESET,
    endPoint: "booking-payment-providers/update",
    mutationFail: UPDATE_BOOKING_PAYMENT_PROVIDERS_FAIL,
    mutationSuccess: UPDATE_BOOKING_PAYMENT_PROVIDERS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_BOOKING_PAYMENT_PROVIDERS,
    endPoint: "booking-payment-providers/delete",
    mutationFail: REMOVE_BOOKING_PAYMENT_PROVIDERS_FAIL,
    mutationSuccess: REMOVE_BOOKING_PAYMENT_PROVIDERS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    method: 'post',
    name: 'positionRequest',
    action: POSITION_BOOKING_PAYMENT_PROVIDERS,
    endPoint: 'booking-payment-providers/position/{id}',
    mutations: {
      fail: POSITION_BOOKING_PAYMENT_PROVIDERS_FAIL,
      success: POSITION_BOOKING_PAYMENT_PROVIDERS_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'createStripeTerminalAccessTokenRequest',
    action: CREATE_BOOKING_PAYMENT_STRIPE_TERMINAL_ACCESS_TOKEN,
    endPoint: 'booking-payment-providers/stripe/terminal/access-token',
    mutations: {
      fail: CREATE_BOOKING_PAYMENT_STRIPE_TERMINAL_ACCESS_TOKEN_FAIL,
      success: CREATE_BOOKING_PAYMENT_STRIPE_TERMINAL_ACCESS_TOKEN_SUCCESS,
    },
  },
  {
    name: 'getStripeTerminalLocationsRequest',
    action: GET_BOOKING_PAYMENT_STRIPE_TERMINAL_LOCATIONS,
    endPoint: 'booking-payment-providers/stripe/terminal/locations',
    mutations: {
      fail: GET_BOOKING_PAYMENT_STRIPE_TERMINAL_LOCATIONS_FAIL,
      success: GET_BOOKING_PAYMENT_STRIPE_TERMINAL_LOCATIONS_SUCCESS,
    },
  },
]);

const actions = {
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
  [GET_BOOKING_PAYMENT_STRIPE_AUTH_URL](context, {owner_type, owner_id}){
    context.state.stripe.loading = true;
    return new Promise(resolve => {
      ApiService.get("booking-payment-providers/stripe-authorize", `${owner_type}/${owner_id}`)
        .then(response => {
          context.commit(GET_BOOKING_PAYMENT_STRIPE_AUTH_URL_SUCCESS, response.data.data);
          resolve(response.data.data)
        })
        .catch(({ response }) => {
          context.commit(GET_BOOKING_PAYMENT_STRIPE_AUTH_URL_FAIL, response.data);
        });
    });
  },
  [CREATE_BOOKING_PAYMENT_STRIPE_AUTH](context, payload){
    return new Promise(resolve => {
      ApiService.post("booking-payment-providers/stripe-connect", payload)
        .then(response => {
          context.commit(CREATE_BOOKING_PAYMENT_STRIPE_AUTH_SUCCESS, response.data.data);
          resolve(response.data.data)
        })
        .catch(({ response }) => {
          context.commit(CREATE_BOOKING_PAYMENT_STRIPE_AUTH_FAIL, response.data);
        });
    });
  },
  [REMOVE_BOOKING_PAYMENT_STRIPE_AUTH](context, payload){
    context.state.stripe.loading = true;
    return new Promise(resolve => {
      ApiService.post("booking-payment-providers/stripe-deauthorize", payload)
        .then(response => {
          context.commit(REMOVE_BOOKING_PAYMENT_STRIPE_AUTH_SUCCESS, response.data.data);
          resolve(response.data.data)
        })
        .catch(({ response }) => {
          context.commit(REMOVE_BOOKING_PAYMENT_STRIPE_AUTH_FAIL, response.data);
        });
    });
  },
  [INHERIT_BOOKING_PAYMENT_PROVIDERS](context, payload){
    context.state.inherit.loading = true;
    return new Promise(resolve => {
      ApiService.post("booking-payment-providers/inherit", payload)
        .then(response => {
          context.commit(INHERIT_BOOKING_PAYMENT_PROVIDERS_SUCCESS, response.data.data);
          resolve(response.data.data)
        })
        .catch(({ response }) => {
          context.commit(INHERIT_BOOKING_PAYMENT_PROVIDERS_FAIL, response.data);
        });
    });
  },
  [OVERRIDE_BOOKING_PAYMENT_PROVIDERS](context, payload){
    context.state.inherit.loading = true;
    return new Promise(resolve => {
      ApiService.post("booking-payment-providers/override", payload)
        .then(response => {
          context.commit(OVERRIDE_BOOKING_PAYMENT_PROVIDERS_SUCCESS, response.data.data);
          resolve(response.data.data)
        })
        .catch(({ response }) => {
          context.commit(OVERRIDE_BOOKING_PAYMENT_PROVIDERS_FAIL, response.data);
        });
    });
  }
};

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
  stripe: {
    data: null,
    loading: false,
    success: false,
    failed: false,
    errors: {}
  },
  inherit: {
    loading: false,
    success: false,
    failed: false,
    errors: {}
  }
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
  [GET_BOOKING_PAYMENT_STRIPE_AUTH_URL_SUCCESS](state, payload) {
    state.stripe = {
      data: payload,
      success: true,
      failed: false,
      errors: {}
    }
  },
  [GET_BOOKING_PAYMENT_STRIPE_AUTH_URL_FAIL](state, payload) {
    state.stripe = {
      data: null,
      success: false,
      failed: true,
      errors: payload
    }
  },
  [CREATE_BOOKING_PAYMENT_STRIPE_AUTH_SUCCESS](state, payload) {
    state.stripe = {
      data: payload,
      success: true,
      failed: false,
      errors: {}
    }
  },
  [CREATE_BOOKING_PAYMENT_STRIPE_AUTH_FAIL](state, payload) {
    state.stripe = {
      data: null,
      success: false,
      failed: true,
      errors: payload
    }
  },
  [REMOVE_BOOKING_PAYMENT_STRIPE_AUTH_SUCCESS](state, payload) {
    state.stripe = {
      data: payload,
      success: true,
      failed: false,
      errors: {}
    }
  },
  [REMOVE_BOOKING_PAYMENT_STRIPE_AUTH_FAIL](state, payload) {
    state.stripe = {
      data: null,
      success: false,
      failed: true,
      errors: payload
    }
  },
  [INHERIT_BOOKING_PAYMENT_PROVIDERS_SUCCESS](state, payload) {
    state.inherit = {
      loading: false,
      success: true,
      failed: false,
      errors: {}
    }
  },
  [INHERIT_BOOKING_PAYMENT_PROVIDERS_FAIL](state, payload) {
    state.inherit = {
      loading: false,
      success: false,
      failed: true,
      errors: payload
    }
  },
  [OVERRIDE_BOOKING_PAYMENT_PROVIDERS_SUCCESS](state, payload) {
    state.inherit = {
      loading: false,
      success: true,
      failed: false,
      errors: {}
    }
  },
  [OVERRIDE_BOOKING_PAYMENT_PROVIDERS_FAIL](state, payload) {
    state.inherit = {
      loading: false,
      success: false,
      failed: true,
      errors: payload
    }
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
