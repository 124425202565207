export default [
  {
    name: "calendars",
    path: "/calendars",
    component: () => import(/*webpackChunkName: "calendars" */ "@/pages/calendars/Index"),
    meta: {
      title: 'titles.calendar_events'
    }
  },
];
