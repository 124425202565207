export const GET_PROPERTY_REVENUE_SHARE_RULES_LIST = "[PROPERTY_REVENUE_SHARE_RULES] List Get";
export const GET_PROPERTY_REVENUE_SHARE_RULES_LIST_SUCCESS = "[PROPERTY_REVENUE_SHARE_RULES] List Get Success";
export const GET_PROPERTY_REVENUE_SHARE_RULES_LIST_FAIL = "[PROPERTY_REVENUE_SHARE_RULES] List Get Fail";

export const GET_PROPERTY_REVENUE_SHARE_RULES_PAGINATION = "[PROPERTY_REVENUE_SHARE_RULES] Pagination Get";
export const GET_PROPERTY_REVENUE_SHARE_RULES_PAGINATION_SUCCESS = "[PROPERTY_REVENUE_SHARE_RULES] Pagination Get Success";
export const GET_PROPERTY_REVENUE_SHARE_RULES_PAGINATION_FAIL = "[PROPERTY_REVENUE_SHARE_RULES] Pagination Get Fail";

export const GET_PROPERTY_REVENUE_SHARE_RULES_COLLECTION = "[PROPERTY_REVENUE_SHARE_RULES] Collection Get";
export const GET_PROPERTY_REVENUE_SHARE_RULES_COLLECTION_SUCCESS = "[PROPERTY_REVENUE_SHARE_RULES] Collection Get Success";
export const GET_PROPERTY_REVENUE_SHARE_RULES_COLLECTION_FAIL = "[PROPERTY_REVENUE_SHARE_RULES] Collection Get Fail";

export const GET_PROPERTY_REVENUE_SHARE_RULES_SINGLE = "[PROPERTY_REVENUE_SHARE_RULES] Single Get";
export const GET_PROPERTY_REVENUE_SHARE_RULES_SINGLE_SUCCESS = "[PROPERTY_REVENUE_SHARE_RULES] Single Get Success";
export const GET_PROPERTY_REVENUE_SHARE_RULES_SINGLE_FAIL = "[PROPERTY_REVENUE_SHARE_RULES] Single Get Fail";

export const CREATE_PROPERTY_REVENUE_SHARE_RULES = "[PROPERTY_REVENUE_SHARE_RULES] Create";
export const CREATE_PROPERTY_REVENUE_SHARE_RULES_SUCCESS = "[PROPERTY_REVENUE_SHARE_RULES] Create Success";
export const CREATE_PROPERTY_REVENUE_SHARE_RULES_FAIL = "[PROPERTY_REVENUE_SHARE_RULES] Create Fail";
export const CREATE_PROPERTY_REVENUE_SHARE_RULES_RESET = "[PROPERTY_REVENUE_SHARE_RULES] Create Reset";

export const UPDATE_PROPERTY_REVENUE_SHARE_RULES = "[PROPERTY_REVENUE_SHARE_RULES] Update";
export const UPDATE_PROPERTY_REVENUE_SHARE_RULES_SUCCESS = "[PROPERTY_REVENUE_SHARE_RULES] Update Success";
export const UPDATE_PROPERTY_REVENUE_SHARE_RULES_FAIL = "[PROPERTY_REVENUE_SHARE_RULES] Update Fail";
export const UPDATE_PROPERTY_REVENUE_SHARE_RULES_RESET = "[PROPERTY_REVENUE_SHARE_RULES] Update Reset";

export const REMOVE_PROPERTY_REVENUE_SHARE_RULES = "[PROPERTY_REVENUE_SHARE_RULES] Remove";
export const REMOVE_PROPERTY_REVENUE_SHARE_RULES_SUCCESS = "[PROPERTY_REVENUE_SHARE_RULES] Remove Success";
export const REMOVE_PROPERTY_REVENUE_SHARE_RULES_FAIL = "[PROPERTY_REVENUE_SHARE_RULES] Remove Fail";

export const BULK_UPDATE_REVENUE_SHARE_RULES = "[PROPERTY_REVENUE_SHARE_RULES] Bulk Update";
export const BULK_UPDATE_REVENUE_SHARE_RULES_SUCCESS = "[PROPERTY_REVENUE_SHARE_RULES] Bulk Update Success";
export const BULK_UPDATE_REVENUE_SHARE_RULES_FAIL = "[PROPERTY_REVENUE_SHARE_RULES] Bulk Update Fail";