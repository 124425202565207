import {
  toDateString,
  toDateTimeString,
  toDateFormat,
  toDateTimeFormat,
  toTimeFormat,
  fromNow,
  dateDiffInDays,
  dateIsWeekend,
} from '@/helpers/functions';

export default {
  methods: {

    /**
     * @param date
     * @param format
     * @returns {*}
     */
    $toDateString(date, format) {
      return toDateString(date, format);
    },

    /**
     * @param dateTime
     * @param format
     * @returns {*}
     */
    $toDateTimeString(dateTime, format) {
      return toDateTimeString(dateTime, format);
    },

    /**
     * @param date
     * @param format
     * @returns {*}
     */
    $toDateFormat(date, format) {
      return toDateFormat(date, format);
    },

    /**
     * @param dateTime
     * @param format
     * @returns {*}
     */
    $toDateTimeFormat(dateTime, format) {
      return toDateTimeFormat(dateTime, format);
    },

    /**
     * 
     * @param {*} dateTime 
     * @returns 
     */
    $fromNow(dateTime) {
      return fromNow(dateTime);
    },

    /**
     * @param time
     * @param format
     * @returns {*}
     */
    $toTimeFormat(time, format) {
      return toTimeFormat(time, format);
    },

    /**
     * @param startDate
     * @param endDate
     */
    $dateDiffInDays(startDate, endDate) {
      return dateDiffInDays(startDate, endDate);
    },

    /**
     * @param date
     * @returns {*}
     */
    $dateIsWeekend(date) {
      return dateIsWeekend(date);
    }
  },
};
