export const UPLOAD_COMPANIES_GOOGLE_ACCOUNT_JSON = "[COMPANIES] Google Account JSON Upload";
export const UPLOAD_COMPANIES_GOOGLE_ACCOUNT_JSON_SUCCESS = "[COMPANIES] Google Account JSON Upload Success";
export const UPLOAD_COMPANIES_GOOGLE_ACCOUNT_JSON_FAIL = "[COMPANIES] Google Account JSON Upload Fail";
export const UPLOAD_COMPANIES_GOOGLE_ACCOUNT_JSON_RESET = "[COMPANIES] Google Account JSON Upload Reset";

export const VERIFY_COMPANIES_GOOGLE_ACCOUNT = "[COMPANIES] Google Account Verify";
export const VERIFY_COMPANIES_GOOGLE_ACCOUNT_SUCCESS = "[COMPANIES] Google Account Verify Success";
export const VERIFY_COMPANIES_GOOGLE_ACCOUNT_FAIL = "[COMPANIES] Google Account Verify Fail";
export const VERIFY_COMPANIES_GOOGLE_ACCOUNT_RESET = "[COMPANIES] Google Account Verify Reset";

export const UPLOAD_COMPANIES_GOOGLE_SERVICE_ACCOUNT_JSON = "[COMPANIES] Google Service Account JSON Upload";
export const UPLOAD_COMPANIES_GOOGLE_SERVICE_ACCOUNT_JSON_SUCCESS = "[COMPANIES] Google Service Account JSON Upload Success";
export const UPLOAD_COMPANIES_GOOGLE_SERVICE_ACCOUNT_JSON_FAIL = "[COMPANIES] Google Service Account JSON Upload Fail";
export const UPLOAD_COMPANIES_GOOGLE_SERVICE_ACCOUNT_JSON_RESET = "[COMPANIES] Google Service Account JSON Upload Reset";

export const SYNC_COMPANIES_GOOGLE_CONTACTS = "[COMPANIES] Google Contacts Sync";
export const SYNC_COMPANIES_GOOGLE_CONTACTS_SUCCESS = "[COMPANIES] Google Contacts Sync Success";
export const SYNC_COMPANIES_GOOGLE_CONTACTS_FAIL = "[COMPANIES] Google Contacts Sync Fail";
export const SYNC_COMPANIES_GOOGLE_CONTACTS_RESET = "[COMPANIES] Google Contacts Sync Reset";
