export const GET_BOOKING_ENGINE_COMBINATIONS = "[BOOKING_ENGINE] Combinations Get";
export const GET_BOOKING_ENGINE_COMBINATIONS_SUCCESS = "[BOOKING_ENGINE] Combinations Get Success";
export const GET_BOOKING_ENGINE_COMBINATIONS_FAIL = "[BOOKING_ENGINE] Combinations Get Fail";
export const GET_BOOKING_ENGINE_COMBINATIONS_RESET = "[BOOKING_ENGINE] Combinations Get Reset";

export const GET_BOOKING_ENGINE_ROOM_TYPES = "[BOOKING_ENGINE] Room Types Get";
export const GET_BOOKING_ENGINE_ROOM_TYPES_SUCCESS = "[BOOKING_ENGINE] Room Types Get Success";
export const GET_BOOKING_ENGINE_ROOM_TYPES_FAIL = "[BOOKING_ENGINE] Room Types Get Fail";
export const GET_BOOKING_ENGINE_ROOM_TYPES_RESET = "[BOOKING_ENGINE] Room Types Get Reset";

export const GET_BOOKING_ENGINE_QUOTE_COMBINATIONS = "[BOOKING_ENGINE] Quote Combinations Get";
export const GET_BOOKING_ENGINE_QUOTE_COMBINATIONS_SUCCESS = "[BOOKING_ENGINE] Quote Combinations Get Success";
export const GET_BOOKING_ENGINE_QUOTE_COMBINATIONS_FAIL = "[BOOKING_ENGINE] Quote Combinations Get Fail";
export const GET_BOOKING_ENGINE_QUOTE_COMBINATIONS_RESET = "[BOOKING_ENGINE] Quote Combinations Get Reset";