export default {
  methods: {
  getMealPlanIcon(mealPlan) {
      if (mealPlan === this.$const('ConstMealPlans', 'ROOM_ONLY')) return 'mdi-coffee-off';
      if (mealPlan === this.$const('ConstMealPlans', 'BREAKFAST')) return 'mdi-coffee';
      if (mealPlan === this.$const('ConstMealPlans', 'HALF_BOARD')) return 'mdi-food-outline';
      if (mealPlan === this.$const('ConstMealPlans', 'FULL_BOARD')) return 'mdi-food';
      if (mealPlan === this.$const('ConstMealPlans', 'ALL_INCLUSIVE')) return 'mdi-all-inclusive';

      return 'mdi-coffee-off';
    },
    getMealPlanColor(mealPlan) {
      switch (mealPlan) {
        case this.$const('ConstMealPlans', 'ROOM_ONLY'):
          return 'red';
        case this.$const('ConstMealPlans', 'BREAKFAST'):
          return 'green';
        case this.$const('ConstMealPlans', 'HALF_BOARD'):
          return 'orange';
        case this.$const('ConstMealPlans', 'FULL_BOARD'):
          return 'green'
        case this.$const('ConstMealPlans', 'ALL_INCLUSUIVE'):
          return 'warning';
      }
    }
  }
}
