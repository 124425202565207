export const GET_COMPANY_SMART_DEVICE_ACCOUNTS_LIST = "[COMPANY_SMART_DEVICE_ACCOUNTS] List Get";
export const GET_COMPANY_SMART_DEVICE_ACCOUNTS_LIST_SUCCESS = "[COMPANY_SMART_DEVICE_ACCOUNTS] List Get Success";
export const GET_COMPANY_SMART_DEVICE_ACCOUNTS_LIST_FAIL = "[COMPANY_SMART_DEVICE_ACCOUNTS] List Get Fail";

export const GET_COMPANY_SMART_DEVICE_ACCOUNTS_PAGINATION = "[COMPANY_SMART_DEVICE_ACCOUNTS] Pagination Get";
export const GET_COMPANY_SMART_DEVICE_ACCOUNTS_PAGINATION_SUCCESS = "[COMPANY_SMART_DEVICE_ACCOUNTS] Pagination Get Success";
export const GET_COMPANY_SMART_DEVICE_ACCOUNTS_PAGINATION_FAIL = "[COMPANY_SMART_DEVICE_ACCOUNTS] Pagination Get Fail";

export const GET_COMPANY_SMART_DEVICE_ACCOUNTS_COLLECTION = "[COMPANY_SMART_DEVICE_ACCOUNTS] Collection Get";
export const GET_COMPANY_SMART_DEVICE_ACCOUNTS_COLLECTION_SUCCESS = "[COMPANY_SMART_DEVICE_ACCOUNTS] Collection Get Success";
export const GET_COMPANY_SMART_DEVICE_ACCOUNTS_COLLECTION_FAIL = "[COMPANY_SMART_DEVICE_ACCOUNTS] Collection Get Fail";

export const GET_COMPANY_SMART_DEVICE_ACCOUNTS_SINGLE = "[COMPANY_SMART_DEVICE_ACCOUNTS] Single Get";
export const GET_COMPANY_SMART_DEVICE_ACCOUNTS_SINGLE_SUCCESS = "[COMPANY_SMART_DEVICE_ACCOUNTS] Single Get Success";
export const GET_COMPANY_SMART_DEVICE_ACCOUNTS_SINGLE_FAIL = "[COMPANY_SMART_DEVICE_ACCOUNTS] Single Get Fail";

export const CREATE_COMPANY_SMART_DEVICE_ACCOUNTS = "[COMPANY_SMART_DEVICE_ACCOUNTS] Create";
export const CREATE_COMPANY_SMART_DEVICE_ACCOUNTS_SUCCESS = "[COMPANY_SMART_DEVICE_ACCOUNTS] Create Success";
export const CREATE_COMPANY_SMART_DEVICE_ACCOUNTS_FAIL = "[COMPANY_SMART_DEVICE_ACCOUNTS] Create Fail";
export const CREATE_COMPANY_SMART_DEVICE_ACCOUNTS_RESET = "[COMPANY_SMART_DEVICE_ACCOUNTS] Create Reset";

export const UPDATE_COMPANY_SMART_DEVICE_ACCOUNTS = "[COMPANY_SMART_DEVICE_ACCOUNTS] Update";
export const UPDATE_COMPANY_SMART_DEVICE_ACCOUNTS_SUCCESS = "[COMPANY_SMART_DEVICE_ACCOUNTS] Update Success";
export const UPDATE_COMPANY_SMART_DEVICE_ACCOUNTS_FAIL = "[COMPANY_SMART_DEVICE_ACCOUNTS] Update Fail";
export const UPDATE_COMPANY_SMART_DEVICE_ACCOUNTS_RESET = "[COMPANY_SMART_DEVICE_ACCOUNTS] Update Reset";

export const REMOVE_COMPANY_SMART_DEVICE_ACCOUNTS = "[COMPANY_SMART_DEVICE_ACCOUNTS] Remove";
export const REMOVE_COMPANY_SMART_DEVICE_ACCOUNTS_SUCCESS = "[COMPANY_SMART_DEVICE_ACCOUNTS] Remove Success";
export const REMOVE_COMPANY_SMART_DEVICE_ACCOUNTS_FAIL = "[COMPANY_SMART_DEVICE_ACCOUNTS] Remove Fail";

export const GET_NUKI_AUTHORIZATION_URL = "[COMPANY_SMART_DEVICE_ACCOUNTS] Nuki Authorization Url Get";
export const GET_NUKI_AUTHORIZATION_URL_SUCCESS = "[COMPANY_SMART_DEVICE_ACCOUNTS] Nuki Authorization Url Get Success";
export const GET_NUKI_AUTHORIZATION_URL_FAIL = "[COMPANY_SMART_DEVICE_ACCOUNTS] Nuki Authorization Url Get Fail";
