export const GET_POINT_OF_SALES_LIST = "[POINT_OF_SALES] List Get";
export const GET_POINT_OF_SALES_LIST_SUCCESS = "[POINT_OF_SALES] List Get Success";
export const GET_POINT_OF_SALES_LIST_FAIL = "[POINT_OF_SALES] List Get Fail";

export const GET_POINT_OF_SALES_PAGINATION = "[POINT_OF_SALES] Pagination Get";
export const GET_POINT_OF_SALES_PAGINATION_SUCCESS = "[POINT_OF_SALES] Pagination Get Success";
export const GET_POINT_OF_SALES_PAGINATION_FAIL = "[POINT_OF_SALES] Pagination Get Fail";

export const GET_POINT_OF_SALES_COLLECTION = "[POINT_OF_SALES] Collection Get";
export const GET_POINT_OF_SALES_COLLECTION_SUCCESS = "[POINT_OF_SALES] Collection Get Success";
export const GET_POINT_OF_SALES_COLLECTION_FAIL = "[POINT_OF_SALES] Collection Get Fail";

export const GET_POINT_OF_SALES_SINGLE = "[POINT_OF_SALES] Single Get";
export const GET_POINT_OF_SALES_SINGLE_SUCCESS = "[POINT_OF_SALES] Single Get Success";
export const GET_POINT_OF_SALES_SINGLE_FAIL = "[POINT_OF_SALES] Single Get Fail";

export const CREATE_POINT_OF_SALES = "[POINT_OF_SALES] Create";
export const CREATE_POINT_OF_SALES_SUCCESS = "[POINT_OF_SALES] Create Success";
export const CREATE_POINT_OF_SALES_FAIL = "[POINT_OF_SALES] Create Fail";
export const CREATE_POINT_OF_SALES_RESET = "[POINT_OF_SALES] Create Reset";

export const UPDATE_POINT_OF_SALES = "[POINT_OF_SALES] Update";
export const UPDATE_POINT_OF_SALES_SUCCESS = "[POINT_OF_SALES] Update Success";
export const UPDATE_POINT_OF_SALES_FAIL = "[POINT_OF_SALES] Update Fail";
export const UPDATE_POINT_OF_SALES_RESET = "[POINT_OF_SALES] Update Reset";

export const REPLACE_POINT_OF_SALES = "[POINT_OF_SALES] Replace";
export const REPLACE_POINT_OF_SALES_SUCCESS = "[POINT_OF_SALES] Replace Success";
export const REPLACE_POINT_OF_SALES_FAIL = "[POINT_OF_SALES] Replace Fail";
export const REPLACE_POINT_OF_SALES_RESET = "[POINT_OF_SALES] Replace Reset";

export const REMOVE_POINT_OF_SALES = "[POINT_OF_SALES] Remove";
export const REMOVE_POINT_OF_SALES_SUCCESS = "[POINT_OF_SALES] Remove Success";
export const REMOVE_POINT_OF_SALES_FAIL = "[POINT_OF_SALES] Remove Fail";

export const CONFIRM_POINT_OF_SALES = "[POINT_OF_SALES] Confirm";
export const CONFIRM_POINT_OF_SALES_SUCCESS = "[POINT_OF_SALES] Confirm Success";
export const CONFIRM_POINT_OF_SALES_FAIL = "[POINT_OF_SALES] Confirm Fail";
export const CONFIRM_POINT_OF_SALES_RESET = "[POINT_OF_SALES] Confirm Reset";
