export const GET_WAREHOUSE_PRODUCT_LIST = "[WAREHOUSE_PRODUCT] List Get";
export const GET_WAREHOUSE_PRODUCT_LIST_SUCCESS = "[WAREHOUSE_PRODUCT] List Get Success";
export const GET_WAREHOUSE_PRODUCT_LIST_FAIL = "[WAREHOUSE_PRODUCT] List Get Fail";

export const GET_WAREHOUSE_PRODUCT_PAGINATION = "[WAREHOUSE_PRODUCT] Pagination Get";
export const GET_WAREHOUSE_PRODUCT_PAGINATION_SUCCESS = "[WAREHOUSE_PRODUCT] Pagination Get Success";
export const GET_WAREHOUSE_PRODUCT_PAGINATION_FAIL = "[WAREHOUSE_PRODUCT] Pagination Get Fail";

export const GET_WAREHOUSE_PRODUCT_COLLECTION = "[WAREHOUSE_PRODUCT] Collection Get";
export const GET_WAREHOUSE_PRODUCT_COLLECTION_SUCCESS = "[WAREHOUSE_PRODUCT] Collection Get Success";
export const GET_WAREHOUSE_PRODUCT_COLLECTION_FAIL = "[WAREHOUSE_PRODUCT] Collection Get Fail";

export const SYNC_WAREHOUSE_PRODUCT = "[WAREHOUSE_PRODUCT] Sync";
export const SYNC_WAREHOUSE_PRODUCT_SUCCESS = "[WAREHOUSE_PRODUCT] Sync Success";
export const SYNC_WAREHOUSE_PRODUCT_FAIL = "[WAREHOUSE_PRODUCT] Sync Fail";
export const SYNC_WAREHOUSE_PRODUCT_RESET = "[WAREHOUSE_PRODUCT] Sync Reset";

export const REMOVE_WAREHOUSE_PRODUCT = "[WAREHOUSE_PRODUCT] Remove";
export const REMOVE_WAREHOUSE_PRODUCT_SUCCESS = "[WAREHOUSE_PRODUCT] Remove Success";
export const REMOVE_WAREHOUSE_PRODUCT_FAIL = "[WAREHOUSE_PRODUCT] Remove Fail";
