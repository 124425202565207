import ApiService from "@/services/api";

import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_RESERVATION_META_LIST,
  GET_RESERVATION_META_LIST_SUCCESS,
  GET_RESERVATION_META_LIST_FAIL,
  GET_RESERVATION_META_PAGINATION,
  GET_RESERVATION_META_PAGINATION_SUCCESS,
  GET_RESERVATION_META_PAGINATION_FAIL,
  GET_RESERVATION_META_COLLECTION,
  GET_RESERVATION_META_COLLECTION_SUCCESS,
  GET_RESERVATION_META_COLLECTION_FAIL,
  GET_RESERVATION_META_SINGLE,
  GET_RESERVATION_META_SINGLE_SUCCESS,
  GET_RESERVATION_META_SINGLE_FAIL,
  CREATE_RESERVATION_META,
  CREATE_RESERVATION_META_SUCCESS,
  CREATE_RESERVATION_META_FAIL,
  CREATE_RESERVATION_META_RESET,
  UPDATE_RESERVATION_META,
  UPDATE_RESERVATION_META_SUCCESS,
  UPDATE_RESERVATION_META_FAIL,
  UPDATE_RESERVATION_META_RESET,
  REPLACE_RESERVATION_META,
  REPLACE_RESERVATION_META_SUCCESS,
  REPLACE_RESERVATION_META_FAIL,
  REPLACE_RESERVATION_META_RESET,
  REMOVE_RESERVATION_META,
  REMOVE_RESERVATION_META_SUCCESS,
  REMOVE_RESERVATION_META_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_RESERVATION_META_LIST,
    endPoint: "reservation-meta/list",
    mutationFail: GET_RESERVATION_META_LIST_FAIL,
    mutationSuccess: GET_RESERVATION_META_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_RESERVATION_META_PAGINATION,
    endPoint: "reservation-meta/paginated",
    mutationFail: GET_RESERVATION_META_PAGINATION_FAIL,
    mutationSuccess: GET_RESERVATION_META_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_RESERVATION_META_COLLECTION,
    endPoint: "reservation-meta/collected",
    mutationFail: GET_RESERVATION_META_COLLECTION_FAIL,
    mutationSuccess: GET_RESERVATION_META_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_RESERVATION_META_SINGLE,
    endPoint: "reservation-meta/single",
    mutationFail: GET_RESERVATION_META_SINGLE_FAIL,
    mutationSuccess: GET_RESERVATION_META_SINGLE_SUCCESS
  },
  creationRequest: {
    isUpload: true,
    action: CREATE_RESERVATION_META,
    actionReset: CREATE_RESERVATION_META_RESET,
    endPoint: "reservation-meta/create",
    mutationFail: CREATE_RESERVATION_META_FAIL,
    mutationSuccess: CREATE_RESERVATION_META_SUCCESS
  },
  editingRequest: {
    isUpload: true,
    action: UPDATE_RESERVATION_META,
    actionReset: UPDATE_RESERVATION_META_RESET,
    endPoint: "reservation-meta/update",
    mutationFail: UPDATE_RESERVATION_META_FAIL,
    mutationSuccess: UPDATE_RESERVATION_META_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_RESERVATION_META,
    actionReset: REPLACE_RESERVATION_META_RESET,
    endPoint: "reservation-meta/replace",
    mutationFail: REPLACE_RESERVATION_META_FAIL,
    mutationSuccess: REPLACE_RESERVATION_META_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_RESERVATION_META,
    endPoint: "reservation-meta/delete",
    mutationFail: REMOVE_RESERVATION_META_FAIL,
    mutationSuccess: REMOVE_RESERVATION_META_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.state,
};

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
