import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_PRODUCT_CATEGORIES_LIST,
  GET_PRODUCT_CATEGORIES_LIST_SUCCESS,
  GET_PRODUCT_CATEGORIES_LIST_FAIL,
  GET_PRODUCT_CATEGORIES_PAGINATION,
  GET_PRODUCT_CATEGORIES_PAGINATION_SUCCESS,
  GET_PRODUCT_CATEGORIES_PAGINATION_FAIL,
  GET_PRODUCT_CATEGORIES_COLLECTION,
  GET_PRODUCT_CATEGORIES_COLLECTION_SUCCESS,
  GET_PRODUCT_CATEGORIES_COLLECTION_FAIL,
  GET_PRODUCT_CATEGORIES_SINGLE,
  GET_PRODUCT_CATEGORIES_SINGLE_SUCCESS,
  GET_PRODUCT_CATEGORIES_SINGLE_FAIL,
  CREATE_PRODUCT_CATEGORIES,
  CREATE_PRODUCT_CATEGORIES_SUCCESS,
  CREATE_PRODUCT_CATEGORIES_FAIL,
  CREATE_PRODUCT_CATEGORIES_RESET,
  UPDATE_PRODUCT_CATEGORIES,
  UPDATE_PRODUCT_CATEGORIES_SUCCESS,
  UPDATE_PRODUCT_CATEGORIES_FAIL,
  UPDATE_PRODUCT_CATEGORIES_RESET,
  REPLACE_PRODUCT_CATEGORIES,
  REPLACE_PRODUCT_CATEGORIES_SUCCESS,
  REPLACE_PRODUCT_CATEGORIES_FAIL,
  REPLACE_PRODUCT_CATEGORIES_RESET,
  REMOVE_PRODUCT_CATEGORIES,
  REMOVE_PRODUCT_CATEGORIES_SUCCESS,
  REMOVE_PRODUCT_CATEGORIES_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_PRODUCT_CATEGORIES_LIST,
    endPoint: "product-categories/list",
    mutationFail: GET_PRODUCT_CATEGORIES_LIST_FAIL,
    mutationSuccess: GET_PRODUCT_CATEGORIES_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_PRODUCT_CATEGORIES_PAGINATION,
    endPoint: "product-categories/paginated",
    mutationFail: GET_PRODUCT_CATEGORIES_PAGINATION_FAIL,
    mutationSuccess: GET_PRODUCT_CATEGORIES_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_PRODUCT_CATEGORIES_COLLECTION,
    endPoint: "product-categories/collected",
    mutationFail: GET_PRODUCT_CATEGORIES_COLLECTION_FAIL,
    mutationSuccess: GET_PRODUCT_CATEGORIES_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_PRODUCT_CATEGORIES_SINGLE,
    endPoint: "product-categories/single",
    mutationFail: GET_PRODUCT_CATEGORIES_SINGLE_FAIL,
    mutationSuccess: GET_PRODUCT_CATEGORIES_SINGLE_SUCCESS
  },
  creationRequest: {
    isUpload: true,
    action: CREATE_PRODUCT_CATEGORIES,
    actionReset: CREATE_PRODUCT_CATEGORIES_RESET,
    endPoint: "product-categories/create",
    mutationFail: CREATE_PRODUCT_CATEGORIES_FAIL,
    mutationSuccess: CREATE_PRODUCT_CATEGORIES_SUCCESS
  },
  editingRequest: {
    isUpload: true,
    action: UPDATE_PRODUCT_CATEGORIES,
    actionReset: UPDATE_PRODUCT_CATEGORIES_RESET,
    endPoint: "product-categories/update",
    mutationFail: UPDATE_PRODUCT_CATEGORIES_FAIL,
    mutationSuccess: UPDATE_PRODUCT_CATEGORIES_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_PRODUCT_CATEGORIES,
    actionReset: REPLACE_PRODUCT_CATEGORIES_RESET,
    endPoint: "product-categories/replace",
    mutationFail: REPLACE_PRODUCT_CATEGORIES_FAIL,
    mutationSuccess: REPLACE_PRODUCT_CATEGORIES_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_PRODUCT_CATEGORIES,
    endPoint: "product-categories/delete",
    mutationFail: REMOVE_PRODUCT_CATEGORIES_FAIL,
    mutationSuccess: REMOVE_PRODUCT_CATEGORIES_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.state,
};

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
