export const GET_OCTORATE_ACCOMMODATIONS_PAGINATION = "[OCTORATE_ACCOMMODATIONS] Pagination Get";
export const GET_OCTORATE_ACCOMMODATIONS_PAGINATION_SUCCESS = "[OCTORATE_ACCOMMODATIONS] Pagination Get Success";
export const GET_OCTORATE_ACCOMMODATIONS_PAGINATION_FAIL = "[OCTORATE_ACCOMMODATIONS] Pagination Get Fail";

export const GET_OCTORATE_ACCOMMODATIONS_COLLECTION = "[OCTORATE_ACCOMMODATIONS] Collection Get";
export const GET_OCTORATE_ACCOMMODATIONS_COLLECTION_SUCCESS = "[OCTORATE_ACCOMMODATIONS] Collection Get Success";
export const GET_OCTORATE_ACCOMMODATIONS_COLLECTION_FAIL = "[OCTORATE_ACCOMMODATIONS] Collection Get Fail";

export const GET_OCTORATE_ACCOMMODATIONS_SINGLE = "[OCTORATE_ACCOMMODATIONS] Single Get";
export const GET_OCTORATE_ACCOMMODATIONS_SINGLE_SUCCESS = "[OCTORATE_ACCOMMODATIONS] Single Get Success";
export const GET_OCTORATE_ACCOMMODATIONS_SINGLE_FAIL = "[OCTORATE_ACCOMMODATIONS] Single Get Fail";

export const CREATE_OCTORATE_ACCOMMODATIONS = "[OCTORATE_ACCOMMODATIONS] Create";
export const CREATE_OCTORATE_ACCOMMODATIONS_SUCCESS = "[OCTORATE_ACCOMMODATIONS] Create Success";
export const CREATE_OCTORATE_ACCOMMODATIONS_FAIL = "[OCTORATE_ACCOMMODATIONS] Create Fail";
export const CREATE_OCTORATE_ACCOMMODATIONS_RESET = "[OCTORATE_ACCOMMODATIONS] Create Reset";

export const UPDATE_OCTORATE_ACCOMMODATIONS = "[OCTORATE_ACCOMMODATIONS] Update";
export const UPDATE_OCTORATE_ACCOMMODATIONS_SUCCESS = "[OCTORATE_ACCOMMODATIONS] Update Success";
export const UPDATE_OCTORATE_ACCOMMODATIONS_FAIL = "[OCTORATE_ACCOMMODATIONS] Update Fail";
export const UPDATE_OCTORATE_ACCOMMODATIONS_RESET = "[OCTORATE_ACCOMMODATIONS] Update Reset";

export const REMOVE_OCTORATE_ACCOMMODATIONS = "[OCTORATE_ACCOMMODATIONS] Remove";
export const REMOVE_OCTORATE_ACCOMMODATIONS_SUCCESS = "[OCTORATE_ACCOMMODATIONS] Remove Success";
export const REMOVE_OCTORATE_ACCOMMODATIONS_FAIL = "[OCTORATE_ACCOMMODATIONS] Remove Fail";

export const IMPORT_OCTORATE_CALENDAR = "[OCTORATE_ACCOMMODATIONS] Import Calendar";
export const IMPORT_OCTORATE_CALENDAR_SUCCESS = "[OCTORATE_ACCOMMODATIONS] Import Calendar Success";
export const IMPORT_OCTORATE_CALENDAR_FAIL = "[OCTORATE_ACCOMMODATIONS] Import Calendar Fail";

export const SYNC_OCTORATE_ACCOMMODATIONS = "[OCTORATE_ACCOMMODATIONS] Sync";
export const SYNC_OCTORATE_ACCOMMODATIONS_SUCCESS = "[OCTORATE_ACCOMMODATIONS] Sync Success";
export const SYNC_OCTORATE_ACCOMMODATIONS_FAIL = "[OCTORATE_ACCOMMODATIONS] Sync Fail";