import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_RATE_PLANS_LIST,
  GET_RATE_PLANS_LIST_SUCCESS,
  GET_RATE_PLANS_LIST_FAIL,
  GET_RATE_PLANS_PAGINATION,
  GET_RATE_PLANS_PAGINATION_SUCCESS,
  GET_RATE_PLANS_PAGINATION_FAIL,
  GET_RATE_PLANS_COLLECTION,
  GET_RATE_PLANS_COLLECTION_SUCCESS,
  GET_RATE_PLANS_COLLECTION_FAIL,
  GET_RATE_PLANS_SINGLE,
  GET_RATE_PLANS_SINGLE_SUCCESS,
  GET_RATE_PLANS_SINGLE_FAIL,
  CREATE_RATE_PLANS,
  CREATE_RATE_PLANS_SUCCESS,
  CREATE_RATE_PLANS_FAIL,
  CREATE_RATE_PLANS_RESET,
  UPDATE_RATE_PLANS,
  UPDATE_RATE_PLANS_SUCCESS,
  UPDATE_RATE_PLANS_FAIL,
  UPDATE_RATE_PLANS_RESET,
  REMOVE_RATE_PLANS,
  REMOVE_RATE_PLANS_SUCCESS,
  REMOVE_RATE_PLANS_FAIL,
  POSITION_RATE_PLANS,
  POSITION_RATE_PLANS_SUCCESS,
  POSITION_RATE_PLANS_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_RATE_PLANS_LIST,
    endPoint: "/rate-plans/list",
    mutationFail: GET_RATE_PLANS_LIST_FAIL,
    mutationSuccess: GET_RATE_PLANS_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_RATE_PLANS_PAGINATION,
    endPoint: "/rate-plans/paginated",
    mutationFail: GET_RATE_PLANS_PAGINATION_FAIL,
    mutationSuccess: GET_RATE_PLANS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_RATE_PLANS_COLLECTION,
    endPoint: "/rate-plans/collected",
    mutationFail: GET_RATE_PLANS_COLLECTION_FAIL,
    mutationSuccess: GET_RATE_PLANS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_RATE_PLANS_SINGLE,
    endPoint: "/rate-plans/single",
    mutationFail: GET_RATE_PLANS_SINGLE_FAIL,
    mutationSuccess: GET_RATE_PLANS_SINGLE_SUCCESS
  },
  creationRequest: {
    isUpload: true,
    action: CREATE_RATE_PLANS,
    actionReset: CREATE_RATE_PLANS_RESET,
    endPoint: "/rate-plans/create",
    mutationFail: CREATE_RATE_PLANS_FAIL,
    mutationSuccess: CREATE_RATE_PLANS_SUCCESS
  },
  editingRequest: {
    isUpload: true,
    action: UPDATE_RATE_PLANS,
    actionReset: UPDATE_RATE_PLANS_RESET,
    endPoint: "/rate-plans/update",
    mutationFail: UPDATE_RATE_PLANS_FAIL,
    mutationSuccess: UPDATE_RATE_PLANS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_RATE_PLANS,
    endPoint: "/rate-plans/delete",
    mutationFail: REMOVE_RATE_PLANS_FAIL,
    mutationSuccess: REMOVE_RATE_PLANS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    method: 'post',
    name: 'positionRequest',
    action: POSITION_RATE_PLANS,
    endPoint: 'rate-plans/position/{id}',
    mutations: {
      fail: POSITION_RATE_PLANS_FAIL,
      success: POSITION_RATE_PLANS_SUCCESS,
    },
  },
]);

const getters = {};

const actions = {
  ...crudProcessed.actions,
  ...listProcessed.actions,
  ...actionsProcessed.actions,
};

const state = {
  ...crudProcessed.state,
  ...listProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...crudProcessed.mutations,
  ...listProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
