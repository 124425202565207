import { mapState } from "vuex";

import data from "@/mixins/data";
import formable from "@/mixins/formable";
import rolesInfo from "./rolesInfo";

import { GET_ROLES_COLLECTION } from "@/store/roles/actions";
import { GET_COMPANIES_COLLECTION } from "@/store/companies/actions";
import { GET_PERMISSION_COLLECTION } from "@/store/data/actions";

import {
  GET_USER_TELEGRAM_ID,
} from "@/store/users/actions";

export default {
  mixins: [
    data,
    formable,
    rolesInfo,
  ],
  data() {
    return {
      form: {
        password: null,
        password_confirm: null,
        is_chat_enabled: 0,
        is_ticket_enabled: 0,
        locale: null,
        properties_added: [],
        properties_removed: [],
      },
      userProperties: [],
      showPassword: false,
      search: null,
      caseSensitive: false,
      showOnlySelected: false,
      initialProperties: [],
      autologinToken: null,
    }
  },
  beforeMount() {
    this.form.locale = this.$locale();
    this.$store.dispatch(GET_ROLES_COLLECTION);
    this.$store.dispatch(GET_PERMISSION_COLLECTION);
    this.getCompanies();
  },
  methods: {
    all() {
      let properties = [];
      this.companies.forEach((company) => {
        company.children.forEach((property) => {
          properties.push(property.id);
        })
      });

      this.$set(this.form, 'properties', properties);
    },
    clear() {
      this.$set(this.form, 'properties', []);
    },
    propertiesInput() {
      const addedItems = this.form.properties.filter(item => !this.initialProperties.includes(item));
      const removedItems = this.initialProperties.filter(item => !this.form.properties.includes(item));

      this.form.properties_added = [...addedItems];
      this.form.properties_removed = [...removedItems];
    },
    getTelegramUserId() {
      this.$store.dispatch(GET_USER_TELEGRAM_ID, {id: this.form.id}).then((response) => {
        if (!response) {
          this.showNotification(this.$lang('main.words.error'), 'error');
          return;
        }

        this.getItem();
        this.showNotification(this.$lang('main.words.succeeded'), 'success');
      });
    },
    getCompanies() {
      this.$store.dispatch(GET_COMPANIES_COLLECTION, {
        order: 'asc',
        sortBy: 'name',
        _data: {
          selects: [
            'id',
            'name'
          ],
          relations: {
            properties: {
              order: 'asc',
              sortBy: 'name',
              selects: [
                'id',
                'name',
                'company_id',
              ],
              where: [
                {
                  field: 'is_active',
                  value: 1
                }
              ]
            },
          }
        }
      });
    },
    setAutologinToken() {
      try {
        const jsonString = JSON.stringify({
          username: this.form.email,
          password: this.form.password,
        });

        this.autologinToken = `https://${location.host}/login?autologin=${this.$encrypt(jsonString)}`
      } catch (error) {
        console.error('Error encrypting autologin token:', error);
      }
    },
  },
  computed: {
    ...mapState({
      roles: state => state.roles.getCollectionRequest.collection,
      companiesRequest: state => state.companies.getCollectionRequest
    }),
    allRoutes() {
      let routes = [];
      const excluded = [
        'login'
      ];

      this.$router.options.routes.forEach(route => {
        if (
          !route.path.includes(':') &&
          !route.path.includes('*') &&
          excluded.indexOf(route.name) < 0 &&
          route.meta.loginRedirect
        ) {
          routes.push({
            value: route.path,
            text: this.$lang(`main.${route.meta.title}`)
          });
        }
      });

      return routes;
    },
    filter () {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
    companies() {
      if (!this.companiesRequest.collection) {
        return [];
      }

      return this.companiesRequest.collection
        .filter(company => company.properties && company.properties.length > 0) // Filter companies with properties
        .filter(company => {
          return !this.showOnlySelected ||
            company.properties.some(property => this.form.properties.includes(property.id));
        })
        .map(company => {
          return {
            id: company.id,
            name: company.name,
            children: company.properties.map(property => ({
              id: property.id,
              name: property.name
            }))
          };
        });
    },
  },
  watch: {
    'form.properties': {
      handler(newVal, oldVal) {
        if (!oldVal) {
          return;
        }

        // const addedItems = newVal.filter(item => !oldVal.includes(item));
        // const removedItems = oldVal.filter(item => !newVal.includes(item));

        // this.form.properties_added = [...addedItems];
        // this.form.properties_removed = [...removedItems];
      }
    }
  }
};
