export default [
  {
    name: "error",
    path: "/error",
    component: () => import(/* webpackChunkName: "global", webpackPreload: true */ "@/pages/static/Error"),
    meta: {}
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import(/* webpackChunkName: "global", webpackPreload: true */ "@/pages/static/Maintenance"),
    meta: {}
  }
];
