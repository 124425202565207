<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" xs="12" md="12">
            <v-text-field
              v-model="form.name"
              :label="$lang('users.fields.name')"
              :hint="$lang('users.hints.name')"
              :error="!!validation.name"
              :error-messages="!!validation.name ? validation.name : []"
            ></v-text-field>
            <v-text-field
              v-model="form.email"
              :label="$lang('users.fields.email')"
              :hint="$lang('users.hints.email')"
              :error="!!validation.email"
              :error-messages="!!validation.email ? validation.email : []"
            ></v-text-field>
            <v-text-field
              v-model="form.password"
              :label="$lang('users.fields.password')"
              :hint="$lang('users.hints.password')"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              :error="!!validation.password"
              :error-messages="!!validation.password ? validation.password : []"
            ></v-text-field>
            <v-text-field
              v-model="form.password_confirm"
              :label="$lang('users.fields.password_confirm')"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              :error="!!validation.password_confirm"
              :error-messages="!!validation.password_confirm ? validation.password_confirm : []"
            ></v-text-field>
            <v-locale
              v-model="form.locale"
              :label="$lang('users.fields.locale')"
              :hint="$lang('users.hints.locale')"
              persistent-hint
              :error="!!validation.locale"
              :error-messages="!!validation.locale ? validation.locale : []"
            />
            <v-select
              v-model="form.date_format"
              :items="$in('ConstDateFormat')"
              :label="$lang('users.fields.date_format')"
              :hint="$lang('users.hints.date_format')"
              persistent-hint
              :error="!!validation.date_format"
              :error-messages="!!validation.date_format ? validation.date_format : []"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row class="my-2">
          <v-col>
            <h3>
              {{ $lang('users.im.telegram.title') }}
            </h3>
            <div class="subtitle">{{ $lang('users.im.telegram.subtitle') }}</div>
            <v-row v-if="!form.telegram_id" class="mt-2">
              <v-col>
                <v-btn :href="`https://t.me/ciaobookingbot?start=a-${form.id}`" target="_blank" color="success" block>
                  {{$lang('users.im.actions.connect')}}
                </v-btn>
              </v-col>
              <v-col>
                <v-btn v-if="!form.telegram_id" @click="getTelegramUserId()" color="primary" block>
                  {{$lang('users.im.actions.verify')}}
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="form.telegram_id" class="mt-2">
              <v-col>
                <v-btn @click="form.telegram_id = null" color="error" block>
                  {{$lang('users.im.actions.detach')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="my-2">
          <v-col>
            <h3>
              {{ $lang('users.mfa.title') }}
            </h3>
            <v-row>
              <v-col>
                <template v-if="mfaConfig.mfa_method">
                  <div class="subtitle">{{ $lang('users.mfa.set', { method: $constText('ConstMfaMethods', mfaConfig.mfa_method) }) }}</div>
                </template>
                <template v-else>
                  <template v-if="mfa.google2fa_secret">
                    <v-img :src="googleInfo.image" width="300px" />
                    <v-text-field
                      v-model="mfa.google2fa_secret"
                      :label="$lang('users.mfa.google2fa_secret')"
                      readonly
                    />
                    <span>{{ $lang('users.mfa.code') }}</span>
                    <v-otp-input
                      v-model="mfa.code"
                      :error="!!enableRequestValidation.code"
                      :error-messages="!!enableRequestValidation.code ? enableRequestValidation.code : []"
                    />
                  </template>
                  <template v-else>
                    <v-radio-group dense v-model="mfa.mfa_method" :mandatory="false">
                      <v-radio
                        v-for="(method, i) in $in('ConstMfaMethods')"
                        :key="i"
                        :label="method.text"
                        :value="method.value"
                      />
                    </v-radio-group>
                  </template>
                </template>
                <v-btn v-if="mfaConfig.mfa_method" @click="disableMFA" color="error" block :loading="disableRequest.loading">
                  {{ $lang('users.mfa.disable') }}
                </v-btn>
                <v-btn v-else @click="preEnableMFA" color="success" block :loading="googleInfoRequest.loading || enableRequest.loading">
                  {{ $lang('users.mfa.enable') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="submit()" :loading="loading" :disabled="loading">{{ $lang("main.crud.save") }}</v-btn>
      <v-btn color="error" @click="cancel()">{{ $lang("main.crud.cancel") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import {mapState} from "vuex";

  import mfa from "@/pages/users/mixins/mfa";
  import form from "@/pages/users/mixins/form";

  import {
    GET_USERS_SINGLE,
    REPLACE_USERS,
    REPLACE_USERS_RESET,
  } from "@/store/users/actions";

  export default {
    name: "EditingForm",
    mixins: [
      mfa,
      form,
    ],
    props: [
      'itemId'
    ],
    beforeMount() {
      this.getItem();
    },
    methods: {
      resetAction() {
        return REPLACE_USERS_RESET;
      },
      submitAction() {
        return REPLACE_USERS;
      },
      submitActionOptions() {
        return {
          id: this.itemId,
          params: this.form
        };
      },
      populate(item) {
        this.form = {
          ...this.form,
          ...item
        };
      },
      getItem() {
        this.$store.dispatch(GET_USERS_SINGLE, {
          id: this.itemId,
        });
      }
    },
    computed: {
      ...mapState({
        item: state => state.users.getSingleRequest.item,
        loading: state => state.users.replaceRequest.loading,
        validation: state => state.users.replaceRequest.validation,
      })
    },
  };
</script>

<style scoped>
</style>
