export const GET_WORK_ORDER_COMMENTS_LIST = "[WORK_ORDER_COMMENTS] List Get";
export const GET_WORK_ORDER_COMMENTS_LIST_SUCCESS = "[WORK_ORDER_COMMENTS] List Get Success";
export const GET_WORK_ORDER_COMMENTS_LIST_FAIL = "[WORK_ORDER_COMMENTS] List Get Fail";

export const GET_WORK_ORDER_COMMENTS_PAGINATION = "[WORK_ORDER_COMMENTS] Pagination Get";
export const GET_WORK_ORDER_COMMENTS_PAGINATION_SUCCESS = "[WORK_ORDER_COMMENTS] Pagination Get Success";
export const GET_WORK_ORDER_COMMENTS_PAGINATION_FAIL = "[WORK_ORDER_COMMENTS] Pagination Get Fail";

export const GET_WORK_ORDER_COMMENTS_COLLECTION = "[WORK_ORDER_COMMENTS] Collection Get";
export const GET_WORK_ORDER_COMMENTS_COLLECTION_SUCCESS = "[WORK_ORDER_COMMENTS] Collection Get Success";
export const GET_WORK_ORDER_COMMENTS_COLLECTION_FAIL = "[WORK_ORDER_COMMENTS] Collection Get Fail";

export const GET_WORK_ORDER_COMMENTS_SINGLE = "[WORK_ORDER_COMMENTS] Single Get";
export const GET_WORK_ORDER_COMMENTS_SINGLE_SUCCESS = "[WORK_ORDER_COMMENTS] Single Get Success";
export const GET_WORK_ORDER_COMMENTS_SINGLE_FAIL = "[WORK_ORDER_COMMENTS] Single Get Fail";

export const CREATE_WORK_ORDER_COMMENTS = "[WORK_ORDER_COMMENTS] Create";
export const CREATE_WORK_ORDER_COMMENTS_SUCCESS = "[WORK_ORDER_COMMENTS] Create Success";
export const CREATE_WORK_ORDER_COMMENTS_FAIL = "[WORK_ORDER_COMMENTS] Create Fail";
export const CREATE_WORK_ORDER_COMMENTS_RESET = "[WORK_ORDER_COMMENTS] Create Reset";

export const UPDATE_WORK_ORDER_COMMENTS = "[WORK_ORDER_COMMENTS] Update";
export const UPDATE_WORK_ORDER_COMMENTS_SUCCESS = "[WORK_ORDER_COMMENTS] Update Success";
export const UPDATE_WORK_ORDER_COMMENTS_FAIL = "[WORK_ORDER_COMMENTS] Update Fail";
export const UPDATE_WORK_ORDER_COMMENTS_RESET = "[WORK_ORDER_COMMENTS] Update Reset";

export const REPLACE_WORK_ORDER_COMMENTS = "[WORK_ORDER_COMMENTS] Replace";
export const REPLACE_WORK_ORDER_COMMENTS_SUCCESS = "[WORK_ORDER_COMMENTS] Replace Success";
export const REPLACE_WORK_ORDER_COMMENTS_FAIL = "[WORK_ORDER_COMMENTS] Replace Fail";
export const REPLACE_WORK_ORDER_COMMENTS_RESET = "[WORK_ORDER_COMMENTS] Replace Reset";

export const REMOVE_WORK_ORDER_COMMENTS = "[WORK_ORDER_COMMENTS] Remove";
export const REMOVE_WORK_ORDER_COMMENTS_SUCCESS = "[WORK_ORDER_COMMENTS] Remove Success";
export const REMOVE_WORK_ORDER_COMMENTS_FAIL = "[WORK_ORDER_COMMENTS] Remove Fail";
