import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_OCTORATE_CONNECTION_ROOM_TYPES_PAGINATION,
  GET_OCTORATE_CONNECTION_ROOM_TYPES_PAGINATION_SUCCESS,
  GET_OCTORATE_CONNECTION_ROOM_TYPES_PAGINATION_FAIL,
  GET_OCTORATE_CONNECTION_ROOM_TYPES_COLLECTION,
  GET_OCTORATE_CONNECTION_ROOM_TYPES_COLLECTION_SUCCESS,
  GET_OCTORATE_CONNECTION_ROOM_TYPES_COLLECTION_FAIL,
  GET_OCTORATE_CONNECTION_ROOM_TYPES_SINGLE,
  GET_OCTORATE_CONNECTION_ROOM_TYPES_SINGLE_SUCCESS,
  GET_OCTORATE_CONNECTION_ROOM_TYPES_SINGLE_FAIL,
  CREATE_OCTORATE_CONNECTION_ROOM_TYPES,
  CREATE_OCTORATE_CONNECTION_ROOM_TYPES_SUCCESS,
  CREATE_OCTORATE_CONNECTION_ROOM_TYPES_FAIL,
  CREATE_OCTORATE_CONNECTION_ROOM_TYPES_RESET,
  UPDATE_OCTORATE_CONNECTION_ROOM_TYPES,
  UPDATE_OCTORATE_CONNECTION_ROOM_TYPES_SUCCESS,
  UPDATE_OCTORATE_CONNECTION_ROOM_TYPES_FAIL,
  UPDATE_OCTORATE_CONNECTION_ROOM_TYPES_RESET,
  REMOVE_OCTORATE_CONNECTION_ROOM_TYPES,
  REMOVE_OCTORATE_CONNECTION_ROOM_TYPES_SUCCESS,
  REMOVE_OCTORATE_CONNECTION_ROOM_TYPES_FAIL,
  IMPORT_OCTORATE_CONNECTION_ROOM_TYPES,
  IMPORT_OCTORATE_CONNECTION_ROOM_TYPES_SUCCESS,
  IMPORT_OCTORATE_CONNECTION_ROOM_TYPES_FAIL,
  IMPORT_OCTORATE_CONNECTION_MAPPING,
  IMPORT_OCTORATE_CONNECTION_MAPPING_SUCCESS,
  IMPORT_OCTORATE_CONNECTION_MAPPING_FAIL,
  GET_MAPPING_COLLECTION,
  GET_MAPPING_COLLECTION_SUCCESS,
  GET_MAPPING_COLLECTION_FAIL,
  CREATE_OCTORATE_CONNECTION_MAPPING,
  CREATE_OCTORATE_CONNECTION_MAPPING_SUCCESS,
  CREATE_OCTORATE_CONNECTION_MAPPING_FAIL,
  REMOVE_OCTORATE_CONNECTION_MAPPING,
  REMOVE_OCTORATE_CONNECTION_MAPPING_SUCCESS,
  REMOVE_OCTORATE_CONNECTION_MAPPING_FAIL,
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_OCTORATE_CONNECTION_ROOM_TYPES_PAGINATION,
    endPoint: "octorate/connections/room-types/paginated",
    mutationFail: GET_OCTORATE_CONNECTION_ROOM_TYPES_PAGINATION_FAIL,
    mutationSuccess: GET_OCTORATE_CONNECTION_ROOM_TYPES_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_OCTORATE_CONNECTION_ROOM_TYPES_COLLECTION,
    endPoint: "octorate/connections/room-types/collected",
    mutationFail: GET_OCTORATE_CONNECTION_ROOM_TYPES_COLLECTION_FAIL,
    mutationSuccess: GET_OCTORATE_CONNECTION_ROOM_TYPES_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_OCTORATE_CONNECTION_ROOM_TYPES_SINGLE,
    endPoint: "octorate/connections/room-types/single",
    mutationFail: GET_OCTORATE_CONNECTION_ROOM_TYPES_SINGLE_FAIL,
    mutationSuccess: GET_OCTORATE_CONNECTION_ROOM_TYPES_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_OCTORATE_CONNECTION_ROOM_TYPES,
    actionReset: CREATE_OCTORATE_CONNECTION_ROOM_TYPES_RESET,
    endPoint: "octorate/connections/room-types/create",
    mutationFail: CREATE_OCTORATE_CONNECTION_ROOM_TYPES_FAIL,
    mutationSuccess: CREATE_OCTORATE_CONNECTION_ROOM_TYPES_SUCCESS
  },
  editingRequest: {
    action: UPDATE_OCTORATE_CONNECTION_ROOM_TYPES,
    actionReset: UPDATE_OCTORATE_CONNECTION_ROOM_TYPES_RESET,
    endPoint: "octorate/connections/room-types/update",
    mutationFail: UPDATE_OCTORATE_CONNECTION_ROOM_TYPES_FAIL,
    mutationSuccess: UPDATE_OCTORATE_CONNECTION_ROOM_TYPES_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_OCTORATE_CONNECTION_ROOM_TYPES,
    endPoint: "octorate/connections/room-types/delete",
    mutationFail: REMOVE_OCTORATE_CONNECTION_ROOM_TYPES_FAIL,
    mutationSuccess: REMOVE_OCTORATE_CONNECTION_ROOM_TYPES_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    name: 'getOctorateRoomTypesImportRequest',
    action: IMPORT_OCTORATE_CONNECTION_ROOM_TYPES,
    endPoint: 'octorate/connections/room-types/import/{connection_id}',
    mutations: {
      fail: IMPORT_OCTORATE_CONNECTION_ROOM_TYPES_FAIL,
      success: IMPORT_OCTORATE_CONNECTION_ROOM_TYPES_SUCCESS,
    },
  },
  {
    method: 'delete',
    name: 'removeOctorateRoomTypesRequest',
    action: REMOVE_OCTORATE_CONNECTION_ROOM_TYPES,
    endPoint: 'octorate/connections/room-types/delete/bulk/{connection_id}',
    mutations: {
      fail: REMOVE_OCTORATE_CONNECTION_ROOM_TYPES_FAIL,
      success: REMOVE_OCTORATE_CONNECTION_ROOM_TYPES_SUCCESS,
    },
  },
  {
    name: 'getMappingImportRequest',
    action: IMPORT_OCTORATE_CONNECTION_MAPPING,
    endPoint: 'octorate/connections/room-types/mapping/import/{accommodation_id}/{connection_id}',
    mutations: {
      fail: IMPORT_OCTORATE_CONNECTION_MAPPING_FAIL,
      success: IMPORT_OCTORATE_CONNECTION_MAPPING_SUCCESS,
    },
  },
  {
    name: 'getMappingCollectionRequest',
    action: GET_MAPPING_COLLECTION,
    endPoint: 'octorate/connections/room-types/mapping/collection/{connection_id}',
    mutations: {
      fail: GET_MAPPING_COLLECTION_FAIL,
      success: GET_MAPPING_COLLECTION_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'mappingCreationRequest',
    action: CREATE_OCTORATE_CONNECTION_MAPPING,
    endPoint: 'octorate/connections/room-types/mapping/create/{accommodation_id}/{connection_id}',
    mutations: {
      fail: CREATE_OCTORATE_CONNECTION_MAPPING_FAIL,
      success: CREATE_OCTORATE_CONNECTION_MAPPING_SUCCESS,
    },
  },
  {
    method: 'delete',
    name: 'mappingDeletionRequest',
    action: REMOVE_OCTORATE_CONNECTION_MAPPING,
    endPoint: 'octorate/connections/room-types/mapping/delete/{accommodation_id}/{connection_id}/{mapping_id}',
    mutations: {
      fail: REMOVE_OCTORATE_CONNECTION_MAPPING_FAIL,
      success: REMOVE_OCTORATE_CONNECTION_MAPPING_SUCCESS,
    },
  },
]);

const actions = {
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
