export const CM_SYNC_RATES = '[CIAOCHANNEL] Sync Rates';
export const CM_SYNC_RATES_SUCCESS = '[CIAOCHANNEL] Sync Rates Success';
export const CM_SYNC_RATES_FAIL = '[CIAOCHANNEL] Sync Rates Fail';

export const CM_GET_CONNECTIONS = '[CIAOCHANNEL] Get Connections';
export const CM_GET_CONNECTIONS_SUCCESS = '[CIAOCHANNEL] Get Connections Success';
export const CM_GET_CONNECTIONS_FAIL = '[CIAOCHANNEL] Get Connections Fail';

export const CM_GET_PORTALS = '[CIAOCHANNEL] Get Portals';
export const CM_GET_PORTALS_SUCCESS = '[CIAOCHANNEL] Get Portals Success';
export const CM_GET_PORTALS_FAIL = '[CIAOCHANNEL] Get Portals Fail';