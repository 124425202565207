import ApiService from "@/services/api";

import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_FONTS_LIST,
  GET_FONTS_LIST_SUCCESS,
  GET_FONTS_LIST_FAIL,
  GET_FONTS_PAGINATION,
  GET_FONTS_PAGINATION_SUCCESS,
  GET_FONTS_PAGINATION_FAIL,
  GET_FONTS_COLLECTION,
  GET_FONTS_COLLECTION_SUCCESS,
  GET_FONTS_COLLECTION_FAIL,
  GET_FONTS_SINGLE,
  GET_FONTS_SINGLE_SUCCESS,
  GET_FONTS_SINGLE_FAIL,
  CREATE_FONTS,
  CREATE_FONTS_SUCCESS,
  CREATE_FONTS_FAIL,
  CREATE_FONTS_RESET,
  UPDATE_FONTS,
  UPDATE_FONTS_SUCCESS,
  UPDATE_FONTS_FAIL,
  UPDATE_FONTS_RESET,
  REPLACE_FONTS,
  REPLACE_FONTS_SUCCESS,
  REPLACE_FONTS_FAIL,
  REPLACE_FONTS_RESET,
  REMOVE_FONTS,
  REMOVE_FONTS_SUCCESS,
  REMOVE_FONTS_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_FONTS_LIST,
    endPoint: "fonts/list",
    mutationFail: GET_FONTS_LIST_FAIL,
    mutationSuccess: GET_FONTS_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_FONTS_PAGINATION,
    endPoint: "fonts/paginated",
    mutationFail: GET_FONTS_PAGINATION_FAIL,
    mutationSuccess: GET_FONTS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_FONTS_COLLECTION,
    endPoint: "fonts/collected",
    mutationFail: GET_FONTS_COLLECTION_FAIL,
    mutationSuccess: GET_FONTS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_FONTS_SINGLE,
    endPoint: "fonts/single",
    mutationFail: GET_FONTS_SINGLE_FAIL,
    mutationSuccess: GET_FONTS_SINGLE_SUCCESS
  },
  creationRequest: {
    isUpload: true,
    action: CREATE_FONTS,
    actionReset: CREATE_FONTS_RESET,
    endPoint: "fonts/create",
    mutationFail: CREATE_FONTS_FAIL,
    mutationSuccess: CREATE_FONTS_SUCCESS
  },
  editingRequest: {
    isUpload: true,
    action: UPDATE_FONTS,
    actionReset: UPDATE_FONTS_RESET,
    endPoint: "fonts/update",
    mutationFail: UPDATE_FONTS_FAIL,
    mutationSuccess: UPDATE_FONTS_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_FONTS,
    actionReset: REPLACE_FONTS_RESET,
    endPoint: "fonts/replace",
    mutationFail: REPLACE_FONTS_FAIL,
    mutationSuccess: REPLACE_FONTS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_FONTS,
    endPoint: "fonts/delete",
    mutationFail: REMOVE_FONTS_FAIL,
    mutationSuccess: REMOVE_FONTS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.state,
};

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
