import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_COMPANY_SMART_DEVICES_LIST,
  GET_COMPANY_SMART_DEVICES_LIST_SUCCESS,
  GET_COMPANY_SMART_DEVICES_LIST_FAIL,
  CREATE_COMPANY_SMART_DEVICES,
  CREATE_COMPANY_SMART_DEVICES_FAIL,
  CREATE_COMPANY_SMART_DEVICES_RESET,
  CREATE_COMPANY_SMART_DEVICES_SUCCESS,
  GET_COMPANY_SMART_DEVICES_COLLECTION,
  GET_COMPANY_SMART_DEVICES_COLLECTION_FAIL,
  GET_COMPANY_SMART_DEVICES_COLLECTION_SUCCESS,
  GET_COMPANY_SMART_DEVICES_SINGLE,
  GET_COMPANY_SMART_DEVICES_SINGLE_FAIL,
  GET_COMPANY_SMART_DEVICES_SINGLE_SUCCESS,
  REMOVE_COMPANY_SMART_DEVICES,
  REMOVE_COMPANY_SMART_DEVICES_FAIL,
  REMOVE_COMPANY_SMART_DEVICES_SUCCESS,
  UPDATE_COMPANY_SMART_DEVICES,
  UPDATE_COMPANY_SMART_DEVICES_FAIL,
  UPDATE_COMPANY_SMART_DEVICES_RESET,
  UPDATE_COMPANY_SMART_DEVICES_SUCCESS,
  GET_COMPANY_SMART_DEVICES_PAGINATION,
  GET_COMPANY_SMART_DEVICES_PAGINATION_FAIL,
  GET_COMPANY_SMART_DEVICES_PAGINATION_SUCCESS,
  LOCK_COMPANY_SMART_DEVICES,
  LOCK_COMPANY_SMART_DEVICES_FAIL,
  LOCK_COMPANY_SMART_DEVICES_RESET,
  LOCK_COMPANY_SMART_DEVICES_SUCCESS,
  UNLOCK_COMPANY_SMART_DEVICES,
  UNLOCK_COMPANY_SMART_DEVICES_FAIL,
  UNLOCK_COMPANY_SMART_DEVICES_RESET,
  UNLOCK_COMPANY_SMART_DEVICES_SUCCESS,
  TOGGLE_COMPANY_SMART_DEVICES,
  TOGGLE_COMPANY_SMART_DEVICES_FAIL,
  TOGGLE_COMPANY_SMART_DEVICES_RESET,
  TOGGLE_COMPANY_SMART_DEVICES_SUCCESS,
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_COMPANY_SMART_DEVICES_PAGINATION,
    endPoint: "company/smart-devices/paginated",
    mutationFail: GET_COMPANY_SMART_DEVICES_PAGINATION_FAIL,
    mutationSuccess: GET_COMPANY_SMART_DEVICES_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_COMPANY_SMART_DEVICES_COLLECTION,
    endPoint: "company/smart-devices/collected",
    mutationFail: GET_COMPANY_SMART_DEVICES_COLLECTION_FAIL,
    mutationSuccess: GET_COMPANY_SMART_DEVICES_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_COMPANY_SMART_DEVICES_SINGLE,
    endPoint: "company/smart-devices/single",
    mutationFail: GET_COMPANY_SMART_DEVICES_SINGLE_FAIL,
    mutationSuccess: GET_COMPANY_SMART_DEVICES_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_COMPANY_SMART_DEVICES,
    actionReset: CREATE_COMPANY_SMART_DEVICES_RESET,
    endPoint: "company/smart-devices/create",
    mutationFail: CREATE_COMPANY_SMART_DEVICES_FAIL,
    mutationSuccess: CREATE_COMPANY_SMART_DEVICES_SUCCESS
  },
  editingRequest: {
    action: UPDATE_COMPANY_SMART_DEVICES,
    actionReset: UPDATE_COMPANY_SMART_DEVICES_RESET,
    endPoint: "company/smart-devices/update",
    mutationFail: UPDATE_COMPANY_SMART_DEVICES_FAIL,
    mutationSuccess: UPDATE_COMPANY_SMART_DEVICES_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_COMPANY_SMART_DEVICES,
    endPoint: "company/smart-devices/delete",
    mutationFail: REMOVE_COMPANY_SMART_DEVICES_FAIL,
    mutationSuccess: REMOVE_COMPANY_SMART_DEVICES_SUCCESS
  }
});

const listProcessed = list.process({
  getListRequest: {
    action: GET_COMPANY_SMART_DEVICES_LIST,
    endPoint: "company-invoices/list",
    mutationFail: GET_COMPANY_SMART_DEVICES_LIST_FAIL,
    mutationSuccess: GET_COMPANY_SMART_DEVICES_LIST_SUCCESS
  },
});

const actionsProcessed = action.processMultiple([
  {
    method: 'post',
    name: 'lockRequest',
    action: LOCK_COMPANY_SMART_DEVICES,
    actionReset: LOCK_COMPANY_SMART_DEVICES_RESET,
    endPoint: 'company/smart-devices/lock/{id}',
    mutations: {
      fail: LOCK_COMPANY_SMART_DEVICES_FAIL,
      success: LOCK_COMPANY_SMART_DEVICES_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'unlockRequest',
    action: UNLOCK_COMPANY_SMART_DEVICES,
    actionReset: UNLOCK_COMPANY_SMART_DEVICES_RESET,
    endPoint: 'company/smart-devices/unlock/{id}',
    mutations: {
      fail: UNLOCK_COMPANY_SMART_DEVICES_FAIL,
      success: UNLOCK_COMPANY_SMART_DEVICES_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'toggleRequest',
    action: TOGGLE_COMPANY_SMART_DEVICES,
    actionReset: TOGGLE_COMPANY_SMART_DEVICES_RESET,
    endPoint: 'company/smart-devices/toggle/{id}',
    mutations: {
      fail: TOGGLE_COMPANY_SMART_DEVICES_FAIL,
      success: TOGGLE_COMPANY_SMART_DEVICES_SUCCESS,
    },
  },
]);

const getters = {};

const actions = {
  ...crudProcessed.actions,
  ...listProcessed.actions,
  ...actionsProcessed.actions,
};

const state = {
  ...crudProcessed.state,
  ...listProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...crudProcessed.mutations,
  ...listProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
