export default [
  {
    name: "clients",
    path: "/clients",
    component: () => import(/*webpackChunkName: "clients" */ "@/pages/clients/Index"),
    meta: {
      title: 'titles.clients'
    }
  },
  {
    name: "clients-merge",
    path: "/clients/merge",
    component: () => import(/*webpackChunkName: "clients-merge" */ "@/pages/clients-merge/Index"),
    meta: {
      title: 'titles.clients-merge'
    }
  }
];
