export default [
  {
    name: "bills",
    path: "/accounting/bills",
    component: () => import(/*webpackChunkName: "accounting" */ "@/pages/bills/Index"),
    meta: {
      title: 'titles.bills'
    }
  }
];
