import ApiService from "@/services/api";

import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_OWNER_DOCUMENTS_LIST,
  GET_OWNER_DOCUMENTS_LIST_SUCCESS,
  GET_OWNER_DOCUMENTS_LIST_FAIL,
  GET_OWNER_DOCUMENTS_PAGINATION,
  GET_OWNER_DOCUMENTS_PAGINATION_SUCCESS,
  GET_OWNER_DOCUMENTS_PAGINATION_FAIL,
  GET_OWNER_DOCUMENTS_COLLECTION,
  GET_OWNER_DOCUMENTS_COLLECTION_SUCCESS,
  GET_OWNER_DOCUMENTS_COLLECTION_FAIL,
  GET_OWNER_DOCUMENTS_SINGLE,
  GET_OWNER_DOCUMENTS_SINGLE_SUCCESS,
  GET_OWNER_DOCUMENTS_SINGLE_FAIL,
  CREATE_OWNER_DOCUMENTS,
  CREATE_OWNER_DOCUMENTS_SUCCESS,
  CREATE_OWNER_DOCUMENTS_FAIL,
  CREATE_OWNER_DOCUMENTS_RESET,
  UPDATE_OWNER_DOCUMENTS,
  UPDATE_OWNER_DOCUMENTS_SUCCESS,
  UPDATE_OWNER_DOCUMENTS_FAIL,
  UPDATE_OWNER_DOCUMENTS_RESET,
  REPLACE_OWNER_DOCUMENTS,
  REPLACE_OWNER_DOCUMENTS_SUCCESS,
  REPLACE_OWNER_DOCUMENTS_FAIL,
  REPLACE_OWNER_DOCUMENTS_RESET,
  REMOVE_OWNER_DOCUMENTS,
  REMOVE_OWNER_DOCUMENTS_SUCCESS,
  REMOVE_OWNER_DOCUMENTS_FAIL,

  EXPORT_PDF_OWNER_DOCUMENTS,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_OWNER_DOCUMENTS_LIST,
    endPoint: "owner-documents/list",
    mutationFail: GET_OWNER_DOCUMENTS_LIST_FAIL,
    mutationSuccess: GET_OWNER_DOCUMENTS_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_OWNER_DOCUMENTS_PAGINATION,
    endPoint: "owner-documents/paginated",
    mutationFail: GET_OWNER_DOCUMENTS_PAGINATION_FAIL,
    mutationSuccess: GET_OWNER_DOCUMENTS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_OWNER_DOCUMENTS_COLLECTION,
    endPoint: "owner-documents/collected",
    mutationFail: GET_OWNER_DOCUMENTS_COLLECTION_FAIL,
    mutationSuccess: GET_OWNER_DOCUMENTS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_OWNER_DOCUMENTS_SINGLE,
    endPoint: "owner-documents/single",
    mutationFail: GET_OWNER_DOCUMENTS_SINGLE_FAIL,
    mutationSuccess: GET_OWNER_DOCUMENTS_SINGLE_SUCCESS
  },
  creationRequest: {
    isUpload: true,
    action: CREATE_OWNER_DOCUMENTS,
    actionReset: CREATE_OWNER_DOCUMENTS_RESET,
    endPoint: "owner-documents/create",
    mutationFail: CREATE_OWNER_DOCUMENTS_FAIL,
    mutationSuccess: CREATE_OWNER_DOCUMENTS_SUCCESS
  },
  editingRequest: {
    isUpload: true,
    action: UPDATE_OWNER_DOCUMENTS,
    actionReset: UPDATE_OWNER_DOCUMENTS_RESET,
    endPoint: "owner-documents/update",
    mutationFail: UPDATE_OWNER_DOCUMENTS_FAIL,
    mutationSuccess: UPDATE_OWNER_DOCUMENTS_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_OWNER_DOCUMENTS,
    actionReset: REPLACE_OWNER_DOCUMENTS_RESET,
    endPoint: "owner-documents/replace",
    mutationFail: REPLACE_OWNER_DOCUMENTS_FAIL,
    mutationSuccess: REPLACE_OWNER_DOCUMENTS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_OWNER_DOCUMENTS,
    endPoint: "owner-documents/delete",
    mutationFail: REMOVE_OWNER_DOCUMENTS_FAIL,
    mutationSuccess: REMOVE_OWNER_DOCUMENTS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
]);

const actions = {
  [EXPORT_PDF_OWNER_DOCUMENTS](context, id) {
    ApiService.download(`owner-documents/export-pdf/${id}`, true);
  },
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.state,
};

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
