<template>
  <span style="width: 400px">
    <v-autocomplete
      v-debounce:700="getItems"
      v-model="id"
      item-value="id"
      item-text="client.name"
      prepend-icon="mdi-magnify"
      :label="label ? label : $lang('main.navbar.search')"
      :items="items"
      :filter="item => item"
      :loading="loading"
      :disabled="disabled"
      :return-object="returnObject"
    >
      <template v-slot:selection="{item}">
        {{ item.client.name }}
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title>
            <span class="text-h6">{{ item.bill_n }} - {{ item.bill_series }}</span>
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ $toDateFormat(item.date) }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $lang('documents.hints.search') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </span>
</template>

<script>
import { mapState } from "vuex";
import { GET_DOCUMENTS_COLLECTION } from "@/store/documents/actions";

export default {
  name: "CDocumentSearch",
  props: {
    value: {
      required: false
    },
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      search: null,
      id: null
    };
  },
  mounted() {
    this.id = this.value;
    this.getItems();
  },
  methods: {
    getItems(search) {
      if((!search || search.length <= 3) && !this.value) {
        return
      }

      let id = search ? null : this.value;

      this.$store.dispatch(GET_DOCUMENTS_COLLECTION, {
        id,
        search: search,
        _data: {
          selects: [
            "id",
            "client_id",
            "date",
            "document_n",
            "document_series",
          ],
          relations: {
            client: {
              selects: ["id", "name"]
            },
          }
        }
      });
    },
  },
  computed: {
    ...mapState({
      request: state => state.documents.getCollectionRequest
    }),
    items() {
      if (this.request.loading) {
        return [];
      }
      return this.request.collection;
    },
    loading() {
      return this.request.loading;
    },
  },
  watch: {
    id(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.id = newVal;
    },
  }
};
</script>
