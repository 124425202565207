export default [
  {
    name: "ciao-channel-rates-sync",
    path: "/ciao-channel/sync/rates",
    component: () => import(/*webpackChunkName: "accounting" */ "@/pages/ciao-channel/CalendarSync"),
  },
  {
    name: "ciao-channel-connections",
    path: "/ciao-channel/connections",
    component: () => import(/*webpackChunkName: "accounting" */ "@/pages/ciao-channel/Connections"),
  }
];
