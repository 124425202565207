import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      dataTimezones: state => state.data.dataTimezonesRequest.data,
      dataCurrencies: state => state.data.dataCurrenciesRequest.data,
      dataStatTypes: state => state.data.dataStatTypesRequest.data,
      dataPoliceTypes: state => state.data.dataPoliceTypesRequest.data,
      dataDocTypes: state => state.data.dataDocTypesRequest.data,
      dataIsoCountries3Letters: state => state.data.dataIsoCountries3LettersRequest.data,
      dataIsoCountries2Letters: state => state.data.dataIsoCountries2LettersRequest.data,
      dataLocale: state => state.data.dataLocale.data,
      dataIso6392: state => state.data.dataIso6392Request.data,
      dataChekinStatExternalId: state => state.data.dataChekinStatExternalIdRequest.data,
      permissions: state => state.data.permissionRequest.data,
    })
  }
};
