export const GET_BOOKING_POLICY_COLLECTION = "[BOOKING_POLICY] Collection Get";
export const GET_BOOKING_POLICY_COLLECTION_SUCCESS = "[BOOKING_POLICY] Collection Get Success";
export const GET_BOOKING_POLICY_COLLECTION_FAIL = "[BOOKING_POLICY] Collection Get Fail";

export const CREATE_BOOKING_POLICY = "[BOOKING_POLICY] Create";
export const CREATE_BOOKING_POLICY_SUCCESS = "[BOOKING_POLICY] Create Success";
export const CREATE_BOOKING_POLICY_FAIL = "[BOOKING_POLICY] Create Fail";
export const CREATE_BOOKING_POLICY_RESET = "[BOOKING_POLICY] Create Reset";

export const UPDATE_BOOKING_POLICY = "[BOOKING_POLICY] Update";
export const UPDATE_BOOKING_POLICY_SUCCESS = "[BOOKING_POLICY] Update Success";
export const UPDATE_BOOKING_POLICY_FAIL = "[BOOKING_POLICY] Update Fail";
export const UPDATE_BOOKING_POLICY_RESET = "[BOOKING_POLICY] Update Reset";

export const REMOVE_BOOKING_POLICY = "[BOOKING_POLICY] Remove";
export const REMOVE_BOOKING_POLICY_SUCCESS = "[BOOKING_POLICY] Remove Success";
export const REMOVE_BOOKING_POLICY_FAIL = "[BOOKING_POLICY] Remove Fail";
