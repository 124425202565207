import list from "@/store/core/list";
import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_PROPERTIES_LIST,
  GET_PROPERTIES_LIST_SUCCESS,
  GET_PROPERTIES_LIST_FAIL,
  GET_PROPERTIES_PAGINATION,
  GET_PROPERTIES_PAGINATION_SUCCESS,
  GET_PROPERTIES_PAGINATION_FAIL,
  GET_PROPERTIES_COLLECTION,
  GET_PROPERTIES_COLLECTION_SUCCESS,
  GET_PROPERTIES_COLLECTION_FAIL,
  GET_PROPERTIES_SINGLE,
  GET_PROPERTIES_SINGLE_SUCCESS,
  GET_PROPERTIES_SINGLE_FAIL,
  CREATE_PROPERTIES,
  CREATE_PROPERTIES_SUCCESS,
  CREATE_PROPERTIES_FAIL,
  CREATE_PROPERTIES_RESET,
  UPDATE_PROPERTIES,
  UPDATE_PROPERTIES_SUCCESS,
  UPDATE_PROPERTIES_FAIL,
  UPDATE_PROPERTIES_RESET,
  REPLACE_PROPERTIES,
  REPLACE_PROPERTIES_SUCCESS,
  REPLACE_PROPERTIES_FAIL,
  REPLACE_PROPERTIES_RESET,
  REMOVE_PROPERTIES,
  REMOVE_PROPERTIES_SUCCESS,
  REMOVE_PROPERTIES_FAIL,
  RESEND_ACTIVATION,
  RESEND_ACTIVATION_SUCCESS,
  RESEND_ACTIVATION_FAIL,
  TEST_SMTP_SETTINGS,
  TEST_SMTP_SETTINGS_SUCCESS,
  TEST_SMTP_SETTINGS_FAIL,
  REGENERATE_PROPERTY_RESERVATIONS_CITY_TAX,
  REGENERATE_PROPERTY_RESERVATIONS_CITY_TAX_SUCCESS,
  REGENERATE_PROPERTY_RESERVATIONS_CITY_TAX_FAIL
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_PROPERTIES_LIST,
    endPoint: "properties/list",
    mutationFail: GET_PROPERTIES_LIST_FAIL,
    mutationSuccess: GET_PROPERTIES_LIST_SUCCESS
  }
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_PROPERTIES_PAGINATION,
    endPoint: "properties/paginated",
    mutationFail: GET_PROPERTIES_PAGINATION_FAIL,
    mutationSuccess: GET_PROPERTIES_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_PROPERTIES_COLLECTION,
    endPoint: "properties/collected",
    mutationFail: GET_PROPERTIES_COLLECTION_FAIL,
    mutationSuccess: GET_PROPERTIES_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_PROPERTIES_SINGLE,
    endPoint: "properties/single",
    mutationFail: GET_PROPERTIES_SINGLE_FAIL,
    mutationSuccess: GET_PROPERTIES_SINGLE_SUCCESS
  },
  creationRequest: {
    isUpload: true,
    action: CREATE_PROPERTIES,
    actionReset: CREATE_PROPERTIES_RESET,
    endPoint: "properties/create",
    mutationFail: CREATE_PROPERTIES_FAIL,
    mutationSuccess: CREATE_PROPERTIES_SUCCESS
  },
  editingRequest: {
    isUpload: true,
    action: UPDATE_PROPERTIES,
    actionReset: UPDATE_PROPERTIES_RESET,
    endPoint: "properties/update",
    mutationFail: UPDATE_PROPERTIES_FAIL,
    mutationSuccess: UPDATE_PROPERTIES_SUCCESS
  },
  replaceRequest: {
    isUpload: true,
    action: REPLACE_PROPERTIES,
    actionReset: REPLACE_PROPERTIES_RESET,
    endPoint: "properties/replace",
    mutationFail: REPLACE_PROPERTIES_FAIL,
    mutationSuccess: REPLACE_PROPERTIES_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_PROPERTIES,
    endPoint: "properties/delete",
    mutationFail: REMOVE_PROPERTIES_FAIL,
    mutationSuccess: REMOVE_PROPERTIES_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    name: 'getTestSmtpSettingsRequest',
    action: TEST_SMTP_SETTINGS,
    endPoint: 'properties/test-smtp',
    mutations: {
      fail: TEST_SMTP_SETTINGS_FAIL,
      success: TEST_SMTP_SETTINGS_SUCCESS,
    },
  },
  {
    name: 'getResendSecomActivationRequest',
    action: RESEND_ACTIVATION,
    endPoint: 'properties/resend-secom-activation/{id}',
    mutations: {
      fail: RESEND_ACTIVATION_FAIL,
      success: RESEND_ACTIVATION_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'regeneratePropertyReservationsCityTax',
    action: REGENERATE_PROPERTY_RESERVATIONS_CITY_TAX,
    endPoint: 'properties/{id}/regenerate-reservations-citytax',
    mutations: {
      fail: REGENERATE_PROPERTY_RESERVATIONS_CITY_TAX_FAIL,
      success: REGENERATE_PROPERTY_RESERVATIONS_CITY_TAX_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
