<template>
  <span>
    <v-autocomplete
      v-model="ids"
      item-value="id"
      :items="items"
      :label="label"
      :multiple="multiple"
      :small-chips="smallChips"
      :deletable-chips="deletableChips"
      :hint="hint"
      :persistent-hint="persistentHint"
      :loading="loading"
      :prepend-icon="prependIcon"
      append-outer-icon="mdi-refresh"
      :clearable="clearable"
      :disabled="disabled"
      :readonly="readonly"
      :dark="dark"
      @change="change"
      @blur="blur"
      @click:append-outer="getItems()"
      :dense="dense"
      :error="error"
      :error-messages="!!errorMessages ? errorMessages : []"
    >
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title>
            <v-chip
              :color="item.color"
              class="white--text"
              label
              small
            >
              <v-icon color="white" class="mr-1" x-small>{{ item.icon }}</v-icon>
              {{ item.name }}
            </v-chip>
          </v-list-item-title>
        </v-list-item-content>
      </template>
      <template v-slot:selection="{item}">
        <v-chip :color="item.color" class="white--text" small label>
          <v-icon small class="mr-1" color="white">{{ item.icon }}</v-icon>
          {{ item.name }}
        </v-chip>
      </template>
    </v-autocomplete>
  </span>
</template>

<script>
import { mapState } from "vuex";
import { GET_PROPERTY_CLUSTERS_COLLECTION } from "@/store/property-clusters/actions";

export default {
	name: "CPropertyClustersAutocomplete",
	props: {
		label: {
			type: String,
			required: false,
      default: ''
		},
		hint: {
			type: String,
			default: ""
		},
    persistentHint: {
      type: Boolean,
      default: false
    },
		value: {
			default: null
		},
		error: {
			default: false
		},
		errorMessages: {
			default: () => []
		},
		dense: {
			type: Boolean,
			default: false
		},
		prependIcon: {
			default: ""
		},
		clearable: {
			type: Boolean,
			default: false
		},
    multiple: {
      type: Boolean,
			default: false
    },
		disabled: {
			type: Boolean,
			default: false
		},
		readonly: {
			type: Boolean,
			default: false
		},
		dark: {
			type: Boolean,
			default: false
		},
    smallChips: {
      type: Boolean,
			default: false
    },
    deletableChips: {
      type: Boolean,
			default: false
    },
    withSelectionButtons: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => {
        return {};
      }
    },
    companyId: {
      type: Number,
      default:null,
    }
	},
	data() {
		return {
			ids: null,
      selectedIds: [],
      cache: false
		};
	},
  beforeMount() {
    if (!this.isListCached || Object.keys(this.options).length) {
      this.getItems();
      this.cache = true;
    }
  },
  mounted() {
    this.ids = this.value;
  },
	methods: {
    getItems() {
      if(this.request.loading) {
        return;
      }
      
      let filters = this.options.filters || {};

      this.$store.dispatch(GET_PROPERTY_CLUSTERS_COLLECTION, {
        sortBy: 'name',
        order: 'asc',
        companyId: this.companyId,
        ...filters,
      });
    },
		change() {
			this.$emit("change");
		},
    blur() {
      this.$emit("blur");
    },
    selectAllItems() {
      let items = [];

      Object.keys(this.items).map(key => {
        items.push(this.items[key].value);
      });

      this.ids = [...items];
    },
    deselectAllItems() {
      this.ids = [];
    },
	},
  computed: {
    ...mapState({
      request: state => state.propertyClusters.getCollectionRequest,
    }),
    items() {
      if (!this.request.collection) {
        return [];
      }

      return this.request.collection;
    },
    loading() {
      return this.request.loading;
    },
    isListCached() {
      return this.request.success;
    },
  },
  watch: {
    ids(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.ids = newVal;
    },
    companyId() {
      this.getItems();
    }
  }
};
</script>
