import action from "@/store/core/action";

import {
  DATA_ISO_COUNTRIES_2_LETTERS,
  DATA_ISO_COUNTRIES_2_LETTERS_SUCCESS,
  DATA_ISO_COUNTRIES_2_LETTERS_FAIL,
  DATA_ISO_COUNTRIES_3_LETTERS,
  DATA_ISO_COUNTRIES_3_LETTERS_SUCCESS,
  DATA_ISO_COUNTRIES_3_LETTERS_FAIL,
  DATA_STAT_TYPES,
  DATA_STAT_TYPES_SUCCESS,
  DATA_STAT_TYPES_FAIL,
  DATA_POLICE_TYPES,
  DATA_POLICE_TYPES_SUCCESS,
  DATA_POLICE_TYPES_FAIL,
  DATA_DOC_TYPES,
  DATA_DOC_TYPES_SUCCESS,
  DATA_DOC_TYPES_FAIL,
  DATA_CURRENCIES,
  DATA_CURRENCIES_SUCCESS,
  DATA_CURRENCIES_FAIL,
  DATA_TIMEZONES,
  DATA_TIMEZONES_SUCCESS,
  DATA_TIMEZONES_FAIL,
  DATA_LOCALE,
  DATA_LOCALE_SUCCESS,
  DATA_LOCALE_FAIL,
  DATA_ISO639_2,
  DATA_ISO639_2_SUCCESS,
  DATA_ISO639_2_FAIL,
  DATA_CHEKIN_STAT_EXTERNAL_ID,
  DATA_CHEKIN_STAT_EXTERNAL_ID_FAIL,
  DATA_CHEKIN_STAT_EXTERNAL_ID_SUCCESS,
  CHECK_VAT_CODE,
  CHECK_VAT_CODE_SUCCESS,
  CHECK_VAT_CODE_FAIL,
  GET_PERMISSION_COLLECTION,
  GET_PERMISSION_COLLECTION_SUCCESS,
  GET_PERMISSION_COLLECTION_FAIL,
} from "./actions";

const actionsProcessed = action.processMultiple([
  {
    name: 'dataIsoCountries2LettersRequest',
    action: DATA_ISO_COUNTRIES_2_LETTERS,
    endPoint: 'data/iso-countries-2-letters',
    mutations: {
      fail: DATA_ISO_COUNTRIES_2_LETTERS_FAIL,
      success: DATA_ISO_COUNTRIES_2_LETTERS_SUCCESS,
    },
  },
  {
    name: 'dataIsoCountries3LettersRequest',
    action: DATA_ISO_COUNTRIES_3_LETTERS,
    endPoint: 'data/iso-countries-3-letters',
    mutations: {
      fail: DATA_ISO_COUNTRIES_3_LETTERS_FAIL,
      success: DATA_ISO_COUNTRIES_3_LETTERS_SUCCESS,
    },
  },
  {
    name: 'dataStatTypesRequest',
    action: DATA_STAT_TYPES,
    endPoint: 'data/stat-types',
    mutations: {
      fail: DATA_STAT_TYPES_FAIL,
      success: DATA_STAT_TYPES_SUCCESS,
    },
  },
  {
    name: 'dataPoliceTypesRequest',
    action: DATA_POLICE_TYPES,
    endPoint: 'data/police-types',
    mutations: {
      fail: DATA_POLICE_TYPES_FAIL,
      success: DATA_POLICE_TYPES_SUCCESS,
    },
  },
  {
    name: 'dataDocTypesRequest',
    action: DATA_DOC_TYPES,
    endPoint: 'data/doc-types',
    mutations: {
      fail: DATA_DOC_TYPES_FAIL,
      success: DATA_DOC_TYPES_SUCCESS,
    },
  },
  {
    name: 'dataCurrenciesRequest',
    action: DATA_CURRENCIES,
    endPoint: 'data/currencies',
    mutations: {
      fail: DATA_CURRENCIES_FAIL,
      success: DATA_CURRENCIES_SUCCESS,
    },
  },
  {
    name: 'dataTimezonesRequest',
    action: DATA_TIMEZONES,
    endPoint: 'data/timezones',
    mutations: {
      fail: DATA_TIMEZONES_FAIL,
      success: DATA_TIMEZONES_SUCCESS,
    },
  },
  {
    name: 'dataLocale',
    action: DATA_LOCALE,
    endPoint: 'data/locale',
    mutations: {
      fail: DATA_LOCALE_FAIL,
      success: DATA_LOCALE_SUCCESS,
    },
  },
  {
    name: 'dataIso6392Request',
    action: DATA_ISO639_2,
    endPoint: 'data/iso-639-2',
    mutations: {
      fail: DATA_ISO639_2_FAIL,
      success: DATA_ISO639_2_SUCCESS,
    },
  },
  {
    name: 'dataChekinStatExternalIdRequest',
    action: DATA_CHEKIN_STAT_EXTERNAL_ID,
    endPoint: 'data/stat-external-id',
    mutations: {
      fail: DATA_CHEKIN_STAT_EXTERNAL_ID_FAIL,
      success: DATA_CHEKIN_STAT_EXTERNAL_ID_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'checkVatCodeRequest',
    action: CHECK_VAT_CODE,
    endPoint: "data/check-vat-code",
    mutations: {
      fail: CHECK_VAT_CODE_FAIL,
      success: CHECK_VAT_CODE_SUCCESS,
    },
  },
  {
    name: 'permissionRequest',
    action: GET_PERMISSION_COLLECTION,
    endPoint: 'data/permissions',
    mutations: {
      fail: GET_PERMISSION_COLLECTION_FAIL,
      success: GET_PERMISSION_COLLECTION_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  ...actionsProcessed.state
};

const actions = {
  ...actionsProcessed.actions
};

const mutations = {
  ...actionsProcessed.mutations
};

export default {
  state,
  actions,
  mutations,
  getters
};
