import list from "@/store/core/list";
import crud from "@/store/core/crud";
import action from "@/store/core/action";
import ApiService from "@/services/api";

import {
  GET_ROLES_LIST,
  GET_ROLES_LIST_SUCCESS,
  GET_ROLES_LIST_FAIL,
  GET_ROLES_COLLECTION,
  GET_ROLES_COLLECTION_SUCCESS,
  GET_ROLES_COLLECTION_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_ROLES_LIST,
    endPoint: "roles/list",
    mutationFail: GET_ROLES_LIST_FAIL,
    mutationSuccess: GET_ROLES_LIST_SUCCESS
  }
});

const crudProcessed = crud.process({
  getCollectionRequest: {
    action: GET_ROLES_COLLECTION,
    endPoint: "roles/collected",
    mutationFail: GET_ROLES_COLLECTION_FAIL,
    mutationSuccess: GET_ROLES_COLLECTION_SUCCESS
  },
});

const actionsProcessed = action.processMultiple([

]);

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};