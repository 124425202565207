import crud from "@/store/core/crud";
import action from "@/store/core/action";
import ApiService from "@/services/api";

import {
  GET_BILLS_PAGINATION,
  GET_BILLS_PAGINATION_SUCCESS,
  GET_BILLS_PAGINATION_FAIL,
  GET_BILLS_COLLECTION,
  GET_BILLS_COLLECTION_SUCCESS,
  GET_BILLS_COLLECTION_FAIL,
  GET_BILLS_SINGLE,
  GET_BILLS_SINGLE_SUCCESS,
  GET_BILLS_SINGLE_FAIL,
  CREATE_BILLS,
  CREATE_BILLS_SUCCESS,
  CREATE_BILLS_FAIL,
  CREATE_BILLS_RESET,
  UPDATE_BILLS,
  UPDATE_BILLS_SUCCESS,
  UPDATE_BILLS_FAIL,
  UPDATE_BILLS_RESET,
  REMOVE_BILLS,
  REMOVE_BILLS_SUCCESS,
  REMOVE_BILLS_FAIL,
  SEND_BILLS,
  SEND_BILLS_SUCCESS,
  SEND_BILLS_FAIL,
  EXPORT_PDF_BILLS,
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_BILLS_PAGINATION,
    endPoint: "bills/paginated",
    mutationFail: GET_BILLS_PAGINATION_FAIL,
    mutationSuccess: GET_BILLS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_BILLS_COLLECTION,
    endPoint: "bills/collected",
    mutationFail: GET_BILLS_COLLECTION_FAIL,
    mutationSuccess: GET_BILLS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_BILLS_SINGLE,
    endPoint: "bills/single",
    mutationFail: GET_BILLS_SINGLE_FAIL,
    mutationSuccess: GET_BILLS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_BILLS,
    actionReset: CREATE_BILLS_RESET,
    endPoint: "bills/create",
    mutationFail: CREATE_BILLS_FAIL,
    mutationSuccess: CREATE_BILLS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_BILLS,
    actionReset: UPDATE_BILLS_RESET,
    endPoint: "bills/update",
    mutationFail: UPDATE_BILLS_FAIL,
    mutationSuccess: UPDATE_BILLS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_BILLS,
    endPoint: "bills/delete",
    mutationFail: REMOVE_BILLS_FAIL,
    mutationSuccess: REMOVE_BILLS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    name: 'sendRequest',
    action: SEND_BILLS,
    endPoint: 'bills/send-by-email/{id}',
    mutations: {
      fail: SEND_BILLS_FAIL,
      success: SEND_BILLS_SUCCESS
    }
  }
]);

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  [EXPORT_PDF_BILLS](context, id) {
    ApiService.download(`bills/export-pdf/${id}`, true);
  },
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
