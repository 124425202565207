export const GET_INVENTORY_MOVEMENTS_LIST = "[INVENTORY_MOVEMENTS] List Get";
export const GET_INVENTORY_MOVEMENTS_LIST_SUCCESS = "[INVENTORY_MOVEMENTS] List Get Success";
export const GET_INVENTORY_MOVEMENTS_LIST_FAIL = "[INVENTORY_MOVEMENTS] List Get Fail";

export const GET_INVENTORY_MOVEMENTS_PAGINATION = "[INVENTORY_MOVEMENTS] Pagination Get";
export const GET_INVENTORY_MOVEMENTS_PAGINATION_SUCCESS = "[INVENTORY_MOVEMENTS] Pagination Get Success";
export const GET_INVENTORY_MOVEMENTS_PAGINATION_FAIL = "[INVENTORY_MOVEMENTS] Pagination Get Fail";

export const GET_INVENTORY_MOVEMENTS_COLLECTION = "[INVENTORY_MOVEMENTS] Collection Get";
export const GET_INVENTORY_MOVEMENTS_COLLECTION_SUCCESS = "[INVENTORY_MOVEMENTS] Collection Get Success";
export const GET_INVENTORY_MOVEMENTS_COLLECTION_FAIL = "[INVENTORY_MOVEMENTS] Collection Get Fail";

export const GET_INVENTORY_MOVEMENTS_SINGLE = "[INVENTORY_MOVEMENTS] Single Get";
export const GET_INVENTORY_MOVEMENTS_SINGLE_SUCCESS = "[INVENTORY_MOVEMENTS] Single Get Success";
export const GET_INVENTORY_MOVEMENTS_SINGLE_FAIL = "[INVENTORY_MOVEMENTS] Single Get Fail";

export const CREATE_INVENTORY_MOVEMENTS = "[INVENTORY_MOVEMENTS] Create";
export const CREATE_INVENTORY_MOVEMENTS_SUCCESS = "[INVENTORY_MOVEMENTS] Create Success";
export const CREATE_INVENTORY_MOVEMENTS_FAIL = "[INVENTORY_MOVEMENTS] Create Fail";
export const CREATE_INVENTORY_MOVEMENTS_RESET = "[INVENTORY_MOVEMENTS] Create Reset";

export const UPDATE_INVENTORY_MOVEMENTS = "[INVENTORY_MOVEMENTS] Update";
export const UPDATE_INVENTORY_MOVEMENTS_SUCCESS = "[INVENTORY_MOVEMENTS] Update Success";
export const UPDATE_INVENTORY_MOVEMENTS_FAIL = "[INVENTORY_MOVEMENTS] Update Fail";
export const UPDATE_INVENTORY_MOVEMENTS_RESET = "[INVENTORY_MOVEMENTS] Update Reset";

export const REPLACE_INVENTORY_MOVEMENTS = "[INVENTORY_MOVEMENTS] Replace";
export const REPLACE_INVENTORY_MOVEMENTS_SUCCESS = "[INVENTORY_MOVEMENTS] Replace Success";
export const REPLACE_INVENTORY_MOVEMENTS_FAIL = "[INVENTORY_MOVEMENTS] Replace Fail";
export const REPLACE_INVENTORY_MOVEMENTS_RESET = "[INVENTORY_MOVEMENTS] Replace Reset";

export const REMOVE_INVENTORY_MOVEMENTS = "[INVENTORY_MOVEMENTS] Remove";
export const REMOVE_INVENTORY_MOVEMENTS_SUCCESS = "[INVENTORY_MOVEMENTS] Remove Success";
export const REMOVE_INVENTORY_MOVEMENTS_FAIL = "[INVENTORY_MOVEMENTS] Remove Fail";
