import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_RATE_HISTORY_PAGINATION,
  GET_RATE_HISTORY_PAGINATION_SUCCESS,
  GET_RATE_HISTORY_PAGINATION_FAIL,
  GET_RATE_HISTORY_COLLECTION,
  GET_RATE_HISTORY_COLLECTION_SUCCESS,
  GET_RATE_HISTORY_COLLECTION_FAIL,
  GET_RATE_HISTORY_SINGLE,
  GET_RATE_HISTORY_SINGLE_SUCCESS,
  GET_RATE_HISTORY_SINGLE_FAIL,
  REMOVE_RATE_HISTORY,
  REMOVE_RATE_HISTORY_SUCCESS,
  REMOVE_RATE_HISTORY_FAIL,
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_RATE_HISTORY_PAGINATION,
    endPoint: "rate-history/paginated",
    mutationFail: GET_RATE_HISTORY_PAGINATION_FAIL,
    mutationSuccess: GET_RATE_HISTORY_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_RATE_HISTORY_COLLECTION,
    endPoint: "rate-history/collected",
    mutationFail: GET_RATE_HISTORY_COLLECTION_FAIL,
    mutationSuccess: GET_RATE_HISTORY_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_RATE_HISTORY_SINGLE,
    endPoint: "rate-history/single",
    mutationFail: GET_RATE_HISTORY_SINGLE_FAIL,
    mutationSuccess: GET_RATE_HISTORY_SINGLE_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_RATE_HISTORY,
    endPoint: "rate-history/delete",
    mutationFail: REMOVE_RATE_HISTORY_FAIL,
    mutationSuccess: REMOVE_RATE_HISTORY_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const actions = {
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
