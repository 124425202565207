import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_BOOKING_NETWORK_PAGES_LIST,
  GET_BOOKING_NETWORK_PAGES_LIST_SUCCESS,
  GET_BOOKING_NETWORK_PAGES_LIST_FAIL,
  CREATE_BOOKING_NETWORK_PAGES,
  CREATE_BOOKING_NETWORK_PAGES_FAIL,
  CREATE_BOOKING_NETWORK_PAGES_RESET,
  CREATE_BOOKING_NETWORK_PAGES_SUCCESS,
  GET_BOOKING_NETWORK_PAGES_COLLECTION,
  GET_BOOKING_NETWORK_PAGES_COLLECTION_FAIL,
  GET_BOOKING_NETWORK_PAGES_COLLECTION_SUCCESS,
  GET_BOOKING_NETWORK_PAGES_SINGLE,
  GET_BOOKING_NETWORK_PAGES_SINGLE_FAIL,
  GET_BOOKING_NETWORK_PAGES_SINGLE_SUCCESS,
  REMOVE_BOOKING_NETWORK_PAGES,
  REMOVE_BOOKING_NETWORK_PAGES_FAIL,
  REMOVE_BOOKING_NETWORK_PAGES_SUCCESS,
  UPDATE_BOOKING_NETWORK_PAGES,
  UPDATE_BOOKING_NETWORK_PAGES_FAIL,
  UPDATE_BOOKING_NETWORK_PAGES_RESET,
  UPDATE_BOOKING_NETWORK_PAGES_SUCCESS,
  REPLACE_BOOKING_NETWORK_PAGES,
  REPLACE_BOOKING_NETWORK_PAGES_FAIL,
  REPLACE_BOOKING_NETWORK_PAGES_RESET,
  REPLACE_BOOKING_NETWORK_PAGES_SUCCESS,
  GET_BOOKING_NETWORK_PAGES_PAGINATION,
  GET_BOOKING_NETWORK_PAGES_PAGINATION_FAIL,
  GET_BOOKING_NETWORK_PAGES_PAGINATION_SUCCESS,
  POSITION_BOOKING_NETWORK_PAGES,
  POSITION_BOOKING_NETWORK_PAGES_SUCCESS,
  POSITION_BOOKING_NETWORK_PAGES_FAIL,
  DUPLICATE_BOOKING_NETWORK_PAGES,
  DUPLICATE_BOOKING_NETWORK_PAGES_SUCCESS,
  DUPLICATE_BOOKING_NETWORK_PAGES_FAIL,
  DUPLICATE_BOOKING_NETWORK_PAGES_RESET,
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_BOOKING_NETWORK_PAGES_PAGINATION,
    endPoint: "booking-network-pages/paginated",
    mutationFail: GET_BOOKING_NETWORK_PAGES_PAGINATION_FAIL,
    mutationSuccess: GET_BOOKING_NETWORK_PAGES_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_BOOKING_NETWORK_PAGES_COLLECTION,
    endPoint: "booking-network-pages/collected",
    mutationFail: GET_BOOKING_NETWORK_PAGES_COLLECTION_FAIL,
    mutationSuccess: GET_BOOKING_NETWORK_PAGES_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_BOOKING_NETWORK_PAGES_SINGLE,
    endPoint: "booking-network-pages/single",
    mutationFail: GET_BOOKING_NETWORK_PAGES_SINGLE_FAIL,
    mutationSuccess: GET_BOOKING_NETWORK_PAGES_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_BOOKING_NETWORK_PAGES,
    actionReset: CREATE_BOOKING_NETWORK_PAGES_RESET,
    endPoint: "booking-network-pages/create",
    mutationFail: CREATE_BOOKING_NETWORK_PAGES_FAIL,
    mutationSuccess: CREATE_BOOKING_NETWORK_PAGES_SUCCESS
  },
  editingRequest: {
    action: UPDATE_BOOKING_NETWORK_PAGES,
    actionReset: UPDATE_BOOKING_NETWORK_PAGES_RESET,
    endPoint: "booking-network-pages/update",
    mutationFail: UPDATE_BOOKING_NETWORK_PAGES_FAIL,
    mutationSuccess: UPDATE_BOOKING_NETWORK_PAGES_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_BOOKING_NETWORK_PAGES,
    actionReset: REPLACE_BOOKING_NETWORK_PAGES_RESET,
    endPoint: "booking-network-pages/replace",
    mutationFail: REPLACE_BOOKING_NETWORK_PAGES_FAIL,
    mutationSuccess: REPLACE_BOOKING_NETWORK_PAGES_SUCCESS
  },
  duplicateRequest: {
    action: DUPLICATE_BOOKING_NETWORK_PAGES,
    actionReset: DUPLICATE_BOOKING_NETWORK_PAGES_RESET,
    endPoint: "booking-network-pages/duplicate",
    mutationFail: DUPLICATE_BOOKING_NETWORK_PAGES_FAIL,
    mutationSuccess: DUPLICATE_BOOKING_NETWORK_PAGES_SUCCESS,
  },
  deletionRequest: {
    action: REMOVE_BOOKING_NETWORK_PAGES,
    endPoint: "booking-network-pages/delete",
    mutationFail: REMOVE_BOOKING_NETWORK_PAGES_FAIL,
    mutationSuccess: REMOVE_BOOKING_NETWORK_PAGES_SUCCESS
  }
});

const listProcessed = list.process({
  getListRequest: {
    action: GET_BOOKING_NETWORK_PAGES_LIST,
    endPoint: "booking-network-pages/list",
    mutationFail: GET_BOOKING_NETWORK_PAGES_LIST_FAIL,
    mutationSuccess: GET_BOOKING_NETWORK_PAGES_LIST_SUCCESS
  },
});

const actionsProcessed = action.processMultiple([
  {
    method: 'post',
    name: 'positionRequest',
    action: POSITION_BOOKING_NETWORK_PAGES,
    endPoint: 'booking-network-pages/position/{id}',
    mutations: {
      fail: POSITION_BOOKING_NETWORK_PAGES_FAIL,
      success: POSITION_BOOKING_NETWORK_PAGES_SUCCESS,
    },
  },
]);

const getters = {};

const actions = {
  ...crudProcessed.actions,
  ...listProcessed.actions,
  ...actionsProcessed.actions,
};

const state = {
  ...crudProcessed.state,
  ...listProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...crudProcessed.mutations,
  ...listProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
