export const GET_SUBSCRIPTION_COUPONS_LIST = "[SUBSCRIPTION_COUPONS] List Get";
export const GET_SUBSCRIPTION_COUPONS_LIST_SUCCESS = "[SUBSCRIPTION_COUPONS] List Get Success";
export const GET_SUBSCRIPTION_COUPONS_LIST_FAIL = "[SUBSCRIPTION_COUPONS] List Get Fail";

export const GET_SUBSCRIPTION_COUPONS_PAGINATION = "[SUBSCRIPTION_COUPONS] Pagination Get";
export const GET_SUBSCRIPTION_COUPONS_PAGINATION_SUCCESS = "[SUBSCRIPTION_COUPONS] Pagination Get Success";
export const GET_SUBSCRIPTION_COUPONS_PAGINATION_FAIL = "[SUBSCRIPTION_COUPONS] Pagination Get Fail";

export const GET_SUBSCRIPTION_COUPONS_COLLECTION = "[SUBSCRIPTION_COUPONS] Collection Get";
export const GET_SUBSCRIPTION_COUPONS_COLLECTION_SUCCESS = "[SUBSCRIPTION_COUPONS] Collection Get Success";
export const GET_SUBSCRIPTION_COUPONS_COLLECTION_FAIL = "[SUBSCRIPTION_COUPONS] Collection Get Fail";

export const GET_SUBSCRIPTION_COUPONS_SINGLE = "[SUBSCRIPTION_COUPONS] Single Get";
export const GET_SUBSCRIPTION_COUPONS_SINGLE_SUCCESS = "[SUBSCRIPTION_COUPONS] Single Get Success";
export const GET_SUBSCRIPTION_COUPONS_SINGLE_FAIL = "[SUBSCRIPTION_COUPONS] Single Get Fail";

export const CREATE_SUBSCRIPTION_COUPONS = "[SUBSCRIPTION_COUPONS] Create";
export const CREATE_SUBSCRIPTION_COUPONS_SUCCESS = "[SUBSCRIPTION_COUPONS] Create Success";
export const CREATE_SUBSCRIPTION_COUPONS_FAIL = "[SUBSCRIPTION_COUPONS] Create Fail";
export const CREATE_SUBSCRIPTION_COUPONS_RESET = "[SUBSCRIPTION_COUPONS] Create Reset";

export const UPDATE_SUBSCRIPTION_COUPONS = "[SUBSCRIPTION_COUPONS] Update";
export const UPDATE_SUBSCRIPTION_COUPONS_SUCCESS = "[SUBSCRIPTION_COUPONS] Update Success";
export const UPDATE_SUBSCRIPTION_COUPONS_FAIL = "[SUBSCRIPTION_COUPONS] Update Fail";
export const UPDATE_SUBSCRIPTION_COUPONS_RESET = "[SUBSCRIPTION_COUPONS] Update Reset";

export const REMOVE_SUBSCRIPTION_COUPONS = "[SUBSCRIPTION_COUPONS] Remove";
export const REMOVE_SUBSCRIPTION_COUPONS_SUCCESS = "[SUBSCRIPTION_COUPONS] Remove Success";
export const REMOVE_SUBSCRIPTION_COUPONS_FAIL = "[SUBSCRIPTION_COUPONS] Remove Fail";
