export const GET_OCTORATE_CONNECTIONS_PAGINATION = "[OCTORATE_CONNECTIONS] Pagination Get";
export const GET_OCTORATE_CONNECTIONS_PAGINATION_SUCCESS = "[OCTORATE_CONNECTIONS] Pagination Get Success";
export const GET_OCTORATE_CONNECTIONS_PAGINATION_FAIL = "[OCTORATE_CONNECTIONS] Pagination Get Fail";

export const GET_OCTORATE_CONNECTIONS_COLLECTION = "[OCTORATE_CONNECTIONS] Collection Get";
export const GET_OCTORATE_CONNECTIONS_COLLECTION_SUCCESS = "[OCTORATE_CONNECTIONS] Collection Get Success";
export const GET_OCTORATE_CONNECTIONS_COLLECTION_FAIL = "[OCTORATE_CONNECTIONS] Collection Get Fail";

export const GET_OCTORATE_CONNECTIONS_SINGLE = "[OCTORATE_CONNECTIONS] Single Get";
export const GET_OCTORATE_CONNECTIONS_SINGLE_SUCCESS = "[OCTORATE_CONNECTIONS] Single Get Success";
export const GET_OCTORATE_CONNECTIONS_SINGLE_FAIL = "[OCTORATE_CONNECTIONS] Single Get Fail";

export const CREATE_OCTORATE_CONNECTIONS = "[OCTORATE_CONNECTIONS] Create";
export const CREATE_OCTORATE_CONNECTIONS_SUCCESS = "[OCTORATE_CONNECTIONS] Create Success";
export const CREATE_OCTORATE_CONNECTIONS_FAIL = "[OCTORATE_CONNECTIONS] Create Fail";
export const CREATE_OCTORATE_CONNECTIONS_RESET = "[OCTORATE_CONNECTIONS] Create Reset";

export const UPDATE_OCTORATE_CONNECTIONS = "[OCTORATE_CONNECTIONS] Update";
export const UPDATE_OCTORATE_CONNECTIONS_SUCCESS = "[OCTORATE_CONNECTIONS] Update Success";
export const UPDATE_OCTORATE_CONNECTIONS_FAIL = "[OCTORATE_CONNECTIONS] Update Fail";
export const UPDATE_OCTORATE_CONNECTIONS_RESET = "[OCTORATE_CONNECTIONS] Update Reset";

export const REMOVE_OCTORATE_CONNECTIONS = "[OCTORATE_CONNECTIONS] Remove";
export const REMOVE_OCTORATE_CONNECTIONS_SUCCESS = "[OCTORATE_CONNECTIONS] Remove Success";
export const REMOVE_OCTORATE_CONNECTIONS_FAIL = "[OCTORATE_CONNECTIONS] Remove Fail";

export const IMPORT_OCTORATE_CONNECTIONS = "[OCTORATE_CONNECTIONS] Import";
export const IMPORT_OCTORATE_CONNECTIONS_SUCCESS = "[OCTORATE_CONNECTIONS] Import Success";
export const IMPORT_OCTORATE_CONNECTIONS_FAIL = "[OCTORATE_CONNECTIONS] Import Fail";

export const OCTORATE_CONNECTION_ACTION = "[OCTORATE_CONNECTIONS] Action";
export const OCTORATE_CONNECTION_ACTION_SUCCESS = "[OCTORATE_CONNECTIONS] Action Success";
export const OCTORATE_CONNECTION_ACTION_FAIL = "[OCTORATE_CONNECTIONS] Action Fail";

export const OCTORATE_CONNECTION_SYNC = "[OCTORATE_CONNECTIONS] Sync";
export const OCTORATE_CONNECTION_SYNC_SUCCESS = "[OCTORATE_CONNECTIONS] Sync Success";
export const OCTORATE_CONNECTION_SYNC_FAIL = "[OCTORATE_CONNECTIONS] Sync Fail";

export const OCTORATE_CONNECTION_ACTIVATE = "[OCTORATE_CONNECTIONS] Activate";
export const OCTORATE_CONNECTION_ACTIVATE_SUCCESS = "[OCTORATE_CONNECTIONS] Activate Success";
export const OCTORATE_CONNECTION_ACTIVATE_FAIL = "[OCTORATE_CONNECTIONS] Activate Fail";