export const GET_UNIT_ICALS_LIST = "[UNIT_ICALS] List Get";
export const GET_UNIT_ICALS_LIST_SUCCESS = "[UNIT_ICALS] List Get Success";
export const GET_UNIT_ICALS_LIST_FAIL = "[UNIT_ICALS] List Get Fail";

export const GET_UNIT_ICALS_PAGINATION = "[UNIT_ICALS] Pagination Get";
export const GET_UNIT_ICALS_PAGINATION_SUCCESS = "[UNIT_ICALS] Pagination Get Success";
export const GET_UNIT_ICALS_PAGINATION_FAIL = "[UNIT_ICALS] Pagination Get Fail";

export const GET_UNIT_ICALS_COLLECTION = "[UNIT_ICALS] Collection Get";
export const GET_UNIT_ICALS_COLLECTION_SUCCESS = "[UNIT_ICALS] Collection Get Success";
export const GET_UNIT_ICALS_COLLECTION_FAIL = "[UNIT_ICALS] Collection Get Fail";

export const GET_UNIT_ICALS_SINGLE = "[UNIT_ICALS] Single Get";
export const GET_UNIT_ICALS_SINGLE_SUCCESS = "[UNIT_ICALS] Single Get Success";
export const GET_UNIT_ICALS_SINGLE_FAIL = "[UNIT_ICALS] Single Get Fail";

export const CREATE_UNIT_ICALS = "[UNIT_ICALS] Create";
export const CREATE_UNIT_ICALS_SUCCESS = "[UNIT_ICALS] Create Success";
export const CREATE_UNIT_ICALS_FAIL = "[UNIT_ICALS] Create Fail";
export const CREATE_UNIT_ICALS_RESET = "[UNIT_ICALS] Create Reset";

export const UPDATE_UNIT_ICALS = "[UNIT_ICALS] Update";
export const UPDATE_UNIT_ICALS_SUCCESS = "[UNIT_ICALS] Update Success";
export const UPDATE_UNIT_ICALS_FAIL = "[UNIT_ICALS] Update Fail";
export const UPDATE_UNIT_ICALS_RESET = "[UNIT_ICALS] Update Reset";

export const REMOVE_UNIT_ICALS = "[UNIT_ICALS] Remove";
export const REMOVE_UNIT_ICALS_SUCCESS = "[UNIT_ICALS] Remove Success";
export const REMOVE_UNIT_ICALS_FAIL = "[UNIT_ICALS] Remove Fail";
