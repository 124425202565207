import ApiService from "@/services/api";

import list from "@/store/core/list";
import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_UNIT_CATEGORY_COSTS_LIST,
  GET_UNIT_CATEGORY_COSTS_LIST_SUCCESS,
  GET_UNIT_CATEGORY_COSTS_LIST_FAIL,
  GET_UNIT_CATEGORY_COSTS_PAGINATION,
  GET_UNIT_CATEGORY_COSTS_PAGINATION_SUCCESS,
  GET_UNIT_CATEGORY_COSTS_PAGINATION_FAIL,
  GET_UNIT_CATEGORY_COSTS_COLLECTION,
  GET_UNIT_CATEGORY_COSTS_COLLECTION_SUCCESS,
  GET_UNIT_CATEGORY_COSTS_COLLECTION_FAIL,
  GET_UNIT_CATEGORY_COSTS_SINGLE,
  GET_UNIT_CATEGORY_COSTS_SINGLE_SUCCESS,
  GET_UNIT_CATEGORY_COSTS_SINGLE_FAIL,
  CREATE_UNIT_CATEGORY_COSTS,
  CREATE_UNIT_CATEGORY_COSTS_SUCCESS,
  CREATE_UNIT_CATEGORY_COSTS_FAIL,
  CREATE_UNIT_CATEGORY_COSTS_RESET,
  UPDATE_UNIT_CATEGORY_COSTS,
  UPDATE_UNIT_CATEGORY_COSTS_SUCCESS,
  UPDATE_UNIT_CATEGORY_COSTS_FAIL,
  UPDATE_UNIT_CATEGORY_COSTS_RESET,
  REMOVE_UNIT_CATEGORY_COSTS,
  REMOVE_UNIT_CATEGORY_COSTS_SUCCESS,
  REMOVE_UNIT_CATEGORY_COSTS_FAIL,
  BULK_REMOVE_UNIT_CATEGORY_COSTS,
  BULK_REMOVE_UNIT_CATEGORY_COSTS_SUCCESS,
  BULK_REMOVE_UNIT_CATEGORY_COSTS_FAIL,
  CREATE_UNIT_CATEGORY_COSTS_BULK,
  CREATE_UNIT_CATEGORY_COSTS_BULK_SUCCESS,
  CREATE_UNIT_CATEGORY_COSTS_BULK_FAIL,
  CREATE_UNIT_CATEGORY_COSTS_BULK_RESET,
  REGENERATE_UNIT_CATEGORY_COSTS,
  REGENERATE_UNIT_CATEGORY_COSTS_SUCCESS,
  REGENERATE_UNIT_CATEGORY_COSTS_FAIL,

  REGENERATE_BULK_UNIT_CATEGORY_COSTS,
  REGENERATE_BULK_UNIT_CATEGORY_COSTS_SUCCESS,
  REGENERATE_BULK_UNIT_CATEGORY_COSTS_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_UNIT_CATEGORY_COSTS_LIST,
    endPoint: "unit-category-costs/list",
    mutationFail: GET_UNIT_CATEGORY_COSTS_LIST_FAIL,
    mutationSuccess: GET_UNIT_CATEGORY_COSTS_LIST_SUCCESS
  }
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_UNIT_CATEGORY_COSTS_PAGINATION,
    endPoint: "unit-category-costs/paginated",
    mutationFail: GET_UNIT_CATEGORY_COSTS_PAGINATION_FAIL,
    mutationSuccess: GET_UNIT_CATEGORY_COSTS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_UNIT_CATEGORY_COSTS_COLLECTION,
    endPoint: "unit-category-costs/collected",
    mutationFail: GET_UNIT_CATEGORY_COSTS_COLLECTION_FAIL,
    mutationSuccess: GET_UNIT_CATEGORY_COSTS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_UNIT_CATEGORY_COSTS_SINGLE,
    endPoint: "unit-category-costs/single",
    mutationFail: GET_UNIT_CATEGORY_COSTS_SINGLE_FAIL,
    mutationSuccess: GET_UNIT_CATEGORY_COSTS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_UNIT_CATEGORY_COSTS,
    actionReset: CREATE_UNIT_CATEGORY_COSTS_RESET,
    endPoint: "unit-category-costs/create",
    mutationFail: CREATE_UNIT_CATEGORY_COSTS_FAIL,
    mutationSuccess: CREATE_UNIT_CATEGORY_COSTS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_UNIT_CATEGORY_COSTS,
    actionReset: UPDATE_UNIT_CATEGORY_COSTS_RESET,
    endPoint: "unit-category-costs/update",
    mutationFail: UPDATE_UNIT_CATEGORY_COSTS_FAIL,
    mutationSuccess: UPDATE_UNIT_CATEGORY_COSTS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_UNIT_CATEGORY_COSTS,
    endPoint: "unit-category-costs/delete",
    mutationFail: REMOVE_UNIT_CATEGORY_COSTS_FAIL,
    mutationSuccess: REMOVE_UNIT_CATEGORY_COSTS_SUCCESS
  },
  bulkDeletionRequest: {
    action: BULK_REMOVE_UNIT_CATEGORY_COSTS,
    endPoint: "/unit-category-costs/bulk-delete",
    mutationFail: BULK_REMOVE_UNIT_CATEGORY_COSTS_FAIL,
    mutationSuccess: BULK_REMOVE_UNIT_CATEGORY_COSTS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    method: 'post',
    name: 'regenerateUnitCosts',
    action: REGENERATE_UNIT_CATEGORY_COSTS,
    endPoint: 'unit-category-costs/regenerate',
    mutations: {
      fail: REGENERATE_UNIT_CATEGORY_COSTS_FAIL,
      success: REGENERATE_UNIT_CATEGORY_COSTS_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'regenerateBulkUnitCosts',
    action: REGENERATE_BULK_UNIT_CATEGORY_COSTS,
    endPoint: 'unit-category-costs/regenerate-bulk',
    mutations: {
      fail: REGENERATE_BULK_UNIT_CATEGORY_COSTS_FAIL,
      success: REGENERATE_BULK_UNIT_CATEGORY_COSTS_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  creationBulkRequest: {
    loading: false,
    success: false,
    fail: false,
    validation: {},
    response: {}
  },
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  [CREATE_UNIT_CATEGORY_COSTS_BULK_RESET](context) {
    context.state.creationBulkRequest = {
      loading: false,
      success: false,
      fail: false,
      validation: {},
      response: {}
    };
  },
  [CREATE_UNIT_CATEGORY_COSTS_BULK](context, params) {
    context.state.creationBulkRequest.loading = true;

    return new Promise((resolve, reject) => {
      ApiService.post(`unit-category-costs/create-bulk`, params)
        .then(response => {
          context.commit(CREATE_UNIT_CATEGORY_COSTS_BULK_SUCCESS, response.data.data);
          resolve(response.data.data);
        })
        .catch(({ response }) => {
          context.commit(CREATE_UNIT_CATEGORY_COSTS_BULK_FAIL, response.data.errors);
          reject(response.data.errors);
        });
    });
  },
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  [CREATE_UNIT_CATEGORY_COSTS_BULK_SUCCESS](state, data) {
    state.creationBulkRequest = {
      loading: false,
      success: true,
      fail: false,
      validation: {},
      response: data
    };
  },
  [CREATE_UNIT_CATEGORY_COSTS_BULK_FAIL](state, data) {
    state.creationBulkRequest = {
      loading: false,
      success: false,
      fail: true,
      validation: data,
      response: {}
    };
  },
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
