import crud from "@/store/core/crud";
import action from "@/store/core/action";
import ApiService from "@/services/api";
import BroadcastService from "@/services/broadcast";

import {
  GET_LEGAL_COMPLIANCE_PAGINATION,
  GET_LEGAL_COMPLIANCE_PAGINATION_SUCCESS,
  GET_LEGAL_COMPLIANCE_PAGINATION_FAIL,
  GET_LEGAL_COMPLIANCE_COLLECTION,
  GET_LEGAL_COMPLIANCE_COLLECTION_SUCCESS,
  GET_LEGAL_COMPLIANCE_COLLECTION_FAIL,
  GET_LEGAL_COMPLIANCE_SINGLE,
  GET_LEGAL_COMPLIANCE_SINGLE_SUCCESS,
  GET_LEGAL_COMPLIANCE_SINGLE_FAIL,
  CREATE_LEGAL_COMPLIANCE,
  CREATE_LEGAL_COMPLIANCE_SUCCESS,
  CREATE_LEGAL_COMPLIANCE_FAIL,
  CREATE_LEGAL_COMPLIANCE_RESET,
  UPDATE_LEGAL_COMPLIANCE,
  UPDATE_LEGAL_COMPLIANCE_SUCCESS,
  UPDATE_LEGAL_COMPLIANCE_FAIL,
  UPDATE_LEGAL_COMPLIANCE_RESET,
  REPLACE_LEGAL_COMPLIANCE,
  REPLACE_LEGAL_COMPLIANCE_SUCCESS,
  REPLACE_LEGAL_COMPLIANCE_FAIL,
  REPLACE_LEGAL_COMPLIANCE_RESET,
  REMOVE_LEGAL_COMPLIANCE,
  REMOVE_LEGAL_COMPLIANCE_SUCCESS,
  REMOVE_LEGAL_COMPLIANCE_FAIL,
  LEGAL_COMPLIANCE_SEND_POLICE,
  LEGAL_COMPLIANCE_SEND_POLICE_SUCCESS,
  LEGAL_COMPLIANCE_SEND_POLICE_FAIL,
  LEGAL_COMPLIANCE_SEND_PAYTOURIST,
  LEGAL_COMPLIANCE_SEND_PAYTOURIST_SUCCESS,
  LEGAL_COMPLIANCE_SEND_PAYTOURIST_FAIL,
  LISTEN_LEGAL_COMPLIANCE_UNCONFIRMED,
  LEGAL_COMPLIANCE_DOWNLOAD_FILE,
  LEGAL_COMPLIANCE_CALCULATE_CITY_TAX,
  LEGAL_COMPLIANCE_CALCULATE_CITY_TAX_SUCCESS,
  LEGAL_COMPLIANCE_CALCULATE_CITY_TAX_FAIL
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_LEGAL_COMPLIANCE_PAGINATION,
    endPoint: "legal-compliance/paginated",
    mutationFail: GET_LEGAL_COMPLIANCE_PAGINATION_FAIL,
    mutationSuccess: GET_LEGAL_COMPLIANCE_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_LEGAL_COMPLIANCE_COLLECTION,
    endPoint: "legal-compliance/collected",
    mutationFail: GET_LEGAL_COMPLIANCE_COLLECTION_FAIL,
    mutationSuccess: GET_LEGAL_COMPLIANCE_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_LEGAL_COMPLIANCE_SINGLE,
    endPoint: "legal-compliance/single",
    mutationFail: GET_LEGAL_COMPLIANCE_SINGLE_FAIL,
    mutationSuccess: GET_LEGAL_COMPLIANCE_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_LEGAL_COMPLIANCE,
    actionReset: CREATE_LEGAL_COMPLIANCE_RESET,
    endPoint: "legal-compliance/create",
    mutationFail: CREATE_LEGAL_COMPLIANCE_FAIL,
    mutationSuccess: CREATE_LEGAL_COMPLIANCE_SUCCESS
  },
  editingRequest: {
    action: UPDATE_LEGAL_COMPLIANCE,
    actionReset: UPDATE_LEGAL_COMPLIANCE_RESET,
    endPoint: "legal-compliance/update",
    mutationFail: UPDATE_LEGAL_COMPLIANCE_FAIL,
    mutationSuccess: UPDATE_LEGAL_COMPLIANCE_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_LEGAL_COMPLIANCE,
    actionReset: REPLACE_LEGAL_COMPLIANCE_RESET,
    endPoint: "legal-compliance/replace",
    mutationFail: REPLACE_LEGAL_COMPLIANCE_FAIL,
    mutationSuccess: REPLACE_LEGAL_COMPLIANCE_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_LEGAL_COMPLIANCE,
    endPoint: "legal-compliance/delete",
    mutationFail: REMOVE_LEGAL_COMPLIANCE_FAIL,
    mutationSuccess: REMOVE_LEGAL_COMPLIANCE_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    name: 'getSendPoliceRequest',
    action: LEGAL_COMPLIANCE_SEND_POLICE,
    endPoint: 'legal-compliance/send/police/{id}',
    mutations: {
      fail: LEGAL_COMPLIANCE_SEND_POLICE_FAIL,
      success: LEGAL_COMPLIANCE_SEND_POLICE_SUCCESS,
    },
  },
  {
    name: 'getSendPaytouristRequest',
    action: LEGAL_COMPLIANCE_SEND_PAYTOURIST,
    endPoint: 'legal-compliance/send/paytourist/{id}',
    mutations: {
      fail: LEGAL_COMPLIANCE_SEND_PAYTOURIST_FAIL,
      success: LEGAL_COMPLIANCE_SEND_PAYTOURIST_SUCCESS,
    },
  },
  {
    name: 'getCalculateCityTax',
    action: LEGAL_COMPLIANCE_CALCULATE_CITY_TAX,
    endPoint: 'legal-compliance/{id}/calculate-city-tax',
    mutations: {
      fail: LEGAL_COMPLIANCE_CALCULATE_CITY_TAX_FAIL,
      success: LEGAL_COMPLIANCE_CALCULATE_CITY_TAX_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  [LEGAL_COMPLIANCE_DOWNLOAD_FILE](context, id) {
    ApiService.download(`legal-compliance/allogiatti-file/download/${id}`, true);
  },
  [LISTEN_LEGAL_COMPLIANCE_UNCONFIRMED] (context) {
    BroadcastService.listen('legal-compliance-requests', '.unconfirmed.changed', (data) => {
      context.commit(GET_LEGAL_COMPLIANCE_COLLECTION_SUCCESS, data);
    });
  },
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
