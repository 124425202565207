import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_CLIENTS_PAGINATION,
  GET_CLIENTS_PAGINATION_SUCCESS,
  GET_CLIENTS_PAGINATION_FAIL,
  GET_CLIENTS_COLLECTION,
  GET_CLIENTS_COLLECTION_SUCCESS,
  GET_CLIENTS_COLLECTION_FAIL,
  GET_CLIENTS_SINGLE,
  GET_CLIENTS_SINGLE_SUCCESS,
  GET_CLIENTS_SINGLE_FAIL,
  CREATE_CLIENTS,
  CREATE_CLIENTS_SUCCESS,
  CREATE_CLIENTS_FAIL,
  CREATE_CLIENTS_RESET,
  UPDATE_CLIENTS,
  UPDATE_CLIENTS_SUCCESS,
  UPDATE_CLIENTS_FAIL,
  UPDATE_CLIENTS_RESET,
  REMOVE_CLIENTS,
  REMOVE_CLIENTS_SUCCESS,
  REMOVE_CLIENTS_FAIL,
  GET_CLIENTS_DUPLICATES,
  GET_CLIENTS_DUPLICATES_SUCCESS,
  GET_CLIENTS_DUPLICATES_FAIL,
  MERGE_CLIENTS_DUPLICATES,
  MERGE_CLIENTS_DUPLICATES_SUCCESS,
  MERGE_CLIENTS_DUPLICATES_FAIL,
  MERGE_CLIENTS_DUPLICATES_ALL,
  MERGE_CLIENTS_DUPLICATES_ALL_SUCCESS,
  MERGE_CLIENTS_DUPLICATES_ALL_FAIL,
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_CLIENTS_PAGINATION,
    endPoint: "clients/paginated",
    mutationFail: GET_CLIENTS_PAGINATION_FAIL,
    mutationSuccess: GET_CLIENTS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_CLIENTS_COLLECTION,
    endPoint: "clients/collected",
    mutationFail: GET_CLIENTS_COLLECTION_FAIL,
    mutationSuccess: GET_CLIENTS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_CLIENTS_SINGLE,
    endPoint: "clients/single",
    mutationFail: GET_CLIENTS_SINGLE_FAIL,
    mutationSuccess: GET_CLIENTS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_CLIENTS,
    actionReset: CREATE_CLIENTS_RESET,
    endPoint: "clients/create",
    mutationFail: CREATE_CLIENTS_FAIL,
    mutationSuccess: CREATE_CLIENTS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_CLIENTS,
    actionReset: UPDATE_CLIENTS_RESET,
    endPoint: "clients/update",
    mutationFail: UPDATE_CLIENTS_FAIL,
    mutationSuccess: UPDATE_CLIENTS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_CLIENTS,
    endPoint: "clients/delete",
    mutationFail: REMOVE_CLIENTS_FAIL,
    mutationSuccess: REMOVE_CLIENTS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    name: 'getDuplicatesRequest',
    action: GET_CLIENTS_DUPLICATES,
    endPoint: 'clients/duplicates',
    mutations: {
      fail: GET_CLIENTS_DUPLICATES_FAIL,
      success: GET_CLIENTS_DUPLICATES_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'mergeDuplicatesRequest',
    action: MERGE_CLIENTS_DUPLICATES,
    endPoint: 'clients/duplicates/{id}/merge',
    mutations: {
      fail: MERGE_CLIENTS_DUPLICATES_FAIL,
      success: MERGE_CLIENTS_DUPLICATES_SUCCESS,
      successCallback(state, data) {
        const removedId = state.mergeDuplicatesRequest.params.id;
        const currentValues = state.getDuplicatesRequest.data.collection;

        state.getDuplicatesRequest.data.collection = currentValues.filter(item => item.id !== removedId);
      }
    },
  },
  {
    method: 'post',
    name: 'mergeDuplicatesAllRequest',
    action: MERGE_CLIENTS_DUPLICATES_ALL,
    endPoint: 'clients/duplicates/merge/all',
    mutations: {
      fail: MERGE_CLIENTS_DUPLICATES_ALL_FAIL,
      success: MERGE_CLIENTS_DUPLICATES_ALL_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
