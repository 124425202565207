export const GET_BOOKING_NETWORK_PROPERTIES_LIST = "[BOOKING_NETWORK_PROPERTIES] List Get";
export const GET_BOOKING_NETWORK_PROPERTIES_LIST_SUCCESS = "[BOOKING_NETWORK_PROPERTIES] List Get Success";
export const GET_BOOKING_NETWORK_PROPERTIES_LIST_FAIL = "[BOOKING_NETWORK_PROPERTIES] List Get Fail";

export const GET_BOOKING_NETWORK_PROPERTIES_PAGINATION = "[BOOKING_NETWORK_PROPERTIES] Pagination Get";
export const GET_BOOKING_NETWORK_PROPERTIES_PAGINATION_SUCCESS = "[BOOKING_NETWORK_PROPERTIES] Pagination Get Success";
export const GET_BOOKING_NETWORK_PROPERTIES_PAGINATION_FAIL = "[BOOKING_NETWORK_PROPERTIES] Pagination Get Fail";

export const GET_BOOKING_NETWORK_PROPERTIES_COLLECTION = "[BOOKING_NETWORK_PROPERTIES] Collection Get";
export const GET_BOOKING_NETWORK_PROPERTIES_COLLECTION_SUCCESS = "[BOOKING_NETWORK_PROPERTIES] Collection Get Success";
export const GET_BOOKING_NETWORK_PROPERTIES_COLLECTION_FAIL = "[BOOKING_NETWORK_PROPERTIES] Collection Get Fail";

export const GET_BOOKING_NETWORK_PROPERTIES_SINGLE = "[BOOKING_NETWORK_PROPERTIES] Single Get";
export const GET_BOOKING_NETWORK_PROPERTIES_SINGLE_SUCCESS = "[BOOKING_NETWORK_PROPERTIES] Single Get Success";
export const GET_BOOKING_NETWORK_PROPERTIES_SINGLE_FAIL = "[BOOKING_NETWORK_PROPERTIES] Single Get Fail";

export const CREATE_BOOKING_NETWORK_PROPERTIES = "[BOOKING_NETWORK_PROPERTIES] Create";
export const CREATE_BOOKING_NETWORK_PROPERTIES_SUCCESS = "[BOOKING_NETWORK_PROPERTIES] Create Success";
export const CREATE_BOOKING_NETWORK_PROPERTIES_FAIL = "[BOOKING_NETWORK_PROPERTIES] Create Fail";
export const CREATE_BOOKING_NETWORK_PROPERTIES_RESET = "[BOOKING_NETWORK_PROPERTIES] Create Reset";

export const UPDATE_BOOKING_NETWORK_PROPERTIES = "[BOOKING_NETWORK_PROPERTIES] Update";
export const UPDATE_BOOKING_NETWORK_PROPERTIES_SUCCESS = "[BOOKING_NETWORK_PROPERTIES] Update Success";
export const UPDATE_BOOKING_NETWORK_PROPERTIES_FAIL = "[BOOKING_NETWORK_PROPERTIES] Update Fail";
export const UPDATE_BOOKING_NETWORK_PROPERTIES_RESET = "[BOOKING_NETWORK_PROPERTIES] Update Reset";

export const REMOVE_BOOKING_NETWORK_PROPERTIES = "[BOOKING_NETWORK_PROPERTIES] Remove";
export const REMOVE_BOOKING_NETWORK_PROPERTIES_SUCCESS = "[BOOKING_NETWORK_PROPERTIES] Remove Success";
export const REMOVE_BOOKING_NETWORK_PROPERTIES_FAIL = "[BOOKING_NETWORK_PROPERTIES] Remove Fail";
