<template>
  <v-select
    v-model="selectValue"
    :items="dataLocale"
    :loading="loading"
    :label="label"
    :hint="hint"
    :single-line="singleLine"
    :hide-details="hideDetails"
    :error="error"
    :error-messages="errorMessages"
    :prepend-icon="prependIcon"
    :append-icon="appendIcon"
    :disabled="disabled"
    :readonly="readonly"
    :persistent-hint="persistentHint"
  >
    <template v-slot:item="{item}">
      <lang-flag class="mr-2" :iso="item" :squared="false" />
        {{item.toUpperCase()}}
    </template>
    <template v-slot:selection="{item}">
      <lang-flag class="mr-2" :iso="item" :squared="false" />
      {{item.toUpperCase()}}
    </template>
  </v-select>
</template>

<script>
import { mapState } from "vuex";

import { DATA_LOCALE } from "@/store/data/actions";

export default {
  name: "VLocale",
  props: {
    value: {
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      required: false,
    },
    errorMessages: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    appendIcon: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      selectValue: null
    }
  },
  beforeMount() {
    this.selectValue = this.value;

    if (!this.dataLocale.length) {
      this.getLocales()
    }
  },
  methods: {
    getLocales() {
      this.$store.dispatch(DATA_LOCALE);
    }
  },
  watch: {
    value() {
      this.selectValue = this.value;
    },
    selectValue(newVal) {
      this.$emit('input', newVal);
    }
  },
  computed: {
    ...mapState({
      dataLocale: state => state.data.dataLocale.data,
    })
  }
}
</script>
