import ApiService from "@/services/api";
import action from "@/store/core/action";

import {
  GET_STATISTICS_OCCUPANCY,
  GET_STATISTICS_OCCUPANCY_SUCCESS,
  GET_STATISTICS_OCCUPANCY_FAIL,

  GET_STATISTICS_SOURCES,
  GET_STATISTICS_SOURCES_SUCCESS,
  GET_STATISTICS_SOURCES_FAIL,

  GET_CITY_TAX,
  GET_CITY_TAX_SUCCESS,
  GET_CITY_TAX_FAIL,

  GET_CITY_TAX_DETAILS,
  GET_CITY_TAX_DETAILS_SUCCESS,
  GET_CITY_TAX_DETAILS_FAIL,

  GET_REVENUES,
  GET_REVENUES_SUCCESS,
  GET_REVENUES_FAIL,

  GET_RESERVATIONS_REPORT,
  GET_RESERVATIONS_REPORT_FAIL,
  GET_RESERVATIONS_REPORT_SUCCESS,

  GET_ECONOMICS,
  GET_ECONOMICS_SUCCESS,
  GET_ECONOMICS_FAIL
} from "./actions";

const actionsProcessed = action.processMultiple([
  {
    name: 'getOccupancyRequest',
    action: GET_STATISTICS_OCCUPANCY,
    endPoint: 'statistics/occupancy',
    mutations: {
      fail: GET_STATISTICS_OCCUPANCY_FAIL,
      success: GET_STATISTICS_OCCUPANCY_SUCCESS,
    },
  },
  {
    name: 'getSourcesRequest',
    action: GET_STATISTICS_SOURCES,
    endPoint: 'statistics/sources',
    mutations: {
      fail: GET_STATISTICS_SOURCES_FAIL,
      success: GET_STATISTICS_SOURCES_SUCCESS,
    },
  },
  {
    name: 'getCityTaxRequest',
    action: GET_CITY_TAX,
    endPoint: 'statistics/city-tax',
    mutations: {
      fail: GET_CITY_TAX_FAIL,
      success: GET_CITY_TAX_SUCCESS,
    },
  },
  {
    name: 'getCityTaxDetailsRequest',
    action: GET_CITY_TAX_DETAILS,
    endPoint: 'statistics/city-tax-details',
    mutations: {
      fail: GET_CITY_TAX_DETAILS_FAIL,
      success: GET_CITY_TAX_DETAILS_SUCCESS,
    },
  },
  {
    name: 'getReservationsReportRequest',
    action: GET_RESERVATIONS_REPORT,
    endPoint: 'reports/reservations',
    mutations: {
      fail: GET_RESERVATIONS_REPORT_FAIL,
      success: GET_RESERVATIONS_REPORT_SUCCESS,
    },
  },
  {
    name: 'getEconomicsRequest',
    action: GET_ECONOMICS,
    endPoint: 'statistics/economics',
    mutations: {
      fail: GET_ECONOMICS_FAIL,
      success: GET_ECONOMICS_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  ...actionsProcessed.state,
};

const actions = {
  ...actionsProcessed.actions,
};

const mutations = {
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
