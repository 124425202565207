import { Node, mergeAttributes } from '@tiptap/core';

export const CtaButton = Node.create({
  name: 'ctaButton',

  group: 'inline',
  inline: true,
  selectable: true,
  draggable: true,

  addAttributes() {
    return {
      href: {
        default: '',
      },
      text: {
        default: 'Click Me',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span.msohide.es-button-border',
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      {
        class: 'msohide es-button-border',
        style: 'border-style:solid;border-color:#616161;background:#616161;border-width:2px;display:inline-block;border-radius:5px;width:auto;mso-hide:all',
      },
      [
        'a',
        mergeAttributes(HTMLAttributes, {
          href: node.attrs.href,
          class: 'es-button',
          target: '_blank',
          style: 'mso-style-priority:100 !important;text-decoration:none;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;color:#FFFFFF;font-size:20px;border-style:solid;border-color:#5c68e2;border-width:10px 30px 10px 30px;display:inline-block;background:#5c68e2;border-radius:5px;font-family:arial, "helvetica neue", helvetica, sans-serif;font-weight:normal;font-style:normal;line-height:24px;width:auto;text-align:center',
        }),
        node.attrs.text,
      ],
    ];
  },

  addCommands() {
    return {
      insertButton: (href, text) => ({ commands }) => {
        return commands.insertContent({
          type: this.name,
          attrs: { href, text },
        });
      },
    };
  },
});
