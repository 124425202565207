export const GET_SETTINGS_COMPANIES_COLLECTION = "[SETTINGS] Companies Collection Get";
export const GET_SETTINGS_COMPANIES_COLLECTION_SUCCESS = "[SETTINGS] Companies Collection Get Success";
export const GET_SETTINGS_COMPANIES_COLLECTION_FAIL = "[SETTINGS] Companies Collection Get Fail";

export const STORE_PROPERTY_IDS = "[SETTINGS] Store Property IDs in Session";
export const RETRIEVE_PROPERTY_IDS = "[SETTINGS] Retrieve Property IDs in Session";

export const STORE_FILTERS_SETTINGS = "[SETTINGS] Store Filters Settings in Session";
export const RETRIEVE_FILTERS_SETTINGS = "[SETTINGS] Retrieve Filters Settings in Session";

export const INACTIVATE_HELP = "[SETTINGS] Inactivate Help";

export const DISMISS_NOTIFICATION_BANNER = "[SETTINGS] Dismiss Notifications Banner Alert";
export const DISMISS_ANNOUNCEMENT = "[SETTINGS] Dismiss Announcement";
export const DISMISS_TOUR = "[SETTINGS] Dismiss Tour";

export const FORGET_SETTINGS = "[SETTINGS] Forget";

export const TOGGLE_SIDEBAR_MENU = "[SETTINGS] Toggle Sidebar";

export const STORE_WHITELABEL = "[SETTINGS] Whitelabel";