export const GET_FONTS_LIST = "[FONTS] List Get";
export const GET_FONTS_LIST_SUCCESS = "[FONTS] List Get Success";
export const GET_FONTS_LIST_FAIL = "[FONTS] List Get Fail";

export const GET_FONTS_PAGINATION = "[FONTS] Pagination Get";
export const GET_FONTS_PAGINATION_SUCCESS = "[FONTS] Pagination Get Success";
export const GET_FONTS_PAGINATION_FAIL = "[FONTS] Pagination Get Fail";

export const GET_FONTS_COLLECTION = "[FONTS] Collection Get";
export const GET_FONTS_COLLECTION_SUCCESS = "[FONTS] Collection Get Success";
export const GET_FONTS_COLLECTION_FAIL = "[FONTS] Collection Get Fail";

export const GET_FONTS_SINGLE = "[FONTS] Single Get";
export const GET_FONTS_SINGLE_SUCCESS = "[FONTS] Single Get Success";
export const GET_FONTS_SINGLE_FAIL = "[FONTS] Single Get Fail";

export const CREATE_FONTS = "[FONTS] Create";
export const CREATE_FONTS_SUCCESS = "[FONTS] Create Success";
export const CREATE_FONTS_FAIL = "[FONTS] Create Fail";
export const CREATE_FONTS_RESET = "[FONTS] Create Reset";

export const UPDATE_FONTS = "[FONTS] Update";
export const UPDATE_FONTS_SUCCESS = "[FONTS] Update Success";
export const UPDATE_FONTS_FAIL = "[FONTS] Update Fail";
export const UPDATE_FONTS_RESET = "[FONTS] Update Reset";

export const REPLACE_FONTS = "[FONTS] Replace";
export const REPLACE_FONTS_SUCCESS = "[FONTS] Replace Success";
export const REPLACE_FONTS_FAIL = "[FONTS] Replace Fail";
export const REPLACE_FONTS_RESET = "[FONTS] Replace Reset";

export const REMOVE_FONTS = "[FONTS] Remove";
export const REMOVE_FONTS_SUCCESS = "[FONTS] Remove Success";
export const REMOVE_FONTS_FAIL = "[FONTS] Remove Fail";