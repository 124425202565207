export const GET_CLUSTERS_LIST = "[CLUSTERS] List Get";
export const GET_CLUSTERS_LIST_SUCCESS = "[CLUSTERS] List Get Success";
export const GET_CLUSTERS_LIST_FAIL = "[CLUSTERS] List Get Fail";

export const GET_CLUSTERS_PAGINATION = "[CLUSTERS] Pagination Get";
export const GET_CLUSTERS_PAGINATION_SUCCESS = "[CLUSTERS] Pagination Get Success";
export const GET_CLUSTERS_PAGINATION_FAIL = "[CLUSTERS] Pagination Get Fail";

export const GET_CLUSTERS_COLLECTION = "[CLUSTERS] Collection Get";
export const GET_CLUSTERS_COLLECTION_SUCCESS = "[CLUSTERS] Collection Get Success";
export const GET_CLUSTERS_COLLECTION_FAIL = "[CLUSTERS] Collection Get Fail";

export const GET_CLUSTERS_SINGLE = "[CLUSTERS] Single Get";
export const GET_CLUSTERS_SINGLE_SUCCESS = "[CLUSTERS] Single Get Success";
export const GET_CLUSTERS_SINGLE_FAIL = "[CLUSTERS] Single Get Fail";

export const CREATE_CLUSTERS = "[CLUSTERS] Create";
export const CREATE_CLUSTERS_SUCCESS = "[CLUSTERS] Create Success";
export const CREATE_CLUSTERS_FAIL = "[CLUSTERS] Create Fail";
export const CREATE_CLUSTERS_RESET = "[CLUSTERS] Create Reset";

export const UPDATE_CLUSTERS = "[CLUSTERS] Update";
export const UPDATE_CLUSTERS_SUCCESS = "[CLUSTERS] Update Success";
export const UPDATE_CLUSTERS_FAIL = "[CLUSTERS] Update Fail";
export const UPDATE_CLUSTERS_RESET = "[CLUSTERS] Update Reset";

export const REMOVE_CLUSTERS = "[CLUSTERS] Remove";
export const REMOVE_CLUSTERS_SUCCESS = "[CLUSTERS] Remove Success";
export const REMOVE_CLUSTERS_FAIL = "[CLUSTERS] Remove Fail";
