export const GET_TICKETS_LIST = "[TICKETS] List Get";
export const GET_TICKETS_LIST_SUCCESS = "[TICKETS] List Get Success";
export const GET_TICKETS_LIST_FAIL = "[TICKETS] List Get Fail";

export const GET_TICKETS_PAGINATION = "[TICKETS] Pagination Get";
export const GET_TICKETS_PAGINATION_SUCCESS = "[TICKETS] Pagination Get Success";
export const GET_TICKETS_PAGINATION_FAIL = "[TICKETS] Pagination Get Fail";

export const GET_TICKETS_COLLECTION = "[TICKETS] Collection Get";
export const GET_TICKETS_COLLECTION_SUCCESS = "[TICKETS] Collection Get Success";
export const GET_TICKETS_COLLECTION_FAIL = "[TICKETS] Collection Get Fail";

export const GET_TICKETS_SINGLE = "[TICKETS] Single Get";
export const GET_TICKETS_SINGLE_SUCCESS = "[TICKETS] Single Get Success";
export const GET_TICKETS_SINGLE_FAIL = "[TICKETS] Single Get Fail";

export const CREATE_TICKETS = "[TICKETS] Create";
export const CREATE_TICKETS_SUCCESS = "[TICKETS] Create Success";
export const CREATE_TICKETS_FAIL = "[TICKETS] Create Fail";
export const CREATE_TICKETS_RESET = "[TICKETS] Create Reset";

export const UPDATE_TICKETS = "[TICKETS] Update";
export const UPDATE_TICKETS_SUCCESS = "[TICKETS] Update Success";
export const UPDATE_TICKETS_FAIL = "[TICKETS] Update Fail";
export const UPDATE_TICKETS_RESET = "[TICKETS] Update Reset";

export const REPLACE_TICKETS = "[TICKETS] Replace";
export const REPLACE_TICKETS_SUCCESS = "[TICKETS] Replace Success";
export const REPLACE_TICKETS_FAIL = "[TICKETS] Replace Fail";
export const REPLACE_TICKETS_RESET = "[TICKETS] Replace Reset";

export const REMOVE_TICKETS = "[TICKETS] Remove";
export const REMOVE_TICKETS_SUCCESS = "[TICKETS] Remove Success";
export const REMOVE_TICKETS_FAIL = "[TICKETS] Remove Fail";