export const GET_PRODUCTS_LIST = "[PRODUCTS] List Get";
export const GET_PRODUCTS_LIST_SUCCESS = "[PRODUCTS] List Get Success";
export const GET_PRODUCTS_LIST_FAIL = "[PRODUCTS] List Get Fail";

export const GET_PRODUCTS_PAGINATION = "[PRODUCTS] Pagination Get";
export const GET_PRODUCTS_PAGINATION_SUCCESS = "[PRODUCTS] Pagination Get Success";
export const GET_PRODUCTS_PAGINATION_FAIL = "[PRODUCTS] Pagination Get Fail";

export const GET_PRODUCTS_COLLECTION = "[PRODUCTS] Collection Get";
export const GET_PRODUCTS_COLLECTION_SUCCESS = "[PRODUCTS] Collection Get Success";
export const GET_PRODUCTS_COLLECTION_FAIL = "[PRODUCTS] Collection Get Fail";

export const GET_PRODUCTS_SINGLE = "[PRODUCTS] Single Get";
export const GET_PRODUCTS_SINGLE_SUCCESS = "[PRODUCTS] Single Get Success";
export const GET_PRODUCTS_SINGLE_FAIL = "[PRODUCTS] Single Get Fail";

export const CREATE_PRODUCTS = "[PRODUCTS] Create";
export const CREATE_PRODUCTS_SUCCESS = "[PRODUCTS] Create Success";
export const CREATE_PRODUCTS_FAIL = "[PRODUCTS] Create Fail";
export const CREATE_PRODUCTS_RESET = "[PRODUCTS] Create Reset";

export const UPDATE_PRODUCTS = "[PRODUCTS] Update";
export const UPDATE_PRODUCTS_SUCCESS = "[PRODUCTS] Update Success";
export const UPDATE_PRODUCTS_FAIL = "[PRODUCTS] Update Fail";
export const UPDATE_PRODUCTS_RESET = "[PRODUCTS] Update Reset";

export const REPLACE_PRODUCTS = "[PRODUCTS] Replace";
export const REPLACE_PRODUCTS_SUCCESS = "[PRODUCTS] Replace Success";
export const REPLACE_PRODUCTS_FAIL = "[PRODUCTS] Replace Fail";
export const REPLACE_PRODUCTS_RESET = "[PRODUCTS] Replace Reset";

export const REMOVE_PRODUCTS = "[PRODUCTS] Remove";
export const REMOVE_PRODUCTS_SUCCESS = "[PRODUCTS] Remove Success";
export const REMOVE_PRODUCTS_FAIL = "[PRODUCTS] Remove Fail";
