export const GET_OWNER_DOCUMENTS_LIST = "[OWNER_DOCUMENTS] List Get";
export const GET_OWNER_DOCUMENTS_LIST_SUCCESS = "[OWNER_DOCUMENTS] List Get Success";
export const GET_OWNER_DOCUMENTS_LIST_FAIL = "[OWNER_DOCUMENTS] List Get Fail";

export const GET_OWNER_DOCUMENTS_PAGINATION = "[OWNER_DOCUMENTS] Pagination Get";
export const GET_OWNER_DOCUMENTS_PAGINATION_SUCCESS = "[OWNER_DOCUMENTS] Pagination Get Success";
export const GET_OWNER_DOCUMENTS_PAGINATION_FAIL = "[OWNER_DOCUMENTS] Pagination Get Fail";

export const GET_OWNER_DOCUMENTS_COLLECTION = "[OWNER_DOCUMENTS] Collection Get";
export const GET_OWNER_DOCUMENTS_COLLECTION_SUCCESS = "[OWNER_DOCUMENTS] Collection Get Success";
export const GET_OWNER_DOCUMENTS_COLLECTION_FAIL = "[OWNER_DOCUMENTS] Collection Get Fail";

export const GET_OWNER_DOCUMENTS_SINGLE = "[OWNER_DOCUMENTS] Single Get";
export const GET_OWNER_DOCUMENTS_SINGLE_SUCCESS = "[OWNER_DOCUMENTS] Single Get Success";
export const GET_OWNER_DOCUMENTS_SINGLE_FAIL = "[OWNER_DOCUMENTS] Single Get Fail";

export const CREATE_OWNER_DOCUMENTS = "[OWNER_DOCUMENTS] Create";
export const CREATE_OWNER_DOCUMENTS_SUCCESS = "[OWNER_DOCUMENTS] Create Success";
export const CREATE_OWNER_DOCUMENTS_FAIL = "[OWNER_DOCUMENTS] Create Fail";
export const CREATE_OWNER_DOCUMENTS_RESET = "[OWNER_DOCUMENTS] Create Reset";

export const UPDATE_OWNER_DOCUMENTS = "[OWNER_DOCUMENTS] Update";
export const UPDATE_OWNER_DOCUMENTS_SUCCESS = "[OWNER_DOCUMENTS] Update Success";
export const UPDATE_OWNER_DOCUMENTS_FAIL = "[OWNER_DOCUMENTS] Update Fail";
export const UPDATE_OWNER_DOCUMENTS_RESET = "[OWNER_DOCUMENTS] Update Reset";

export const REPLACE_OWNER_DOCUMENTS = "[OWNER_DOCUMENTS] Replace";
export const REPLACE_OWNER_DOCUMENTS_SUCCESS = "[OWNER_DOCUMENTS] Replace Success";
export const REPLACE_OWNER_DOCUMENTS_FAIL = "[OWNER_DOCUMENTS] Replace Fail";
export const REPLACE_OWNER_DOCUMENTS_RESET = "[OWNER_DOCUMENTS] Replace Reset";

export const REMOVE_OWNER_DOCUMENTS = "[OWNER_DOCUMENTS] Remove";
export const REMOVE_OWNER_DOCUMENTS_SUCCESS = "[OWNER_DOCUMENTS] Remove Success";
export const REMOVE_OWNER_DOCUMENTS_FAIL = "[OWNER_DOCUMENTS] Remove Fail";

export const EXPORT_PDF_OWNER_DOCUMENTS = "[OWNER_DOCUMENTS] Export PDF";