export const GET_BOOKING_NETWORK_PAGES_LIST = "[BOOKING_NETWORK_PAGES] List Get";
export const GET_BOOKING_NETWORK_PAGES_LIST_SUCCESS = "[BOOKING_NETWORK_PAGES] List Get Success";
export const GET_BOOKING_NETWORK_PAGES_LIST_FAIL = "[BOOKING_NETWORK_PAGES] List Get Fail";

export const GET_BOOKING_NETWORK_PAGES_PAGINATION = "[BOOKING_NETWORK_PAGES] Pagination Get";
export const GET_BOOKING_NETWORK_PAGES_PAGINATION_SUCCESS = "[BOOKING_NETWORK_PAGES] Pagination Get Success";
export const GET_BOOKING_NETWORK_PAGES_PAGINATION_FAIL = "[BOOKING_NETWORK_PAGES] Pagination Get Fail";

export const GET_BOOKING_NETWORK_PAGES_COLLECTION = "[BOOKING_NETWORK_PAGES] Collection Get";
export const GET_BOOKING_NETWORK_PAGES_COLLECTION_SUCCESS = "[BOOKING_NETWORK_PAGES] Collection Get Success";
export const GET_BOOKING_NETWORK_PAGES_COLLECTION_FAIL = "[BOOKING_NETWORK_PAGES] Collection Get Fail";

export const GET_BOOKING_NETWORK_PAGES_SINGLE = "[BOOKING_NETWORK_PAGES] Single Get";
export const GET_BOOKING_NETWORK_PAGES_SINGLE_SUCCESS = "[BOOKING_NETWORK_PAGES] Single Get Success";
export const GET_BOOKING_NETWORK_PAGES_SINGLE_FAIL = "[BOOKING_NETWORK_PAGES] Single Get Fail";

export const CREATE_BOOKING_NETWORK_PAGES = "[BOOKING_NETWORK_PAGES] Create";
export const CREATE_BOOKING_NETWORK_PAGES_SUCCESS = "[BOOKING_NETWORK_PAGES] Create Success";
export const CREATE_BOOKING_NETWORK_PAGES_FAIL = "[BOOKING_NETWORK_PAGES] Create Fail";
export const CREATE_BOOKING_NETWORK_PAGES_RESET = "[BOOKING_NETWORK_PAGES] Create Reset";

export const UPDATE_BOOKING_NETWORK_PAGES = "[BOOKING_NETWORK_PAGES] Update";
export const UPDATE_BOOKING_NETWORK_PAGES_SUCCESS = "[BOOKING_NETWORK_PAGES] Update Success";
export const UPDATE_BOOKING_NETWORK_PAGES_FAIL = "[BOOKING_NETWORK_PAGES] Update Fail";
export const UPDATE_BOOKING_NETWORK_PAGES_RESET = "[BOOKING_NETWORK_PAGES] Update Reset";

export const DUPLICATE_BOOKING_NETWORK_PAGES = "[BOOKING_NETWORK_PAGES] Duplicate";
export const DUPLICATE_BOOKING_NETWORK_PAGES_SUCCESS = "[BOOKING_NETWORK_PAGES] Duplicate Success";
export const DUPLICATE_BOOKING_NETWORK_PAGES_FAIL = "[BOOKING_NETWORK_PAGES] Duplicate Fail";
export const DUPLICATE_BOOKING_NETWORK_PAGES_RESET = "[BOOKING_NETWORK_PAGES] Duplicate Reset";

export const REPLACE_BOOKING_NETWORK_PAGES = "[BOOKING_NETWORK_PAGES] Replace";
export const REPLACE_BOOKING_NETWORK_PAGES_SUCCESS = "[BOOKING_NETWORK_PAGES] Replace Success";
export const REPLACE_BOOKING_NETWORK_PAGES_FAIL = "[BOOKING_NETWORK_PAGES] Replace Fail";
export const REPLACE_BOOKING_NETWORK_PAGES_RESET = "[BOOKING_NETWORK_PAGES] Replace Reset";

export const REMOVE_BOOKING_NETWORK_PAGES = "[BOOKING_NETWORK_PAGES] Remove";
export const REMOVE_BOOKING_NETWORK_PAGES_SUCCESS = "[BOOKING_NETWORK_PAGES] Remove Success";
export const REMOVE_BOOKING_NETWORK_PAGES_FAIL = "[BOOKING_NETWORK_PAGES] Remove Fail";

export const POSITION_BOOKING_NETWORK_PAGES = "[BOOKING_NETWORK_PAGES] Position";
export const POSITION_BOOKING_NETWORK_PAGES_SUCCESS = "[BOOKING_NETWORK_PAGES] Position Success";
export const POSITION_BOOKING_NETWORK_PAGES_FAIL = "[BOOKING_NETWORK_PAGES] Position Fail";
