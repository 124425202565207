export const GET_MESSAGES_PAGINATION = "[MESSAGES] Pagination Get";
export const GET_MESSAGES_PAGINATION_SUCCESS = "[MESSAGES] Pagination Get Success";
export const GET_MESSAGES_PAGINATION_FAIL = "[MESSAGES] Pagination Get Fail";

export const GET_MESSAGES_COLLECTION = "[MESSAGES] Collection Get";
export const GET_MESSAGES_COLLECTION_SUCCESS = "[MESSAGES] Collection Get Success";
export const GET_MESSAGES_COLLECTION_FAIL = "[MESSAGES] Collection Get Fail";

export const GET_MESSAGES_SINGLE = "[MESSAGES] Single Get";
export const GET_MESSAGES_SINGLE_SUCCESS = "[MESSAGES] Single Get Success";
export const GET_MESSAGES_SINGLE_FAIL = "[MESSAGES] Single Get Fail";

export const CREATE_MESSAGES = "[MESSAGES] Create";
export const CREATE_MESSAGES_SUCCESS = "[MESSAGES] Create Success";
export const CREATE_MESSAGES_FAIL = "[MESSAGES] Create Fail";
export const CREATE_MESSAGES_RESET = "[MESSAGES] Create Reset";

export const UPDATE_MESSAGES = "[MESSAGES] Update";
export const UPDATE_MESSAGES_SUCCESS = "[MESSAGES] Update Success";
export const UPDATE_MESSAGES_FAIL = "[MESSAGES] Update Fail";
export const UPDATE_MESSAGES_RESET = "[MESSAGES] Update Reset";

export const REMOVE_MESSAGES = "[MESSAGES] Remove";
export const REMOVE_MESSAGES_SUCCESS = "[MESSAGES] Remove Success";
export const REMOVE_MESSAGES_FAIL = "[MESSAGES] Remove Fail";

export const RESEND_MESSAGES = "[MESSAGES] Resend";
export const RESEND_MESSAGES_SUCCESS = "[MESSAGES] Resend Success";
export const RESEND_MESSAGES_FAIL = "[MESSAGES] Resend Fail";
