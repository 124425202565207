<template>
  <span>
    <v-autocomplete
      v-if="!isCustom"
      v-model="ids"
      item-value="name"
      item-text="name"
      :items="items"
      :label="label"
      :multiple="multiple"
      :small-chips="smallChips"
      :deletable-chips="deletableChips"
      :hint="hint"
      :persistent-hint="persistentHint"
      :loading="loading"
      :prepend-icon="prependIcon"
      append-outer-icon="mdi-refresh"
      :clearable="clearable"
      :disabled="disabled"
      :readonly="readonly"
      :dark="dark"
      @change="change"
      @blur="blur"
      @click:append-outer="getItems()"
      :dense="dense"
      :error="error"
      :error-messages="!!errorMessages ? errorMessages : []"
    >
      <template v-if="withSelectionButtons" v-slot:prepend-item>
        <v-list-item class="primary">
            <v-list-item-content>
              <v-row class="ma-1">
                <v-col cols="6" align="center" class="ma-0 pa-0">
                  <v-btn @click="selectAllItems" small>{{ $lang('main.settings.select_all') }}</v-btn>
                </v-col>
                <v-col cols="6" align="center" class="ma-0 pa-0">
                  <v-btn @click="deselectAllItems" small>{{ $lang('main.settings.deselect_all') }}</v-btn>
                </v-col>
              </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-1"></v-divider>
      </template>
    </v-autocomplete>
    <v-text-field
      v-if="isCustom"
      v-model="ids"
      :label="label"
      :loading="loading"
      :prepend-icon="prependIcon"
      :clearable="clearable"
      :disabled="disabled"
      :readonly="readonly"
      :dark="dark"
      @change="change"
      @blur="blur"
      :dense="dense"
      :error="error"
      :error-messages="!!errorMessages ? errorMessages : []"
    ></v-text-field>
  </span>
</template>

<script>
import { mapState } from "vuex";
import { GET_AGENCIES_COLLECTION } from "@/store/agencies/actions";

export default {
	name: "CAgenciesAutocomplete",
	props: {
		label: {
			type: String,
			required: false,
      default: ''
		},
		hint: {
			type: String,
			default: ""
		},
    persistentHint: {
      type: Boolean,
      default: false
    },
		value: {
			default: null
		},
		error: {
			default: false
		},
		errorMessages: {
			default: () => []
		},
		dense: {
			type: Boolean,
			default: false
		},
		prependIcon: {
			default: "mdi-account-star"
		},
		clearable: {
			type: Boolean,
			default: false
		},
    multiple: {
      type: Boolean,
			default: false
    },
		disabled: {
			type: Boolean,
			default: false
		},
		readonly: {
			type: Boolean,
			default: false
		},
		dark: {
			type: Boolean,
			default: false
		},
    smallChips: {
      type: Boolean,
			default: false
    },
    deletableChips: {
      type: Boolean,
			default: false
    },
    withSelectionButtons: {
      type: Boolean,
      default: false
    },
    autoselect: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => {
        return {};
      }
    }
	},
	data() {
		return {
			ids: null,
      selectedIds: [],
      cache: false
		};
	},
  beforeMount() {
    if (!this.isListCached || Object.keys(this.options).length) {
      this.getItems();
      this.cache = true;
    }
  },
  mounted() {
    this.ids = this.value;
    this.checkAutoselect();
  },
	methods: {
    getItems() {
      if (
        this.$cannot('VIEW_AGENCIES') ||
        this.listRequest.loading
      ) {
        return;
      }

      let filters = this.options.filters || {};

      this.$store.dispatch(GET_AGENCIES_COLLECTION, {
        sortBy: 'name',
        order: 'asc',
        ...filters,
      });
    },
		change() {
			this.$emit("change");
		},
    blur() {
      this.$emit("blur");
    },
    selectAllItems() {
      let items = [];

      Object.keys(this.items).map(key => {
        items.push(this.items[key].value);
      });

      this.ids = [...items];
    },
    deselectAllItems() {
      this.ids = [];
    },
    checkAutoselect() {
      if (this.items.length === 1 && this.autoselect) {
        this.ids = this.items[0].value;
      }
    }
	},
  computed: {
    ...mapState({
      listRequest: state => state.agencies.getCollectionRequest,
    }),
    items() {
      if (!this.listRequest.collection) {
        return [];
      }

      return this.listRequest.collection;
    },
    isCustom() {
      if (!this.ids) {
        return false;
      }

      return !this.items.find(item => {
        return this.ids === item.name;
      })
    },
    loading() {
      return this.listRequest.loading;
    },
    isListCached() {
      return this.listRequest.success;
    },
  },
  watch: {
    ids(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.ids = newVal;
    },
  }
};
</script>
