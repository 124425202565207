import ApiService from "@/services/api";
import {
  DELETE_ALL_IMAGES,
  DELETE_ALL_IMAGES_COLLECTION,
  DELETE_ALL_IMAGES_FAIL,
  DELETE_ALL_IMAGES_SUCCESS,
  DELETE_FROM_IMAGES_COLLECTION,
  DELETE_IMAGES,
  DELETE_IMAGES_FAIL,
  DELETE_IMAGES_SUCCESS,
  GET_IMAGES_COLLECTION,
  GET_IMAGES_COLLECTION_FAIL,
  GET_IMAGES_COLLECTION_SUCCESS,
  PUSH_TO_IMAGES_COLLECTION,
  UPDATE_ALL_IMAGES,
  UPDATE_ALL_IMAGES_FAIL,
  UPDATE_ALL_IMAGES_SUCCESS,
  UPDATE_IMAGES,
  UPDATE_IMAGES_FAIL,
  UPDATE_IMAGES_SUCCESS,
  UPLOAD_IMAGES,
  UPLOAD_IMAGES_FAIL,
  UPLOAD_IMAGES_SUCCESS
} from "./actions";


const state = {
  getCollectionRequest: {
    loading: false,
    success: false,
    fail: false,
    collection: [],
    errors: {}
  },
  uploadRequest: {
    loading: false,
    success: false,
    fail: false,
    validation: {},
    response: {}
  },
  deletionRequest: {
    loading: false,
    success: false,
    fail: false
  },
  deletionAllRequest: {
    loading: false,
    success: false,
    fail: false
  }
};

const actions = {
  [GET_IMAGES_COLLECTION](context, {slug, ownerId}) {
    context.state.getCollectionRequest.loading = true;
    return new Promise((resolve, reject) => {
      ApiService.get(`images/${slug}/${ownerId}/collection`)
        .then(response => {
          context.commit(GET_IMAGES_COLLECTION_SUCCESS, response.data.data);
          resolve(response.data.data);
        })
        .catch(response => {
          context.commit(GET_IMAGES_COLLECTION_FAIL, response.data);
          reject(response.data)
        });
    });
  },
  [UPLOAD_IMAGES](context, {slug, ownerId, data}) {
    context.state.uploadRequest.loading = true;
    return new Promise((resolve, reject) => {
      ApiService.postUpload(`images/${slug}/${ownerId}/upload`, data)
        .then(response => {
          context.commit(UPLOAD_IMAGES_SUCCESS, response.data.data);
          context.commit(PUSH_TO_IMAGES_COLLECTION, response.data.data);
          resolve(response.data);
        })
        .catch(({response}) => {
          context.commit(UPLOAD_IMAGES_FAIL, response.data.errors);
          reject(response.data)
        });
    });
  },
  [UPDATE_IMAGES](context, {slug, ownerId, imageId, data}) {
    ApiService.put(`images/${slug}/${ownerId}/${imageId}/update`, data)
      .then(response => {
        context.commit(UPDATE_IMAGES_SUCCESS, response.data.data);
      })
      .catch(response => {
        context.commit(UPDATE_IMAGES_FAIL, response.data);
      });
  },
  [UPDATE_ALL_IMAGES](context, {slug, ownerId, data}) {
    ApiService.put(`images/${slug}/${ownerId}/update/all`, data)
      .then(response => {
        context.commit(UPDATE_ALL_IMAGES_SUCCESS, response.data.data);
      })
      .catch(response => {
        context.commit(UPDATE_ALL_IMAGES_FAIL, response.data);
      });
  },
  [DELETE_IMAGES](context, {slug, ownerId, imageId}) {
    context.state.deletionRequest.loading = true;
    ApiService.delete(`images/${slug}/${ownerId}/${imageId}/delete`)
      .then(response => {
        context.commit(DELETE_IMAGES_SUCCESS, response.data.data);
        context.commit(DELETE_FROM_IMAGES_COLLECTION, imageId);
      })
      .catch(response => {
        context.commit(DELETE_IMAGES_FAIL, response.data);
      });
  },
  [DELETE_ALL_IMAGES](context, {slug, ownerId}) {
    context.state.deletionAllRequest.loading = true;
    ApiService.delete(`images/${slug}/${ownerId}/delete/all`)
      .then(response => {
        context.commit(DELETE_ALL_IMAGES_SUCCESS, response.data.data);
        context.commit(DELETE_ALL_IMAGES_COLLECTION);
      })
      .catch(response => {
        context.commit(DELETE_ALL_IMAGES_FAIL, response.data);
      });
  },
};

const mutations = {
  [GET_IMAGES_COLLECTION_SUCCESS](state, data) {
    state.getCollectionRequest = {
      loading: false,
      success: true,
      fail: false,
      collection: data.collection,
      errors: {}
    };
  },
  [GET_IMAGES_COLLECTION_FAIL](state, data) {
    state.getCollectionRequest = {
      loading: false,
      success: false,
      fail: true,
      collection: [],
      errors: data
    };
  },
  [PUSH_TO_IMAGES_COLLECTION](state, data) {
    state.getCollectionRequest.collection.push(data);
  },
  [UPLOAD_IMAGES_SUCCESS](state, data) {
    state.uploadRequest = {
      loading: false,
      success: true,
      fail: false,
      validation: {},
      response: data
    }
  },
  [UPLOAD_IMAGES_FAIL](state, data) {
    state.uploadRequest = {
      loading: false,
      success: false,
      fail: true,
      validation: data,
      response: []
    }
  },
  [UPDATE_IMAGES_SUCCESS](state, data) {

  },
  [UPDATE_IMAGES_FAIL](state, data) {

  },
  [UPDATE_ALL_IMAGES_SUCCESS](state, data) {

  },
  [UPDATE_ALL_IMAGES_FAIL](state, data) {

  },
  [DELETE_IMAGES_SUCCESS](state, data) {
    state.deletionRequest = {
      loading: false,
      success: true,
      fail: false
    };
  },
  [DELETE_IMAGES_FAIL](state, data) {
    state.deletionRequest = {
      loading: false,
      success: false,
      fail: true
    };
  },
  [DELETE_FROM_IMAGES_COLLECTION](state, imageId) {
    const items = state.getCollectionRequest.collection;
    const len = items.length;
    let newItems = [];

    for (let i = 0; i < len; i++) {
      if (items[i].id != imageId) {
        newItems.push(items[i]);
      }
    }

    state.getCollectionRequest.collection = newItems;
  },
  [DELETE_ALL_IMAGES_SUCCESS](state, data) {
    state.deletionAllRequest = {
      loading: false,
      success: true,
      fail: false
    };
  },
  [DELETE_ALL_IMAGES_FAIL](state, data) {
    state.deletionAllRequest = {
      loading: false,
      success: false,
      fail: true
    };
  },
  [DELETE_ALL_IMAGES_COLLECTION](state, data) {
    state.getCollectionRequest.collection = [];
  }
};

const getters = {};

export default {
  state,
  actions,
  mutations,
  getters
};
