export default {
  methods: {
    resourceIcon(type) {
      switch(type) {
        case this.$const('ConstResourceTypes', 'PARKING'):
          return 'mdi-parking';
        case this.$const('ConstResourceTypes', 'BABY_CRIB'):
          return 'mdi-cradle';
        case this.$const('ConstResourceTypes', 'EXTRA_BED'):
          return 'mdi-bed';
        case this.$const('ConstResourceTypes', 'STUDIO'):
          return 'mdi-chair-rolling';
        case this.$const('ConstResourceTypes', 'SPA'):
          return 'mdi-spa';
        case this.$const('ConstResourceTypes', 'TOUR'):
          return 'mdi-forest';
        case this.$const('ConstResourceTypes', 'RECHARGE_STATION'):
          return 'mdi-ev-station';
        case this.$const('ConstResourceTypes', 'RENTAL'):
          return 'mdi-bike';
      }

      return 'mdi-semantic-web';
    }
  }
};
