export const GET_ASSETS_LIST = "[ASSETS] List Get";
export const GET_ASSETS_LIST_SUCCESS = "[ASSETS] List Get Success";
export const GET_ASSETS_LIST_FAIL = "[ASSETS] List Get Fail";

export const GET_ASSETS_PAGINATION = "[ASSETS] Pagination Get";
export const GET_ASSETS_PAGINATION_SUCCESS = "[ASSETS] Pagination Get Success";
export const GET_ASSETS_PAGINATION_FAIL = "[ASSETS] Pagination Get Fail";

export const GET_ASSETS_COLLECTION = "[ASSETS] Collection Get";
export const GET_ASSETS_COLLECTION_SUCCESS = "[ASSETS] Collection Get Success";
export const GET_ASSETS_COLLECTION_FAIL = "[ASSETS] Collection Get Fail";

export const GET_ASSETS_SINGLE = "[ASSETS] Single Get";
export const GET_ASSETS_SINGLE_SUCCESS = "[ASSETS] Single Get Success";
export const GET_ASSETS_SINGLE_FAIL = "[ASSETS] Single Get Fail";

export const CREATE_ASSETS = "[ASSETS] Create";
export const CREATE_ASSETS_SUCCESS = "[ASSETS] Create Success";
export const CREATE_ASSETS_FAIL = "[ASSETS] Create Fail";
export const CREATE_ASSETS_RESET = "[ASSETS] Create Reset";

export const UPDATE_ASSETS = "[ASSETS] Update";
export const UPDATE_ASSETS_SUCCESS = "[ASSETS] Update Success";
export const UPDATE_ASSETS_FAIL = "[ASSETS] Update Fail";
export const UPDATE_ASSETS_RESET = "[ASSETS] Update Reset";

export const REMOVE_ASSETS = "[ASSETS] Remove";
export const REMOVE_ASSETS_SUCCESS = "[ASSETS] Remove Success";
export const REMOVE_ASSETS_FAIL = "[ASSETS] Remove Fail";

export const GET_ASSETS_DOWNTIME = "[ASSETS] Downtime Get";
export const GET_ASSETS_DOWNTIME_SUCCESS = "[ASSETS] Downtime Get Success";
export const GET_ASSETS_DOWNTIME_FAIL = "[ASSETS] Downtime Get Fail";
