import Vue from "vue";
import ApiService from "@/services/api";
import { isAuthenticated } from "@/helpers/functions";
import { Workbox } from "workbox-window";

import appConfig from "@/config/app";
import pushConfig from "@/config/push";

const WorkerService = {
  /**
   *
   */
  init() {
    let wb;

    if ("serviceWorker" in navigator) {
      wb = new Workbox(`${appConfig.url}service-worker.js`);
      wb.addEventListener("controlling", () => {
        window.location.reload();
      });
      wb.addEventListener('waiting', (event) => {
        console.log(`A new service worker has installed, but it can't activate` +
          `until all tabs running the current version have fully unloaded.`);
      });
      wb.register();
    } else {
      wb = null;
    }

    Vue.prototype.$workbox = wb;
  },

  /**
   *
   */
  isGranted() {
    return Notification.permission === 'granted';
  },

  /**
   *
   */
  async isSubscribed() {
    const registration = await navigator.serviceWorker.ready;
    return registration ? !!await registration.pushManager.getSubscription() : false
  },

  /**
   *
   */
  activateSubscription() {
    if (!navigator.serviceWorker.ready || !isAuthenticated()) {
      return;
    }

    return new Promise((resolve, reject) => {
      const permissionResult = Notification.requestPermission((result) => {
        resolve(result);
      });

      if (permissionResult) {
        permissionResult.then(resolve, reject);
      }
    })
      .then((permissionResult) => {
        if (permissionResult !== 'granted') {
          return;
        }

        this.subscribeUser();
      });
  },

  /**
   *
   */
  subscribeUser() {
    this.isSubscribed().then((result) => {
      if (result) {
        console.warn("Already subscribed");
        return;
      }

      navigator.serviceWorker.ready
        .then((registration) => {
          const subscribeOptions = {
            userVisibleOnly: true,
            applicationServerKey: this.urlBase64ToUint8Array(pushConfig.vapid.public.key)
          };

          return registration.pushManager.subscribe(subscribeOptions);
        })
        .then((pushSubscription) => {
          this.storePushSubscription(pushSubscription);
        });
    });
  },

  /**
   *
   */
  unsubscribeUser() {
    return navigator.serviceWorker.ready.then((registration) => {
      registration.pushManager.getSubscription().then((subscription) => {
        if (!subscription) {
          console.warn('No active subscription');
          return;
        }

        subscription.unsubscribe().then(() => {
          this.deletePushSubscription(subscription)
        }).catch(function(e) {
          console.error(e)
        })
      })
    });
  },

  /**
   * @param base64String
   * @returns {Uint8Array}
   */
  urlBase64ToUint8Array(base64String) {
    let padding = '='.repeat((4 - base64String.length % 4) % 4);
    let base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    let rawData = window.atob(base64);
    let outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  },

  /**
   * @param pushSubscription
   */
  storePushSubscription(pushSubscription) {
    ApiService.post('push/subscribe', pushSubscription)
  },

  /**
   * @param pushSubscription
   */
  deletePushSubscription(pushSubscription) {
    ApiService.post('push/unsubscribe', pushSubscription)
  },
};

export default WorkerService;
