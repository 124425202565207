import { mapState } from "vuex";

import { NOTIFICATION_SHOW, NOTIFICATION_HIDE } from "@/store/app/actions";

export default {
  methods: {
    showNotification(text, color) {
      this.$store.dispatch(NOTIFICATION_SHOW, {
        text: text,
        color: color,
        exists: true
      });

      setTimeout(() => {
        this.$store.dispatch(NOTIFICATION_HIDE);
      }, 5000);
    }
  },
  computed: {
    ...mapState({
      notification: state => state.app.notification
    })
  }
};
