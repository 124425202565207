export default [
  {
    name: "tickets",
    path: "/tickets",
    component: () => import(/*webpackChunkName: "tickets" */ "@/pages/tickets/Index"),
    meta: {
      title: 'titles.tickets'
    }
  },
  {
    name: "ticket-messages",
    path: "/tickets/:id/messages",
    component: () => import(/*webpackChunkName: "tickets" */ "@/pages/tickets/SingleView"),
    meta: {
      title: 'titles.tickets',
      breadcrumbs: [
        { text: 'titles.tickets', to: 'tickets' },
      ],
    }
  },
];
