<template>
  <span>
    <v-autocomplete
      v-model="ids"
      item-value="id"
      item-text="name"
      :items="items"
      :label="label"
      :multiple="multiple"
      :small-chips="smallChips"
      :deletable-chips="deletableChips"
      :chips="chips"
      :hint="hint"
      :persistent-hint="persistentHint"
      :loading="loading"
      :prepend-icon="prependIcon"
      :clearable="clearable"
      :disabled="disabled"
      :readonly="readonly"
      :dark="dark"
      :dense="dense"
      :error="error"
      :error-messages="!!errorMessages ? errorMessages : []"
    >
      <template v-if="withSelectionButtons" v-slot:prepend-item>
        <v-list-item class="primary">
            <v-list-item-content>
              <v-row class="ma-1">
                <v-col cols="6" align="center" class="ma-0 pa-0">
                  <v-btn @click="selectAll" small>{{ $lang('main.settings.select_all') }}</v-btn>
                </v-col>
                <v-col cols="6" align="center" class="ma-0 pa-0">
                  <v-btn @click="deselectAll" small>{{ $lang('main.settings.deselect_all') }}</v-btn>
                </v-col>
              </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-1"></v-divider>
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title>
            <v-chip v-if="chips" :small="smallChips" :color="roomTypeColor(item)" class="white--text">
              <span v-if="withHumanName">
                {{ item.unit_category.name }} //
                {{ item.rate_plan.name }}<v-icon>mdi-human-male</v-icon>x{{ item.guests }}
              </span>
              <span v-else>
                {{ item.name }}
              </span>
            </v-chip>
            <template v-else>
              <span v-if="withHumanName">
                {{ item.unit_category.name }} //
                {{ item.rate_plan.name }}<v-icon>mdi-human-male</v-icon>x{{ item.guests }}
              </span>
              <span v-else>
                {{ item.name }}
              </span>
            </template>
          </v-list-item-title>
        </v-list-item-content>
      </template>
      <template v-slot:selection="{ item }">
        <v-chip v-if="chips" :small="smallChips" :color="roomTypeColor(item)" class="white--text">
          <span v-if="withHumanName">
            {{ item.unit_category.name }} // {{ item.rate_plan.name }}<v-icon>mdi-human-male</v-icon>x{{ item.guests }}
          </span>
          <span v-else>
            {{ item.name }}
          </span>
        </v-chip>
        <template v-else>
          <span v-if="withHumanName">
            {{ item.unit_category.name }} // {{ item.rate_plan.name }}<v-icon>mdi-human-male</v-icon>x{{ item.guests }}
          </span>
          <span v-else>
            {{ item.name }}
          </span>
        </template>
      </template>
    </v-autocomplete>
  </span>
</template>

<script>
import { mapState } from "vuex";
import { GET_ROOM_TYPES_COLLECTION } from "@/store/room-types/actions";

export default {
  name: "CRoomTypesAutocomplete",
  props: {
    label: {
      type: String,
      required: false,
      default: "RoomTypes"
    },
    hint: {
      type: String,
      default: ""
    },
    persistentHint: {
      type: Boolean,
      default: false
    },
    value: {
      default: null
    },
    error: {
      default: false
    },
    errorMessages: {
      default: () => []
    },
    dense: {
      type: Boolean,
      default: false
    },
    prependIcon: {
      type: String,
      default: "mdi-home"
    },
    clearable: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    chips: {
      type: Boolean,
      default: false,
    },
    smallChips: {
      type: Boolean,
      default: false
    },
    deletableChips: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: () => {
        return {};
      }
    },
    withHumanName: {
      type: Boolean,
      default: false
    },
    withSelectionButtons: {
      type: Boolean,
      default: false
    },
    withColors: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ids: null,
      items: [],
    };
  },
  beforeMount() {
    this.getRoomTypes();
  },
  mounted() {
    this.ids = this.value;
  },
  methods: {
    getRoomTypes() {
      let options = {};

      if (this.withColors || this.withHumanName) {
        options = {
          ...options,
          relations: {
            unit_category: {
              selects: [
                'id',
                'name',
                'color',
              ]
            },
            rate_plan: {
              selects: [
                'id',
                'name'
              ]
            },
          }
        };
      }

      this.$store.dispatch(GET_ROOM_TYPES_COLLECTION, {
        _data: {
          selects: [
            'id',
            'name',
            'rate_plan_id',
            'unit_category_id',
            'guests',
          ],
          sortBy: [
            {
              field: 'rate_plan_id',
              order: 'asc',
            },
            {
              field: 'guests',
              order: 'asc',
            },
          ],
          ...options,
        },
        ...this.filters,
      }).then(response => {
        this.items = response.collection;
      });
    },
    change() {
      this.$emit("change");
    },
    blur() {
      this.$emit("blur");
    },
    roomTypeColor(roomType) {
      if (!roomType.unit_category) {
        return 'primary';
      }

      return roomType.unit_category.color;
    },
    selectAll() {
      let items = [];

      Object.keys(this.roomTypes).map(key => {
        items.push(this.roomTypes[key].id);
      });
      
      this.ids = [...items];
    },
    deselectAll() {
      this.ids = [];
    },
  },
  computed: {
    ...mapState({
      roomTypes: state => state.roomTypes.getCollectionRequest.collection,
    }),
    loading() {
      return this.roomTypes.loading;
    },
  },
  watch: {
    ids(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.ids = newVal;
    },
    filters: {
      handler() {
        this.getRoomTypes();
      },
      deep: true
    }
  }
};
</script>
