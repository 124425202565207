<template>
  <v-menu v-model="isMenuOpen" top nudge-bottom="105" nudge-left="16" :close-on-content-click="true">
    <template v-slot:activator="{ on }">
      <v-btn class="ma-2" small fab :color="internalValue" v-on="on">
        <v-icon color="grey">mdi-palette</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-text class="pa-0">
        <v-color-picker
          :value="internalValue"
          @input="updateValue"
          show-swatches
          hide-canvas
          hide-sliders
          hide-mode-switch
          hide-inputs
          swatches-max-height="250"
          mode="hexa"
        />
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isMenuOpen: false,
      internalValue: this.value,
      form: {
        color: 'primary' // Default color
      }
    };
  },
  watch: {
    value(newVal) {
      this.internalValue = newVal;
    },
    internalValue(newVal) {
      this.$emit('input', newVal);
    }
  },
  methods: {
    updateValue(newVal) {
      this.internalValue = newVal;
    }
  }
};
</script>