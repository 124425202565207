<template>
  <span>
    <v-autocomplete
      v-model="ids"
      item-value="id"
      item-text="name"
      :items="items"
      :label="label"
      :multiple="multiple"
      :small-chips="smallChips"
      :deletable-chips="deletableChips"
      :hint="hint"
      :persistent-hint="persistentHint"
      :loading="loading"
      :prepend-icon="prependIcon"
      append-outer-icon="mdi-refresh"
      :clearable="clearable"
      :disabled="disabled"
      :readonly="readonly"
      :dark="dark"
      @click:append-outer="getUnits()"
      :dense="dense"
      :error="error"
      :error-messages="!!errorMessages ? errorMessages : []"
    >
      <template v-slot:selection="{item}">
        <v-icon class="mr-1">{{resourceIcon(item.type)}}</v-icon>
        {{item.name}}
      </template>
      <template v-slot:item="{item}">
        <v-icon class="mr-1">{{resourceIcon(item.type)}}</v-icon>
        {{item.name}}
      </template>
    </v-autocomplete>
  </span>
</template>

<script>
import { mapState } from "vuex";
import { GET_RESOURCES_COLLECTION } from "@/store/resources/actions";

import format from "@/pages/resources/mixins/format";

export default {
  name: "CResourcesAutocomplete",
  props: {
    label: {
      type: String,
      required: false,
      default: "Resources"
    },
    hint: {
      type: String,
      default: ""
    },
    persistentHint: {
      type: Boolean,
      default: false
    },
    value: {
      default: null
    },
    error: {
      default: false
    },
    errorMessages: {
      default: () => []
    },
    dense: {
      type: Boolean,
      default: false
    },
    prependIcon: {
      type: String,
      default: "mdi-semantic-web"
    },
    clearable: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    smallChips: {
      type: Boolean,
      default: false
    },
    deletableChips: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: () => {
        return {};
      }
    },
    options: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  mixins: [
    format
  ],
  data() {
    return {
      ids: null
    };
  },
  beforeMount() {
    this.getItems();
  },
  mounted() {
    this.ids = this.value;
  },
  methods: {
    getItems() {
      if (
        this.disabled ||
        this.request.loading ||
        this.$cannot('VIEW_RESOURCES')
      ) {
        return;
      }
      this.$store.dispatch(GET_RESOURCES_COLLECTION, {
        _data: {
          selects: [
            "id",
            "name",
            "type",
          ],
          sortBy: "name",
          order: "asc",
        },
        ...this.filters
      });
    },
    change() {
      this.$emit("change");
    },
    blur() {
      this.$emit("blur");
    },
  },
  computed: {
    ...mapState({
      request: (state) => state.resources.getCollectionRequest
    }),
    items() {
      if (!this.request.collection) {
        return [];
      }

      return this.request.collection;
    },
    loading() {
      return this.request.loading;
    },
  },
  watch: {
    ids(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.ids = newVal;
    },
    filters: {
      handler() {
        this.getItems();
      },
      deep: true
    }
  }
};
</script>
