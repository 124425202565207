import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_AUTHORIZATION_URL,
  GET_AUTHORIZATION_URL_SUCCESS,
  GET_AUTHORIZATION_URL_FAIL,

  GET_COMPANY_QUICKBOOKS_INSTANCE,
  GET_COMPANY_QUICKBOOKS_INSTANCE_SUCCESS,
  GET_COMPANY_QUICKBOOKS_INSTANCE_FAIL,

  STORE_AUTH,
  STORE_AUTH_SUCCESS,
  STORE_AUTH_FAIL,

  REVOKE_AUTH,
  REVOKE_AUTH_SUCCESS,
  REVOKE_AUTH_FAIL,

  UPDATE_INSTANCE,
  UPDATE_INSTANCE_SUCCESS,
  UPDATE_INSTANCE_FAIL,

  SYNC_CLASSES,
  SYNC_CLASSES_SUCCESS,
  SYNC_CLASSES_FAIL,

  GET_CLASSES,
  GET_CLASSES_SUCCESS,
  GET_CLASSES_FAIL,

  GET_ACCOUNTS,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAIL,

  SYNC_ACCOUNTS,
  SYNC_ACCOUNTS_FAIL,
  SYNC_ACCOUNTS_SUCCESS,

  GET_ITEMS,
  GET_ITEMS_SUCCESS,
  GET_ITEMS_FAIL,

  SYNC_ITEMS,
  SYNC_ITEMS_FAIL,
  SYNC_ITEMS_SUCCESS,

  SAVE_PROPERTY_EXTRAS,
  SAVE_PROPERTY_EXTRAS_FAIL,
  SAVE_PROPERTY_EXTRAS_SUCCESS,

  GET_TAX_CODES,
  GET_TAX_CODES_FAIL,
  GET_TAX_CODES_SUCCESS
} from "./actions";

const crudProcessed = crud.process({
  getSingleRequest: {
    action: GET_COMPANY_QUICKBOOKS_INSTANCE,
    endPoint: "/quickbooks/single",
    mutationFail: GET_COMPANY_QUICKBOOKS_INSTANCE_FAIL,
    mutationSuccess: GET_COMPANY_QUICKBOOKS_INSTANCE_SUCCESS
  },
});

const actionsProcessed = action.processMultiple([
  {
    name: 'getOAuthUrlRequest',
    action: GET_AUTHORIZATION_URL,
    endPoint: 'quickbooks/oauth-url',
    mutations: {
      fail: GET_AUTHORIZATION_URL_FAIL,
      success: GET_AUTHORIZATION_URL_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'storeOAuth',
    action: STORE_AUTH,
    endPoint: 'quickbooks/oauth-store',
    mutations: {
      fail: STORE_AUTH_FAIL,
      success: STORE_AUTH_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'updateInstance',
    action: UPDATE_INSTANCE,
    endPoint: 'quickbooks/update/{id}',
    mutations: {
      fail: UPDATE_INSTANCE_FAIL,
      success: UPDATE_INSTANCE_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'revokeAuth',
    action: REVOKE_AUTH,
    endPoint: 'quickbooks/revoke/{company_id}',
    mutations: {
      fail: REVOKE_AUTH_FAIL,
      success: REVOKE_AUTH_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'syncClassesRequest',
    action: SYNC_CLASSES,
    endPoint: 'quickbooks/classes/{company_id}/sync',
    mutations: {
      fail: SYNC_CLASSES_FAIL,
      success: SYNC_CLASSES_SUCCESS,
    },
  },
  {
    method: 'query',
    name: 'getClassesRequest',
    action: GET_CLASSES,
    endPoint: 'quickbooks/classes/{company_id}',
    mutations: {
      fail: GET_CLASSES_FAIL,
      success: GET_CLASSES_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'syncAccountsRequest',
    action: SYNC_ACCOUNTS,
    endPoint: 'quickbooks/accounts/{company_id}/sync',
    mutations: {
      fail: SYNC_ACCOUNTS_FAIL,
      success: SYNC_ACCOUNTS_SUCCESS,
    },
  },
  {
    method: 'query',
    name: 'getAccountsRequest',
    action: GET_ACCOUNTS,
    endPoint: 'quickbooks/accounts/{company_id}',
    mutations: {
      fail: GET_ACCOUNTS_FAIL,
      success: GET_ACCOUNTS_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'syncItemsRequest',
    action: SYNC_ITEMS,
    endPoint: 'quickbooks/items/{company_id}/sync',
    mutations: {
      fail: SYNC_ITEMS_FAIL,
      success: SYNC_ITEMS_SUCCESS,
    },
  },
  {
    method: 'query',
    name: 'getItemsRequest',
    action: GET_ITEMS,
    endPoint: 'quickbooks/items/{company_id}',
    mutations: {
      fail: GET_ITEMS_FAIL,
      success: GET_ITEMS_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'savePropertyExtra',
    action: SAVE_PROPERTY_EXTRAS,
    endPoint: 'quickbooks/property-extras/update',
    mutations: {
      fail: SAVE_PROPERTY_EXTRAS_FAIL,
      success: SAVE_PROPERTY_EXTRAS_SUCCESS,
    },
  },
  {
    method: 'query',
    name: 'getTaxCodesRequest',
    action: GET_TAX_CODES,
    endPoint: 'quickbooks/tax-codes/{company_id}',
    mutations: {
      fail: GET_TAX_CODES_FAIL,
      success: GET_TAX_CODES_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
