import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_ACTIVITY_LOGS_PAGINATION,
  GET_ACTIVITY_LOGS_PAGINATION_SUCCESS,
  GET_ACTIVITY_LOGS_PAGINATION_FAIL,
  GET_ACTIVITY_LOGS_COLLECTION,
  GET_ACTIVITY_LOGS_COLLECTION_SUCCESS,
  GET_ACTIVITY_LOGS_COLLECTION_FAIL,
  GET_ACTIVITY_LOGS_SINGLE,
  GET_ACTIVITY_LOGS_SINGLE_SUCCESS,
  GET_ACTIVITY_LOGS_SINGLE_FAIL,
  CREATE_ACTIVITY_LOGS,
  CREATE_ACTIVITY_LOGS_SUCCESS,
  CREATE_ACTIVITY_LOGS_FAIL,
  CREATE_ACTIVITY_LOGS_RESET,
  UPDATE_ACTIVITY_LOGS,
  UPDATE_ACTIVITY_LOGS_SUCCESS,
  UPDATE_ACTIVITY_LOGS_FAIL,
  UPDATE_ACTIVITY_LOGS_RESET,
  REMOVE_ACTIVITY_LOGS,
  REMOVE_ACTIVITY_LOGS_SUCCESS,
  REMOVE_ACTIVITY_LOGS_FAIL
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_ACTIVITY_LOGS_PAGINATION,
    endPoint: "activity-logs/simple-paginated",
    mutationFail: GET_ACTIVITY_LOGS_PAGINATION_FAIL,
    mutationSuccess: GET_ACTIVITY_LOGS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_ACTIVITY_LOGS_COLLECTION,
    endPoint: "activity-logs/collected",
    mutationFail: GET_ACTIVITY_LOGS_COLLECTION_FAIL,
    mutationSuccess: GET_ACTIVITY_LOGS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_ACTIVITY_LOGS_SINGLE,
    endPoint: "activity-logs/single",
    mutationFail: GET_ACTIVITY_LOGS_SINGLE_FAIL,
    mutationSuccess: GET_ACTIVITY_LOGS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_ACTIVITY_LOGS,
    actionReset: CREATE_ACTIVITY_LOGS_RESET,
    endPoint: "activity-logs/create",
    mutationFail: CREATE_ACTIVITY_LOGS_FAIL,
    mutationSuccess: CREATE_ACTIVITY_LOGS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_ACTIVITY_LOGS,
    actionReset: UPDATE_ACTIVITY_LOGS_RESET,
    endPoint: "activity-logs/update",
    mutationFail: UPDATE_ACTIVITY_LOGS_FAIL,
    mutationSuccess: UPDATE_ACTIVITY_LOGS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_ACTIVITY_LOGS,
    endPoint: "activity-logs/delete",
    mutationFail: REMOVE_ACTIVITY_LOGS_FAIL,
    mutationSuccess: REMOVE_ACTIVITY_LOGS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
