import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_ANNOUNCEMENTS_PAGINATION,
  GET_ANNOUNCEMENTS_PAGINATION_SUCCESS,
  GET_ANNOUNCEMENTS_PAGINATION_FAIL,
  GET_ANNOUNCEMENTS_COLLECTION,
  GET_ANNOUNCEMENTS_COLLECTION_SUCCESS,
  GET_ANNOUNCEMENTS_COLLECTION_FAIL,
  GET_ANNOUNCEMENTS_SINGLE,
  GET_ANNOUNCEMENTS_SINGLE_SUCCESS,
  GET_ANNOUNCEMENTS_SINGLE_FAIL,
  CREATE_ANNOUNCEMENTS,
  CREATE_ANNOUNCEMENTS_SUCCESS,
  CREATE_ANNOUNCEMENTS_FAIL,
  CREATE_ANNOUNCEMENTS_RESET,
  UPDATE_ANNOUNCEMENTS,
  UPDATE_ANNOUNCEMENTS_SUCCESS,
  UPDATE_ANNOUNCEMENTS_FAIL,
  UPDATE_ANNOUNCEMENTS_RESET,
  REMOVE_ANNOUNCEMENTS,
  REMOVE_ANNOUNCEMENTS_SUCCESS,
  REMOVE_ANNOUNCEMENTS_FAIL
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_ANNOUNCEMENTS_PAGINATION,
    endPoint: "announcements/paginated",
    mutationFail: GET_ANNOUNCEMENTS_PAGINATION_FAIL,
    mutationSuccess: GET_ANNOUNCEMENTS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_ANNOUNCEMENTS_COLLECTION,
    endPoint: "announcements/collected",
    mutationFail: GET_ANNOUNCEMENTS_COLLECTION_FAIL,
    mutationSuccess: GET_ANNOUNCEMENTS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_ANNOUNCEMENTS_SINGLE,
    endPoint: "announcements/single",
    mutationFail: GET_ANNOUNCEMENTS_SINGLE_FAIL,
    mutationSuccess: GET_ANNOUNCEMENTS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_ANNOUNCEMENTS,
    actionReset: CREATE_ANNOUNCEMENTS_RESET,
    endPoint: "announcements/create",
    mutationFail: CREATE_ANNOUNCEMENTS_FAIL,
    mutationSuccess: CREATE_ANNOUNCEMENTS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_ANNOUNCEMENTS,
    actionReset: UPDATE_ANNOUNCEMENTS_RESET,
    endPoint: "announcements/update",
    mutationFail: UPDATE_ANNOUNCEMENTS_FAIL,
    mutationSuccess: UPDATE_ANNOUNCEMENTS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_ANNOUNCEMENTS,
    endPoint: "announcements/delete",
    mutationFail: REMOVE_ANNOUNCEMENTS_FAIL,
    mutationSuccess: REMOVE_ANNOUNCEMENTS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
