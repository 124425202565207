export const GET_COMPANY_PAYMENTS_PAGINATION = "[COMPANY_PAYMENTS] Pagination Get";
export const GET_COMPANY_PAYMENTS_PAGINATION_SUCCESS = "[COMPANY_PAYMENTS] Pagination Get Success";
export const GET_COMPANY_PAYMENTS_PAGINATION_FAIL = "[COMPANY_PAYMENTS] Pagination Get Fail";

export const REMOVE_COMPANY_PAYMENTS = "[COMPANY_PAYMENTS] Remove";
export const REMOVE_COMPANY_PAYMENTS_SUCCESS = "[COMPANY_PAYMENTS] Remove Success";
export const REMOVE_COMPANY_PAYMENTS_FAIL = "[COMPANY_PAYMENTS] Remove Fail";

export const CHARGE_COMPANY_PAYMENTS = "[COMPANY_PAYMENTS] Charge";
export const CHARGE_COMPANY_PAYMENTS_SUCCESS = "[COMPANY_PAYMENTS] Charge Success";
export const CHARGE_COMPANY_PAYMENTS_FAIL = "[COMPANY_PAYMENTS] Charge Fail";

export const GET_COMPANY_PAYMENTS_SINGLE = "[COMPANY_PAYMENTS] Single Get";
export const GET_COMPANY_PAYMENTS_SINGLE_SUCCESS = "[COMPANY_PAYMENTS] Single Get Success";
export const GET_COMPANY_PAYMENTS_SINGLE_FAIL = "[COMPANY_PAYMENTS] Single Get Fail";

export const CREATE_COMPANY_PAYMENTS = "[COMPANY_PAYMENTS] Create";
export const CREATE_COMPANY_PAYMENTS_SUCCESS = "[COMPANY_PAYMENTS] Create Success";
export const CREATE_COMPANY_PAYMENTS_FAIL = "[COMPANY_PAYMENTS] Create Fail";
export const CREATE_COMPANY_PAYMENTS_RESET = "[COMPANY_PAYMENTS] Create Reset";

export const UPDATE_COMPANY_PAYMENTS = "[COMPANY_PAYMENTS] Update";
export const UPDATE_COMPANY_PAYMENTS_SUCCESS = "[COMPANY_PAYMENTS] Update Success";
export const UPDATE_COMPANY_PAYMENTS_FAIL = "[COMPANY_PAYMENTS] Update Fail";
export const UPDATE_COMPANY_PAYMENTS_RESET = "[COMPANY_PAYMENTS] Update Reset";
