import list from "@/store/core/list";
import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_USERS_LIST,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAIL,
  GET_USERS_PAGINATION,
  GET_USERS_PAGINATION_SUCCESS,
  GET_USERS_PAGINATION_FAIL,
  GET_USERS_COLLECTION,
  GET_USERS_COLLECTION_SUCCESS,
  GET_USERS_COLLECTION_FAIL,
  GET_USERS_SINGLE,
  GET_USERS_SINGLE_SUCCESS,
  GET_USERS_SINGLE_FAIL,
  CREATE_USERS,
  CREATE_USERS_SUCCESS,
  CREATE_USERS_FAIL,
  CREATE_USERS_RESET,
  UPDATE_USERS,
  UPDATE_USERS_SUCCESS,
  UPDATE_USERS_FAIL,
  UPDATE_USERS_RESET,
  REMOVE_USERS,
  REMOVE_USERS_SUCCESS,
  REMOVE_USERS_FAIL,
  REPLACE_USERS,
  REPLACE_USERS_SUCCESS,
  REPLACE_USERS_FAIL,
  REPLACE_USERS_RESET,
  DETACH_USER,
  DETACH_USER_FAIL,
  DETACH_USER_SUCCESS,
  GET_USER_TELEGRAM_ID,
  GET_USER_TELEGRAM_ID_FAIL,
  GET_USER_TELEGRAM_ID_SUCCESS,
  SEND_WEBPUSH_TEST_NOTIFICATION,
  SEND_WEBPUSH_TEST_NOTIFICATION_SUCCESS,
  SEND_WEBPUSH_TEST_NOTIFICATION_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_USERS_LIST,
    endPoint: "users/list",
    mutationFail: GET_USERS_LIST_FAIL,
    mutationSuccess: GET_USERS_LIST_SUCCESS
  }
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_USERS_PAGINATION,
    endPoint: "users/paginated",
    mutationFail: GET_USERS_PAGINATION_FAIL,
    mutationSuccess: GET_USERS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_USERS_COLLECTION,
    endPoint: "users/collected",
    mutationFail: GET_USERS_COLLECTION_FAIL,
    mutationSuccess: GET_USERS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_USERS_SINGLE,
    endPoint: "users/single",
    mutationFail: GET_USERS_SINGLE_FAIL,
    mutationSuccess: GET_USERS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_USERS,
    actionReset: CREATE_USERS_RESET,
    endPoint: "users/create",
    mutationFail: CREATE_USERS_FAIL,
    mutationSuccess: CREATE_USERS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_USERS,
    actionReset: UPDATE_USERS_RESET,
    endPoint: "users/update",
    mutationFail: UPDATE_USERS_FAIL,
    mutationSuccess: UPDATE_USERS_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_USERS,
    actionReset: REPLACE_USERS_RESET,
    endPoint: "users/replace",
    mutationFail: REPLACE_USERS_FAIL,
    mutationSuccess: REPLACE_USERS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_USERS,
    endPoint: "users/delete",
    mutationFail: REMOVE_USERS_FAIL,
    mutationSuccess: REMOVE_USERS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    method: 'post',
    name: 'detachRequest',
    action: DETACH_USER,
    endPoint: 'users/detach/{id}',
    mutations: {
      fail: DETACH_USER_FAIL,
      success: DETACH_USER_SUCCESS,
    },
  },
  {
    name: 'getTelegramIdRequest',
    action: GET_USER_TELEGRAM_ID,
    endPoint: 'users/get-telegram-id/{id}',
    mutations: {
      fail: GET_USER_TELEGRAM_ID_FAIL,
      success: GET_USER_TELEGRAM_ID_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'webpushNotificationTest',
    action: SEND_WEBPUSH_TEST_NOTIFICATION,
    endPoint: 'users/webpush-test/{id}',
    mutations: {
      fail: SEND_WEBPUSH_TEST_NOTIFICATION_FAIL,
      success: SEND_WEBPUSH_TEST_NOTIFICATION_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
