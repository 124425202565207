import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_WAREHOUSES_LIST,
  GET_WAREHOUSES_LIST_SUCCESS,
  GET_WAREHOUSES_LIST_FAIL,
  GET_WAREHOUSES_PAGINATION,
  GET_WAREHOUSES_PAGINATION_SUCCESS,
  GET_WAREHOUSES_PAGINATION_FAIL,
  GET_WAREHOUSES_COLLECTION,
  GET_WAREHOUSES_COLLECTION_SUCCESS,
  GET_WAREHOUSES_COLLECTION_FAIL,
  GET_WAREHOUSES_SINGLE,
  GET_WAREHOUSES_SINGLE_SUCCESS,
  GET_WAREHOUSES_SINGLE_FAIL,
  CREATE_WAREHOUSES,
  CREATE_WAREHOUSES_SUCCESS,
  CREATE_WAREHOUSES_FAIL,
  CREATE_WAREHOUSES_RESET,
  UPDATE_WAREHOUSES,
  UPDATE_WAREHOUSES_SUCCESS,
  UPDATE_WAREHOUSES_FAIL,
  UPDATE_WAREHOUSES_RESET,
  REPLACE_WAREHOUSES,
  REPLACE_WAREHOUSES_SUCCESS,
  REPLACE_WAREHOUSES_FAIL,
  REPLACE_WAREHOUSES_RESET,
  REMOVE_WAREHOUSES,
  REMOVE_WAREHOUSES_SUCCESS,
  REMOVE_WAREHOUSES_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_WAREHOUSES_LIST,
    endPoint: "warehouses/list",
    mutationFail: GET_WAREHOUSES_LIST_FAIL,
    mutationSuccess: GET_WAREHOUSES_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_WAREHOUSES_PAGINATION,
    endPoint: "warehouses/paginated",
    mutationFail: GET_WAREHOUSES_PAGINATION_FAIL,
    mutationSuccess: GET_WAREHOUSES_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_WAREHOUSES_COLLECTION,
    endPoint: "warehouses/collected",
    mutationFail: GET_WAREHOUSES_COLLECTION_FAIL,
    mutationSuccess: GET_WAREHOUSES_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_WAREHOUSES_SINGLE,
    endPoint: "warehouses/single",
    mutationFail: GET_WAREHOUSES_SINGLE_FAIL,
    mutationSuccess: GET_WAREHOUSES_SINGLE_SUCCESS
  },
  creationRequest: {
    isUpload: true,
    action: CREATE_WAREHOUSES,
    actionReset: CREATE_WAREHOUSES_RESET,
    endPoint: "warehouses/create",
    mutationFail: CREATE_WAREHOUSES_FAIL,
    mutationSuccess: CREATE_WAREHOUSES_SUCCESS
  },
  editingRequest: {
    isUpload: true,
    action: UPDATE_WAREHOUSES,
    actionReset: UPDATE_WAREHOUSES_RESET,
    endPoint: "warehouses/update",
    mutationFail: UPDATE_WAREHOUSES_FAIL,
    mutationSuccess: UPDATE_WAREHOUSES_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_WAREHOUSES,
    actionReset: REPLACE_WAREHOUSES_RESET,
    endPoint: "warehouses/replace",
    mutationFail: REPLACE_WAREHOUSES_FAIL,
    mutationSuccess: REPLACE_WAREHOUSES_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_WAREHOUSES,
    endPoint: "warehouses/delete",
    mutationFail: REMOVE_WAREHOUSES_FAIL,
    mutationSuccess: REMOVE_WAREHOUSES_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.state,
};

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
