export default [
  {
    name: "warehouses",
    path: "/companies/:id/warehouses",
    component: () => import(/*webpackChunkName: "pos" */ "@/pages/warehouses/Index"),
    meta: {
      breadcrumbs: [
        { text: 'titles.companies', to: 'companies', params: false, disabled: false },
        { text: 'titles.company_settings', to: 'company-settings', params: true, disabled: false },
        { text: 'titles.company_pos', to: 'company-pos', params: true, disabled: false },
      ],
      title: 'titles.warehouses'
    }
  },
  {
    name: "point-of-sales",
    path: "/companies/:id/pos/point-of-sales",
    component: () => import(/*webpackChunkName: "pos" */ "@/pages/point-of-sales/Index"),
    meta: {
      title: 'titles.point_of_sales',
      breadcrumbs: [
        { text: 'titles.companies', to: 'companies', params: false, disabled: false },
        { text: 'titles.company_settings', to: 'company-settings', params: true, disabled: false },
        { text: 'titles.company_pos', to: 'company-pos', params: true, disabled: false },
      ],
    }
  },
  {
    name: "products",
    path: "/companies/:id/pos/products",
    component: () => import(/*webpackChunkName: "pos" */ "@/pages/products/Index"),
    meta: {
      title: 'titles.products',
      breadcrumbs: [
        { text: 'titles.companies', to: 'companies', params: false, disabled: false },
        { text: 'titles.company_settings', to: 'company-settings', params: true, disabled: false },
        { text: 'titles.company_pos', to: 'company-pos', params: true, disabled: false },
      ],
    }
  },
  {
    name: "product-categories",
    path: "/companies/:id/pos/product-categories",
    component: () => import(/*webpackChunkName: "pos" */ "@/pages/product-categories/Index"),
    meta: {
      title: 'titles.product_categories',
      breadcrumbs: [
        { text: 'titles.companies', to: 'companies', params: false, disabled: false },
        { text: 'titles.company_settings', to: 'company-settings', params: true, disabled: false },
        { text: 'titles.company_pos', to: 'company-pos', params: true, disabled: false },
      ],
    }
  },
  {
    name: "inventory-movements",
    path: "/companies/:id/pos/inventory-movements",
    component: () => import(/*webpackChunkName: "pos" */ "@/pages/inventory-movements/Index"),
    meta: {
      title: 'titles.inventory_movements',
      breadcrumbs: [
        { text: 'titles.companies', to: 'companies', params: false, disabled: false },
        { text: 'titles.company_settings', to: 'company-settings', params: true, disabled: false },
        { text: 'titles.company_pos', to: 'company-pos', params: true, disabled: false },
      ],
    }
  }
];
