export default [
  {
    name: "guest-privacy",
    path: "/privacy/:token",
    component: () => import(/*webpackChunkName: "guests" */ "@/pages/guests/Privacy"),
    meta: {
      layout: 'privacy'
    }
  },
];
