export default [
  {
    name: "housekeeping-planner",
    path: "/housekeeping/planner",
    component: () => import(/*webpackChunkName: "housekeeping" */ "@/pages/housekeeping/Index"),
    meta: {
      title: 'titles.housekeeping_planner',
      loginRedirect: true
    }
  },
  {
    name: "housekeeping-calendar",
    path: "/housekeeping/calendar",
    component: () => import(/*webpackChunkName: "housekeeping" */ "@/pages/housekeeping/Calendar"),
    meta: {
      title: 'titles.housekeeping_calendar',
      loginRedirect: true
    }
  },
  {
    name: "housekeeping-report",
    path: "/housekeeping/report",
    component: () => import(/*webpackChunkName: "housekeeping" */ "@/pages/housekeeping/Report"),
    meta: {
      title: 'titles.housekeeping_report',
      loginRedirect: true
    }
  },
  {
    name: "housekeeping-report-by-unit",
    path: "/housekeeping/report-by-unit",
    component: () => import(/*webpackChunkName: "housekeeping" */ "@/pages/housekeeping/ReportByUnit"),
    meta: {
      title: 'titles.housekeeping_report_by_unit',
      loginRedirect: true
    }
  },
];
