export const GET_HOUSEKEEPING_SETTINGS_LIST = "[HOUSEKEEPING_SETTINGS] List Get";
export const GET_HOUSEKEEPING_SETTINGS_LIST_SUCCESS = "[HOUSEKEEPING_SETTINGS] List Get Success";
export const GET_HOUSEKEEPING_SETTINGS_LIST_FAIL = "[HOUSEKEEPING_SETTINGS] List Get Fail";

export const GET_HOUSEKEEPING_SETTINGS_PAGINATION = "[HOUSEKEEPING_SETTINGS] Pagination Get";
export const GET_HOUSEKEEPING_SETTINGS_PAGINATION_SUCCESS = "[HOUSEKEEPING_SETTINGS] Pagination Get Success";
export const GET_HOUSEKEEPING_SETTINGS_PAGINATION_FAIL = "[HOUSEKEEPING_SETTINGS] Pagination Get Fail";

export const GET_HOUSEKEEPING_SETTINGS_COLLECTION = "[HOUSEKEEPING_SETTINGS] Collection Get";
export const GET_HOUSEKEEPING_SETTINGS_COLLECTION_SUCCESS = "[HOUSEKEEPING_SETTINGS] Collection Get Success";
export const GET_HOUSEKEEPING_SETTINGS_COLLECTION_FAIL = "[HOUSEKEEPING_SETTINGS] Collection Get Fail";

export const GET_HOUSEKEEPING_SETTINGS_SINGLE = "[HOUSEKEEPING_SETTINGS] Single Get";
export const GET_HOUSEKEEPING_SETTINGS_SINGLE_SUCCESS = "[HOUSEKEEPING_SETTINGS] Single Get Success";
export const GET_HOUSEKEEPING_SETTINGS_SINGLE_FAIL = "[HOUSEKEEPING_SETTINGS] Single Get Fail";

export const CREATE_HOUSEKEEPING_SETTINGS = "[HOUSEKEEPING_SETTINGS] Create";
export const CREATE_HOUSEKEEPING_SETTINGS_SUCCESS = "[HOUSEKEEPING_SETTINGS] Create Success";
export const CREATE_HOUSEKEEPING_SETTINGS_FAIL = "[HOUSEKEEPING_SETTINGS] Create Fail";
export const CREATE_HOUSEKEEPING_SETTINGS_RESET = "[HOUSEKEEPING_SETTINGS] Create Reset";

export const UPDATE_HOUSEKEEPING_SETTINGS = "[HOUSEKEEPING_SETTINGS] Update";
export const UPDATE_HOUSEKEEPING_SETTINGS_SUCCESS = "[HOUSEKEEPING_SETTINGS] Update Success";
export const UPDATE_HOUSEKEEPING_SETTINGS_FAIL = "[HOUSEKEEPING_SETTINGS] Update Fail";
export const UPDATE_HOUSEKEEPING_SETTINGS_RESET = "[HOUSEKEEPING_SETTINGS] Update Reset";

export const REMOVE_HOUSEKEEPING_SETTINGS = "[HOUSEKEEPING_SETTINGS] Remove";
export const REMOVE_HOUSEKEEPING_SETTINGS_SUCCESS = "[HOUSEKEEPING_SETTINGS] Remove Success";
export const REMOVE_HOUSEKEEPING_SETTINGS_FAIL = "[HOUSEKEEPING_SETTINGS] Remove Fail";

export const GENERATE_HOUSEKEEPING_TASKS = "[HOUSEKEEPING_SETTINGS] Generate Tasks";
export const GENERATE_HOUSEKEEPING_TASKS_SUCCESS = "[HOUSEKEEPING_SETTINGS] Generate Tasks Success";
export const GENERATE_HOUSEKEEPING_TASKS_FAIL = "[HOUSEKEEPING_SETTINGS] Generate Tasks Fail";

export const UPDATE_HOUSEKEEPING_COSTS = "[HOUSEKEEPING_SETTINGS] Costs Update";
export const UPDATE_HOUSEKEEPING_COSTS_SUCCESS = "[HOUSEKEEPING_SETTINGS] Costs Update Success";
export const UPDATE_HOUSEKEEPING_COSTS_FAIL = "[HOUSEKEEPING_SETTINGS] Costs Update Fail";

export const GET_PROPERTY_ICS_LINK = "[HOUSEKEEPING] Get Property Ics Link";
export const GET_PROPERTY_ICS_LINK_SUCCESS = "[HOUSEKEEPING] Get Property Ics Link Success";
export const GET_PROPERTY_ICS_LINK_FAIL = "[HOUSEKEEPING] Get Property Ics Link Fail";