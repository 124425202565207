<template>
  <span>
    <v-autocomplete
      v-model="ids"
      item-value="id"
      item-text="name"
      :items="units"
      :label="label"
      :multiple="multiple"
      :small-chips="smallChips"
      :deletable-chips="deletableChips"
      :hint="hint"
      :persistent-hint="persistentHint"
      :loading="loading"
      :prepend-icon="prependIcon"
      append-outer-icon="mdi-refresh"
      :clearable="clearable"
      :disabled="disabled"
      :readonly="readonly"
      :dark="dark"
      @click:append-outer="getUnits()"
      :dense="dense"
      :error="error"
      :error-messages="!!errorMessages ? errorMessages : []"
      :filter="searchUnit"
    >
      <template v-slot:item="{ item }">
        <v-list-item-content @click="checkUnitCategory(item.unit_category_id)">
          <v-list-item-title>
            {{ item.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <span v-if="withProperty">
              <v-icon x-small>mdi-office-building</v-icon>
              {{ item.property.name }}<br />
            </span>
            <v-chip
              :color="item.unit_category.color"
              class="white--text"
              x-small
              label
            >
              <v-icon color="white" class="mr-1" x-small>mdi-shape</v-icon>
              {{ item.unit_category.name }}
            </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $lang('reservations.labels.no_units_available') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </span>
</template>

<script>
import { mapState } from "vuex";
import { GET_UNITS_COLLECTION } from "@/store/units/actions";

export default {
  name: "CUnitsAutocomplete",
  props: {
    label: {
      type: String,
      required: false,
      default: "Units"
    },
    hint: {
      type: String,
      default: ""
    },
    persistentHint: {
      type: Boolean,
      default: false
    },
    value: {
      default: null
    },
    error: {
      default: false
    },
    errorMessages: {
      default: () => []
    },
    dense: {
      type: Boolean,
      default: false
    },
    prependIcon: {
      type: String,
      default: "mdi-bed"
    },
    clearable: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    smallChips: {
      type: Boolean,
      default: false
    },
    deletableChips: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: () => {
        return {};
      }
    },
    options: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      ids: null
    };
  },
  beforeMount() {
    this.getUnits();
  },
  mounted() {
    this.ids = this.value;
  },
  methods: {
    getUnits() {
      if (
        this.disabled ||
        this.unitsRequest.loading ||
        this.$cannot('VIEW_UNITS')
      ) {
        return;
      }

      this.$store.dispatch(GET_UNITS_COLLECTION, {
        unit_selects: true,
        ...this.filters
      });
    },
    change() {
      this.$emit("change");
    },
    blur() {
      this.$emit("blur");
    },
    checkUnitCategory(unit_category_id) {
      if (!this.options.unit_category_id) {
        return;
      }
      if (this.options.unit_category_id != unit_category_id) {
        this.showNotification(this.$lang('reservations.hints.unit_category_changed'), "warning");
      }
    },
    searchUnit(item, queryText, itemText) {
      const propertyName = item.property ? item.property.name : null;
      
      return item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        propertyName.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
    },
  },
  computed: {
    ...mapState({
      unitsRequest: (state) => state.units.getCollectionRequest
    }),
    units() {
      if (!this.unitsRequest.collection) {
        return [];
      }

      return this.unitsRequest.collection;
    },
    loading() {
      return this.unitsRequest.loading;
    },
    withProperty() {
      return !this.filters.property_id;
    },
  },
  watch: {
    ids(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.ids = newVal;
    },
    filters: {
      handler() {
        this.getUnits();
      },
      deep: true
    }
  }
};
</script>
