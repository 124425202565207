import moment from "moment";

export class Local {
  /**
   * Get key
   *
   * @param key
   * @returns {*}
   */
  get(key) {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
      return null;
    }

    const item = JSON.parse(itemStr);

    if (item.expire_at && moment().isAfter(moment(item.expire_at))) {
      this.remove(key);
      return null;
    }

    return item.value;
  }

  /**
   * Set key
   *
   * @param key
   * @param value
   * @param options
   * @returns {*}
   */
  set(key, value, options = {}) {
    const item = { value };

    if (options.ttl && options.ttl > 0) {
      item.expire_at = moment().add(options.ttl, 'seconds');
    }

    return localStorage.setItem(key, JSON.stringify(item));
  }

  /**
   * Remove key
   *
   * @param key
   * @param options
   * @returns {*}
   */
  remove(key, options = {}) {
    return localStorage.removeItem(key);
  }

  /**
   *
   * @returns {*[]}
   */
  getAll() {
    let values = [];
    let keys = Object.keys(localStorage);
    let i = keys.length;

    while (i--) {
      values.push(localStorage.getItem(keys[i]));
    }

    return values;
  }
}
