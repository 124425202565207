import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_WORK_ORDER_COMMENTS_LIST,
  GET_WORK_ORDER_COMMENTS_LIST_SUCCESS,
  GET_WORK_ORDER_COMMENTS_LIST_FAIL,
  GET_WORK_ORDER_COMMENTS_PAGINATION,
  GET_WORK_ORDER_COMMENTS_PAGINATION_SUCCESS,
  GET_WORK_ORDER_COMMENTS_PAGINATION_FAIL,
  GET_WORK_ORDER_COMMENTS_COLLECTION,
  GET_WORK_ORDER_COMMENTS_COLLECTION_SUCCESS,
  GET_WORK_ORDER_COMMENTS_COLLECTION_FAIL,
  GET_WORK_ORDER_COMMENTS_SINGLE,
  GET_WORK_ORDER_COMMENTS_SINGLE_SUCCESS,
  GET_WORK_ORDER_COMMENTS_SINGLE_FAIL,
  CREATE_WORK_ORDER_COMMENTS,
  CREATE_WORK_ORDER_COMMENTS_SUCCESS,
  CREATE_WORK_ORDER_COMMENTS_FAIL,
  CREATE_WORK_ORDER_COMMENTS_RESET,
  UPDATE_WORK_ORDER_COMMENTS,
  UPDATE_WORK_ORDER_COMMENTS_SUCCESS,
  UPDATE_WORK_ORDER_COMMENTS_FAIL,
  REPLACE_WORK_ORDER_COMMENTS,
  REPLACE_WORK_ORDER_COMMENTS_SUCCESS,
  REPLACE_WORK_ORDER_COMMENTS_FAIL,
  REPLACE_WORK_ORDER_COMMENTS_RESET,
  UPDATE_WORK_ORDER_COMMENTS_RESET,
  REMOVE_WORK_ORDER_COMMENTS,
  REMOVE_WORK_ORDER_COMMENTS_SUCCESS,
  REMOVE_WORK_ORDER_COMMENTS_FAIL
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_WORK_ORDER_COMMENTS_LIST,
    endPoint: "work-orders/comments/list",
    mutationFail: GET_WORK_ORDER_COMMENTS_LIST_FAIL,
    mutationSuccess: GET_WORK_ORDER_COMMENTS_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_WORK_ORDER_COMMENTS_PAGINATION,
    endPoint: "work-orders/comments/paginated",
    mutationFail: GET_WORK_ORDER_COMMENTS_PAGINATION_FAIL,
    mutationSuccess: GET_WORK_ORDER_COMMENTS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_WORK_ORDER_COMMENTS_COLLECTION,
    endPoint: "work-orders/comments/collected",
    mutationFail: GET_WORK_ORDER_COMMENTS_COLLECTION_FAIL,
    mutationSuccess: GET_WORK_ORDER_COMMENTS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_WORK_ORDER_COMMENTS_SINGLE,
    endPoint: "work-orders/comments/single",
    mutationFail: GET_WORK_ORDER_COMMENTS_SINGLE_FAIL,
    mutationSuccess: GET_WORK_ORDER_COMMENTS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_WORK_ORDER_COMMENTS,
    actionReset: CREATE_WORK_ORDER_COMMENTS_RESET,
    endPoint: "work-orders/comments/create",
    mutationFail: CREATE_WORK_ORDER_COMMENTS_FAIL,
    mutationSuccess: CREATE_WORK_ORDER_COMMENTS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_WORK_ORDER_COMMENTS,
    actionReset: UPDATE_WORK_ORDER_COMMENTS_RESET,
    endPoint: "work-orders/comments/update",
    mutationFail: UPDATE_WORK_ORDER_COMMENTS_FAIL,
    mutationSuccess: UPDATE_WORK_ORDER_COMMENTS_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_WORK_ORDER_COMMENTS,
    actionReset: REPLACE_WORK_ORDER_COMMENTS_RESET,
    endPoint: "work-orders/comments/replace",
    mutationFail: REPLACE_WORK_ORDER_COMMENTS_FAIL,
    mutationSuccess: REPLACE_WORK_ORDER_COMMENTS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_WORK_ORDER_COMMENTS,
    endPoint: "work-orders/comments/delete",
    mutationFail: REMOVE_WORK_ORDER_COMMENTS_FAIL,
    mutationSuccess: REMOVE_WORK_ORDER_COMMENTS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
