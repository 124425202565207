export const GET_COMPANY_LOCATIONS_LIST = "[COMPANY_LOCATIONS] List Get";
export const GET_COMPANY_LOCATIONS_LIST_SUCCESS = "[COMPANY_LOCATIONS] List Get Success";
export const GET_COMPANY_LOCATIONS_LIST_FAIL = "[COMPANY_LOCATIONS] List Get Fail";

export const GET_COMPANY_LOCATIONS_PAGINATION = "[COMPANY_LOCATIONS] Pagination Get";
export const GET_COMPANY_LOCATIONS_PAGINATION_SUCCESS = "[COMPANY_LOCATIONS] Pagination Get Success";
export const GET_COMPANY_LOCATIONS_PAGINATION_FAIL = "[COMPANY_LOCATIONS] Pagination Get Fail";

export const GET_COMPANY_LOCATIONS_COLLECTION = "[COMPANY_LOCATIONS] Collection Get";
export const GET_COMPANY_LOCATIONS_COLLECTION_SUCCESS = "[COMPANY_LOCATIONS] Collection Get Success";
export const GET_COMPANY_LOCATIONS_COLLECTION_FAIL = "[COMPANY_LOCATIONS] Collection Get Fail";

export const GET_COMPANY_LOCATIONS_SINGLE = "[COMPANY_LOCATIONS] Single Get";
export const GET_COMPANY_LOCATIONS_SINGLE_SUCCESS = "[COMPANY_LOCATIONS] Single Get Success";
export const GET_COMPANY_LOCATIONS_SINGLE_FAIL = "[COMPANY_LOCATIONS] Single Get Fail";

export const CREATE_COMPANY_LOCATIONS = "[COMPANY_LOCATIONS] Create";
export const CREATE_COMPANY_LOCATIONS_SUCCESS = "[COMPANY_LOCATIONS] Create Success";
export const CREATE_COMPANY_LOCATIONS_FAIL = "[COMPANY_LOCATIONS] Create Fail";
export const CREATE_COMPANY_LOCATIONS_RESET = "[COMPANY_LOCATIONS] Create Reset";

export const UPDATE_COMPANY_LOCATIONS = "[COMPANY_LOCATIONS] Update";
export const UPDATE_COMPANY_LOCATIONS_SUCCESS = "[COMPANY_LOCATIONS] Update Success";
export const UPDATE_COMPANY_LOCATIONS_FAIL = "[COMPANY_LOCATIONS] Update Fail";
export const UPDATE_COMPANY_LOCATIONS_RESET = "[COMPANY_LOCATIONS] Update Reset";

export const REMOVE_COMPANY_LOCATIONS = "[COMPANY_LOCATIONS] Remove";
export const REMOVE_COMPANY_LOCATIONS_SUCCESS = "[COMPANY_LOCATIONS] Remove Success";
export const REMOVE_COMPANY_LOCATIONS_FAIL = "[COMPANY_LOCATIONS] Remove Fail";