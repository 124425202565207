import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_OCTORATE_CONNECTIONS_PAGINATION,
  GET_OCTORATE_CONNECTIONS_PAGINATION_SUCCESS,
  GET_OCTORATE_CONNECTIONS_PAGINATION_FAIL,
  GET_OCTORATE_CONNECTIONS_COLLECTION,
  GET_OCTORATE_CONNECTIONS_COLLECTION_SUCCESS,
  GET_OCTORATE_CONNECTIONS_COLLECTION_FAIL,
  GET_OCTORATE_CONNECTIONS_SINGLE,
  GET_OCTORATE_CONNECTIONS_SINGLE_SUCCESS,
  GET_OCTORATE_CONNECTIONS_SINGLE_FAIL,
  CREATE_OCTORATE_CONNECTIONS,
  CREATE_OCTORATE_CONNECTIONS_SUCCESS,
  CREATE_OCTORATE_CONNECTIONS_FAIL,
  CREATE_OCTORATE_CONNECTIONS_RESET,
  UPDATE_OCTORATE_CONNECTIONS,
  UPDATE_OCTORATE_CONNECTIONS_SUCCESS,
  UPDATE_OCTORATE_CONNECTIONS_FAIL,
  UPDATE_OCTORATE_CONNECTIONS_RESET,
  REMOVE_OCTORATE_CONNECTIONS,
  REMOVE_OCTORATE_CONNECTIONS_SUCCESS,
  REMOVE_OCTORATE_CONNECTIONS_FAIL,
  IMPORT_OCTORATE_CONNECTIONS,
  IMPORT_OCTORATE_CONNECTIONS_SUCCESS,
  IMPORT_OCTORATE_CONNECTIONS_FAIL,
  OCTORATE_CONNECTION_ACTION,
  OCTORATE_CONNECTION_ACTION_SUCCESS,
  OCTORATE_CONNECTION_ACTION_FAIL,
  OCTORATE_CONNECTION_SYNC,
  OCTORATE_CONNECTION_SYNC_SUCCESS,
  OCTORATE_CONNECTION_SYNC_FAIL,
  OCTORATE_CONNECTION_ACTIVATE,
  OCTORATE_CONNECTION_ACTIVATE_SUCCESS,
  OCTORATE_CONNECTION_ACTIVATE_FAIL,
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_OCTORATE_CONNECTIONS_PAGINATION,
    endPoint: "octorate/connections/paginated",
    mutationFail: GET_OCTORATE_CONNECTIONS_PAGINATION_FAIL,
    mutationSuccess: GET_OCTORATE_CONNECTIONS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_OCTORATE_CONNECTIONS_COLLECTION,
    endPoint: "octorate/connections/collected",
    mutationFail: GET_OCTORATE_CONNECTIONS_COLLECTION_FAIL,
    mutationSuccess: GET_OCTORATE_CONNECTIONS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_OCTORATE_CONNECTIONS_SINGLE,
    endPoint: "octorate/connections/single",
    mutationFail: GET_OCTORATE_CONNECTIONS_SINGLE_FAIL,
    mutationSuccess: GET_OCTORATE_CONNECTIONS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_OCTORATE_CONNECTIONS,
    actionReset: CREATE_OCTORATE_CONNECTIONS_RESET,
    endPoint: "octorate/connections/create",
    mutationFail: CREATE_OCTORATE_CONNECTIONS_FAIL,
    mutationSuccess: CREATE_OCTORATE_CONNECTIONS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_OCTORATE_CONNECTIONS,
    actionReset: UPDATE_OCTORATE_CONNECTIONS_RESET,
    endPoint: "octorate/connections/update",
    mutationFail: UPDATE_OCTORATE_CONNECTIONS_FAIL,
    mutationSuccess: UPDATE_OCTORATE_CONNECTIONS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_OCTORATE_CONNECTIONS,
    endPoint: "octorate/connections/delete",
    mutationFail: REMOVE_OCTORATE_CONNECTIONS_FAIL,
    mutationSuccess: REMOVE_OCTORATE_CONNECTIONS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    name: 'getImport',
    action: IMPORT_OCTORATE_CONNECTIONS,
    endPoint: 'octorate/connections/import',
    mutations: {
      fail: IMPORT_OCTORATE_CONNECTIONS_FAIL,
      success: IMPORT_OCTORATE_CONNECTIONS_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'postAction',
    action: OCTORATE_CONNECTION_ACTION,
    endPoint: 'octorate/connections/action/{id}',
    mutations: {
      fail: OCTORATE_CONNECTION_ACTION_FAIL,
      success: OCTORATE_CONNECTION_ACTION_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'postSync',
    action: OCTORATE_CONNECTION_SYNC,
    endPoint: 'octorate/connections/sync',
    mutations: {
      fail: OCTORATE_CONNECTION_SYNC_FAIL,
      success: OCTORATE_CONNECTION_SYNC_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'postActivateConnection',
    action: OCTORATE_CONNECTION_ACTIVATE,
    endPoint: 'octorate/connections/activate/{id}',
    mutations: {
      fail: OCTORATE_CONNECTION_ACTIVATE_FAIL,
      success: OCTORATE_CONNECTION_ACTIVATE_SUCCESS,
    },
  },
]);

const actions = {
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
