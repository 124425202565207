import { mapState } from 'vuex';

export default {
  methods: {
    roleIcon(roleName) {
      switch (true) {
        case roleName.includes('feature'):
          return 'mdi-star';
        case roleName.includes('role'):
          return 'mdi-account-key';
        case roleName.includes('external'):
          return 'mdi-api';
        case roleName.includes('admin'):
          return 'mdi-alert'
        case roleName.includes('internal'):
          return 'mdi-face-agent'
        default:
          return 'mdi-crosshairs-question';
      }
    },
    roleColor(role) {
      let roleName = role.name;

      switch (roleName) {
        case this.$const('ConstRoles', 'ADMIN'):
          return 'black';
        case this.$const('ConstRoles', 'INTERNAL_SUPPORT'):
          return 'red darken-4';
        case this.$const('ConstRoles', 'USER_ADMIN'):
          return 'red';
        case this.$const('ConstRoles', 'MANAGER'):
          return 'blue-grey darken-3';
        case this.$const('ConstRoles', 'STAFF'):
          return 'orange darken-4';
        case this.$const('ConstRoles', 'OWNER'):
          return 'green';
        case this.$const('ConstRoles', 'OWNER_RS'):
          return 'green darken-1';
        case this.$const('ConstRoles', 'CLEANING_SERVICE'):
          return 'purple';
        case this.$const('ConstRoles', 'EXTERNAL_REVENUE_MANAGER'):
          return 'grey';
        default:
          return 'secondary';
      }
    },
    sortRoles(items) {
      return items.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
    }
  },
  computed: {
    ...mapState({
      roles: state => state.roles.getCollectionRequest.collection,
      companiesRequest: state => state.companies.getCollectionRequest
    }),
    computedRoles() {
      let role = [];
      let feature = [];
      let external = [];
      let extra = [];

      this.roles.map(item => {
        switch (true) {
          case item.name.includes('feature'): 
            feature.push(item);
            break;
          case item.name.includes('role'): 
            role.push(item);
            break;
          case item.name.includes('external'): 
            external.push(item);
            break;
          default:
            extra.push(item);
        }
      });

      const data = {
        role: this.sortRoles(role),
        feature,
        external,
        extra,
      };

      if (!this.$can('INTERNAL_SUPPORT')) {
        delete data['extra'];
      }

      return data;
    }
  },
};
