<template>
  <v-dialog v-model="isOpen" :width="width" @keydown.esc="no" @keydown.enter="yes">
    <v-card>
      <v-form @submit.prevent="yes">
        <v-card-title>
          <span v-if="!title">
            {{ $lang("main.crud.are_you_sure") }}
          </span>
          <span v-if="!!title">
            {{ title }}
          </span>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <span v-if="!!message">
            {{ message }}
          </span>
          <slot name="content"></slot>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="no"
          >
            {{ $lang("main.no") }}
          </v-btn>
          <v-btn
            color="primary"
            :loading="!!options.loading"
            type="submit"
          >
            {{ $lang("main.yes") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: "VConfirmation",
    props: {
      value: {
        default: null
      },
      options: {
        type: Object,
        required: false,
        default() {
          return {};
        }
      },
      title: {
        type: String,
        required: false,
      },
      message: {
        type: String,
        required: false,
      },
      width: {
        required: false,
        default: '300'
      }
    },
    data() {
      return {
        isOpen: false
      }
    },
    created() {
      this.isOpen = this.value;
    },
    methods: {
      no() {
        this.isOpen = false;

        if (typeof this.options.no === 'function') {
          this.options.no();
        }
      },
      yes() {
        this.options.yes();
      }
    },
    watch: {
      value() {
        this.isOpen = this.value;
      },
      isOpen(val) {
        this.$emit('input', val);
      }
    }
  }
</script>