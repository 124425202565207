<template>
  <span>
    <v-autocomplete
      v-model="ids"
      item-value="accommodation_id"
      item-text="accommodation_id"
      :items="items"
      :label="label"
      :multiple="multiple"
      :small-chips="smallChips"
      :deletable-chips="deletableChips"
      :hint="hint"
      :persistent-hint="persistentHint"
      :loading="loading"
      :prepend-icon="prependIcon"
      append-outer-icon="mdi-refresh"
      :clearable="clearable"
      :disabled="disabled"
      :readonly="readonly"
      :dark="dark"
      @click:append-outer="getData()"
      :dense="dense"
      :error="error"
      :error-messages="!!errorMessages ? errorMessages : []"
      :filter="searchElement"
    >
      <template v-slot:item="{ item }">
        <v-list>
          <v-list-item-content>
            <v-list-item-title>
              {{item.accommodation_id}}
            </v-list-item-title>
            <v-list-item-subtitle v-for="(element, el) in item.property" :key="el">
              {{element.name}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list>
      </template>
    </v-autocomplete>
  </span>
</template>

<script>
import { mapState } from "vuex";
import {
    GET_OCTORATE_ACCOMMODATIONS_COLLECTION
} from "@/store/octorate-accommodations/actions";

export default {
  name: "CUnitsAutocomplete",
  props: {
    label: {
      type: String,
      required: false,
      default: "Units"
    },
    hint: {
      type: String,
      default: ""
    },
    persistentHint: {
      type: Boolean,
      default: false
    },
    value: {
      default: null
    },
    error: {
      default: false
    },
    errorMessages: {
      default: () => []
    },
    dense: {
      type: Boolean,
      default: false
    },
    prependIcon: {
      type: String,
      default: "mdi-office-building"
    },
    clearable: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    smallChips: {
      type: Boolean,
      default: false
    },
    deletableChips: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: () => {
        return {};
      }
    },
    options: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      ids: null
    };
  },
  beforeMount() {
    this.getData();
  },
  mounted() {
    this.ids = this.value;
  },
  methods: {
    searchElement(item, queryText, itemText) {
      let names = item.property.filter(el => {
        return el.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
      });

      return item.accommodation_id.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 || names.length;
    },
    getData() {
      this.$store.dispatch(GET_OCTORATE_ACCOMMODATIONS_COLLECTION, {
        sortBy: 'accommodation_id',
        order: 'asc',
        _data: {
          relations: {
            property: {
              selects: [
                'id',
                'octorate_accommodation_id',
                'name'
              ]
            }
          }
        }
      });
    },
    change() {
      this.$emit("change");
    },
    blur() {
      this.$emit("blur");
    },
  },
  computed: {
    ...mapState({
      request: state => state.octorateAccommodations.getCollectionRequest,
    }),
    items() {
      if (!this.request.collection) {
        return [];
      }

      return this.request.collection;
    },
    loading() {
      return this.request.loading;
    },
  },
  watch: {
    ids(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.ids = newVal;
    },
  }
};
</script>
