export const GET_BOOKING_NETWORK_DESTINATIONS_LIST = "[BOOKING_NETWORK_DESTINATIONS] List Get";
export const GET_BOOKING_NETWORK_DESTINATIONS_LIST_SUCCESS = "[BOOKING_NETWORK_DESTINATIONS] List Get Success";
export const GET_BOOKING_NETWORK_DESTINATIONS_LIST_FAIL = "[BOOKING_NETWORK_DESTINATIONS] List Get Fail";

export const GET_BOOKING_NETWORK_DESTINATIONS_PAGINATION = "[BOOKING_NETWORK_DESTINATIONS] Pagination Get";
export const GET_BOOKING_NETWORK_DESTINATIONS_PAGINATION_SUCCESS = "[BOOKING_NETWORK_DESTINATIONS] Pagination Get Success";
export const GET_BOOKING_NETWORK_DESTINATIONS_PAGINATION_FAIL = "[BOOKING_NETWORK_DESTINATIONS] Pagination Get Fail";

export const GET_BOOKING_NETWORK_DESTINATIONS_COLLECTION = "[BOOKING_NETWORK_DESTINATIONS] Collection Get";
export const GET_BOOKING_NETWORK_DESTINATIONS_COLLECTION_SUCCESS = "[BOOKING_NETWORK_DESTINATIONS] Collection Get Success";
export const GET_BOOKING_NETWORK_DESTINATIONS_COLLECTION_FAIL = "[BOOKING_NETWORK_DESTINATIONS] Collection Get Fail";

export const GET_BOOKING_NETWORK_DESTINATIONS_SINGLE = "[BOOKING_NETWORK_DESTINATIONS] Single Get";
export const GET_BOOKING_NETWORK_DESTINATIONS_SINGLE_SUCCESS = "[BOOKING_NETWORK_DESTINATIONS] Single Get Success";
export const GET_BOOKING_NETWORK_DESTINATIONS_SINGLE_FAIL = "[BOOKING_NETWORK_DESTINATIONS] Single Get Fail";

export const CREATE_BOOKING_NETWORK_DESTINATIONS = "[BOOKING_NETWORK_DESTINATIONS] Create";
export const CREATE_BOOKING_NETWORK_DESTINATIONS_SUCCESS = "[BOOKING_NETWORK_DESTINATIONS] Create Success";
export const CREATE_BOOKING_NETWORK_DESTINATIONS_FAIL = "[BOOKING_NETWORK_DESTINATIONS] Create Fail";
export const CREATE_BOOKING_NETWORK_DESTINATIONS_RESET = "[BOOKING_NETWORK_DESTINATIONS] Create Reset";

export const UPDATE_BOOKING_NETWORK_DESTINATIONS = "[BOOKING_NETWORK_DESTINATIONS] Update";
export const UPDATE_BOOKING_NETWORK_DESTINATIONS_SUCCESS = "[BOOKING_NETWORK_DESTINATIONS] Update Success";
export const UPDATE_BOOKING_NETWORK_DESTINATIONS_FAIL = "[BOOKING_NETWORK_DESTINATIONS] Update Fail";
export const UPDATE_BOOKING_NETWORK_DESTINATIONS_RESET = "[BOOKING_NETWORK_DESTINATIONS] Update Reset";

export const REPLACE_BOOKING_NETWORK_DESTINATIONS = "[BOOKING_NETWORK_DESTINATIONS] Replace";
export const REPLACE_BOOKING_NETWORK_DESTINATIONS_SUCCESS = "[BOOKING_NETWORK_DESTINATIONS] Replace Success";
export const REPLACE_BOOKING_NETWORK_DESTINATIONS_FAIL = "[BOOKING_NETWORK_DESTINATIONS] Replace Fail";
export const REPLACE_BOOKING_NETWORK_DESTINATIONS_RESET = "[BOOKING_NETWORK_DESTINATIONS] Replace Reset";

export const REMOVE_BOOKING_NETWORK_DESTINATIONS = "[BOOKING_NETWORK_DESTINATIONS] Remove";
export const REMOVE_BOOKING_NETWORK_DESTINATIONS_SUCCESS = "[BOOKING_NETWORK_DESTINATIONS] Remove Success";
export const REMOVE_BOOKING_NETWORK_DESTINATIONS_FAIL = "[BOOKING_NETWORK_DESTINATIONS] Remove Fail";
