import ApiService from "@/services/api";

import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_ASSETS_LIST,
  GET_ASSETS_LIST_SUCCESS,
  GET_ASSETS_LIST_FAIL,
  GET_ASSETS_PAGINATION,
  GET_ASSETS_PAGINATION_SUCCESS,
  GET_ASSETS_PAGINATION_FAIL,
  GET_ASSETS_COLLECTION,
  GET_ASSETS_COLLECTION_SUCCESS,
  GET_ASSETS_COLLECTION_FAIL,
  GET_ASSETS_SINGLE,
  GET_ASSETS_SINGLE_SUCCESS,
  GET_ASSETS_SINGLE_FAIL,
  CREATE_ASSETS,
  CREATE_ASSETS_SUCCESS,
  CREATE_ASSETS_FAIL,
  CREATE_ASSETS_RESET,
  UPDATE_ASSETS,
  UPDATE_ASSETS_SUCCESS,
  UPDATE_ASSETS_FAIL,
  UPDATE_ASSETS_RESET,
  REMOVE_ASSETS,
  REMOVE_ASSETS_SUCCESS,
  REMOVE_ASSETS_FAIL,
  GET_ASSETS_DOWNTIME,
  GET_ASSETS_DOWNTIME_SUCCESS,
  GET_ASSETS_DOWNTIME_FAIL
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_ASSETS_LIST,
    endPoint: "assets/list",
    mutationFail: GET_ASSETS_LIST_FAIL,
    mutationSuccess: GET_ASSETS_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_ASSETS_PAGINATION,
    endPoint: "assets/paginated",
    mutationFail: GET_ASSETS_PAGINATION_FAIL,
    mutationSuccess: GET_ASSETS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_ASSETS_COLLECTION,
    endPoint: "assets/collected",
    mutationFail: GET_ASSETS_COLLECTION_FAIL,
    mutationSuccess: GET_ASSETS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_ASSETS_SINGLE,
    endPoint: "assets/single",
    mutationFail: GET_ASSETS_SINGLE_FAIL,
    mutationSuccess: GET_ASSETS_SINGLE_SUCCESS
  },
  creationRequest: {
    isUpload: true,
    action: CREATE_ASSETS,
    actionReset: CREATE_ASSETS_RESET,
    endPoint: "assets/create",
    mutationFail: CREATE_ASSETS_FAIL,
    mutationSuccess: CREATE_ASSETS_SUCCESS
  },
  editingRequest: {
    isUpload: true,
    action: UPDATE_ASSETS,
    actionReset: UPDATE_ASSETS_RESET,
    endPoint: "assets/update",
    mutationFail: UPDATE_ASSETS_FAIL,
    mutationSuccess: UPDATE_ASSETS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_ASSETS,
    endPoint: "assets/delete",
    mutationFail: REMOVE_ASSETS_FAIL,
    mutationSuccess: REMOVE_ASSETS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const actions = {
  [GET_ASSETS_DOWNTIME](context, params) {
    context.state.getDowntimeRequest.loading = true;

    ApiService.query(`assets/get-downtime`, params)
      .then(response => {
        context.commit(GET_ASSETS_DOWNTIME_SUCCESS, response.data.data);
      })
      .catch(response => {
        context.commit(GET_ASSETS_DOWNTIME_FAIL, response.data);
      });
  },
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const getters = {};

const state = {
  getDowntimeRequest: {
    loading: false,
    success: false,
    fail: false,
    data: [],
    errors: {},
  },
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  [GET_ASSETS_DOWNTIME_SUCCESS](state, data) {
    state.getDowntimeRequest = {
      loading: false,
      success: true,
      fail: false,
      data: data,
      errors: {},
    };
  },
  [GET_ASSETS_DOWNTIME_FAIL](state, data) {
    state.getDowntimeRequest = {
      loading: false,
      success: false,
      fail: true,
      data: [],
      errors: data,
    };
  },
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
