export const GET_COMPANY_SMART_DEVICES_LIST = "[COMPANY_SMART_DEVICES] List Get";
export const GET_COMPANY_SMART_DEVICES_LIST_SUCCESS = "[COMPANY_SMART_DEVICES] List Get Success";
export const GET_COMPANY_SMART_DEVICES_LIST_FAIL = "[COMPANY_SMART_DEVICES] List Get Fail";

export const GET_COMPANY_SMART_DEVICES_PAGINATION = "[COMPANY_SMART_DEVICES] Pagination Get";
export const GET_COMPANY_SMART_DEVICES_PAGINATION_SUCCESS = "[COMPANY_SMART_DEVICES] Pagination Get Success";
export const GET_COMPANY_SMART_DEVICES_PAGINATION_FAIL = "[COMPANY_SMART_DEVICES] Pagination Get Fail";

export const GET_COMPANY_SMART_DEVICES_COLLECTION = "[COMPANY_SMART_DEVICES] Collection Get";
export const GET_COMPANY_SMART_DEVICES_COLLECTION_SUCCESS = "[COMPANY_SMART_DEVICES] Collection Get Success";
export const GET_COMPANY_SMART_DEVICES_COLLECTION_FAIL = "[COMPANY_SMART_DEVICES] Collection Get Fail";

export const GET_COMPANY_SMART_DEVICES_SINGLE = "[COMPANY_SMART_DEVICES] Single Get";
export const GET_COMPANY_SMART_DEVICES_SINGLE_SUCCESS = "[COMPANY_SMART_DEVICES] Single Get Success";
export const GET_COMPANY_SMART_DEVICES_SINGLE_FAIL = "[COMPANY_SMART_DEVICES] Single Get Fail";

export const CREATE_COMPANY_SMART_DEVICES = "[COMPANY_SMART_DEVICES] Create";
export const CREATE_COMPANY_SMART_DEVICES_SUCCESS = "[COMPANY_SMART_DEVICES] Create Success";
export const CREATE_COMPANY_SMART_DEVICES_FAIL = "[COMPANY_SMART_DEVICES] Create Fail";
export const CREATE_COMPANY_SMART_DEVICES_RESET = "[COMPANY_SMART_DEVICES] Create Reset";

export const UPDATE_COMPANY_SMART_DEVICES = "[COMPANY_SMART_DEVICES] Update";
export const UPDATE_COMPANY_SMART_DEVICES_SUCCESS = "[COMPANY_SMART_DEVICES] Update Success";
export const UPDATE_COMPANY_SMART_DEVICES_FAIL = "[COMPANY_SMART_DEVICES] Update Fail";
export const UPDATE_COMPANY_SMART_DEVICES_RESET = "[COMPANY_SMART_DEVICES] Update Reset";

export const REMOVE_COMPANY_SMART_DEVICES = "[COMPANY_SMART_DEVICES] Remove";
export const REMOVE_COMPANY_SMART_DEVICES_SUCCESS = "[COMPANY_SMART_DEVICES] Remove Success";
export const REMOVE_COMPANY_SMART_DEVICES_FAIL = "[COMPANY_SMART_DEVICES] Remove Fail";

export const LOCK_COMPANY_SMART_DEVICES = "[COMPANY_SMART_DEVICES] Lock";
export const LOCK_COMPANY_SMART_DEVICES_SUCCESS = "[COMPANY_SMART_DEVICES] Lock Success";
export const LOCK_COMPANY_SMART_DEVICES_FAIL = "[COMPANY_SMART_DEVICES] Lock Fail";
export const LOCK_COMPANY_SMART_DEVICES_RESET = "[COMPANY_SMART_DEVICES] Lock Reset";

export const UNLOCK_COMPANY_SMART_DEVICES = "[COMPANY_SMART_DEVICES] Unlock";
export const UNLOCK_COMPANY_SMART_DEVICES_SUCCESS = "[COMPANY_SMART_DEVICES] Unlock Success";
export const UNLOCK_COMPANY_SMART_DEVICES_FAIL = "[COMPANY_SMART_DEVICES] Unlock Fail";
export const UNLOCK_COMPANY_SMART_DEVICES_RESET = "[COMPANY_SMART_DEVICES] Unlock Reset";

export const TOGGLE_COMPANY_SMART_DEVICES = "[COMPANY_SMART_DEVICES] Toggle";
export const TOGGLE_COMPANY_SMART_DEVICES_SUCCESS = "[COMPANY_SMART_DEVICES] Toggle Success";
export const TOGGLE_COMPANY_SMART_DEVICES_FAIL = "[COMPANY_SMART_DEVICES] Toggle Fail";
export const TOGGLE_COMPANY_SMART_DEVICES_RESET = "[COMPANY_SMART_DEVICES] Toggle Reset";
