import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_LOCATIONS_COLLECTION,
  GET_LOCATIONS_COLLECTION_SUCCESS,
  GET_LOCATIONS_COLLECTION_FAIL,
  GET_ITALY_CITIES,
  GET_ITALY_CITIES_SUCCESS,
  GET_ITALY_CITIES_FAIL,
} from "./actions";

const crudProcessed = crud.process({
  getCollectionRequest: {
    action: GET_LOCATIONS_COLLECTION,
    endPoint: "locations/collected",
    mutationFail: GET_LOCATIONS_COLLECTION_FAIL,
    mutationSuccess: GET_LOCATIONS_COLLECTION_SUCCESS
  },
});

const actionsProcessed = action.processMultiple([
  {
    name: 'getItalyCitiesRequest',
    action: GET_ITALY_CITIES,
    endPoint: 'locations/collected',
    params: {
      country: 'IT',
      division_level_3_type: 'comune',
      _data: {
        selects: [
          'division_level_3_code',
          'division_level_3_name',
        ]
      }
    },
    mutations: {
      fail: GET_ITALY_CITIES_FAIL,
      success: GET_ITALY_CITIES_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state
};

const actions = {
  ...crudProcessed.actions,
  ...actionsProcessed.actions
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations
};

export default {
  state,
  actions,
  mutations,
  getters
};
