<template>
  <span style="width: 400px">
    <v-autocomplete
      v-debounce:700="getReservations"
      v-model="id"
      item-value="id"
      item-text="client.name"
      prepend-icon="mdi-magnify"
      :label="label ? label : $lang('main.navbar.search_reservation')"
      :items="reservations"
      :filter="item => item"
      :loading="loading"
      :disabled="disabled"
      :return-object="returnObject"
    >
      <template v-slot:selection="{item}">
        {{ item.client.name }}
      </template>
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title>
            <span class="text-h6">{{item.client.name}}</span>
            - {{ $lang('reservations.fields.res_id') }}: {{item.res_id}}
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-icon small>mdi-office-building</v-icon> {{ item.property.name }}
            <v-icon small>mdi-bed</v-icon> {{ item.unit.name }}
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            {{ $toDateFormat(item.start_date) }}
            <v-icon small>mdi-arrow-right</v-icon>
            {{ $toDateFormat(item.end_date) }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $lang('reservations.hints.search') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </span>
</template>

<script>
import { mapState } from "vuex";
import { SEARCH_RESERVATIONS } from "@/store/reservations/actions";


export default {
	name: "CReservationSearch",
  props: {
    value: {
      required: false
    },
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    }
  },
	data() {
		return {
			search: null,
      id: null
		};
	},
  mounted() {
    this.id = this.value;
    this.getReservations();
  },
	methods: {
		getReservations(search) {
      if((!search || search.length <= 3) && !this.value) {
        return
      }

      let id = search ? null : this.value;

			this.$store.dispatch(SEARCH_RESERVATIONS, {
        id,
        search: search,
        from: this.$moment().format('YYYY-MM-DD'),
        _data: {
          selects: [
            "id",
            "start_date",
            "end_date",
            "res_id",
            "client_id",
            "property_id",
            "unit_id",
          ],
          relations: {
            client: {
              selects: ["id", "name"]
            },
            property: {
              selects: ["id", "name"]
            },
            unit: {
              selects: ["id", "name"]
            },
          }
        }
      });
    },
	},
	computed: {
    ...mapState({
			request: state => state.reservations.getSearchRequest
		}),
    reservations() {
      if (this.request.loading) {
        return [];
      }
      return this.request.collection;
    },
    loading() {
      return this.request.loading;
    },
	},
  watch: {
    id(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.id = newVal;
    },
  }
};
</script>
