export const GET_PAYMENTS_PAGINATION = "[PAYMENTS] Pagination Get";
export const GET_PAYMENTS_PAGINATION_SUCCESS = "[PAYMENTS] Pagination Get Success";
export const GET_PAYMENTS_PAGINATION_FAIL = "[PAYMENTS] Pagination Get Fail";

export const GET_PAYMENTS_COLLECTION = "[PAYMENTS] Collection Get";
export const GET_PAYMENTS_COLLECTION_SUCCESS = "[PAYMENTS] Collection Get Success";
export const GET_PAYMENTS_COLLECTION_FAIL = "[PAYMENTS] Collection Get Fail";

export const GET_PAYMENTS_SINGLE = "[PAYMENTS] Single Get";
export const GET_PAYMENTS_SINGLE_SUCCESS = "[PAYMENTS] Single Get Success";
export const GET_PAYMENTS_SINGLE_FAIL = "[PAYMENTS] Single Get Fail";

export const CREATE_PAYMENTS = "[PAYMENTS] Create";
export const CREATE_PAYMENTS_SUCCESS = "[PAYMENTS] Create Success";
export const CREATE_PAYMENTS_FAIL = "[PAYMENTS] Create Fail";
export const CREATE_PAYMENTS_RESET = "[PAYMENTS] Create Reset";

export const UPDATE_PAYMENTS = "[PAYMENTS] Update";
export const UPDATE_PAYMENTS_SUCCESS = "[PAYMENTS] Update Success";
export const UPDATE_PAYMENTS_FAIL = "[PAYMENTS] Update Fail";
export const UPDATE_PAYMENTS_RESET = "[PAYMENTS] Update Reset";

export const REMOVE_PAYMENTS = "[PAYMENTS] Remove";
export const REMOVE_PAYMENTS_SUCCESS = "[PAYMENTS] Remove Success";
export const REMOVE_PAYMENTS_FAIL = "[PAYMENTS] Remove Fail";

export const GET_PAYMENTS_CASHFLOW = "[PAYMENTS] Cashflow Get";
export const GET_PAYMENTS_CASHFLOW_SUCCESS = "[PAYMENTS] Cashflow Get Success";
export const GET_PAYMENTS_CASHFLOW_FAIL = "[PAYMENTS] Cashflow Get Fail";
export const GET_PAYMENTS_CASHFLOW_RESET = "[PAYMENTS] Cashflow Reset";

export const CONFIRM_PREAUTHORIZED_PAYMENT = "[PAYMENTS] Preauthorize Confirm";
export const CONFIRM_PREAUTHORIZED_PAYMENT_SUCCESS = "[PAYMENTS] Preauthorize Confirm Success";
export const CONFIRM_PREAUTHORIZED_PAYMENT_FAIL = "[PAYMENTS] Preauthorize Confirm Fail";

export const GET_PAYMENTS_STRIPE_TERMINAL_DATA = "[PAYMENTS] Stripe Terminal Data Get";
export const GET_PAYMENTS_STRIPE_TERMINAL_DATA_SUCCESS = "[PAYMENTS] Stripe Terminal Data Get Success";
export const GET_PAYMENTS_STRIPE_TERMINAL_DATA_FAIL = "[PAYMENTS] Stripe Terminal Data Get Fail";

export const CREATE_PAYMENTS_STRIPE_PAYMENT_INTENT = "[PAYMENTS] Stripe Payment Intent Create";
export const CREATE_PAYMENTS_STRIPE_PAYMENT_INTENT_SUCCESS = "[PAYMENTS] Stripe Payment Intent Create Success";
export const CREATE_PAYMENTS_STRIPE_PAYMENT_INTENT_FAIL = "[PAYMENTS] Stripe Payment Intent Create Fail";
export const CREATE_PAYMENTS_STRIPE_PAYMENT_INTENT_RESET = "[PAYMENTS] Stripe Payment Intent Create Reset";

export const CAPTURE_PAYMENTS_STRIPE_PAYMENT_INTENT = "[PAYMENTS] Stripe Payment Intent Capture";
export const CAPTURE_PAYMENTS_STRIPE_PAYMENT_INTENT_SUCCESS = "[PAYMENTS] Stripe Payment Intent Capture Success";
export const CAPTURE_PAYMENTS_STRIPE_PAYMENT_INTENT_FAIL = "[PAYMENTS] Stripe Payment Intent Capture Fail";
export const CAPTURE_PAYMENTS_STRIPE_PAYMENT_INTENT_RESET = "[PAYMENTS] Stripe Payment Intent Capture Reset";
