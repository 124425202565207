export const GET_RATE_CALENDAR_DETAILED_DATA = "[RATE_CALENDAR] Detailed Data Get";
export const GET_RATE_CALENDAR_DETAILED_DATA_SUCCESS = "[RATE_CALENDAR] Detailed Data Get Success";
export const GET_RATE_CALENDAR_DETAILED_DATA_FAIL = "[RATE_CALENDAR] Detailed Data Get Fail";

export const GET_RATE_CALENDAR_COMPRESSED_DATA = "[RATE_CALENDAR] Compressed Data Get";
export const GET_RATE_CALENDAR_COMPRESSED_DATA_SUCCESS = "[RATE_CALENDAR] Compressed Data Get Success";
export const GET_RATE_CALENDAR_COMPRESSED_DATA_FAIL = "[RATE_CALENDAR] Compressed Data Get Fail";

export const GET_RATE_CALENDAR_CHILDREN_DATA = "[RATE_CALENDAR] Children Data Get";
export const GET_RATE_CALENDAR_CHILDREN_DATA_LOADING = "[RATE_CALENDAR] Children Data Get Loading";
export const GET_RATE_CALENDAR_CHILDREN_DATA_SUCCESS = "[RATE_CALENDAR] Children Data Get Success";
export const GET_RATE_CALENDAR_CHILDREN_DATA_FAIL = "[RATE_CALENDAR] Children Data Get Fail";

export const CLEAR_RATE_CALENDAR_CHILDREN_DATA = "[RATE_CALENDAR] Children Data Clear";
export const CLEAR_RATE_CALENDAR_CHILDREN_DATA_SUCCESS = "[RATE_CALENDAR] Children Data Clear Success";

export const GET_RATE_CALENDAR_COLLECTION = "[RATE_CALENDAR] Collection Get";
export const GET_RATE_CALENDAR_COLLECTION_SUCCESS = "[RATE_CALENDAR] Collection Get Success";
export const GET_RATE_CALENDAR_COLLECTION_FAIL = "[RATE_CALENDAR] Collection Get Fail";

export const GET_RATE_CALENDAR_SINGLE = "[RATE_CALENDAR] Single Get";
export const GET_RATE_CALENDAR_SINGLE_SUCCESS = "[RATE_CALENDAR] Single Get Success";
export const GET_RATE_CALENDAR_SINGLE_FAIL = "[RATE_CALENDAR] Single Get Fail";

export const CREATE_RATE = "[RATE_CALENDAR] Create";
export const CREATE_RATE_SUCCESS = "[RATE_CALENDAR] Create Success";
export const CREATE_RATE_FAIL = "[RATE_CALENDAR] Create Fail";
export const CREATE_RATE_RESET = "[RATE_CALENDAR] Create Reset";

export const CREATE_RATE_BULK = "[RATE_CALENDAR] Create Bulk";
export const CREATE_RATE_BULK_SUCCESS = "[RATE_CALENDAR] Create Bulk Success";
export const CREATE_RATE_BULK_FAIL = "[RATE_CALENDAR] Create Bulk Fail";
export const CREATE_RATE_BULK_RESET = "[RATE_CALENDAR] Create Bulk Reset";