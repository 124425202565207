export default [
  {
    name: "planner",
    path: "/planner",
    component: () => import(/*webpackChunkName: "planner" */ "@/pages/planner/Index"),
    meta: {
      title: 'titles.planner',
      loginRedirect: true
    }
  }
];
