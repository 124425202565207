export default [
  {
    name: "rmt",
    path: "/rmt",
    component: () => import(/*webpackChunkName: "rmt" */ "@/pages/rmt/RMT"),
    meta: {
      title: 'titles.rmt'
    }
  },
  {
    name: "clusters",
    path: "/clusters",
    component: () => import(/*webpackChunkName: "rmt" */ "@/pages/clusters/Index"),
    meta: {
      title: 'titles.clusters'
    }
  }
];
