import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";
import ApiService from "@/services/api";

import {
  GET_GUESTS_LIST,
  GET_GUESTS_LIST_SUCCESS,
  GET_GUESTS_LIST_FAIL,
  GET_GUESTS_PAGINATION,
  GET_GUESTS_PAGINATION_SUCCESS,
  GET_GUESTS_PAGINATION_FAIL,
  GET_GUESTS_COLLECTION,
  GET_GUESTS_COLLECTION_SUCCESS,
  GET_GUESTS_COLLECTION_FAIL,
  GET_GUESTS_SINGLE,
  GET_GUESTS_SINGLE_SUCCESS,
  GET_GUESTS_SINGLE_FAIL,
  CREATE_GUESTS,
  CREATE_GUESTS_SUCCESS,
  CREATE_GUESTS_FAIL,
  CREATE_GUESTS_RESET,
  UPDATE_GUESTS,
  UPDATE_GUESTS_SUCCESS,
  UPDATE_GUESTS_FAIL,
  UPDATE_GUESTS_RESET,
  REPLACE_GUESTS,
  REPLACE_GUESTS_SUCCESS,
  REPLACE_GUESTS_FAIL,
  REPLACE_GUESTS_RESET,
  REMOVE_GUESTS,
  REMOVE_GUESTS_SUCCESS,
  REMOVE_GUESTS_FAIL,
  GET_GUESTS_GROUP,
  GET_GUESTS_GROUP_SUCCESS,
  GET_GUESTS_GROUP_FAIL,
  GET_GUESTS_FAMILY,
  GET_GUESTS_FAMILY_SUCCESS,
  GET_GUESTS_FAMILY_FAIL,
  EXPORT_PDF_GUESTS,
  GET_PRIVACY_CONTENT_GUESTS,
  GET_PRIVACY_CONTENT_GUESTS_SUCCESS,
  GET_PRIVACY_CONTENT_GUESTS_FAIL,
  SAVE_PRIVACY_GUESTS,
  SAVE_PRIVACY_GUESTS_SUCCESS,
  SAVE_PRIVACY_GUESTS_FAIL,
  DETACH_GUEST,
  DETACH_GUEST_SUCCESS,
  DETACH_GUEST_FAIL
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_GUESTS_LIST,
    endPoint: "guests/list",
    mutationFail: GET_GUESTS_LIST_FAIL,
    mutationSuccess: GET_GUESTS_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_GUESTS_PAGINATION,
    endPoint: "guests/paginated",
    mutationFail: GET_GUESTS_PAGINATION_FAIL,
    mutationSuccess: GET_GUESTS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_GUESTS_COLLECTION,
    endPoint: "guests/collected",
    mutationFail: GET_GUESTS_COLLECTION_FAIL,
    mutationSuccess: GET_GUESTS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_GUESTS_SINGLE,
    endPoint: "guests/single",
    mutationFail: GET_GUESTS_SINGLE_FAIL,
    mutationSuccess: GET_GUESTS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_GUESTS,
    actionReset: CREATE_GUESTS_RESET,
    endPoint: "guests/create",
    mutationFail: CREATE_GUESTS_FAIL,
    mutationSuccess: CREATE_GUESTS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_GUESTS,
    actionReset: UPDATE_GUESTS_RESET,
    endPoint: "guests/update",
    mutationFail: UPDATE_GUESTS_FAIL,
    mutationSuccess: UPDATE_GUESTS_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_GUESTS,
    actionReset: REPLACE_GUESTS_RESET,
    endPoint: "guests/replace",
    mutationFail: REPLACE_GUESTS_FAIL,
    mutationSuccess: REPLACE_GUESTS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_GUESTS,
    endPoint: "guests/delete",
    mutationFail: REMOVE_GUESTS_FAIL,
    mutationSuccess: REMOVE_GUESTS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    name: 'getGroupCollectionRequest',
    action: GET_GUESTS_GROUP,
    endPoint: 'guests/collected',
    params: {
      group: true
    },
    mutations: {
      fail: GET_GUESTS_GROUP_FAIL,
      success: GET_GUESTS_GROUP_SUCCESS,
    },
  },
  {
    name: 'getFamilyCollectionRequest',
    action: GET_GUESTS_FAMILY,
    endPoint: 'guests/collected',
    params: {
      family: true
    },
    mutations: {
      fail: GET_GUESTS_FAMILY_FAIL,
      success: GET_GUESTS_FAMILY_SUCCESS,
    },
  },
  {
    name: 'getPrivacyContentRequest',
    action: GET_PRIVACY_CONTENT_GUESTS,
    endPoint: 'guests/privacy-content/{id}',
    mutations: {
      fail: GET_PRIVACY_CONTENT_GUESTS_FAIL,
      success: GET_PRIVACY_CONTENT_GUESTS_SUCCESS,
    },
  },
  {
    method: 'postUpload',
    name: 'savePrivacyRequest',
    action: SAVE_PRIVACY_GUESTS,
    endPoint: 'guests/privacy/{id}',
    mutations: {
      fail: SAVE_PRIVACY_GUESTS_FAIL,
      success: SAVE_PRIVACY_GUESTS_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'detachRequest',
    action: DETACH_GUEST,
    endPoint: 'guests/detach',
    mutations: {
      fail: DETACH_GUEST_FAIL,
      success: DETACH_GUEST_SUCCESS,
    },
  },
]);

const actions = {
  [EXPORT_PDF_GUESTS](context, id) {
    ApiService.download(`guests/export-pdf/${id}`, true);
  },
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
