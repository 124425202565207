import Vue from "vue";

const ConfigService = {
  /**
   *
   */
  init() {
    Vue.config.productionTip = false;
  }
};

export default ConfigService;
