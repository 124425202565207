export default [
  {
    name: "properties",
    path: "/properties",
    component: () => import(/*webpackChunkName: "properties" */ "@/pages/properties/Index"),
    meta: {
      breadcrumbs: [
      ],
      title: 'titles.properties'
    }
  },
  {
    name: "property-settings",
    path: "/properties/:id",
    component: () => import(/*webpackChunkName: "properties" */ "@/pages/properties/EditingBlocks"),
    meta: {
      breadcrumbs: [
        { text: 'titles.properties', to: 'properties', params: true, disabled: false },
      ],
      title: 'titles.property_settings'
    }
  },
  {
    name: "property-units-rates",
    path: "/properties/:id/units-rates",
    component: () => import(/*webpackChunkName: "properties" */ "@/pages/properties/settings/UnitRatesBlocks"),
    props: true,
    meta: {
      breadcrumbs: [
        { text: 'titles.properties', to: 'properties', params: true },
        { text: 'titles.property_settings', to: 'property-settings' },
      ],
      title: 'titles.property_units_rates'

    }
  },
  {
    name: "property-bureaucracy",
    path: "/properties/:id/bureaucracy",
    component: () => import(/*webpackChunkName: "properties" */ "@/pages/properties/BureaucracyBlocks"),
    props: true,
    meta: {
      breadcrumbs: [
        { text: 'titles.properties', to: 'properties', params: true },
        { text: 'titles.property_settings', to: 'property-settings' },
      ],
      title: 'titles.property_bureaucracy'

    }
  },
  {
    name: "property-cost-settings",
    path: "/properties/:id/costs-settings",
    component: () => import(/*webpackChunkName: "properties" */ "@/pages/properties/settings/CostsBlocks"),
    props: true,
    meta: {
      breadcrumbs: [
        { text: 'titles.properties', to: 'properties', params: true },
        { text: 'titles.property_settings', to: 'property-settings' },
      ],
      title: 'titles.property_costs'
    }
  },
  {
    name: "property-room-types",
    path: "/properties/:id/room-types",
    component: () => import(/*webpackChunkName: "properties" */ "@/pages/properties/blocks/RoomTypes"),
    meta: {
      breadcrumbs: [
        { text: 'titles.properties', to: 'properties', params: true },
        { text: 'titles.property_settings', to: 'property-settings' },
        { text: 'titles.property_units_rates', to: 'property-units-rates' },
      ],
      title: 'titles.property_room_types'
    }
  },
  {
    name: "room-type-google-sheets",
    path: "/room-type-google-sheets",
    component: () => import(/*webpackChunkName: "properties" */ "@/pages/room-type-google-sheets/Index"),
    meta: {
      title: 'titles.room_type_google_sheet',
      discontinued: true,
    }
  },
  {
    name: "property-costs",
    path: "/properties/:id/costs",
    component: () => import(/*webpackChunkName: "properties" */ "@/pages/property-costs/Index"),
    meta: {
      breadcrumbs: [
        { text: 'titles.properties', to: 'properties', params: true },
        { text: 'titles.property_settings', to: 'property-settings' },
        { text: 'titles.property_cost_settings', to: 'property-cost-settings' },
      ],
      title: 'titles.property_costs'
    }
  },
  {
    name: "property-extras",
    path: "/properties/:id/property-extras",
    component: () => import(/*webpackChunkName: "properties" */ "@/pages/property-extras/Index"),
    meta: {
      breadcrumbs: [
        { text: 'titles.properties', to: 'properties', params: true },
        { text: 'titles.property_settings', to: 'property-settings' },
      ],
      title: 'titles.property_extras'
    }
  },
  {
    name: "property-promotions",
    path: "/properties/:id/promotions",
    component: () => import(/*webpackChunkName: "properties" */ "@/pages/property-promotions/Index"),
    meta: {
      breadcrumbs: [
        { text: 'titles.properties', to: 'properties', params: true },
        { text: 'titles.property_settings', to: 'property-settings' },
      ],
      title: 'titles.property_promotions'
    }
  },
  {
    name: "property-revenue-share",
    path: "/properties/:id/revenue-shares",
    component: () => import(/*webpackChunkName: "properties" */ "@/pages/property-revenue-shares/Index"),
    meta: {
      breadcrumbs: [
        { text: 'titles.properties', to: 'properties', params: true },
        { text: 'titles.property_settings', to: 'property-settings' },
      ],
      title: 'titles.property_revenue_share'
    }
  },
  {
    name: "property-message-templates",
    path: "/properties/:id/message-templates",
    component: () => import(/*webpackChunkName: "properties" */ "@/pages/property-message-templates/Index"),
    meta: {
      breadcrumbs: [
        { text: 'titles.properties', to: 'properties', params: true },
        { text: 'titles.property_settings', to: 'property-settings' },
      ],
      title: 'titles.property_message_templates'
    }
  },
  {
    name: "unit-categories",
    path: "/properties/:id/unit-categories",
    component: () => import(/*webpackChunkName: "properties" */ "@/pages/unit-categories/Index"),
    meta: {
      breadcrumbs: [
        { text: 'titles.properties', to: 'properties', params: true },
        { text: 'titles.property_settings', to: 'property-settings' },
        { text: 'titles.property_units_rates', to: 'property-units-rates' },
      ],
      title: 'titles.unit_categories'
    }
  },
  {
    name: "rate-plans",
    path: "/properties/:id/rate-plans",
    component: () => import(/*webpackChunkName: "properties" */ "@/pages/rate-plans/Index"),
    meta: {
      breadcrumbs: [
        { text: 'titles.properties', to: 'properties', params: true },
        { text: 'titles.property_settings', to: 'property-settings' },
        { text: 'titles.property_units_rates', to: 'property-units-rates' },
      ],
      title: 'titles.rate_plans'
    }
  },
  {
    name: "housekeeping-settings",
    path: "/properties/:id/housekeeping-settings",
    component: () => import(/*webpackChunkName: "properties" */ "@/pages/housekeeping-settings/Index"),
    meta: {
      breadcrumbs: [
        { text: 'titles.properties', to: 'properties', params: true },
        { text: 'titles.property_settings', to: 'property-settings' },
      ],
      title: 'titles.housekeeping_settings'
    }
  },
  {
    name: "property-contracts",
    path: "/properties/:id/property-contracts",
    component: () => import(/*webpackChunkName: "properties" */ "@/pages/property-contracts/Index"),
    meta: {
      breadcrumbs: [
        { text: 'titles.properties', to: 'properties', params: true },
        { text: 'titles.property_settings', to: 'property-settings' },
      ],
      title: 'titles.property_contracts'
    }
  }
];
