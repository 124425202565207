export default [
  {
    name: "nuki-authorization",
    path: "/nuki-authorization",
    component: () => import(/*webpackChunkName: "nuki-authorization" */ "@/pages/company-smart-device-accounts/NukiAuthorization"),
    meta: {
      layout: 'empty'
    }
  },
];
