export const GET_TICKET_MESSAGES_LIST = "[TICKET_MESSAGES] List Get";
export const GET_TICKET_MESSAGES_LIST_SUCCESS = "[TICKET_MESSAGES] List Get Success";
export const GET_TICKET_MESSAGES_LIST_FAIL = "[TICKET_MESSAGES] List Get Fail";

export const GET_TICKET_MESSAGES_PAGINATION = "[TICKET_MESSAGES] Pagination Get";
export const GET_TICKET_MESSAGES_PAGINATION_SUCCESS = "[TICKET_MESSAGES] Pagination Get Success";
export const GET_TICKET_MESSAGES_PAGINATION_FAIL = "[TICKET_MESSAGES] Pagination Get Fail";

export const GET_TICKET_MESSAGES_COLLECTION = "[TICKET_MESSAGES] Collection Get";
export const GET_TICKET_MESSAGES_COLLECTION_SUCCESS = "[TICKET_MESSAGES] Collection Get Success";
export const GET_TICKET_MESSAGES_COLLECTION_FAIL = "[TICKET_MESSAGES] Collection Get Fail";

export const GET_TICKET_MESSAGES_SINGLE = "[TICKET_MESSAGES] Single Get";
export const GET_TICKET_MESSAGES_SINGLE_SUCCESS = "[TICKET_MESSAGES] Single Get Success";
export const GET_TICKET_MESSAGES_SINGLE_FAIL = "[TICKET_MESSAGES] Single Get Fail";

export const CREATE_TICKET_MESSAGES = "[TICKET_MESSAGES] Create";
export const CREATE_TICKET_MESSAGES_SUCCESS = "[TICKET_MESSAGES] Create Success";
export const CREATE_TICKET_MESSAGES_FAIL = "[TICKET_MESSAGES] Create Fail";
export const CREATE_TICKET_MESSAGES_RESET = "[TICKET_MESSAGES] Create Reset";

export const UPDATE_TICKET_MESSAGES = "[TICKET_MESSAGES] Update";
export const UPDATE_TICKET_MESSAGES_SUCCESS = "[TICKET_MESSAGES] Update Success";
export const UPDATE_TICKET_MESSAGES_FAIL = "[TICKET_MESSAGES] Update Fail";
export const UPDATE_TICKET_MESSAGES_RESET = "[TICKET_MESSAGES] Update Reset";

export const REMOVE_TICKET_MESSAGES = "[TICKET_MESSAGES] Remove";
export const REMOVE_TICKET_MESSAGES_SUCCESS = "[TICKET_MESSAGES] Remove Success";
export const REMOVE_TICKET_MESSAGES_FAIL = "[TICKET_MESSAGES] Remove Fail";

export const READ_TICKET_MESSAGES = "[TICKET_MESSAGES] Read Messages";
export const READ_TICKET_MESSAGES_SUCCESS = "[TICKET_MESSAGES] Read Messages Success";
export const READ_TICKET_MESSAGES_FAIL = "[TICKET_MESSAGES] Read Messages Fail";