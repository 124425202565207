import ApiService from "@/services/api";

import list from "@/store/core/list";
import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_ROOM_TYPE_GOOGLE_SHEET_LIST,
  GET_ROOM_TYPE_GOOGLE_SHEET_LIST_SUCCESS,
  GET_ROOM_TYPE_GOOGLE_SHEET_LIST_FAIL,
  GET_ROOM_TYPE_GOOGLE_SHEET_PAGINATION,
  GET_ROOM_TYPE_GOOGLE_SHEET_PAGINATION_SUCCESS,
  GET_ROOM_TYPE_GOOGLE_SHEET_PAGINATION_FAIL,
  GET_ROOM_TYPE_GOOGLE_SHEET_COLLECTION,
  GET_ROOM_TYPE_GOOGLE_SHEET_COLLECTION_SUCCESS,
  GET_ROOM_TYPE_GOOGLE_SHEET_COLLECTION_FAIL,
  GET_ROOM_TYPE_GOOGLE_SHEET_SINGLE,
  GET_ROOM_TYPE_GOOGLE_SHEET_SINGLE_SUCCESS,
  GET_ROOM_TYPE_GOOGLE_SHEET_SINGLE_FAIL,
  CREATE_ROOM_TYPE_GOOGLE_SHEET,
  CREATE_ROOM_TYPE_GOOGLE_SHEET_SUCCESS,
  CREATE_ROOM_TYPE_GOOGLE_SHEET_FAIL,
  CREATE_ROOM_TYPE_GOOGLE_SHEET_RESET,
  UPDATE_ROOM_TYPE_GOOGLE_SHEET,
  UPDATE_ROOM_TYPE_GOOGLE_SHEET_SUCCESS,
  UPDATE_ROOM_TYPE_GOOGLE_SHEET_FAIL,
  UPDATE_ROOM_TYPE_GOOGLE_SHEET_RESET,
  REMOVE_ROOM_TYPE_GOOGLE_SHEET,
  REMOVE_ROOM_TYPE_GOOGLE_SHEET_SUCCESS,
  REMOVE_ROOM_TYPE_GOOGLE_SHEET_FAIL,
  GET_ROOM_TYPE_GOOGLE_SHEET_HEADER,
  GET_ROOM_TYPE_GOOGLE_SHEET_HEADER_SUCCESS,
  GET_ROOM_TYPE_GOOGLE_SHEET_HEADER_FAIL,
  SYNC_ROOM_TYPE_GOOGLE_SHEET,
  SYNC_ROOM_TYPE_GOOGLE_SHEET_SUCCESS,
  SYNC_ROOM_TYPE_GOOGLE_SHEET_FAIL,
  SYNC_PROPERTY_GOOGLE_SHEET,
  SYNC_PROPERTY_GOOGLE_SHEET_FAIL,
  SYNC_PROPERTY_GOOGLE_SHEET_SUCCESS,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_ROOM_TYPE_GOOGLE_SHEET_LIST,
    endPoint: "room-type/google-sheet/list",
    mutationFail: GET_ROOM_TYPE_GOOGLE_SHEET_LIST_FAIL,
    mutationSuccess: GET_ROOM_TYPE_GOOGLE_SHEET_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_ROOM_TYPE_GOOGLE_SHEET_PAGINATION,
    endPoint: "room-type/google-sheet/paginated",
    mutationFail: GET_ROOM_TYPE_GOOGLE_SHEET_PAGINATION_FAIL,
    mutationSuccess: GET_ROOM_TYPE_GOOGLE_SHEET_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_ROOM_TYPE_GOOGLE_SHEET_COLLECTION,
    endPoint: "room-type/google-sheet/collected",
    mutationFail: GET_ROOM_TYPE_GOOGLE_SHEET_COLLECTION_FAIL,
    mutationSuccess: GET_ROOM_TYPE_GOOGLE_SHEET_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_ROOM_TYPE_GOOGLE_SHEET_SINGLE,
    endPoint: "room-type/google-sheet/single",
    mutationFail: GET_ROOM_TYPE_GOOGLE_SHEET_SINGLE_FAIL,
    mutationSuccess: GET_ROOM_TYPE_GOOGLE_SHEET_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_ROOM_TYPE_GOOGLE_SHEET,
    actionReset: CREATE_ROOM_TYPE_GOOGLE_SHEET_RESET,
    endPoint: "room-type/google-sheet/create",
    mutationFail: CREATE_ROOM_TYPE_GOOGLE_SHEET_FAIL,
    mutationSuccess: CREATE_ROOM_TYPE_GOOGLE_SHEET_SUCCESS
  },
  editingRequest: {
    action: UPDATE_ROOM_TYPE_GOOGLE_SHEET,
    actionReset: UPDATE_ROOM_TYPE_GOOGLE_SHEET_RESET,
    endPoint: "room-type/google-sheet/update",
    mutationFail: UPDATE_ROOM_TYPE_GOOGLE_SHEET_FAIL,
    mutationSuccess: UPDATE_ROOM_TYPE_GOOGLE_SHEET_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_ROOM_TYPE_GOOGLE_SHEET,
    endPoint: "room-type/google-sheet/delete",
    mutationFail: REMOVE_ROOM_TYPE_GOOGLE_SHEET_FAIL,
    mutationSuccess: REMOVE_ROOM_TYPE_GOOGLE_SHEET_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    name: 'getHeaderRequest',
    action: GET_ROOM_TYPE_GOOGLE_SHEET_HEADER,
    endPoint: 'room-type/google-sheet/get-header',
    mutations: {
      fail: GET_ROOM_TYPE_GOOGLE_SHEET_HEADER_FAIL,
      success: GET_ROOM_TYPE_GOOGLE_SHEET_HEADER_SUCCESS,
    },
  },
  {
    name: 'getSyncRequest',
    action: SYNC_ROOM_TYPE_GOOGLE_SHEET,
    endPoint: 'room-type/google-sheet/sync/{id}',
    mutations: {
      fail: SYNC_ROOM_TYPE_GOOGLE_SHEET_FAIL,
      success: SYNC_ROOM_TYPE_GOOGLE_SHEET_SUCCESS,
    },
  },
  {
    name: 'getPropertySyncRequest',
    action: SYNC_PROPERTY_GOOGLE_SHEET,
    endPoint: 'room-type/google-sheet/property-sync/{id}',
    mutations: {
      fail: SYNC_PROPERTY_GOOGLE_SHEET_FAIL,
      success: SYNC_PROPERTY_GOOGLE_SHEET_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
