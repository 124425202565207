export default [
  {
    name: "Assets",
    path: "/cmms/assets",
    component: () => import(/*webpackChunkName: "cmms" */ "@/pages/assets/Index"),
    meta: {
      title: 'titles.assets'
    }
  },
  {
    name: "work-orders",
    path: "/cmms/work-orders",
    component: () => import(/*webpackChunkName: "cmms" */ "@/pages/work-orders/Index"),
    meta: {
      title: 'titles.work_orders',
      loginRedirect: true
    }
  },
  {
    name: "workers",
    path: "/cmms/workers",
    component: () => import(/*webpackChunkName: "cmms" */ "@/pages/workers/Index"),
    meta: {
      title: 'titles.workers'
    }
  }
];
