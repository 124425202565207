<template>
  <span>
    <v-autocomplete
      v-model="ids"
      item-value="id"
      item-text="name"
      :items="items"
      :label="label"
      :multiple="multiple"
      :small-chips="smallChips"
      :deletable-chips="deletableChips"
      :hint="hint"
      :persistent-hint="persistentHint"
      :loading="loading"
      :prepend-icon="prependIcon"
      append-outer-icon="mdi-refresh"
      :clearable="clearable"
      :disabled="disabled"
      :readonly="readonly"
      :dark="dark"
      @click:append-outer="getItems()"
      :dense="dense"
      :error="error"
      :error-messages="!!errorMessages ? errorMessages : []"
    >
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title>
            <v-chip
              :color="item.color"
              class="white--text"
              label
              small
            >
              <v-icon color="white" class="mr-1" x-small>mdi-shape</v-icon>
              {{ item.name }}
            </v-chip>
          </v-list-item-title>
          <v-list-item-subtitle>
            <span v-if="withProperty">
              <v-icon x-small>mdi-office-building</v-icon>
              {{ item.property.name }}<br />
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <template v-if="chips" v-slot:selection="{item}">
        <v-chip :color="item.color" class="white--text" small label>
          <v-icon small class="mr-1" color="white">mdi-shape</v-icon>
          {{ item.name }}
        </v-chip>
      </template>
    </v-autocomplete>
  </span>
</template>

<script>
import { mapState } from "vuex";
import { GET_UNIT_CATEGORIES_COLLECTION } from "@/store/unit-categories/actions";

export default {
  name: "CUnitCategoriesAutocomplete",
  props: {
    label: {
      type: String,
      required: false,
      default: "Unit Categories"
    },
    hint: {
      type: String,
      default: ""
    },
    persistentHint: {
      type: Boolean,
      default: false
    },
    value: {
      default: null
    },
    error: {
      default: false
    },
    errorMessages: {
      default: () => []
    },
    dense: {
      type: Boolean,
      default: false
    },
    prependIcon: {
      type: String,
      default: "mdi-shape"
    },
    clearable: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    smallChips: {
      type: Boolean,
      default: false
    },
    chips: {
      type: Boolean,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: () => {
        return {};
      }
    },
    options: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      ids: null
    };
  },
  beforeMount() {
    this.getItems();
  },
  mounted() {
    this.ids = this.value;
  },
  methods: {
    getItems() {
      if (
        this.disabled ||
        this.unitCategoriesRequest.loading ||
        this.$cannot('VIEW_UNIT_CATEGORIES')
      ) {
        return;
      }
      this.$store.dispatch(GET_UNIT_CATEGORIES_COLLECTION, {
        sortBy: "name",
        order: "asc",
        _data: {
          selects: [
            "id",
            "property_id",
            "name",
            "color",
          ],
          relations: {
            property: {
              selects: [
                "id",
                "name"
              ]
            }
          }
        },
        ...this.filters
      });
    },
    change() {
      this.$emit("change");
    },
    blur() {
      this.$emit("blur");
    },
  },
  computed: {
    ...mapState({
      unitCategoriesRequest: (state) => state.unitCategories.getCollectionRequest
    }),
    items() {
      if (!this.unitCategoriesRequest.collection) {
        return [];
      }

      return this.unitCategoriesRequest.collection;
    },
    loading() {
      return this.unitCategoriesRequest.loading;
    },
    withProperty() {
      return !this.filters.property_id;
    }
  },
  watch: {
    ids(newVal) {
      this.$emit("input", newVal);
    },
    value(newVal) {
      this.ids = newVal;
    },
    filters: {
      handler() {
        this.getItems();
      },
      deep: true
    }
  }
};
</script>
