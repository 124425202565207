import list from "@/store/core/list";
import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_SUBSCRIPTION_COUPONS_LIST,
  GET_SUBSCRIPTION_COUPONS_LIST_SUCCESS,
  GET_SUBSCRIPTION_COUPONS_LIST_FAIL,
  GET_SUBSCRIPTION_COUPONS_PAGINATION,
  GET_SUBSCRIPTION_COUPONS_PAGINATION_SUCCESS,
  GET_SUBSCRIPTION_COUPONS_PAGINATION_FAIL,
  GET_SUBSCRIPTION_COUPONS_COLLECTION,
  GET_SUBSCRIPTION_COUPONS_COLLECTION_SUCCESS,
  GET_SUBSCRIPTION_COUPONS_COLLECTION_FAIL,
  GET_SUBSCRIPTION_COUPONS_SINGLE,
  GET_SUBSCRIPTION_COUPONS_SINGLE_SUCCESS,
  GET_SUBSCRIPTION_COUPONS_SINGLE_FAIL,
  CREATE_SUBSCRIPTION_COUPONS,
  CREATE_SUBSCRIPTION_COUPONS_SUCCESS,
  CREATE_SUBSCRIPTION_COUPONS_FAIL,
  CREATE_SUBSCRIPTION_COUPONS_RESET,
  UPDATE_SUBSCRIPTION_COUPONS,
  UPDATE_SUBSCRIPTION_COUPONS_SUCCESS,
  UPDATE_SUBSCRIPTION_COUPONS_FAIL,
  UPDATE_SUBSCRIPTION_COUPONS_RESET,
  REMOVE_SUBSCRIPTION_COUPONS,
  REMOVE_SUBSCRIPTION_COUPONS_SUCCESS,
  REMOVE_SUBSCRIPTION_COUPONS_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_SUBSCRIPTION_COUPONS_LIST,
    endPoint: "subscription-coupons/list",
    mutationFail: GET_SUBSCRIPTION_COUPONS_LIST_FAIL,
    mutationSuccess: GET_SUBSCRIPTION_COUPONS_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_SUBSCRIPTION_COUPONS_PAGINATION,
    endPoint: "subscription-coupons/paginated",
    mutationFail: GET_SUBSCRIPTION_COUPONS_PAGINATION_FAIL,
    mutationSuccess: GET_SUBSCRIPTION_COUPONS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_SUBSCRIPTION_COUPONS_COLLECTION,
    endPoint: "subscription-coupons/collected",
    mutationFail: GET_SUBSCRIPTION_COUPONS_COLLECTION_FAIL,
    mutationSuccess: GET_SUBSCRIPTION_COUPONS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_SUBSCRIPTION_COUPONS_SINGLE,
    endPoint: "subscription-coupons/single",
    mutationFail: GET_SUBSCRIPTION_COUPONS_SINGLE_FAIL,
    mutationSuccess: GET_SUBSCRIPTION_COUPONS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_SUBSCRIPTION_COUPONS,
    actionReset: CREATE_SUBSCRIPTION_COUPONS_RESET,
    endPoint: "subscription-coupons/create",
    mutationFail: CREATE_SUBSCRIPTION_COUPONS_FAIL,
    mutationSuccess: CREATE_SUBSCRIPTION_COUPONS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_SUBSCRIPTION_COUPONS,
    actionReset: UPDATE_SUBSCRIPTION_COUPONS_RESET,
    endPoint: "subscription-coupons/update",
    mutationFail: UPDATE_SUBSCRIPTION_COUPONS_FAIL,
    mutationSuccess: UPDATE_SUBSCRIPTION_COUPONS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_SUBSCRIPTION_COUPONS,
    endPoint: "subscription-coupons/delete",
    mutationFail: REMOVE_SUBSCRIPTION_COUPONS_FAIL,
    mutationSuccess: REMOVE_SUBSCRIPTION_COUPONS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
