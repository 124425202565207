<template>
  <v-avatar v-if="isShow" size="20" :small="small" color="channel" :title="value">
    <v-icon
      color="black"
      :small="small"
    >mdi-transit-connection-variant
    </v-icon>
  </v-avatar>
</template>

<script>
  export default {
    name: "VChannel",
    props: {
      small: {
        type: Boolean,
        default: false
      },
      value: {
        type: String,
        default: null
      }
    },
    computed: {
      isShow() {
        return !!this.value && this.$can('INTERNAL_SUPPORT')
      }
    }
  }
</script>
