export const GET_PROPERTY_EXTRAS_LIST = "[PROPERTYEXTRAS] List Get";
export const GET_PROPERTY_EXTRAS_LIST_SUCCESS = "[PROPERTYEXTRAS] List Get Success";
export const GET_PROPERTY_EXTRAS_LIST_FAIL = "[PROPERTYEXTRAS] List Get Fail";

export const GET_PROPERTY_EXTRAS_PAGINATION = "[PROPERTYEXTRAS] Pagination Get";
export const GET_PROPERTY_EXTRAS_PAGINATION_SUCCESS = "[PROPERTYEXTRAS] Pagination Get Success";
export const GET_PROPERTY_EXTRAS_PAGINATION_FAIL = "[PROPERTYEXTRAS] Pagination Get Fail";

export const GET_PROPERTY_EXTRAS_COLLECTION = "[PROPERTYEXTRAS] Collection Get";
export const GET_PROPERTY_EXTRAS_COLLECTION_SUCCESS = "[PROPERTYEXTRAS] Collection Get Success";
export const GET_PROPERTY_EXTRAS_COLLECTION_FAIL = "[PROPERTYEXTRAS] Collection Get Fail";

export const GET_PROPERTY_EXTRAS_SINGLE = "[PROPERTYEXTRAS] Single Get";
export const GET_PROPERTY_EXTRAS_SINGLE_SUCCESS = "[PROPERTYEXTRAS] Single Get Success";
export const GET_PROPERTY_EXTRAS_SINGLE_FAIL = "[PROPERTYEXTRAS] Single Get Fail";

export const CREATE_PROPERTY_EXTRAS = "[PROPERTYEXTRAS] Create";
export const CREATE_PROPERTY_EXTRAS_SUCCESS = "[PROPERTYEXTRAS] Create Success";
export const CREATE_PROPERTY_EXTRAS_FAIL = "[PROPERTYEXTRAS] Create Fail";
export const CREATE_PROPERTY_EXTRAS_RESET = "[PROPERTYEXTRAS] Create Reset";

export const UPDATE_PROPERTY_EXTRAS = "[PROPERTYEXTRAS] Update";
export const UPDATE_PROPERTY_EXTRAS_SUCCESS = "[PROPERTYEXTRAS] Update Success";
export const UPDATE_PROPERTY_EXTRAS_FAIL = "[PROPERTYEXTRAS] Update Fail";
export const UPDATE_PROPERTY_EXTRAS_RESET = "[PROPERTYEXTRAS] Update Reset";

export const REMOVE_PROPERTY_EXTRAS = "[PROPERTYEXTRAS] Remove";
export const REMOVE_PROPERTY_EXTRAS_SUCCESS = "[PROPERTYEXTRAS] Remove Success";
export const REMOVE_PROPERTY_EXTRAS_FAIL = "[PROPERTYEXTRAS] Remove Fail";

