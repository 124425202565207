import crud from "@/store/core/crud";
import action from "@/store/core/action";
import list from "@/store/core/list";

import {
  GET_HOUSEKEEPING_SETTINGS_LIST,
  GET_HOUSEKEEPING_SETTINGS_LIST_SUCCESS,
  GET_HOUSEKEEPING_SETTINGS_LIST_FAIL,
  GET_HOUSEKEEPING_SETTINGS_PAGINATION,
  GET_HOUSEKEEPING_SETTINGS_PAGINATION_SUCCESS,
  GET_HOUSEKEEPING_SETTINGS_PAGINATION_FAIL,
  GET_HOUSEKEEPING_SETTINGS_COLLECTION,
  GET_HOUSEKEEPING_SETTINGS_COLLECTION_SUCCESS,
  GET_HOUSEKEEPING_SETTINGS_COLLECTION_FAIL,
  GET_HOUSEKEEPING_SETTINGS_SINGLE,
  GET_HOUSEKEEPING_SETTINGS_SINGLE_SUCCESS,
  GET_HOUSEKEEPING_SETTINGS_SINGLE_FAIL,
  CREATE_HOUSEKEEPING_SETTINGS,
  CREATE_HOUSEKEEPING_SETTINGS_SUCCESS,
  CREATE_HOUSEKEEPING_SETTINGS_FAIL,
  CREATE_HOUSEKEEPING_SETTINGS_RESET,
  UPDATE_HOUSEKEEPING_SETTINGS,
  UPDATE_HOUSEKEEPING_SETTINGS_SUCCESS,
  UPDATE_HOUSEKEEPING_SETTINGS_FAIL,
  UPDATE_HOUSEKEEPING_SETTINGS_RESET,
  REMOVE_HOUSEKEEPING_SETTINGS,
  REMOVE_HOUSEKEEPING_SETTINGS_SUCCESS,
  REMOVE_HOUSEKEEPING_SETTINGS_FAIL,
  GENERATE_HOUSEKEEPING_TASKS,
  GENERATE_HOUSEKEEPING_TASKS_SUCCESS,
  GENERATE_HOUSEKEEPING_TASKS_FAIL,
  UPDATE_HOUSEKEEPING_COSTS,
  UPDATE_HOUSEKEEPING_COSTS_SUCCESS,
  UPDATE_HOUSEKEEPING_COSTS_FAIL,
  GET_PROPERTY_ICS_LINK,
  GET_PROPERTY_ICS_LINK_SUCCESS,
  GET_PROPERTY_ICS_LINK_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_HOUSEKEEPING_SETTINGS_LIST,
    endPoint: "housekeeping-settings/list",
    mutationFail: GET_HOUSEKEEPING_SETTINGS_LIST_FAIL,
    mutationSuccess: GET_HOUSEKEEPING_SETTINGS_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_HOUSEKEEPING_SETTINGS_PAGINATION,
    endPoint: "housekeeping-settings/paginated",
    mutationFail: GET_HOUSEKEEPING_SETTINGS_PAGINATION_FAIL,
    mutationSuccess: GET_HOUSEKEEPING_SETTINGS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_HOUSEKEEPING_SETTINGS_COLLECTION,
    endPoint: "housekeeping-settings/collected",
    mutationFail: GET_HOUSEKEEPING_SETTINGS_COLLECTION_FAIL,
    mutationSuccess: GET_HOUSEKEEPING_SETTINGS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_HOUSEKEEPING_SETTINGS_SINGLE,
    endPoint: "housekeeping-settings/single",
    mutationFail: GET_HOUSEKEEPING_SETTINGS_SINGLE_FAIL,
    mutationSuccess: GET_HOUSEKEEPING_SETTINGS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_HOUSEKEEPING_SETTINGS,
    actionReset: CREATE_HOUSEKEEPING_SETTINGS_RESET,
    endPoint: "housekeeping-settings/create",
    mutationFail: CREATE_HOUSEKEEPING_SETTINGS_FAIL,
    mutationSuccess: CREATE_HOUSEKEEPING_SETTINGS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_HOUSEKEEPING_SETTINGS,
    actionReset: UPDATE_HOUSEKEEPING_SETTINGS_RESET,
    endPoint: "housekeeping-settings/update",
    mutationFail: UPDATE_HOUSEKEEPING_SETTINGS_FAIL,
    mutationSuccess: UPDATE_HOUSEKEEPING_SETTINGS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_HOUSEKEEPING_SETTINGS,
    endPoint: "housekeeping-settings/delete",
    mutationFail: REMOVE_HOUSEKEEPING_SETTINGS_FAIL,
    mutationSuccess: REMOVE_HOUSEKEEPING_SETTINGS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    method: 'post',
    name: 'createTasksRequest',
    action: GENERATE_HOUSEKEEPING_TASKS,
    endPoint: 'housekeeping-settings/generate',
    mutations: {
      fail: GENERATE_HOUSEKEEPING_TASKS_FAIL,
      success: GENERATE_HOUSEKEEPING_TASKS_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'updateCostsRequest',
    action: UPDATE_HOUSEKEEPING_COSTS,
    endPoint: 'housekeeping-settings/update-costs',
    mutations: {
      fail: UPDATE_HOUSEKEEPING_COSTS_FAIL,
      success: UPDATE_HOUSEKEEPING_COSTS_SUCCESS,
    },
  },
  {
    name: 'getPropertyIcsLink',
    action: GET_PROPERTY_ICS_LINK,
    endPoint: '/housekeeping-settings/get-ics-link/{property_id}',
    mutations: {
      fail: GET_PROPERTY_ICS_LINK_SUCCESS,
      success: GET_PROPERTY_ICS_LINK_FAIL,
    },
  },
]);

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
