export const GET_RESERVATION_UNIT_CATEGORY_COSTS_PAGINATION = "[RESERVATION_UNIT_CATEGORY_COSTS] Pagination Get";
export const GET_RESERVATION_UNIT_CATEGORY_COSTS_PAGINATION_SUCCESS = "[RESERVATION_UNIT_CATEGORY_COSTS] Pagination Get Success";
export const GET_RESERVATION_UNIT_CATEGORY_COSTS_PAGINATION_FAIL = "[RESERVATION_UNIT_CATEGORY_COSTS] Pagination Get Fail";

export const GET_RESERVATION_UNIT_CATEGORY_COSTS_COLLECTION = "[RESERVATION_UNIT_CATEGORY_COSTS] Collection Get";
export const GET_RESERVATION_UNIT_CATEGORY_COSTS_COLLECTION_SUCCESS = "[RESERVATION_UNIT_CATEGORY_COSTS] Collection Get Success";
export const GET_RESERVATION_UNIT_CATEGORY_COSTS_COLLECTION_FAIL = "[RESERVATION_UNIT_CATEGORY_COSTS] Collection Get Fail";

export const GET_RESERVATION_UNIT_CATEGORY_COSTS_SINGLE = "[RESERVATION_UNIT_CATEGORY_COSTS] Single Get";
export const GET_RESERVATION_UNIT_CATEGORY_COSTS_SINGLE_SUCCESS = "[RESERVATION_UNIT_CATEGORY_COSTS] Single Get Success";
export const GET_RESERVATION_UNIT_CATEGORY_COSTS_SINGLE_FAIL = "[RESERVATION_UNIT_CATEGORY_COSTS] Single Get Fail";

export const CREATE_RESERVATION_UNIT_CATEGORY_COSTS = "[RESERVATION_UNIT_CATEGORY_COSTS] Create";
export const CREATE_RESERVATION_UNIT_CATEGORY_COSTS_SUCCESS = "[RESERVATION_UNIT_CATEGORY_COSTS] Create Success";
export const CREATE_RESERVATION_UNIT_CATEGORY_COSTS_FAIL = "[RESERVATION_UNIT_CATEGORY_COSTS] Create Fail";
export const CREATE_RESERVATION_UNIT_CATEGORY_COSTS_RESET = "[RESERVATION_UNIT_CATEGORY_COSTS] Create Reset";

export const UPDATE_RESERVATION_UNIT_CATEGORY_COSTS = "[RESERVATION_UNIT_CATEGORY_COSTS] Update";
export const UPDATE_RESERVATION_UNIT_CATEGORY_COSTS_SUCCESS = "[RESERVATION_UNIT_CATEGORY_COSTS] Update Success";
export const UPDATE_RESERVATION_UNIT_CATEGORY_COSTS_FAIL = "[RESERVATION_UNIT_CATEGORY_COSTS] Update Fail";
export const UPDATE_RESERVATION_UNIT_CATEGORY_COSTS_RESET = "[RESERVATION_UNIT_CATEGORY_COSTS] Update Reset";

export const REMOVE_RESERVATION_UNIT_CATEGORY_COSTS = "[RESERVATION_UNIT_CATEGORY_COSTS] Remove";
export const REMOVE_RESERVATION_UNIT_CATEGORY_COSTS_SUCCESS = "[RESERVATION_UNIT_CATEGORY_COSTS] Remove Success";
export const REMOVE_RESERVATION_UNIT_CATEGORY_COSTS_FAIL = "[RESERVATION_UNIT_CATEGORY_COSTS] Remove Fail";
