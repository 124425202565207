export const attach = (success, error) => {
  let script = document.createElement('script');

  script.setAttribute('src', 'https://js.stripe.com/v3/');

  if (typeof success === 'function') {
    script.addEventListener('load', (e) => {
      success(null, e);
    }, false);
  }

  if (typeof error === 'function') {
    script.addEventListener('error', (e) => {
      error(null, e);
    }, false);
  }

  document.head.appendChild(script);
};
