import { mapState } from "vuex";

import {
  MFA_GET,
  MFA_ENABLE,
  MFA_DISABLE,
  MFA_GOOGLE_INFO_GET,
} from "@/store/mfa/actions";

export default {
  data() {
    return {
      mfa: {
        code: null,
        method: null,
        google2fa_secret: null,
      }
    }
  },
  async beforeMount() {
    await this.getMfaConfig();
  },
  methods: {
    async getMfaConfig() {
      await this.$store.dispatch(MFA_GET);
    },
    async disableMFA() {
      await this.$store.dispatch(MFA_DISABLE);
      await this.getMfaConfig();
      this.$emit("success");
    },
    async enableMFA(params) {
      await this.$store.dispatch(MFA_ENABLE, params);
      await this.getMfaConfig();
      this.showNotification(this.$lang('users.mfa.succeeded'), 'success');
      this.$emit("success");
    },
    async getGoogleInfo() {
      await this.$store.dispatch(MFA_GOOGLE_INFO_GET);
      this.mfa.google2fa_secret = this.googleInfo.secret;
    },
    async preEnableMFA() {
      if (!this.mfa.mfa_method) {
        this.showNotification(this.$lang('users.mfa.choose_error'), 'error');
        return;
      }
      if (this.mfa.mfa_method === this.$const('ConstMfaMethods', 'GOOGLE')) {
        if (this.mfa.google2fa_secret) {
          await this.enableMFA({
            code: this.mfa.code,
            mfa_method: this.mfa.mfa_method,
            google2fa_secret: this.mfa.google2fa_secret,
          });
        } else {
          await this.getGoogleInfo();
        }
      }
      if (this.mfa.mfa_method === this.$const('ConstMfaMethods', 'EMAIL')) {
        await this.enableMFA({
          mfa_method: this.mfa.mfa_method,
        });
      }
    },
  },
  computed: {
    ...mapState({
      mfaConfig: state => state.mfa.getRequest.data,
      enableRequest: state => state.mfa.enableRequest,
      disableRequest: state => state.mfa.disableRequest,
      googleInfo: state => state.mfa.googleInfoRequest.data,
      googleInfoRequest: state => state.mfa.googleInfoRequest,
      enableRequestValidation: state => state.mfa.enableRequest.errors,
    }),
  },
};
