export const GET_CARDS_DETAILS = "[CARDS] Details Get";
export const GET_CARDS_DETAILS_SUCCESS = "[CARDS] Details Get Success";
export const GET_CARDS_DETAILS_FAIL = "[CARDS] Details Get Fail";

export const CREATE_CARDS_API_USER = "[CARDS] Create Api User";
export const CREATE_CARDS_API_USER_SUCCESS = "[CARDS] Create Api User Success";
export const CREATE_CARDS_API_USER_FAIL = "[CARDS] Create Api User Fail";
export const CREATE_CARDS_API_USER_RESET = "[CARDS] Create Api User Reset";

export const GET_CARD_SECONDARY_USER = "[CARDS] Secondary User Get";
export const GET_CARD_SECONDARY_USER_SUCCESS = "[CARDS] Secondary User Get Success";
export const GET_CARD_SECONDARY_USER_FAIL = "[CARDS] Secondary User Get Fail";

export const GET_CARD_UI = "[CARDS] UI Get";
export const GET_CARD_UI_SUCCESS = "[CARDS] UI Get Success";
export const GET_CARD_UI_FAIL = "[CARDS] UI Get Fail";

export const STORE_CARD = "[CARDS] Store";
export const STORE_CARD_SUCCESS = "[CARDS] Store Success";
export const STORE_CARD_FAIL = "[CARDS] Store Fail";
export const STORE_CARD_RESET = "[CARDS] Store Reset";

export const GET_API_USER = "[CARDS] Api User Get";
export const GET_API_USER_SUCCESS = "[CARDS] Api User Get Succeess";
export const GET_API_USER_FAIL = "[CARDS] Api User Get Fail";
export const GET_API_USER_RESET = "[CARDS] Api User Get Reset";

export const UPDATE_API_USER = "[CARDS] Api User Update";
export const UPDATE_API_USER_SUCCESS = "[CARDS] Api User Update Succeess";
export const UPDATE_API_USER_FAIL = "[CARDS] Api User Update Fail";
export const UPDATE_API_USER_RESET = "[CARDS] Api User Update Reset";
