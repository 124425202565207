export const GET_IMAGES_COLLECTION = "[IMAGES] Collection Get";
export const GET_IMAGES_COLLECTION_SUCCESS = "[IMAGES] Collection Get Success";
export const GET_IMAGES_COLLECTION_FAIL = "[IMAGES] Collection Get Fail";
export const PUSH_TO_IMAGES_COLLECTION = "[IMAGES] Push to images collection";

export const UPLOAD_IMAGES = "[IMAGES] Create";
export const UPLOAD_IMAGES_SUCCESS = "[IMAGES] Create Success";
export const UPLOAD_IMAGES_FAIL = "[IMAGES] Create Fail";
export const UPLOAD_IMAGES_RESET = "[IMAGES] Create Reset";

export const UPDATE_IMAGES = "[IMAGES] Update";
export const UPDATE_IMAGES_SUCCESS = "[IMAGES] Update Success";
export const UPDATE_IMAGES_FAIL = "[IMAGES] Update Fail";
export const UPDATE_IMAGES_RESET = "[IMAGES] Update Reset";

export const UPDATE_ALL_IMAGES = "[IMAGES] Update All";
export const UPDATE_ALL_IMAGES_SUCCESS = "[IMAGES] Update All Success";
export const UPDATE_ALL_IMAGES_FAIL = "[IMAGES] Update All Fail";
export const UPDATE_ALL_IMAGES_RESET = "[IMAGES] Update All Reset";

export const DELETE_IMAGES = "[IMAGES] Delete";
export const DELETE_IMAGES_SUCCESS = "[IMAGES] Delete Success";
export const DELETE_IMAGES_FAIL = "[IMAGES] Delete Fail";
export const DELETE_FROM_IMAGES_COLLECTION = "[IMAGES] Delete from images collection";


export const DELETE_ALL_IMAGES = "[IMAGES] Delete All";
export const DELETE_ALL_IMAGES_SUCCESS = "[IMAGES] Delete All Success";
export const DELETE_ALL_IMAGES_FAIL = "[IMAGES] Delete All Fail";
export const DELETE_ALL_IMAGES_COLLECTION = "[IMAGES] Delete All Images collection";
