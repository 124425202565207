export const GET_AGENCIES_LIST = "[AGENCIES] List Get";
export const GET_AGENCIES_LIST_SUCCESS = "[AGENCIES] List Get Success";
export const GET_AGENCIES_LIST_FAIL = "[AGENCIES] List Get Fail";

export const GET_AGENCIES_PAGINATION = "[AGENCIES] Pagination Get";
export const GET_AGENCIES_PAGINATION_SUCCESS = "[AGENCIES] Pagination Get Success";
export const GET_AGENCIES_PAGINATION_FAIL = "[AGENCIES] Pagination Get Fail";

export const GET_AGENCIES_COLLECTION = "[AGENCIES] Collection Get";
export const GET_AGENCIES_COLLECTION_SUCCESS = "[AGENCIES] Collection Get Success";
export const GET_AGENCIES_COLLECTION_FAIL = "[AGENCIES] Collection Get Fail";

export const GET_AGENCIES_SINGLE = "[AGENCIES] Single Get";
export const GET_AGENCIES_SINGLE_SUCCESS = "[AGENCIES] Single Get Success";
export const GET_AGENCIES_SINGLE_FAIL = "[AGENCIES] Single Get Fail";

export const CREATE_AGENCIES = "[AGENCIES] Create";
export const CREATE_AGENCIES_SUCCESS = "[AGENCIES] Create Success";
export const CREATE_AGENCIES_FAIL = "[AGENCIES] Create Fail";
export const CREATE_AGENCIES_RESET = "[AGENCIES] Create Reset";

export const UPDATE_AGENCIES = "[AGENCIES] Update";
export const UPDATE_AGENCIES_SUCCESS = "[AGENCIES] Update Success";
export const UPDATE_AGENCIES_FAIL = "[AGENCIES] Update Fail";
export const UPDATE_AGENCIES_RESET = "[AGENCIES] Update Reset";

export const REPLACE_AGENCIES = "[AGENCIES] Replace";
export const REPLACE_AGENCIES_SUCCESS = "[AGENCIES] Replace Success";
export const REPLACE_AGENCIES_FAIL = "[AGENCIES] Replace Fail";
export const REPLACE_AGENCIES_RESET = "[AGENCIES] Replace Reset";

export const REMOVE_AGENCIES = "[AGENCIES] Remove";
export const REMOVE_AGENCIES_SUCCESS = "[AGENCIES] Remove Success";
export const REMOVE_AGENCIES_FAIL = "[AGENCIES] Remove Fail";