export const GET_COMPANIES_LIST = "[COMPANIES] List Get";
export const GET_COMPANIES_LIST_SUCCESS = "[COMPANIES] List Get Success";
export const GET_COMPANIES_LIST_FAIL = "[COMPANIES] List Get Fail";

export const GET_COMPANIES_PAGINATION = "[COMPANIES] Pagination Get";
export const GET_COMPANIES_PAGINATION_SUCCESS = "[COMPANIES] Pagination Get Success";
export const GET_COMPANIES_PAGINATION_FAIL = "[COMPANIES] Pagination Get Fail";

export const GET_COMPANIES_COLLECTION = "[COMPANIES] Collection Get";
export const GET_COMPANIES_COLLECTION_SUCCESS = "[COMPANIES] Collection Get Success";
export const GET_COMPANIES_COLLECTION_FAIL = "[COMPANIES] Collection Get Fail";

export const GET_COMPANIES_SINGLE = "[COMPANIES] Single Get";
export const GET_COMPANIES_SINGLE_SUCCESS = "[COMPANIES] Single Get Success";
export const GET_COMPANIES_SINGLE_FAIL = "[COMPANIES] Single Get Fail";

export const CREATE_COMPANIES = "[COMPANIES] Create";
export const CREATE_COMPANIES_SUCCESS = "[COMPANIES] Create Success";
export const CREATE_COMPANIES_FAIL = "[COMPANIES] Create Fail";
export const CREATE_COMPANIES_RESET = "[COMPANIES] Create Reset";

export const UPDATE_COMPANIES = "[COMPANIES] Update";
export const UPDATE_COMPANIES_SUCCESS = "[COMPANIES] Update Success";
export const UPDATE_COMPANIES_FAIL = "[COMPANIES] Update Fail";
export const UPDATE_COMPANIES_RESET = "[COMPANIES] Update Reset";

export const REPLACE_COMPANIES = "[COMPANIES] Replace";
export const REPLACE_COMPANIES_SUCCESS = "[COMPANIES] Replace Success";
export const REPLACE_COMPANIES_FAIL = "[COMPANIES] Replace Fail";
export const REPLACE_COMPANIES_RESET = "[COMPANIES] Replace Reset";

export const REMOVE_COMPANIES = "[COMPANIES] Remove";
export const REMOVE_COMPANIES_SUCCESS = "[COMPANIES] Remove Success";
export const REMOVE_COMPANIES_FAIL = "[COMPANIES] Remove Fail";

export const GET_COMPANIES_TAX_REGIMES = "[COMPANIES] Tax Regime Get";
export const GET_COMPANIES_TAX_REGIMES_SUCCESS = "[COMPANIES] Tax Regime Get Success";
export const GET_COMPANIES_TAX_REGIMES_FAIL = "[COMPANIES] Tax Regime Get Fail";

export const GET_COMPANIES_ENTRATEL_ACCOUNT_TYPES = "[COMPANIES] Entratel Account Type Get";
export const GET_COMPANIES_ENTRATEL_ACCOUNT_TYPES_SUCCESS = "[COMPANIES] Entratel Account Type Get Success";
export const GET_COMPANIES_ENTRATEL_ACCOUNT_TYPES_FAIL = "[COMPANIES] Entratel Account Type Get Fail";

export const GET_COMPANIES_ENTRATEL_ACCOUNT_SUBTYPES = "[COMPANIES] Entratel Account Subtype Get";
export const GET_COMPANIES_ENTRATEL_ACCOUNT_SUBTYPES_SUCCESS = "[COMPANIES] Entratel Account Subtype Get Success";
export const GET_COMPANIES_ENTRATEL_ACCOUNT_SUBTYPES_FAIL = "[COMPANIES] Entratel Account Subtype Get Fail";

export const GET_COMPANY_WHITELABEL = "[COMPANIES] Whitelabel Get";
export const GET_COMPANY_WHITELABEL_SUCCESS = "[COMPANIES] Whitelabel Get Success";
export const GET_COMPANY_WHITELABEL_FAIL = "[COMPANIES] Whitelabel Get Fail";
