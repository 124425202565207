export const DATA_ISO_COUNTRIES_2_LETTERS = "[DATA] Iso Countries 2 letters";
export const DATA_ISO_COUNTRIES_2_LETTERS_SUCCESS = "[DATA] Iso Countries 2 letters Success";
export const DATA_ISO_COUNTRIES_2_LETTERS_FAIL = "[DATA] Iso Countries 2 letters Fail";

export const DATA_ISO_COUNTRIES_3_LETTERS = "[DATA] Iso Countries 3 letters";
export const DATA_ISO_COUNTRIES_3_LETTERS_SUCCESS = "[DATA] Iso Countries 3 letters Success";
export const DATA_ISO_COUNTRIES_3_LETTERS_FAIL = "[DATA] Iso Countries 3 letters Fail";

export const DATA_STAT_TYPES = "[DATA] Stat Types";
export const DATA_STAT_TYPES_SUCCESS = "[DATA] Stat Types Success";
export const DATA_STAT_TYPES_FAIL = "[DATA] Stat Types Fail";

export const DATA_POLICE_TYPES = "[DATA] Police Types";
export const DATA_POLICE_TYPES_SUCCESS = "[DATA] Police Types Success";
export const DATA_POLICE_TYPES_FAIL = "[DATA] Police Types Fail";

export const DATA_DOC_TYPES = "[DATA] Doc Types";
export const DATA_DOC_TYPES_SUCCESS = "[DATA] Doc Types Success";
export const DATA_DOC_TYPES_FAIL = "[DATA] Doc Types Fail";

export const DATA_CURRENCIES = "[DATA] Currencies";
export const DATA_CURRENCIES_SUCCESS = "[DATA] Currencies Success";
export const DATA_CURRENCIES_FAIL = "[DATA] Currencies Fail";

export const DATA_TIMEZONES = "[DATA] Timezones";
export const DATA_TIMEZONES_SUCCESS = "[DATA] Timezones Success";
export const DATA_TIMEZONES_FAIL = "[DATA] Timezones Fail";

export const DATA_LOCALE = "[DATA] Locale";
export const DATA_LOCALE_SUCCESS = "[DATA] Locale Success";
export const DATA_LOCALE_FAIL = "[DATA] Locale Fail";

export const DATA_ISO639_2 = "[DATA] ISO639_2";
export const DATA_ISO639_2_SUCCESS = "[DATA] ISO639_2 Success";
export const DATA_ISO639_2_FAIL = "[DATA] ISO639_2 Fail";

export const DATA_CHEKIN_STAT_EXTERNAL_ID = "[DATA] Chekin Stat External ID";
export const DATA_CHEKIN_STAT_EXTERNAL_ID_SUCCESS = "[DATA] Chekin Stat External ID Success";
export const DATA_CHEKIN_STAT_EXTERNAL_ID_FAIL = "[DATA] Chekin Stat External ID Fail";

export const CHECK_VAT_CODE = "[DATA] Check Vat Code";
export const CHECK_VAT_CODE_SUCCESS = "[DATA] Check Vat Code Success";
export const CHECK_VAT_CODE_FAIL = "[DATA] Check Vat Code Fail";

export const GET_PERMISSION_COLLECTION = "[DATA] Permission";
export const GET_PERMISSION_COLLECTION_SUCCESS = "[DATA] Permission Success";
export const GET_PERMISSION_COLLECTION_FAIL = "[DATA] Permission Fail";
