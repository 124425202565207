import ApiService from "@/services/api";
import { authenticate } from "../mix/authenticate";
import { isAuthenticated } from '@/helpers/functions';
import SessionService from '@/libraries/sessionjs/src';

import {
  LOAD,
  LOAD_SUCCESS_AUTHENTICATED,
  LOAD_SUCCESS_UNAUTHENTICATED,
  LOAD_FAIL,
  LOAD_TRANSLATIONS,
  LOAD_TRANSLATIONS_SUCCESS,
  LOAD_TRANSLATIONS_FAIL,
  LOAD_CONSTANTS,
  LOAD_CONSTANTS_SUCCESS,
  LOAD_CONSTANTS_FAIL,
  NOTIFICATION_SHOW,
  NOTIFICATION_HIDE,
  LOADING_SHOW,
  LOADING_HIDE,
  CLEAR_CACHE,
  CLEAR_TRANSLATIONS,
  LOAD_NOTES,
  SAVE_NOTES,
  TOGGLE_STATISTICS_PRIVACY,
  INIT_STATISTICS_PRIVACY,
} from "./actions";

import {
  LOGIN_RESTORE,
} from "../auth/actions";

const getters = {};

const state = {
  failed: false,
  loaded: false,
  contentLoading: false,
  constants: {},
  translations: {},
  notification: {
    color: null,
    exists: false,
    text: null
  },
  appLoading: false,
  notes: null,
  statisticsPrivacy: false,
};

const actions = {
  [LOAD](context, config) {
    config = config || {};

    if (isAuthenticated()) {
      authenticate(context, config).then(() => {
        context.commit(LOAD_SUCCESS_AUTHENTICATED);
      });

      context.dispatch(LOGIN_RESTORE);
    } else {
      const modules = config.translationModules;

      context.dispatch(LOAD_TRANSLATIONS, { modules }).then(() => {
        setTimeout(() => {
          context.commit(LOAD_SUCCESS_UNAUTHENTICATED);
        }, 1000);
      })
    }
  },
  [LOAD_TRANSLATIONS](context, params) {
    let translations = SessionService.disk('local').get('translations');

    if (!!translations) {
      return new Promise(resolve => {
        context.commit(LOAD_TRANSLATIONS_SUCCESS, translations);
        resolve(translations);
      })
    }

    return new Promise(resolve => {
      ApiService.query("translations", params)
        .then(response => {
          if (params && typeof params.modules === 'undefined') {
            SessionService.disk('local').set('translations', response.data.data, {ttl: 3600});
          }
          context.commit(LOAD_TRANSLATIONS_SUCCESS, response.data.data);
          resolve(response.data);
        })
        .catch(() => {
          context.commit(LOAD_TRANSLATIONS_FAIL);
        });
    });
  },
  [LOAD_CONSTANTS](context) {
    let constants = SessionService.disk('local').get('constants');

    if (!!constants) {
      return new Promise(resolve => {
        context.commit(LOAD_CONSTANTS_SUCCESS, constants);
        resolve(constants);
      })
    }

    return new Promise(resolve => {
      ApiService.get("constants")
        .then(response => {
          SessionService.disk('local').set('constants', response.data.data, {ttl: 3600});
          context.commit(LOAD_CONSTANTS_SUCCESS, response.data.data);
          resolve(response.data);
        })
        .catch(() => {
          context.commit(LOAD_CONSTANTS_FAIL);
        });
    });
  },
  [NOTIFICATION_SHOW](context, { text, color }) {
    context.state.notification.text = text;
    context.state.notification.color = color;
    context.state.notification.exists = true;
  },
  [NOTIFICATION_HIDE](context) {
    context.state.notification.text = null;
    context.state.notification.color = null;
    context.state.notification.exists = false;
  },
  [LOADING_SHOW](context) {
    context.state.appLoading = true;
  },
  [LOADING_HIDE](context) {
    context.state.appLoading = false;
  },
  [CLEAR_CACHE](context) {
    return new Promise(resolve => {
      SessionService.disk('local').remove('constants');
      SessionService.disk('local').remove('translations');
      SessionService.disk('local').remove('FILTERS');
      resolve(true);
    })
  },
  [CLEAR_TRANSLATIONS](context) {
    return new Promise(resolve => {
      SessionService.disk('local').remove('constants');
      SessionService.disk('local').remove('translations');
      resolve(true);
    })
  },
  [LOAD_NOTES] (context) {
    let notes = SessionService.disk('local').get('NOTES');

    context.state.notes = notes;

    return notes;
  },
  [SAVE_NOTES] (context, params) {
    return new Promise(resolve => {
      resolve(SessionService.disk('local').set('NOTES', params));
    });
  },
  [TOGGLE_STATISTICS_PRIVACY] (context) {
    return new Promise(resolve => {
      const value = SessionService.disk('local').get('STATISTICS_PRIVACY') || false;
      resolve(SessionService.disk('local').set('STATISTICS_PRIVACY', !value));

      context.state.statisticsPrivacy = !value;
    });
  },
  [INIT_STATISTICS_PRIVACY] (context) {
    return new Promise(resolve => {
      const value = SessionService.disk('local').get('STATISTICS_PRIVACY') || false;

      context.state.statisticsPrivacy = value;
    });
  }
};

const mutations = {
  [LOAD_SUCCESS_AUTHENTICATED](state) {
    state.loaded = true;
    state.failed = false;
  },
  [LOAD_SUCCESS_UNAUTHENTICATED](state) {
    state.loaded = true;
    state.failed = false;
  },
  [LOAD_FAIL](state) {
    state.loaded = true;
    state.failed = true;
  },
  [LOAD_TRANSLATIONS_SUCCESS](state, data) {
    state.translations = data;
    state.data = Object.freeze(data);
  },
  [LOAD_TRANSLATIONS_FAIL](state) {
    state.translations = {};
    state.loaded = false;
    state.failed = true;
  },
  [LOAD_CONSTANTS_SUCCESS](state, data) {
    state.constants = data;
    state.data = Object.freeze(data);
  },
  [LOAD_CONSTANTS_FAIL](state) {
    state.constants = {};
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
