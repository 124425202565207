import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";
import ApiService from "@/services/api";

import {
  GET_COMPANY_JOURNALS_LIST,
  GET_COMPANY_JOURNALS_LIST_SUCCESS,
  GET_COMPANY_JOURNALS_LIST_FAIL,
  CREATE_COMPANY_JOURNALS,
  CREATE_COMPANY_JOURNALS_FAIL,
  CREATE_COMPANY_JOURNALS_RESET,
  CREATE_COMPANY_JOURNALS_SUCCESS,
  GET_COMPANY_JOURNALS_COLLECTION,
  GET_COMPANY_JOURNALS_COLLECTION_FAIL,
  GET_COMPANY_JOURNALS_COLLECTION_SUCCESS,
  GET_COMPANY_JOURNALS_SINGLE,
  GET_COMPANY_JOURNALS_SINGLE_FAIL,
  GET_COMPANY_JOURNALS_SINGLE_SUCCESS,
  REMOVE_COMPANY_JOURNALS,
  REMOVE_COMPANY_JOURNALS_FAIL,
  REMOVE_COMPANY_JOURNALS_SUCCESS,
  UPDATE_COMPANY_JOURNALS,
  UPDATE_COMPANY_JOURNALS_FAIL,
  UPDATE_COMPANY_JOURNALS_RESET,
  UPDATE_COMPANY_JOURNALS_SUCCESS,
  GET_COMPANY_JOURNALS_PAGINATION,
  GET_COMPANY_JOURNALS_PAGINATION_FAIL,
  GET_COMPANY_JOURNALS_PAGINATION_SUCCESS,
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_COMPANY_JOURNALS_PAGINATION,
    endPoint: "company-journals/paginated",
    mutationFail: GET_COMPANY_JOURNALS_PAGINATION_FAIL,
    mutationSuccess: GET_COMPANY_JOURNALS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_COMPANY_JOURNALS_COLLECTION,
    endPoint: "company-journals/collected",
    mutationFail: GET_COMPANY_JOURNALS_COLLECTION_FAIL,
    mutationSuccess: GET_COMPANY_JOURNALS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_COMPANY_JOURNALS_SINGLE,
    endPoint: "company-journals/single",
    mutationFail: GET_COMPANY_JOURNALS_SINGLE_FAIL,
    mutationSuccess: GET_COMPANY_JOURNALS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_COMPANY_JOURNALS,
    actionReset: CREATE_COMPANY_JOURNALS_RESET,
    endPoint: "company-journals/create",
    mutationFail: CREATE_COMPANY_JOURNALS_FAIL,
    mutationSuccess: CREATE_COMPANY_JOURNALS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_COMPANY_JOURNALS,
    actionReset: UPDATE_COMPANY_JOURNALS_RESET,
    endPoint: "company-journals/update",
    mutationFail: UPDATE_COMPANY_JOURNALS_FAIL,
    mutationSuccess: UPDATE_COMPANY_JOURNALS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_COMPANY_JOURNALS,
    endPoint: "company-journals/delete",
    mutationFail: REMOVE_COMPANY_JOURNALS_FAIL,
    mutationSuccess: REMOVE_COMPANY_JOURNALS_SUCCESS
  }
});

const listProcessed = list.process({
  getListRequest: {
    action: GET_COMPANY_JOURNALS_LIST,
    endPoint: "company-journals/list",
    mutationFail: GET_COMPANY_JOURNALS_LIST_FAIL,
    mutationSuccess: GET_COMPANY_JOURNALS_LIST_SUCCESS
  },
});

const actionsProcessed = action.processMultiple([
]);

const getters = {};

const actions = {
  ...crudProcessed.actions,
  ...listProcessed.actions,
  ...actionsProcessed.actions,
};

const state = {
  ...crudProcessed.state,
  ...listProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...crudProcessed.mutations,
  ...listProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
