import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_BOOKING_NETWORK_DESTINATIONS_LIST,
  GET_BOOKING_NETWORK_DESTINATIONS_LIST_SUCCESS,
  GET_BOOKING_NETWORK_DESTINATIONS_LIST_FAIL,
  CREATE_BOOKING_NETWORK_DESTINATIONS,
  CREATE_BOOKING_NETWORK_DESTINATIONS_FAIL,
  CREATE_BOOKING_NETWORK_DESTINATIONS_RESET,
  CREATE_BOOKING_NETWORK_DESTINATIONS_SUCCESS,
  GET_BOOKING_NETWORK_DESTINATIONS_COLLECTION,
  GET_BOOKING_NETWORK_DESTINATIONS_COLLECTION_FAIL,
  GET_BOOKING_NETWORK_DESTINATIONS_COLLECTION_SUCCESS,
  GET_BOOKING_NETWORK_DESTINATIONS_SINGLE,
  GET_BOOKING_NETWORK_DESTINATIONS_SINGLE_FAIL,
  GET_BOOKING_NETWORK_DESTINATIONS_SINGLE_SUCCESS,
  REMOVE_BOOKING_NETWORK_DESTINATIONS,
  REMOVE_BOOKING_NETWORK_DESTINATIONS_FAIL,
  REMOVE_BOOKING_NETWORK_DESTINATIONS_SUCCESS,
  UPDATE_BOOKING_NETWORK_DESTINATIONS,
  UPDATE_BOOKING_NETWORK_DESTINATIONS_FAIL,
  UPDATE_BOOKING_NETWORK_DESTINATIONS_RESET,
  UPDATE_BOOKING_NETWORK_DESTINATIONS_SUCCESS,
  REPLACE_BOOKING_NETWORK_DESTINATIONS,
  REPLACE_BOOKING_NETWORK_DESTINATIONS_FAIL,
  REPLACE_BOOKING_NETWORK_DESTINATIONS_RESET,
  REPLACE_BOOKING_NETWORK_DESTINATIONS_SUCCESS,
  GET_BOOKING_NETWORK_DESTINATIONS_PAGINATION,
  GET_BOOKING_NETWORK_DESTINATIONS_PAGINATION_FAIL,
  GET_BOOKING_NETWORK_DESTINATIONS_PAGINATION_SUCCESS,
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_BOOKING_NETWORK_DESTINATIONS_PAGINATION,
    endPoint: "booking-network-destinations/paginated",
    mutationFail: GET_BOOKING_NETWORK_DESTINATIONS_PAGINATION_FAIL,
    mutationSuccess: GET_BOOKING_NETWORK_DESTINATIONS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_BOOKING_NETWORK_DESTINATIONS_COLLECTION,
    endPoint: "booking-network-destinations/collected",
    mutationFail: GET_BOOKING_NETWORK_DESTINATIONS_COLLECTION_FAIL,
    mutationSuccess: GET_BOOKING_NETWORK_DESTINATIONS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_BOOKING_NETWORK_DESTINATIONS_SINGLE,
    endPoint: "booking-network-destinations/single",
    mutationFail: GET_BOOKING_NETWORK_DESTINATIONS_SINGLE_FAIL,
    mutationSuccess: GET_BOOKING_NETWORK_DESTINATIONS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_BOOKING_NETWORK_DESTINATIONS,
    actionReset: CREATE_BOOKING_NETWORK_DESTINATIONS_RESET,
    endPoint: "booking-network-destinations/create",
    mutationFail: CREATE_BOOKING_NETWORK_DESTINATIONS_FAIL,
    mutationSuccess: CREATE_BOOKING_NETWORK_DESTINATIONS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_BOOKING_NETWORK_DESTINATIONS,
    actionReset: UPDATE_BOOKING_NETWORK_DESTINATIONS_RESET,
    endPoint: "booking-network-destinations/update",
    mutationFail: UPDATE_BOOKING_NETWORK_DESTINATIONS_FAIL,
    mutationSuccess: UPDATE_BOOKING_NETWORK_DESTINATIONS_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_BOOKING_NETWORK_DESTINATIONS,
    actionReset: REPLACE_BOOKING_NETWORK_DESTINATIONS_RESET,
    endPoint: "booking-network-destinations/replace",
    mutationFail: REPLACE_BOOKING_NETWORK_DESTINATIONS_FAIL,
    mutationSuccess: REPLACE_BOOKING_NETWORK_DESTINATIONS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_BOOKING_NETWORK_DESTINATIONS,
    endPoint: "booking-network-destinations/delete",
    mutationFail: REMOVE_BOOKING_NETWORK_DESTINATIONS_FAIL,
    mutationSuccess: REMOVE_BOOKING_NETWORK_DESTINATIONS_SUCCESS
  }
});

const listProcessed = list.process({
  getListRequest: {
    action: GET_BOOKING_NETWORK_DESTINATIONS_LIST,
    endPoint: "booking-network-destinations/list",
    mutationFail: GET_BOOKING_NETWORK_DESTINATIONS_LIST_FAIL,
    mutationSuccess: GET_BOOKING_NETWORK_DESTINATIONS_LIST_SUCCESS
  },
});

const actionsProcessed = action.processMultiple([

]);

const getters = {};

const actions = {
  ...crudProcessed.actions,
  ...listProcessed.actions,
  ...actionsProcessed.actions,
};

const state = {
  ...crudProcessed.state,
  ...listProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...crudProcessed.mutations,
  ...listProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
