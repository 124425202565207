export const GET_WORK_ORDERS_LIST = "[WORK_ORDERS] List Get";
export const GET_WORK_ORDERS_LIST_SUCCESS = "[WORK_ORDERS] List Get Success";
export const GET_WORK_ORDERS_LIST_FAIL = "[WORK_ORDERS] List Get Fail";

export const GET_WORK_ORDERS_PAGINATION = "[WORK_ORDERS] Pagination Get";
export const GET_WORK_ORDERS_PAGINATION_SUCCESS = "[WORK_ORDERS] Pagination Get Success";
export const GET_WORK_ORDERS_PAGINATION_FAIL = "[WORK_ORDERS] Pagination Get Fail";

export const GET_WORK_ORDERS_COLLECTION = "[WORK_ORDERS] Collection Get";
export const GET_WORK_ORDERS_COLLECTION_SUCCESS = "[WORK_ORDERS] Collection Get Success";
export const GET_WORK_ORDERS_COLLECTION_FAIL = "[WORK_ORDERS] Collection Get Fail";

export const GET_WORK_ORDERS_SINGLE = "[WORK_ORDERS] Single Get";
export const GET_WORK_ORDERS_SINGLE_SUCCESS = "[WORK_ORDERS] Single Get Success";
export const GET_WORK_ORDERS_SINGLE_FAIL = "[WORK_ORDERS] Single Get Fail";

export const CREATE_WORK_ORDERS = "[WORK_ORDERS] Create";
export const CREATE_WORK_ORDERS_SUCCESS = "[WORK_ORDERS] Create Success";
export const CREATE_WORK_ORDERS_FAIL = "[WORK_ORDERS] Create Fail";
export const CREATE_WORK_ORDERS_RESET = "[WORK_ORDERS] Create Reset";

export const UPDATE_WORK_ORDERS = "[WORK_ORDERS] Update";
export const UPDATE_WORK_ORDERS_SUCCESS = "[WORK_ORDERS] Update Success";
export const UPDATE_WORK_ORDERS_FAIL = "[WORK_ORDERS] Update Fail";
export const UPDATE_WORK_ORDERS_RESET = "[WORK_ORDERS] Update Reset";

export const REPLACE_WORK_ORDERS = "[WORK_ORDERS] Replace";
export const REPLACE_WORK_ORDERS_SUCCESS = "[WORK_ORDERS] Replace Success";
export const REPLACE_WORK_ORDERS_FAIL = "[WORK_ORDERS] Replace Fail";
export const REPLACE_WORK_ORDERS_RESET = "[WORK_ORDERS] Replace Reset";

export const REMOVE_WORK_ORDERS = "[WORK_ORDERS] Remove";
export const REMOVE_WORK_ORDERS_SUCCESS = "[WORK_ORDERS] Remove Success";
export const REMOVE_WORK_ORDERS_FAIL = "[WORK_ORDERS] Remove Fail";
