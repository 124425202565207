export const GET_BOOKING_PAYMENT_PROVIDERS_LIST = "[BOOKING_PAYMENT_PROVIDERS] List Get";
export const GET_BOOKING_PAYMENT_PROVIDERS_LIST_SUCCESS = "[BOOKING_PAYMENT_PROVIDERS] List Get Success";
export const GET_BOOKING_PAYMENT_PROVIDERS_LIST_FAIL = "[BOOKING_PAYMENT_PROVIDERS] List Get Fail";

export const GET_BOOKING_PAYMENT_PROVIDERS_PAGINATION = "[BOOKING_PAYMENT_PROVIDERS] Pagination Get";
export const GET_BOOKING_PAYMENT_PROVIDERS_PAGINATION_SUCCESS = "[BOOKING_PAYMENT_PROVIDERS] Pagination Get Success";
export const GET_BOOKING_PAYMENT_PROVIDERS_PAGINATION_FAIL = "[BOOKING_PAYMENT_PROVIDERS] Pagination Get Fail";

export const GET_BOOKING_PAYMENT_PROVIDERS_COLLECTION = "[BOOKING_PAYMENT_PROVIDERS] Collection Get";
export const GET_BOOKING_PAYMENT_PROVIDERS_COLLECTION_SUCCESS = "[BOOKING_PAYMENT_PROVIDERS] Collection Get Success";
export const GET_BOOKING_PAYMENT_PROVIDERS_COLLECTION_FAIL = "[BOOKING_PAYMENT_PROVIDERS] Collection Get Fail";

export const GET_BOOKING_PAYMENT_PROVIDERS_SINGLE = "[BOOKING_PAYMENT_PROVIDERS] Single Get";
export const GET_BOOKING_PAYMENT_PROVIDERS_SINGLE_SUCCESS = "[BOOKING_PAYMENT_PROVIDERS] Single Get Success";
export const GET_BOOKING_PAYMENT_PROVIDERS_SINGLE_FAIL = "[BOOKING_PAYMENT_PROVIDERS] Single Get Fail";

export const CREATE_BOOKING_PAYMENT_PROVIDERS = "[BOOKING_PAYMENT_PROVIDERS] Create";
export const CREATE_BOOKING_PAYMENT_PROVIDERS_SUCCESS = "[BOOKING_PAYMENT_PROVIDERS] Create Success";
export const CREATE_BOOKING_PAYMENT_PROVIDERS_FAIL = "[BOOKING_PAYMENT_PROVIDERS] Create Fail";
export const CREATE_BOOKING_PAYMENT_PROVIDERS_RESET = "[BOOKING_PAYMENT_PROVIDERS] Create Reset";

export const UPDATE_BOOKING_PAYMENT_PROVIDERS = "[BOOKING_PAYMENT_PROVIDERS] Update";
export const UPDATE_BOOKING_PAYMENT_PROVIDERS_SUCCESS = "[BOOKING_PAYMENT_PROVIDERS] Update Success";
export const UPDATE_BOOKING_PAYMENT_PROVIDERS_FAIL = "[BOOKING_PAYMENT_PROVIDERS] Update Fail";
export const UPDATE_BOOKING_PAYMENT_PROVIDERS_RESET = "[BOOKING_PAYMENT_PROVIDERS] Update Reset";

export const REMOVE_BOOKING_PAYMENT_PROVIDERS = "[BOOKING_PAYMENT_PROVIDERS] Remove";
export const REMOVE_BOOKING_PAYMENT_PROVIDERS_SUCCESS = "[BOOKING_PAYMENT_PROVIDERS] Remove Success";
export const REMOVE_BOOKING_PAYMENT_PROVIDERS_FAIL = "[BOOKING_PAYMENT_PROVIDERS] Remove Fail";



export const GET_BOOKING_PAYMENT_STRIPE_AUTH_URL = '[GET_BOOKING_PAYMENT_STRIPE_AUTH_URL] Get authorize url'
export const GET_BOOKING_PAYMENT_STRIPE_AUTH_URL_SUCCESS = '[GET_BOOKING_PAYMENT_STRIPE_AUTH_URL] Get authorize url success'
export const GET_BOOKING_PAYMENT_STRIPE_AUTH_URL_FAIL = '[GET_BOOKING_PAYMENT_STRIPE_AUTH_URL] Get authorize url fail'

export const CREATE_BOOKING_PAYMENT_STRIPE_AUTH = '[GET_BOOKING_PAYMENT_STRIPE_AUTH] Create authorize'
export const CREATE_BOOKING_PAYMENT_STRIPE_AUTH_SUCCESS = '[GET_BOOKING_PAYMENT_STRIPE_AUTH] Create authorize success'
export const CREATE_BOOKING_PAYMENT_STRIPE_AUTH_FAIL = '[GET_BOOKING_PAYMENT_STRIPE_AUTH] Create authorize fail'

export const REMOVE_BOOKING_PAYMENT_STRIPE_AUTH = '[REMOVE_BOOKING_PAYMENT_STRIPE] remove authorize'
export const REMOVE_BOOKING_PAYMENT_STRIPE_AUTH_SUCCESS = '[REMOVE_BOOKING_PAYMENT_STRIPE_AUTH] remove authorize success'
export const REMOVE_BOOKING_PAYMENT_STRIPE_AUTH_FAIL = '[REMOVE_BOOKING_PAYMENT_STRIPE_AUTH] remove authorize fail'


export const INHERIT_BOOKING_PAYMENT_PROVIDERS = '[INHERIT_BOOKING_PAYMENT_PROVIDERS] Inherit providers'
export const INHERIT_BOOKING_PAYMENT_PROVIDERS_SUCCESS = '[INHERIT_BOOKING_PAYMENT_PROVIDERS] Inherit providers success'
export const INHERIT_BOOKING_PAYMENT_PROVIDERS_FAIL = '[INHERIT_BOOKING_PAYMENT_PROVIDERS] Inherit providers fail'

export const OVERRIDE_BOOKING_PAYMENT_PROVIDERS = '[OVERRIDE_BOOKING_PAYMENT_PROVIDERS] Override providers'
export const OVERRIDE_BOOKING_PAYMENT_PROVIDERS_SUCCESS = '[OVERRIDE_BOOKING_PAYMENT_PROVIDERS] Override providers success'
export const OVERRIDE_BOOKING_PAYMENT_PROVIDERS_FAIL = '[OVERRIDE_BOOKING_PAYMENT_PROVIDERS] Override providers fail'

export const POSITION_BOOKING_PAYMENT_PROVIDERS = '[BOOKING_PAYMENT_PROVIDERS] Position';
export const POSITION_BOOKING_PAYMENT_PROVIDERS_SUCCESS = '[BOOKING_PAYMENT_PROVIDERS] Position Success';
export const POSITION_BOOKING_PAYMENT_PROVIDERS_FAIL = '[BOOKING_PAYMENT_PROVIDERS] Position Fail';

export const CREATE_BOOKING_PAYMENT_STRIPE_TERMINAL_ACCESS_TOKEN = '[BOOKING_PAYMENT_PROVIDERS] Create Terminal Access Token'
export const CREATE_BOOKING_PAYMENT_STRIPE_TERMINAL_ACCESS_TOKEN_SUCCESS = '[BOOKING_PAYMENT_PROVIDERS] Create Terminal Access Token Success'
export const CREATE_BOOKING_PAYMENT_STRIPE_TERMINAL_ACCESS_TOKEN_FAIL = '[BOOKING_PAYMENT_PROVIDERS] Create Terminal Access Token Fail'

export const GET_BOOKING_PAYMENT_STRIPE_TERMINAL_LOCATIONS = '[BOOKING_PAYMENT_PROVIDERS] Get Terminal Locations'
export const GET_BOOKING_PAYMENT_STRIPE_TERMINAL_LOCATIONS_SUCCESS = '[BOOKING_PAYMENT_PROVIDERS] Get Terminal Locations Success'
export const GET_BOOKING_PAYMENT_STRIPE_TERMINAL_LOCATIONS_FAIL = '[BOOKING_PAYMENT_PROVIDERS] Get Terminal Locations Fail'
