import crud from "@/store/core/crud";
import action from "@/store/core/action";
import ApiService from "@/services/api";

import {
  GET_DOCUMENTS_PAGINATION,
  GET_DOCUMENTS_PAGINATION_SUCCESS,
  GET_DOCUMENTS_PAGINATION_FAIL,
  GET_DOCUMENTS_COLLECTION,
  GET_DOCUMENTS_COLLECTION_SUCCESS,
  GET_DOCUMENTS_COLLECTION_FAIL,
  GET_DOCUMENTS_SINGLE,
  GET_DOCUMENTS_SINGLE_SUCCESS,
  GET_DOCUMENTS_SINGLE_FAIL,
  CREATE_DOCUMENTS,
  CREATE_DOCUMENTS_SUCCESS,
  CREATE_DOCUMENTS_FAIL,
  CREATE_DOCUMENTS_RESET,
  UPDATE_DOCUMENTS,
  UPDATE_DOCUMENTS_SUCCESS,
  UPDATE_DOCUMENTS_FAIL,
  UPDATE_DOCUMENTS_RESET,
  REMOVE_DOCUMENTS,
  REMOVE_DOCUMENTS_SUCCESS,
  REMOVE_DOCUMENTS_FAIL,
  SEND_RECEIPT_DOCUMENTS,
  SEND_RECEIPT_DOCUMENTS_SUCCESS,
  SEND_RECEIPT_DOCUMENTS_FAIL,
  SEND_RECEIPT_CANCELLATION_DOCUMENTS,
  SEND_RECEIPT_CANCELLATION_DOCUMENTS_SUCCESS,
  SEND_RECEIPT_CANCELLATION_DOCUMENTS_FAIL,
  EXPORT_PDF_DOCUMENTS,
  EXPORT_XML_DOCUMENTS,
  GET_FEEDS_REGISTER,
  GET_FEEDS_REGISTER_FAIL,
  GET_FEEDS_REGISTER_SUCCESS,
  SEND_DOCUMENT_EMAIL,
  SEND_DOCUMENT_EMAIL_SUCCESS,
  SEND_DOCUMENT_EMAIL_FAIL,
  SEND_DOCUMENT_SDI,
  SEND_DOCUMENT_SDI_SUCCESS,
  SEND_DOCUMENT_SDI_FAIL,
  SEND_DOCUMENT_QUICKBOOKS,
  SEND_DOCUMENT_QUICKBOOKS_SUCCESS,
  SEND_DOCUMENT_QUICKBOOKS_FAIL,
  SEND_DOCUMENT_QUICKBOOKS_BULK,
  SEND_DOCUMENT_QUICKBOOKS_BULK_SUCCESS,
  SEND_DOCUMENT_QUICKBOOKS_BULK_FAIL,
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_DOCUMENTS_PAGINATION,
    endPoint: "documents/paginated",
    mutationFail: GET_DOCUMENTS_PAGINATION_FAIL,
    mutationSuccess: GET_DOCUMENTS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_DOCUMENTS_COLLECTION,
    endPoint: "documents/collected",
    mutationFail: GET_DOCUMENTS_COLLECTION_FAIL,
    mutationSuccess: GET_DOCUMENTS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_DOCUMENTS_SINGLE,
    endPoint: "documents/single",
    mutationFail: GET_DOCUMENTS_SINGLE_FAIL,
    mutationSuccess: GET_DOCUMENTS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_DOCUMENTS,
    actionReset: CREATE_DOCUMENTS_RESET,
    endPoint: "documents/create",
    mutationFail: CREATE_DOCUMENTS_FAIL,
    mutationSuccess: CREATE_DOCUMENTS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_DOCUMENTS,
    actionReset: UPDATE_DOCUMENTS_RESET,
    endPoint: "documents/update",
    mutationFail: UPDATE_DOCUMENTS_FAIL,
    mutationSuccess: UPDATE_DOCUMENTS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_DOCUMENTS,
    endPoint: "documents/delete",
    mutationFail: REMOVE_DOCUMENTS_FAIL,
    mutationSuccess: REMOVE_DOCUMENTS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    method: 'post',
    name: 'sendReceiptRequest',
    action: SEND_RECEIPT_DOCUMENTS,
    endPoint: 'documents/send-receipt/{id}',
    mutations: {
      fail: SEND_RECEIPT_DOCUMENTS_FAIL,
      success: SEND_RECEIPT_DOCUMENTS_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'sendReceiptCancellationRequest',
    action: SEND_RECEIPT_CANCELLATION_DOCUMENTS,
    endPoint: 'documents/send-receipt-cancellation/{id}',
    mutations: {
      fail: SEND_RECEIPT_CANCELLATION_DOCUMENTS_FAIL,
      success: SEND_RECEIPT_CANCELLATION_DOCUMENTS_SUCCESS,
    },
  },
  {
    name: 'getFeedsRegisterRequest',
    action: GET_FEEDS_REGISTER,
    endPoint: 'documents/feeds-register',
    mutations: {
      fail: GET_FEEDS_REGISTER_FAIL,
      success: GET_FEEDS_REGISTER_SUCCESS
    }
  },
  {
    name: 'sendDocumentRequest',
    action: SEND_DOCUMENT_EMAIL,
    endPoint: 'documents/send-by-email/{id}',
    mutations: {
      fail: SEND_DOCUMENT_EMAIL_FAIL,
      success: SEND_DOCUMENT_EMAIL_SUCCESS
    }
  },
  {
    method: 'post',
    name: 'sendDocumentRequest',
    action: SEND_DOCUMENT_SDI,
    endPoint: 'documents/send-to-sdi/{id}',
    mutations: {
      fail: SEND_DOCUMENT_SDI_FAIL,
      success: SEND_DOCUMENT_SDI_SUCCESS
    }
  },
  {
    method: 'post',
    name: 'sendQuickbooksRequest',
    action: SEND_DOCUMENT_QUICKBOOKS,
    endPoint: 'documents/send-to-quickbooks/{id}',
    mutations: {
      fail: SEND_DOCUMENT_QUICKBOOKS_FAIL,
      success: SEND_DOCUMENT_QUICKBOOKS_SUCCESS
    }
  },
  {
    method: 'post',
    name: 'sendQuickbooksBulkRequest',
    action: SEND_DOCUMENT_QUICKBOOKS_BULK,
    endPoint: 'documents/send-to-quickbooks-bulk',
    mutations: {
      fail: SEND_DOCUMENT_QUICKBOOKS_BULK_FAIL,
      success: SEND_DOCUMENT_QUICKBOOKS_BULK_SUCCESS
    }
  }
]);

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  [EXPORT_PDF_DOCUMENTS](context, id) {
    ApiService.download(`documents/export-pdf/${id}`, true);
  },
  [EXPORT_XML_DOCUMENTS](context, id) {
    ApiService.download(`documents/export-xml/${id}`, true);
  },
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
