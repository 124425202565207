import { mapState } from "vuex";

export default {
  methods: {

    /**
     * Get translation
     *
     * @param key
     * @param params
     * @returns {*}
     */
    $lang(key, params) {
      let translations = this.translations;

      if (this.$isEmpty(translations)) {
        return "";
      }

      const keys = key.split(".");

      keys.map(key => {
        if (typeof translations[key] === "undefined") {
          translations = key;
          return false;
        }

        translations = translations[key];
      });

      if (typeof params !== 'object') {
        return translations;
      }

      for (let i in params) {
        translations = translations.replace(`:${i}`, params[i]);
      }

      return translations;
    }
  },
  computed: {
    ...mapState({
      translations: state => state.app.translations
    })
  }
};
