import crud from "@/store/core/crud";
import action from "@/store/core/action";
import ApiService from "@/services/api";
import BroadcastService from "@/services/broadcast";

import {
  GET_RESERVATIONS_PAGINATION,
  GET_RESERVATIONS_PAGINATION_SUCCESS,
  GET_RESERVATIONS_PAGINATION_FAIL,
  GET_RESERVATIONS_COLLECTION,
  GET_RESERVATIONS_COLLECTION_SUCCESS,
  GET_RESERVATIONS_COLLECTION_FAIL,
  GET_RESERVATIONS_SINGLE,
  GET_RESERVATIONS_SINGLE_SUCCESS,
  GET_RESERVATIONS_SINGLE_FAIL,
  CREATE_RESERVATIONS,
  CREATE_RESERVATIONS_SUCCESS,
  CREATE_RESERVATIONS_FAIL,
  CREATE_RESERVATIONS_RESET,
  UPDATE_RESERVATIONS,
  UPDATE_RESERVATIONS_SUCCESS,
  UPDATE_RESERVATIONS_FAIL,
  UPDATE_RESERVATIONS_RESET,
  REPLACE_RESERVATIONS,
  REPLACE_RESERVATIONS_SUCCESS,
  REPLACE_RESERVATIONS_FAIL,
  REPLACE_RESERVATIONS_RESET,
  REMOVE_RESERVATIONS,
  REMOVE_RESERVATIONS_SUCCESS,
  REMOVE_RESERVATIONS_FAIL,
  CREATE_RESERVATIONS_WIZARD,
  CREATE_RESERVATIONS_WIZARD_SUCCESS,
  CREATE_RESERVATIONS_WIZARD_FAIL,
  CREATE_RESERVATIONS_WIZARD_RESET,
  CREATE_RESERVATIONS_MULTIPLE,
  CREATE_RESERVATIONS_MULTIPLE_SUCCESS,
  CREATE_RESERVATIONS_MULTIPLE_FAIL,
  CREATE_RESERVATIONS_MULTIPLE_RESET,
  SEARCH_RESERVATIONS,
  SEARCH_RESERVATIONS_SUCCESS,
  SEARCH_RESERVATIONS_FAIL,
  GET_RESERVATIONS_UNASSIGNED,
  GET_RESERVATIONS_UNASSIGNED_SUCCESS,
  GET_RESERVATIONS_UNASSIGNED_FAIL,
  LISTEN_RESERVATIONS_UNASSIGNED,
  SPLIT_RESERVATIONS,
  SPLIT_RESERVATIONS_SUCCESS,
  SPLIT_RESERVATIONS_FAIL,
  SPLIT_RESERVATIONS_RESET,
  DELETE_SPLIT_RESERVATIONS,
  DELETE_SPLIT_RESERVATIONS_SUCCESS,
  DELETE_SPLIT_RESERVATIONS_FAIL,
  DELETE_SPLIT_RESERVATIONS_RESET,
  IMPORT_XLS_RESERVATIONS,
  IMPORT_XLS_RESERVATIONS_SUCCESS,
  IMPORT_XLS_RESERVATIONS_FAIL,
  IMPORT_XLS_RESERVATIONS_RESET,
  FORCE_REMOVE_RESERVATIONS,
  FORCE_REMOVE_RESERVATIONS_FAIL,
  FORCE_REMOVE_RESERVATIONS_SUCCESS,
  GET_RESERVATION_CHANGES,
  GET_RESERVATION_CHANGES_SUCCESS,
  GET_RESERVATION_CHANGES_FAIL,
  GET_RESERVATION_CHANGES_RESET,
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_RESERVATIONS_PAGINATION,
    endPoint: "reservations/paginated",
    mutationFail: GET_RESERVATIONS_PAGINATION_FAIL,
    mutationSuccess: GET_RESERVATIONS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_RESERVATIONS_COLLECTION,
    endPoint: "reservations/collected",
    mutationFail: GET_RESERVATIONS_COLLECTION_FAIL,
    mutationSuccess: GET_RESERVATIONS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_RESERVATIONS_SINGLE,
    endPoint: "reservations/single",
    mutationFail: GET_RESERVATIONS_SINGLE_FAIL,
    mutationSuccess: GET_RESERVATIONS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_RESERVATIONS,
    actionReset: CREATE_RESERVATIONS_RESET,
    endPoint: "reservations/create",
    mutationFail: CREATE_RESERVATIONS_FAIL,
    mutationSuccess: CREATE_RESERVATIONS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_RESERVATIONS,
    actionReset: UPDATE_RESERVATIONS_RESET,
    endPoint: "reservations/update",
    mutationFail: UPDATE_RESERVATIONS_FAIL,
    mutationSuccess: UPDATE_RESERVATIONS_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_RESERVATIONS,
    actionReset: REPLACE_RESERVATIONS_RESET,
    endPoint: "reservations/replace",
    mutationFail: REPLACE_RESERVATIONS_FAIL,
    mutationSuccess: REPLACE_RESERVATIONS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_RESERVATIONS,
    endPoint: "reservations/delete",
    mutationFail: REMOVE_RESERVATIONS_FAIL,
    mutationSuccess: REMOVE_RESERVATIONS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    method: 'post',
    name: 'splitRequest',
    action: SPLIT_RESERVATIONS,
    actionReset: SPLIT_RESERVATIONS_RESET,
    endPoint: 'reservations/split/{id}',
    mutations: {
      fail: SPLIT_RESERVATIONS_FAIL,
      success: SPLIT_RESERVATIONS_SUCCESS,
    },
  },
  {
    method: 'delete',
    name: 'splitDeleteRequest',
    action: DELETE_SPLIT_RESERVATIONS,
    actionReset: DELETE_SPLIT_RESERVATIONS_RESET,
    endPoint: 'reservations/split/{id}',
    mutations: {
      fail: DELETE_SPLIT_RESERVATIONS_FAIL,
      success: DELETE_SPLIT_RESERVATIONS_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'creationMultipleRequest',
    action: CREATE_RESERVATIONS_MULTIPLE,
    actionReset: CREATE_RESERVATIONS_MULTIPLE_RESET,
    endPoint: 'reservations/create-multiple',
    mutations: {
      fail: CREATE_RESERVATIONS_MULTIPLE_FAIL,
      success: CREATE_RESERVATIONS_MULTIPLE_SUCCESS,
    },
  },
  {
    method: 'postUpload',
    name: 'importXlsReservationsRequest',
    action: IMPORT_XLS_RESERVATIONS,
    actionReset: IMPORT_XLS_RESERVATIONS_RESET,
    endPoint: 'reservations/import/xls',
    mutations: {
      fail: IMPORT_XLS_RESERVATIONS_FAIL,
      success: IMPORT_XLS_RESERVATIONS_SUCCESS,
    },
  },
  {
    method: 'delete',
    name: 'forceDeleteRequest',
    action: FORCE_REMOVE_RESERVATIONS,
    endPoint: 'reservations/force-delete/{id}',
    mutations: {
      fail: FORCE_REMOVE_RESERVATIONS_FAIL,
      success: FORCE_REMOVE_RESERVATIONS_SUCCESS,
    },
  },
  {
    method: 'query',
    name: 'reservationChangesRequest',
    action: GET_RESERVATION_CHANGES,
    endPoint: 'reservations/{id}/changes',
    mutations: {
      fail: GET_RESERVATION_CHANGES_FAIL,
      success: GET_RESERVATION_CHANGES_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  creationWizardRequest: {
    loading: false,
    success: false,
    fail: false,
    validation: {},
    response: {}
  },
  getAmountDetailsPaginationRequest: {
    loading: false,
    success: false,
    fail: false,
    collection: [],
    pagination: {
      page: 1
    },
    errors: {}
  },
  getSearchRequest: {
    loading: false,
    success: false,
    fail: false,
    collection: [],
    errors: {}
  },
  getUnassignedRequest: {
    loading: false,
    success: false,
    fail: false,
    collection: [],
    errors: {}
  },
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  [CREATE_RESERVATIONS_WIZARD_RESET](context) {
    context.state.creationWizardRequest = {
      loading: false,
      success: false,
      fail: false,
      validation: {},
      response: {}
    };
  },
  [CREATE_RESERVATIONS_WIZARD](context, params) {
    context.state.creationWizardRequest.loading = true;

    return new Promise((resolve, reject) => {
      ApiService.post(`reservations/create-wizard`, params)
        .then(response => {
          context.commit(CREATE_RESERVATIONS_WIZARD_SUCCESS, response.data.data);
          resolve(response.data.data);
        })
        .catch(({ response }) => {
          context.commit(CREATE_RESERVATIONS_WIZARD_FAIL, response.data);
          reject(response.data);
        });
    });
  },
  [SEARCH_RESERVATIONS](context, params) {
    context.state.getSearchRequest.loading = true;

    ApiService.query('reservations/collected', params)
      .then(response => {
        context.commit(
          SEARCH_RESERVATIONS_SUCCESS,
          response.data.data
        );
      })
      .catch(response => {
        context.commit(SEARCH_RESERVATIONS_FAIL, response);
      });
  },
  [GET_RESERVATIONS_UNASSIGNED](context, params) {
    context.state.getUnassignedRequest.loading = true;

    ApiService.query('reservations/paginated', params)
      .then(response => {
        context.commit(
          GET_RESERVATIONS_UNASSIGNED_SUCCESS,
          response.data.data
        );
      })
      .catch(response => {
        context.commit(GET_RESERVATIONS_UNASSIGNED_FAIL, response);
      });
  },
  [LISTEN_RESERVATIONS_UNASSIGNED] (context) {
    BroadcastService.listen('reservations', '.unassigned.changed', (data) => {
      context.commit(GET_RESERVATIONS_UNASSIGNED_SUCCESS, data);
    });
  },
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  [CREATE_RESERVATIONS_WIZARD_SUCCESS](state, data) {
    state.creationWizardRequest = {
      loading: false,
      success: true,
      fail: false,
      validation: {},
      response: data
    };
  },
  [CREATE_RESERVATIONS_WIZARD_FAIL](state, data) {
    state.creationWizardRequest = {
      loading: false,
      success: false,
      fail: true,
      validation: data.errors,
      response: {}
    };
  },
  [SEARCH_RESERVATIONS_SUCCESS](state, data) {
    state.getSearchRequest = {
      loading: false,
      success: true,
      fail: false,
      collection: data.collection,
      errors: {}
    };
  },
  [SEARCH_RESERVATIONS_FAIL](state, data) {
    state.getSearchRequest = {
      loading: false,
      success: false,
      fail: true,
      collection: [],
      errors: data.errors
    };
  },
  [GET_RESERVATIONS_UNASSIGNED_SUCCESS](state, data) {
    state.getUnassignedRequest = {
      loading: false,
      success: true,
      fail: false,
      collection: data.collection,
      pagination: data.pagination,
      errors: {}
    };
  },
  [GET_RESERVATIONS_UNASSIGNED_FAIL](state, data) {
    state.getUnassignedRequest = {
      loading: false,
      success: false,
      fail: true,
      collection: [],
      pagination: [],
      errors: data.errors
    };
  },
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
