export const GET_CLIENTS_PAGINATION = "[CLIENTS] Pagination Get";
export const GET_CLIENTS_PAGINATION_SUCCESS = "[CLIENTS] Pagination Get Success";
export const GET_CLIENTS_PAGINATION_FAIL = "[CLIENTS] Pagination Get Fail";

export const GET_CLIENTS_COLLECTION = "[CLIENTS] Collection Get";
export const GET_CLIENTS_COLLECTION_SUCCESS = "[CLIENTS] Collection Get Success";
export const GET_CLIENTS_COLLECTION_FAIL = "[CLIENTS] Collection Get Fail";

export const GET_CLIENTS_SINGLE = "[CLIENTS] Single Get";
export const GET_CLIENTS_SINGLE_SUCCESS = "[CLIENTS] Single Get Success";
export const GET_CLIENTS_SINGLE_FAIL = "[CLIENTS] Single Get Fail";

export const CREATE_CLIENTS = "[CLIENTS] Create";
export const CREATE_CLIENTS_SUCCESS = "[CLIENTS] Create Success";
export const CREATE_CLIENTS_FAIL = "[CLIENTS] Create Fail";
export const CREATE_CLIENTS_RESET = "[CLIENTS] Create Reset";

export const UPDATE_CLIENTS = "[CLIENTS] Update";
export const UPDATE_CLIENTS_SUCCESS = "[CLIENTS] Update Success";
export const UPDATE_CLIENTS_FAIL = "[CLIENTS] Update Fail";
export const UPDATE_CLIENTS_RESET = "[CLIENTS] Update Reset";

export const REMOVE_CLIENTS = "[CLIENTS] Remove";
export const REMOVE_CLIENTS_SUCCESS = "[CLIENTS] Remove Success";
export const REMOVE_CLIENTS_FAIL = "[CLIENTS] Remove Fail";

export const GET_CLIENTS_DUPLICATES = "[CLIENTS] Duplicates Get";
export const GET_CLIENTS_DUPLICATES_SUCCESS = "[CLIENTS] Duplicates Get Success";
export const GET_CLIENTS_DUPLICATES_FAIL = "[CLIENTS] Duplicates Get Fail";

export const MERGE_CLIENTS_DUPLICATES = "[CLIENTS] Duplicates Merge";
export const MERGE_CLIENTS_DUPLICATES_SUCCESS = "[CLIENTS] Duplicates Merge Success";
export const MERGE_CLIENTS_DUPLICATES_FAIL = "[CLIENTS] Duplicates Merge Fail";

export const MERGE_CLIENTS_DUPLICATES_ALL = "[CLIENTS] Duplicates All Merge";
export const MERGE_CLIENTS_DUPLICATES_ALL_SUCCESS = "[CLIENTS] Duplicates All Merge Success";
export const MERGE_CLIENTS_DUPLICATES_ALL_FAIL = "[CLIENTS] Duplicates All Merge Fail";
