export const GET_WAREHOUSES_LIST = "[WAREHOUSES] List Get";
export const GET_WAREHOUSES_LIST_SUCCESS = "[WAREHOUSES] List Get Success";
export const GET_WAREHOUSES_LIST_FAIL = "[WAREHOUSES] List Get Fail";

export const GET_WAREHOUSES_PAGINATION = "[WAREHOUSES] Pagination Get";
export const GET_WAREHOUSES_PAGINATION_SUCCESS = "[WAREHOUSES] Pagination Get Success";
export const GET_WAREHOUSES_PAGINATION_FAIL = "[WAREHOUSES] Pagination Get Fail";

export const GET_WAREHOUSES_COLLECTION = "[WAREHOUSES] Collection Get";
export const GET_WAREHOUSES_COLLECTION_SUCCESS = "[WAREHOUSES] Collection Get Success";
export const GET_WAREHOUSES_COLLECTION_FAIL = "[WAREHOUSES] Collection Get Fail";

export const GET_WAREHOUSES_SINGLE = "[WAREHOUSES] Single Get";
export const GET_WAREHOUSES_SINGLE_SUCCESS = "[WAREHOUSES] Single Get Success";
export const GET_WAREHOUSES_SINGLE_FAIL = "[WAREHOUSES] Single Get Fail";

export const CREATE_WAREHOUSES = "[WAREHOUSES] Create";
export const CREATE_WAREHOUSES_SUCCESS = "[WAREHOUSES] Create Success";
export const CREATE_WAREHOUSES_FAIL = "[WAREHOUSES] Create Fail";
export const CREATE_WAREHOUSES_RESET = "[WAREHOUSES] Create Reset";

export const UPDATE_WAREHOUSES = "[WAREHOUSES] Update";
export const UPDATE_WAREHOUSES_SUCCESS = "[WAREHOUSES] Update Success";
export const UPDATE_WAREHOUSES_FAIL = "[WAREHOUSES] Update Fail";
export const UPDATE_WAREHOUSES_RESET = "[WAREHOUSES] Update Reset";

export const REPLACE_WAREHOUSES = "[WAREHOUSES] Replace";
export const REPLACE_WAREHOUSES_SUCCESS = "[WAREHOUSES] Replace Success";
export const REPLACE_WAREHOUSES_FAIL = "[WAREHOUSES] Replace Fail";
export const REPLACE_WAREHOUSES_RESET = "[WAREHOUSES] Replace Reset";

export const REMOVE_WAREHOUSES = "[WAREHOUSES] Remove";
export const REMOVE_WAREHOUSES_SUCCESS = "[WAREHOUSES] Remove Success";
export const REMOVE_WAREHOUSES_FAIL = "[WAREHOUSES] Remove Fail";
