import { mapState } from "vuex";

export default {
  data() {
    return {
      payableSources: [],
    }
  },
  methods: {
    initPayableSources() {
      if (this.ownerType !== this.$const('ConstMorphTypeAliases', 'RESERVATION')) {
        return;
      }
      if (this.ownerId !== this.reservation.id) {
        return;
      }
      if (this.reservation.ccs_token) {
        return this.payableSources = this.sources.filter(source => {
          return source.key === 'stripe' ||
            source.key === 'stripe_2' ||
            source.key === 'nexi';
        });
      }
    },
  },
  computed: {
    ...mapState({
      sourcesRequest: state => state.paymentSources.getCollectionRequest,
      sources: state => state.paymentSources.getCollectionRequest.collection,
      loading: state => state.paymentSources.getCollectionRequest.loading,
    }),
    isPayable() {
      return this.payableSources.filter(source => source.id === this.form.source_id).length > 0 &&
        this.form.type == this.$const('ConstPaymentTypes', 'CASH_IN');
    },
    isPlannable() {
      let plannableSources = this.sources.filter(source => {
        return source.key == this.$const('ConstPaymentSources', 'STRIPE_SEPA')
      });

      return plannableSources.find(item => {
        return this.form.source_id === item.id
      });
    },
  },
  watch: {
    sourcesRequest(yes) {
      if (yes) {
        return;
      }

      this.initPayableSources();
    },
  }
};
