export const GET_USERS_LIST = "[USERS] List Get";
export const GET_USERS_LIST_SUCCESS = "[USERS] List Get Success";
export const GET_USERS_LIST_FAIL = "[USERS] List Get Fail";

export const GET_USERS_PAGINATION = "[USERS] Pagination Get";
export const GET_USERS_PAGINATION_SUCCESS = "[USERS] Pagination Get Success";
export const GET_USERS_PAGINATION_FAIL = "[USERS] Pagination Get Fail";

export const GET_USERS_COLLECTION = "[USERS] Collection Get";
export const GET_USERS_COLLECTION_SUCCESS = "[USERS] Collection Get Success";
export const GET_USERS_COLLECTION_FAIL = "[USERS] Collection Get Fail";

export const GET_USERS_SINGLE = "[USERS] Single Get";
export const GET_USERS_SINGLE_SUCCESS = "[USERS] Single Get Success";
export const GET_USERS_SINGLE_FAIL = "[USERS] Single Get Fail";

export const CREATE_USERS = "[USERS] Create";
export const CREATE_USERS_SUCCESS = "[USERS] Create Success";
export const CREATE_USERS_FAIL = "[USERS] Create Fail";
export const CREATE_USERS_RESET = "[USERS] Create Reset";

export const UPDATE_USERS = "[USERS] Update";
export const UPDATE_USERS_SUCCESS = "[USERS] Update Success";
export const UPDATE_USERS_FAIL = "[USERS] Update Fail";
export const UPDATE_USERS_RESET = "[USERS] Update Reset";

export const REPLACE_USERS = "[USERS] Replace";
export const REPLACE_USERS_SUCCESS = "[USERS] Replace Success";
export const REPLACE_USERS_FAIL = "[USERS] Replace Fail";
export const REPLACE_USERS_RESET = "[USERS] Replace Reset";

export const REMOVE_USERS = "[USERS] Remove";
export const REMOVE_USERS_SUCCESS = "[USERS] Remove Success";
export const REMOVE_USERS_FAIL = "[USERS] Remove Fail";

export const DETACH_USER = "[USERS] Detach";
export const DETACH_USER_SUCCESS = "[USERS] Detach Success";
export const DETACH_USER_FAIL = "[USERS] Detach Fail";

export const GET_USER_TELEGRAM_ID = "[USERS] Telegram ID";
export const GET_USER_TELEGRAM_ID_SUCCESS = "[USERS] Telegram ID Success";
export const GET_USER_TELEGRAM_ID_FAIL = "[USERS] Telegram ID Fail";

export const SEND_WEBPUSH_TEST_NOTIFICATION = "[USERS] Send Webpush send notification";
export const SEND_WEBPUSH_TEST_NOTIFICATION_SUCCESS = "[USERS] Send Webpush send notification Success";
export const SEND_WEBPUSH_TEST_NOTIFICATION_FAIL = "[USERS] Send Webpush send notification Success";
