import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_WORKERS_LIST,
  GET_WORKERS_LIST_SUCCESS,
  GET_WORKERS_LIST_FAIL,
  GET_WORKERS_PAGINATION,
  GET_WORKERS_PAGINATION_SUCCESS,
  GET_WORKERS_PAGINATION_FAIL,
  GET_WORKERS_COLLECTION,
  GET_WORKERS_COLLECTION_SUCCESS,
  GET_WORKERS_COLLECTION_FAIL,
  GET_WORKERS_SINGLE,
  GET_WORKERS_SINGLE_SUCCESS,
  GET_WORKERS_SINGLE_FAIL,
  CREATE_WORKERS,
  CREATE_WORKERS_SUCCESS,
  CREATE_WORKERS_FAIL,
  CREATE_WORKERS_RESET,
  UPDATE_WORKERS,
  UPDATE_WORKERS_SUCCESS,
  UPDATE_WORKERS_FAIL,
  UPDATE_WORKERS_RESET,
  REMOVE_WORKERS,
  REMOVE_WORKERS_SUCCESS,
  REMOVE_WORKERS_FAIL
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_WORKERS_LIST,
    endPoint: "workers/list",
    mutationFail: GET_WORKERS_LIST_FAIL,
    mutationSuccess: GET_WORKERS_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_WORKERS_PAGINATION,
    endPoint: "workers/paginated",
    mutationFail: GET_WORKERS_PAGINATION_FAIL,
    mutationSuccess: GET_WORKERS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_WORKERS_COLLECTION,
    endPoint: "workers/collected",
    mutationFail: GET_WORKERS_COLLECTION_FAIL,
    mutationSuccess: GET_WORKERS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_WORKERS_SINGLE,
    endPoint: "workers/single",
    mutationFail: GET_WORKERS_SINGLE_FAIL,
    mutationSuccess: GET_WORKERS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_WORKERS,
    actionReset: CREATE_WORKERS_RESET,
    endPoint: "workers/create",
    mutationFail: CREATE_WORKERS_FAIL,
    mutationSuccess: CREATE_WORKERS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_WORKERS,
    actionReset: UPDATE_WORKERS_RESET,
    endPoint: "workers/update",
    mutationFail: UPDATE_WORKERS_FAIL,
    mutationSuccess: UPDATE_WORKERS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_WORKERS,
    endPoint: "workers/delete",
    mutationFail: REMOVE_WORKERS_FAIL,
    mutationSuccess: REMOVE_WORKERS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
