import action from "@/store/core/action";

import {
  GET_BOOKING_ENGINE_COMBINATIONS,
  GET_BOOKING_ENGINE_COMBINATIONS_SUCCESS,
  GET_BOOKING_ENGINE_COMBINATIONS_FAIL,
  GET_BOOKING_ENGINE_COMBINATIONS_RESET,
  GET_BOOKING_ENGINE_ROOM_TYPES,
  GET_BOOKING_ENGINE_ROOM_TYPES_SUCCESS,
  GET_BOOKING_ENGINE_ROOM_TYPES_FAIL,
  GET_BOOKING_ENGINE_ROOM_TYPES_RESET,
  GET_BOOKING_ENGINE_QUOTE_COMBINATIONS,
  GET_BOOKING_ENGINE_QUOTE_COMBINATIONS_SUCCESS,
  GET_BOOKING_ENGINE_QUOTE_COMBINATIONS_FAIL,
  GET_BOOKING_ENGINE_QUOTE_COMBINATIONS_RESET,
} from "./actions";

const actionsProcessed = action.processMultiple([
  {
    name: 'getCombinationsRequest',
    action: GET_BOOKING_ENGINE_COMBINATIONS,
    actionReset: GET_BOOKING_ENGINE_COMBINATIONS_RESET,
    endPoint: 'booking-engine/combinations',
    mutations: {
      fail: GET_BOOKING_ENGINE_COMBINATIONS_FAIL,
      success: GET_BOOKING_ENGINE_COMBINATIONS_SUCCESS,
      successCallback(state, data) {
        if (data.pagination.page > 1) {
          data.collection = [
            ...state.getCombinationsRequest.data.collection,
            ...data.collection,
          ];
        }

        state.getCombinationsRequest = {
          loading: false,
          success: true,
          fail: false,
          data: data,
          errors: {},
        };
      }
    },
  },
  {
    name: 'getQuoteCombinationsRequest',
    action: GET_BOOKING_ENGINE_QUOTE_COMBINATIONS,
    actionReset: GET_BOOKING_ENGINE_QUOTE_COMBINATIONS_RESET,
    endPoint: 'booking-engine/get-combinations',
    mutations: {
      fail: GET_BOOKING_ENGINE_QUOTE_COMBINATIONS_FAIL,
      success: GET_BOOKING_ENGINE_QUOTE_COMBINATIONS_SUCCESS,
      successCallback(state, data) {
        if (data.pagination.page > 1) {
          data.collection = [
            ...state.getQuoteCombinationsRequest.data.collection,
            ...data.collection,
          ];
        }

        state.getQuoteCombinationsRequest = {
          loading: false,
          success: true,
          fail: false,
          data: data,
          errors: {},
        };
      }
    },
  },
  {
    name: 'getRoomTypesRequest',
    action: GET_BOOKING_ENGINE_ROOM_TYPES,
    actionReset: GET_BOOKING_ENGINE_ROOM_TYPES_RESET,
    endPoint: 'booking-engine/room-types',
    mutations: {
      fail: GET_BOOKING_ENGINE_ROOM_TYPES_FAIL,
      success: GET_BOOKING_ENGINE_ROOM_TYPES_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  ...actionsProcessed.state,
};

const actions = {
  ...actionsProcessed.actions,
};

const mutations = {
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
