export default [
  {
    name: "dashboard",
    path: "/",
    component: () => import(/*webpackChunkName: "dashboard", webpackPreload: true */ "@/pages/dashboard/Dashboard"),
    meta: {
      title: 'titles.dashboard',
      loginRedirect: true
    }
  }
];
