export const GET_PROPERTY_META_LIST = "[PROPERTY_META] List Get";
export const GET_PROPERTY_META_LIST_SUCCESS = "[PROPERTY_META] List Get Success";
export const GET_PROPERTY_META_LIST_FAIL = "[PROPERTY_META] List Get Fail";

export const GET_PROPERTY_META_PAGINATION = "[PROPERTY_META] Pagination Get";
export const GET_PROPERTY_META_PAGINATION_SUCCESS = "[PROPERTY_META] Pagination Get Success";
export const GET_PROPERTY_META_PAGINATION_FAIL = "[PROPERTY_META] Pagination Get Fail";

export const GET_PROPERTY_META_COLLECTION = "[PROPERTY_META] Collection Get";
export const GET_PROPERTY_META_COLLECTION_SUCCESS = "[PROPERTY_META] Collection Get Success";
export const GET_PROPERTY_META_COLLECTION_FAIL = "[PROPERTY_META] Collection Get Fail";

export const GET_PROPERTY_META_SINGLE = "[PROPERTY_META] Single Get";
export const GET_PROPERTY_META_SINGLE_SUCCESS = "[PROPERTY_META] Single Get Success";
export const GET_PROPERTY_META_SINGLE_FAIL = "[PROPERTY_META] Single Get Fail";

export const CREATE_PROPERTY_META = "[PROPERTY_META] Create";
export const CREATE_PROPERTY_META_SUCCESS = "[PROPERTY_META] Create Success";
export const CREATE_PROPERTY_META_FAIL = "[PROPERTY_META] Create Fail";
export const CREATE_PROPERTY_META_RESET = "[PROPERTY_META] Create Reset";

export const UPDATE_PROPERTY_META = "[PROPERTY_META] Update";
export const UPDATE_PROPERTY_META_SUCCESS = "[PROPERTY_META] Update Success";
export const UPDATE_PROPERTY_META_FAIL = "[PROPERTY_META] Update Fail";
export const UPDATE_PROPERTY_META_RESET = "[PROPERTY_META] Update Reset";

export const REPLACE_PROPERTY_META = "[PROPERTY_META] Replace";
export const REPLACE_PROPERTY_META_SUCCESS = "[PROPERTY_META] Replace Success";
export const REPLACE_PROPERTY_META_FAIL = "[PROPERTY_META] Replace Fail";
export const REPLACE_PROPERTY_META_RESET = "[PROPERTY_META] Replace Reset";

export const REMOVE_PROPERTY_META = "[PROPERTY_META] Remove";
export const REMOVE_PROPERTY_META_SUCCESS = "[PROPERTY_META] Remove Success";
export const REMOVE_PROPERTY_META_FAIL = "[PROPERTY_META] Remove Fail";