import action from "@/store/core/action";

import {
  UPLOAD_COMPANIES_GOOGLE_ACCOUNT_JSON,
  UPLOAD_COMPANIES_GOOGLE_ACCOUNT_JSON_RESET,
  UPLOAD_COMPANIES_GOOGLE_ACCOUNT_JSON_SUCCESS,
  UPLOAD_COMPANIES_GOOGLE_ACCOUNT_JSON_FAIL,
  VERIFY_COMPANIES_GOOGLE_ACCOUNT,
  VERIFY_COMPANIES_GOOGLE_ACCOUNT_RESET,
  VERIFY_COMPANIES_GOOGLE_ACCOUNT_SUCCESS,
  VERIFY_COMPANIES_GOOGLE_ACCOUNT_FAIL,
  UPLOAD_COMPANIES_GOOGLE_SERVICE_ACCOUNT_JSON,
  UPLOAD_COMPANIES_GOOGLE_SERVICE_ACCOUNT_JSON_RESET,
  UPLOAD_COMPANIES_GOOGLE_SERVICE_ACCOUNT_JSON_SUCCESS,
  UPLOAD_COMPANIES_GOOGLE_SERVICE_ACCOUNT_JSON_FAIL,
  SYNC_COMPANIES_GOOGLE_CONTACTS,
  SYNC_COMPANIES_GOOGLE_CONTACTS_RESET,
  SYNC_COMPANIES_GOOGLE_CONTACTS_FAIL,
  SYNC_COMPANIES_GOOGLE_CONTACTS_SUCCESS,
} from "./actions";

const actionsProcessed = action.processMultiple([
  {
    method: 'postUpload',
    name: 'uploadCompaniesGoogleAccountJsonRequest',
    action: UPLOAD_COMPANIES_GOOGLE_ACCOUNT_JSON,
    actionReset: UPLOAD_COMPANIES_GOOGLE_ACCOUNT_JSON_RESET,
    endPoint: "companies/{id}/google-account-json-upload",
    mutations: {
      fail: UPLOAD_COMPANIES_GOOGLE_ACCOUNT_JSON_FAIL,
      success: UPLOAD_COMPANIES_GOOGLE_ACCOUNT_JSON_SUCCESS,
    },
  },
  {
    name: 'verifyCompaniesGoogleAccountRequest',
    action: VERIFY_COMPANIES_GOOGLE_ACCOUNT,
    actionReset: VERIFY_COMPANIES_GOOGLE_ACCOUNT_RESET,
    endPoint: "companies/{id}/google-account-verify",
    mutations: {
      fail: VERIFY_COMPANIES_GOOGLE_ACCOUNT_FAIL,
      success: VERIFY_COMPANIES_GOOGLE_ACCOUNT_SUCCESS,
    },
  },
  {
    method: 'postUpload',
    name: 'uploadCompaniesGoogleServiceAccountJsonRequest',
    action: UPLOAD_COMPANIES_GOOGLE_SERVICE_ACCOUNT_JSON,
    actionReset: UPLOAD_COMPANIES_GOOGLE_SERVICE_ACCOUNT_JSON_RESET,
    endPoint: "companies/{id}/google-service-account-json-upload",
    mutations: {
      fail: UPLOAD_COMPANIES_GOOGLE_SERVICE_ACCOUNT_JSON_FAIL,
      success: UPLOAD_COMPANIES_GOOGLE_SERVICE_ACCOUNT_JSON_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'syncCompanyGoogleContactsRequest',
    action: SYNC_COMPANIES_GOOGLE_CONTACTS,
    actionReset: SYNC_COMPANIES_GOOGLE_CONTACTS_RESET,
    endPoint: "companies/{id}/google-sync-contacts",
    mutations: {
      fail: SYNC_COMPANIES_GOOGLE_CONTACTS_FAIL,
      success: SYNC_COMPANIES_GOOGLE_CONTACTS_SUCCESS,
    },
  },
]);

const getters = {};

const actions = {
  ...actionsProcessed.actions,
};

const state = {
  ...actionsProcessed.state,
};

const mutations = {
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
