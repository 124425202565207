import list from "@/store/core/list";
import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_HELPS_LIST,
  GET_HELPS_LIST_SUCCESS,
  GET_HELPS_LIST_FAIL,
  GET_HELPS_PAGINATION,
  GET_HELPS_PAGINATION_SUCCESS,
  GET_HELPS_PAGINATION_FAIL,
  GET_HELPS_COLLECTION,
  GET_HELPS_COLLECTION_SUCCESS,
  GET_HELPS_COLLECTION_FAIL,
  GET_HELPS_SINGLE,
  GET_HELPS_SINGLE_SUCCESS,
  GET_HELPS_SINGLE_FAIL,
  CREATE_HELPS,
  CREATE_HELPS_SUCCESS,
  CREATE_HELPS_FAIL,
  CREATE_HELPS_RESET,
  UPDATE_HELPS,
  UPDATE_HELPS_SUCCESS,
  UPDATE_HELPS_FAIL,
  UPDATE_HELPS_RESET,
  REMOVE_HELPS,
  REMOVE_HELPS_SUCCESS,
  REMOVE_HELPS_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_HELPS_LIST,
    endPoint: "helps/list",
    mutationFail: GET_HELPS_LIST_FAIL,
    mutationSuccess: GET_HELPS_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_HELPS_PAGINATION,
    endPoint: "helps/paginated",
    mutationFail: GET_HELPS_PAGINATION_FAIL,
    mutationSuccess: GET_HELPS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_HELPS_COLLECTION,
    endPoint: "helps/collected",
    mutationFail: GET_HELPS_COLLECTION_FAIL,
    mutationSuccess: GET_HELPS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_HELPS_SINGLE,
    endPoint: "helps/single",
    mutationFail: GET_HELPS_SINGLE_FAIL,
    mutationSuccess: GET_HELPS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_HELPS,
    actionReset: CREATE_HELPS_RESET,
    endPoint: "helps/create",
    mutationFail: CREATE_HELPS_FAIL,
    mutationSuccess: CREATE_HELPS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_HELPS,
    actionReset: UPDATE_HELPS_RESET,
    endPoint: "helps/update",
    mutationFail: UPDATE_HELPS_FAIL,
    mutationSuccess: UPDATE_HELPS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_HELPS,
    endPoint: "helps/delete",
    mutationFail: REMOVE_HELPS_FAIL,
    mutationSuccess: REMOVE_HELPS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
