import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      userData: state => state.auth.userData,
      isLoggedIn: state => state.auth.isAuthenticated
    })
  }
};
