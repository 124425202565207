<template>
  <span>
    <v-menu offset-y max-height="400px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          :small="small"
          :block="block"
        >
          <v-icon v-if="prependIcon">{{prependIcon}}</v-icon>
          {{$lang('property-message-templates.replacement_tags')}}
        </v-btn>
      </template>
      <v-list :dense="dense">
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="clicked(item)"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </span>
</template>

<script>
import { mapState } from "vuex";

import {
  GET_PROPERTY_MESSAGE_TEMPLATES_VARIABLES,
  GET_PROPERTY_MESSAGE_TEMPLATES_EMAIL_PRESETS,
} from "@/store/property-message-templates/actions";

export default {
  name: "CVariablesMenu",
  props: {
    label: {
      type: String,
      required: false,
      default: ""
    },
    language: {
      default: null
    },
    small: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    prependIcon: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Array,
      default: () => {
        return [];
      }
    },
    options: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      ids: null
    };
  },
  beforeMount() {
    this.getItems();
  },
  methods: {
    getItems() {
      if (this.disabled) {
        return;
      }
      this.$store.dispatch(GET_PROPERTY_MESSAGE_TEMPLATES_VARIABLES, {
        ...this.filters
      });
    },
    clicked(item) {
      this.$emit('input', {
        value: item.value,
        language: this.language
      });
    }
  },
  computed: {
    ...mapState({
      request: state => state.propertyMessageTemplates.getVariablesRequest,
    }),
    items() {
      if (!this.request.data) {
        return [];
      }

      return this.request.data;
    },
    loading() {
      return this.request.loading;
    },
  },
};
</script>
