import action from "@/store/core/action";

import {
  GET_RESERVATION_CALENDAR_DATA,
  GET_RESERVATION_CALENDAR_DATA_SUCCESS,
  GET_RESERVATION_CALENDAR_DATA_FAIL,
} from "./actions";

const actionsProcessed = action.processMultiple([
  {
    name: 'getDataRequest',
    action: GET_RESERVATION_CALENDAR_DATA,
    endPoint: 'reservation-calendar/data',
    mutations: {
      fail: GET_RESERVATION_CALENDAR_DATA_FAIL,
      success: GET_RESERVATION_CALENDAR_DATA_SUCCESS,
      successCallback(state, data) {
        if (data.pagination.page > 1) {
          data.collection = [
            ...state.getDataRequest.data.collection,
            ...data.collection,
          ];
        }

        state.getDataRequest = {
          loading: false,
          success: true,
          fail: false,
          data: data,
          errors: {},
        };
      }
    },
  },
]);

const getters = {};

const state = {
  ...actionsProcessed.state,
};

const actions = {
  ...actionsProcessed.actions,
};

const mutations = {
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
