import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_PROPERTY_MESSAGE_TEMPLATES_PAGINATION,
  GET_PROPERTY_MESSAGE_TEMPLATES_PAGINATION_SUCCESS,
  GET_PROPERTY_MESSAGE_TEMPLATES_PAGINATION_FAIL,
  GET_PROPERTY_MESSAGE_TEMPLATES_COLLECTION,
  GET_PROPERTY_MESSAGE_TEMPLATES_COLLECTION_SUCCESS,
  GET_PROPERTY_MESSAGE_TEMPLATES_COLLECTION_FAIL,
  GET_PROPERTY_MESSAGE_TEMPLATES_SINGLE,
  GET_PROPERTY_MESSAGE_TEMPLATES_SINGLE_SUCCESS,
  GET_PROPERTY_MESSAGE_TEMPLATES_SINGLE_FAIL,
  CREATE_PROPERTY_MESSAGE_TEMPLATES,
  CREATE_PROPERTY_MESSAGE_TEMPLATES_SUCCESS,
  CREATE_PROPERTY_MESSAGE_TEMPLATES_FAIL,
  CREATE_PROPERTY_MESSAGE_TEMPLATES_RESET,
  UPDATE_PROPERTY_MESSAGE_TEMPLATES,
  UPDATE_PROPERTY_MESSAGE_TEMPLATES_SUCCESS,
  UPDATE_PROPERTY_MESSAGE_TEMPLATES_FAIL,
  UPDATE_PROPERTY_MESSAGE_TEMPLATES_RESET,
  DUPLICATE_PROPERTY_MESSAGE_TEMPLATES,
  DUPLICATE_PROPERTY_MESSAGE_TEMPLATES_SUCCESS,
  DUPLICATE_PROPERTY_MESSAGE_TEMPLATES_FAIL,
  DUPLICATE_PROPERTY_MESSAGE_TEMPLATES_RESET,
  REMOVE_PROPERTY_MESSAGE_TEMPLATES,
  REMOVE_PROPERTY_MESSAGE_TEMPLATES_SUCCESS,
  REMOVE_PROPERTY_MESSAGE_TEMPLATES_FAIL,
  GET_PROPERTY_MESSAGE_TEMPLATES_VARIABLES,
  GET_PROPERTY_MESSAGE_TEMPLATES_VARIABLES_SUCCESS,
  GET_PROPERTY_MESSAGE_TEMPLATES_VARIABLES_FAIL,
  GET_PROPERTY_MESSAGE_TEMPLATES_EMAIL_PRESETS,
  GET_PROPERTY_MESSAGE_TEMPLATES_EMAIL_PRESETS_SUCCESS,
  GET_PROPERTY_MESSAGE_TEMPLATES_EMAIL_PRESETS_FAIL,
  PROPERTY_MESSAGE_TEMPLATES_CLONE,
  PROPERTY_MESSAGE_TEMPLATES_CLONE_SUCCESS,
  PROPERTY_MESSAGE_TEMPLATES_CLONE_FAIL,
  CREATE_PROPERTY_DEFAULT_MESSAGE_TEMPLATES,
  CREATE_PROPERTY_DEFAULT_MESSAGE_TEMPLATES_SUCCESS,
  CREATE_PROPERTY_DEFAULT_MESSAGE_TEMPLATES_FAIL,
  REFRESH_PROPERTY_RESERVATIONS_MESSAGES,
  REFRESH_PROPERTY_RESERVATIONS_MESSAGES_SUCCESS,
  REFRESH_PROPERTY_RESERVATIONS_MESSAGES_FAIL,
  CREATE_BULK_PROPERTY_MESSAGE_TEMPLATES,
  CREATE_BULK_PROPERTY_MESSAGE_TEMPLATES_SUCCESS,
  CREATE_BULK_PROPERTY_MESSAGE_TEMPLATES_FAIL,
  CREATE_BULK_PROPERTY_MESSAGE_TEMPLATES_RESET
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_PROPERTY_MESSAGE_TEMPLATES_PAGINATION,
    endPoint: "message-templates/paginated",
    mutationFail: GET_PROPERTY_MESSAGE_TEMPLATES_PAGINATION_FAIL,
    mutationSuccess: GET_PROPERTY_MESSAGE_TEMPLATES_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_PROPERTY_MESSAGE_TEMPLATES_COLLECTION,
    endPoint: "message-templates/collected",
    mutationFail: GET_PROPERTY_MESSAGE_TEMPLATES_COLLECTION_FAIL,
    mutationSuccess: GET_PROPERTY_MESSAGE_TEMPLATES_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_PROPERTY_MESSAGE_TEMPLATES_SINGLE,
    endPoint: "message-templates/single",
    mutationFail: GET_PROPERTY_MESSAGE_TEMPLATES_SINGLE_FAIL,
    mutationSuccess: GET_PROPERTY_MESSAGE_TEMPLATES_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_PROPERTY_MESSAGE_TEMPLATES,
    actionReset: CREATE_PROPERTY_MESSAGE_TEMPLATES_RESET,
    endPoint: "message-templates/create",
    mutationFail: CREATE_PROPERTY_MESSAGE_TEMPLATES_FAIL,
    mutationSuccess: CREATE_PROPERTY_MESSAGE_TEMPLATES_SUCCESS
  },
  editingRequest: {
    action: UPDATE_PROPERTY_MESSAGE_TEMPLATES,
    actionReset: UPDATE_PROPERTY_MESSAGE_TEMPLATES_RESET,
    endPoint: "message-templates/update",
    mutationFail: UPDATE_PROPERTY_MESSAGE_TEMPLATES_FAIL,
    mutationSuccess: UPDATE_PROPERTY_MESSAGE_TEMPLATES_SUCCESS
  },
  duplicateRequest: {
    action: DUPLICATE_PROPERTY_MESSAGE_TEMPLATES,
    actionReset: DUPLICATE_PROPERTY_MESSAGE_TEMPLATES_RESET,
    endPoint: "message-templates/duplicate",
    mutationFail: DUPLICATE_PROPERTY_MESSAGE_TEMPLATES_FAIL,
    mutationSuccess: DUPLICATE_PROPERTY_MESSAGE_TEMPLATES_SUCCESS,
  },
  deletionRequest: {
    action: REMOVE_PROPERTY_MESSAGE_TEMPLATES,
    endPoint: "message-templates/delete",
    mutationFail: REMOVE_PROPERTY_MESSAGE_TEMPLATES_FAIL,
    mutationSuccess: REMOVE_PROPERTY_MESSAGE_TEMPLATES_SUCCESS
  },
  bulkCreationRequest: {
    action: CREATE_BULK_PROPERTY_MESSAGE_TEMPLATES,
    actionReset: CREATE_BULK_PROPERTY_MESSAGE_TEMPLATES_RESET,
    endPoint: "message-templates/bulk-create",
    mutationFail: CREATE_BULK_PROPERTY_MESSAGE_TEMPLATES_FAIL,
    mutationSuccess: CREATE_BULK_PROPERTY_MESSAGE_TEMPLATES_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    name: 'getVariablesRequest',
    action: GET_PROPERTY_MESSAGE_TEMPLATES_VARIABLES,
    endPoint: 'message-templates/variables',
    mutations: {
      fail: GET_PROPERTY_MESSAGE_TEMPLATES_VARIABLES_FAIL,
      success: GET_PROPERTY_MESSAGE_TEMPLATES_VARIABLES_SUCCESS,
    },
  },
  {
    name: 'getEmailPresetsRequest',
    action: GET_PROPERTY_MESSAGE_TEMPLATES_EMAIL_PRESETS,
    endPoint: 'message-templates/email-presets',
    mutations: {
      fail: GET_PROPERTY_MESSAGE_TEMPLATES_EMAIL_PRESETS_FAIL,
      success: GET_PROPERTY_MESSAGE_TEMPLATES_EMAIL_PRESETS_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'cloneRequest',
    action: PROPERTY_MESSAGE_TEMPLATES_CLONE,
    endPoint: 'message-templates/clone',
    mutations: {
      fail: PROPERTY_MESSAGE_TEMPLATES_CLONE_FAIL,
      success: PROPERTY_MESSAGE_TEMPLATES_CLONE_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'createDefaultsRequest',
    action: CREATE_PROPERTY_DEFAULT_MESSAGE_TEMPLATES,
    endPoint: 'message-templates/create-defaults/{property_id}',
    mutations: {
      fail: CREATE_PROPERTY_DEFAULT_MESSAGE_TEMPLATES_FAIL,
      success: CREATE_PROPERTY_DEFAULT_MESSAGE_TEMPLATES_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'refreshMessagesRequest',
    action: REFRESH_PROPERTY_RESERVATIONS_MESSAGES,
    endPoint: 'message-templates/refresh-messages/{property_id}',
    mutations: {
      fail: REFRESH_PROPERTY_RESERVATIONS_MESSAGES_FAIL,
      success: REFRESH_PROPERTY_RESERVATIONS_MESSAGES_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
