export const GET_AUTHORIZATION_URL = '[QUICKBOOKS] Authorization Get';
export const GET_AUTHORIZATION_URL_SUCCESS = '[QUICKBOOKS] Authorization Get Success';
export const GET_AUTHORIZATION_URL_FAIL = '[QUICKBOOKS] Authorization Get Fail';

export const STORE_AUTH = '[QUICKBOOKS] Store auth';
export const STORE_AUTH_SUCCESS = '[QUICKBOOKS] Store auth Success';
export const STORE_AUTH_FAIL = '[QUICKBOOKS] Store auth Fail';

export const REVOKE_AUTH = '[QUICKBOOKS] Revoke auth';
export const REVOKE_AUTH_SUCCESS = '[QUICKBOOKS] Revoke auth Success';
export const REVOKE_AUTH_FAIL = '[QUICKBOOKS] Revoke auth Fail';

export const UPDATE_INSTANCE = '[QUICKBOOKS] Instance Update';
export const UPDATE_INSTANCE_SUCCESS = '[QUICKBOOKS] Instance Update Success';
export const UPDATE_INSTANCE_FAIL = '[QUICKBOOKS] Instance Update Fail';

export const GET_COMPANY_QUICKBOOKS_INSTANCE = '[QUICKBOOKS] Instance GET';
export const GET_COMPANY_QUICKBOOKS_INSTANCE_SUCCESS = '[QUICKBOOKS] Instance GET Success';
export const GET_COMPANY_QUICKBOOKS_INSTANCE_FAIL = '[QUICKBOOKS] Instance GET Fail';

export const SYNC_CLASSES = '[QUICKBOOKS] Sync Classes';
export const SYNC_CLASSES_SUCCESS = '[QUICKBOOKS] Sync Classes Success';
export const SYNC_CLASSES_FAIL = '[QUICKBOOKS] Sync Classes Fail';

export const GET_CLASSES = '[QUICKBOOKS] Get Classes';
export const GET_CLASSES_SUCCESS = '[QUICKBOOKS] Get Classes Success';
export const GET_CLASSES_FAIL = '[QUICKBOOKS] Get Classes Fail';

export const GET_ACCOUNTS = '[QUICKBOOKS] Get Accounts';
export const GET_ACCOUNTS_SUCCESS = '[QUICKBOOKS] Get Accounts Success';
export const GET_ACCOUNTS_FAIL = '[QUICKBOOKS] Get Accounts Fail';

export const SYNC_ACCOUNTS = '[QUICKBOOKS] Sync Accounts';
export const SYNC_ACCOUNTS_SUCCESS = '[QUICKBOOKS] Sync Accounts Success';
export const SYNC_ACCOUNTS_FAIL = '[QUICKBOOKS] Sync Accounts Fail';

export const GET_ITEMS = '[QUICKBOOKS] Get Items';
export const GET_ITEMS_SUCCESS = '[QUICKBOOKS] Get Items Success';
export const GET_ITEMS_FAIL = '[QUICKBOOKS] Get Items Fail';

export const SYNC_ITEMS = '[QUICKBOOKS] Sync Items';
export const SYNC_ITEMS_SUCCESS = '[QUICKBOOKS] Sync Items Success';
export const SYNC_ITEMS_FAIL = '[QUICKBOOKS] Sync Items Fail';

export const SAVE_PROPERTY_EXTRAS = '[QUICKBOOKS] Save Property Extras';
export const SAVE_PROPERTY_EXTRAS_SUCCESS = '[QUICKBOOKS] Save Property Extras Success';
export const SAVE_PROPERTY_EXTRAS_FAIL = '[QUICKBOOKS] Save Property Extras Fail';

export const GET_TAX_CODES = '[QUICKBOOKS] Get taxCodes';
export const GET_TAX_CODES_SUCCESS = '[QUICKBOOKS] Get taxCodes Success';
export const GET_TAX_CODES_FAIL = '[QUICKBOOKS] Get taxCodes Fail';