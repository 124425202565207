export const GET_ACTIVITY_LOGS_PAGINATION = "[ACTIVITY_LOGS] Pagination Get";
export const GET_ACTIVITY_LOGS_PAGINATION_SUCCESS = "[ACTIVITY_LOGS] Pagination Get Success";
export const GET_ACTIVITY_LOGS_PAGINATION_FAIL = "[ACTIVITY_LOGS] Pagination Get Fail";

export const GET_ACTIVITY_LOGS_COLLECTION = "[ACTIVITY_LOGS] Collection Get";
export const GET_ACTIVITY_LOGS_COLLECTION_SUCCESS = "[ACTIVITY_LOGS] Collection Get Success";
export const GET_ACTIVITY_LOGS_COLLECTION_FAIL = "[ACTIVITY_LOGS] Collection Get Fail";

export const GET_ACTIVITY_LOGS_SINGLE = "[ACTIVITY_LOGS] Single Get";
export const GET_ACTIVITY_LOGS_SINGLE_SUCCESS = "[ACTIVITY_LOGS] Single Get Success";
export const GET_ACTIVITY_LOGS_SINGLE_FAIL = "[ACTIVITY_LOGS] Single Get Fail";

export const CREATE_ACTIVITY_LOGS = "[ACTIVITY_LOGS] Create";
export const CREATE_ACTIVITY_LOGS_SUCCESS = "[ACTIVITY_LOGS] Create Success";
export const CREATE_ACTIVITY_LOGS_FAIL = "[ACTIVITY_LOGS] Create Fail";
export const CREATE_ACTIVITY_LOGS_RESET = "[ACTIVITY_LOGS] Create Reset";

export const UPDATE_ACTIVITY_LOGS = "[ACTIVITY_LOGS] Update";
export const UPDATE_ACTIVITY_LOGS_SUCCESS = "[ACTIVITY_LOGS] Update Success";
export const UPDATE_ACTIVITY_LOGS_FAIL = "[ACTIVITY_LOGS] Update Fail";
export const UPDATE_ACTIVITY_LOGS_RESET = "[ACTIVITY_LOGS] Update Reset";

export const REMOVE_ACTIVITY_LOGS = "[ACTIVITY_LOGS] Remove";
export const REMOVE_ACTIVITY_LOGS_SUCCESS = "[ACTIVITY_LOGS] Remove Success";
export const REMOVE_ACTIVITY_LOGS_FAIL = "[ACTIVITY_LOGS] Remove Fail";
