export default [
  {
    name: "quickbooks",
    path: "/companies/:id/quickbooks",
    component: () => import(/*webpackChunkName: "quickbooks" */ "@/pages/quickbooks/Index"),
    meta: {
      breadcrumbs: [
        { text: 'titles.companies', to: 'companies', params: false, disabled: false },
        { text: 'titles.company_settings', to: 'company-settings', params: true, disabled: false },
      ],
    }
  },
  {
    name: "quickbooks-authorize",
    path: "/companies/:id/quickbooks/authorize",
    component: () => import(/*webpackChunkName: "quickbooks" */ "@/pages/quickbooks/Authorize"),
    meta: {
      // layout: 'empty'
    }
  },
  {
    name: "quickbooks-callback",
    path: "/quickbooks/callback",
    component: () => import(/*webpackChunkName: "quickbooks" */ "@/pages/quickbooks/Callback"),
    meta: {
      layout: 'empty'
    }
  },
];
