import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_BOOKING_SETTINGS_COLLECTION,
  GET_BOOKING_SETTINGS_COLLECTION_FAIL,
  GET_BOOKING_SETTINGS_COLLECTION_SUCCESS,
  CREATE_BOOKING_SETTINGS,
  CREATE_BOOKING_SETTINGS_FAIL,
  CREATE_BOOKING_SETTINGS_RESET,
  CREATE_BOOKING_SETTINGS_SUCCESS,
  UPDATE_BOOKING_SETTINGS,
  UPDATE_BOOKING_SETTINGS_FAIL,
  UPDATE_BOOKING_SETTINGS_RESET,
  UPDATE_BOOKING_SETTINGS_SUCCESS,
  REMOVE_BOOKING_SETTINGS,
  REMOVE_BOOKING_SETTINGS_FAIL,
  REMOVE_BOOKING_SETTINGS_SUCCESS,
} from "./actions";

const crudProcessed = crud.process({
  getCollectionRequest: {
    action: GET_BOOKING_SETTINGS_COLLECTION,
    endPoint: "booking-settings/collected",
    mutationFail: GET_BOOKING_SETTINGS_COLLECTION_FAIL,
    mutationSuccess: GET_BOOKING_SETTINGS_COLLECTION_SUCCESS
  },
  creationRequest: {
    action: CREATE_BOOKING_SETTINGS,
    actionReset: CREATE_BOOKING_SETTINGS_RESET,
    endPoint: "booking-settings/create",
    mutationFail: CREATE_BOOKING_SETTINGS_FAIL,
    mutationSuccess: CREATE_BOOKING_SETTINGS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_BOOKING_SETTINGS,
    actionReset: UPDATE_BOOKING_SETTINGS_RESET,
    endPoint: "booking-settings/update",
    mutationFail: UPDATE_BOOKING_SETTINGS_FAIL,
    mutationSuccess: UPDATE_BOOKING_SETTINGS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_BOOKING_SETTINGS,
    endPoint: "booking-settings/delete",
    mutationFail: REMOVE_BOOKING_SETTINGS_FAIL,
    mutationSuccess: REMOVE_BOOKING_SETTINGS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([]);

const actions = {
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
