import { Extension } from '@tiptap/core';

export const Fullscreen = Extension.create({
  name: 'fullscreen',

  addCommands() {
    return {
      toggleFullscreen: () => ({ editor }) => {
        const editorContainer = editor.view.dom.closest('#editor-container');
        if (editorContainer.classList.contains('fullscreen')) {
          editorContainer.classList.remove('fullscreen');
        } else {
          editorContainer.classList.add('fullscreen');
        }
        editor.view.update();
        return true;
      },
    };
  },
});