import crud from "@/store/core/crud";
import action from "@/store/core/action";
import ApiService from "@/services/api";

import {
  GET_GLOBAL_SETTINGS_COLLECTION,
  GET_GLOBAL_SETTINGS_COLLECTION_SUCCESS,
  GET_GLOBAL_SETTINGS_COLLECTION_FAIL,
  GET_GLOBAL_SETTINGS_SINGLE,
  GET_GLOBAL_SETTINGS_SINGLE_SUCCESS,
  GET_GLOBAL_SETTINGS_SINGLE_FAIL,
  CREATE_GLOBAL_SETTINGS,
  CREATE_GLOBAL_SETTINGS_SUCCESS,
  CREATE_GLOBAL_SETTINGS_FAIL,
  CREATE_GLOBAL_SETTINGS_RESET,
  UPDATE_GLOBAL_SETTINGS,
  UPDATE_GLOBAL_SETTINGS_SUCCESS,
  UPDATE_GLOBAL_SETTINGS_FAIL,
  UPDATE_GLOBAL_SETTINGS_RESET,
  REPLACE_GLOBAL_SETTINGS,
  REPLACE_GLOBAL_SETTINGS_SUCCESS,
  REPLACE_GLOBAL_SETTINGS_FAIL,
  REPLACE_GLOBAL_SETTINGS_RESET,
  GET_COMMANDS_LIST,
  GET_COMMANDS_LIST_SUCCESS,
  GET_COMMANDS_LIST_FAIL,
  RUN_COMMAND,
  RUN_COMMAND_SUCCESS,
  RUN_COMMAND_FAIL,
} from "./actions";

const crudProcessed = crud.process({
  getCollectionRequest: {
    action: GET_GLOBAL_SETTINGS_COLLECTION,
    endPoint: "global-settings/collected",
    mutationFail: GET_GLOBAL_SETTINGS_COLLECTION_FAIL,
    mutationSuccess: GET_GLOBAL_SETTINGS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_GLOBAL_SETTINGS_SINGLE,
    endPoint: "global-settings/single",
    mutationFail: GET_GLOBAL_SETTINGS_SINGLE_FAIL,
    mutationSuccess: GET_GLOBAL_SETTINGS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_GLOBAL_SETTINGS,
    actionReset: CREATE_GLOBAL_SETTINGS_RESET,
    endPoint: "global-settings/create",
    mutationFail: CREATE_GLOBAL_SETTINGS_FAIL,
    mutationSuccess: CREATE_GLOBAL_SETTINGS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_GLOBAL_SETTINGS,
    actionReset: UPDATE_GLOBAL_SETTINGS_RESET,
    endPoint: "global-settings/update",
    mutationFail: UPDATE_GLOBAL_SETTINGS_FAIL,
    mutationSuccess: UPDATE_GLOBAL_SETTINGS_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_GLOBAL_SETTINGS,
    actionReset: REPLACE_GLOBAL_SETTINGS_RESET,
    endPoint: "global-settings/replace",
    mutationFail: REPLACE_GLOBAL_SETTINGS_FAIL,
    mutationSuccess: REPLACE_GLOBAL_SETTINGS_SUCCESS
  },
});

const actionsProcessed = action.processMultiple([
  {
    name: 'getCommandsRequest',
    action: GET_COMMANDS_LIST,
    endPoint: 'global-settings/command',
    mutations: {
      fail: GET_COMMANDS_LIST_FAIL,
      success: GET_COMMANDS_LIST_SUCCESS,
    },
  },
]);

const actions = {
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
  [RUN_COMMAND](context, params) {
    context.state.runCommandRequest.loading = true;

    return new Promise((resolve, reject) => {
      ApiService.post(`global-settings/command`, params)
        .then(response => {
          context.commit(RUN_COMMAND_SUCCESS, response);
          resolve(response);
        })
        .catch(({ response }) => {
          context.commit(RUN_COMMAND_FAIL, response.data.errors);
          reject(response.data.errors);
        });
    });
  },
};

const getters = {};

const state = {
  runCommandRequest: {
    loading: false,
    success: false,
    fail: false,
    validation: {},
    response: {}
  },
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  [RUN_COMMAND_SUCCESS](state, data) {
    state.runCommandRequest = {
      loading: false,
      success: true,
      fail: false,
      validation: {},
      response: data
    };
  },
  [RUN_COMMAND_FAIL](state, data) {
    state.runCommandRequest = {
      loading: false,
      success: false,
      fail: true,
      validation: data,
      response: {}
    };
  },
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
