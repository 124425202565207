export const GET_RATE_PLANS_LIST = "[RATE_PLANS] List Get";
export const GET_RATE_PLANS_LIST_SUCCESS = "[RATE_PLANS] List Get Success";
export const GET_RATE_PLANS_LIST_FAIL = "[RATE_PLANS] List Get Fail";

export const GET_RATE_PLANS_PAGINATION = "[RATE_PLANS] Pagination Get";
export const GET_RATE_PLANS_PAGINATION_SUCCESS = "[RATE_PLANS] Pagination Get Success";
export const GET_RATE_PLANS_PAGINATION_FAIL = "[RATE_PLANS] Pagination Get Fail";

export const GET_RATE_PLANS_COLLECTION = "[RATE_PLANS] Collection Get";
export const GET_RATE_PLANS_COLLECTION_SUCCESS = "[RATE_PLANS] Collection Get Success";
export const GET_RATE_PLANS_COLLECTION_FAIL = "[RATE_PLANS] Collection Get Fail";

export const GET_RATE_PLANS_SINGLE = "[RATE_PLANS] Single Get";
export const GET_RATE_PLANS_SINGLE_SUCCESS = "[RATE_PLANS] Single Get Success";
export const GET_RATE_PLANS_SINGLE_FAIL = "[RATE_PLANS] Single Get Fail";

export const CREATE_RATE_PLANS = "[RATE_PLANS] Create";
export const CREATE_RATE_PLANS_SUCCESS = "[RATE_PLANS] Create Success";
export const CREATE_RATE_PLANS_FAIL = "[RATE_PLANS] Create Fail";
export const CREATE_RATE_PLANS_RESET = "[RATE_PLANS] Create Reset";

export const UPDATE_RATE_PLANS = "[RATE_PLANS] Update";
export const UPDATE_RATE_PLANS_SUCCESS = "[RATE_PLANS] Update Success";
export const UPDATE_RATE_PLANS_FAIL = "[RATE_PLANS] Update Fail";
export const UPDATE_RATE_PLANS_RESET = "[RATE_PLANS] Update Reset";

export const REMOVE_RATE_PLANS = "[RATE_PLANS] Remove";
export const REMOVE_RATE_PLANS_SUCCESS = "[RATE_PLANS] Remove Success";
export const REMOVE_RATE_PLANS_FAIL = "[RATE_PLANS] Remove Fail";

export const POSITION_RATE_PLANS = "[RATE_PLANS] Position";
export const POSITION_RATE_PLANS_SUCCESS = "[RATE_PLANS] Position Success";
export const POSITION_RATE_PLANS_FAIL = "[RATE_PLANS] Position Fail";

