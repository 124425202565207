export default {
  methods: {
    $role(role) {
      const roles = this.userData.roles || {};
      return !!roles[role];
    },
    $can(permission) {
      const permissions = this.userData.permissions || {};
      return !!permissions[permission];
    },
    $cannot(permission) {
      return !this.$can(permission);
    }
  }
};
