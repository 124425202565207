<template>
  <vuetify-google-autocomplete
    id="address-autcomplete"
    :append-icon="appendIcon"
    :addressComponents="{
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'short_name',
      administrative_area_level_2: 'short_name',
      country: 'short_name',
      postal_code: 'short_name',
    }"
    :placeholder="label"
    v-on:placechanged="getAddressData"
    :solo="solo"
  />
</template>

<script>
export default {
  name: "VAddressAutocomplete",
  props: {
    value: {
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      required: false,
    },
    errorMessages: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    appendIcon: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    persistentHint: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      selectValue: null
    }
  },
  beforeMount() {
    this.selectValue = this.value;
  },
  methods: {
    getAddressData(data) {
      this.$emit('input', data);
    }
  },
  watch: {
    value() {
      this.selectValue = this.value;
    },
  },
}
</script>
