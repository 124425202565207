import crud from "@/store/core/crud";
import action from "@/store/core/action";
import ApiService from "@/services/api";

import {
  GET_STAT_ACCOUNTS_PAGINATION,
  GET_STAT_ACCOUNTS_PAGINATION_SUCCESS,
  GET_STAT_ACCOUNTS_PAGINATION_FAIL,
  GET_STAT_ACCOUNTS_COLLECTION,
  GET_STAT_ACCOUNTS_COLLECTION_SUCCESS,
  GET_STAT_ACCOUNTS_COLLECTION_FAIL,
  GET_STAT_ACCOUNTS_SINGLE,
  GET_STAT_ACCOUNTS_SINGLE_SUCCESS,
  GET_STAT_ACCOUNTS_SINGLE_FAIL,
  CREATE_STAT_ACCOUNTS,
  CREATE_STAT_ACCOUNTS_SUCCESS,
  CREATE_STAT_ACCOUNTS_FAIL,
  CREATE_STAT_ACCOUNTS_RESET,
  UPDATE_STAT_ACCOUNTS,
  UPDATE_STAT_ACCOUNTS_SUCCESS,
  UPDATE_STAT_ACCOUNTS_FAIL,
  UPDATE_STAT_ACCOUNTS_RESET,
  REMOVE_STAT_ACCOUNTS,
  REMOVE_STAT_ACCOUNTS_SUCCESS,
  REMOVE_STAT_ACCOUNTS_FAIL,
  VALIDATE_STAT_ACCOUNT,
  VALIDATE_STAT_ACCOUNT_SUCCESS,
  VALIDATE_STAT_ACCOUNT_FAIL,
  VALIDATE_STAT_ACCOUNT_RESET,
  MANUAL_SUBMIT_STAT_ACCOUNT,
  MANUAL_SUBMIT_STAT_ACCOUNT_SUCCESS,
  MANUAL_SUBMIT_STAT_ACCOUNT_FAIL,
  FILE_DOWNLOAD_STAT_ACCOUNT,
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_STAT_ACCOUNTS_PAGINATION,
    endPoint: "stat-accounts/paginated",
    mutationFail: GET_STAT_ACCOUNTS_PAGINATION_FAIL,
    mutationSuccess: GET_STAT_ACCOUNTS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_STAT_ACCOUNTS_COLLECTION,
    endPoint: "stat-accounts/collected",
    mutationFail: GET_STAT_ACCOUNTS_COLLECTION_FAIL,
    mutationSuccess: GET_STAT_ACCOUNTS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_STAT_ACCOUNTS_SINGLE,
    endPoint: "stat-accounts/single",
    mutationFail: GET_STAT_ACCOUNTS_SINGLE_FAIL,
    mutationSuccess: GET_STAT_ACCOUNTS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_STAT_ACCOUNTS,
    actionReset: CREATE_STAT_ACCOUNTS_RESET,
    endPoint: "stat-accounts/create",
    mutationFail: CREATE_STAT_ACCOUNTS_FAIL,
    mutationSuccess: CREATE_STAT_ACCOUNTS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_STAT_ACCOUNTS,
    actionReset: UPDATE_STAT_ACCOUNTS_RESET,
    endPoint: "stat-accounts/update",
    mutationFail: UPDATE_STAT_ACCOUNTS_FAIL,
    mutationSuccess: UPDATE_STAT_ACCOUNTS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_STAT_ACCOUNTS,
    endPoint: "stat-accounts/delete",
    mutationFail: REMOVE_STAT_ACCOUNTS_FAIL,
    mutationSuccess: REMOVE_STAT_ACCOUNTS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    name: 'getValidateRequest',
    action: VALIDATE_STAT_ACCOUNT,
    actionReset: VALIDATE_STAT_ACCOUNT_RESET,
    endPoint: 'stat-accounts/validate',
    mutations: {
      fail: VALIDATE_STAT_ACCOUNT_FAIL,
      success: VALIDATE_STAT_ACCOUNT_SUCCESS
    }
  },
  {
    name: 'getManualSubmitRequest',
    action: MANUAL_SUBMIT_STAT_ACCOUNT,
    endPoint: 'stat-accounts/manual-submit',
    mutations: {
      fail: MANUAL_SUBMIT_STAT_ACCOUNT_FAIL,
      success: MANUAL_SUBMIT_STAT_ACCOUNT_SUCCESS
    }
  },
]);

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  [FILE_DOWNLOAD_STAT_ACCOUNT](context, data) {
    ApiService.download(`stat-accounts/${data.id}/download-file?from=${data.from}&to=${data.to}`, data, true);
  },
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
