import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_OCTORATE_ACCOMMODATIONS_PAGINATION,
  GET_OCTORATE_ACCOMMODATIONS_PAGINATION_SUCCESS,
  GET_OCTORATE_ACCOMMODATIONS_PAGINATION_FAIL,
  GET_OCTORATE_ACCOMMODATIONS_COLLECTION,
  GET_OCTORATE_ACCOMMODATIONS_COLLECTION_SUCCESS,
  GET_OCTORATE_ACCOMMODATIONS_COLLECTION_FAIL,
  GET_OCTORATE_ACCOMMODATIONS_SINGLE,
  GET_OCTORATE_ACCOMMODATIONS_SINGLE_SUCCESS,
  GET_OCTORATE_ACCOMMODATIONS_SINGLE_FAIL,
  CREATE_OCTORATE_ACCOMMODATIONS,
  CREATE_OCTORATE_ACCOMMODATIONS_SUCCESS,
  CREATE_OCTORATE_ACCOMMODATIONS_FAIL,
  CREATE_OCTORATE_ACCOMMODATIONS_RESET,
  UPDATE_OCTORATE_ACCOMMODATIONS,
  UPDATE_OCTORATE_ACCOMMODATIONS_SUCCESS,
  UPDATE_OCTORATE_ACCOMMODATIONS_FAIL,
  UPDATE_OCTORATE_ACCOMMODATIONS_RESET,
  REMOVE_OCTORATE_ACCOMMODATIONS,
  REMOVE_OCTORATE_ACCOMMODATIONS_SUCCESS,
  REMOVE_OCTORATE_ACCOMMODATIONS_FAIL,
  IMPORT_OCTORATE_CALENDAR,
  IMPORT_OCTORATE_CALENDAR_SUCCESS,
  IMPORT_OCTORATE_CALENDAR_FAIL,
  SYNC_OCTORATE_ACCOMMODATIONS,
  SYNC_OCTORATE_ACCOMMODATIONS_SUCCESS,
  SYNC_OCTORATE_ACCOMMODATIONS_FAIL,
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_OCTORATE_ACCOMMODATIONS_PAGINATION,
    endPoint: "octorate-accommodations/paginated",
    mutationFail: GET_OCTORATE_ACCOMMODATIONS_PAGINATION_FAIL,
    mutationSuccess: GET_OCTORATE_ACCOMMODATIONS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_OCTORATE_ACCOMMODATIONS_COLLECTION,
    endPoint: "octorate-accommodations/collected",
    mutationFail: GET_OCTORATE_ACCOMMODATIONS_COLLECTION_FAIL,
    mutationSuccess: GET_OCTORATE_ACCOMMODATIONS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_OCTORATE_ACCOMMODATIONS_SINGLE,
    endPoint: "octorate-accommodations/single",
    mutationFail: GET_OCTORATE_ACCOMMODATIONS_SINGLE_FAIL,
    mutationSuccess: GET_OCTORATE_ACCOMMODATIONS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_OCTORATE_ACCOMMODATIONS,
    actionReset: CREATE_OCTORATE_ACCOMMODATIONS_RESET,
    endPoint: "octorate-accommodations/create",
    mutationFail: CREATE_OCTORATE_ACCOMMODATIONS_FAIL,
    mutationSuccess: CREATE_OCTORATE_ACCOMMODATIONS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_OCTORATE_ACCOMMODATIONS,
    actionReset: UPDATE_OCTORATE_ACCOMMODATIONS_RESET,
    endPoint: "octorate-accommodations/update",
    mutationFail: UPDATE_OCTORATE_ACCOMMODATIONS_FAIL,
    mutationSuccess: UPDATE_OCTORATE_ACCOMMODATIONS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_OCTORATE_ACCOMMODATIONS,
    endPoint: "octorate-accommodations/delete",
    mutationFail: REMOVE_OCTORATE_ACCOMMODATIONS_FAIL,
    mutationSuccess: REMOVE_OCTORATE_ACCOMMODATIONS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    name: 'getImportCalendarRequest',
    action: IMPORT_OCTORATE_CALENDAR,
    endPoint: 'octorate-accommodations/calendar-sync',
    mutations: {
      fail: IMPORT_OCTORATE_CALENDAR_FAIL,
      success: IMPORT_OCTORATE_CALENDAR_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'getAccommmodationSyncRequest',
    action: SYNC_OCTORATE_ACCOMMODATIONS,
    endPoint: 'octorate-accommodations/sync',
    mutations: {
      fail: SYNC_OCTORATE_ACCOMMODATIONS_FAIL,
      success: SYNC_OCTORATE_ACCOMMODATIONS_SUCCESS,
    },
  },
]);

const actions = {
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
