import Vue from "vue";
import Router from "vue-router";
import Middleware, { middleware } from "vue-router-middleware";
import NotFound from "@/pages/static/NotFound";
import { isAuthenticated, isTermsAccepted } from '@/helpers/functions';

//Routes
import auth from "@/router/auth";
import admin from "@/router/admin";
import global from "@/router/global";
import dashboard from "@/router/dashboard";
import clients from "@/router/clients";
import derivations from "@/router/derivations";
import properties from "@/router/properties";
import reservations from "@/router/reservations";
import companies from "@/router/companies";
import octorate from "@/router/octorate";
import bookingNetworks from "@/router/booking-networks";
import units from "@/router/units";
import unitCategoryCosts from "@/router/unit-category-costs";
import users from "@/router/users";
import cmms from "@/router/cmms";
import planner from "@/router/planner";
import bills from "@/router/bills";
import documents from "@/router/documents";
import statistics from "@/router/statistics";
import rmt from "@/router/rmt";
import payments from "@/router/payments";
import privacy from "@/router/privacy";
import bookingPaymentProviders from "@/router/booking-payment-providers";
import legalCompliance from "@/router/legal-compliance";
import quotes from "@/router/quotes";
import housekeeping from "@/router/housekeeping";
import subscriptionCoupons from "@/router/subscription-coupons";
import tickets from "@/router/tickets";
import calendars from "@/router/calendars";
import resources from "@/router/resources";
import agencies from "@/router/agencies";
import pos from "@/router/pos";
import calendar from "@/router/calendar";
import activityLogs from "@/router/activity-logs";
import nuki from "@/router/nuki";
import quickbooks from "@/router/quickbooks";
import ciaoChannel from "@/router/ciao-channel";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    ...middleware("guest", [
      ...auth
    ]),
    ...middleware("auth", [
      ...admin,
      ...global,
      ...dashboard,
      ...clients,
      ...derivations,
      ...properties,
      ...reservations,
      ...companies,
      ...octorate,
      ...units,
      ...bookingNetworks,
      ...unitCategoryCosts,
      ...users,
      ...cmms,
      ...planner,
      ...bills,
      ...documents,
      ...statistics,
      ...rmt,
      ...payments,
      ...bookingPaymentProviders,
      ...privacy,
      ...legalCompliance,
      ...housekeeping,
      ...quotes,
      ...subscriptionCoupons,
      ...tickets,
      ...calendars,
      ...resources,
      ...agencies,
      ...pos,
      ...calendar,
      ...activityLogs,
      ...nuki,
      ...quickbooks,
      ...ciaoChannel,
    ]),
    {
      path: "*",
      component: NotFound
    }
  ]
});

Vue.use(Middleware, {
  router,
  middlewares: {
    guest(params, to, from, next) {
      if (isAuthenticated()) {
        next({ name: "dashboard" });
      } else {
        next();
      }
    },
    auth(params, to, from, next) {
      if (isAuthenticated()) {
        next();
      } else {
        next({ name: "login" });
      }
    }
  }
});

export default router;
