export default [
  {
    name: "resources",
    path: "/resources",
    component: () => import(/*webpackChunkName: "resources" */ "@/pages/resources/Index"),
    meta: {
      title: 'titles.resources'
    }
  },
  {
    name: "resources-calendar",
    path: "/resources/calendar",
    component: () => import(/*webpackChunkName: "resources-calendar" */ "@/pages/resources-calendar/Index"),
    meta: {
      title: 'titles.resources_calendar'
    }
  },
];
