<template>
  <span>
    <v-autocomplete
      v-model="ids"
      :items="items"
      :label="label"
      :multiple="multiple"
      :small-chips="smallChips"
      :deletable-chips="deletableChips"
      :hint="hint"
      :persistent-hint="persistentHint"
      :loading="loading"
      :clearable="clearable"
      :disabled="disabled"
      :readonly="readonly"
      :dark="dark"
      @change="change"
      @blur="blur"
      @click:append-outer="getItems()"
      append-outer-icon="mdi-refresh"
      :dense="dense"
      :error="error"
      :error-messages="!!errorMessages ? errorMessages : []"
    >
    </v-autocomplete>
  </span>
</template>

<script>
  import { mapState } from "vuex";

  import {
    GET_POINT_OF_SALES_LIST
  } from "@/store/point-of-sales/actions";

  export default {
    name: "CPointOfSalesAutocomplete",
    props: {
      label: {
        type: String,
        required: false,
        default: 'Point of Sales'
      },
      hint: {
        type: String,
        default: ""
      },
      persistentHint: {
        type: Boolean,
        default: false
      },
      value: {
        default: null
      },
      error: {
        default: false
      },
      errorMessages: {
        default: () => []
      },
      dense: {
        type: Boolean,
        default: false
      },
      clearable: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      readonly: {
        type: Boolean,
        default: false
      },
      dark: {
        type: Boolean,
        default: false
      },
      smallChips: {
        type: Boolean,
        default: false
      },
      deletableChips: {
        type: Boolean,
        default: false
      },
      options: {
        type: Object,
        default: () => {
          return {};
        }
      }
    },
    data() {
      return {
        ids: null,
        selectedIds: [],
        cache: false
      };
    },
    beforeMount() {
      if (!this.isListCached || Object.keys(this.options).length) {
        this.getItems();
        this.cache = true;
      }
    },
    mounted() {
      this.ids = this.value;
    },
    methods: {
      getItems() {
        let filters = {};
        if (!!this.options.companyId) {
          filters.company_id = this.options.companyId;
        }

        this.$store.dispatch(GET_POINT_OF_SALES_LIST, {
          sortBy: 'name',
          order: 'asc',
          ...filters,
        });
      },
      change() {
        this.$emit("change");
      },
      blur() {
        this.$emit("blur");
      },
    },
    computed: {
      ...mapState({
        itemsRequest: state => state.pointOfSales.getListRequest,
      }),
      items() {
        if (!this.itemsRequest.list) {
          return [];
        }

        return this.itemsRequest.list;
      },
      loading() {
        return this.itemsRequest.loading;
      },
      isListCached() {
        return this.itemsRequest.success;
      },
    },
    watch: {
      ids(newVal) {
        this.$emit('input', newVal);
      },
      value(newVal) {
        this.ids = newVal;
      }
    }
  };
</script>
