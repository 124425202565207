export default [
  {
    name: "reservations",
    path: "/reservations",
    component: () => import(/*webpackChunkName: "reservations" */ "@/pages/reservations/Index"),
    meta: {
      title: 'titles.reservations',
      loginRedirect: true
    }
  },
  {
    name: "reservation-view",
    path: "/reservations/:itemId",
    component: () => import(/*webpackChunkName: "reservations" */ "@/pages/reservations/View"),
    meta: {
      title: 'titles.view_reservation',
      breadcrumbs: [
        { text: 'titles.reservations', to: 'properties' },
      ],
    },
    props: (route) => {
      const itemId = Number.parseInt(route.params.itemId, 10)
      if (Number.isNaN(itemId)) {
        return 0
      }
      return { itemId }
    }
  },
  {
    name: "reservations-card-confirmation",
    path: "/reservations/:id/card-confirmation",
    component: () => import(/*webpackChunkName: "reservations" */ "@/pages/reservations/partials/AddCardConfirmation"),
    meta: {
      layout: 'empty'
    }
  },
];
