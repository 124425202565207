export default [
  {
    name: "octorate-accommodations",
    path: "/octorate/accommodations",
    component: () => import(/* webpackChunkName: "octorate" */ "@/pages/octorate-accommodations/Index"),
    meta: {
      title: 'titles.octorate_accommodations'
    }
  },
  {
    name: "octorate-reservations",
    path: "/octorate/reservations",
    component: () => import(/*webpackChunkName: "octorate" */ "@/pages/octorate-reservations/Index"),
    meta: {
      title: 'titles.octorate_reservations'
    }
  },
  {
    name: "octorate-calendar-sync",
    path: "/octorate/calendar-sync",
    component: () => import(/* webpackChunkName: "octorate" */ "@/pages/octorate-accommodations/CalendarSync"),
    meta: {
      title: 'titles.octorate_calendar_sync'
    }
  },
  {
    name: "octorate-connections",
    path: "/properties/:id/channel/:accommodation/connections",
    component: () => import(/*webpackChunkName: "octorate" */ "@/pages/octorate-connections/Index"),
    meta: {
      breadcrumbs: [
        { text: 'titles.properties', to: 'properties', params: true },
        { text: 'titles.property_settings', to: 'property-settings' },
      ],
      title: 'titles.octorate_connections'
    }
  },
  {
    name: "octorate-mapping",
    path: "/properties/:id/channel/:accommodation/connections/mapping/:connection",
    component: () => import(/*webpackChunkName: "octorate" */ "@/pages/octorate-connections/Mapping"),
    meta: {
      breadcrumbs: [
        { text: 'titles.properties', to: 'properties', params: true },
        { text: 'titles.octorate_connections', to: 'octorate-connections', params: true },
      ],
      title: 'titles.octorate_mapping'
    }
  },
];
