import action from "@/store/core/action";

import {
  GET_OCTORATE_RESERVATIONS,
  GET_OCTORATE_RESERVATIONS_SUCCESS,
  GET_OCTORATE_RESERVATIONS_FAIL,
  IMPORT_OCTORATE_RESERVATIONS,
  IMPORT_OCTORATE_RESERVATIONS_SUCCESS,
  IMPORT_OCTORATE_RESERVATIONS_FAIL
} from "./actions";

const actionsProcessed = action.processMultiple([
  {
    name: 'getReservationsRequest',
    action: GET_OCTORATE_RESERVATIONS,
    endPoint: 'octorate-reservations/get',
    mutations: {
      fail: GET_OCTORATE_RESERVATIONS_FAIL,
      success: GET_OCTORATE_RESERVATIONS_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'importReservationsRequest',
    action: IMPORT_OCTORATE_RESERVATIONS,
    endPoint: 'octorate-reservations/import',
    mutations: {
      fail: IMPORT_OCTORATE_RESERVATIONS_FAIL,
      success: IMPORT_OCTORATE_RESERVATIONS_SUCCESS,
    },
  }
]);

const getters = {};

const state = {
  ...actionsProcessed.state
};

const actions = {
  ...actionsProcessed.actions
};

const mutations = {
  ...actionsProcessed.mutations
};

export default {
  state,
  actions,
  mutations,
  getters
};
