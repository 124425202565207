<template>
  <span>
    <v-autocomplete
      v-model="ids"
      item-value="value"
      item-text="text"
      :items="items"
      :label="label"
      :small-chips="smallChips"
      :hint="hint"
      :persistent-hint="persistentHint"
      :loading="loading"
      :clearable="clearable"
      :readonly="readonly"
      :dark="dark"
      :dense="dense"
      @click:append-outer="getLanguage()"
      :error="error"
      :error-messages="!!errorMessages ? errorMessages : []"
      :prepend-icon="prependIcon"
    >
      <template v-slot:item="{item}">
        <lang-flag class="mr-1" :iso="item.value" />
        {{ item.text }}
      </template>
      <template v-slot:selection="{item}">
        <lang-flag class="mr-1" :iso="item.value" />
        {{ item.text }}
      </template>
    </v-autocomplete>
  </span>
</template>

<script>
import { mapState } from "vuex";
import {
  DATA_ISO639_2
} from "@/store/data/actions";

export default {
  name: "CLanguagesAutocomplete",
  props: {
    label: {
      type: String,
      required: false,
      default: "Languages"
    },
    hint: {
      type: String,
      default: ""
    },
    persistentHint: {
      type: Boolean,
      default: false
    },
    value: {
      default: null
    },
    error: {
      default: false
    },
    errorMessages: {
      default: () => []
    },
    dense: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    smallChips: {
      type: Boolean,
      default: false
    },
    prependIcon: {
      type: String,
      default: 'mdi-translate'
    }
  },
  data() {
    return {
      ids: null,
    };
  },
  beforeMount() {
    this.getLanguage();
  },
  mounted() {
    this.ids = this.value;
  },
  methods: {
    getLanguage() {
      this.$store.dispatch(DATA_ISO639_2, {
        sortBy: 'name',
        order: 'asc',
      });
    },
    change() {
      this.$emit("change");
    },
    blur() {
      this.$emit("blur");
    },
  },
  computed: {
    ...mapState({
      listRequest: (state) => state.data.dataIso6392Request
    }),
    items() {
      if (!this.listRequest.data) {
        return [];
      }
      return this.listRequest.data;
    },
    loading() {
      return this.listRequest.loading;
    },
    isListCached() {
      return this.listRequest.success;
    },
  },
  watch: {
    ids(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.ids = newVal;
    },
  }
};
</script>
