export const GET_RESERVATION_EXTRAS_PAGINATION = "[RESERVATION_EXTRAS] Pagination Get";
export const GET_RESERVATION_EXTRAS_PAGINATION_SUCCESS = "[RESERVATION_EXTRAS] Pagination Get Success";
export const GET_RESERVATION_EXTRAS_PAGINATION_FAIL = "[RESERVATION_EXTRAS] Pagination Get Fail";

export const GET_RESERVATION_EXTRAS_COLLECTION = "[RESERVATION_EXTRAS] Collection Get";
export const GET_RESERVATION_EXTRAS_COLLECTION_SUCCESS = "[RESERVATION_EXTRAS] Collection Get Success";
export const GET_RESERVATION_EXTRAS_COLLECTION_FAIL = "[RESERVATION_EXTRAS] Collection Get Fail";

export const GET_RESERVATION_EXTRAS_SINGLE = "[RESERVATION_EXTRAS] Single Get";
export const GET_RESERVATION_EXTRAS_SINGLE_SUCCESS = "[RESERVATION_EXTRAS] Single Get Success";
export const GET_RESERVATION_EXTRAS_SINGLE_FAIL = "[RESERVATION_EXTRAS] Single Get Fail";

export const CREATE_RESERVATION_EXTRAS = "[RESERVATION_EXTRAS] Create";
export const CREATE_RESERVATION_EXTRAS_SUCCESS = "[RESERVATION_EXTRAS] Create Success";
export const CREATE_RESERVATION_EXTRAS_FAIL = "[RESERVATION_EXTRAS] Create Fail";
export const CREATE_RESERVATION_EXTRAS_RESET = "[RESERVATION_EXTRAS] Create Reset";

export const UPDATE_RESERVATION_EXTRAS = "[RESERVATION_EXTRAS] Update";
export const UPDATE_RESERVATION_EXTRAS_SUCCESS = "[RESERVATION_EXTRAS] Update Success";
export const UPDATE_RESERVATION_EXTRAS_FAIL = "[RESERVATION_EXTRAS] Update Fail";
export const UPDATE_RESERVATION_EXTRAS_RESET = "[RESERVATION_EXTRAS] Update Reset";

export const REMOVE_RESERVATION_EXTRAS = "[RESERVATION_EXTRAS] Remove";
export const REMOVE_RESERVATION_EXTRAS_SUCCESS = "[RESERVATION_EXTRAS] Remove Success";
export const REMOVE_RESERVATION_EXTRAS_FAIL = "[RESERVATION_EXTRAS] Remove Fail";
