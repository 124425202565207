export const GET_STATISTICS_OCCUPANCY = "[STATISTICS] Occupancy";
export const GET_STATISTICS_OCCUPANCY_SUCCESS = "[STATISTICS] Occupancy Success";
export const GET_STATISTICS_OCCUPANCY_FAIL = "[STATISTICS] Occupancy Fail";

export const GET_STATISTICS_SOURCES = "[STATISTICS] Sources";
export const GET_STATISTICS_SOURCES_SUCCESS = "[STATISTICS] Sources Success";
export const GET_STATISTICS_SOURCES_FAIL = "[STATISTICS] Sources Fail";

export const GET_CITY_TAX = "[STATISTICS] City Tax";
export const GET_CITY_TAX_SUCCESS = "[STATISTICS] City Tax Success";
export const GET_CITY_TAX_FAIL = "[STATISTICS] City Tax Fail";

export const GET_CITY_TAX_DETAILS = "[STATISTICS] City Tax Details";
export const GET_CITY_TAX_DETAILS_SUCCESS = "[STATISTICS] City Tax Details Success";
export const GET_CITY_TAX_DETAILS_FAIL = "[STATISTICS] City Tax Details Fail";

export const GET_RESERVATIONS_REPORT = "[STATISTICS] Reservations Report";
export const GET_RESERVATIONS_REPORT_SUCCESS = "[STATISTICS] Reservations Report Success";
export const GET_RESERVATIONS_REPORT_FAIL = "[STATISTICS] Reservations Report Fail";

export const GET_ECONOMICS = "[STATISTICS] Economics";
export const GET_ECONOMICS_SUCCESS = "[STATISTICS] Economics Success";
export const GET_ECONOMICS_FAIL = "[STATISTICS] Economics Fail";