import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_MESSAGES_PAGINATION,
  GET_MESSAGES_PAGINATION_SUCCESS,
  GET_MESSAGES_PAGINATION_FAIL,
  GET_MESSAGES_COLLECTION,
  GET_MESSAGES_COLLECTION_SUCCESS,
  GET_MESSAGES_COLLECTION_FAIL,
  GET_MESSAGES_SINGLE,
  GET_MESSAGES_SINGLE_SUCCESS,
  GET_MESSAGES_SINGLE_FAIL,
  CREATE_MESSAGES,
  CREATE_MESSAGES_SUCCESS,
  CREATE_MESSAGES_FAIL,
  CREATE_MESSAGES_RESET,
  UPDATE_MESSAGES,
  UPDATE_MESSAGES_SUCCESS,
  UPDATE_MESSAGES_FAIL,
  UPDATE_MESSAGES_RESET,
  REMOVE_MESSAGES,
  REMOVE_MESSAGES_SUCCESS,
  REMOVE_MESSAGES_FAIL,
  RESEND_MESSAGES,
  RESEND_MESSAGES_SUCCESS,
  RESEND_MESSAGES_FAIL
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_MESSAGES_PAGINATION,
    endPoint: "messages/paginated",
    mutationFail: GET_MESSAGES_PAGINATION_FAIL,
    mutationSuccess: GET_MESSAGES_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_MESSAGES_COLLECTION,
    endPoint: "messages/collected",
    mutationFail: GET_MESSAGES_COLLECTION_FAIL,
    mutationSuccess: GET_MESSAGES_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_MESSAGES_SINGLE,
    endPoint: "messages/single",
    mutationFail: GET_MESSAGES_SINGLE_FAIL,
    mutationSuccess: GET_MESSAGES_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_MESSAGES,
    actionReset: CREATE_MESSAGES_RESET,
    endPoint: "messages/create",
    mutationFail: CREATE_MESSAGES_FAIL,
    mutationSuccess: CREATE_MESSAGES_SUCCESS
  },
  editingRequest: {
    action: UPDATE_MESSAGES,
    actionReset: UPDATE_MESSAGES_RESET,
    endPoint: "messages/update",
    mutationFail: UPDATE_MESSAGES_FAIL,
    mutationSuccess: UPDATE_MESSAGES_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_MESSAGES,
    endPoint: "messages/delete",
    mutationFail: REMOVE_MESSAGES_FAIL,
    mutationSuccess: REMOVE_MESSAGES_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    name: 'getResendRequest',
    action: RESEND_MESSAGES,
    endPoint: 'messages/resend/{id}',
    mutations: {
      fail: RESEND_MESSAGES_FAIL,
      success: RESEND_MESSAGES_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
