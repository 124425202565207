export const GET_DOCUMENTS_PAGINATION = "[DOCUMENTS] Pagination Get";
export const GET_DOCUMENTS_PAGINATION_SUCCESS = "[DOCUMENTS] Pagination Get Success";
export const GET_DOCUMENTS_PAGINATION_FAIL = "[DOCUMENTS] Pagination Get Fail";

export const GET_DOCUMENTS_COLLECTION = "[DOCUMENTS] Collection Get";
export const GET_DOCUMENTS_COLLECTION_SUCCESS = "[DOCUMENTS] Collection Get Success";
export const GET_DOCUMENTS_COLLECTION_FAIL = "[DOCUMENTS] Collection Get Fail";

export const GET_DOCUMENTS_SINGLE = "[DOCUMENTS] Single Get";
export const GET_DOCUMENTS_SINGLE_SUCCESS = "[DOCUMENTS] Single Get Success";
export const GET_DOCUMENTS_SINGLE_FAIL = "[DOCUMENTS] Single Get Fail";

export const CREATE_DOCUMENTS = "[DOCUMENTS] Create";
export const CREATE_DOCUMENTS_SUCCESS = "[DOCUMENTS] Create Success";
export const CREATE_DOCUMENTS_FAIL = "[DOCUMENTS] Create Fail";
export const CREATE_DOCUMENTS_RESET = "[DOCUMENTS] Create Reset";

export const UPDATE_DOCUMENTS = "[DOCUMENTS] Update";
export const UPDATE_DOCUMENTS_SUCCESS = "[DOCUMENTS] Update Success";
export const UPDATE_DOCUMENTS_FAIL = "[DOCUMENTS] Update Fail";
export const UPDATE_DOCUMENTS_RESET = "[DOCUMENTS] Update Reset";

export const REMOVE_DOCUMENTS = "[DOCUMENTS] Remove";
export const REMOVE_DOCUMENTS_SUCCESS = "[DOCUMENTS] Remove Success";
export const REMOVE_DOCUMENTS_FAIL = "[DOCUMENTS] Remove Fail";

export const SEND_RECEIPT_DOCUMENTS = "[DOCUMENTS] Send Receipt";
export const SEND_RECEIPT_DOCUMENTS_SUCCESS = "[DOCUMENTS] Send Receipt Success";
export const SEND_RECEIPT_DOCUMENTS_FAIL = "[DOCUMENTS] Send Receipt Fail";

export const SEND_RECEIPT_CANCELLATION_DOCUMENTS = "[DOCUMENTS] Send Receipt Cancellation";
export const SEND_RECEIPT_CANCELLATION_DOCUMENTS_SUCCESS = "[DOCUMENTS] Send Receipt Cancellation Success";
export const SEND_RECEIPT_CANCELLATION_DOCUMENTS_FAIL = "[DOCUMENTS] Send Receipt Cancellation Fail";

export const EXPORT_PDF_DOCUMENTS = "[DOCUMENTS] Export PDF";
export const EXPORT_XML_DOCUMENTS = "[DOCUMENTS] Export XML";

export const GET_FEEDS_REGISTER = "[DOCUMENTS] Feeds Register Get";
export const GET_FEEDS_REGISTER_SUCCESS = "[DOCUMENTS] Feeds Register Get Success";
export const GET_FEEDS_REGISTER_FAIL = "[DOCUMENTS] Feeds Register Get Fail";

export const SEND_DOCUMENT_EMAIL = "[DOCUMENTS] Send Email";
export const SEND_DOCUMENT_EMAIL_SUCCESS = "[DOCUMENTS] Send Email Success";
export const SEND_DOCUMENT_EMAIL_FAIL = "[DOCUMENTS] Send Email Fail";

export const SEND_DOCUMENT_SDI = "[DOCUMENTS] Send SDI";
export const SEND_DOCUMENT_SDI_SUCCESS = "[DOCUMENTS] Send SDI Success";
export const SEND_DOCUMENT_SDI_FAIL = "[DOCUMENTS] Send SDI Fail";

export const SEND_DOCUMENT_QUICKBOOKS = "[DOCUMENTS] Send Quickbooks";
export const SEND_DOCUMENT_QUICKBOOKS_SUCCESS = "[DOCUMENTS] Send Quickbooks Success";
export const SEND_DOCUMENT_QUICKBOOKS_FAIL = "[DOCUMENTS] Send Quickbooks Fail";

export const SEND_DOCUMENT_QUICKBOOKS_BULK = "[DOCUMENTS] Send Quickbooks Bulk";
export const SEND_DOCUMENT_QUICKBOOKS_BULK_SUCCESS = "[DOCUMENTS] Send Quickbooks Bulk Success";
export const SEND_DOCUMENT_QUICKBOOKS_BULK_FAIL = "[DOCUMENTS] Send Quickbooks Bulk Fail";

