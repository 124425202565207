// import moment from 'moment';
import moment from 'moment-timezone';
import appConfig from '@/config/app';
import SessionService from '@/libraries/sessionjs/src';
import CryptoJS from 'crypto-js';
import * as XLSX from 'xlsx/xlsx.mjs';

/*
|--------------------------------------------------------------------------
| Helpers
|--------------------------------------------------------------------------
*/

/**
 * @returns {boolean}
 */
export const isAuthenticated = () => {
  return !!SessionService.get("token") || !!SessionService.get("refreshToken");
};

/**
 *
 * @param href
 */
export const redirectTo = (href) => {
  window.location.href = href;
};

/**
 *
 * @returns {string}
 */
export const getCurrentUrl = () => {
  return window.location.href;
};

/**
 *
 * @param obj
 * @returns {boolean}
 */
export const isEmpty = (obj) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }

  return true;
};

/**
 *
 * @param object
 * @param value
 * @returns {string | undefined}
 */
export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
};

/**
 *
 * @param array
 * @param attribute
 * @param value
 */
export const findByAttribute = (array, attribute, value) => {
  return array.find(item => item[attribute] === value);
};

/**
 *
 * @param array
 * @param attribute
 * @param value
 */
export const findIndexByAttribute = (array, attribute, value) => {
  return array.findIndex(item => item[attribute] === value);
};

/**
 *
 * @param array
 * @param attribute
 * @param value
 */
export const whereInByAttribute = (array, attribute, value) => {
  return array.filter(item => item[attribute] === value);
};

/**
 * @param count
 * @returns {Array}
 */
export const range = (count) => {
  let range = [];

  for (let i = 0; i < count; i++) {
    range.push(i);
  }

  return range;
};

/**
 * @param number
 * @returns {*}
 */
export const absolute = (number) => {
  if (number === 0) {
    return 0;
  }
  if (!number) {
    return number;
  }

  return Math.abs(number);
};

/**
 * @param amount
 * @param currency
 * @returns {*}
 */
export const money = (amount, currency, format = 'en-US', digits = 2) => {
  if (!amount) {
    amount = 0;
  }

  amount = parseFloat(amount);
  amount = amount.toFixed(2);

  if (!currency) {
    return amount;
  }

  const formatter = new Intl.NumberFormat(format, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: digits
  });

  return formatter.format(amount);
};

/**
 * @param {*} amount
 * @param {*} format
 * @param {*} options
 */
export const percent = (amount, format = 'en-US', options = {}) => {
  if (!amount) {
    amount = 0;
  }

  const formatter = new Intl.NumberFormat(format, {
    style: 'percent',
    signDisplay: !!options.signDisplay ? options.signDisplay : 'exceptZero',
    minimumFractionDigits: !!options.digits ? options.digits : 0,
  });

  return formatter.format(amount);
}

/**
 * @param {*} amount
 * @param {*} format
 * @param {*} options
 */
export const variation = (amount, format = 'en-US', options = {}) => {
  if (!amount) {
    amount = 0;
  }

  const formatter = new Intl.NumberFormat(format, {
    signDisplay: !!options.signDisplay ? options.signDisplay : 'exceptZero',
    minimumFractionDigits: !!options.digits ? options.digits : 0,
  });

  return formatter.format(amount);
}

/**
 * @param phone
 * @returns {*}
 */
export const phoneFormat = (phone) => {
  const parts = phone.match(/[0-9]+/g);

  if (!parts) {
    return '';
  }

  return parts.join('');
};

/*
|--------------------------------------------------------------------------
| Dates
|--------------------------------------------------------------------------
*/

/**
 * @param date
 * @param format
 * @returns {boolean}
 */
export const isValidDate = (date, format) => {
  return moment(date, format).isValid();
};

/**
 * @param date
 * @param format
 * @returns {*}
 */
export const toDateString = (date, format) => {
  format = format || appConfig.format.date;

  if (!isValidDate(date, format)) {
    return null;
  }

  return moment(date, format).format('YYYY-MM-DD');
};

/**
 * @param dateTime
 * @param format
 * @returns {*}
 */
export const toDateTimeString = (dateTime, format) => {
  format = format || appConfig.format.dateTime;

  if (!isValidDate(dateTime, format)) {
    return null;
  }

  return moment(dateTime, format).format('YYYY-MM-DD HH:mm:ss');
};

/**
 * @param date
 * @param format
 * @returns {*}
 */
export const toDateFormat = (date, format) => {
  format = format || appConfig.format.date;

  if (!isValidDate(date, 'YYYY-MM-DD')) {
    return null;
  }

  return moment(date, 'YYYY-MM-DD').format(format);
};

/**
 * @param dateTime
 * @param format
 * @returns {*}
 */
export const toDateTimeFormat = (dateTime, format) => {
  format = format || appConfig.format.dateTime;

  if (!isValidDate(dateTime, 'YYYY-MM-DD HH:mm:ss')) {
    return null;
  }

  return moment(`${dateTime}Z`, 'YYYY-MM-DD HH:mm:ssZ').tz(getTimezone()).format(format);
};

/**
 * @param time
 * @param format
 * @returns {*}
 */
export const toTimeFormat = (time, format) => {
  format = format || appConfig.format.time;

  if (!isValidDate(time, 'HH:mm:ss')) {
    return null;
  }

  return moment(time, 'HH:mm:ss').format(format);
};

/**
 * @param dateTime
 * @returns {*}
 */
 export const fromNow = (dateTime) => {

  if (!isValidDate(dateTime, 'YYYY-MM-DD HH:mm:ss')) {
    return null;
  }

  return moment(`${dateTime}Z`, 'YYYY-MM-DD HH:mm:ssZ').locale(getLocale()).tz(getTimezone()).fromNow();
};

/**
 * @param startDate
 * @param endDate
 */
export const dateDiffInDays = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return 0;
  }

  endDate = moment(endDate);
  startDate = moment(startDate);

  return endDate.diff(startDate, 'd');
};

/**
 * @param date
 * @returns {boolean}
 */
export const dateIsWeekend = (date) => {
  date = moment(date);

  return date.day() === 0 || date.day() === 6;
};

/**
 * @returns {string}
 */
export const getTimezone = () => {
  return new window.Intl.DateTimeFormat().resolvedOptions().timeZone;
};

/**
 * @returns {string}
 */
export const getLocale = () => {
  if (SessionService.get("userData")) {
    return SessionService.get("userData").locale;
  }

  if (navigator.language) {
    return navigator.language.slice(0,2);
  }

  return appConfig.fallbackLocale;
};

/**
 * @param encryption
 * @param secret
 * @returns {string}
 */
export const encrypt = (encryption, secret) => {
  const secretKey = secret ? secret : appConfig.autologinSecret;
  const encryptedBytes =  CryptoJS.AES.encrypt(encryption, secretKey).toString();
  return CryptoJS.enc.Base64.parse(encryptedBytes).toString(CryptoJS.enc.Hex);
};

/**
 *
 * @param decryption
 * @param secret
 * @returns {string}
 */
export const decrypt = (decryption, secret) => {
  const secretKey = secret ? secret : appConfig.autologinSecret;
  const base64Token = CryptoJS.enc.Hex.parse(decryption);
  const decryptedBytes = base64Token.toString(CryptoJS.enc.Base64);
  const decryptedData = CryptoJS.AES.decrypt(decryptedBytes, secretKey);
  return decryptedData.toString(CryptoJS.enc.Utf8);
};

/**
 * @param callback
 */
export const ip = (callback) => {
  fetch('https://api.ipify.org?format=json')
    .then(x => x.json())
    .then(({ ip }) => {
      callback(ip);
    });
};

/**
 * @param obj
 */
export const removeEmptyProperties = (obj) => {
  Object.keys(obj).forEach(function(key) {
    if (obj[key] && typeof obj[key] === 'object') removeEmptyProperties(obj[key]); //recursive for objects
    else if (obj[key] == null || obj[key]== "") delete obj[key];         //remove empty properties
    if (typeof obj[key] === 'object' && Object.keys(obj[key]).length == 0) delete obj[key]; //remove empty objects
  });
};

/**
 * 
 * @param {*} data 
 * @param {*} locale 
 * @param {*} fallback 
 * @returns 
 */
export const localize = (data, locale = 'en', fallback = '') => {
  if (!data) {
    return '';
  }
  let content = !!data[locale] ? data[locale] : data[appConfig.fallbackLocale]

  return !!content ? content : fallback
};

/**
 * @param name
 * @param data
 */
export const exportXLSX = (name, data) => {
  let sheet = XLSX.utils.json_to_sheet(data);
  let wb = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(wb, sheet);
  XLSX.writeFile(wb, `${name}.xlsx`);
};

/**
 * @param name
 * @param data
 */
 export const exportCSV = (name, data) => {
  let sheet = XLSX.utils.json_to_sheet(data);
  let wb = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(wb, sheet);
  XLSX.writeFile(wb, `${name}.csv`);
};

/**
 * @param obj
 * @param form
 * @param namespace
 * @returns {FormData}
 */
export const objectToFormData = (obj, form, namespace) => {
  let fd = form || new FormData();
  let formKey;

  if (Array.isArray(obj) && obj.length === 0) {
    fd.append(namespace, []);
  }

  for (let property in obj) {
    if (!obj.hasOwnProperty(property)) {
      continue;
    }

    formKey = namespace ? namespace + "[" + property + "]" : property;

    if (
      typeof obj[property] === "object" &&
      !(obj[property] instanceof File) &&
      obj[property] !== null
    ) {
      objectToFormData(obj[property], fd, formKey);
    } else {
      if (obj[property] !== false) {
        const value = obj[property] === null ? '' : obj[property];
        fd.append(formKey, value);
      }
    }
  }

  return fd;
}

/**
 * @param response
 */
export const downloadAxiosResponse = response => {
  let link = document.createElement('a');
  let url = window.URL.createObjectURL(new Blob([response.data]));
  let filename = response.headers['content-disposition'].split('filename=')[1];

  filename = filename.replaceAll('"', '');

  link.setAttribute('href', url);
  link.setAttribute('download', filename);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 *
 * @param {*} birthdate
 * @param {*} startDate
 * @param {*} endDate
 * @returns
 */
export const isDateBetween = (date, options = {}) => {
  let startDate = moment(options.start_date ? options.start_date : null);
  let endDate = moment(options.end_date ? options.end_date : null);

  return date.isBetween(startDate, endDate, 'null', []);
}

/**
 * 
 * @param {*} func 
 * @param {*} wait 
 * @returns 
 */
export const debounce = (func, wait) => {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

/**
 * 
 * @param {*} text 
 * @returns 
 */
export const slugify = (text) => {
  return text
    .toString()                   // Cast to string (optional)
    .normalize('NFKD')            // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase()                // Convert the string to lowercase letters
    .trim()                       // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, '-')         // Replace spaces with -
    .replace(/[^\w\-]+/g, '')     // Remove all non-word chars
    .replace(/\_/g,'-')           // Replace _ with -
    .replace(/\-\-+/g, '-')       // Replace multiple - with single -
    .replace(/\-$/g, '');         // Remove trailing -
}
