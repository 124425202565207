import action from "@/store/core/action";

import {
  ACTIVATE_CHANNEL_MANAGER,
  ACTIVATE_CHANNEL_MANAGER_SUCCESS,
  ACTIVATE_CHANNEL_MANAGER_FAIL,
} from "./actions";

const actionsProcessed = action.processMultiple([
  {
    method: 'post',
    name: 'getActivateRequest',
    action: ACTIVATE_CHANNEL_MANAGER,
    endPoint: 'channel-managers/activate/{id}',
    mutations: {
      fail: ACTIVATE_CHANNEL_MANAGER_FAIL,
      success: ACTIVATE_CHANNEL_MANAGER_SUCCESS,
    },
  }
]);

const actions = {
  ...actionsProcessed.actions,
};

const getters = {};

const state = {
  ...actionsProcessed.state,
};

const mutations = {
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
