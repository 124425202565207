export const GET_BOOKING_NETWORKS_LIST = "[BOOKING_NETWORKS] List Get";
export const GET_BOOKING_NETWORKS_LIST_SUCCESS = "[BOOKING_NETWORKS] List Get Success";
export const GET_BOOKING_NETWORKS_LIST_FAIL = "[BOOKING_NETWORKS] List Get Fail";

export const GET_BOOKING_NETWORKS_PAGINATION = "[BOOKING_NETWORKS] Pagination Get";
export const GET_BOOKING_NETWORKS_PAGINATION_SUCCESS = "[BOOKING_NETWORKS] Pagination Get Success";
export const GET_BOOKING_NETWORKS_PAGINATION_FAIL = "[BOOKING_NETWORKS] Pagination Get Fail";

export const GET_BOOKING_NETWORKS_COLLECTION = "[BOOKING_NETWORKS] Collection Get";
export const GET_BOOKING_NETWORKS_COLLECTION_SUCCESS = "[BOOKING_NETWORKS] Collection Get Success";
export const GET_BOOKING_NETWORKS_COLLECTION_FAIL = "[BOOKING_NETWORKS] Collection Get Fail";

export const GET_BOOKING_NETWORKS_SINGLE = "[BOOKING_NETWORKS] Single Get";
export const GET_BOOKING_NETWORKS_SINGLE_SUCCESS = "[BOOKING_NETWORKS] Single Get Success";
export const GET_BOOKING_NETWORKS_SINGLE_FAIL = "[BOOKING_NETWORKS] Single Get Fail";

export const CREATE_BOOKING_NETWORKS = "[BOOKING_NETWORKS] Create";
export const CREATE_BOOKING_NETWORKS_SUCCESS = "[BOOKING_NETWORKS] Create Success";
export const CREATE_BOOKING_NETWORKS_FAIL = "[BOOKING_NETWORKS] Create Fail";
export const CREATE_BOOKING_NETWORKS_RESET = "[BOOKING_NETWORKS] Create Reset";

export const UPDATE_BOOKING_NETWORKS = "[BOOKING_NETWORKS] Update";
export const UPDATE_BOOKING_NETWORKS_SUCCESS = "[BOOKING_NETWORKS] Update Success";
export const UPDATE_BOOKING_NETWORKS_FAIL = "[BOOKING_NETWORKS] Update Fail";
export const UPDATE_BOOKING_NETWORKS_RESET = "[BOOKING_NETWORKS] Update Reset";

export const REMOVE_BOOKING_NETWORKS = "[BOOKING_NETWORKS] Remove";
export const REMOVE_BOOKING_NETWORKS_SUCCESS = "[BOOKING_NETWORKS] Remove Success";
export const REMOVE_BOOKING_NETWORKS_FAIL = "[BOOKING_NETWORKS] Remove Fail";

export const REGENERATE_BOOKING_NETWORK_RATES = "[BOOKING_NETWORKS] Regenerate Rates";
export const REGENERATE_BOOKING_NETWORK_RATES_SUCCESS = "[BOOKING_NETWORKS] Regenerate Rates Success";
export const REGENERATE_BOOKING_NETWORK_RATES_FAIL = "[BOOKING_NETWORKS] Regenerate Rates Fail";
