<template>
  <span>
    <v-autocomplete
      v-model="ids"
      :items="items"
      :label="label"
      :multiple="multiple"
      :small-chips="smallChips"
      :deletable-chips="deletableChips"
      :hint="hint"
      :persistent-hint="persistentHint"
      :loading="loading"
      :clearable="clearable"
      :disabled="disabled"
      :readonly="readonly"
      :dark="dark"
      @change="change"
      @blur="blur"
      prepend-icon="mdi-plus"
      @click:prepend="openCreation"
      :dense="dense"
      :error="error"
      :error-messages="!!errorMessages ? errorMessages : []"
    >
    </v-autocomplete>

    <v-dialog v-model="isOpenCreation" transition="dialog-bottom-transition" width="500">
      <creation-form
        v-if="isOpenCreation"
        :provider="options.provider"
        :company-id="options.companyId"
        @cancel="closeCreation"
        @error="failedCreation"
        @success="successCreation"
      ></creation-form>
    </v-dialog>
  </span>
</template>

<script>
  import { mapState } from "vuex";

  import {
    GET_COMPANY_SMART_DEVICE_ACCOUNTS_LIST
  } from "@/store/company-smart-device-accounts/actions";

  import CreationForm from "@/pages/company-smart-device-accounts/CreationForm";

  export default {
    name: "CSmartDeviceAccountsAutocomplete",
    components: {
      CreationForm
    },
    props: {
      label: {
        type: String,
        required: false,
        default: 'Smart Device Accounts'
      },
      hint: {
        type: String,
        default: ""
      },
      persistentHint: {
        type: Boolean,
        default: false
      },
      value: {
        default: null
      },
      error: {
        default: false
      },
      errorMessages: {
        default: () => []
      },
      dense: {
        type: Boolean,
        default: false
      },
      clearable: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      readonly: {
        type: Boolean,
        default: false
      },
      dark: {
        type: Boolean,
        default: false
      },
      smallChips: {
        type: Boolean,
        default: false
      },
      deletableChips: {
        type: Boolean,
        default: false
      },
      options: {
        type: Object,
        default: () => {
          return {};
        }
      }
    },
    data() {
      return {
        ids: null,
        selectedIds: [],
        cache: false,
        isOpenCreation: false,
      };
    },
    beforeMount() {
      if (!this.isListCached || Object.keys(this.options).length) {
        this.getItems();
        this.cache = true;
      }
    },
    mounted() {
      this.ids = this.value;
    },
    methods: {
      getItems() {
        let filters = {};

        if (!!this.options.provider) {
          filters.provider = this.options.provider;
        }
        if (!!this.options.companyId) {
          filters.company_id = this.options.companyId;
        }

        this.$store.dispatch(GET_COMPANY_SMART_DEVICE_ACCOUNTS_LIST, {
          sortBy: 'name',
          order: 'asc',
          ...filters,
        });
      },
      change() {
        this.$emit("change");
      },
      blur() {
        this.$emit("blur");
      },
      openCreation() {
        this.isOpenCreation = true;
      },
      closeCreation() {
        this.isOpenCreation = false;
      },
      successCreation(response) {

      },
      failedCreation(response) {

      },
    },
    computed: {
      ...mapState({
        itemsRequest: state => state.companySmartDeviceAccounts.getListRequest,
      }),
      items() {
        if (!this.itemsRequest.list) {
          return [];
        }

        return this.itemsRequest.list;
      },
      loading() {
        return this.itemsRequest.loading;
      },
      isListCached() {
        return this.itemsRequest.success;
      },
    },
    watch: {
      ids(newVal) {
        this.$emit('input', newVal);
      },
      value(newVal) {
        this.ids = newVal;
      },
      'options.provider'() {
        this.getItems();
      }
    }
  };
</script>
