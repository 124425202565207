export const GET_PROPERTY_COSTS_LIST = "[PROPERTYCOSTS] List Get";
export const GET_PROPERTY_COSTS_LIST_SUCCESS = "[PROPERTYCOSTS] List Get Success";
export const GET_PROPERTY_COSTS_LIST_FAIL = "[PROPERTYCOSTS] List Get Fail";

export const GET_PROPERTY_COSTS_PAGINATION = "[PROPERTYCOSTS] Pagination Get";
export const GET_PROPERTY_COSTS_PAGINATION_SUCCESS = "[PROPERTYCOSTS] Pagination Get Success";
export const GET_PROPERTY_COSTS_PAGINATION_FAIL = "[PROPERTYCOSTS] Pagination Get Fail";

export const GET_PROPERTY_COSTS_COLLECTION = "[PROPERTYCOSTS] Collection Get";
export const GET_PROPERTY_COSTS_COLLECTION_SUCCESS = "[PROPERTYCOSTS] Collection Get Success";
export const GET_PROPERTY_COSTS_COLLECTION_FAIL = "[PROPERTYCOSTS] Collection Get Fail";

export const GET_PROPERTY_COSTS_SINGLE = "[PROPERTYCOSTS] Single Get";
export const GET_PROPERTY_COSTS_SINGLE_SUCCESS = "[PROPERTYCOSTS] Single Get Success";
export const GET_PROPERTY_COSTS_SINGLE_FAIL = "[PROPERTYCOSTS] Single Get Fail";

export const CREATE_PROPERTY_COSTS = "[PROPERTYCOSTS] Create";
export const CREATE_PROPERTY_COSTS_SUCCESS = "[PROPERTYCOSTS] Create Success";
export const CREATE_PROPERTY_COSTS_FAIL = "[PROPERTYCOSTS] Create Fail";
export const CREATE_PROPERTY_COSTS_RESET = "[PROPERTYCOSTS] Create Reset";

export const BULK_CREATE_PROPERTY_COSTS = "[PROPERTYCOSTS] Bulk Create";
export const BULK_CREATE_PROPERTY_COSTS_SUCCESS = "[PROPERTYCOSTS] Bulk Create Success";
export const BULK_CREATE_PROPERTY_COSTS_FAIL = "[PROPERTYCOSTS] Bulk Create Fail";
export const BULK_CREATE_PROPERTY_COSTS_RESET = "[PROPERTYCOSTS] Bulk Create Reset";

export const UPDATE_PROPERTY_COSTS = "[PROPERTYCOSTS] Update";
export const UPDATE_PROPERTY_COSTS_SUCCESS = "[PROPERTYCOSTS] Update Success";
export const UPDATE_PROPERTY_COSTS_FAIL = "[PROPERTYCOSTS] Update Fail";
export const UPDATE_PROPERTY_COSTS_RESET = "[PROPERTYCOSTS] Update Reset";

export const REMOVE_PROPERTY_COSTS = "[PROPERTYCOSTS] Remove";
export const REMOVE_PROPERTY_COSTS_SUCCESS = "[PROPERTYCOSTS] Remove Success";
export const REMOVE_PROPERTY_COSTS_FAIL = "[PROPERTYCOSTS] Remove Fail";

export const BULK_REMOVE_PROPERTY_COSTS = "[PROPERTYCOSTS] Bulk Remove";
export const BULK_REMOVE_PROPERTY_COSTS_SUCCESS = "[PROPERTYCOSTS] Bulk Remove Success";
export const BULK_REMOVE_PROPERTY_COSTS_FAIL = "[PROPERTYCOSTS] Bulk Remove Fail";
