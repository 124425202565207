import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_BOOKING_NETWORKS_LIST,
  GET_BOOKING_NETWORKS_LIST_SUCCESS,
  GET_BOOKING_NETWORKS_LIST_FAIL,
  CREATE_BOOKING_NETWORKS,
  CREATE_BOOKING_NETWORKS_FAIL,
  CREATE_BOOKING_NETWORKS_RESET,
  CREATE_BOOKING_NETWORKS_SUCCESS,
  GET_BOOKING_NETWORKS_COLLECTION,
  GET_BOOKING_NETWORKS_COLLECTION_FAIL,
  GET_BOOKING_NETWORKS_COLLECTION_SUCCESS,
  GET_BOOKING_NETWORKS_SINGLE,
  GET_BOOKING_NETWORKS_SINGLE_FAIL,
  GET_BOOKING_NETWORKS_SINGLE_SUCCESS,
  REMOVE_BOOKING_NETWORKS,
  REMOVE_BOOKING_NETWORKS_FAIL,
  REMOVE_BOOKING_NETWORKS_SUCCESS,
  UPDATE_BOOKING_NETWORKS,
  UPDATE_BOOKING_NETWORKS_FAIL,
  UPDATE_BOOKING_NETWORKS_RESET,
  UPDATE_BOOKING_NETWORKS_SUCCESS,
  GET_BOOKING_NETWORKS_PAGINATION,
  GET_BOOKING_NETWORKS_PAGINATION_FAIL,
  GET_BOOKING_NETWORKS_PAGINATION_SUCCESS,
  REGENERATE_BOOKING_NETWORK_RATES,
  REGENERATE_BOOKING_NETWORK_RATES_FAIL,
  REGENERATE_BOOKING_NETWORK_RATES_SUCCESS
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_BOOKING_NETWORKS_PAGINATION,
    endPoint: "booking-networks/paginated",
    mutationFail: GET_BOOKING_NETWORKS_PAGINATION_FAIL,
    mutationSuccess: GET_BOOKING_NETWORKS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_BOOKING_NETWORKS_COLLECTION,
    endPoint: "booking-networks/collected",
    mutationFail: GET_BOOKING_NETWORKS_COLLECTION_FAIL,
    mutationSuccess: GET_BOOKING_NETWORKS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_BOOKING_NETWORKS_SINGLE,
    endPoint: "booking-networks/single",
    mutationFail: GET_BOOKING_NETWORKS_SINGLE_FAIL,
    mutationSuccess: GET_BOOKING_NETWORKS_SINGLE_SUCCESS
  },
  creationRequest: {
    isUpload: true,
    action: CREATE_BOOKING_NETWORKS,
    actionReset: CREATE_BOOKING_NETWORKS_RESET,
    endPoint: "booking-networks/create",
    mutationFail: CREATE_BOOKING_NETWORKS_FAIL,
    mutationSuccess: CREATE_BOOKING_NETWORKS_SUCCESS
  },
  editingRequest: {
    isUpload: true,
    action: UPDATE_BOOKING_NETWORKS,
    actionReset: UPDATE_BOOKING_NETWORKS_RESET,
    endPoint: "booking-networks/update",
    mutationFail: UPDATE_BOOKING_NETWORKS_FAIL,
    mutationSuccess: UPDATE_BOOKING_NETWORKS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_BOOKING_NETWORKS,
    endPoint: "booking-networks/delete",
    mutationFail: REMOVE_BOOKING_NETWORKS_FAIL,
    mutationSuccess: REMOVE_BOOKING_NETWORKS_SUCCESS
  }
});

const listProcessed = list.process({
  getListRequest: {
    action: GET_BOOKING_NETWORKS_LIST,
    endPoint: "booking-networks/list",
    mutationFail: GET_BOOKING_NETWORKS_LIST_FAIL,
    mutationSuccess: GET_BOOKING_NETWORKS_LIST_SUCCESS
  },
});

const actionsProcessed = action.processMultiple([
  {
    name: 'getRegenerateRatesRequest',
    action: REGENERATE_BOOKING_NETWORK_RATES,
    endPoint: "booking-networks/regenerate-rates/{id}",
    mutations: {
      fail: REGENERATE_BOOKING_NETWORK_RATES_FAIL,
      success: REGENERATE_BOOKING_NETWORK_RATES_SUCCESS,
    }
  }
]);

const actions = {
  ...crudProcessed.actions,
  ...listProcessed.actions,
  ...actionsProcessed.actions,
};

const getters = {};

const state = {
  ...crudProcessed.state,
  ...listProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...crudProcessed.mutations,
  ...listProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
