import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_WORK_ORDERS_LIST,
  GET_WORK_ORDERS_LIST_SUCCESS,
  GET_WORK_ORDERS_LIST_FAIL,
  GET_WORK_ORDERS_PAGINATION,
  GET_WORK_ORDERS_PAGINATION_SUCCESS,
  GET_WORK_ORDERS_PAGINATION_FAIL,
  GET_WORK_ORDERS_COLLECTION,
  GET_WORK_ORDERS_COLLECTION_SUCCESS,
  GET_WORK_ORDERS_COLLECTION_FAIL,
  GET_WORK_ORDERS_SINGLE,
  GET_WORK_ORDERS_SINGLE_SUCCESS,
  GET_WORK_ORDERS_SINGLE_FAIL,
  CREATE_WORK_ORDERS,
  CREATE_WORK_ORDERS_SUCCESS,
  CREATE_WORK_ORDERS_FAIL,
  CREATE_WORK_ORDERS_RESET,
  UPDATE_WORK_ORDERS,
  UPDATE_WORK_ORDERS_SUCCESS,
  UPDATE_WORK_ORDERS_FAIL,
  REPLACE_WORK_ORDERS,
  REPLACE_WORK_ORDERS_SUCCESS,
  REPLACE_WORK_ORDERS_FAIL,
  REPLACE_WORK_ORDERS_RESET,
  UPDATE_WORK_ORDERS_RESET,
  REMOVE_WORK_ORDERS,
  REMOVE_WORK_ORDERS_SUCCESS,
  REMOVE_WORK_ORDERS_FAIL
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_WORK_ORDERS_LIST,
    endPoint: "work-orders/list",
    mutationFail: GET_WORK_ORDERS_LIST_FAIL,
    mutationSuccess: GET_WORK_ORDERS_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_WORK_ORDERS_PAGINATION,
    endPoint: "work-orders/paginated",
    mutationFail: GET_WORK_ORDERS_PAGINATION_FAIL,
    mutationSuccess: GET_WORK_ORDERS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_WORK_ORDERS_COLLECTION,
    endPoint: "work-orders/collected",
    mutationFail: GET_WORK_ORDERS_COLLECTION_FAIL,
    mutationSuccess: GET_WORK_ORDERS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_WORK_ORDERS_SINGLE,
    endPoint: "work-orders/single",
    mutationFail: GET_WORK_ORDERS_SINGLE_FAIL,
    mutationSuccess: GET_WORK_ORDERS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_WORK_ORDERS,
    actionReset: CREATE_WORK_ORDERS_RESET,
    endPoint: "work-orders/create",
    mutationFail: CREATE_WORK_ORDERS_FAIL,
    mutationSuccess: CREATE_WORK_ORDERS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_WORK_ORDERS,
    actionReset: UPDATE_WORK_ORDERS_RESET,
    endPoint: "work-orders/update",
    mutationFail: UPDATE_WORK_ORDERS_FAIL,
    mutationSuccess: UPDATE_WORK_ORDERS_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_WORK_ORDERS,
    actionReset: REPLACE_WORK_ORDERS_RESET,
    endPoint: "work-orders/replace",
    mutationFail: REPLACE_WORK_ORDERS_FAIL,
    mutationSuccess: REPLACE_WORK_ORDERS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_WORK_ORDERS,
    endPoint: "work-orders/delete",
    mutationFail: REMOVE_WORK_ORDERS_FAIL,
    mutationSuccess: REMOVE_WORK_ORDERS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
