import Vue from "vue";
import VueDebounce from 'vue-debounce';
import VueClipboard from 'vue-clipboard2';
import VueLazyInput from 'vue-lazy-input';
import VueMoment from 'vue-moment';
import VueDragDrop from 'vue-drag-drop';
import VueScrollLoader from 'vue-scroll-loader';
import Vuelidate from 'vuelidate';
import moment from 'moment';
// import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-2';
import Tawk from 'vue2-tawk';
import VueMobileDetection from "vue-mobile-detection";
import VueStripeWrapper from "@/libraries/vue-stripe-wrapper/src";
import VueSignaturePad from 'vue-signature-pad';
import VueMermaid from "vue-mermaid";
// import VueShepherd from 'vue-shepherd';
import VMask from 'v-mask';
import VueHtmlToPaper from 'vue-html-to-paper';

import stripeConfig from '@/config/stripe';
import chatConfig from '@/config/chat';

const LibrariesService = {
  /**
   *
   */
  init() {
    this.initVueMoment();
    this.initVuelidate();
    this.initVueDragDrop();
    this.initVueDebounce();
    this.initVueClipBoard();
    this.initVueLazyInput();
    this.initVueScrollLoader();
    this.initVueTawk();
    this.initVueMobileDetection();
    this.initVueStripeWrapper();
    this.initVueSignaturePad();
    this.initVueMermaid();
    // this.initVueSheperd();
    this.initVMask();
    this.initVueHtmlToPaper();
  },

  /**
   *
   */
  initVueLazyInput() {
    Vue.use(VueLazyInput);
  },

  /**
   *
   */
  initVueMoment() {
    Vue.use(VueMoment, {
      moment
    })
  },

  /**
   *
   */
  initVueDragDrop() {
    Vue.use(VueDragDrop);
  },

  /**
   *
   */
  initVueScrollLoader() {
    Vue.use(VueScrollLoader);
  },

  /**
   *
   */
  initVuelidate() {
    Vue.use(Vuelidate);
  },

  /**
   *
   */
  initVueDebounce() {
    Vue.use(VueDebounce);
  },

  /**
   *
   */
  initVueClipBoard() {
    VueClipboard.config.autoSetContainer = true;
    Vue.use(VueClipboard);
  },

  /**
   *
   */
  initVueTawk() {
    Vue.use(Tawk, {
      propertyId : chatConfig.propertyId,
      widgetId : chatConfig.widgetId,
    });
  },

  /**
   *
   */
  initVueMobileDetection() {
    Vue.use(VueMobileDetection);
  },

  /**
   *
   */
  initVueStripeWrapper() {
    Vue.use(VueStripeWrapper, {
      secret: stripeConfig.keys.public
    })
  },

  /**
   *
   */
  initVueSignaturePad() {
    Vue.use(VueSignaturePad);
  },

  /**
   *
   */
  initVueMermaid() {
    Vue.use(VueMermaid);
  },

  /**
   *
   */
  // initVueSheperd() {
  //   Vue.use(VueShepherd);
  // },

  /**
   *
   */
   initVMask() {
    Vue.use(VMask);
  },

  /**
   *
   */
  initVueHtmlToPaper() {
    const options = {
      name: '_blank',
      specs: [
        'fullscreen=no',
        'titlebar=no',
        'scrollbars=no'
      ],
      timeout: 1000,
      autoClose: true,
      windowTitle: window.document.title,
    }
    
    Vue.use(VueHtmlToPaper, options);
  },
};

export default LibrariesService;
