export default [
  {
    name: "derivations",
    path: "/derivations",
    component: () => import(/*webpackChunkName: "derivations" */ "@/pages/derivations/Index"),
    meta: {
      title: 'titles.derivations'
    }
  }
];
