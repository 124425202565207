import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_PROPERTY_REVENUE_SHARE_RULES_LIST,
  GET_PROPERTY_REVENUE_SHARE_RULES_LIST_SUCCESS,
  GET_PROPERTY_REVENUE_SHARE_RULES_LIST_FAIL,
  GET_PROPERTY_REVENUE_SHARE_RULES_PAGINATION,
  GET_PROPERTY_REVENUE_SHARE_RULES_PAGINATION_SUCCESS,
  GET_PROPERTY_REVENUE_SHARE_RULES_PAGINATION_FAIL,
  GET_PROPERTY_REVENUE_SHARE_RULES_COLLECTION,
  GET_PROPERTY_REVENUE_SHARE_RULES_COLLECTION_SUCCESS,
  GET_PROPERTY_REVENUE_SHARE_RULES_COLLECTION_FAIL,
  GET_PROPERTY_REVENUE_SHARE_RULES_SINGLE,
  GET_PROPERTY_REVENUE_SHARE_RULES_SINGLE_SUCCESS,
  GET_PROPERTY_REVENUE_SHARE_RULES_SINGLE_FAIL,
  CREATE_PROPERTY_REVENUE_SHARE_RULES,
  CREATE_PROPERTY_REVENUE_SHARE_RULES_SUCCESS,
  CREATE_PROPERTY_REVENUE_SHARE_RULES_FAIL,
  CREATE_PROPERTY_REVENUE_SHARE_RULES_RESET,
  UPDATE_PROPERTY_REVENUE_SHARE_RULES,
  UPDATE_PROPERTY_REVENUE_SHARE_RULES_SUCCESS,
  UPDATE_PROPERTY_REVENUE_SHARE_RULES_FAIL,
  UPDATE_PROPERTY_REVENUE_SHARE_RULES_RESET,
  REMOVE_PROPERTY_REVENUE_SHARE_RULES,
  REMOVE_PROPERTY_REVENUE_SHARE_RULES_SUCCESS,
  REMOVE_PROPERTY_REVENUE_SHARE_RULES_FAIL,

  BULK_UPDATE_REVENUE_SHARE_RULES,
  BULK_UPDATE_REVENUE_SHARE_RULES_SUCCESS,
  BULK_UPDATE_REVENUE_SHARE_RULES_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_PROPERTY_REVENUE_SHARE_RULES_LIST,
    endPoint: "/property/revenue-share/rule/list",
    mutationFail: GET_PROPERTY_REVENUE_SHARE_RULES_LIST_FAIL,
    mutationSuccess: GET_PROPERTY_REVENUE_SHARE_RULES_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_PROPERTY_REVENUE_SHARE_RULES_PAGINATION,
    endPoint: "/property/revenue-share/rule/paginated",
    mutationFail: GET_PROPERTY_REVENUE_SHARE_RULES_PAGINATION_FAIL,
    mutationSuccess: GET_PROPERTY_REVENUE_SHARE_RULES_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_PROPERTY_REVENUE_SHARE_RULES_COLLECTION,
    endPoint: "/property/revenue-share/rule/collected",
    mutationFail: GET_PROPERTY_REVENUE_SHARE_RULES_COLLECTION_FAIL,
    mutationSuccess: GET_PROPERTY_REVENUE_SHARE_RULES_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_PROPERTY_REVENUE_SHARE_RULES_SINGLE,
    endPoint: "/property/revenue-share/rule/single",
    mutationFail: GET_PROPERTY_REVENUE_SHARE_RULES_SINGLE_FAIL,
    mutationSuccess: GET_PROPERTY_REVENUE_SHARE_RULES_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_PROPERTY_REVENUE_SHARE_RULES,
    actionReset: CREATE_PROPERTY_REVENUE_SHARE_RULES_RESET,
    endPoint: "/property/revenue-share/rule/create",
    mutationFail: CREATE_PROPERTY_REVENUE_SHARE_RULES_FAIL,
    mutationSuccess: CREATE_PROPERTY_REVENUE_SHARE_RULES_SUCCESS
  },
  editingRequest: {
    action: UPDATE_PROPERTY_REVENUE_SHARE_RULES,
    actionReset: UPDATE_PROPERTY_REVENUE_SHARE_RULES_RESET,
    endPoint: "/property/revenue-share/rule/update",
    mutationFail: UPDATE_PROPERTY_REVENUE_SHARE_RULES_FAIL,
    mutationSuccess: UPDATE_PROPERTY_REVENUE_SHARE_RULES_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_PROPERTY_REVENUE_SHARE_RULES,
    endPoint: "/property/revenue-share/rule/delete",
    mutationFail: REMOVE_PROPERTY_REVENUE_SHARE_RULES_FAIL,
    mutationSuccess: REMOVE_PROPERTY_REVENUE_SHARE_RULES_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    name: 'bulkUpdate',
    method: 'post',
    action: BULK_UPDATE_REVENUE_SHARE_RULES,
    endPoint: '/property/revenue-share/rule/bulk-update',
    mutations: {
      fail: BULK_UPDATE_REVENUE_SHARE_RULES_FAIL,
      success: BULK_UPDATE_REVENUE_SHARE_RULES_SUCCESS
    }
  }
]);

const getters = {};

const actions = {
  ...crudProcessed.actions,
  ...listProcessed.actions,
  ...actionsProcessed.actions,
};

const state = {
  ...crudProcessed.state,
  ...listProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...crudProcessed.mutations,
  ...listProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
