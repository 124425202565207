import ApiService from "@/services/api";

import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_PROPERTY_META_LIST,
  GET_PROPERTY_META_LIST_SUCCESS,
  GET_PROPERTY_META_LIST_FAIL,
  GET_PROPERTY_META_PAGINATION,
  GET_PROPERTY_META_PAGINATION_SUCCESS,
  GET_PROPERTY_META_PAGINATION_FAIL,
  GET_PROPERTY_META_COLLECTION,
  GET_PROPERTY_META_COLLECTION_SUCCESS,
  GET_PROPERTY_META_COLLECTION_FAIL,
  GET_PROPERTY_META_SINGLE,
  GET_PROPERTY_META_SINGLE_SUCCESS,
  GET_PROPERTY_META_SINGLE_FAIL,
  CREATE_PROPERTY_META,
  CREATE_PROPERTY_META_SUCCESS,
  CREATE_PROPERTY_META_FAIL,
  CREATE_PROPERTY_META_RESET,
  UPDATE_PROPERTY_META,
  UPDATE_PROPERTY_META_SUCCESS,
  UPDATE_PROPERTY_META_FAIL,
  UPDATE_PROPERTY_META_RESET,
  REPLACE_PROPERTY_META,
  REPLACE_PROPERTY_META_SUCCESS,
  REPLACE_PROPERTY_META_FAIL,
  REPLACE_PROPERTY_META_RESET,
  REMOVE_PROPERTY_META,
  REMOVE_PROPERTY_META_SUCCESS,
  REMOVE_PROPERTY_META_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_PROPERTY_META_LIST,
    endPoint: "property-meta/list",
    mutationFail: GET_PROPERTY_META_LIST_FAIL,
    mutationSuccess: GET_PROPERTY_META_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_PROPERTY_META_PAGINATION,
    endPoint: "property-meta/paginated",
    mutationFail: GET_PROPERTY_META_PAGINATION_FAIL,
    mutationSuccess: GET_PROPERTY_META_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_PROPERTY_META_COLLECTION,
    endPoint: "property-meta/collected",
    mutationFail: GET_PROPERTY_META_COLLECTION_FAIL,
    mutationSuccess: GET_PROPERTY_META_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_PROPERTY_META_SINGLE,
    endPoint: "property-meta/single",
    mutationFail: GET_PROPERTY_META_SINGLE_FAIL,
    mutationSuccess: GET_PROPERTY_META_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_PROPERTY_META,
    actionReset: CREATE_PROPERTY_META_RESET,
    endPoint: "property-meta/create",
    mutationFail: CREATE_PROPERTY_META_FAIL,
    mutationSuccess: CREATE_PROPERTY_META_SUCCESS
  },
  editingRequest: {
    action: UPDATE_PROPERTY_META,
    actionReset: UPDATE_PROPERTY_META_RESET,
    endPoint: "property-meta/update",
    mutationFail: UPDATE_PROPERTY_META_FAIL,
    mutationSuccess: UPDATE_PROPERTY_META_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_PROPERTY_META,
    actionReset: REPLACE_PROPERTY_META_RESET,
    endPoint: "property-meta/replace",
    mutationFail: REPLACE_PROPERTY_META_FAIL,
    mutationSuccess: REPLACE_PROPERTY_META_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_PROPERTY_META,
    endPoint: "property-meta/delete",
    mutationFail: REMOVE_PROPERTY_META_FAIL,
    mutationSuccess: REMOVE_PROPERTY_META_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.state,
};

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
