import list from "@/store/core/list";
import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_QUOTES_LIST,
  GET_QUOTES_LIST_SUCCESS,
  GET_QUOTES_LIST_FAIL,
  GET_QUOTES_PAGINATION,
  GET_QUOTES_PAGINATION_SUCCESS,
  GET_QUOTES_PAGINATION_FAIL,
  GET_QUOTES_COLLECTION,
  GET_QUOTES_COLLECTION_SUCCESS,
  GET_QUOTES_COLLECTION_FAIL,
  GET_QUOTES_SINGLE,
  GET_QUOTES_SINGLE_SUCCESS,
  GET_QUOTES_SINGLE_FAIL,
  CREATE_QUOTES,
  CREATE_QUOTES_SUCCESS,
  CREATE_QUOTES_FAIL,
  CREATE_QUOTES_RESET,
  UPDATE_QUOTES,
  UPDATE_QUOTES_SUCCESS,
  UPDATE_QUOTES_FAIL,
  UPDATE_QUOTES_RESET,
  REMOVE_QUOTES,
  REMOVE_QUOTES_SUCCESS,
  REMOVE_QUOTES_FAIL,
  GET_QUOTES_SERIAL_N,
  GET_QUOTES_SERIAL_N_SUCCESS,
  GET_QUOTES_SERIAL_N_FAIL,
  SEND_QUOTES,
  SEND_QUOTES_SUCCESS,
  SEND_QUOTES_FAIL,
  SEND_QUOTES_RESET,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_QUOTES_LIST,
    endPoint: "quotes/list",
    mutationFail: GET_QUOTES_LIST_FAIL,
    mutationSuccess: GET_QUOTES_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_QUOTES_PAGINATION,
    endPoint: "quotes/paginated",
    mutationFail: GET_QUOTES_PAGINATION_FAIL,
    mutationSuccess: GET_QUOTES_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_QUOTES_COLLECTION,
    endPoint: "quotes/collected",
    mutationFail: GET_QUOTES_COLLECTION_FAIL,
    mutationSuccess: GET_QUOTES_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_QUOTES_SINGLE,
    endPoint: "quotes/single",
    mutationFail: GET_QUOTES_SINGLE_FAIL,
    mutationSuccess: GET_QUOTES_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_QUOTES,
    actionReset: CREATE_QUOTES_RESET,
    endPoint: "quotes/create",
    mutationFail: CREATE_QUOTES_FAIL,
    mutationSuccess: CREATE_QUOTES_SUCCESS
  },
  editingRequest: {
    action: UPDATE_QUOTES,
    actionReset: UPDATE_QUOTES_RESET,
    endPoint: "quotes/update",
    mutationFail: UPDATE_QUOTES_FAIL,
    mutationSuccess: UPDATE_QUOTES_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_QUOTES,
    endPoint: "quotes/delete",
    mutationFail: REMOVE_QUOTES_FAIL,
    mutationSuccess: REMOVE_QUOTES_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    name: 'getSerialNRequest',
    action: GET_QUOTES_SERIAL_N,
    endPoint: 'quotes/serial-n',
    mutations: {
      fail: GET_QUOTES_SERIAL_N_FAIL,
      success: GET_QUOTES_SERIAL_N_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'sendRequest',
    action: SEND_QUOTES,
    actionReset: SEND_QUOTES_RESET,
    endPoint: 'quotes/send/{id}',
    mutations: {
      fail: SEND_QUOTES_FAIL,
      success: SEND_QUOTES_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
