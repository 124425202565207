export const GET_PROPERTY_CLUSTERS_LIST = "[PROPERTY_CLUSTERS] List Get";
export const GET_PROPERTY_CLUSTERS_LIST_SUCCESS = "[PROPERTY_CLUSTERS] List Get Success";
export const GET_PROPERTY_CLUSTERS_LIST_FAIL = "[PROPERTY_CLUSTERS] List Get Fail";

export const GET_PROPERTY_CLUSTERS_PAGINATION = "[PROPERTY_CLUSTERS] Pagination Get";
export const GET_PROPERTY_CLUSTERS_PAGINATION_SUCCESS = "[PROPERTY_CLUSTERS] Pagination Get Success";
export const GET_PROPERTY_CLUSTERS_PAGINATION_FAIL = "[PROPERTY_CLUSTERS] Pagination Get Fail";

export const GET_PROPERTY_CLUSTERS_COLLECTION = "[PROPERTY_CLUSTERS] Collection Get";
export const GET_PROPERTY_CLUSTERS_COLLECTION_SUCCESS = "[PROPERTY_CLUSTERS] Collection Get Success";
export const GET_PROPERTY_CLUSTERS_COLLECTION_FAIL = "[PROPERTY_CLUSTERS] Collection Get Fail";

export const GET_PROPERTY_CLUSTERS_SINGLE = "[PROPERTY_CLUSTERS] Single Get";
export const GET_PROPERTY_CLUSTERS_SINGLE_SUCCESS = "[PROPERTY_CLUSTERS] Single Get Success";
export const GET_PROPERTY_CLUSTERS_SINGLE_FAIL = "[PROPERTY_CLUSTERS] Single Get Fail";

export const CREATE_PROPERTY_CLUSTERS = "[PROPERTY_CLUSTERS] Create";
export const CREATE_PROPERTY_CLUSTERS_SUCCESS = "[PROPERTY_CLUSTERS] Create Success";
export const CREATE_PROPERTY_CLUSTERS_FAIL = "[PROPERTY_CLUSTERS] Create Fail";
export const CREATE_PROPERTY_CLUSTERS_RESET = "[PROPERTY_CLUSTERS] Create Reset";

export const UPDATE_PROPERTY_CLUSTERS = "[PROPERTY_CLUSTERS] Update";
export const UPDATE_PROPERTY_CLUSTERS_SUCCESS = "[PROPERTY_CLUSTERS] Update Success";
export const UPDATE_PROPERTY_CLUSTERS_FAIL = "[PROPERTY_CLUSTERS] Update Fail";
export const UPDATE_PROPERTY_CLUSTERS_RESET = "[PROPERTY_CLUSTERS] Update Reset";

export const REMOVE_PROPERTY_CLUSTERS = "[PROPERTY_CLUSTERS] Remove";
export const REMOVE_PROPERTY_CLUSTERS_SUCCESS = "[PROPERTY_CLUSTERS] Remove Success";
export const REMOVE_PROPERTY_CLUSTERS_FAIL = "[PROPERTY_CLUSTERS] Remove Fail";
