export const GET_PROPERTY_PROMOTIONS_LIST = "[PROPERTY_PROMOTIONS] List Get";
export const GET_PROPERTY_PROMOTIONS_LIST_SUCCESS = "[PROPERTY_PROMOTIONS] List Get Success";
export const GET_PROPERTY_PROMOTIONS_LIST_FAIL = "[PROPERTY_PROMOTIONS] List Get Fail";

export const GET_PROPERTY_PROMOTIONS_PAGINATION = "[PROPERTY_PROMOTIONS] Pagination Get";
export const GET_PROPERTY_PROMOTIONS_PAGINATION_SUCCESS = "[PROPERTY_PROMOTIONS] Pagination Get Success";
export const GET_PROPERTY_PROMOTIONS_PAGINATION_FAIL = "[PROPERTY_PROMOTIONS] Pagination Get Fail";

export const GET_PROPERTY_PROMOTIONS_COLLECTION = "[PROPERTY_PROMOTIONS] Collection Get";
export const GET_PROPERTY_PROMOTIONS_COLLECTION_SUCCESS = "[PROPERTY_PROMOTIONS] Collection Get Success";
export const GET_PROPERTY_PROMOTIONS_COLLECTION_FAIL = "[PROPERTY_PROMOTIONS] Collection Get Fail";

export const GET_PROPERTY_PROMOTIONS_SINGLE = "[PROPERTY_PROMOTIONS] Single Get";
export const GET_PROPERTY_PROMOTIONS_SINGLE_SUCCESS = "[PROPERTY_PROMOTIONS] Single Get Success";
export const GET_PROPERTY_PROMOTIONS_SINGLE_FAIL = "[PROPERTY_PROMOTIONS] Single Get Fail";

export const CREATE_PROPERTY_PROMOTIONS = "[PROPERTY_PROMOTIONS] Create";
export const CREATE_PROPERTY_PROMOTIONS_SUCCESS = "[PROPERTY_PROMOTIONS] Create Success";
export const CREATE_PROPERTY_PROMOTIONS_FAIL = "[PROPERTY_PROMOTIONS] Create Fail";
export const CREATE_PROPERTY_PROMOTIONS_RESET = "[PROPERTY_PROMOTIONS] Create Reset";

export const UPDATE_PROPERTY_PROMOTIONS = "[PROPERTY_PROMOTIONS] Update";
export const UPDATE_PROPERTY_PROMOTIONS_SUCCESS = "[PROPERTY_PROMOTIONS] Update Success";
export const UPDATE_PROPERTY_PROMOTIONS_FAIL = "[PROPERTY_PROMOTIONS] Update Fail";
export const UPDATE_PROPERTY_PROMOTIONS_RESET = "[PROPERTY_PROMOTIONS] Update Reset";

export const REMOVE_PROPERTY_PROMOTIONS = "[PROPERTY_PROMOTIONS] Remove";
export const REMOVE_PROPERTY_PROMOTIONS_SUCCESS = "[PROPERTY_PROMOTIONS] Remove Success";
export const REMOVE_PROPERTY_PROMOTIONS_FAIL = "[PROPERTY_PROMOTIONS] Remove Fail";

