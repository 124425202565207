import ApiService from "@/services/api";

import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_HOUSEKEEPING_TASKS_LIST,
  GET_HOUSEKEEPING_TASKS_LIST_SUCCESS,
  GET_HOUSEKEEPING_TASKS_LIST_FAIL,
  GET_HOUSEKEEPING_TASKS_PAGINATION,
  GET_HOUSEKEEPING_TASKS_PAGINATION_SUCCESS,
  GET_HOUSEKEEPING_TASKS_PAGINATION_FAIL,
  GET_HOUSEKEEPING_TASKS_COLLECTION,
  GET_HOUSEKEEPING_TASKS_COLLECTION_SUCCESS,
  GET_HOUSEKEEPING_TASKS_COLLECTION_FAIL,
  GET_HOUSEKEEPING_TASKS_SINGLE,
  GET_HOUSEKEEPING_TASKS_SINGLE_SUCCESS,
  GET_HOUSEKEEPING_TASKS_SINGLE_FAIL,
  CREATE_HOUSEKEEPING_TASKS,
  CREATE_HOUSEKEEPING_TASKS_SUCCESS,
  CREATE_HOUSEKEEPING_TASKS_FAIL,
  CREATE_HOUSEKEEPING_TASKS_RESET,
  UPDATE_HOUSEKEEPING_TASKS,
  UPDATE_HOUSEKEEPING_TASKS_SUCCESS,
  UPDATE_HOUSEKEEPING_TASKS_FAIL,
  REPLACE_HOUSEKEEPING_TASKS,
  REPLACE_HOUSEKEEPING_TASKS_SUCCESS,
  REPLACE_HOUSEKEEPING_TASKS_FAIL,
  REPLACE_HOUSEKEEPING_TASKS_RESET,
  UPDATE_HOUSEKEEPING_TASKS_RESET,
  REMOVE_HOUSEKEEPING_TASKS,
  REMOVE_HOUSEKEEPING_TASKS_SUCCESS,
  REMOVE_HOUSEKEEPING_TASKS_FAIL,
  GET_HOUSEKEEPING_TASKS_2_PAGINATION,
  GET_HOUSEKEEPING_TASKS_2_PAGINATION_SUCCESS,
  GET_HOUSEKEEPING_TASKS_2_PAGINATION_FAIL,
  GET_HOUSEKEEPING_TASKS_REPORT,
  GET_HOUSEKEEPING_TASKS_REPORT_SUCCESS,
  GET_HOUSEKEEPING_TASKS_REPORT_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_HOUSEKEEPING_TASKS_LIST,
    endPoint: "housekeeping-tasks/list",
    mutationFail: GET_HOUSEKEEPING_TASKS_LIST_FAIL,
    mutationSuccess: GET_HOUSEKEEPING_TASKS_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_HOUSEKEEPING_TASKS_PAGINATION,
    endPoint: "housekeeping-tasks/paginated",
    mutationFail: GET_HOUSEKEEPING_TASKS_PAGINATION_FAIL,
    mutationSuccess: GET_HOUSEKEEPING_TASKS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_HOUSEKEEPING_TASKS_COLLECTION,
    endPoint: "housekeeping-tasks/collected",
    mutationFail: GET_HOUSEKEEPING_TASKS_COLLECTION_FAIL,
    mutationSuccess: GET_HOUSEKEEPING_TASKS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_HOUSEKEEPING_TASKS_SINGLE,
    endPoint: "housekeeping-tasks/single",
    mutationFail: GET_HOUSEKEEPING_TASKS_SINGLE_FAIL,
    mutationSuccess: GET_HOUSEKEEPING_TASKS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_HOUSEKEEPING_TASKS,
    actionReset: CREATE_HOUSEKEEPING_TASKS_RESET,
    endPoint: "housekeeping-tasks/create",
    mutationFail: CREATE_HOUSEKEEPING_TASKS_FAIL,
    mutationSuccess: CREATE_HOUSEKEEPING_TASKS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_HOUSEKEEPING_TASKS,
    actionReset: UPDATE_HOUSEKEEPING_TASKS_RESET,
    endPoint: "housekeeping-tasks/update",
    mutationFail: UPDATE_HOUSEKEEPING_TASKS_FAIL,
    mutationSuccess: UPDATE_HOUSEKEEPING_TASKS_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_HOUSEKEEPING_TASKS,
    actionReset: REPLACE_HOUSEKEEPING_TASKS_RESET,
    endPoint: "housekeeping-tasks/replace",
    mutationFail: REPLACE_HOUSEKEEPING_TASKS_FAIL,
    mutationSuccess: REPLACE_HOUSEKEEPING_TASKS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_HOUSEKEEPING_TASKS,
    endPoint: "housekeeping-tasks/delete",
    mutationFail: REMOVE_HOUSEKEEPING_TASKS_FAIL,
    mutationSuccess: REMOVE_HOUSEKEEPING_TASKS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    name: 'getHousekeepingReport',
    action: GET_HOUSEKEEPING_TASKS_REPORT,
    endPoint: 'housekeeping-tasks/housekeeping-report',
    mutations: {
      fail: GET_HOUSEKEEPING_TASKS_REPORT_FAIL,
      success: GET_HOUSEKEEPING_TASKS_REPORT_SUCCESS,
    },
  },
]);

const state = {
  getHousekeepingPaginationRequest: {
    loading: false,
    success: false,
    fail: false,
    collection: [],
    pagination: {
      page: 1
    },
    errors: {}
  },
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  [GET_HOUSEKEEPING_TASKS_2_PAGINATION](context, params) {
    context.state.getHousekeepingPaginationRequest.loading = true;

    ApiService.query('housekeeping-tasks/paginated', params)
      .then(response => {
        context.commit(
          GET_HOUSEKEEPING_TASKS_2_PAGINATION_SUCCESS,
          response.data.data
        );
      })
      .catch(response => {
        context.commit(GET_HOUSEKEEPING_TASKS_2_PAGINATION_FAIL, response);
      });
  },
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const getters = {};

const mutations = {
  [GET_HOUSEKEEPING_TASKS_2_PAGINATION_SUCCESS](state, data) {
    state.getHousekeepingPaginationRequest = {
      loading: false,
      success: true,
      fail: false,
      collection: data.collection,
      pagination: data.pagination,
      errors: {}
    };
  },
  [GET_HOUSEKEEPING_TASKS_2_PAGINATION_FAIL](state, data) {
    state.getHousekeepingPaginationRequest = {
      loading: false,
      success: false,
      fail: true,
      collection: [],
      pagination: {
        page: 1
      },
      errors: data.errors
    };
  },
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
