export default [
  {
    name: "calendar",
    path: "/calendar/combined",
    component: () => import(/*webpackChunkName: "calendar" */ "@/pages/calendar/Index"),
    meta: {
      title: 'titles.calendar',
      loginRedirect: true
    }
  },
  {
    name: "rate-calendar",
    path: "/calendar/rates",
    component: () => import(/*webpackChunkName: "rate-calendar" */ "@/pages/rate-calendar/Redirect"),
    meta: {
      title: 'titles.rate_calendar'
    }
  },
  {
    name: "rate-history",
    path: "/calendar/rate-history",
    component: () => import(/*webpackChunkName: "rate-history" */ "@/pages/rate-history/Index"),
    meta: {
      breadcrumbs: [
        { text: 'titles.rate_calendar', to: 'rate-calendar' },
      ],
      title: 'titles.rate_history'
    }
  },
  {
    name: "reservations-calendar",
    path: "/calendar/reservations",
    component: () => import(/*webpackChunkName: "reservation-calendar" */ "@/pages/reservations-calendar/Redirect"),
    meta: {
      title: 'titles.reservations_calendar',
      loginRedirect: true
    }
  },
  {
    name: "old-reservations-calendar",
    path: "/old/calendar/reservations",
    component: () => import(/*webpackChunkName: "reservation-calendar" */ "@/pages/reservations-calendar/Index"),
    meta: {
      title: 'titles.reservations_calendar',
      loginRedirect: true
    }
  },
  {
    name: "old-rate-calendar",
    path: "/old/calendar/rates",
    component: () => import(/*webpackChunkName: "rate-calendar" */ "@/pages/rate-calendar/Index"),
    meta: {
      title: 'titles.rate_calendar'
    }
  },
];
