export default [
  {
    name: "Amenities",
    path: "/admin/amenities",
    component: () => import(/*webpackChunkName: "admin" */ "@/pages/amenities/Index"),
    meta: {
      title: 'titles.amenities'
    }
  },
  {
    name: "Announcements",
    path: "/admin/announcements",
    component: () => import(/*webpackChunkName: "admin" */ "@/pages/announcements/Index"),
    meta: {
      title: 'titles.announcements'
    }
  },
  {
    name: "news",
    path: "/news",
    component: () => import(/*webpackChunkName: "admin" */ "@/pages/announcements/Timeline"),
    meta: {
      title: 'titles.announcements'
    }
  },
  {
    name: "helps",
    path: "/admin/helps",
    component: () => import(/*webpackChunkName: "admin" */ "@/pages/helps/Index"),
    meta: {
      title: 'titles.helps'
    }
  },
  {
    name: "tours",
    path: "/admin/tours",
    component: () => import(/*webpackChunkName: "admin" */ "@/pages/tours/Index"),
    meta: {
      title: 'titles.tours'
    }
  },
  {
    name: "global-settings",
    path: "/admin/global-settings",
    component: () => import(/*webpackChunkName: "global-settings" */ "@/pages/global-settings/Index"),
    meta: {
      title: 'titles.global-settings'
    }
  },
  {
    name: "subscription-coupons",
    path: "/admin/subscription-coupons",
    component: () => import(/*webpackChunkName: "subscription-coupons" */ "@/pages/subscription-coupons/Index"),
    meta: {
      title: 'titles.subscription_coupons'
    }
  },
  {
    name: "fonts",
    path: "/admin/fonts",
    component: () => import(/*webpackChunkName: "fonts" */ "@/pages/fonts/Index"),
    meta: {
      title: 'titles.fonts'
    }
  }
];
