export const GET_CALENDAR_DATA = "[CALENDAR] Data Get";
export const GET_CALENDAR_DATA_SUCCESS = "[CALENDAR] Data Get Success";
export const GET_CALENDAR_DATA_FAIL = "[CALENDAR] Data Get Fail";
export const GET_CALENDAR_DATA_RESET = "[CALENDAR] Data Get Reset";

export const CREATE_CALENDAR_RATE = "[CALENDAR] Rate Create";
export const CREATE_CALENDAR_RATE_SUCCESS = "[CALENDAR] Rate Create Success";
export const CREATE_CALENDAR_RATE_FAIL = "[CALENDAR] Rate Create Fail";
export const CREATE_CALENDAR_RATE_RESET = "[CALENDAR] Rate Create Reset";

export const CREATE_CALENDAR_RATE_BULK = "[CALENDAR] Rate Create Bulk";
export const CREATE_CALENDAR_RATE_BULK_SUCCESS = "[CALENDAR] Rate Create Bulk Success";
export const CREATE_CALENDAR_RATE_BULK_FAIL = "[CALENDAR] Rate Create Bulk Fail";
export const CREATE_CALENDAR_RATE_BULK_RESET = "[CALENDAR] Rate Create Bulk Reset";

export const GET_CALENDAR_CHILDREN_DATA = "[CALENDAR] Children Data Get";
export const GET_CALENDAR_CHILDREN_DATA_LOADING = "[CALENDAR] Children Data Get Loading";
export const GET_CALENDAR_CHILDREN_DATA_SUCCESS = "[CALENDAR] Children Data Get Success";
export const GET_CALENDAR_CHILDREN_DATA_FAIL = "[CALENDAR] Children Data Get Fail";

export const CLEAR_CALENDAR_CHILDREN_DATA = "[CALENDAR] Children Data Clear";
export const CLEAR_CALENDAR_CHILDREN_DATA_SUCCESS = "[CALENDAR] Children Data Clear Success";

export const CALENDAR_MOVE_RESERVATION = "[CALENDAR] Move Reservation";
export const CALENDAR_MOVE_RESERVATION_SUCCESS = "[CALENDAR] Move Reservation Success";
export const CALENDAR_MOVE_RESERVATION_FAIL = "[CALENDAR] Move Reservation Fail";
export const CALENDAR_MOVE_RESERVATION_RESET = "[CALENDAR] Move Reservation Reset";

export const COPY_CALENDAR_RATES = "[CALENDAR] Rates Copy";
export const COPY_CALENDAR_RATES_SUCCESS = "[CALENDAR] Rates Copy Success";
export const COPY_CALENDAR_RATES_FAIL = "[CALENDAR] Rates Copy Fail";
export const COPY_CALENDAR_RATES_RESET = "[CALENDAR] Rates Copy Reset";
