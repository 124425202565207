import moment from 'moment';
import action from "@/store/core/action";
import SessionService from '@/libraries/sessionjs/src';

import {
  GET_SETTINGS_COMPANIES_COLLECTION,
  GET_SETTINGS_COMPANIES_COLLECTION_SUCCESS,
  GET_SETTINGS_COMPANIES_COLLECTION_FAIL,
  STORE_PROPERTY_IDS,
  RETRIEVE_PROPERTY_IDS,
  STORE_FILTERS_SETTINGS,
  RETRIEVE_FILTERS_SETTINGS,
  INACTIVATE_HELP,
  DISMISS_NOTIFICATION_BANNER,
  DISMISS_ANNOUNCEMENT,
  DISMISS_TOUR,
  FORGET_SETTINGS,
  TOGGLE_SIDEBAR_MENU,
  STORE_WHITELABEL,
} from "./actions";

const actionsProcessed = action.processMultiple([
  {
    name: 'getCompaniesRequest',
    action: GET_SETTINGS_COMPANIES_COLLECTION,
    endPoint: 'companies/collected',
    mutations: {
      fail: GET_SETTINGS_COMPANIES_COLLECTION_FAIL,
      success: GET_SETTINGS_COMPANIES_COLLECTION_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  filters: {},
  property: {
    ids: []
  },
  sidebar: {
    active: true,
    toggled: false,
    minimized: false,
  },
  inactiveHelps: SessionService.disk('local').get('INACTIVE_HELPS') || [],
  inactiveAnnouncements: SessionService.disk('local').get('INACTIVE_ANNOUNCEMENTS') || [],
  isNotificationBannerDismissed: SessionService.disk('local').get('DISMISS_NOTIFICATION_BANNER') || false,
  isTourDismissed: SessionService.disk('local').get('DISMISS_TOUR') || false,
  whitelabel: SessionService.disk('local').get('STORE_WHITELABEL') || {},
  ...actionsProcessed.state,
};

const actions = {
  [STORE_PROPERTY_IDS](context, ids) {
    context.state.property.ids = ids || [];

    SessionService.disk('local').set('PROPERTY_IDS', context.state.property.ids);
  },
  [RETRIEVE_PROPERTY_IDS](context) {
    return new Promise(resolve => {
      context.state.property.ids = SessionService.disk('local').get('PROPERTY_IDS') || [];
      resolve(context.state.property.ids);
    });
  },
  [STORE_FILTERS_SETTINGS](context, { module, filters }) {
    context.state.filters[module] = filters;

    SessionService.disk('local').set('FILTERS', context.state.filters);
  },
  [RETRIEVE_FILTERS_SETTINGS](context, { module }) {
    return new Promise(resolve => {
      context.state.filters = SessionService.disk('local').get('FILTERS') || {};

      if (!module) {
        resolve(context.state.filters);
      } else {
        resolve(context.state.filters[module]);
      }
    });
  },
  [FORGET_SETTINGS](context) {
    SessionService.disk('local').remove('FILTERS');
    SessionService.disk('local').remove('PROPERTY_IDS');

    context.state.filters = {};
    context.state.property.ids = [];
  },
  [INACTIVATE_HELP](context, module) {
    if (context.state.inactiveHelps.indexOf(module) === -1) {
      context.state.inactiveHelps.push(module);
    }

    SessionService.disk('local').set('INACTIVE_HELPS', context.state.inactiveHelps, {
      path: "/",
      expires: new Date(moment().add(10, 'years').format('YYYY-MM-DD'))
    });
  },
  [DISMISS_NOTIFICATION_BANNER](context, data) {
    context.state.isNotificationBannerDismissed = true;

    SessionService.disk('local').set('DISMISS_NOTIFICATION_BANNER', true);
  },
  [DISMISS_ANNOUNCEMENT](context, module) {
    if (context.state.inactiveAnnouncements.indexOf(module) === -1) {
      context.state.inactiveAnnouncements.push(module);
    }

    SessionService.disk('local').set('INACTIVE_ANNOUNCEMENTS', context.state.inactiveAnnouncements, {
      path: "/",
      expires: new Date(moment().add(1, 'years').format('YYYY-MM-DD'))
    });
  },
  [DISMISS_TOUR](context, data) {
    context.state.isTourDismissed = true;

    SessionService.disk('local').set('DISMISS_TOUR', true, {
      path: "/",
      expires: new Date(moment().add(1, 'years').format('YYYY-MM-DD'))
    });
  },
  [TOGGLE_SIDEBAR_MENU](context, isMobile = false) {
    if (isMobile) {
      context.state.sidebar.minimized = false;
      context.state.sidebar.active = !context.state.sidebar.active;
    } else {
      context.state.sidebar.active = true
      context.state.sidebar.minimized = !context.state.sidebar.minimized;
    }
  },
  [STORE_WHITELABEL](context, data) {
    context.state.whitelabel = data;

    SessionService.disk('local').set('STORE_WHITELABEL', data);
  },
  ...actionsProcessed.actions,
};

const mutations = {
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
