export const GET_RESERVATION_CONTRACTS_LIST = "[RESERVATION_CONTRACTS] List Get";
export const GET_RESERVATION_CONTRACTS_LIST_SUCCESS = "[RESERVATION_CONTRACTS] List Get Success";
export const GET_RESERVATION_CONTRACTS_LIST_FAIL = "[RESERVATION_CONTRACTS] List Get Fail";

export const GET_RESERVATION_CONTRACTS_PAGINATION = "[RESERVATION_CONTRACTS] Pagination Get";
export const GET_RESERVATION_CONTRACTS_PAGINATION_SUCCESS = "[RESERVATION_CONTRACTS] Pagination Get Success";
export const GET_RESERVATION_CONTRACTS_PAGINATION_FAIL = "[RESERVATION_CONTRACTS] Pagination Get Fail";

export const GET_RESERVATION_CONTRACTS_COLLECTION = "[RESERVATION_CONTRACTS] Collection Get";
export const GET_RESERVATION_CONTRACTS_COLLECTION_SUCCESS = "[RESERVATION_CONTRACTS] Collection Get Success";
export const GET_RESERVATION_CONTRACTS_COLLECTION_FAIL = "[RESERVATION_CONTRACTS] Collection Get Fail";

export const GET_RESERVATION_CONTRACTS_SINGLE = "[RESERVATION_CONTRACTS] Single Get";
export const GET_RESERVATION_CONTRACTS_SINGLE_SUCCESS = "[RESERVATION_CONTRACTS] Single Get Success";
export const GET_RESERVATION_CONTRACTS_SINGLE_FAIL = "[RESERVATION_CONTRACTS] Single Get Fail";

export const CREATE_RESERVATION_CONTRACTS = "[RESERVATION_CONTRACTS] Create";
export const CREATE_RESERVATION_CONTRACTS_SUCCESS = "[RESERVATION_CONTRACTS] Create Success";
export const CREATE_RESERVATION_CONTRACTS_FAIL = "[RESERVATION_CONTRACTS] Create Fail";
export const CREATE_RESERVATION_CONTRACTS_RESET = "[RESERVATION_CONTRACTS] Create Reset";

export const UPDATE_RESERVATION_CONTRACTS = "[RESERVATION_CONTRACTS] Update";
export const UPDATE_RESERVATION_CONTRACTS_SUCCESS = "[RESERVATION_CONTRACTS] Update Success";
export const UPDATE_RESERVATION_CONTRACTS_FAIL = "[RESERVATION_CONTRACTS] Update Fail";
export const UPDATE_RESERVATION_CONTRACTS_RESET = "[RESERVATION_CONTRACTS] Update Reset";

export const REPLACE_RESERVATION_CONTRACTS = "[RESERVATION_CONTRACTS] Replace";
export const REPLACE_RESERVATION_CONTRACTS_SUCCESS = "[RESERVATION_CONTRACTS] Replace Success";
export const REPLACE_RESERVATION_CONTRACTS_FAIL = "[RESERVATION_CONTRACTS] Replace Fail";
export const REPLACE_RESERVATION_CONTRACTS_RESET = "[RESERVATION_CONTRACTS] Replace Reset";

export const REMOVE_RESERVATION_CONTRACTS = "[RESERVATION_CONTRACTS] Remove";
export const REMOVE_RESERVATION_CONTRACTS_SUCCESS = "[RESERVATION_CONTRACTS] Remove Success";
export const REMOVE_RESERVATION_CONTRACTS_FAIL = "[RESERVATION_CONTRACTS] Remove Fail";
