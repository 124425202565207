import SessionService from '@/libraries/sessionjs/src';
import settingsInterface from "./interfaces/settings";
import staticSettingsKeys from "./interfaces/static-keys";

import {
  CALENDAR_INIT_SETTINGS,
  CALENDAR_STORE_SETTINGS,
  CALENDAR_CHANGE_SETTINGS,
  CALENDAR_REMOVE_SETTINGS,
  CALENDAR_UPDATE_SETTINGS,
  CALENDAR_ACTIVATE_SETTINGS,
} from "./actions";

const getters = {};

const state = {
  stored: [
    {
      ...settingsInterface,
    }
  ],
  current: {
    ...settingsInterface,
  },
};

const getStorableSettings = (settings) => {
  let result = { ...settings };

  staticSettingsKeys.forEach(key => {
    delete result[key];
  });

  return result;
};

const getStorableSettingsBulk = (items) => {
  return items.map(settings => {
    return getStorableSettings(settings);
  });
};

const actions = {
  [CALENDAR_INIT_SETTINGS](context) {
    const settings = SessionService.disk('local').get('CALENDAR_SETTINGS');

    if (settings && settings.length) {
      context.state.stored = [ ...settings ];
      const current = settings.find(item => item.isActive);

      if (current) {
        context.state.current = {
          ...context.state.current,
          ...current
        };
      }
    } else {
      SessionService.disk('local').set('CALENDAR_SETTINGS', [
        getStorableSettings(settingsInterface)
      ]);
    }
  },
  [CALENDAR_STORE_SETTINGS](context, title) {
    let settings = SessionService.disk('local').get('CALENDAR_SETTINGS') || [];
    let existingIndex = settings.findIndex(item => item.title === title);

    let item = {
      ...context.state.current,
      title,
      isActive: true
    };

    settings.map(item => {
      item.isActive = false;
    });

    if (existingIndex > -1) {
      settings[existingIndex] = item;
    } else {
      settings.push(item);
    }

    context.state.stored = [ ...settings ];

    SessionService.disk('local').set('CALENDAR_SETTINGS', getStorableSettingsBulk(settings));
  },
  [CALENDAR_REMOVE_SETTINGS](context, title) {
    let settings = SessionService.disk('local').get('CALENDAR_SETTINGS') || [];
    let existingIndex = settings.findIndex(item => item.title === title);

    const wasActive = settings[existingIndex].isActive;

    settings.splice(existingIndex, 1);

    if (settings[0] && wasActive) {
      settings[0].isActive = true;
    }

    context.state.current = {
      ...context.state.current,
      ...settings[0]
    };
    context.state.stored = [ ...settings ];

    SessionService.disk('local').set('CALENDAR_SETTINGS', getStorableSettingsBulk(settings));
  },
  [CALENDAR_ACTIVATE_SETTINGS](context, title) {
    let settings = SessionService.disk('local').get('CALENDAR_SETTINGS') || [];
    let existingIndex = settings.findIndex(item => item.title === title);

    settings.map(item => {
      item.isActive = false;
    });

    settings[existingIndex].isActive = true;

    context.state.current = {
      ...context.state.current,
      ...settings[existingIndex]
    };
    context.state.stored = [ ...settings ];

    SessionService.disk('local').set('CALENDAR_SETTINGS', getStorableSettingsBulk(settings));
  },
  [CALENDAR_CHANGE_SETTINGS](context, payload) {
    let settings = context.state.stored;
    let existingIndex = settings.findIndex(item => item.isActive);

    if (existingIndex > -1) {
      const title = settings[existingIndex].title;
      settings[existingIndex] = { ...payload, title };
    } else {
      settings.push({ ...payload });
    }

    context.state.current = {
      ...context.state.current,
      ...payload
    };
    context.state.stored = [ ...settings ];
  },
  [CALENDAR_UPDATE_SETTINGS](context, payload) {
    let settings = SessionService.disk('local').get('CALENDAR_SETTINGS') || [];
    let existingIndex = settings.findIndex(item => item.isActive);

    if (existingIndex > -1) {
      settings[existingIndex] = { ...payload };
    } else {
      settings.push({ ...payload });
    }

    context.state.current = {
      ...context.state.current,
      ...payload
    };
    context.state.stored = [ ...settings ];

    SessionService.disk('local').set('CALENDAR_SETTINGS', getStorableSettingsBulk(settings));
  },
};

const mutations = {

};

export default {
  state,
  actions,
  mutations,
  getters
};
