export default [
  {
    name: "companies",
    path: "/companies",
    component: () => import(/*webpackChunkName: "companies" */ "@/pages/companies/Index"),
    meta: {
      title: 'titles.companies'
    }
  },
  {
    name: "property-clusters",
    path: "/companies/:id/property-clusters",
    component: () => import(/*webpackChunkName: "companies" */ "@/pages/property-clusters/Index"),
    meta: {
      breadcrumbs: [
        { text: 'titles.companies', to: 'companies', params: false, disabled: false },
        { text: 'titles.company_settings', to: 'company-settings', params: true, disabled: false },
      ],
      title: 'titles.property_clusters'
    }
  },
  {
    name: "property-owners",
    path: "/companies/:id/property-owners",
    component: () => import(/*webpackChunkName: "companies" */ "@/pages/property-owners/Index"),
    meta: {
      breadcrumbs: [
        { text: 'titles.companies', to: 'companies', params: false, disabled: false },
        { text: 'titles.company_settings', to: 'company-settings', params: true, disabled: false },
      ],
      title: 'titles.property_owners'
    }
  },
  {
    name: "company-payments",
    path: "/admin/companies/payments",
    component: () => import(/*webpackChunkName: "companies" */ "@/pages/company-payments/Index"),
    meta: {
      title: 'Pagamenti',
    }
  },
  {
    name: "company-invoices",
    path: "/admin/companies/invoices",
    component: () => import(/*webpackChunkName: "companies" */ "@/pages/company-invoices/Index"),
    meta: {
      title: 'Fatture',
    }
  },
  {
    name: "company-settings",
    path: "/companies/:id",
    component: () => import(/*webpackChunkName: "companies" */ "@/pages/companies/SettingsBlocks"),
    meta: {
      title: 'titles.company_settings',
      breadcrumbs: [
        { text: 'titles.companies', to: 'companies' },
      ],
    }
  },
  {
    name: "company-smart-devices",
    path: "/companies/:id/smart-devices",
    component: () => import(/*webpackChunkName: "companies" */ "@/pages/company-smart-devices/Index"),
    meta: {
      breadcrumbs: [
        { text: 'titles.companies', to: 'companies', params: false, disabled: false },
        { text: 'titles.company_settings', to: 'company-settings', params: true, disabled: false },
        { text: 'titles.company_smart_device_accounts', to: 'company-smart-device-accounts', params: true, disabled: false },
      ],
      title: 'titles.company_smart_devices',
    },
  },
  {
    name: "company-smart-device-accounts",
    path: "/companies/:id/smart-device-accounts",
    component: () => import(/*webpackChunkName: "companies" */ "@/pages/company-smart-device-accounts/Index"),
    meta: {
      breadcrumbs: [
        { text: 'titles.companies', to: 'companies', params: false, disabled: false },
        { text: 'titles.company_settings', to: 'company-settings', params: true, disabled: false },
      ],
      title: 'titles.company_smart_device_accounts',
    },
  },
  {
    name: "agencies",
    path: "/companies/:id/agencies",
    component: () => import(/*webpackChunkName: "companies" */ "@/pages/agencies/Index"),
    meta: {
      breadcrumbs: [
        { text: 'titles.companies', to: 'companies', params: false, disabled: false },
        { text: 'titles.company_settings', to: 'company-settings', params: true, disabled: false },
      ],
      title: 'titles.agencies'
    }
  },
  {
    name: "company-google-accounts",
    path: "/companies/:id/google-accounts",
    component: () => import(/*webpackChunkName: "companies" */ "@/pages/company-google-accounts/Index"),
    meta: {
      breadcrumbs: [
        { text: 'titles.companies', to: 'companies', params: false, disabled: false },
        { text: 'titles.company_settings', to: 'company-settings', params: true, disabled: false },
      ],
      title: 'titles.company_google_accounts',
    },
  },
  {
    name: "company-pos",
    path: "/companies/:id/pos",
    component: () => import(/*webpackChunkName: "companies" */ "@/pages/companies/PosBlocks"),
    meta: {
      title: 'titles.company_pos',
      breadcrumbs: [
        { text: 'titles.companies', to: 'companies' },
        { text: 'titles.company_settings', to: 'company-settings', params: true, disabled: false },
      ],
    }
  },
  {
    name: "events",
    path: "/companies/:id/events",
    component: () => import(/*webpackChunkName: "companies" */ "@/pages/events/Index"),
    meta: {
      title: 'titles.events',
      breadcrumbs: [
        { text: 'titles.companies', to: 'companies' },
        { text: 'titles.company_settings', to: 'company-settings', params: true, disabled: false },
      ],
    }
  },
  {
    name: "company-locations",
    path: "/companies/:id/locations",
    component: () => import(/*webpackChunkName: "companies" */ "@/pages/company-locations/Index"),
    meta: {
      title: 'titles.company_locations',
      breadcrumbs: [
        { text: 'titles.companies', to: 'companies' },
        { text: 'titles.company_settings', to: 'company-settings', params: true, disabled: false },
      ],
    }
  },
  {
    name: "company-journals",
    path: "/companies/:id/journals",
    component: () => import(/*webpackChunkName: "companies" */ "@/pages/company-journals/Index"),
    meta: {
      title: 'titles.company_journals',
      breadcrumbs: [
        { text: 'titles.companies', to: 'companies' },
        { text: 'titles.company_settings', to: 'company-settings', params: true, disabled: false },
      ],
    }
  },
  {
    name: "company-user-support-requests",
    path: "/users/:company_id/support-requests",
    component: () => import(/*webpackChunkName: "users" */ "@/pages/user-support-requests/Index"),
    meta: {
      title: 'titles.user_support_requests',
      breadcrumbs: [
        { text: 'titles.users', to: 'companies' },
      ],
    }
  }
];
