import { attach } from './attach';

export default {
  install(Vue, options) {
    if (typeof options !== 'object' || !options.secret) {
      throw new Error('Stripe Secret is not specified');
    }

    let isAttached = false;

    let stripe = {
      ready() {
        return new Promise((resolve, reject) => {
          if (isAttached) {
            resolve(stripe);
            return;
          }

          attach(() => {
            let instance = Stripe(options.secret);
            let elements = instance.elements();

            stripe = {
              ...stripe,
              ...instance,
              elements
            };

            Vue.prototype.$stripe = stripe;

            isAttached = true;

            resolve(stripe);
          }, () => {
            reject({
              message: 'Failed to load Stripe'
            });
          });
        });
      },
    };

    if (!isAttached) {
      Vue.prototype.$stripe = stripe;
    }
  }
};
