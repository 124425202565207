export default {
  methods: {
    providerColor(provider) {
      switch(provider) {
        case this.$const('ConstCompanySmartDevicesProviders', 'OK_HOME'):
          return 'black';
        case this.$const('ConstCompanySmartDevicesProviders', 'NUKI'):
          return 'white';
        case this.$const('ConstCompanySmartDevicesProviders', 'SWITCH_BOT'):
          return 'white';
        case this.$const('ConstCompanySmartDevicesProviders', 'ACCOMMODATION_SMART'):
          return 'white';
      }
    }
  }
};
