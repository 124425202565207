export const GET_RESERVATION_AMOUNT_DETAILS_PAGINATION = "[RESERVATION_AMOUNT_DETAILS] Pagination Get";
export const GET_RESERVATION_AMOUNT_DETAILS_PAGINATION_SUCCESS = "[RESERVATION_AMOUNT_DETAILS] Pagination Get Success";
export const GET_RESERVATION_AMOUNT_DETAILS_PAGINATION_FAIL = "[RESERVATION_AMOUNT_DETAILS] Pagination Get Fail";

export const GET_RESERVATION_AMOUNT_DETAILS_COLLECTION = "[RESERVATION_AMOUNT_DETAILS] Collection Get";
export const GET_RESERVATION_AMOUNT_DETAILS_COLLECTION_SUCCESS = "[RESERVATION_AMOUNT_DETAILS] Collection Get Success";
export const GET_RESERVATION_AMOUNT_DETAILS_COLLECTION_FAIL = "[RESERVATION_AMOUNT_DETAILS] Collection Get Fail";

export const GET_RESERVATION_AMOUNT_DETAILS_SINGLE = "[RESERVATION_AMOUNT_DETAILS] Single Get";
export const GET_RESERVATION_AMOUNT_DETAILS_SINGLE_SUCCESS = "[RESERVATION_AMOUNT_DETAILS] Single Get Success";
export const GET_RESERVATION_AMOUNT_DETAILS_SINGLE_FAIL = "[RESERVATION_AMOUNT_DETAILS] Single Get Fail";

export const CREATE_RESERVATION_AMOUNT_DETAILS = "[RESERVATION_AMOUNT_DETAILS] Create";
export const CREATE_RESERVATION_AMOUNT_DETAILS_SUCCESS = "[RESERVATION_AMOUNT_DETAILS] Create Success";
export const CREATE_RESERVATION_AMOUNT_DETAILS_FAIL = "[RESERVATION_AMOUNT_DETAILS] Create Fail";
export const CREATE_RESERVATION_AMOUNT_DETAILS_RESET = "[RESERVATION_AMOUNT_DETAILS] Create Reset";

export const UPDATE_RESERVATION_AMOUNT_DETAILS = "[RESERVATION_AMOUNT_DETAILS] Update";
export const UPDATE_RESERVATION_AMOUNT_DETAILS_SUCCESS = "[RESERVATION_AMOUNT_DETAILS] Update Success";
export const UPDATE_RESERVATION_AMOUNT_DETAILS_FAIL = "[RESERVATION_AMOUNT_DETAILS] Update Fail";
export const UPDATE_RESERVATION_AMOUNT_DETAILS_RESET = "[RESERVATION_AMOUNT_DETAILS] Update Reset";

export const REMOVE_RESERVATION_AMOUNT_DETAILS = "[RESERVATION_AMOUNT_DETAILS] Remove";
export const REMOVE_RESERVATION_AMOUNT_DETAILS_SUCCESS = "[RESERVATION_AMOUNT_DETAILS] Remove Success";
export const REMOVE_RESERVATION_AMOUNT_DETAILS_FAIL = "[RESERVATION_AMOUNT_DETAILS] Remove Fail";
