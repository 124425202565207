export const GET_ROOM_TYPE_GOOGLE_SHEET_LIST = "[ROOM_TYPE_GOOGLE_SHEET] List Get";
export const GET_ROOM_TYPE_GOOGLE_SHEET_LIST_SUCCESS = "[ROOM_TYPE_GOOGLE_SHEET] List Get Success";
export const GET_ROOM_TYPE_GOOGLE_SHEET_LIST_FAIL = "[ROOM_TYPE_GOOGLE_SHEET] List Get Fail";

export const GET_ROOM_TYPE_GOOGLE_SHEET_PAGINATION = "[ROOM_TYPE_GOOGLE_SHEET] Pagination Get";
export const GET_ROOM_TYPE_GOOGLE_SHEET_PAGINATION_SUCCESS = "[ROOM_TYPE_GOOGLE_SHEET] Pagination Get Success";
export const GET_ROOM_TYPE_GOOGLE_SHEET_PAGINATION_FAIL = "[ROOM_TYPE_GOOGLE_SHEET] Pagination Get Fail";

export const GET_ROOM_TYPE_GOOGLE_SHEET_COLLECTION = "[ROOM_TYPE_GOOGLE_SHEET] Collection Get";
export const GET_ROOM_TYPE_GOOGLE_SHEET_COLLECTION_SUCCESS = "[ROOM_TYPE_GOOGLE_SHEET] Collection Get Success";
export const GET_ROOM_TYPE_GOOGLE_SHEET_COLLECTION_FAIL = "[ROOM_TYPE_GOOGLE_SHEET] Collection Get Fail";

export const GET_ROOM_TYPE_GOOGLE_SHEET_SINGLE = "[ROOM_TYPE_GOOGLE_SHEET] Single Get";
export const GET_ROOM_TYPE_GOOGLE_SHEET_SINGLE_SUCCESS = "[ROOM_TYPE_GOOGLE_SHEET] Single Get Success";
export const GET_ROOM_TYPE_GOOGLE_SHEET_SINGLE_FAIL = "[ROOM_TYPE_GOOGLE_SHEET] Single Get Fail";

export const CREATE_ROOM_TYPE_GOOGLE_SHEET = "[ROOM_TYPE_GOOGLE_SHEET] Create";
export const CREATE_ROOM_TYPE_GOOGLE_SHEET_SUCCESS = "[ROOM_TYPE_GOOGLE_SHEET] Create Success";
export const CREATE_ROOM_TYPE_GOOGLE_SHEET_FAIL = "[ROOM_TYPE_GOOGLE_SHEET] Create Fail";
export const CREATE_ROOM_TYPE_GOOGLE_SHEET_RESET = "[ROOM_TYPE_GOOGLE_SHEET] Create Reset";

export const UPDATE_ROOM_TYPE_GOOGLE_SHEET = "[ROOM_TYPE_GOOGLE_SHEET] Update";
export const UPDATE_ROOM_TYPE_GOOGLE_SHEET_SUCCESS = "[ROOM_TYPE_GOOGLE_SHEET] Update Success";
export const UPDATE_ROOM_TYPE_GOOGLE_SHEET_FAIL = "[ROOM_TYPE_GOOGLE_SHEET] Update Fail";
export const UPDATE_ROOM_TYPE_GOOGLE_SHEET_RESET = "[ROOM_TYPE_GOOGLE_SHEET] Update Reset";

export const REMOVE_ROOM_TYPE_GOOGLE_SHEET = "[ROOM_TYPE_GOOGLE_SHEET] Remove";
export const REMOVE_ROOM_TYPE_GOOGLE_SHEET_SUCCESS = "[ROOM_TYPE_GOOGLE_SHEET] Remove Success";
export const REMOVE_ROOM_TYPE_GOOGLE_SHEET_FAIL = "[ROOM_TYPE_GOOGLE_SHEET] Remove Fail";

export const GET_ROOM_TYPE_GOOGLE_SHEET_HEADER = "[ROOM_TYPE_GOOGLE_SHEET] Header Get";
export const GET_ROOM_TYPE_GOOGLE_SHEET_HEADER_SUCCESS = "[ROOM_TYPE_GOOGLE_SHEET] Header Get Success";
export const GET_ROOM_TYPE_GOOGLE_SHEET_HEADER_FAIL = "[ROOM_TYPE_GOOGLE_SHEET] Header Get Fail";

export const SYNC_ROOM_TYPE_GOOGLE_SHEET = "[ROOM_TYPE_GOOGLE_SHEET] Sync";
export const SYNC_ROOM_TYPE_GOOGLE_SHEET_SUCCESS = "[ROOM_TYPE_GOOGLE_SHEET] Sync Success";
export const SYNC_ROOM_TYPE_GOOGLE_SHEET_FAIL = "[ROOM_TYPE_GOOGLE_SHEET] Sync Fail";

export const SYNC_PROPERTY_GOOGLE_SHEET = "[PROPERTY_GOOGLE_SHEET] Sync";
export const SYNC_PROPERTY_GOOGLE_SHEET_SUCCESS = "[PROPERTY_GOOGLE_SHEET] Sync Success";
export const SYNC_PROPERTY_GOOGLE_SHEET_FAIL = "[PROPERTY_GOOGLE_SHEET] Sync Fail";
