export default [
  {
    name: "payments-report",
    path: "/accounting/payments/report",
    component: () => import(/*webpackChunkName: "payments" */ "@/pages/payments/Report"),
    meta: {
      title: 'titles.payments_report'
    }
  },
  {
    name: "payments-cashflow",
    path: "/accounting/payments/cashflow",
    component: () => import(/*webpackChunkName: "payments" */ "@/pages/payments/Cashflow"),
    meta: {
      title: 'titles.payments_cashflow'
    }
  }
];
