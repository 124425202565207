<template>
  <v-btn icon>
    <v-icon @click="fireAction()" :title="title">
      {{icon}}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    action: {
      type: Function,
      required: true
    }
  },
  methods: {
    fireAction() {
      this.action();
    }
  }
};
</script>
