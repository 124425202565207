export const GET_HELPS_LIST = "[HELPS] List Get";
export const GET_HELPS_LIST_SUCCESS = "[HELPS] List Get Success";
export const GET_HELPS_LIST_FAIL = "[HELPS] List Get Fail";

export const GET_HELPS_PAGINATION = "[HELPS] Pagination Get";
export const GET_HELPS_PAGINATION_SUCCESS = "[HELPS] Pagination Get Success";
export const GET_HELPS_PAGINATION_FAIL = "[HELPS] Pagination Get Fail";

export const GET_HELPS_COLLECTION = "[HELPS] Collection Get";
export const GET_HELPS_COLLECTION_SUCCESS = "[HELPS] Collection Get Success";
export const GET_HELPS_COLLECTION_FAIL = "[HELPS] Collection Get Fail";

export const GET_HELPS_SINGLE = "[HELPS] Single Get";
export const GET_HELPS_SINGLE_SUCCESS = "[HELPS] Single Get Success";
export const GET_HELPS_SINGLE_FAIL = "[HELPS] Single Get Fail";

export const CREATE_HELPS = "[HELPS] Create";
export const CREATE_HELPS_SUCCESS = "[HELPS] Create Success";
export const CREATE_HELPS_FAIL = "[HELPS] Create Fail";
export const CREATE_HELPS_RESET = "[HELPS] Create Reset";

export const UPDATE_HELPS = "[HELPS] Update";
export const UPDATE_HELPS_SUCCESS = "[HELPS] Update Success";
export const UPDATE_HELPS_FAIL = "[HELPS] Update Fail";
export const UPDATE_HELPS_RESET = "[HELPS] Update Reset";

export const REMOVE_HELPS = "[HELPS] Remove";
export const REMOVE_HELPS_SUCCESS = "[HELPS] Remove Success";
export const REMOVE_HELPS_FAIL = "[HELPS] Remove Fail";
