export const GET_COMPANY_JOURNALS_LIST = "[COMPANY_JOURNALS] List Get";
export const GET_COMPANY_JOURNALS_LIST_SUCCESS = "[COMPANY_JOURNALS] List Get Success";
export const GET_COMPANY_JOURNALS_LIST_FAIL = "[COMPANY_JOURNALS] List Get Fail";

export const GET_COMPANY_JOURNALS_PAGINATION = "[COMPANY_JOURNALS] Pagination Get";
export const GET_COMPANY_JOURNALS_PAGINATION_SUCCESS = "[COMPANY_JOURNALS] Pagination Get Success";
export const GET_COMPANY_JOURNALS_PAGINATION_FAIL = "[COMPANY_JOURNALS] Pagination Get Fail";

export const GET_COMPANY_JOURNALS_COLLECTION = "[COMPANY_JOURNALS] Collection Get";
export const GET_COMPANY_JOURNALS_COLLECTION_SUCCESS = "[COMPANY_JOURNALS] Collection Get Success";
export const GET_COMPANY_JOURNALS_COLLECTION_FAIL = "[COMPANY_JOURNALS] Collection Get Fail";

export const GET_COMPANY_JOURNALS_SINGLE = "[COMPANY_JOURNALS] Single Get";
export const GET_COMPANY_JOURNALS_SINGLE_SUCCESS = "[COMPANY_JOURNALS] Single Get Success";
export const GET_COMPANY_JOURNALS_SINGLE_FAIL = "[COMPANY_JOURNALS] Single Get Fail";

export const CREATE_COMPANY_JOURNALS = "[COMPANY_JOURNALS] Create";
export const CREATE_COMPANY_JOURNALS_SUCCESS = "[COMPANY_JOURNALS] Create Success";
export const CREATE_COMPANY_JOURNALS_FAIL = "[COMPANY_JOURNALS] Create Fail";
export const CREATE_COMPANY_JOURNALS_RESET = "[COMPANY_JOURNALS] Create Reset";

export const UPDATE_COMPANY_JOURNALS = "[COMPANY_JOURNALS] Update";
export const UPDATE_COMPANY_JOURNALS_SUCCESS = "[COMPANY_JOURNALS] Update Success";
export const UPDATE_COMPANY_JOURNALS_FAIL = "[COMPANY_JOURNALS] Update Fail";
export const UPDATE_COMPANY_JOURNALS_RESET = "[COMPANY_JOURNALS] Update Reset";

export const REMOVE_COMPANY_JOURNALS = "[COMPANY_JOURNALS] Remove";
export const REMOVE_COMPANY_JOURNALS_SUCCESS = "[COMPANY_JOURNALS] Remove Success";
export const REMOVE_COMPANY_JOURNALS_FAIL = "[COMPANY_JOURNALS] Remove Fail";