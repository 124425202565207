export default [
  {
    name: "quotes",
    path: "/quotes",
    component: () => import(/*webpackChunkName: "quotes" */ "@/pages/quotes/Index"),
    meta: {
      title: 'titles.quotes'
    }
  },
  {
    name: "quote-requests",
    path: "/quotes/requests",
    component: () => import(/*webpackChunkName: "quotes" */ "@/pages/quote-requests/Index"),
    meta: {
      title: 'titles.quote_requests'
    }
  }
];
