export default {
  url: process.env.VUE_APP_URL,
  name: process.env.VUE_APP_TITLE,
  fallbackLocale: process.env.VUE_APP_LOCAL,
  format: {
    date: 'DD/MM/YYYY',
    dateTime: 'DD/MM/YYYY HH:mm',
    time: 'HH:mm',
  },
  autologinSecret: process.env.VUE_APP_AUTOLOGIN_SECRET,
}
