<template>
  <span>
    <v-autocomplete
      v-model="ids"
      :items="items"
      :label="label"
      :multiple="multiple"
      :small-chips="smallChips"
      :deletable-chips="deletableChips"
      :hint="hint"
      :persistent-hint="persistentHint"
      :loading="loading"
      :clearable="clearable"
      :disabled="disabled"
      :readonly="readonly"
      :dark="dark"
      :single-line="singleLine"
      :hide-details="hideDetails"
      @change="change"
      @blur="blur"
      @click:append-outer="getItems()"
      append-outer-icon="mdi-refresh"
      :dense="dense"
      :error="error"
      :error-messages="!!errorMessages ? errorMessages : []"
      item-value="id"
      item-text="name"
    >
    </v-autocomplete>
  </span>
</template>

<script>
  import { mapState } from "vuex";
  import payments from "@/mixins/payments";

  import {
    GET_PAYMENT_SOURCES_COLLECTION,
  } from "@/store/payment-sources/actions";

  export default {
    name: "CPaymentSourcesAutocomplete",
    mixins: [
      payments,
    ],
    props: {
      label: {
        type: String,
        required: false,
        default: null
      },
      hint: {
        type: String,
        default: ""
      },
      persistentHint: {
        type: Boolean,
        default: false
      },
      value: {
        default: null
      },
      error: {
        default: false
      },
      errorMessages: {
        default: () => []
      },
      dense: {
        type: Boolean,
        default: false
      },
      singleLine: {
        type: Boolean,
        default: false
      },
      hideDetails: {
        type: Boolean,
        default: false
      },
      clearable: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      readonly: {
        type: Boolean,
        default: false
      },
      dark: {
        type: Boolean,
        default: false
      },
      smallChips: {
        type: Boolean,
        default: false
      },
      deletableChips: {
        type: Boolean,
        default: false
      },
      options: {
        type: Object,
        default: () => {
          return {};
        }
      },
      companyId: {
        required: false,
      },
      propertyId: {
        required: false,
      },
      reservationId: {
        required: false,
      }
    },
    data() {
      return {
        ids: null,
        selectedIds: [],
        cache: false
      };
    },
    beforeMount() {
      this.getItems();
    },
    mounted() {
      this.ids = this.value;
    },
    methods: {
      getItems() {
        if (this.request.loading) {
          return;
        }

        let filters = {};

        if (this.companyId) {
          filters.company_id = this.companyId
        }

        if (this.propertyId) {
          filters.property_id = this.propertyId
        }

        if (this.reservationId) {
          filters.reservation_id = this.reservationId
        }

        this.$store.dispatch(GET_PAYMENT_SOURCES_COLLECTION, {
          sortBy: 'name',
          order: 'asc',
          ...filters,
        }).then(() => {
          this.initPayableSources();
        });
      },
      change() {
        this.$emit("change");
      },
      blur() {
        this.$emit("blur");
      },
    },
    computed: {
      ...mapState({
        request: state => state.paymentSources.getCollectionRequest,
      }),
      items() {
        if (!this.request.collection) {
          return [];
        }

        return this.request.collection;
      },
      loading() {
        return this.request.loading;
      },
    },
    watch: {
      ids(newVal) {
        this.$emit('input', newVal);
      },
      value(newVal) {
        this.ids = newVal;
      },
      companyId() {
        this.getItems();
      },
      propertyId() {
        this.getItems();
      },
      reservationId() {
        this.getItems();
      },
    }
  };
</script>
