export const GET_RESERVATION_TASKS_LIST = "[RESERVATION_TASKS] List Get";
export const GET_RESERVATION_TASKS_LIST_SUCCESS = "[RESERVATION_TASKS] List Get Success";
export const GET_RESERVATION_TASKS_LIST_FAIL = "[RESERVATION_TASKS] List Get Fail";

export const GET_RESERVATION_TASKS_PAGINATION = "[RESERVATION_TASKS] Pagination Get";
export const GET_RESERVATION_TASKS_PAGINATION_SUCCESS = "[RESERVATION_TASKS] Pagination Get Success";
export const GET_RESERVATION_TASKS_PAGINATION_FAIL = "[RESERVATION_TASKS] Pagination Get Fail";

export const GET_RESERVATION_TASKS_COLLECTION = "[RESERVATION_TASKS] Collection Get";
export const GET_RESERVATION_TASKS_COLLECTION_SUCCESS = "[RESERVATION_TASKS] Collection Get Success";
export const GET_RESERVATION_TASKS_COLLECTION_FAIL = "[RESERVATION_TASKS] Collection Get Fail";

export const GET_RESERVATION_TASKS_SINGLE = "[RESERVATION_TASKS] Single Get";
export const GET_RESERVATION_TASKS_SINGLE_SUCCESS = "[RESERVATION_TASKS] Single Get Success";
export const GET_RESERVATION_TASKS_SINGLE_FAIL = "[RESERVATION_TASKS] Single Get Fail";

export const CREATE_RESERVATION_TASKS = "[RESERVATION_TASKS] Create";
export const CREATE_RESERVATION_TASKS_SUCCESS = "[RESERVATION_TASKS] Create Success";
export const CREATE_RESERVATION_TASKS_FAIL = "[RESERVATION_TASKS] Create Fail";
export const CREATE_RESERVATION_TASKS_RESET = "[RESERVATION_TASKS] Create Reset";

export const UPDATE_RESERVATION_TASKS = "[RESERVATION_TASKS] Update";
export const UPDATE_RESERVATION_TASKS_SUCCESS = "[RESERVATION_TASKS] Update Success";
export const UPDATE_RESERVATION_TASKS_FAIL = "[RESERVATION_TASKS] Update Fail";
export const UPDATE_RESERVATION_TASKS_RESET = "[RESERVATION_TASKS] Update Reset";

export const REPLACE_RESERVATION_TASKS = "[RESERVATION_TASKS] Replace";
export const REPLACE_RESERVATION_TASKS_SUCCESS = "[RESERVATION_TASKS] Replace Success";
export const REPLACE_RESERVATION_TASKS_FAIL = "[RESERVATION_TASKS] Replace Fail";
export const REPLACE_RESERVATION_TASKS_RESET = "[RESERVATION_TASKS] Replace Reset";

export const REMOVE_RESERVATION_TASKS = "[RESERVATION_TASKS] Remove";
export const REMOVE_RESERVATION_TASKS_SUCCESS = "[RESERVATION_TASKS] Remove Success";
export const REMOVE_RESERVATION_TASKS_FAIL = "[RESERVATION_TASKS] Remove Fail";
