// import JQuery from "jquery";

const ExtraService = {
  /**
   *
   */
  init() {
    // this.initJquery();
  },

  /**
   *
   */
  // initJquery() {
  //   window.$ = JQuery;
  // }
};

export default ExtraService;
