import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_RESERVATION_AMOUNT_DETAILS_PAGINATION,
  GET_RESERVATION_AMOUNT_DETAILS_PAGINATION_SUCCESS,
  GET_RESERVATION_AMOUNT_DETAILS_PAGINATION_FAIL,
  GET_RESERVATION_AMOUNT_DETAILS_COLLECTION,
  GET_RESERVATION_AMOUNT_DETAILS_COLLECTION_SUCCESS,
  GET_RESERVATION_AMOUNT_DETAILS_COLLECTION_FAIL,
  GET_RESERVATION_AMOUNT_DETAILS_SINGLE,
  GET_RESERVATION_AMOUNT_DETAILS_SINGLE_SUCCESS,
  GET_RESERVATION_AMOUNT_DETAILS_SINGLE_FAIL,
  CREATE_RESERVATION_AMOUNT_DETAILS,
  CREATE_RESERVATION_AMOUNT_DETAILS_SUCCESS,
  CREATE_RESERVATION_AMOUNT_DETAILS_FAIL,
  CREATE_RESERVATION_AMOUNT_DETAILS_RESET,
  UPDATE_RESERVATION_AMOUNT_DETAILS,
  UPDATE_RESERVATION_AMOUNT_DETAILS_SUCCESS,
  UPDATE_RESERVATION_AMOUNT_DETAILS_FAIL,
  UPDATE_RESERVATION_AMOUNT_DETAILS_RESET,
  REMOVE_RESERVATION_AMOUNT_DETAILS,
  REMOVE_RESERVATION_AMOUNT_DETAILS_SUCCESS,
  REMOVE_RESERVATION_AMOUNT_DETAILS_FAIL
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_RESERVATION_AMOUNT_DETAILS_PAGINATION,
    endPoint: "reservation-amount-details/paginated",
    mutationFail: GET_RESERVATION_AMOUNT_DETAILS_PAGINATION_FAIL,
    mutationSuccess: GET_RESERVATION_AMOUNT_DETAILS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_RESERVATION_AMOUNT_DETAILS_COLLECTION,
    endPoint: "reservation-amount-details/collected",
    mutationFail: GET_RESERVATION_AMOUNT_DETAILS_COLLECTION_FAIL,
    mutationSuccess: GET_RESERVATION_AMOUNT_DETAILS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_RESERVATION_AMOUNT_DETAILS_SINGLE,
    endPoint: "reservation-amount-details/single",
    mutationFail: GET_RESERVATION_AMOUNT_DETAILS_SINGLE_FAIL,
    mutationSuccess: GET_RESERVATION_AMOUNT_DETAILS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_RESERVATION_AMOUNT_DETAILS,
    actionReset: CREATE_RESERVATION_AMOUNT_DETAILS_RESET,
    endPoint: "reservation-amount-details/create",
    mutationFail: CREATE_RESERVATION_AMOUNT_DETAILS_FAIL,
    mutationSuccess: CREATE_RESERVATION_AMOUNT_DETAILS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_RESERVATION_AMOUNT_DETAILS,
    actionReset: UPDATE_RESERVATION_AMOUNT_DETAILS_RESET,
    endPoint: "reservation-amount-details/update",
    mutationFail: UPDATE_RESERVATION_AMOUNT_DETAILS_FAIL,
    mutationSuccess: UPDATE_RESERVATION_AMOUNT_DETAILS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_RESERVATION_AMOUNT_DETAILS,
    endPoint: "reservation-amount-details/delete",
    mutationFail: REMOVE_RESERVATION_AMOUNT_DETAILS_FAIL,
    mutationSuccess: REMOVE_RESERVATION_AMOUNT_DETAILS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
