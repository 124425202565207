export const GET_GLOBAL_SETTINGS_COLLECTION = "[GLOBAL_SETTINGS] Collection Get";
export const GET_GLOBAL_SETTINGS_COLLECTION_SUCCESS = "[GLOBAL_SETTINGS] Collection Get Success";
export const GET_GLOBAL_SETTINGS_COLLECTION_FAIL = "[GLOBAL_SETTINGS] Collection Get Fail";

export const GET_GLOBAL_SETTINGS_SINGLE = "[GLOBAL_SETTINGS] Single Get";
export const GET_GLOBAL_SETTINGS_SINGLE_SUCCESS = "[GLOBAL_SETTINGS] Single Get Success";
export const GET_GLOBAL_SETTINGS_SINGLE_FAIL = "[GLOBAL_SETTINGS] Single Get Fail";

export const CREATE_GLOBAL_SETTINGS = "[GLOBAL_SETTINGS] Create";
export const CREATE_GLOBAL_SETTINGS_SUCCESS = "[GLOBAL_SETTINGS] Create Success";
export const CREATE_GLOBAL_SETTINGS_FAIL = "[GLOBAL_SETTINGS] Create Fail";
export const CREATE_GLOBAL_SETTINGS_RESET = "[GLOBAL_SETTINGS] Create Reset";

export const UPDATE_GLOBAL_SETTINGS = "[GLOBAL_SETTINGS] Update";
export const UPDATE_GLOBAL_SETTINGS_SUCCESS = "[GLOBAL_SETTINGS] Update Success";
export const UPDATE_GLOBAL_SETTINGS_FAIL = "[GLOBAL_SETTINGS] Update Fail";
export const UPDATE_GLOBAL_SETTINGS_RESET = "[GLOBAL_SETTINGS] Update Reset";

export const REPLACE_GLOBAL_SETTINGS = "[GLOBAL_SETTINGS] Replace";
export const REPLACE_GLOBAL_SETTINGS_SUCCESS = "[GLOBAL_SETTINGS] Replace Success";
export const REPLACE_GLOBAL_SETTINGS_FAIL = "[GLOBAL_SETTINGS] Replace Fail";
export const REPLACE_GLOBAL_SETTINGS_RESET = "[GLOBAL_SETTINGS] Replace Reset";

export const GET_COMMANDS_LIST = "[GLOBAL_SETTINGS] Commands List";
export const GET_COMMANDS_LIST_SUCCESS = "[GLOBAL_SETTINGS] Commands List Success";
export const GET_COMMANDS_LIST_FAIL = "[GLOBAL_SETTINGS] Commands List Fail";

export const RUN_COMMAND = "[GLOBAL_SETTINGS] Command Run";
export const RUN_COMMAND_SUCCESS = "[GLOBAL_SETTINGS] Command Run Success";
export const RUN_COMMAND_FAIL = "[GLOBAL_SETTINGS] Command Run Fail";
