<template>
  <div>
    <template v-for="(item, index) in items">
      <div
        v-if="item.type === 'divider'"
        class="divider"
        :key="`divider${index}`"
      ></div>
      <menu-item
        v-else
        :editor="editor"
        :key="index"
        v-bind="item"
      />
    </template>
    <!-- <input
      type="color"
      @input="editor.chain().focus().setColor($event.target.value).run()"
      :value="editor.getAttributes('textStyle').color"
    > -->
    <v-dialog v-model="isOpenSource">
      <v-card>
        <v-card-text>
          <v-textarea
            v-model="htmlSource"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="setHtml" color="success">
            {{ $lang('main.crud.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MenuItem from "./MenuItem.vue";

export default {
  components: {
    MenuItem,
  },
  props: {
    editor: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOpenSource: false,
      htmlSource: '',
      items: [
        {
          icon: "mdi-fullscreen",
          title: "Fullscreen",
          action: () => this.editor.commands.toggleFullscreen(),
        },
        {
          icon: "mdi-code-block-tags",
          title: "View Source",
          action: () => this.viewSource(),
        },
        {
          icon: "mdi-format-bold",
          title: "Bold",
          action: () => this.editor.chain().focus().toggleBold().run(),
        },
        {
          icon: "mdi-format-italic",
          title: "Italic",
          action: () => this.editor.chain().focus().toggleItalic().run(),
        },
        {
          icon: "mdi-format-strikethrough",
          title: "Strike",
          action: () => this.editor.chain().focus().toggleStrike().run(),
        },
        // {
        //   icon: "mdi-format-align-left",
        //   title: "Align Left",
        //   action: () => this.editor.chain().focus().setTextAlign('left').run(),
        // },
        // {
        //   icon: "mdi-format-align-center",
        //   title: "Align Center",
        //   action: () => this.editor.chain().focus().setTextAlign('center').run(),
        // },
        // {
        //   icon: "mdi-format-align-right",
        //   title: "Align Right",
        //   action: () => this.editor.chain().focus().setTextAlign("right").run(),
        // },
        // {
        //   icon: "mdi-format-align-justify",
        //   title: "Align Justify",
        //   action: () =>
        //     this.editor.chain().focus().setTextAlign("justify").run(),
        // },
        {
          icon: "mdi-xml",
          title: "Code",
          action: () => this.editor.chain().focus().toggleCode().run(),
        },
        {
          type: "divider",
        },
        {
          icon: "mdi-format-header-1",
          title: "Heading 1",
          action: () => this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
        },
        {
          icon: "mdi-format-header-2",
          title: "Heading 2",
          action: () => this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
        },
        {
          icon: "mdi-format-header-3",
          title: "Heading 3",
          action: () => this.editor.chain().focus().toggleHeading({ level: 3 }).run(),
        },
        {
          icon: "mdi-format-align-left",
          title: "Align Left",
          action: () => this.editor.chain().focus().setTextAlign('left').run(),
        },
        {
          icon: "mdi-format-align-center",
          title: "Align Center",
          action: () => this.editor.chain().focus().setTextAlign('center').run(),
        },
        {
          icon: "mdi-format-align-right",
          title: "Align Right",
          action: () => this.editor.chain().focus().setTextAlign('right').run(),
        },
        {
          icon: "mdi-format-align-justify",
          title: "Align Justify",
          action: () => this.editor.chain().focus().setTextAlign('justify').run(),
        },
        {
          icon: "mdi-format-paragraph",
          title: "Paragraph",
          action: () => this.editor.chain().focus().setParagraph().run(),
        },
        {
          icon: "mdi-format-list-bulleted",
          title: "Bullet List",
          action: () => this.editor.chain().focus().toggleBulletList().run(),
        },
        {
          icon: "mdi-format-list-numbered",
          title: "Ordered List",
          action: () => this.editor.chain().focus().toggleOrderedList().run(),
        },
        {
          type: "divider",
        },
        {
          icon: "mdi-format-quote-close",
          title: "Blockquote",
          action: () => this.editor.chain().focus().toggleBlockquote().run(),
        },
        // {
        //   icon: "mdi-format-text-wrapping-wrap",
        //   title: "Hard Break",
        //   action: () => this.editor.chain().focus().setHardBreak().run(),
        // },
        {
          icon: "mdi-format-clear",
          title: "Clear Format",
          action: () => this.editor.chain().focus().clearNodes().unsetAllMarks().run(),
        },
        {
          type: "divider",
        },
        {
          icon: "mdi-link",
          title: "Link",
          action: () => this.setLink(),
        },
        {
          icon: "mdi-link-off",
          title: "Link Remove",
          action: () => this.editor.chain().focus().unsetLink().run(),
        },
        {
          icon: "mdi-image",
          title: "Link",
          action: () => this.addImage(),
        },
        {
          icon: "mdi-table-plus",
          title: "Table",
          action: () => this.editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()
        },
        {
          icon: "mdi-table-remove",
          title: "Table Delete",
          action: () => this.editor.chain().focus().deleteTable().run()
        },
        {
          icon: "mdi-table-column-plus-after",
          title: "Add Column After",
          action: () => this.editor.chain().focus().addColumnAfter().run()
        },
        {
          icon: "mdi-table-column-remove",
          title: "Add Column After",
          action: () => this.editor.chain().focus().deleteColumn().run()
        },
        {
          icon: "mdi-table-row-plus-after",
          title: "Add Row After",
          action: () => this.editor.chain().focus().addRowAfter().run()
        },
        {
          icon: "mdi-table-row-remove",
          title: "Add Row After",
          action: () => this.editor.chain().focus().deleteRow().run()
        },
        {
          icon: "mdi-card",
          title: "CTA",
          action: () => {
            const href = prompt('Enter the URL:', 'www.google.com');
            const text = prompt('Enter the button text:', 'CLICK HERE');
            this.editor.chain().focus().insertButton(href, text).run();
          }
        },
        {
          icon: 'mdi-backspace',
          title: 'Erase ALL',
          action: () => this.editor.commands.clearContent(true)
        }
        // {
        //   icon: "mdi-undo",
        //   title: "Undo",
        //   action: () => this.editor.chain().focus().undo().run(),
        // },
        // {
        //   icon: "mdi-redo",
        //   title: "Redo",
        //   action: () => this.editor.chain().focus().redo().run(),
        // },
      ],
    };
  },
  methods: {
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === '') {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()

        return
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    },
    addImage() {
      const url = window.prompt('URL')

      if (url) {
        this.editor.chain().focus().setImage({ src: url }).run()
      }
    },
    viewSource() {
      this.htmlSource = this.editor.getHTML();
      this.isOpenSource = true;
    },
    setHtml() {
      this.editor.commands.setContent(this.htmlSource);
      this.isOpenSource = false;
    }
  }
};
</script>
