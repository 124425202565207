export const GET_RESERVATIONS_PAGINATION = "[RESERVATIONS] Pagination Get";
export const GET_RESERVATIONS_PAGINATION_SUCCESS = "[RESERVATIONS] Pagination Get Success";
export const GET_RESERVATIONS_PAGINATION_FAIL = "[RESERVATIONS] Pagination Get Fail";

export const GET_RESERVATIONS_COLLECTION = "[RESERVATIONS] Collection Get";
export const GET_RESERVATIONS_COLLECTION_SUCCESS = "[RESERVATIONS] Collection Get Success";
export const GET_RESERVATIONS_COLLECTION_FAIL = "[RESERVATIONS] Collection Get Fail";

export const GET_RESERVATIONS_SINGLE = "[RESERVATIONS] Single Get";
export const GET_RESERVATIONS_SINGLE_SUCCESS = "[RESERVATIONS] Single Get Success";
export const GET_RESERVATIONS_SINGLE_FAIL = "[RESERVATIONS] Single Get Fail";

export const CREATE_RESERVATIONS = "[RESERVATIONS] Create";
export const CREATE_RESERVATIONS_SUCCESS = "[RESERVATIONS] Create Success";
export const CREATE_RESERVATIONS_FAIL = "[RESERVATIONS] Create Fail";
export const CREATE_RESERVATIONS_RESET = "[RESERVATIONS] Create Reset";

export const UPDATE_RESERVATIONS = "[RESERVATIONS] Update";
export const UPDATE_RESERVATIONS_SUCCESS = "[RESERVATIONS] Update Success";
export const UPDATE_RESERVATIONS_FAIL = "[RESERVATIONS] Update Fail";
export const UPDATE_RESERVATIONS_RESET = "[RESERVATIONS] Update Reset";

export const REPLACE_RESERVATIONS = "[RESERVATIONS] Replace";
export const REPLACE_RESERVATIONS_SUCCESS = "[RESERVATIONS] Replace Success";
export const REPLACE_RESERVATIONS_FAIL = "[RESERVATIONS] Replace Fail";
export const REPLACE_RESERVATIONS_RESET = "[RESERVATIONS] Replace Reset";

export const REMOVE_RESERVATIONS = "[RESERVATIONS] Remove";
export const REMOVE_RESERVATIONS_SUCCESS = "[RESERVATIONS] Remove Success";
export const REMOVE_RESERVATIONS_FAIL = "[RESERVATIONS] Remove Fail";

export const FORCE_REMOVE_RESERVATIONS = "[RESERVATIONS] Force Remove";
export const FORCE_REMOVE_RESERVATIONS_SUCCESS = "[RESERVATIONS] Force Remove Success";
export const FORCE_REMOVE_RESERVATIONS_FAIL = "[RESERVATIONS] Force Remove Fail";

export const CREATE_RESERVATIONS_WIZARD = "[RESERVATIONS] Create Wizard";
export const CREATE_RESERVATIONS_WIZARD_SUCCESS = "[RESERVATIONS] Create Wizard Success";
export const CREATE_RESERVATIONS_WIZARD_FAIL = "[RESERVATIONS] Create Wizard Fail";
export const CREATE_RESERVATIONS_WIZARD_RESET = "[RESERVATIONS] Create Wizard Reset";

export const CREATE_RESERVATIONS_MULTIPLE = "[RESERVATIONS] Create Multiple";
export const CREATE_RESERVATIONS_MULTIPLE_SUCCESS = "[RESERVATIONS] Create Multiple Success";
export const CREATE_RESERVATIONS_MULTIPLE_FAIL = "[RESERVATIONS] Create Multiple Fail";
export const CREATE_RESERVATIONS_MULTIPLE_RESET = "[RESERVATIONS] Create Multiple Reset";

export const SEARCH_RESERVATIONS = "[RESERVATIONS] Search Get";
export const SEARCH_RESERVATIONS_SUCCESS = "[RESERVATIONS] Search Get Success";
export const SEARCH_RESERVATIONS_FAIL = "[RESERVATIONS] Search Get Fail";

export const GET_RESERVATIONS_UNASSIGNED = "[RESERVATIONS] Unassigned Get";
export const GET_RESERVATIONS_UNASSIGNED_SUCCESS = "[RESERVATIONS] Unassigned Get Success";
export const GET_RESERVATIONS_UNASSIGNED_FAIL = "[RESERVATIONS] Unassigned Get Fail";

export const LISTEN_RESERVATIONS_UNASSIGNED = "[RESERVATIONS] Unassigned Listen";

export const SPLIT_RESERVATIONS = "[RESERVATIONS] Split";
export const SPLIT_RESERVATIONS_SUCCESS = "[RESERVATIONS] Split Success";
export const SPLIT_RESERVATIONS_FAIL = "[RESERVATIONS] Split Fail";
export const SPLIT_RESERVATIONS_RESET = "[RESERVATIONS] Split Reset";

export const DELETE_SPLIT_RESERVATIONS = "[RESERVATIONS] Split Delete";
export const DELETE_SPLIT_RESERVATIONS_SUCCESS = "[RESERVATIONS] Split Delete Success";
export const DELETE_SPLIT_RESERVATIONS_FAIL = "[RESERVATIONS] Split Delete Fail";
export const DELETE_SPLIT_RESERVATIONS_RESET = "[RESERVATIONS] Split Delete Reset";

export const IMPORT_XLS_RESERVATIONS = "[RESERVATIONS] Import XLS";
export const IMPORT_XLS_RESERVATIONS_SUCCESS = "[RESERVATIONS]Import XLS Success";
export const IMPORT_XLS_RESERVATIONS_FAIL = "[RESERVATIONS] Import XLS Fail";
export const IMPORT_XLS_RESERVATIONS_RESET = "[RESERVATIONS] Import XLS Reset";

export const GET_RESERVATION_CHANGES = "[RESERVATIONS] Get Changes";
export const GET_RESERVATION_CHANGES_SUCCESS = "[RESERVATIONS] Get Changes Success";
export const GET_RESERVATION_CHANGES_FAIL = "[RESERVATIONS] Get Changes Fail";
export const GET_RESERVATION_CHANGES_RESET = "[RESERVATIONS] Get Changes Reset";
