export default [
  {
    name: "users",
    path: "/users",
    component: () => import(/*webpackChunkName: "users" */ "@/pages/users/Index"),
    meta: {
      title: 'titles.users'
    }
  },
  {
    name: "user-property-clusters",
    path: "/users/clusters",
    component: () => import(/*webpackChunkName: "users" */ "@/pages/user-property-clusters/Index"),
    meta: {
      title: 'titles.user_property_clusters'
    }
  },
  {
    name: "user-support-requests",
    path: "/users/:user_id/support-requests",
    component: () => import(/*webpackChunkName: "users" */ "@/pages/user-support-requests/Index"),
    meta: {
      title: 'titles.user_support_requests',
      breadcrumbs: [
        { text: 'titles.users', to: 'users' },
      ],
    }
  }
];
