export const GET_PAYTOURIST_ACCOUNTS_PAGINATION = "[PAYTOURIST_ACCOUNTS] Pagination Get";
export const GET_PAYTOURIST_ACCOUNTS_PAGINATION_SUCCESS = "[PAYTOURIST_ACCOUNTS] Pagination Get Success";
export const GET_PAYTOURIST_ACCOUNTS_PAGINATION_FAIL = "[PAYTOURIST_ACCOUNTS] Pagination Get Fail";

export const GET_PAYTOURIST_ACCOUNTS_COLLECTION = "[PAYTOURIST_ACCOUNTS] Collection Get";
export const GET_PAYTOURIST_ACCOUNTS_COLLECTION_SUCCESS = "[PAYTOURIST_ACCOUNTS] Collection Get Success";
export const GET_PAYTOURIST_ACCOUNTS_COLLECTION_FAIL = "[PAYTOURIST_ACCOUNTS] Collection Get Fail";

export const GET_PAYTOURIST_ACCOUNTS_SINGLE = "[PAYTOURIST_ACCOUNTS] Single Get";
export const GET_PAYTOURIST_ACCOUNTS_SINGLE_SUCCESS = "[PAYTOURIST_ACCOUNTS] Single Get Success";
export const GET_PAYTOURIST_ACCOUNTS_SINGLE_FAIL = "[PAYTOURIST_ACCOUNTS] Single Get Fail";

export const CREATE_PAYTOURIST_ACCOUNTS = "[PAYTOURIST_ACCOUNTS] Create";
export const CREATE_PAYTOURIST_ACCOUNTS_SUCCESS = "[PAYTOURIST_ACCOUNTS] Create Success";
export const CREATE_PAYTOURIST_ACCOUNTS_FAIL = "[PAYTOURIST_ACCOUNTS] Create Fail";
export const CREATE_PAYTOURIST_ACCOUNTS_RESET = "[PAYTOURIST_ACCOUNTS] Create Reset";

export const UPDATE_PAYTOURIST_ACCOUNTS = "[PAYTOURIST_ACCOUNTS] Update";
export const UPDATE_PAYTOURIST_ACCOUNTS_SUCCESS = "[PAYTOURIST_ACCOUNTS] Update Success";
export const UPDATE_PAYTOURIST_ACCOUNTS_FAIL = "[PAYTOURIST_ACCOUNTS] Update Fail";
export const UPDATE_PAYTOURIST_ACCOUNTS_RESET = "[PAYTOURIST_ACCOUNTS] Update Reset";

export const REMOVE_PAYTOURIST_ACCOUNTS = "[PAYTOURIST_ACCOUNTS] Remove";
export const REMOVE_PAYTOURIST_ACCOUNTS_SUCCESS = "[PAYTOURIST_ACCOUNTS] Remove Success";
export const REMOVE_PAYTOURIST_ACCOUNTS_FAIL = "[PAYTOURIST_ACCOUNTS] Remove Fail";

export const VALIDATE_PAYTOURIST_ACCOUNT = "[PAYTOURIST_ACCOUNTS] Validate";
export const VALIDATE_PAYTOURIST_ACCOUNT_SUCCESS = "[PAYTOURIST_ACCOUNTS] Validate Success";
export const VALIDATE_PAYTOURIST_ACCOUNT_FAIL = "[PAYTOURIST_ACCOUNTS] Validate Fail";
export const VALIDATE_PAYTOURIST_ACCOUNT_RESET = "[PAYTOURIST_ACCOUNTS] Validate Reset";
