export const GET_ROOM_TYPES_LIST = "[ROOM_TYPES] List Get";
export const GET_ROOM_TYPES_LIST_SUCCESS = "[ROOM_TYPES] List Get Success";
export const GET_ROOM_TYPES_LIST_FAIL = "[ROOM_TYPES] List Get Fail";

export const GET_ROOM_TYPES_PAGINATION = "[ROOM_TYPES] Pagination Get";
export const GET_ROOM_TYPES_PAGINATION_SUCCESS = "[ROOM_TYPES] Pagination Get Success";
export const GET_ROOM_TYPES_PAGINATION_FAIL = "[ROOM_TYPES] Pagination Get Fail";

export const GET_ROOM_TYPES_COLLECTION = "[ROOM_TYPES] Collection Get";
export const GET_ROOM_TYPES_COLLECTION_SUCCESS = "[ROOM_TYPES] Collection Get Success";
export const GET_ROOM_TYPES_COLLECTION_FAIL = "[ROOM_TYPES] Collection Get Fail";

export const GET_ROOM_TYPES_SINGLE = "[ROOM_TYPES] Single Get";
export const GET_ROOM_TYPES_SINGLE_SUCCESS = "[ROOM_TYPES] Single Get Success";
export const GET_ROOM_TYPES_SINGLE_FAIL = "[ROOM_TYPES] Single Get Fail";

export const CREATE_ROOM_TYPES = "[ROOM_TYPES] Create";
export const CREATE_ROOM_TYPES_SUCCESS = "[ROOM_TYPES] Create Success";
export const CREATE_ROOM_TYPES_FAIL = "[ROOM_TYPES] Create Fail";
export const CREATE_ROOM_TYPES_RESET = "[ROOM_TYPES] Create Reset";

export const UPDATE_ROOM_TYPES = "[ROOM_TYPES] Update";
export const UPDATE_ROOM_TYPES_SUCCESS = "[ROOM_TYPES] Update Success";
export const UPDATE_ROOM_TYPES_FAIL = "[ROOM_TYPES] Update Fail";
export const UPDATE_ROOM_TYPES_RESET = "[ROOM_TYPES] Update Reset";

export const REMOVE_ROOM_TYPES = "[ROOM_TYPES] Remove";
export const REMOVE_ROOM_TYPES_SUCCESS = "[ROOM_TYPES] Remove Success";
export const REMOVE_ROOM_TYPES_FAIL = "[ROOM_TYPES] Remove Fail";

export const GET_ROOM_TYPES_COLLECTION_2 = "[ROOM_TYPES] Collection2 Get";
export const GET_ROOM_TYPES_COLLECTION_2_SUCCESS = "[ROOM_TYPES] Collection2 Get Success";
export const GET_ROOM_TYPES_COLLECTION_2_FAIL = "[ROOM_TYPES] Collection2 Get Fail";

export const GET_IMPORT_OCTORATE_RATES = "[ROOM_TYPES] Octorate Import";
export const GET_IMPORT_OCTORATE_RATES_SUCCESS = "[ROOM_TYPES] Octorate Import Success";
export const GET_IMPORT_OCTORATE_RATES_FAIL = "[ROOM_TYPES] Octorate Import Fail";
