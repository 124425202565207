import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_TOURS_PAGINATION,
  GET_TOURS_PAGINATION_SUCCESS,
  GET_TOURS_PAGINATION_FAIL,
  GET_TOURS_COLLECTION,
  GET_TOURS_COLLECTION_SUCCESS,
  GET_TOURS_COLLECTION_FAIL,
  GET_TOURS_SINGLE,
  GET_TOURS_SINGLE_SUCCESS,
  GET_TOURS_SINGLE_FAIL,
  CREATE_TOURS,
  CREATE_TOURS_SUCCESS,
  CREATE_TOURS_FAIL,
  CREATE_TOURS_RESET,
  UPDATE_TOURS,
  UPDATE_TOURS_SUCCESS,
  UPDATE_TOURS_FAIL,
  UPDATE_TOURS_RESET,
  REMOVE_TOURS,
  REMOVE_TOURS_SUCCESS,
  REMOVE_TOURS_FAIL
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_TOURS_PAGINATION,
    endPoint: "tours/paginated",
    mutationFail: GET_TOURS_PAGINATION_FAIL,
    mutationSuccess: GET_TOURS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_TOURS_COLLECTION,
    endPoint: "tours/collected",
    mutationFail: GET_TOURS_COLLECTION_FAIL,
    mutationSuccess: GET_TOURS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_TOURS_SINGLE,
    endPoint: "tours/single",
    mutationFail: GET_TOURS_SINGLE_FAIL,
    mutationSuccess: GET_TOURS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_TOURS,
    actionReset: CREATE_TOURS_RESET,
    endPoint: "tours/create",
    mutationFail: CREATE_TOURS_FAIL,
    mutationSuccess: CREATE_TOURS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_TOURS,
    actionReset: UPDATE_TOURS_RESET,
    endPoint: "tours/update",
    mutationFail: UPDATE_TOURS_FAIL,
    mutationSuccess: UPDATE_TOURS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_TOURS,
    endPoint: "tours/delete",
    mutationFail: REMOVE_TOURS_FAIL,
    mutationSuccess: REMOVE_TOURS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
