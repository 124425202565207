export const GET_GUESTS_LIST = "[GUESTS] List Get";
export const GET_GUESTS_LIST_SUCCESS = "[GUESTS] List Get Success";
export const GET_GUESTS_LIST_FAIL = "[GUESTS] List Get Fail";

export const GET_GUESTS_PAGINATION = "[GUESTS] Pagination Get";
export const GET_GUESTS_PAGINATION_SUCCESS = "[GUESTS] Pagination Get Success";
export const GET_GUESTS_PAGINATION_FAIL = "[GUESTS] Pagination Get Fail";

export const GET_GUESTS_COLLECTION = "[GUESTS] Collection Get";
export const GET_GUESTS_COLLECTION_SUCCESS = "[GUESTS] Collection Get Success";
export const GET_GUESTS_COLLECTION_FAIL = "[GUESTS] Collection Get Fail";

export const GET_GUESTS_SINGLE = "[GUESTS] Single Get";
export const GET_GUESTS_SINGLE_SUCCESS = "[GUESTS] Single Get Success";
export const GET_GUESTS_SINGLE_FAIL = "[GUESTS] Single Get Fail";

export const CREATE_GUESTS = "[GUESTS] Create";
export const CREATE_GUESTS_SUCCESS = "[GUESTS] Create Success";
export const CREATE_GUESTS_FAIL = "[GUESTS] Create Fail";
export const CREATE_GUESTS_RESET = "[GUESTS] Create Reset";

export const UPDATE_GUESTS = "[GUESTS] Update";
export const UPDATE_GUESTS_SUCCESS = "[GUESTS] Update Success";
export const UPDATE_GUESTS_FAIL = "[GUESTS] Update Fail";
export const UPDATE_GUESTS_RESET = "[GUESTS] Update Reset";

export const REPLACE_GUESTS = "[GUESTS] Replace";
export const REPLACE_GUESTS_SUCCESS = "[GUESTS] Replace Success";
export const REPLACE_GUESTS_FAIL = "[GUESTS] Replace Fail";
export const REPLACE_GUESTS_RESET = "[GUESTS] Replace Reset";

export const REMOVE_GUESTS = "[GUESTS] Remove";
export const REMOVE_GUESTS_SUCCESS = "[GUESTS] Remove Success";
export const REMOVE_GUESTS_FAIL = "[GUESTS] Remove Fail";

export const GET_GUESTS_GROUP = "[GUESTS] Group Get";
export const GET_GUESTS_GROUP_SUCCESS = "[GUESTS] Group Get Success";
export const GET_GUESTS_GROUP_FAIL = "[GUESTS] Group Get Fail";

export const GET_GUESTS_FAMILY = "[GUESTS] Family Get";
export const GET_GUESTS_FAMILY_SUCCESS = "[GUESTS] Family Get Success";
export const GET_GUESTS_FAMILY_FAIL = "[GUESTS] Family Get Fail";

export const EXPORT_PDF_GUESTS = "[GUESTS] Export PDF";

export const GET_PRIVACY_CONTENT_GUESTS = "[GUESTS] Privacy Content Get";
export const GET_PRIVACY_CONTENT_GUESTS_SUCCESS = "[GUESTS] Privacy Content Get Success";
export const GET_PRIVACY_CONTENT_GUESTS_FAIL = "[GUESTS] Privacy Content Get Fail";

export const SAVE_PRIVACY_GUESTS = "[GUESTS] Privacy Save";
export const SAVE_PRIVACY_GUESTS_SUCCESS = "[GUESTS] Privacy Save Success";
export const SAVE_PRIVACY_GUESTS_FAIL = "[GUESTS] Privacy Save Fail";

export const DETACH_GUEST = "[GUESTS] Detach Save";
export const DETACH_GUEST_SUCCESS = "[GUESTS] Detach Success";
export const DETACH_GUEST_FAIL = "[GUESTS] Detach Fail";
