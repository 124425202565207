import Vue from "vue";
import qs from "qs";
import axios from "axios";
import VueAxios from "vue-axios";
import SessionService from '@/libraries/sessionjs/src';
import {
  saveSession,
  destroySession
} from "@/services/oauth";
import {
  getLocale,
  getTimezone,
  objectToFormData,
  downloadAxiosResponse,
} from "@/helpers/functions";

import apiConfig from "@/config/api";

const ApiService = {
  /**
   *
   */
  refreshInProgress: false,

  /**
   *
   */
  startRefresh() {
    this.refreshInProgress = true;
  },

  /**
   *
   */
  finishRefresh() {
    this.refreshInProgress = false;
  },

  /**
   *
   * @returns {boolean}
   */
  isRefreshing() {
    return this.refreshInProgress;
  },

  /**
   *
   */
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = apiConfig.uri;
  },

  /**
   *
   */
  handleRequest() {
    Vue.axios.interceptors.request.use(
      request => {
        let token = SessionService.get("token");
        let refreshToken = SessionService.get("refreshToken");

        request = this.setHeader(request, "Accept-Language", getLocale());
        request = this.setHeader(request, "The-Timezone-IANA", getTimezone());
        request = this.setHeader(request, 'X-Platform', 'frontend');

        if (token) {
          request = this.setHeader(request, "Authorization", "Bearer " + token);
        } else if (refreshToken) {
          return new Promise(resolve => {
            if (this.isRefreshing()) {
              const interval = setInterval(() => {
                if (!this.isRefreshing()) {
                  clearInterval(interval);

                  token = SessionService.get("token");

                  request = this.setHeader(
                    request,
                    "Authorization",
                    "Bearer " + token
                  );

                  resolve(request);
                }
              });
            } else {
              this.startRefresh();

              const instance = axios.create({
                baseURL: apiConfig.uri
              });

              instance
                .post("auth/session/refresh", {
                  refreshToken
                }, {
                  headers: {
                    "Accept-Language": getLocale(),
                    "The-Timezone-IANA": getTimezone(),
                  }
                })
                .then(response => {
                  saveSession(response.data.data, true);
                  request = this.setHeader(
                    request,
                    "Authorization",
                    "Bearer " + response.data.data.token
                  );

                  this.finishRefresh();

                  resolve(request);
                }).catch(error => {
                  destroySession();

                  this.finishRefresh();

                  resolve(request);
                });
            }
          });
        }

        return request;
      },
      error => {
        return Promise.reject(error);
      }
    );
  },

  /**
   *
   * @param callback
   */
  handleResponse(callback) {
    Vue.axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response.status !== 422) {
          callback(error.response.status, error.response.data.message);
        }

        return Promise.reject(error);
      }
    );
  },

  /**
   *
   * @param request
   * @param header
   * @param value
   * @returns {*}
   */
  setHeader(request, header, value) {
    request.headers[header] = value;
    return request;
  },

  /**
   *
   * @param resource
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  query(resource, params) {
    return Vue.axios
      .get(resource, {
        params: params,
        paramsSerializer: function(params) {
          return qs.stringify(params, {
            arrayFormat: 'indices'
          })
        },
      });
  },

  /**
   *
   * @param resource
   * @param slug
   * @returns {Promise<AxiosResponse<any>>}
   */
  get(resource, slug = "") {
    const endPoint = slug ? `${resource}/${slug}` : `${resource}`;

    return Vue.axios.get(endPoint);
  },

  /**
   *
   * @param resource
   * @param params
   * @returns {AxiosPromise<any>}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   *
   * @param resource
   * @param slug
   * @param params
   * @returns {AxiosPromise<any>}
   */
  update(resource, slug, params) {
    const endPoint = slug ? `${resource}/${slug}` : `${resource}`;

    return Vue.axios.put(endPoint, params);
  },

  /**
   *
   * @param resource
   * @param params
   * @returns {AxiosPromise<any>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   *
   * @param resource
   * @param params
   * @returns {Promise<AxiosResponse<T>>}
   */
  patch(resource, params) {
    return Vue.axios.patch(`${resource}`, params);
  },

  /**
   * @param resource
   * @param params
   * @returns {Promise<AxiosResponse<any>>}
   */
  delete(resource, params) {
    return Vue.axios.delete(`${resource}`, params);
  },

  /**
   *
   * @param resource
   * @param params
   */
  postUpload(resource, params) {
    return this.post(resource, objectToFormData(params));
  },

  /**
   *
   * @param resource
   * @param isBlank
   */
  download(resource, isBlank) {
    const responseType = Vue.axios.defaults.responseType;

    Vue.axios.defaults.responseType = 'blob';
    Vue.axios.get(resource).then(response => {
      downloadAxiosResponse(response);
    });
    Vue.axios.defaults.responseType = responseType;
  },
};

export default ApiService;
