import list from "@/store/core/list";
import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_UNIT_ICALS_LIST,
  GET_UNIT_ICALS_LIST_SUCCESS,
  GET_UNIT_ICALS_LIST_FAIL,
  GET_UNIT_ICALS_PAGINATION,
  GET_UNIT_ICALS_PAGINATION_SUCCESS,
  GET_UNIT_ICALS_PAGINATION_FAIL,
  GET_UNIT_ICALS_COLLECTION,
  GET_UNIT_ICALS_COLLECTION_SUCCESS,
  GET_UNIT_ICALS_COLLECTION_FAIL,
  GET_UNIT_ICALS_SINGLE,
  GET_UNIT_ICALS_SINGLE_SUCCESS,
  GET_UNIT_ICALS_SINGLE_FAIL,
  CREATE_UNIT_ICALS,
  CREATE_UNIT_ICALS_SUCCESS,
  CREATE_UNIT_ICALS_FAIL,
  CREATE_UNIT_ICALS_RESET,
  UPDATE_UNIT_ICALS,
  UPDATE_UNIT_ICALS_SUCCESS,
  UPDATE_UNIT_ICALS_FAIL,
  UPDATE_UNIT_ICALS_RESET,
  REMOVE_UNIT_ICALS,
  REMOVE_UNIT_ICALS_SUCCESS,
  REMOVE_UNIT_ICALS_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_UNIT_ICALS_LIST,
    endPoint: "unit-icals/list",
    mutationFail: GET_UNIT_ICALS_LIST_FAIL,
    mutationSuccess: GET_UNIT_ICALS_LIST_SUCCESS
  }
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_UNIT_ICALS_PAGINATION,
    endPoint: "unit-icals/paginated",
    mutationFail: GET_UNIT_ICALS_PAGINATION_FAIL,
    mutationSuccess: GET_UNIT_ICALS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_UNIT_ICALS_COLLECTION,
    endPoint: "unit-icals/collected",
    mutationFail: GET_UNIT_ICALS_COLLECTION_FAIL,
    mutationSuccess: GET_UNIT_ICALS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_UNIT_ICALS_SINGLE,
    endPoint: "unit-icals/single",
    mutationFail: GET_UNIT_ICALS_SINGLE_FAIL,
    mutationSuccess: GET_UNIT_ICALS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_UNIT_ICALS,
    actionReset: CREATE_UNIT_ICALS_RESET,
    endPoint: "unit-icals/create",
    mutationFail: CREATE_UNIT_ICALS_FAIL,
    mutationSuccess: CREATE_UNIT_ICALS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_UNIT_ICALS,
    actionReset: UPDATE_UNIT_ICALS_RESET,
    endPoint: "unit-icals/update",
    mutationFail: UPDATE_UNIT_ICALS_FAIL,
    mutationSuccess: UPDATE_UNIT_ICALS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_UNIT_ICALS,
    endPoint: "unit-icals/delete",
    mutationFail: REMOVE_UNIT_ICALS_FAIL,
    mutationSuccess: REMOVE_UNIT_ICALS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([]);

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
