import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_AMENITIES_PAGINATION,
  GET_AMENITIES_PAGINATION_SUCCESS,
  GET_AMENITIES_PAGINATION_FAIL,
  GET_AMENITIES_COLLECTION,
  GET_AMENITIES_COLLECTION_SUCCESS,
  GET_AMENITIES_COLLECTION_FAIL,
  GET_AMENITIES_SINGLE,
  GET_AMENITIES_SINGLE_SUCCESS,
  GET_AMENITIES_SINGLE_FAIL,
  CREATE_AMENITIES,
  CREATE_AMENITIES_SUCCESS,
  CREATE_AMENITIES_FAIL,
  CREATE_AMENITIES_RESET,
  UPDATE_AMENITIES,
  UPDATE_AMENITIES_SUCCESS,
  UPDATE_AMENITIES_FAIL,
  UPDATE_AMENITIES_RESET,
  REMOVE_AMENITIES,
  REMOVE_AMENITIES_SUCCESS,
  REMOVE_AMENITIES_FAIL
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_AMENITIES_PAGINATION,
    endPoint: "amenities/paginated",
    mutationFail: GET_AMENITIES_PAGINATION_FAIL,
    mutationSuccess: GET_AMENITIES_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_AMENITIES_COLLECTION,
    endPoint: "amenities/collected",
    mutationFail: GET_AMENITIES_COLLECTION_FAIL,
    mutationSuccess: GET_AMENITIES_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_AMENITIES_SINGLE,
    endPoint: "amenities/single",
    mutationFail: GET_AMENITIES_SINGLE_FAIL,
    mutationSuccess: GET_AMENITIES_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_AMENITIES,
    actionReset: CREATE_AMENITIES_RESET,
    endPoint: "amenities/create",
    mutationFail: CREATE_AMENITIES_FAIL,
    mutationSuccess: CREATE_AMENITIES_SUCCESS
  },
  editingRequest: {
    action: UPDATE_AMENITIES,
    actionReset: UPDATE_AMENITIES_RESET,
    endPoint: "amenities/update",
    mutationFail: UPDATE_AMENITIES_FAIL,
    mutationSuccess: UPDATE_AMENITIES_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_AMENITIES,
    endPoint: "amenities/delete",
    mutationFail: REMOVE_AMENITIES_FAIL,
    mutationSuccess: REMOVE_AMENITIES_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
