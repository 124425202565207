import Vue from "vue";
import Vuex from "vuex";

import app from "./app/reducers";
import auth from "./auth/reducers";
import data from "./data/reducers";
import vatTypes from "./vat-types/reducers";
import roomTypes from "./room-types/reducers";
import roomTypeGoogleSheet from "./room-type-google-sheet/reducers";
import properties from "./properties/reducers";
import clients from "./clients/reducers";
import companies from "./companies/reducers";
import companySubscription from "./company-subscription/reducers";
import companyPayments from "./company-payments/reducers";
import companyLocations from "./company-locations/reducers";
import companyJournals from "./company-journals/reducers";
import tags from "./tags/reducers";
import reservations from "./reservations/reducers";
import reservationTasks from "./reservation-tasks/reducers";
import reservationCalendar from './reservation-calendar/reducers';
import reservationSources from './reservation-sources/reducers';
import reservationExtras from './reservation-extras/reducers';
import reservationContracts from './reservation-contracts/reducers';
import reservationUnitCategoryCosts from './reservation-unit-category-costs/reducers';
import reservationAmountDetails from './reservation-amount-details/reducers';
import reservationMeta from './reservation-meta/reducers';
import derivations from './derivations/reducers';
import settings from './settings/reducers';
import rateCalendar from './rate-calendar/reducers';
import rateHistory from './rate-history/reducers';
import unitCategories from './unit-categories/reducers';
import octorateAccommodations from './octorate-accommodations/reducers';
import octorateConnections from './octorate-connections/reducers';
import octorateConnectionRoomTypes from './octorate-connection-room-types/reducers';
import octorateReservations from './octorate-reservations/reducers';
import bookingEngine from './booking-engine/reducers';
import bookingNetworks from './booking-networks/reducers';
import bookingNetworkProperties from './booking-network-properties/reducers';
import units from './units/reducers';
import images from './images/reducers';
import propertyExtras from './property-extras/reducers';
import propertyRevenueShares from './property-revenue-shares/reducers';
import propertyRevenueShareRules from './property-revenue-share-rules/reducers';
import propertyCosts from './property-costs/reducers';
import propertyPromotions from './property-promotions/reducers';
import propertyMessageTemplates from './property-message-templates/reducers';
import propertyClusters from './property-clusters/reducers';
import propertyOwners from './property-owners/reducers';
import propertyMeta from './property-meta/reducers';
import payments from './payments/reducers';
import paymentSources from './payment-sources/reducers';
import unitCategoryCosts from './unit-category-costs/reducers';
import unitICals from './unit-icals/reducers';
import users from './users/reducers';
import userPropertyClusters from './user-property-clusters/reducers';
import roles from './roles/reducers';
import amenities from './amenities/reducers';
import statistics from './statistics/reducers';
import assets from './assets/reducers';
import workers from './workers/reducers';
import workOrders from "./work-orders/reducers";
import workOrderComments from "./work-order-comments/reducers";
import cards from "./cards/reducers";
import guests from "./guests/reducers";
import locations from "./locations/reducers";
import bills from './bills/reducers';
import documents from './documents/reducers';
import ownerDocuments from './owner-documents/reducers';
import messages from './messages/reducers';
import clusters from './clusters/reducers';
import rmt from './rmt/reducers';
import helps from './helps/reducers';
import bookingPaymentProviders from './booking-payment-providers/reducers';
import announcements from './announcements/reducers';
import policeAccounts from './police-accounts/reducers';
import statAccounts from './stat-accounts/reducers';
import legalComplianceRequests from './legal-compliance-requests/reducers';
import ratePlans from './rate-plans/reducers';
import tours from './tours/reducers';
import quotes from './quotes/reducers';
import quoteRequests from './quote-requests/reducers';
import housekeepingSettings from './housekeeping-settings/reducers';
import paytouristAccounts from './paytourist-accounts/reducers';
import bookingNetworkPages from './booking-network-pages/reducers';
import bookingNetworkBlocks from './booking-network-blocks/reducers';
import globalSettings from './global-settings/reducers';
import bookingNetworkDestinations from './booking-network-destinations/reducers';
import propertyContracts from './property-contracts/reducers';
import companyInvoices from './company-invoices/reducers';
import companySmartDevices from './company-smart-devices/reducers';
import companySmartDeviceAccounts from './company-smart-device-accounts/reducers';
import subscriptionCoupons from './subscription-coupons/reducers';
import tickets from './tickets/reducers';
import ticketMessages from './ticket-messages/reducers';
import events from './events/reducers';
import fonts from './fonts/reducers';
import calendarEvents from './calendar-events/reducers';
import resources from './resources/reducers';
import resourceEvents from './resource-events/reducers';
import workingProcesses from './working-processes/reducers';
import agencies from './agencies/reducers';
import companyGoogleAccounts from './company-google-accounts/reducers';
import warehouses from './warehouses/reducers';
import pointOfSales from './point-of-sales/reducers';
import products from './products/reducers';
import productCategories from './product-categories/reducers';
import inventoryMovements from './inventory-movements/reducers';
import warehouseProduct from './warehouse-product/reducers';
import calendar from './calendar/reducers';
import calendarSettings from './calendar-settings/reducers';
import channelManagers from './channel-managers/reducers';
import activityLogs from './activity-logs/reducers';
import bookingPolicy from './booking-policy/reducers';
import bookingSettings from './booking-settings/reducers';
import reports from './reports/reducers';
import userSupportRequests from './user-support-requests/reducers';
import housekeepingTasks from "./housekeeping-tasks/reducers";
import quickbooks from "./quickbooks/reducers";
import ciaoChannel from "./ciao-channel/reducers";
import mfa from "./mfa/reducers";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    auth,
    data,
    vatTypes,
    roomTypes,
    roomTypeGoogleSheet,
    properties,
    clients,
    companies,
    companySubscription,
    companyPayments,
    companyLocations,
    companyJournals,
    tags,
    reservations,
    reservationCalendar,
    reservationTasks,
    reservationSources,
    reservationExtras,
    reservationContracts,
    reservationUnitCategoryCosts,
    reservationAmountDetails,
    reservationMeta,
    derivations,
    settings,
    rateCalendar,
    rateHistory,
    unitCategories,
    octorateAccommodations,
    octorateConnections,
    octorateConnectionRoomTypes,
    octorateReservations,
    units,
    bookingEngine,
    bookingNetworks,
    bookingNetworkProperties,
    images,
    propertyExtras,
    propertyRevenueShares,
    propertyRevenueShareRules,
    propertyCosts,
    propertyPromotions,
    propertyMessageTemplates,
    propertyClusters,
    propertyOwners,
    propertyMeta,
    payments,
    paymentSources,
    unitCategoryCosts,
    unitICals,
    users,
    userPropertyClusters,
    roles,
    amenities,
    statistics,
    assets,
    workers,
    workOrders,
    workOrderComments,
    cards,
    guests,
    locations,
    bills,
    documents,
    ownerDocuments,
    messages,
    clusters,
    rmt,
    helps,
    bookingPaymentProviders,
    announcements,
    policeAccounts,
    statAccounts,
    legalComplianceRequests,
    ratePlans,
    tours,
    housekeepingSettings,
    quotes,
    quoteRequests,
    paytouristAccounts,
    bookingNetworkPages,
    bookingNetworkBlocks,
    globalSettings,
    bookingNetworkDestinations,
    propertyContracts,
    companyInvoices,
    companySmartDevices,
    companySmartDeviceAccounts,
    subscriptionCoupons,
    tickets,
    ticketMessages,
    events,
    fonts,
    calendarEvents,
    resources,
    resourceEvents,
    workingProcesses,
    agencies,
    companyGoogleAccounts,
    warehouses,
    pointOfSales,
    products,
    productCategories,
    inventoryMovements,
    warehouseProduct,
    calendar,
    calendarSettings,
    channelManagers,
    activityLogs,
    bookingPolicy,
    bookingSettings,
    reports,
    userSupportRequests,
    housekeepingTasks,
    quickbooks,
    ciaoChannel,
    mfa,
  }
});
