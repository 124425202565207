import ApiService from "@/services/api";

import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_AGENCIES_LIST,
  GET_AGENCIES_LIST_SUCCESS,
  GET_AGENCIES_LIST_FAIL,
  GET_AGENCIES_PAGINATION,
  GET_AGENCIES_PAGINATION_SUCCESS,
  GET_AGENCIES_PAGINATION_FAIL,
  GET_AGENCIES_COLLECTION,
  GET_AGENCIES_COLLECTION_SUCCESS,
  GET_AGENCIES_COLLECTION_FAIL,
  GET_AGENCIES_SINGLE,
  GET_AGENCIES_SINGLE_SUCCESS,
  GET_AGENCIES_SINGLE_FAIL,
  CREATE_AGENCIES,
  CREATE_AGENCIES_SUCCESS,
  CREATE_AGENCIES_FAIL,
  CREATE_AGENCIES_RESET,
  UPDATE_AGENCIES,
  UPDATE_AGENCIES_SUCCESS,
  UPDATE_AGENCIES_FAIL,
  UPDATE_AGENCIES_RESET,
  REPLACE_AGENCIES,
  REPLACE_AGENCIES_SUCCESS,
  REPLACE_AGENCIES_FAIL,
  REPLACE_AGENCIES_RESET,
  REMOVE_AGENCIES,
  REMOVE_AGENCIES_SUCCESS,
  REMOVE_AGENCIES_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_AGENCIES_LIST,
    endPoint: "agencies/list",
    mutationFail: GET_AGENCIES_LIST_FAIL,
    mutationSuccess: GET_AGENCIES_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_AGENCIES_PAGINATION,
    endPoint: "agencies/paginated",
    mutationFail: GET_AGENCIES_PAGINATION_FAIL,
    mutationSuccess: GET_AGENCIES_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_AGENCIES_COLLECTION,
    endPoint: "agencies/collected",
    mutationFail: GET_AGENCIES_COLLECTION_FAIL,
    mutationSuccess: GET_AGENCIES_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_AGENCIES_SINGLE,
    endPoint: "agencies/single",
    mutationFail: GET_AGENCIES_SINGLE_FAIL,
    mutationSuccess: GET_AGENCIES_SINGLE_SUCCESS
  },
  creationRequest: {
    isUpload: true,
    action: CREATE_AGENCIES,
    actionReset: CREATE_AGENCIES_RESET,
    endPoint: "agencies/create",
    mutationFail: CREATE_AGENCIES_FAIL,
    mutationSuccess: CREATE_AGENCIES_SUCCESS
  },
  editingRequest: {
    isUpload: true,
    action: UPDATE_AGENCIES,
    actionReset: UPDATE_AGENCIES_RESET,
    endPoint: "agencies/update",
    mutationFail: UPDATE_AGENCIES_FAIL,
    mutationSuccess: UPDATE_AGENCIES_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_AGENCIES,
    actionReset: REPLACE_AGENCIES_RESET,
    endPoint: "agencies/replace",
    mutationFail: REPLACE_AGENCIES_FAIL,
    mutationSuccess: REPLACE_AGENCIES_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_AGENCIES,
    endPoint: "agencies/delete",
    mutationFail: REMOVE_AGENCIES_FAIL,
    mutationSuccess: REMOVE_AGENCIES_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.state,
};

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
