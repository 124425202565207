import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  CM_SYNC_RATES,
  CM_SYNC_RATES_SUCCESS,
  CM_SYNC_RATES_FAIL,

  CM_GET_CONNECTIONS,
  CM_GET_CONNECTIONS_SUCCESS,
  CM_GET_CONNECTIONS_FAIL,

  CM_GET_PORTALS,
  CM_GET_PORTALS_SUCCESS,
  CM_GET_PORTALS_FAIL,
} from "./actions";

const crudProcessed = crud.process({
});

const actionsProcessed = action.processMultiple([
  {
    method: 'post',
    name: 'syncRatesRequest',
    action: CM_SYNC_RATES,
    endPoint: 'ciao-channel/sync/rates',
    mutations: {
      fail: CM_SYNC_RATES_FAIL,
      success: CM_SYNC_RATES_SUCCESS,
    },
  },
  {
    name: 'getConnectionsRequest',
    action: CM_GET_CONNECTIONS,
    endPoint: 'ciao-channel/connections',
    mutations: {
      fail: CM_GET_CONNECTIONS_FAIL,
      success: CM_GET_CONNECTIONS_SUCCESS,
    },
  },
  {
    name: 'getPortalsRequest',
    action: CM_GET_PORTALS,
    endPoint: 'ciao-channel/portals',
    mutations: {
      fail: CM_GET_PORTALS_FAIL,
      success: CM_GET_PORTALS_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
