<template>
  <v-btn-toggle v-model="computedValue" shaped mandatory :dense="dense">
    <v-btn :value="1" active-class="warning">{{ $lang('main.words.vat_included') }}</v-btn>
    <v-btn :value="0">{{ $lang('main.words.vat_excluded') }}</v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: "CVatOption",
  props: {
    value: {
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  },
}
</script>
