import {
  STORAGE_LOCAL,
  STORAGE_COOKIE
} from "./constants";
import { Local } from "./storages/local";
import { Cookie } from "./storages/cookie";

export class SessionService {
  storage;
  storageInstance;
  static instance;
  static diskStorage;

  /**
   * @param storage
   */
  constructor(storage = STORAGE_COOKIE) {
    this.setStorage(storage);
  }

  /**
   * @param storage
   * @returns {*}
   */
  static disk(storage) {
    this.diskStorage = storage;
    this.instance = new SessionService(storage);

    return SessionService;
  }

  /**
   * @returns {*}
   */
  static getInstance() {
    if (!this.instance || !this.diskStorage) {
      this.instance = new SessionService();
    }

    this.diskStorage = null;

    return this.instance;
  }

  /**
   * @param storage
   * @returns {SessionService}
   */
  setStorage(storage) {
    this.storage = storage;
    this.createStorageInstance();

    return this;
  }

  /**
   * @returns {*}
   */
  getStorageInstance() {
    return this.storageInstance;
  }

  /**
   * @returns {*}
   */
  createStorageInstance() {
    if (this.storage === STORAGE_LOCAL) {
      this.storageInstance = new Local();
    }
    if (this.storage === STORAGE_COOKIE) {
      this.storageInstance = new Cookie();
    }
  }

  /**
   * Get key
   *
   * @param key
   * @returns {*}
   */
  static get(key) {
    return this.getInstance().getStorageInstance().get(key);
  }

  /**
   * Set key
   *
   * @param key
   * @param value
   * @param options
   * @returns {*}
   */
  static set(key, value, options = {}) {
    return this.getInstance().getStorageInstance().set(key, value, options);
  }

  /**
   * Remove key
   *
   * @param key
   * @param options
   * @returns {*}
   */
  static remove(key, options = {}) {
    return this.getInstance().getStorageInstance().remove(key, options);
  }

  /**
   * Get all keys
   *
   * @returns {*}
   */
  static getAll() {
    return this.getInstance().getStorageInstance().getAll();
  }
}
