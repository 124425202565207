import Cookies from "universal-cookie";

export class Cookie {
  cookies;

  /**
   *
   */
  constructor() {
    this.createInstance();
  }

  /**
   *
   */
  createInstance() {
    this.cookies = this.getInstance();
  }

  /**
   * Get instance
   *
   * @returns {*}
   */
  getInstance() {
    return this.cookies || new Cookies();
  }

  /**
   * Get key
   *
   * @param key
   * @returns {*}
   */
  get(key) {
    return this.getInstance().get(key);
  }

  /**
   * Set key
   *
   * @param key
   * @param value
   * @param options
   * @returns {*}
   */
  set(key, value, options = {}) {
    return this.getInstance().set(key, value, options);
  }

  /**
   * Remove key
   *
   * @param key
   * @param options
   * @returns {*}
   */
  remove(key, options = {}) {
    return this.getInstance().remove(key, options);
  }

  /**
   * Get all keys
   *
   * @returns {*}
   */
  getAll() {
    return this.getInstance().getAll();
  }
}
