export const GET_HOUSEKEEPING_TASKS_LIST = "[HOUSEKEEPING_TASKS] List Get";
export const GET_HOUSEKEEPING_TASKS_LIST_SUCCESS = "[HOUSEKEEPING_TASKS] List Get Success";
export const GET_HOUSEKEEPING_TASKS_LIST_FAIL = "[HOUSEKEEPING_TASKS] List Get Fail";

export const GET_HOUSEKEEPING_TASKS_PAGINATION = "[HOUSEKEEPING_TASKS] Pagination Get";
export const GET_HOUSEKEEPING_TASKS_PAGINATION_SUCCESS = "[HOUSEKEEPING_TASKS] Pagination Get Success";
export const GET_HOUSEKEEPING_TASKS_PAGINATION_FAIL = "[HOUSEKEEPING_TASKS] Pagination Get Fail";

export const GET_HOUSEKEEPING_TASKS_COLLECTION = "[HOUSEKEEPING_TASKS] Collection Get";
export const GET_HOUSEKEEPING_TASKS_COLLECTION_SUCCESS = "[HOUSEKEEPING_TASKS] Collection Get Success";
export const GET_HOUSEKEEPING_TASKS_COLLECTION_FAIL = "[HOUSEKEEPING_TASKS] Collection Get Fail";

export const GET_HOUSEKEEPING_TASKS_SINGLE = "[HOUSEKEEPING_TASKS] Single Get";
export const GET_HOUSEKEEPING_TASKS_SINGLE_SUCCESS = "[HOUSEKEEPING_TASKS] Single Get Success";
export const GET_HOUSEKEEPING_TASKS_SINGLE_FAIL = "[HOUSEKEEPING_TASKS] Single Get Fail";

export const CREATE_HOUSEKEEPING_TASKS = "[HOUSEKEEPING_TASKS] Create";
export const CREATE_HOUSEKEEPING_TASKS_SUCCESS = "[HOUSEKEEPING_TASKS] Create Success";
export const CREATE_HOUSEKEEPING_TASKS_FAIL = "[HOUSEKEEPING_TASKS] Create Fail";
export const CREATE_HOUSEKEEPING_TASKS_RESET = "[HOUSEKEEPING_TASKS] Create Reset";

export const UPDATE_HOUSEKEEPING_TASKS = "[HOUSEKEEPING_TASKS] Update";
export const UPDATE_HOUSEKEEPING_TASKS_SUCCESS = "[HOUSEKEEPING_TASKS] Update Success";
export const UPDATE_HOUSEKEEPING_TASKS_FAIL = "[HOUSEKEEPING_TASKS] Update Fail";
export const UPDATE_HOUSEKEEPING_TASKS_RESET = "[HOUSEKEEPING_TASKS] Update Reset";

export const REPLACE_HOUSEKEEPING_TASKS = "[HOUSEKEEPING_TASKS] Replace";
export const REPLACE_HOUSEKEEPING_TASKS_SUCCESS = "[HOUSEKEEPING_TASKS] Replace Success";
export const REPLACE_HOUSEKEEPING_TASKS_FAIL = "[HOUSEKEEPING_TASKS] Replace Fail";
export const REPLACE_HOUSEKEEPING_TASKS_RESET = "[HOUSEKEEPING_TASKS] Replace Reset";

export const REMOVE_HOUSEKEEPING_TASKS = "[HOUSEKEEPING_TASKS] Remove";
export const REMOVE_HOUSEKEEPING_TASKS_SUCCESS = "[HOUSEKEEPING_TASKS] Remove Success";
export const REMOVE_HOUSEKEEPING_TASKS_FAIL = "[HOUSEKEEPING_TASKS] Remove Fail";

export const GET_HOUSEKEEPING_TASKS_2_PAGINATION = "[HOUSEKEEPING_TASKS] Pagination 2 Get";
export const GET_HOUSEKEEPING_TASKS_2_PAGINATION_SUCCESS = "[HOUSEKEEPING_TASKS] Pagination 2 Get Success";
export const GET_HOUSEKEEPING_TASKS_2_PAGINATION_FAIL = "[HOUSEKEEPING_TASKS] Pagination 2 Get Fail";

export const GET_HOUSEKEEPING_TASKS_REPORT = "[GET_HOUSEKEEPING_TASKS_REPORT] Pagination Get";
export const GET_HOUSEKEEPING_TASKS_REPORT_SUCCESS = "[GET_HOUSEKEEPING_TASKS_REPORT] Pagination Get Success";
export const GET_HOUSEKEEPING_TASKS_REPORT_FAIL = "[GET_HOUSEKEEPING_TASKS_REPORT] Pagination Get Fail";
