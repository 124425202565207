<template>
  <v-card>
    <form-header
      :form="form"
      :fullscreen="false"
      tour="company-smart-device-accounts-form"
      :title='$lang("company-smart-device-accounts.title_create")'
    ></form-header>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col cols="12" xs="12" md="12">
            <v-text-field
              v-model="form.name"
              :label="$lang('company-smart-device-accounts.fields.name')"
              :error="!!validation.name"
              :error-messages="!!validation.name ? validation.name : []"
            ></v-text-field>
            <v-list>
              <v-list-item-group v-model="form.provider">
                <template v-for="(provider, p) in $in('ConstCompanySmartDevicesProviders')">
                  <v-list-item
                    :key="p"
                    :input-value="provider.value == form.provider"
                    active-class="blue-grey lighten-4"
                    :value="provider.value"
                  >
                    <v-list-item-avatar :color="providerColor(provider.value)">
                      <v-img contain :src="`/img/smart-devices/${provider.value}.png`" max-width="100px" max-height="25px"/>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{provider.text}}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
            <!-- <v-select
              v-model="form.provider"
              :items="$in('ConstCompanySmartDevicesProviders')"
              :label="$lang('company-smart-device-accounts.fields.provider')"
              :error="!!validation.provider"
              :error-messages="!!validation.provider ? validation.provider : []"
            ></v-select> -->
            <template v-if="form.provider === $const('ConstCompanySmartDevicesProviders', 'OK_HOME')">
              <v-text-field
                v-model="form.ok_home_session.login"
                :label="$lang('company-smart-device-accounts.fields.ok_home_login')"
                :error="!!validation['ok_home_session.login']"
                :error-messages="!!validation['ok_home_session.login'] ? validation['ok_home_session.login'] : []"
              ></v-text-field>
              <v-text-field
                v-model="form.ok_home_session.password"
                :label="$lang('company-smart-device-accounts.fields.ok_home_password')"
                :error="!!validation['ok_home_session.password']"
                :error-messages="!!validation['ok_home_session.password'] ? validation['ok_home_session.password'] : []"
              ></v-text-field>
            </template>
            <template v-if="form.provider === $const('ConstCompanySmartDevicesProviders', 'NUKI')">
              <template v-if="form.nuki_authorization_code">
                <v-text-field
                  v-model="form.nuki_authorization_code"
                  readonly
                  :label="$lang('company-smart-device-accounts.fields.nuki_authorization_code')"
                  :error="!!validation.nuki_authorization_code"
                  :error-messages="!!validation.nuki_authorization_code ? validation.nuki_authorization_code : []"
                ></v-text-field>
              </template>
              <v-btn
                v-else
                color="info"
                block
                @click="getNukiAuthorizationUrl()"
                :loading="nukiAuthorizationUrlRequest.loading"
                :disabled="nukiAuthorizationUrlRequest.loading"
              >
                {{ $lang("company-smart-device-accounts.actions.authorize") }}
              </v-btn>
            </template>
            <template v-if="form.provider === $const('ConstCompanySmartDevicesProviders', 'SWITCH_BOT')">
              <v-text-field
                v-model="form.switch_bot_credentials.token"
                :label="$lang('company-smart-device-accounts.fields.switch_bot_credentials.token')"
                :error="!!validation['switch_bot_credentials.token']"
                :error-messages="!!validation['switch_bot_credentials.token'] ? validation['switch_bot_credentials.token'] : []"
              ></v-text-field>
              <v-text-field
                v-model="form.switch_bot_credentials.secret"
                :label="$lang('company-smart-device-accounts.fields.switch_bot_credentials.secret')"
                :error="!!validation['switch_bot_credentials.secret']"
                :error-messages="!!validation['switch_bot_credentials.secret'] ? validation['switch_bot_credentials.secret'] : []"
              ></v-text-field>
              <v-text-field
                v-model="form.switch_bot_credentials.nonce"
                :label="$lang('company-smart-device-accounts.fields.switch_bot_credentials.nonce')"
                :error="!!validation['switch_bot_credentials.nonce']"
                :error-messages="!!validation['switch_bot_credentials.nonce'] ? validation['switch_bot_credentials.nonce'] : []"
              ></v-text-field>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="submit()" :loading="loading" :disabled="loading || !canSave">{{ $lang("main.crud.create") }}</v-btn>
      <v-btn color="error" @click="cancel()">{{ $lang("main.crud.cancel") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapState } from "vuex";

  import {
    CREATE_COMPANY_SMART_DEVICE_ACCOUNTS_RESET,
    CREATE_COMPANY_SMART_DEVICE_ACCOUNTS,
    GET_NUKI_AUTHORIZATION_URL,
  } from "@/store/company-smart-device-accounts/actions";

  import form from "@/pages/company-smart-device-accounts/mixins/form";

  export default {
    name: "CreationForm",
    mixins: [
      form
    ],
    props: {
      provider: {
        required: false
      },
      companyId: {
        required: false
      },
    },
    beforeMount() {
      if (this.provider) {
        this.form.provider = this.provider;
      }
      if (this.companyId) {
        this.form.company_id = this.companyId;
      }
      if (this.$route.query.nuki_code) {
        this.initNukiAuthorization();
      }
    },
    methods: {
      resetAction() {
        return CREATE_COMPANY_SMART_DEVICE_ACCOUNTS_RESET;
      },
      submitAction() {
        return CREATE_COMPANY_SMART_DEVICE_ACCOUNTS;
      },
      submitActionOptions() {
        return this.form;
      },
      initNukiAuthorization() {
        const queryParams = { ...this.$route.query };

        this.form.nuki_authorization_code = queryParams.nuki_code;

        delete queryParams['nuki_code'];

        this.form = {
          ...this.form,
          ...queryParams
        };

        this.form.provider = parseInt(this.form.provider);
        this.form.company_id = parseInt(this.form.company_id);
      },
      getNukiAuthorizationUrl() {
        this.$store.dispatch(GET_NUKI_AUTHORIZATION_URL, this.form).then(response => {
          window.location.href = response;
        });
      }
    },
    computed: {
      canSave() {
        if (
          this.form.provider === this.$const('ConstCompanySmartDevicesProviders', 'NUKI') &&
          !this.form.nuki_authorization_code
        ) {
          return false;
        }

        return true;
      },
      ...mapState({
        loading: state => state.companySmartDeviceAccounts.creationRequest.loading,
        validation: state => state.companySmartDeviceAccounts.creationRequest.validation,
        nukiAuthorizationUrlRequest: state => state.companySmartDeviceAccounts.getNukiAuthorizationUrlRequest,
      }),
    },
  };
</script>

<style scoped>

</style>

