export const GET_RESOURCE_EVENTS_LIST = "[RESOURCE_EVENTS] List Get";
export const GET_RESOURCE_EVENTS_LIST_SUCCESS = "[RESOURCE_EVENTS] List Get Success";
export const GET_RESOURCE_EVENTS_LIST_FAIL = "[RESOURCE_EVENTS] List Get Fail";

export const GET_RESOURCE_EVENTS_PAGINATION = "[RESOURCE_EVENTS] Pagination Get";
export const GET_RESOURCE_EVENTS_PAGINATION_SUCCESS = "[RESOURCE_EVENTS] Pagination Get Success";
export const GET_RESOURCE_EVENTS_PAGINATION_FAIL = "[RESOURCE_EVENTS] Pagination Get Fail";

export const GET_RESOURCE_EVENTS_COLLECTION = "[RESOURCE_EVENTS] Collection Get";
export const GET_RESOURCE_EVENTS_COLLECTION_SUCCESS = "[RESOURCE_EVENTS] Collection Get Success";
export const GET_RESOURCE_EVENTS_COLLECTION_FAIL = "[RESOURCE_EVENTS] Collection Get Fail";

export const GET_RESOURCE_EVENTS_SINGLE = "[RESOURCE_EVENTS] Single Get";
export const GET_RESOURCE_EVENTS_SINGLE_SUCCESS = "[RESOURCE_EVENTS] Single Get Success";
export const GET_RESOURCE_EVENTS_SINGLE_FAIL = "[RESOURCE_EVENTS] Single Get Fail";

export const CREATE_RESOURCE_EVENTS = "[RESOURCE_EVENTS] Create";
export const CREATE_RESOURCE_EVENTS_SUCCESS = "[RESOURCE_EVENTS] Create Success";
export const CREATE_RESOURCE_EVENTS_FAIL = "[RESOURCE_EVENTS] Create Fail";
export const CREATE_RESOURCE_EVENTS_RESET = "[RESOURCE_EVENTS] Create Reset";

export const UPDATE_RESOURCE_EVENTS = "[RESOURCE_EVENTS] Update";
export const UPDATE_RESOURCE_EVENTS_SUCCESS = "[RESOURCE_EVENTS] Update Success";
export const UPDATE_RESOURCE_EVENTS_FAIL = "[RESOURCE_EVENTS] Update Fail";
export const UPDATE_RESOURCE_EVENTS_RESET = "[RESOURCE_EVENTS] Update Reset";

export const REPLACE_RESOURCE_EVENTS = "[RESOURCE_EVENTS] Replace";
export const REPLACE_RESOURCE_EVENTS_SUCCESS = "[RESOURCE_EVENTS] Replace Success";
export const REPLACE_RESOURCE_EVENTS_FAIL = "[RESOURCE_EVENTS] Replace Fail";
export const REPLACE_RESOURCE_EVENTS_RESET = "[RESOURCE_EVENTS] Replace Reset";

export const REMOVE_RESOURCE_EVENTS = "[RESOURCE_EVENTS] Remove";
export const REMOVE_RESOURCE_EVENTS_SUCCESS = "[RESOURCE_EVENTS] Remove Success";
export const REMOVE_RESOURCE_EVENTS_FAIL = "[RESOURCE_EVENTS] Remove Fail";

export const CALCULATE_RESOURCE_EVENT_AMOUNT = "[RESOURCE_EVENTS] Calculate Amount";
export const CALCULATE_RESOURCE_EVENT_AMOUNT_SUCCESS = "[RESOURCE_EVENTS] Calculate Amount Success";
export const CALCULATE_RESOURCE_EVENT_AMOUNT_FAIL = "[RESOURCE_EVENTS] Calculate Amount Fail";