export const GET_RESERVATION_SOURCES_LIST = "[RESERVATION_SOURCES] List Get";
export const GET_RESERVATION_SOURCES_LIST_SUCCESS = "[RESERVATION_SOURCES] List Get Success";
export const GET_RESERVATION_SOURCES_LIST_FAIL = "[RESERVATION_SOURCES] List Get Fail";

export const GET_RESERVATION_SOURCES_PAGINATION = "[RESERVATION_SOURCES] Pagination Get";
export const GET_RESERVATION_SOURCES_PAGINATION_SUCCESS = "[RESERVATION_SOURCES] Pagination Get Success";
export const GET_RESERVATION_SOURCES_PAGINATION_FAIL = "[RESERVATION_SOURCES] Pagination Get Fail";

export const GET_RESERVATION_SOURCES_COLLECTION = "[RESERVATION_SOURCES] Collection Get";
export const GET_RESERVATION_SOURCES_COLLECTION_SUCCESS = "[RESERVATION_SOURCES] Collection Get Success";
export const GET_RESERVATION_SOURCES_COLLECTION_FAIL = "[RESERVATION_SOURCES] Collection Get Fail";

export const GET_RESERVATION_SOURCES_SINGLE = "[RESERVATION_SOURCES] Single Get";
export const GET_RESERVATION_SOURCES_SINGLE_SUCCESS = "[RESERVATION_SOURCES] Single Get Success";
export const GET_RESERVATION_SOURCES_SINGLE_FAIL = "[RESERVATION_SOURCES] Single Get Fail";

export const CREATE_RESERVATION_SOURCES = "[RESERVATION_SOURCES] Create";
export const CREATE_RESERVATION_SOURCES_SUCCESS = "[RESERVATION_SOURCES] Create Success";
export const CREATE_RESERVATION_SOURCES_FAIL = "[RESERVATION_SOURCES] Create Fail";
export const CREATE_RESERVATION_SOURCES_RESET = "[RESERVATION_SOURCES] Create Reset";

export const UPDATE_RESERVATION_SOURCES = "[RESERVATION_SOURCES] Update";
export const UPDATE_RESERVATION_SOURCES_SUCCESS = "[RESERVATION_SOURCES] Update Success";
export const UPDATE_RESERVATION_SOURCES_FAIL = "[RESERVATION_SOURCES] Update Fail";
export const UPDATE_RESERVATION_SOURCES_RESET = "[RESERVATION_SOURCES] Update Reset";

export const REPLACE_RESERVATION_SOURCES = "[RESERVATION_SOURCES] Replace";
export const REPLACE_RESERVATION_SOURCES_SUCCESS = "[RESERVATION_SOURCES] Replace Success";
export const REPLACE_RESERVATION_SOURCES_FAIL = "[RESERVATION_SOURCES] Replace Fail";
export const REPLACE_RESERVATION_SOURCES_RESET = "[RESERVATION_SOURCES] Replace Reset";

export const REMOVE_RESERVATION_SOURCES = "[RESERVATION_SOURCES] Remove";
export const REMOVE_RESERVATION_SOURCES_SUCCESS = "[RESERVATION_SOURCES] Remove Success";
export const REMOVE_RESERVATION_SOURCES_FAIL = "[RESERVATION_SOURCES] Remove Fail";
