export const GET_PROPERTY_OWNERS_LIST = "[PROPERTY_OWNERS] List Get";
export const GET_PROPERTY_OWNERS_LIST_SUCCESS = "[PROPERTY_OWNERS] List Get Success";
export const GET_PROPERTY_OWNERS_LIST_FAIL = "[PROPERTY_OWNERS] List Get Fail";

export const GET_PROPERTY_OWNERS_PAGINATION = "[PROPERTY_OWNERS] Pagination Get";
export const GET_PROPERTY_OWNERS_PAGINATION_SUCCESS = "[PROPERTY_OWNERS] Pagination Get Success";
export const GET_PROPERTY_OWNERS_PAGINATION_FAIL = "[PROPERTY_OWNERS] Pagination Get Fail";

export const GET_PROPERTY_OWNERS_COLLECTION = "[PROPERTY_OWNERS] Collection Get";
export const GET_PROPERTY_OWNERS_COLLECTION_SUCCESS = "[PROPERTY_OWNERS] Collection Get Success";
export const GET_PROPERTY_OWNERS_COLLECTION_FAIL = "[PROPERTY_OWNERS] Collection Get Fail";

export const GET_PROPERTY_OWNERS_SINGLE = "[PROPERTY_OWNERS] Single Get";
export const GET_PROPERTY_OWNERS_SINGLE_SUCCESS = "[PROPERTY_OWNERS] Single Get Success";
export const GET_PROPERTY_OWNERS_SINGLE_FAIL = "[PROPERTY_OWNERS] Single Get Fail";

export const CREATE_PROPERTY_OWNERS = "[PROPERTY_OWNERS] Create";
export const CREATE_PROPERTY_OWNERS_SUCCESS = "[PROPERTY_OWNERS] Create Success";
export const CREATE_PROPERTY_OWNERS_FAIL = "[PROPERTY_OWNERS] Create Fail";
export const CREATE_PROPERTY_OWNERS_RESET = "[PROPERTY_OWNERS] Create Reset";

export const UPDATE_PROPERTY_OWNERS = "[PROPERTY_OWNERS] Update";
export const UPDATE_PROPERTY_OWNERS_SUCCESS = "[PROPERTY_OWNERS] Update Success";
export const UPDATE_PROPERTY_OWNERS_FAIL = "[PROPERTY_OWNERS] Update Fail";
export const UPDATE_PROPERTY_OWNERS_RESET = "[PROPERTY_OWNERS] Update Reset";

export const REMOVE_PROPERTY_OWNERS = "[PROPERTY_OWNERS] Remove";
export const REMOVE_PROPERTY_OWNERS_SUCCESS = "[PROPERTY_OWNERS] Remove Success";
export const REMOVE_PROPERTY_OWNERS_FAIL = "[PROPERTY_OWNERS] Remove Fail";
