export const GET_EVENTS_LIST = "[EVENTS] List Get";
export const GET_EVENTS_LIST_SUCCESS = "[EVENTS] List Get Success";
export const GET_EVENTS_LIST_FAIL = "[EVENTS] List Get Fail";

export const GET_EVENTS_PAGINATION = "[EVENTS] Pagination Get";
export const GET_EVENTS_PAGINATION_SUCCESS = "[EVENTS] Pagination Get Success";
export const GET_EVENTS_PAGINATION_FAIL = "[EVENTS] Pagination Get Fail";

export const GET_EVENTS_COLLECTION = "[EVENTS] Collection Get";
export const GET_EVENTS_COLLECTION_SUCCESS = "[EVENTS] Collection Get Success";
export const GET_EVENTS_COLLECTION_FAIL = "[EVENTS] Collection Get Fail";

export const GET_EVENTS_SINGLE = "[EVENTS] Single Get";
export const GET_EVENTS_SINGLE_SUCCESS = "[EVENTS] Single Get Success";
export const GET_EVENTS_SINGLE_FAIL = "[EVENTS] Single Get Fail";

export const CREATE_EVENTS = "[EVENTS] Create";
export const CREATE_EVENTS_SUCCESS = "[EVENTS] Create Success";
export const CREATE_EVENTS_FAIL = "[EVENTS] Create Fail";
export const CREATE_EVENTS_RESET = "[EVENTS] Create Reset";

export const UPDATE_EVENTS = "[EVENTS] Update";
export const UPDATE_EVENTS_SUCCESS = "[EVENTS] Update Success";
export const UPDATE_EVENTS_FAIL = "[EVENTS] Update Fail";
export const UPDATE_EVENTS_RESET = "[EVENTS] Update Reset";

export const REPLACE_EVENTS = "[EVENTS] Replace";
export const REPLACE_EVENTS_SUCCESS = "[EVENTS] Replace Success";
export const REPLACE_EVENTS_FAIL = "[EVENTS] Replace Fail";
export const REPLACE_EVENTS_RESET = "[EVENTS] Replace Reset";

export const REMOVE_EVENTS = "[EVENTS] Remove";
export const REMOVE_EVENTS_SUCCESS = "[EVENTS] Remove Success";
export const REMOVE_EVENTS_FAIL = "[EVENTS] Remove Fail";