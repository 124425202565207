export const GET_PROPERTY_MESSAGE_TEMPLATES_PAGINATION = "[PROPERTY_MESSAGE_TEMPLATES] Pagination Get";
export const GET_PROPERTY_MESSAGE_TEMPLATES_PAGINATION_SUCCESS = "[PROPERTY_MESSAGE_TEMPLATES] Pagination Get Success";
export const GET_PROPERTY_MESSAGE_TEMPLATES_PAGINATION_FAIL = "[PROPERTY_MESSAGE_TEMPLATES] Pagination Get Fail";

export const GET_PROPERTY_MESSAGE_TEMPLATES_COLLECTION = "[PROPERTY_MESSAGE_TEMPLATES] Collection Get";
export const GET_PROPERTY_MESSAGE_TEMPLATES_COLLECTION_SUCCESS = "[PROPERTY_MESSAGE_TEMPLATES] Collection Get Success";
export const GET_PROPERTY_MESSAGE_TEMPLATES_COLLECTION_FAIL = "[PROPERTY_MESSAGE_TEMPLATES] Collection Get Fail";

export const GET_PROPERTY_MESSAGE_TEMPLATES_SINGLE = "[PROPERTY_MESSAGE_TEMPLATES] Single Get";
export const GET_PROPERTY_MESSAGE_TEMPLATES_SINGLE_SUCCESS = "[PROPERTY_MESSAGE_TEMPLATES] Single Get Success";
export const GET_PROPERTY_MESSAGE_TEMPLATES_SINGLE_FAIL = "[PROPERTY_MESSAGE_TEMPLATES] Single Get Fail";

export const CREATE_PROPERTY_MESSAGE_TEMPLATES = "[PROPERTY_MESSAGE_TEMPLATES] Create";
export const CREATE_PROPERTY_MESSAGE_TEMPLATES_SUCCESS = "[PROPERTY_MESSAGE_TEMPLATES] Create Success";
export const CREATE_PROPERTY_MESSAGE_TEMPLATES_FAIL = "[PROPERTY_MESSAGE_TEMPLATES] Create Fail";
export const CREATE_PROPERTY_MESSAGE_TEMPLATES_RESET = "[PROPERTY_MESSAGE_TEMPLATES] Create Reset";

export const UPDATE_PROPERTY_MESSAGE_TEMPLATES = "[PROPERTY_MESSAGE_TEMPLATES] Update";
export const UPDATE_PROPERTY_MESSAGE_TEMPLATES_SUCCESS = "[PROPERTY_MESSAGE_TEMPLATES] Update Success";
export const UPDATE_PROPERTY_MESSAGE_TEMPLATES_FAIL = "[PROPERTY_MESSAGE_TEMPLATES] Update Fail";
export const UPDATE_PROPERTY_MESSAGE_TEMPLATES_RESET = "[PROPERTY_MESSAGE_TEMPLATES] Update Reset";

export const DUPLICATE_PROPERTY_MESSAGE_TEMPLATES = "[PROPERTY_MESSAGE_TEMPLATES] Duplicate";
export const DUPLICATE_PROPERTY_MESSAGE_TEMPLATES_SUCCESS = "[PROPERTY_MESSAGE_TEMPLATES] Duplicate Success";
export const DUPLICATE_PROPERTY_MESSAGE_TEMPLATES_FAIL = "[PROPERTY_MESSAGE_TEMPLATES] Duplicate Fail";
export const DUPLICATE_PROPERTY_MESSAGE_TEMPLATES_RESET = "[PROPERTY_MESSAGE_TEMPLATES] Duplicate Reset";

export const REMOVE_PROPERTY_MESSAGE_TEMPLATES = "[PROPERTY_MESSAGE_TEMPLATES] Remove";
export const REMOVE_PROPERTY_MESSAGE_TEMPLATES_SUCCESS = "[PROPERTY_MESSAGE_TEMPLATES] Remove Success";
export const REMOVE_PROPERTY_MESSAGE_TEMPLATES_FAIL = "[PROPERTY_MESSAGE_TEMPLATES] Remove Fail";

export const GET_PROPERTY_MESSAGE_TEMPLATES_VARIABLES = "[PROPERTY_MESSAGE_TEMPLATES] Variables Get";
export const GET_PROPERTY_MESSAGE_TEMPLATES_VARIABLES_SUCCESS = "[PROPERTY_MESSAGE_TEMPLATES] Variables Get Success";
export const GET_PROPERTY_MESSAGE_TEMPLATES_VARIABLES_FAIL = "[PROPERTY_MESSAGE_TEMPLATES] Variables Get Fail";

export const GET_PROPERTY_MESSAGE_TEMPLATES_EMAIL_PRESETS = "[PROPERTY_MESSAGE_TEMPLATES] Email Presets Get";
export const GET_PROPERTY_MESSAGE_TEMPLATES_EMAIL_PRESETS_SUCCESS = "[PROPERTY_MESSAGE_TEMPLATES] Email Presets Get Success";
export const GET_PROPERTY_MESSAGE_TEMPLATES_EMAIL_PRESETS_FAIL = "[PROPERTY_MESSAGE_TEMPLATES] Email Presets Get Fail";

export const PROPERTY_MESSAGE_TEMPLATES_CLONE = "[PROPERTY_MESSAGE_TEMPLATES] Clone";
export const PROPERTY_MESSAGE_TEMPLATES_CLONE_SUCCESS = "[PROPERTY_MESSAGE_TEMPLATES] Clone Success";
export const PROPERTY_MESSAGE_TEMPLATES_CLONE_FAIL = "[PROPERTY_MESSAGE_TEMPLATES] Clone Fail";

export const CREATE_PROPERTY_DEFAULT_MESSAGE_TEMPLATES = "[PROPERTY_MESSAGE_TEMPLATES] Create Defaults";
export const CREATE_PROPERTY_DEFAULT_MESSAGE_TEMPLATES_SUCCESS = "[PROPERTY_MESSAGE_TEMPLATES] Create Defaults Success";
export const CREATE_PROPERTY_DEFAULT_MESSAGE_TEMPLATES_FAIL = "[PROPERTY_MESSAGE_TEMPLATES] Create Defaults Fail";

export const REFRESH_PROPERTY_RESERVATIONS_MESSAGES = "[PROPERTY_MESSAGE_TEMPLATES] Refresh Messages";
export const REFRESH_PROPERTY_RESERVATIONS_MESSAGES_SUCCESS = "[PROPERTY_MESSAGE_TEMPLATES] Refresh Messages Success";
export const REFRESH_PROPERTY_RESERVATIONS_MESSAGES_FAIL = "[PROPERTY_MESSAGE_TEMPLATES] Refresh Messages Fail";

export const CREATE_BULK_PROPERTY_MESSAGE_TEMPLATES = "[PROPERTY_MESSAGE_TEMPLATES] Create Bulk";
export const CREATE_BULK_PROPERTY_MESSAGE_TEMPLATES_SUCCESS = "[PROPERTY_MESSAGE_TEMPLATES] Create Bulk Success";
export const CREATE_BULK_PROPERTY_MESSAGE_TEMPLATES_FAIL = "[PROPERTY_MESSAGE_TEMPLATES] Create Bulk Fail";
export const CREATE_BULK_PROPERTY_MESSAGE_TEMPLATES_RESET = "[PROPERTY_MESSAGE_TEMPLATES] Create Bulk Reset";
