import crud from "@/store/core/crud";

import {
  GET_BOOKING_NETWORK_PROPERTIES_LIST,
  GET_BOOKING_NETWORK_PROPERTIES_LIST_SUCCESS,
  GET_BOOKING_NETWORK_PROPERTIES_LIST_FAIL,
  GET_BOOKING_NETWORK_PROPERTIES_PAGINATION,
  GET_BOOKING_NETWORK_PROPERTIES_PAGINATION_SUCCESS,
  GET_BOOKING_NETWORK_PROPERTIES_PAGINATION_FAIL,
  GET_BOOKING_NETWORK_PROPERTIES_COLLECTION,
  GET_BOOKING_NETWORK_PROPERTIES_COLLECTION_SUCCESS,
  GET_BOOKING_NETWORK_PROPERTIES_COLLECTION_FAIL,
  GET_BOOKING_NETWORK_PROPERTIES_SINGLE,
  GET_BOOKING_NETWORK_PROPERTIES_SINGLE_FAIL,
  GET_BOOKING_NETWORK_PROPERTIES_SINGLE_SUCCESS,
  CREATE_BOOKING_NETWORK_PROPERTIES,
  CREATE_BOOKING_NETWORK_PROPERTIES_RESET,
  CREATE_BOOKING_NETWORK_PROPERTIES_FAIL,
  CREATE_BOOKING_NETWORK_PROPERTIES_SUCCESS,
  REMOVE_BOOKING_NETWORK_PROPERTIES,
  REMOVE_BOOKING_NETWORK_PROPERTIES_FAIL,
  REMOVE_BOOKING_NETWORK_PROPERTIES_SUCCESS,
  UPDATE_BOOKING_NETWORK_PROPERTIES,
  UPDATE_BOOKING_NETWORK_PROPERTIES_FAIL,
  UPDATE_BOOKING_NETWORK_PROPERTIES_RESET,
  UPDATE_BOOKING_NETWORK_PROPERTIES_SUCCESS,
} from "./actions";
import {
  GET_BOOKING_NETWORKS_COLLECTION, GET_BOOKING_NETWORKS_COLLECTION_FAIL, GET_BOOKING_NETWORKS_COLLECTION_SUCCESS,
  GET_BOOKING_NETWORKS_PAGINATION,
  GET_BOOKING_NETWORKS_PAGINATION_FAIL,
  GET_BOOKING_NETWORKS_PAGINATION_SUCCESS
} from "../booking-networks/actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_BOOKING_NETWORK_PROPERTIES_PAGINATION,
    endPoint: "booking-network-properties/paginated",
    mutationFail: GET_BOOKING_NETWORK_PROPERTIES_PAGINATION_FAIL,
    mutationSuccess: GET_BOOKING_NETWORK_PROPERTIES_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_BOOKING_NETWORK_PROPERTIES_COLLECTION,
    endPoint: "booking-network-properties/collected",
    mutationFail: GET_BOOKING_NETWORK_PROPERTIES_COLLECTION_FAIL,
    mutationSuccess: GET_BOOKING_NETWORK_PROPERTIES_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_BOOKING_NETWORK_PROPERTIES_SINGLE,
    endPoint: "booking-network-properties/single",
    mutationFail: GET_BOOKING_NETWORK_PROPERTIES_SINGLE_FAIL,
    mutationSuccess: GET_BOOKING_NETWORK_PROPERTIES_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_BOOKING_NETWORK_PROPERTIES,
    actionReset: CREATE_BOOKING_NETWORK_PROPERTIES_RESET,
    endPoint: "booking-network-properties/create",
    mutationFail: CREATE_BOOKING_NETWORK_PROPERTIES_FAIL,
    mutationSuccess: CREATE_BOOKING_NETWORK_PROPERTIES_SUCCESS
  },
  editingRequest: {
    action: UPDATE_BOOKING_NETWORK_PROPERTIES,
    actionReset: UPDATE_BOOKING_NETWORK_PROPERTIES_RESET,
    endPoint: "booking-network-properties/update",
    mutationFail: UPDATE_BOOKING_NETWORK_PROPERTIES_FAIL,
    mutationSuccess: UPDATE_BOOKING_NETWORK_PROPERTIES_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_BOOKING_NETWORK_PROPERTIES,
    endPoint: "booking-network-properties/delete",
    mutationFail: REMOVE_BOOKING_NETWORK_PROPERTIES_FAIL,
    mutationSuccess: REMOVE_BOOKING_NETWORK_PROPERTIES_SUCCESS
  }
});

const actions = {
  ...crudProcessed.actions,
};

const getters = {
};

const state = {
  ...crudProcessed.state,
};

const mutations = {
  ...crudProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
