export default [
  {
    name: "activity-logs",
    path: "/activity-logs",
    component: () => import(/*webpackChunkName: "activity-logs" */ "@/pages/activity-logs/Index"),
    meta: {
      title: 'titles.activity_logs'
    }
  },
];
