import Vue from "vue";
import CountryFlag from 'vue-country-flag';
import LangFlag from 'vue-lang-code-flags';
import CAgenciesAutocomplete from '@/components/widgets/CAgenciesAutocomplete';
import CCrudFilters from '@/components/widgets/CCrudFilters';
import CLanguagesAutocomplete from '@/components/widgets/CLanguagesAutocomplete';
import CPropertiesAutocomplete from '@/components/widgets/CPropertiesAutocomplete';
import CPropertyClustersAutocomplete from '@/components/widgets/CPropertyClustersAutocomplete';
import CCompaniesAutocomplete from '@/components/widgets/CCompaniesAutocomplete';
import CCompanyLocationsSelect from '@/components/widgets/CCompanyLocationsSelect';
import CCompanyJournalsSelect from '@/components/widgets/CCompanyJournalsSelect';
import CCountriesAutocomplete from '@/components/widgets/CCountriesAutocomplete';
import CCurrenciesAutocomplete from '@/components/widgets/CCurrenciesAutocomplete';
import CTimezonesAutocomplete from '@/components/widgets/CTimezonesAutocomplete';
import CUsersAutocomplete from '@/components/widgets/CUsersAutocomplete';
import CUnitsAutocomplete from '@/components/widgets/CUnitsAutocomplete';
import CUnitCategoriesAutocomplete from '@/components/widgets/CUnitCategoriesAutocomplete';
import CBillsSearch from '@/components/widgets/CBillsSearch';
import CDocumentsSearch from '@/components/widgets/CDocumentsSearch';
import CPaymentSourcesAutocomplete from '@/components/widgets/CPaymentSourcesAutocomplete';
import CReservationsSearch from '@/components/widgets/CReservationsSearch';
import CRoomTypesAutocomplete from '@/components/widgets/CRoomTypesAutocomplete';
import CResourcesAutocomplete from '@/components/widgets/CResourcesAutocomplete';
import CExtrasAutocomplete from '@/components/widgets/CExtrasAutocomplete';
import CWarehousesAutocomplete from '@/components/widgets/CWarehousesAutocomplete';
import CProductCategoriesAutocomplete from '@/components/widgets/CProductCategoriesAutocomplete';
import CProductsAutocomplete from '@/components/widgets/CProductsAutocomplete';
import CPointOfSalesAutocompleteAutocomplete from '@/components/widgets/CPointOfSalesAutocomplete';
import CSmartDeviceAccountsAutocomplete from '@/components/widgets/CSmartDeviceAccountsAutocomplete';
import CRefundableMenu from '@/components/widgets/CRefundableMenu';
import CMealPlanMenu from '@/components/widgets/CMealPlanMenu';
import CReservationSources from '@/components/widgets/CReservationSources';
import COctorateAccommodations from '@/components/widgets/COctorateAccommodations';
import CVariablesMenu from '@/components/widgets/CVariablesMenu';
import CWhatsappMenu from '@/components/widgets/CWhatsappMenu';
import CPropertyOwnersAutocomplete from '@/components/widgets/CPropertyOwnersAutocomplete';
import CVatOptions from '@/components/widgets/CVatOptions.vue';
import CMfaRequired from '@/components/widgets/CMfaRequired.vue';
import VDate from '@/components/widgets/VDate';
import VClock from '@/components/widgets/VClock';
import VDateTime from '@/components/widgets/VDateTime';
import VBoolean from '@/components/widgets/VBoolean';
import VLocale from '@/components/widgets/VLocale';
import VConfirmation from '@/components/widgets/VConfirmation';
import VAddressAutocomplete from '@/components/widgets/VAddressAutocomplete';
import VListElements from '@/components/widgets/VListElements';
import VColorPickerButton from '@/components/widgets/VColorPickerButton.vue';
import VChannel from '@/components/widgets/VChannel.vue';
import FormHeader from '@/components/layouts/partials/FormHeader';
import TipTap from '@/components/widgets/tip-tap/TipTap';

const ComponentsService = {
  /**
   *
   */
  init() {
    this.initFlags();
    this.initCrudFilters();
    this.initFormHeader();
    this.initTipTap();
  },

  /**
   *
   */
  initFlags() {
    Vue.component('country-flag', CountryFlag);
    Vue.component('lang-flag', LangFlag);
  },

  /**
   *
   */
  initCrudFilters() {
    Vue.component('c-agencies-autocomplete', CAgenciesAutocomplete);
    Vue.component('c-crud-filters', CCrudFilters);
    Vue.component('c-languages-autocomplete', CLanguagesAutocomplete);
    Vue.component('c-properties-autocomplete', CPropertiesAutocomplete);
    Vue.component('c-property-clusters-autocomplete', CPropertyClustersAutocomplete);
    Vue.component('c-companies-autocomplete', CCompaniesAutocomplete);
    Vue.component('c-company-locations-select', CCompanyLocationsSelect);
    Vue.component('c-company-journals-select', CCompanyJournalsSelect);
    Vue.component('c-countries-autocomplete', CCountriesAutocomplete);
    Vue.component('c-currencies-autocomplete', CCurrenciesAutocomplete);
    Vue.component('c-timezone-autocomplete', CTimezonesAutocomplete);
    Vue.component('c-users-autocomplete', CUsersAutocomplete);
    Vue.component('c-units-autocomplete', CUnitsAutocomplete);
    Vue.component('c-unit-categories-autocomplete', CUnitCategoriesAutocomplete);
    Vue.component('c-payment-sources-autocomplete', CPaymentSourcesAutocomplete);
    Vue.component('c-reservations-autocomplete', CReservationsSearch);
    Vue.component('c-roomtypes-autocomplete', CRoomTypesAutocomplete);
    Vue.component('c-resources-autocomplete', CResourcesAutocomplete);
    Vue.component('c-extras-autocomplete', CExtrasAutocomplete);
    Vue.component('c-warehouses-autocomplete', CWarehousesAutocomplete);
    Vue.component('c-product-categories-autocomplete', CProductCategoriesAutocomplete);
    Vue.component('c-products-autocomplete', CProductsAutocomplete);
    Vue.component('c-point-of-sales-autocomplete', CPointOfSalesAutocompleteAutocomplete);
    Vue.component('c-smart-device-accounts-autocomplete', CSmartDeviceAccountsAutocomplete);
    Vue.component('c-bills-search', CBillsSearch);
    Vue.component('c-documents-search', CDocumentsSearch);
    Vue.component('c-reservations-search', CReservationsSearch);
    Vue.component('c-refundable-menu', CRefundableMenu);
    Vue.component('c-meal-plan-menu', CMealPlanMenu);
    Vue.component('c-reservation-sources', CReservationSources);
    Vue.component('c-octorate-accommodations', COctorateAccommodations);
    Vue.component('c-variables-menu', CVariablesMenu);
    Vue.component('c-whatsapp-menu', CWhatsappMenu);
    Vue.component('c-property-owners-autocomplete', CPropertyOwnersAutocomplete);
    Vue.component('c-vat-options', CVatOptions);
    Vue.component('c-mfa-required', CMfaRequired);
    Vue.component('v-date', VDate);
    Vue.component('v-clock', VClock);
    Vue.component('v-date-time', VDateTime);
    Vue.component('v-boolean', VBoolean);
    Vue.component('v-locale', VLocale);
    Vue.component('v-confirmation', VConfirmation);
    Vue.component('v-address-autocomplete', VAddressAutocomplete);
    Vue.component('v-list-elements', VListElements);
    Vue.component('v-color-picker-button', VColorPickerButton);
    Vue.component('v-channel', VChannel);
  },

  /**
   *
   */
  initFormHeader() {
    Vue.component('form-header', FormHeader);
  },

  /**
   * 
   */
  initTipTap() {
    Vue.component('tip-tap', TipTap);
  },
};

export default ComponentsService;
