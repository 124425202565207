import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_PAYMENTS_PAGINATION,
  GET_PAYMENTS_PAGINATION_SUCCESS,
  GET_PAYMENTS_PAGINATION_FAIL,
  GET_PAYMENTS_COLLECTION,
  GET_PAYMENTS_COLLECTION_SUCCESS,
  GET_PAYMENTS_COLLECTION_FAIL,
  GET_PAYMENTS_SINGLE,
  GET_PAYMENTS_SINGLE_SUCCESS,
  GET_PAYMENTS_SINGLE_FAIL,
  CREATE_PAYMENTS,
  CREATE_PAYMENTS_SUCCESS,
  CREATE_PAYMENTS_FAIL,
  CREATE_PAYMENTS_RESET,
  UPDATE_PAYMENTS,
  UPDATE_PAYMENTS_SUCCESS,
  UPDATE_PAYMENTS_FAIL,
  UPDATE_PAYMENTS_RESET,
  REMOVE_PAYMENTS,
  REMOVE_PAYMENTS_SUCCESS,
  REMOVE_PAYMENTS_FAIL,
  GET_PAYMENTS_CASHFLOW,
  GET_PAYMENTS_CASHFLOW_SUCCESS,
  GET_PAYMENTS_CASHFLOW_FAIL,
  GET_PAYMENTS_CASHFLOW_RESET,
  CONFIRM_PREAUTHORIZED_PAYMENT,
  CONFIRM_PREAUTHORIZED_PAYMENT_SUCCESS,
  CONFIRM_PREAUTHORIZED_PAYMENT_FAIL,
  GET_PAYMENTS_STRIPE_TERMINAL_DATA,
  GET_PAYMENTS_STRIPE_TERMINAL_DATA_SUCCESS,
  GET_PAYMENTS_STRIPE_TERMINAL_DATA_FAIL,
  CREATE_PAYMENTS_STRIPE_PAYMENT_INTENT,
  CREATE_PAYMENTS_STRIPE_PAYMENT_INTENT_SUCCESS,
  CREATE_PAYMENTS_STRIPE_PAYMENT_INTENT_FAIL,
  CREATE_PAYMENTS_STRIPE_PAYMENT_INTENT_RESET,
  CAPTURE_PAYMENTS_STRIPE_PAYMENT_INTENT,
  CAPTURE_PAYMENTS_STRIPE_PAYMENT_INTENT_SUCCESS,
  CAPTURE_PAYMENTS_STRIPE_PAYMENT_INTENT_FAIL,
  CAPTURE_PAYMENTS_STRIPE_PAYMENT_INTENT_RESET,
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_PAYMENTS_PAGINATION,
    endPoint: "payments/paginated",
    mutationFail: GET_PAYMENTS_PAGINATION_FAIL,
    mutationSuccess: GET_PAYMENTS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_PAYMENTS_COLLECTION,
    endPoint: "payments/collected",
    mutationFail: GET_PAYMENTS_COLLECTION_FAIL,
    mutationSuccess: GET_PAYMENTS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_PAYMENTS_SINGLE,
    endPoint: "payments/single",
    mutationFail: GET_PAYMENTS_SINGLE_FAIL,
    mutationSuccess: GET_PAYMENTS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_PAYMENTS,
    actionReset: CREATE_PAYMENTS_RESET,
    endPoint: "payments/create",
    mutationFail: CREATE_PAYMENTS_FAIL,
    mutationSuccess: CREATE_PAYMENTS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_PAYMENTS,
    actionReset: UPDATE_PAYMENTS_RESET,
    endPoint: "payments/update",
    mutationFail: UPDATE_PAYMENTS_FAIL,
    mutationSuccess: UPDATE_PAYMENTS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_PAYMENTS,
    endPoint: "payments/delete",
    mutationFail: REMOVE_PAYMENTS_FAIL,
    mutationSuccess: REMOVE_PAYMENTS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    name: 'getCashFlowRequest',
    action: GET_PAYMENTS_CASHFLOW,
    actionReset: GET_PAYMENTS_CASHFLOW_RESET,
    endPoint: 'payments/cashflow',
    mutations: {
      fail: GET_PAYMENTS_CASHFLOW_FAIL,
      success: GET_PAYMENTS_CASHFLOW_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'preauthorizePaymentRequest',
    action: CONFIRM_PREAUTHORIZED_PAYMENT,
    endPoint: 'payments/confirm-preauthorization',
    mutations: {
      fail: CONFIRM_PREAUTHORIZED_PAYMENT_FAIL,
      success: CONFIRM_PREAUTHORIZED_PAYMENT_SUCCESS,
    },
  },
  {
    name: 'getStripeTerminalDataRequest',
    action: GET_PAYMENTS_STRIPE_TERMINAL_DATA,
    endPoint: 'payments/stripe/terminal-data',
    mutations: {
      fail: GET_PAYMENTS_STRIPE_TERMINAL_DATA_FAIL,
      success: GET_PAYMENTS_STRIPE_TERMINAL_DATA_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'createStripePaymentIntentRequest',
    action: CREATE_PAYMENTS_STRIPE_PAYMENT_INTENT,
    actionReset: CREATE_PAYMENTS_STRIPE_PAYMENT_INTENT_RESET,
    endPoint: 'payments/stripe/create-intent',
    mutations: {
      fail: CREATE_PAYMENTS_STRIPE_PAYMENT_INTENT_FAIL,
      success: CREATE_PAYMENTS_STRIPE_PAYMENT_INTENT_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'captureStripePaymentIntentRequest',
    action: CAPTURE_PAYMENTS_STRIPE_PAYMENT_INTENT,
    actionReset: CAPTURE_PAYMENTS_STRIPE_PAYMENT_INTENT_RESET,
    endPoint: 'payments/stripe/capture-intent',
    mutations: {
      fail: CAPTURE_PAYMENTS_STRIPE_PAYMENT_INTENT_FAIL,
      success: CAPTURE_PAYMENTS_STRIPE_PAYMENT_INTENT_SUCCESS,
    },
  },
]);

const actions = {
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const getters = {};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
