<template>
  <span>
    <v-autocomplete
      v-model="ids"
      item-value="value"
      item-text="text"
      :items="items"
      :label="label"
      :small-chips="smallChips"
      :hint="hint"
      :persistent-hint="persistentHint"
      :loading="loading"
      :clearable="clearable"
      :readonly="readonly"
      :dark="dark"
      :dense="dense"
      @click:append-outer="getTimezone()"
      :error="error"
      :error-messages="!!errorMessages ? errorMessages : []"
    >
    </v-autocomplete>
  </span>
</template>

<script>
import { mapState } from "vuex";
import {
  DATA_TIMEZONES
} from "@/store/data/actions";

export default {
  name: "CTimezonesAutocomplete",
  props: {
    label: {
      type: String,
      required: false,
      default: "Timezones"
    },
    hint: {
      type: String,
      default: ""
    },
    persistentHint: {
      type: Boolean,
      default: false
    },
    value: {
      default: null
    },
    error: {
      default: false
    },
    errorMessages: {
      default: () => []
    },
    dense: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    smallChips: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      ids: null,
    };
  },
  beforeMount() {
    this.getTimezone();
  },
  mounted() {
    this.ids = this.value;
  },
  methods: {
    getTimezone() {
      this.$store.dispatch(DATA_TIMEZONES, {
        sortBy: 'name',
        order: 'asc',
      });
    },
    change() {
      this.$emit("change");
    },
    blur() {
      this.$emit("blur");
    },
  },
  computed: {
    ...mapState({
      listRequest: (state) => state.data.dataTimezonesRequest
    }),
    items() {
      if (!this.listRequest.data) {
        return [];
      }
      return this.listRequest.data;
    },
    loading() {
      return this.listRequest.loading;
    },
    isListCached() {
      return this.listRequest.success;
    },
  },
  watch: {
    ids(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.ids = newVal;
    },
  }
};
</script>
