export const GET_TOURS_PAGINATION = "[TOURS] Pagination Get";
export const GET_TOURS_PAGINATION_SUCCESS = "[TOURS] Pagination Get Success";
export const GET_TOURS_PAGINATION_FAIL = "[TOURS] Pagination Get Fail";

export const GET_TOURS_COLLECTION = "[TOURS] Collection Get";
export const GET_TOURS_COLLECTION_SUCCESS = "[TOURS] Collection Get Success";
export const GET_TOURS_COLLECTION_FAIL = "[TOURS] Collection Get Fail";

export const GET_TOURS_SINGLE = "[TOURS] Single Get";
export const GET_TOURS_SINGLE_SUCCESS = "[TOURS] Single Get Success";
export const GET_TOURS_SINGLE_FAIL = "[TOURS] Single Get Fail";

export const CREATE_TOURS = "[TOURS] Create";
export const CREATE_TOURS_SUCCESS = "[TOURS] Create Success";
export const CREATE_TOURS_FAIL = "[TOURS] Create Fail";
export const CREATE_TOURS_RESET = "[TOURS] Create Reset";

export const UPDATE_TOURS = "[TOURS] Update";
export const UPDATE_TOURS_SUCCESS = "[TOURS] Update Success";
export const UPDATE_TOURS_FAIL = "[TOURS] Update Fail";
export const UPDATE_TOURS_RESET = "[TOURS] Update Reset";

export const REMOVE_TOURS = "[TOURS] Remove";
export const REMOVE_TOURS_SUCCESS = "[TOURS] Remove Success";
export const REMOVE_TOURS_FAIL = "[TOURS] Remove Fail";
