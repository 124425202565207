import { nanoid } from 'nanoid';

import {
  CREATE_USER_SUPPORT_REQUESTS,
  REPLACE_USER_SUPPORT_REQUESTS,
} from "@/store/user-support-requests/actions";

export default {
  data() {
    return {
      activeChatId: null,
      activeUserSupportId: null,
    }
  },
  mounted() {
    this.initChat();
  },
  methods: {
    setChatAttributes() {
      if (!this.$Tawk) {
        return;
      }

      const name = this.userData.is_chat_enabled ? this.userData.name : `${this.userData.name} CHAT NOT AVAILABLE`;
      const user = {
        name: name,
        email: this.userData.email,
        hash: this.userData.tawkHash
      };

      this.$Tawk.setAttributes(user);
    },
    initChat() {
      this.$Tawk.init();

      this.$Tawk.onLoad(() => {
        this.setChatAttributes();
      });

      this.$Tawk.onChatStarted((data) => {
        this.setChatAttributes();
        console.log("chatStarted", data);
        this.createSupportRequest(data);
      });

      this.$Tawk.onChatEnded(() => {
        console.log("chatEnded");
        this.closeSupportRequest();
        this.activeChatId = null;
        this.activeUserSupportId = null;
      });

      this.$Tawk.onChatHidden(() => {
        console.log("chatHidden");
        this.closeSupportRequest();
      });

      this.$Tawk.onChatMinimized(() => {
        console.log("chatMinimized");
        this.closeSupportRequest();
      });

      this.$Tawk.onChatMaximized(() => {
        if (!this.userData.is_chat_enabled) {
          this.$Tawk.hideWidget();
        }
      });

      this.$Tawk.onAgentJoinChat((data) => {
        console.log("agentJoin", data);
        this.updateSupportRequest({
          agent: data.name
        });
      });

      this.$Tawk.onChatSatisfaction((satisfaction) => {
        this.updateSupportRequest({
          rating: satisfaction
        });
      });
    },
    createSupportRequest(data) {
      this.activeChatId = data.chatId || nanoid();

      this.$store.dispatch(CREATE_USER_SUPPORT_REQUESTS, {
        user_id: this.userData.id,
        reference: this.activeChatId,
        opened_at: this.$toDateTimeString(this.$moment()),
        source: this.$const('ConstSupportSources', 'CHAT'),
      }).then(response => {
        this.activeUserSupportId = response.id;
      });
    },
    closeSupportRequest() {
      if (!this.activeUserSupportId) {
        return;
      }

      this.$store.dispatch(REPLACE_USER_SUPPORT_REQUESTS, {
        id: this.activeUserSupportId,
        params: {
          closed_at: this.$toDateTimeString(this.$moment())
        }
      });
    },
    updateSupportRequest(data) {
      if (!this.activeUserSupportId) {
        return;
      }

      this.$store.dispatch(REPLACE_USER_SUPPORT_REQUESTS, {
        id: this.activeUserSupportId,
        params: data
      });
    },
  }
};
