<template>
  <span>
    <v-menu offset-y max-height="400px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :small="small"
          :block="block"
          :loading="loading"
          @click="getItems()"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon color="green">mdi-whatsapp</v-icon>
          Whatsapp
        </v-btn>
        
      </template>
      <v-list :dense="dense">
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :href="getWhatsAppLink(item)"
          target="_blank"
        >
          <v-list-item-title>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </span>
</template>

<script>
import {
  GET_PROPERTY_MESSAGE_TEMPLATES_COLLECTION,
} from "@/store/property-message-templates/actions";

export default {
  name: "CWhatsappMenu",
  props: {
    label: {
      type: String,
      required: false,
      default: ""
    },
    language: {
      default: null
    },
    small: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    prependIcon: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Array,
      default: () => {
        return [];
      }
    },
    reservationId: {
      required: true,
    },
    propertyId: {
      required: true,
    },
    client: {
      required: true,
    }
  },
  data() {
    return {
      ids: null,
      items: {},
      loading: false,
    };
  },
  methods: {
    getItems() {
      if (
        this.disabled ||
        Object.keys(this.items).length
      ) {
        return;
      }
      this.loading = true;

      this.$store.dispatch(GET_PROPERTY_MESSAGE_TEMPLATES_COLLECTION, {
        sortBy: 'name',
        order: 'asc',
        content_type: this.$const('ConstMessageContentTypes', 'INSTANT_MESSAGING'),
        property_id: this.propertyId,
        reservation_id: this.reservationId,
        _data: {
          selects: [
            'name',
            'subject',
            'body',
            'content_type',
          ]
        }
      }).then((response) => {
        this.items = response.collection;
        this.loading = false;
      });
    },
    getWhatsAppLink(item) {
      if (!this.client.phone) {
        return '#';
      }

      return `https://wa.me/${this.$phoneFormat(this.client.phone)}?text=${item.body[this.client.locale]}`;
    }
  },
};
</script>
