export const GET_QUOTES_LIST = "[QUOTES] List Get";
export const GET_QUOTES_LIST_SUCCESS = "[QUOTES] List Get Success";
export const GET_QUOTES_LIST_FAIL = "[QUOTES] List Get Fail";

export const GET_QUOTES_PAGINATION = "[QUOTES] Pagination Get";
export const GET_QUOTES_PAGINATION_SUCCESS = "[QUOTES] Pagination Get Success";
export const GET_QUOTES_PAGINATION_FAIL = "[QUOTES] Pagination Get Fail";

export const GET_QUOTES_COLLECTION = "[QUOTES] Collection Get";
export const GET_QUOTES_COLLECTION_SUCCESS = "[QUOTES] Collection Get Success";
export const GET_QUOTES_COLLECTION_FAIL = "[QUOTES] Collection Get Fail";

export const GET_QUOTES_SINGLE = "[QUOTES] Single Get";
export const GET_QUOTES_SINGLE_SUCCESS = "[QUOTES] Single Get Success";
export const GET_QUOTES_SINGLE_FAIL = "[QUOTES] Single Get Fail";

export const CREATE_QUOTES = "[QUOTES] Create";
export const CREATE_QUOTES_SUCCESS = "[QUOTES] Create Success";
export const CREATE_QUOTES_FAIL = "[QUOTES] Create Fail";
export const CREATE_QUOTES_RESET = "[QUOTES] Create Reset";

export const UPDATE_QUOTES = "[QUOTES] Update";
export const UPDATE_QUOTES_SUCCESS = "[QUOTES] Update Success";
export const UPDATE_QUOTES_FAIL = "[QUOTES] Update Fail";
export const UPDATE_QUOTES_RESET = "[QUOTES] Update Reset";

export const REMOVE_QUOTES = "[QUOTES] Remove";
export const REMOVE_QUOTES_SUCCESS = "[QUOTES] Remove Success";
export const REMOVE_QUOTES_FAIL = "[QUOTES] Remove Fail";

export const GET_QUOTES_SERIAL_N = "[QUOTES] Serial N Get";
export const GET_QUOTES_SERIAL_N_SUCCESS = "[QUOTES] Serial N Get Success";
export const GET_QUOTES_SERIAL_N_FAIL = "[QUOTES] Serial N Get Fail";

export const SEND_QUOTES = "[QUOTES] Send";
export const SEND_QUOTES_SUCCESS = "[QUOTES] Send Success";
export const SEND_QUOTES_FAIL = "[QUOTES] Send Fail";
export const SEND_QUOTES_RESET = "[QUOTES] Send Reset";
