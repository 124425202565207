import action from "@/store/core/action";
import crud from "@/store/core/crud";

import {
  GET_SUBSCRIPTION_PLAN_SINGLE,
  GET_SUBSCRIPTION_PLAN_SINGLE_SUCCESS,
  GET_SUBSCRIPTION_PLAN_SINGLE_FAIL,
  CREATE_SUBSCRIPTION_PLAN,
  CREATE_SUBSCRIPTION_PLAN_SUCCESS,
  CREATE_SUBSCRIPTION_PLAN_FAIL,
  CREATE_SUBSCRIPTION_PLAN_RESET,
  UPDATE_SUBSCRIPTION_PLAN,
  UPDATE_SUBSCRIPTION_PLAN_SUCCESS,
  UPDATE_SUBSCRIPTION_PLAN_FAIL,
  UPDATE_SUBSCRIPTION_PLAN_RESET,
  REPLACE_SUBSCRIPTION_PLAN,
  REPLACE_SUBSCRIPTION_PLAN_SUCCESS,
  REPLACE_SUBSCRIPTION_PLAN_FAIL,
  REPLACE_SUBSCRIPTION_PLAN_RESET,
  REMOVE_SUBSCRIPTION_PLAN,
  REMOVE_SUBSCRIPTION_PLAN_SUCCESS,
  REMOVE_SUBSCRIPTION_PLAN_FAIL,
  GET_SUBSCRIPTION_PLAN_STRIPE_PAYMENT_METHOD,
  GET_SUBSCRIPTION_PLAN_STRIPE_PAYMENT_METHOD_SUCCESS,
  GET_SUBSCRIPTION_PLAN_STRIPE_PAYMENT_METHOD_FAIL,
  CONFIRM_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT,
  CONFIRM_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT_SUCCESS,
  CONFIRM_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT_FAIL,
  CANCEL_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT,
  CANCEL_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT_SUCCESS,
  CANCEL_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT_FAIL,
  CREATE_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT,
  CREATE_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT_SUCCESS,
  CREATE_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT_FAIL,
  DETACH_SUBSCRIPTION_PLAN_STRIPE_PAYMENT_METHOD,
  DETACH_SUBSCRIPTION_PLAN_STRIPE_PAYMENT_METHOD_SUCCESS,
  DETACH_SUBSCRIPTION_PLAN_STRIPE_PAYMENT_METHOD_FAIL,
  SEND_CONTRACT,
  SEND_CONTRACT_SUCCESS,
  SEND_CONTRACT_FAIL,
  GET_CONTRACT,
  GET_CONTRACT_SUCCESS,
  GET_CONTRACT_FAIL
} from "./actions";

const crudProcessed = crud.process({
  getSingleRequest: {
    action: GET_SUBSCRIPTION_PLAN_SINGLE,
    endPoint: "company-subscription/single",
    mutationFail: GET_SUBSCRIPTION_PLAN_SINGLE_FAIL,
    mutationSuccess: GET_SUBSCRIPTION_PLAN_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_SUBSCRIPTION_PLAN,
    actionReset: CREATE_SUBSCRIPTION_PLAN_RESET,
    endPoint: "company-subscription/create",
    mutationFail: CREATE_SUBSCRIPTION_PLAN_FAIL,
    mutationSuccess: CREATE_SUBSCRIPTION_PLAN_SUCCESS
  },
  editingRequest: {
    action: UPDATE_SUBSCRIPTION_PLAN,
    actionReset: UPDATE_SUBSCRIPTION_PLAN_RESET,
    endPoint: "company-subscription/update",
    mutationFail: UPDATE_SUBSCRIPTION_PLAN_FAIL,
    mutationSuccess: UPDATE_SUBSCRIPTION_PLAN_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_SUBSCRIPTION_PLAN,
    actionReset: REPLACE_SUBSCRIPTION_PLAN_RESET,
    endPoint: "company-subscription/replace",
    mutationFail: REPLACE_SUBSCRIPTION_PLAN_FAIL,
    mutationSuccess: REPLACE_SUBSCRIPTION_PLAN_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_SUBSCRIPTION_PLAN,
    endPoint: "company-subscription/delete",
    mutationFail: REMOVE_SUBSCRIPTION_PLAN_FAIL,
    mutationSuccess: REMOVE_SUBSCRIPTION_PLAN_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([
  {
    method: 'post',
    name: 'sendContractRequest',
    action: SEND_CONTRACT,
    endPoint: 'company-subscription/{id}/send-contract',
    mutations: {
      fail: SEND_CONTRACT_FAIL,
      success: SEND_CONTRACT_SUCCESS,
    },
  },
  {
    name: 'getContractRequest',
    action: GET_CONTRACT,
    endPoint: 'company-subscription/{id}/get-contract',
    mutations: {
      fail: GET_CONTRACT_FAIL,
      success: GET_CONTRACT_SUCCESS,
    },
  },
  {
    name: 'getStripePaymentMethodRequest',
    action: GET_SUBSCRIPTION_PLAN_STRIPE_PAYMENT_METHOD,
    endPoint: 'company-subscription/{id}/stripe/payment-method',
    mutations: {
      fail: GET_SUBSCRIPTION_PLAN_STRIPE_PAYMENT_METHOD_FAIL,
      success: GET_SUBSCRIPTION_PLAN_STRIPE_PAYMENT_METHOD_SUCCESS,
    },
  },
  {
    method: 'delete',
    name: 'detachStripePaymentMethodRequest',
    action: DETACH_SUBSCRIPTION_PLAN_STRIPE_PAYMENT_METHOD,
    endPoint: 'company-subscription/{id}/stripe/payment-method/detach',
    mutations: {
      fail: DETACH_SUBSCRIPTION_PLAN_STRIPE_PAYMENT_METHOD_FAIL,
      success: DETACH_SUBSCRIPTION_PLAN_STRIPE_PAYMENT_METHOD_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'createStripeSetupIntentRequest',
    action: CREATE_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT,
    endPoint: 'company-subscription/{id}/stripe/setup-intent/create',
    mutations: {
      fail: CREATE_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT_FAIL,
      success: CREATE_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'confirmStripeSetupIntentRequest',
    action: CONFIRM_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT,
    endPoint: 'company-subscription/{id}/stripe/setup-intent/confirm',
    mutations: {
      fail: CONFIRM_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT_FAIL,
      success: CONFIRM_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT_SUCCESS,
    },
  },
  {
    method: 'delete',
    name: 'cancelStripeSetupIntentRequest',
    action: CANCEL_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT,
    endPoint: 'company-subscription/{id}/stripe/setup-intent/cancel',
    mutations: {
      fail: CANCEL_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT_FAIL,
      success: CANCEL_SUBSCRIPTION_PLAN_STRIPE_SETUP_INTENT_SUCCESS,
    },
  },
]);

const getters = {};

const actions = {
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const state = {
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
