export const GET_PROPERTY_REVENUE_SHARES_LIST = "[PROPERTY_REVENUE_SHARES] List Get";
export const GET_PROPERTY_REVENUE_SHARES_LIST_SUCCESS = "[PROPERTY_REVENUE_SHARES] List Get Success";
export const GET_PROPERTY_REVENUE_SHARES_LIST_FAIL = "[PROPERTY_REVENUE_SHARES] List Get Fail";

export const GET_PROPERTY_REVENUE_SHARES_PAGINATION = "[PROPERTY_REVENUE_SHARES] Pagination Get";
export const GET_PROPERTY_REVENUE_SHARES_PAGINATION_SUCCESS = "[PROPERTY_REVENUE_SHARES] Pagination Get Success";
export const GET_PROPERTY_REVENUE_SHARES_PAGINATION_FAIL = "[PROPERTY_REVENUE_SHARES] Pagination Get Fail";

export const GET_PROPERTY_REVENUE_SHARES_COLLECTION = "[PROPERTY_REVENUE_SHARES] Collection Get";
export const GET_PROPERTY_REVENUE_SHARES_COLLECTION_SUCCESS = "[PROPERTY_REVENUE_SHARES] Collection Get Success";
export const GET_PROPERTY_REVENUE_SHARES_COLLECTION_FAIL = "[PROPERTY_REVENUE_SHARES] Collection Get Fail";

export const GET_PROPERTY_REVENUE_SHARES_SINGLE = "[PROPERTY_REVENUE_SHARES] Single Get";
export const GET_PROPERTY_REVENUE_SHARES_SINGLE_SUCCESS = "[PROPERTY_REVENUE_SHARES] Single Get Success";
export const GET_PROPERTY_REVENUE_SHARES_SINGLE_FAIL = "[PROPERTY_REVENUE_SHARES] Single Get Fail";

export const CREATE_PROPERTY_REVENUE_SHARES = "[PROPERTY_REVENUE_SHARES] Create";
export const CREATE_PROPERTY_REVENUE_SHARES_SUCCESS = "[PROPERTY_REVENUE_SHARES] Create Success";
export const CREATE_PROPERTY_REVENUE_SHARES_FAIL = "[PROPERTY_REVENUE_SHARES] Create Fail";
export const CREATE_PROPERTY_REVENUE_SHARES_RESET = "[PROPERTY_REVENUE_SHARES] Create Reset";

export const UPDATE_PROPERTY_REVENUE_SHARES = "[PROPERTY_REVENUE_SHARES] Update";
export const UPDATE_PROPERTY_REVENUE_SHARES_SUCCESS = "[PROPERTY_REVENUE_SHARES] Update Success";
export const UPDATE_PROPERTY_REVENUE_SHARES_FAIL = "[PROPERTY_REVENUE_SHARES] Update Fail";
export const UPDATE_PROPERTY_REVENUE_SHARES_RESET = "[PROPERTY_REVENUE_SHARES] Update Reset";

export const REMOVE_PROPERTY_REVENUE_SHARES = "[PROPERTY_REVENUE_SHARES] Remove";
export const REMOVE_PROPERTY_REVENUE_SHARES_SUCCESS = "[PROPERTY_REVENUE_SHARES] Remove Success";
export const REMOVE_PROPERTY_REVENUE_SHARES_FAIL = "[PROPERTY_REVENUE_SHARES] Remove Fail";

export const REGENERATE_PROPERTY_REVENUE_SHARES = "[PROPERTY_REVENUE_SHARES] Regenerate Get";
export const REGENERATE_PROPERTY_REVENUE_SHARES_SUCCESS = "[PROPERTY_REVENUE_SHARES] Regenerate Get Success";
export const REGENERATE_PROPERTY_REVENUE_SHARES_FAIL = "[PROPERTY_REVENUE_SHARES] Regenerate Get Fail";

export const REGENERATE_BULK_PROPERTY_REVENUE_SHARES = "[PROPERTY_REVENUE_SHARES] Regenerate Bulk Get";
export const REGENERATE_BULK_PROPERTY_REVENUE_SHARES_SUCCESS = "[PROPERTY_REVENUE_SHARES] Regenerate Bulk Get Success";
export const REGENERATE_BULK_PROPERTY_REVENUE_SHARES_FAIL = "[PROPERTY_REVENUE_SHARES] Regenerate Bulk Get Fail";

