export const GET_BOOKING_NETWORK_BLOCKS_LIST = "[BOOKING_NETWORK_BLOCKS] List Get";
export const GET_BOOKING_NETWORK_BLOCKS_LIST_SUCCESS = "[BOOKING_NETWORK_BLOCKS] List Get Success";
export const GET_BOOKING_NETWORK_BLOCKS_LIST_FAIL = "[BOOKING_NETWORK_BLOCKS] List Get Fail";

export const GET_BOOKING_NETWORK_BLOCKS_PAGINATION = "[BOOKING_NETWORK_BLOCKS] Pagination Get";
export const GET_BOOKING_NETWORK_BLOCKS_PAGINATION_SUCCESS = "[BOOKING_NETWORK_BLOCKS] Pagination Get Success";
export const GET_BOOKING_NETWORK_BLOCKS_PAGINATION_FAIL = "[BOOKING_NETWORK_BLOCKS] Pagination Get Fail";

export const GET_BOOKING_NETWORK_BLOCKS_COLLECTION = "[BOOKING_NETWORK_BLOCKS] Collection Get";
export const GET_BOOKING_NETWORK_BLOCKS_COLLECTION_SUCCESS = "[BOOKING_NETWORK_BLOCKS] Collection Get Success";
export const GET_BOOKING_NETWORK_BLOCKS_COLLECTION_FAIL = "[BOOKING_NETWORK_BLOCKS] Collection Get Fail";

export const GET_BOOKING_NETWORK_BLOCKS_SINGLE = "[BOOKING_NETWORK_BLOCKS] Single Get";
export const GET_BOOKING_NETWORK_BLOCKS_SINGLE_SUCCESS = "[BOOKING_NETWORK_BLOCKS] Single Get Success";
export const GET_BOOKING_NETWORK_BLOCKS_SINGLE_FAIL = "[BOOKING_NETWORK_BLOCKS] Single Get Fail";

export const CREATE_BOOKING_NETWORK_BLOCKS = "[BOOKING_NETWORK_BLOCKS] Create";
export const CREATE_BOOKING_NETWORK_BLOCKS_SUCCESS = "[BOOKING_NETWORK_BLOCKS] Create Success";
export const CREATE_BOOKING_NETWORK_BLOCKS_FAIL = "[BOOKING_NETWORK_BLOCKS] Create Fail";
export const CREATE_BOOKING_NETWORK_BLOCKS_RESET = "[BOOKING_NETWORK_BLOCKS] Create Reset";

export const UPDATE_BOOKING_NETWORK_BLOCKS = "[BOOKING_NETWORK_BLOCKS] Update";
export const UPDATE_BOOKING_NETWORK_BLOCKS_SUCCESS = "[BOOKING_NETWORK_BLOCKS] Update Success";
export const UPDATE_BOOKING_NETWORK_BLOCKS_FAIL = "[BOOKING_NETWORK_BLOCKS] Update Fail";
export const UPDATE_BOOKING_NETWORK_BLOCKS_RESET = "[BOOKING_NETWORK_BLOCKS] Update Reset";

export const REPLACE_BOOKING_NETWORK_BLOCKS = "[BOOKING_NETWORK_BLOCKS] Replace";
export const REPLACE_BOOKING_NETWORK_BLOCKS_SUCCESS = "[BOOKING_NETWORK_BLOCKS] Replace Success";
export const REPLACE_BOOKING_NETWORK_BLOCKS_FAIL = "[BOOKING_NETWORK_BLOCKS] Replace Fail";
export const REPLACE_BOOKING_NETWORK_BLOCKS_RESET = "[BOOKING_NETWORK_BLOCKS] Replace Reset";

export const DUPLICATE_BOOKING_NETWORK_BLOCKS = "[BOOKING_NETWORK_BLOCKS] Duplicate";
export const DUPLICATE_BOOKING_NETWORK_BLOCKS_SUCCESS = "[BOOKING_NETWORK_BLOCKS] Duplicate Success";
export const DUPLICATE_BOOKING_NETWORK_BLOCKS_FAIL = "[BOOKING_NETWORK_BLOCKS] Duplicate Fail";
export const DUPLICATE_BOOKING_NETWORK_BLOCKS_RESET = "[BOOKING_NETWORK_BLOCKS] Duplicate Reset";

export const REMOVE_BOOKING_NETWORK_BLOCKS = "[BOOKING_NETWORK_BLOCKS] Remove";
export const REMOVE_BOOKING_NETWORK_BLOCKS_SUCCESS = "[BOOKING_NETWORK_BLOCKS] Remove Success";
export const REMOVE_BOOKING_NETWORK_BLOCKS_FAIL = "[BOOKING_NETWORK_BLOCKS] Remove Fail";

export const POSITION_BOOKING_NETWORK_BLOCKS = "[BOOKING_NETWORK_BLOCKS] Position";
export const POSITION_BOOKING_NETWORK_BLOCKS_SUCCESS = "[BOOKING_NETWORK_BLOCKS] Position Success";
export const POSITION_BOOKING_NETWORK_BLOCKS_FAIL = "[BOOKING_NETWORK_BLOCKS] Position Fail";
