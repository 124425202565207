export const GET_PRODUCT_CATEGORIES_LIST = "[PRODUCT_CATEGORIES] List Get";
export const GET_PRODUCT_CATEGORIES_LIST_SUCCESS = "[PRODUCT_CATEGORIES] List Get Success";
export const GET_PRODUCT_CATEGORIES_LIST_FAIL = "[PRODUCT_CATEGORIES] List Get Fail";

export const GET_PRODUCT_CATEGORIES_PAGINATION = "[PRODUCT_CATEGORIES] Pagination Get";
export const GET_PRODUCT_CATEGORIES_PAGINATION_SUCCESS = "[PRODUCT_CATEGORIES] Pagination Get Success";
export const GET_PRODUCT_CATEGORIES_PAGINATION_FAIL = "[PRODUCT_CATEGORIES] Pagination Get Fail";

export const GET_PRODUCT_CATEGORIES_COLLECTION = "[PRODUCT_CATEGORIES] Collection Get";
export const GET_PRODUCT_CATEGORIES_COLLECTION_SUCCESS = "[PRODUCT_CATEGORIES] Collection Get Success";
export const GET_PRODUCT_CATEGORIES_COLLECTION_FAIL = "[PRODUCT_CATEGORIES] Collection Get Fail";

export const GET_PRODUCT_CATEGORIES_SINGLE = "[PRODUCT_CATEGORIES] Single Get";
export const GET_PRODUCT_CATEGORIES_SINGLE_SUCCESS = "[PRODUCT_CATEGORIES] Single Get Success";
export const GET_PRODUCT_CATEGORIES_SINGLE_FAIL = "[PRODUCT_CATEGORIES] Single Get Fail";

export const CREATE_PRODUCT_CATEGORIES = "[PRODUCT_CATEGORIES] Create";
export const CREATE_PRODUCT_CATEGORIES_SUCCESS = "[PRODUCT_CATEGORIES] Create Success";
export const CREATE_PRODUCT_CATEGORIES_FAIL = "[PRODUCT_CATEGORIES] Create Fail";
export const CREATE_PRODUCT_CATEGORIES_RESET = "[PRODUCT_CATEGORIES] Create Reset";

export const UPDATE_PRODUCT_CATEGORIES = "[PRODUCT_CATEGORIES] Update";
export const UPDATE_PRODUCT_CATEGORIES_SUCCESS = "[PRODUCT_CATEGORIES] Update Success";
export const UPDATE_PRODUCT_CATEGORIES_FAIL = "[PRODUCT_CATEGORIES] Update Fail";
export const UPDATE_PRODUCT_CATEGORIES_RESET = "[PRODUCT_CATEGORIES] Update Reset";

export const REPLACE_PRODUCT_CATEGORIES = "[PRODUCT_CATEGORIES] Replace";
export const REPLACE_PRODUCT_CATEGORIES_SUCCESS = "[PRODUCT_CATEGORIES] Replace Success";
export const REPLACE_PRODUCT_CATEGORIES_FAIL = "[PRODUCT_CATEGORIES] Replace Fail";
export const REPLACE_PRODUCT_CATEGORIES_RESET = "[PRODUCT_CATEGORIES] Replace Reset";

export const REMOVE_PRODUCT_CATEGORIES = "[PRODUCT_CATEGORIES] Remove";
export const REMOVE_PRODUCT_CATEGORIES_SUCCESS = "[PRODUCT_CATEGORIES] Remove Success";
export const REMOVE_PRODUCT_CATEGORIES_FAIL = "[PRODUCT_CATEGORIES] Remove Fail";
