import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_PRODUCTS_LIST,
  GET_PRODUCTS_LIST_SUCCESS,
  GET_PRODUCTS_LIST_FAIL,
  GET_PRODUCTS_PAGINATION,
  GET_PRODUCTS_PAGINATION_SUCCESS,
  GET_PRODUCTS_PAGINATION_FAIL,
  GET_PRODUCTS_COLLECTION,
  GET_PRODUCTS_COLLECTION_SUCCESS,
  GET_PRODUCTS_COLLECTION_FAIL,
  GET_PRODUCTS_SINGLE,
  GET_PRODUCTS_SINGLE_SUCCESS,
  GET_PRODUCTS_SINGLE_FAIL,
  CREATE_PRODUCTS,
  CREATE_PRODUCTS_SUCCESS,
  CREATE_PRODUCTS_FAIL,
  CREATE_PRODUCTS_RESET,
  UPDATE_PRODUCTS,
  UPDATE_PRODUCTS_SUCCESS,
  UPDATE_PRODUCTS_FAIL,
  UPDATE_PRODUCTS_RESET,
  REPLACE_PRODUCTS,
  REPLACE_PRODUCTS_SUCCESS,
  REPLACE_PRODUCTS_FAIL,
  REPLACE_PRODUCTS_RESET,
  REMOVE_PRODUCTS,
  REMOVE_PRODUCTS_SUCCESS,
  REMOVE_PRODUCTS_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_PRODUCTS_LIST,
    endPoint: "products/list",
    mutationFail: GET_PRODUCTS_LIST_FAIL,
    mutationSuccess: GET_PRODUCTS_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_PRODUCTS_PAGINATION,
    endPoint: "products/paginated",
    mutationFail: GET_PRODUCTS_PAGINATION_FAIL,
    mutationSuccess: GET_PRODUCTS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_PRODUCTS_COLLECTION,
    endPoint: "products/collected",
    mutationFail: GET_PRODUCTS_COLLECTION_FAIL,
    mutationSuccess: GET_PRODUCTS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_PRODUCTS_SINGLE,
    endPoint: "products/single",
    mutationFail: GET_PRODUCTS_SINGLE_FAIL,
    mutationSuccess: GET_PRODUCTS_SINGLE_SUCCESS
  },
  creationRequest: {
    isUpload: true,
    action: CREATE_PRODUCTS,
    actionReset: CREATE_PRODUCTS_RESET,
    endPoint: "products/create",
    mutationFail: CREATE_PRODUCTS_FAIL,
    mutationSuccess: CREATE_PRODUCTS_SUCCESS
  },
  editingRequest: {
    isUpload: true,
    action: UPDATE_PRODUCTS,
    actionReset: UPDATE_PRODUCTS_RESET,
    endPoint: "products/update",
    mutationFail: UPDATE_PRODUCTS_FAIL,
    mutationSuccess: UPDATE_PRODUCTS_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_PRODUCTS,
    actionReset: REPLACE_PRODUCTS_RESET,
    endPoint: "products/replace",
    mutationFail: REPLACE_PRODUCTS_FAIL,
    mutationSuccess: REPLACE_PRODUCTS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_PRODUCTS,
    endPoint: "products/delete",
    mutationFail: REMOVE_PRODUCTS_FAIL,
    mutationSuccess: REMOVE_PRODUCTS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.state,
};

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
