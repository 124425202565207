<template>
  <v-menu offset-y max-height="400px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        v-bind="attrs"
        v-on="on"
        @click="getData"
        :loading="getVariablesRequest.loading"
      >
        {{ $lang('property-message-templates.replacement_tags') }}
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item v-for="(item, index) in items" :key="index" @click="$emit('input', item.value)">
        <v-list-item-title>{{ item.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex';
import {
  GET_PROPERTY_MESSAGE_TEMPLATES_VARIABLES,
} from "@/store/property-message-templates/actions";

export default {
  name: "ReplacementTagsMenu",
  props: {
    replacementTagsArray: {
      type: Array,
      required: false
    },
  },
  methods: {
    getData() {
      let params = {};

      if (this.replacementTagsArray) {
        params = {
          groups: this.replacementTagsArray
        }
      }

      this.$store.dispatch(GET_PROPERTY_MESSAGE_TEMPLATES_VARIABLES, params);
    }
  },
  computed: {
    ...mapState({
      getVariablesRequest: state => state.propertyMessageTemplates.getVariablesRequest
    }),
    items() {
      if (!this.getVariablesRequest.data) {
        return []
      }

      return this.getVariablesRequest.data;
    }
  }

}
</script>

<style>

</style>