export const GET_COMPANY_INVOICES_LIST = "[COMPANY_INVOICES] List Get";
export const GET_COMPANY_INVOICES_LIST_SUCCESS = "[COMPANY_INVOICES] List Get Success";
export const GET_COMPANY_INVOICES_LIST_FAIL = "[COMPANY_INVOICES] List Get Fail";

export const GET_COMPANY_INVOICES_PAGINATION = "[COMPANY_INVOICES] Pagination Get";
export const GET_COMPANY_INVOICES_PAGINATION_SUCCESS = "[COMPANY_INVOICES] Pagination Get Success";
export const GET_COMPANY_INVOICES_PAGINATION_FAIL = "[COMPANY_INVOICES] Pagination Get Fail";

export const GET_COMPANY_INVOICES_COLLECTION = "[COMPANY_INVOICES] Collection Get";
export const GET_COMPANY_INVOICES_COLLECTION_SUCCESS = "[COMPANY_INVOICES] Collection Get Success";
export const GET_COMPANY_INVOICES_COLLECTION_FAIL = "[COMPANY_INVOICES] Collection Get Fail";

export const GET_COMPANY_INVOICES_SINGLE = "[COMPANY_INVOICES] Single Get";
export const GET_COMPANY_INVOICES_SINGLE_SUCCESS = "[COMPANY_INVOICES] Single Get Success";
export const GET_COMPANY_INVOICES_SINGLE_FAIL = "[COMPANY_INVOICES] Single Get Fail";

export const CREATE_COMPANY_INVOICES = "[COMPANY_INVOICES] Create";
export const CREATE_COMPANY_INVOICES_SUCCESS = "[COMPANY_INVOICES] Create Success";
export const CREATE_COMPANY_INVOICES_FAIL = "[COMPANY_INVOICES] Create Fail";
export const CREATE_COMPANY_INVOICES_RESET = "[COMPANY_INVOICES] Create Reset";

export const UPDATE_COMPANY_INVOICES = "[COMPANY_INVOICES] Update";
export const UPDATE_COMPANY_INVOICES_SUCCESS = "[COMPANY_INVOICES] Update Success";
export const UPDATE_COMPANY_INVOICES_FAIL = "[COMPANY_INVOICES] Update Fail";
export const UPDATE_COMPANY_INVOICES_RESET = "[COMPANY_INVOICES] Update Reset";

export const REMOVE_COMPANY_INVOICES = "[COMPANY_INVOICES] Remove";
export const REMOVE_COMPANY_INVOICES_SUCCESS = "[COMPANY_INVOICES] Remove Success";
export const REMOVE_COMPANY_INVOICES_FAIL = "[COMPANY_INVOICES] Remove Fail";

export const GET_COMPANY_INVOICES_SERIAL_N = "[COMPANY_INVOICES] Serial N Get";
export const GET_COMPANY_INVOICES_SERIAL_N_SUCCESS = "[COMPANY_INVOICES] Serial N Get Success";
export const GET_COMPANY_INVOICES_SERIAL_N_FAIL = "[COMPANY_INVOICES] Serial N Get Fail";

export const EXPORT_XML_COMPANY_INVOICES = "[COMPANY_INVOICES] Export XML";

export const EXPORT_PDF_COMPANY_INVOICES = "[COMPANY_INVOICES] Export PDF";

export const COMPANY_INVOICE_SEND_TO_SDI = "[COMPANY_INVOICES] Send to SDI";
export const COMPANY_INVOICE_SEND_TO_SDI_SUCCESS = "[COMPANY_INVOICES] Send to SDI Success";
export const COMPANY_INVOICE_SEND_TO_SDI_FAIL = "[COMPANY_INVOICES] Send to SDI Fail";
