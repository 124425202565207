import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_COMPANY_SMART_DEVICE_ACCOUNTS_LIST,
  GET_COMPANY_SMART_DEVICE_ACCOUNTS_LIST_SUCCESS,
  GET_COMPANY_SMART_DEVICE_ACCOUNTS_LIST_FAIL,
  CREATE_COMPANY_SMART_DEVICE_ACCOUNTS,
  CREATE_COMPANY_SMART_DEVICE_ACCOUNTS_FAIL,
  CREATE_COMPANY_SMART_DEVICE_ACCOUNTS_RESET,
  CREATE_COMPANY_SMART_DEVICE_ACCOUNTS_SUCCESS,
  GET_COMPANY_SMART_DEVICE_ACCOUNTS_COLLECTION,
  GET_COMPANY_SMART_DEVICE_ACCOUNTS_COLLECTION_FAIL,
  GET_COMPANY_SMART_DEVICE_ACCOUNTS_COLLECTION_SUCCESS,
  GET_COMPANY_SMART_DEVICE_ACCOUNTS_SINGLE,
  GET_COMPANY_SMART_DEVICE_ACCOUNTS_SINGLE_FAIL,
  GET_COMPANY_SMART_DEVICE_ACCOUNTS_SINGLE_SUCCESS,
  REMOVE_COMPANY_SMART_DEVICE_ACCOUNTS,
  REMOVE_COMPANY_SMART_DEVICE_ACCOUNTS_FAIL,
  REMOVE_COMPANY_SMART_DEVICE_ACCOUNTS_SUCCESS,
  UPDATE_COMPANY_SMART_DEVICE_ACCOUNTS,
  UPDATE_COMPANY_SMART_DEVICE_ACCOUNTS_FAIL,
  UPDATE_COMPANY_SMART_DEVICE_ACCOUNTS_RESET,
  UPDATE_COMPANY_SMART_DEVICE_ACCOUNTS_SUCCESS,
  GET_COMPANY_SMART_DEVICE_ACCOUNTS_PAGINATION,
  GET_COMPANY_SMART_DEVICE_ACCOUNTS_PAGINATION_FAIL,
  GET_COMPANY_SMART_DEVICE_ACCOUNTS_PAGINATION_SUCCESS,
  GET_NUKI_AUTHORIZATION_URL,
  GET_NUKI_AUTHORIZATION_URL_SUCCESS,
  GET_NUKI_AUTHORIZATION_URL_FAIL,
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_COMPANY_SMART_DEVICE_ACCOUNTS_PAGINATION,
    endPoint: "company/smart-device-accounts/paginated",
    mutationFail: GET_COMPANY_SMART_DEVICE_ACCOUNTS_PAGINATION_FAIL,
    mutationSuccess: GET_COMPANY_SMART_DEVICE_ACCOUNTS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_COMPANY_SMART_DEVICE_ACCOUNTS_COLLECTION,
    endPoint: "company/smart-device-accounts/collected",
    mutationFail: GET_COMPANY_SMART_DEVICE_ACCOUNTS_COLLECTION_FAIL,
    mutationSuccess: GET_COMPANY_SMART_DEVICE_ACCOUNTS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_COMPANY_SMART_DEVICE_ACCOUNTS_SINGLE,
    endPoint: "company/smart-device-accounts/single",
    mutationFail: GET_COMPANY_SMART_DEVICE_ACCOUNTS_SINGLE_FAIL,
    mutationSuccess: GET_COMPANY_SMART_DEVICE_ACCOUNTS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_COMPANY_SMART_DEVICE_ACCOUNTS,
    actionReset: CREATE_COMPANY_SMART_DEVICE_ACCOUNTS_RESET,
    endPoint: "company/smart-device-accounts/create",
    mutationFail: CREATE_COMPANY_SMART_DEVICE_ACCOUNTS_FAIL,
    mutationSuccess: CREATE_COMPANY_SMART_DEVICE_ACCOUNTS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_COMPANY_SMART_DEVICE_ACCOUNTS,
    actionReset: UPDATE_COMPANY_SMART_DEVICE_ACCOUNTS_RESET,
    endPoint: "company/smart-device-accounts/update",
    mutationFail: UPDATE_COMPANY_SMART_DEVICE_ACCOUNTS_FAIL,
    mutationSuccess: UPDATE_COMPANY_SMART_DEVICE_ACCOUNTS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_COMPANY_SMART_DEVICE_ACCOUNTS,
    endPoint: "company/smart-device-accounts/delete",
    mutationFail: REMOVE_COMPANY_SMART_DEVICE_ACCOUNTS_FAIL,
    mutationSuccess: REMOVE_COMPANY_SMART_DEVICE_ACCOUNTS_SUCCESS
  }
});

const listProcessed = list.process({
  getListRequest: {
    action: GET_COMPANY_SMART_DEVICE_ACCOUNTS_LIST,
    endPoint: "company/smart-device-accounts/list",
    mutationFail: GET_COMPANY_SMART_DEVICE_ACCOUNTS_LIST_FAIL,
    mutationSuccess: GET_COMPANY_SMART_DEVICE_ACCOUNTS_LIST_SUCCESS
  },
});

const actionsProcessed = action.processMultiple([
  {
    name: 'getNukiAuthorizationUrlRequest',
    action: GET_NUKI_AUTHORIZATION_URL,
    endPoint: 'company/smart-device-accounts/nuki-authorization-url',
    mutations: {
      fail: GET_NUKI_AUTHORIZATION_URL_FAIL,
      success: GET_NUKI_AUTHORIZATION_URL_SUCCESS,
    },
  },
]);

const getters = {};

const actions = {
  ...crudProcessed.actions,
  ...listProcessed.actions,
  ...actionsProcessed.actions,
};

const state = {
  ...crudProcessed.state,
  ...listProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...crudProcessed.mutations,
  ...listProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
