import {
  LOAD_CONSTANTS,
  LOAD_TRANSLATIONS
} from "../app/actions";

import {
  GET_AUTH_USER
} from "../auth/actions";

export const authenticate = (context, config) => {
  config = config || {};

  return new Promise(resolve => {
    context.dispatch(GET_AUTH_USER).then(() => {
      context.dispatch(LOAD_CONSTANTS);

      const modules = config.translationModules;

      context.dispatch(LOAD_TRANSLATIONS, { modules }).then(() => {
        resolve(true);
      });
    });
  });
};

