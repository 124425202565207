export const GET_DERIVATIONS_PAGINATION = '[DERIVATIONS] Pagination Get';
export const GET_DERIVATIONS_PAGINATION_SUCCESS = '[DERIVATIONS] Pagination Get Success';
export const GET_DERIVATIONS_PAGINATION_FAIL = '[DERIVATIONS] Pagination Get Fail';

export const GET_DERIVATIONS_COLLECTION = '[DERIVATIONS] Collection Get';
export const GET_DERIVATIONS_COLLECTION_SUCCESS = '[DERIVATIONS] Collection Get Success';
export const GET_DERIVATIONS_COLLECTION_FAIL = '[DERIVATIONS] Collection Get Fail';

export const GET_DERIVATIONS_SINGLE = '[DERIVATIONS] Single Get';
export const GET_DERIVATIONS_SINGLE_SUCCESS = '[DERIVATIONS] Single Get Success';
export const GET_DERIVATIONS_SINGLE_FAIL = '[DERIVATIONS] Single Get Fail';

export const CREATE_DERIVATIONS = '[DERIVATIONS] Create';
export const CREATE_DERIVATIONS_SUCCESS = '[DERIVATIONS] Create Success';
export const CREATE_DERIVATIONS_FAIL = '[DERIVATIONS] Create Fail';
export const CREATE_DERIVATIONS_RESET = '[DERIVATIONS] Create Reset';

export const UPDATE_DERIVATIONS = '[DERIVATIONS] Update';
export const UPDATE_DERIVATIONS_SUCCESS = '[DERIVATIONS] Update Success';
export const UPDATE_DERIVATIONS_FAIL = '[DERIVATIONS] Update Fail';
export const UPDATE_DERIVATIONS_RESET = '[DERIVATIONS] Update Reset';

export const REMOVE_DERIVATIONS = '[DERIVATIONS] Remove';
export const REMOVE_DERIVATIONS_SUCCESS = '[DERIVATIONS] Remove Success';
export const REMOVE_DERIVATIONS_FAIL = '[DERIVATIONS] Remove Fail';

export const GET_DERIVATION_PRICE_RULES = '[DERIVATIONS] Price rules';
export const GET_DERIVATION_PRICE_RULES_SUCCESS = '[DERIVATIONS] Price rules Success';
export const GET_DERIVATION_PRICE_RULES_FAIL = '[DERIVATIONS] Price rules Fail';
