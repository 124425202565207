import list from "@/store/core/list";
import crud from "@/store/core/crud";
import action from "@/store/core/action";

import {
  GET_PROPERTY_COSTS_LIST,
  GET_PROPERTY_COSTS_LIST_SUCCESS,
  GET_PROPERTY_COSTS_LIST_FAIL,
  GET_PROPERTY_COSTS_PAGINATION,
  GET_PROPERTY_COSTS_PAGINATION_SUCCESS,
  GET_PROPERTY_COSTS_PAGINATION_FAIL,
  GET_PROPERTY_COSTS_COLLECTION,
  GET_PROPERTY_COSTS_COLLECTION_SUCCESS,
  GET_PROPERTY_COSTS_COLLECTION_FAIL,
  GET_PROPERTY_COSTS_SINGLE,
  GET_PROPERTY_COSTS_SINGLE_SUCCESS,
  GET_PROPERTY_COSTS_SINGLE_FAIL,
  CREATE_PROPERTY_COSTS,
  CREATE_PROPERTY_COSTS_SUCCESS,
  CREATE_PROPERTY_COSTS_FAIL,
  CREATE_PROPERTY_COSTS_RESET,
  BULK_CREATE_PROPERTY_COSTS,
  BULK_CREATE_PROPERTY_COSTS_SUCCESS,
  BULK_CREATE_PROPERTY_COSTS_FAIL,
  BULK_CREATE_PROPERTY_COSTS_RESET,
  UPDATE_PROPERTY_COSTS,
  UPDATE_PROPERTY_COSTS_SUCCESS,
  UPDATE_PROPERTY_COSTS_FAIL,
  UPDATE_PROPERTY_COSTS_RESET,
  REMOVE_PROPERTY_COSTS,
  REMOVE_PROPERTY_COSTS_SUCCESS,
  REMOVE_PROPERTY_COSTS_FAIL,
  BULK_REMOVE_PROPERTY_COSTS,
  BULK_REMOVE_PROPERTY_COSTS_SUCCESS,
  BULK_REMOVE_PROPERTY_COSTS_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_PROPERTY_COSTS_LIST,
    endPoint: "property-costs/list",
    mutationFail: GET_PROPERTY_COSTS_LIST_FAIL,
    mutationSuccess: GET_PROPERTY_COSTS_LIST_SUCCESS
  }
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_PROPERTY_COSTS_PAGINATION,
    endPoint: "property-costs/paginated",
    mutationFail: GET_PROPERTY_COSTS_PAGINATION_FAIL,
    mutationSuccess: GET_PROPERTY_COSTS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_PROPERTY_COSTS_COLLECTION,
    endPoint: "property-costs/collected",
    mutationFail: GET_PROPERTY_COSTS_COLLECTION_FAIL,
    mutationSuccess: GET_PROPERTY_COSTS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_PROPERTY_COSTS_SINGLE,
    endPoint: "property-costs/single",
    mutationFail: GET_PROPERTY_COSTS_SINGLE_FAIL,
    mutationSuccess: GET_PROPERTY_COSTS_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_PROPERTY_COSTS,
    actionReset: CREATE_PROPERTY_COSTS_RESET,
    endPoint: "property-costs/create",
    mutationFail: CREATE_PROPERTY_COSTS_FAIL,
    mutationSuccess: CREATE_PROPERTY_COSTS_SUCCESS
  },
  bulkCreationRequest: {
    action: BULK_CREATE_PROPERTY_COSTS,
    actionReset: BULK_CREATE_PROPERTY_COSTS_RESET,
    endPoint: "property-costs/bulk-create",
    mutationFail: BULK_CREATE_PROPERTY_COSTS_FAIL,
    mutationSuccess: BULK_CREATE_PROPERTY_COSTS_SUCCESS
  },
  editingRequest: {
    action: UPDATE_PROPERTY_COSTS,
    actionReset: UPDATE_PROPERTY_COSTS_RESET,
    endPoint: "property-costs/update",
    mutationFail: UPDATE_PROPERTY_COSTS_FAIL,
    mutationSuccess: UPDATE_PROPERTY_COSTS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_PROPERTY_COSTS,
    endPoint: "property-costs/delete",
    mutationFail: REMOVE_PROPERTY_COSTS_FAIL,
    mutationSuccess: REMOVE_PROPERTY_COSTS_SUCCESS
  },
  bulkDeletionRequest: {
    action: BULK_REMOVE_PROPERTY_COSTS,
    endPoint: "/property-costs/bulk-delete",
    mutationFail: BULK_REMOVE_PROPERTY_COSTS_FAIL,
    mutationSuccess: BULK_REMOVE_PROPERTY_COSTS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
