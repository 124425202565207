import Vue from 'vue';
import action from "@/store/core/action";
import ApiService from "@/services/api";

import {
  GET_CALENDAR_DATA,
  GET_CALENDAR_DATA_SUCCESS,
  GET_CALENDAR_DATA_FAIL,
  GET_CALENDAR_DATA_RESET,
  CREATE_CALENDAR_RATE,
  CREATE_CALENDAR_RATE_SUCCESS,
  CREATE_CALENDAR_RATE_FAIL,
  CREATE_CALENDAR_RATE_RESET,
  CREATE_CALENDAR_RATE_BULK,
  CREATE_CALENDAR_RATE_BULK_SUCCESS,
  CREATE_CALENDAR_RATE_BULK_FAIL,
  CREATE_CALENDAR_RATE_BULK_RESET,
  GET_CALENDAR_CHILDREN_DATA,
  GET_CALENDAR_CHILDREN_DATA_LOADING,
  GET_CALENDAR_CHILDREN_DATA_SUCCESS,
  GET_CALENDAR_CHILDREN_DATA_FAIL,
  CLEAR_CALENDAR_CHILDREN_DATA,
  CLEAR_CALENDAR_CHILDREN_DATA_SUCCESS,
  CALENDAR_MOVE_RESERVATION,
  CALENDAR_MOVE_RESERVATION_SUCCESS,
  CALENDAR_MOVE_RESERVATION_FAIL,
  CALENDAR_MOVE_RESERVATION_RESET,
  COPY_CALENDAR_RATES,
  COPY_CALENDAR_RATES_SUCCESS,
  COPY_CALENDAR_RATES_FAIL,
  COPY_CALENDAR_RATES_RESET,
} from "./actions";

const actionsProcessed = action.processMultiple([
  {
    name: 'getDataRequest',
    action: GET_CALENDAR_DATA,
    actionReset: GET_CALENDAR_DATA_RESET,
    endPoint: 'calendar/data',
    mutations: {
      fail: GET_CALENDAR_DATA_FAIL,
      success: GET_CALENDAR_DATA_SUCCESS,
      successCallback(state, data) {
        if (data.pagination.page > 1) {
          data.collection = [
            ...state.getDataRequest.data.collection,
            ...data.collection,
          ];
        }

        state.getDataRequest = {
          loading: false,
          success: true,
          fail: false,
          data: data,
          errors: {},
        };
      }
    },
  },
  {
    name: 'moveReservationRequest',
    action: CALENDAR_MOVE_RESERVATION,
    actionReset: CALENDAR_MOVE_RESERVATION_RESET,
    endPoint: "calendar/{id}/move-reservation",
    mutations: {
      fail: CALENDAR_MOVE_RESERVATION_FAIL,
      success: CALENDAR_MOVE_RESERVATION_SUCCESS,
    },
  },
  {
    method: 'post',
    name: 'copyRequest',
    action: COPY_CALENDAR_RATES,
    actionReset: COPY_CALENDAR_RATES_RESET,
    endPoint: "calendar/copy-rates",
    mutations: {
      fail: COPY_CALENDAR_RATES_FAIL,
      success: COPY_CALENDAR_RATES_SUCCESS,
    },
  },
]);

const getters = {};

const state = {
  creationRequest: {
    loading: false,
    success: false,
    fail: false,
    validation: {},
    response: {}
  },
  creationBulkRequest: {
    loading: false,
    success: false,
    fail: false,
    validation: {},
    response: {}
  },
  getChildrenRequest: {
    errors: {}
  },
  ...actionsProcessed.state,
};

const actions = {
  [CREATE_CALENDAR_RATE](context, params) {
    context.state.creationRequest.loading = true;
    return new Promise((resolve, reject) => {
      ApiService.post('rate-calendar/create', params)
        .then(response => {
          context.commit(CREATE_CALENDAR_RATE_SUCCESS, response.data.data);
          resolve(response.data);
        })
        .catch(({ response }) => {
          context.commit(CREATE_CALENDAR_RATE_FAIL, response.data.errors);
          reject(response.data.errors);
        });
    });
  },
  [CREATE_CALENDAR_RATE_RESET](context) {
    context.state.creationRequest = {
      loading: false,
      success: false,
      fail: false,
      validation: {},
      response: {}
    };
  },
  [CREATE_CALENDAR_RATE_BULK](context, params) {
    context.state.creationBulkRequest.loading = true;
    return new Promise((resolve, reject) => {
      ApiService.post('rate-calendar/bulk-create', params)
        .then(response => {
          context.commit(CREATE_CALENDAR_RATE_BULK_SUCCESS, response.data.data);
          resolve(response.data);
        })
        .catch(({ response }) => {
          context.commit(CREATE_CALENDAR_RATE_BULK_FAIL, response.data.errors);
          reject(response.data.errors);
        });
    });
  },
  [CREATE_CALENDAR_RATE_BULK_RESET](context) {
    context.state.creationBulkRequest = {
      loading: false,
      success: false,
      fail: false,
      validation: {},
      response: {}
    };
  },
  [GET_CALENDAR_CHILDREN_DATA](context, { id, params }) {
    context.commit(GET_CALENDAR_CHILDREN_DATA_LOADING, id);

    return ApiService.query(`calendar/${id}/children`, params)
      .then(response => {
        context.commit(GET_CALENDAR_CHILDREN_DATA_SUCCESS, {
          id,
          data: response.data.data
        });
      })
      .catch(({ response }) => {
        context.commit(GET_CALENDAR_CHILDREN_DATA_FAIL, response.data.errors);
      });
  },
  [CLEAR_CALENDAR_CHILDREN_DATA](context, id) {
    return context.commit(CLEAR_CALENDAR_CHILDREN_DATA_SUCCESS, id);
  },
  ...actionsProcessed.actions,
};

const mutations = {
  [CREATE_CALENDAR_RATE_SUCCESS](state, data) {
    state.creationRequest = {
      loading: false,
      success: true,
      fail: false,
      validation: {},
      response: data
    };

    state.getDataRequest.data.collection.map((property) => {
      property.unit_categories.map((unit_category) => {
        unit_category.rate_plans.map((rate_plan) => {
          let room_type = rate_plan.room_type_parent;

          if (!room_type) {
            return;
          }

          data.map((item) => {

            let isExisting = false;

            room_type.rates.map((rate) => {
              if (item.id === rate.id) {
                isExisting = true;
                Object.assign(rate, item);
              }
            });

            if (!isExisting && item.room_type_id === room_type.id) {
              room_type.rates.push(item);
            }
          });
        });
      });
    });
  },
  [CREATE_CALENDAR_RATE_FAIL](state, data) {
    state.creationRequest = {
      loading: false,
      success: false,
      fail: true,
      validation: data,
      response: {}
    };
  },
  [CREATE_CALENDAR_RATE_BULK_SUCCESS](state, data) {
    state.creationBulkRequest = {
      loading: false,
      success: true,
      fail: false,
      validation: {},
      response: data
    };
  },
  [CREATE_CALENDAR_RATE_BULK_FAIL](state, data) {
    state.creationBulkRequest = {
      loading: false,
      success: false,
      fail: true,
      validation: data,
      response: {}
    };
  },
  [GET_CALENDAR_CHILDREN_DATA_LOADING](state, id) {
    state.getDataRequest.data.collection.map(property => {
      property.unit_categories.map(unit_category => {
        unit_category.rate_plans.map(rate_plan => {
          const room_type_parent = rate_plan.room_types.find(item => item.unit_category_id === unit_category.id);

          if (!room_type_parent || room_type_parent.id !== id) {
            return;
          }

          Vue.set(room_type_parent, 'isLoading', true);
        });
      });
    });
  },
  [GET_CALENDAR_CHILDREN_DATA_SUCCESS](state, { id, data }) {
    state.getDataRequest.data.collection.map(property => {
      property.unit_categories.map(unit_category => {
        unit_category.rate_plans.map(rate_plan => {
          const room_type_parent = rate_plan.room_types.find(item => item.unit_category_id === unit_category.id);

          if (!room_type_parent || room_type_parent.id !== id) {
            return;
          }

          data.collection.map((item) => {
            item.parent_id = id;
          });

          Vue.set(room_type_parent, 'isLoaded', true);
          Vue.set(room_type_parent, 'isLoading', false);
          Vue.set(rate_plan, 'room_type_children', data.collection);
        });
      });
    });
  },
  [CLEAR_CALENDAR_CHILDREN_DATA_SUCCESS](state, id) {
    state.getDataRequest.data.collection.map(property => {
      property.unit_categories.map(unit_category => {
        unit_category.rate_plans.map(rate_plan => {
          const room_type_parent = rate_plan.room_types.find(item => item.unit_category_id === unit_category.id);

          if (!room_type_parent || room_type_parent.id !== id) {
            return;
          }

          Vue.set(room_type_parent, 'isLoaded', false);
          Vue.set(room_type_parent, 'isLoading', false);
          Vue.set(rate_plan, 'room_type_children', []);
        });
      });
    });
  },
  [GET_CALENDAR_CHILDREN_DATA_FAIL](state, data) {

  },
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
