export const GET_RESOURCES_LIST = "[RESOURCES] List Get";
export const GET_RESOURCES_LIST_SUCCESS = "[RESOURCES] List Get Success";
export const GET_RESOURCES_LIST_FAIL = "[RESOURCES] List Get Fail";

export const GET_RESOURCES_PAGINATION = "[RESOURCES] Pagination Get";
export const GET_RESOURCES_PAGINATION_SUCCESS = "[RESOURCES] Pagination Get Success";
export const GET_RESOURCES_PAGINATION_FAIL = "[RESOURCES] Pagination Get Fail";

export const GET_RESOURCES_COLLECTION = "[RESOURCES] Collection Get";
export const GET_RESOURCES_COLLECTION_SUCCESS = "[RESOURCES] Collection Get Success";
export const GET_RESOURCES_COLLECTION_FAIL = "[RESOURCES] Collection Get Fail";

export const GET_RESOURCES_SINGLE = "[RESOURCES] Single Get";
export const GET_RESOURCES_SINGLE_SUCCESS = "[RESOURCES] Single Get Success";
export const GET_RESOURCES_SINGLE_FAIL = "[RESOURCES] Single Get Fail";

export const CREATE_RESOURCES = "[RESOURCES] Create";
export const CREATE_RESOURCES_SUCCESS = "[RESOURCES] Create Success";
export const CREATE_RESOURCES_FAIL = "[RESOURCES] Create Fail";
export const CREATE_RESOURCES_RESET = "[RESOURCES] Create Reset";

export const UPDATE_RESOURCES = "[RESOURCES] Update";
export const UPDATE_RESOURCES_SUCCESS = "[RESOURCES] Update Success";
export const UPDATE_RESOURCES_FAIL = "[RESOURCES] Update Fail";
export const UPDATE_RESOURCES_RESET = "[RESOURCES] Update Reset";

export const REPLACE_RESOURCES = "[RESOURCES] Replace";
export const REPLACE_RESOURCES_SUCCESS = "[RESOURCES] Replace Success";
export const REPLACE_RESOURCES_FAIL = "[RESOURCES] Replace Fail";
export const REPLACE_RESOURCES_RESET = "[RESOURCES] Replace Reset";

export const REMOVE_RESOURCES = "[RESOURCES] Remove";
export const REMOVE_RESOURCES_SUCCESS = "[RESOURCES] Remove Success";
export const REMOVE_RESOURCES_FAIL = "[RESOURCES] Remove Fail";