import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_PAYMENT_SOURCES_LIST,
  GET_PAYMENT_SOURCES_LIST_SUCCESS,
  GET_PAYMENT_SOURCES_LIST_FAIL,
  GET_PAYMENT_SOURCES_PAGINATION,
  GET_PAYMENT_SOURCES_PAGINATION_SUCCESS,
  GET_PAYMENT_SOURCES_PAGINATION_FAIL,
  GET_PAYMENT_SOURCES_COLLECTION,
  GET_PAYMENT_SOURCES_COLLECTION_SUCCESS,
  GET_PAYMENT_SOURCES_COLLECTION_FAIL,
  GET_PAYMENT_SOURCES_SINGLE,
  GET_PAYMENT_SOURCES_SINGLE_SUCCESS,
  GET_PAYMENT_SOURCES_SINGLE_FAIL,
  CREATE_PAYMENT_SOURCES,
  CREATE_PAYMENT_SOURCES_SUCCESS,
  CREATE_PAYMENT_SOURCES_FAIL,
  CREATE_PAYMENT_SOURCES_RESET,
  UPDATE_PAYMENT_SOURCES,
  UPDATE_PAYMENT_SOURCES_SUCCESS,
  UPDATE_PAYMENT_SOURCES_FAIL,
  UPDATE_PAYMENT_SOURCES_RESET,
  REMOVE_PAYMENT_SOURCES,
  REMOVE_PAYMENT_SOURCES_SUCCESS,
  REMOVE_PAYMENT_SOURCES_FAIL
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_PAYMENT_SOURCES_LIST,
    endPoint: "payment-sources/list",
    mutationFail: GET_PAYMENT_SOURCES_LIST_FAIL,
    mutationSuccess: GET_PAYMENT_SOURCES_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_PAYMENT_SOURCES_PAGINATION,
    endPoint: "payment-sources/paginated",
    mutationFail: GET_PAYMENT_SOURCES_PAGINATION_FAIL,
    mutationSuccess: GET_PAYMENT_SOURCES_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_PAYMENT_SOURCES_COLLECTION,
    endPoint: "payment-sources/collected",
    mutationFail: GET_PAYMENT_SOURCES_COLLECTION_FAIL,
    mutationSuccess: GET_PAYMENT_SOURCES_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_PAYMENT_SOURCES_SINGLE,
    endPoint: "payment-sources/single",
    mutationFail: GET_PAYMENT_SOURCES_SINGLE_FAIL,
    mutationSuccess: GET_PAYMENT_SOURCES_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_PAYMENT_SOURCES,
    actionReset: CREATE_PAYMENT_SOURCES_RESET,
    endPoint: "payment-sources/create",
    mutationFail: CREATE_PAYMENT_SOURCES_FAIL,
    mutationSuccess: CREATE_PAYMENT_SOURCES_SUCCESS
  },
  editingRequest: {
    action: UPDATE_PAYMENT_SOURCES,
    actionReset: UPDATE_PAYMENT_SOURCES_RESET,
    endPoint: "payment-sources/update",
    mutationFail: UPDATE_PAYMENT_SOURCES_FAIL,
    mutationSuccess: UPDATE_PAYMENT_SOURCES_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_PAYMENT_SOURCES,
    endPoint: "payment-sources/delete",
    mutationFail: REMOVE_PAYMENT_SOURCES_FAIL,
    mutationSuccess: REMOVE_PAYMENT_SOURCES_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
