import ApiService from "@/services/api";

import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_EVENTS_LIST,
  GET_EVENTS_LIST_SUCCESS,
  GET_EVENTS_LIST_FAIL,
  GET_EVENTS_PAGINATION,
  GET_EVENTS_PAGINATION_SUCCESS,
  GET_EVENTS_PAGINATION_FAIL,
  GET_EVENTS_COLLECTION,
  GET_EVENTS_COLLECTION_SUCCESS,
  GET_EVENTS_COLLECTION_FAIL,
  GET_EVENTS_SINGLE,
  GET_EVENTS_SINGLE_SUCCESS,
  GET_EVENTS_SINGLE_FAIL,
  CREATE_EVENTS,
  CREATE_EVENTS_SUCCESS,
  CREATE_EVENTS_FAIL,
  CREATE_EVENTS_RESET,
  UPDATE_EVENTS,
  UPDATE_EVENTS_SUCCESS,
  UPDATE_EVENTS_FAIL,
  UPDATE_EVENTS_RESET,
  REPLACE_EVENTS,
  REPLACE_EVENTS_SUCCESS,
  REPLACE_EVENTS_FAIL,
  REPLACE_EVENTS_RESET,
  REMOVE_EVENTS,
  REMOVE_EVENTS_SUCCESS,
  REMOVE_EVENTS_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_EVENTS_LIST,
    endPoint: "events/list",
    mutationFail: GET_EVENTS_LIST_FAIL,
    mutationSuccess: GET_EVENTS_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_EVENTS_PAGINATION,
    endPoint: "events/paginated",
    mutationFail: GET_EVENTS_PAGINATION_FAIL,
    mutationSuccess: GET_EVENTS_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_EVENTS_COLLECTION,
    endPoint: "events/collected",
    mutationFail: GET_EVENTS_COLLECTION_FAIL,
    mutationSuccess: GET_EVENTS_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_EVENTS_SINGLE,
    endPoint: "events/single",
    mutationFail: GET_EVENTS_SINGLE_FAIL,
    mutationSuccess: GET_EVENTS_SINGLE_SUCCESS
  },
  creationRequest: {
    isUpload: true,
    action: CREATE_EVENTS,
    actionReset: CREATE_EVENTS_RESET,
    endPoint: "events/create",
    mutationFail: CREATE_EVENTS_FAIL,
    mutationSuccess: CREATE_EVENTS_SUCCESS
  },
  editingRequest: {
    isUpload: true,
    action: UPDATE_EVENTS,
    actionReset: UPDATE_EVENTS_RESET,
    endPoint: "events/update",
    mutationFail: UPDATE_EVENTS_FAIL,
    mutationSuccess: UPDATE_EVENTS_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_EVENTS,
    actionReset: REPLACE_EVENTS_RESET,
    endPoint: "events/replace",
    mutationFail: REPLACE_EVENTS_FAIL,
    mutationSuccess: REPLACE_EVENTS_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_EVENTS,
    endPoint: "events/delete",
    mutationFail: REMOVE_EVENTS_FAIL,
    mutationSuccess: REMOVE_EVENTS_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.actions,
};

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
