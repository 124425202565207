import Vue from "vue";
import store from "@/mixins/store";
import dates from "@/mixins/dates";
import helpers from "@/mixins/helpers";
import constants from "@/mixins/constants";
import translator from "@/mixins/translator";
import permissions from "@/mixins/permissions";
import notifications from "@/mixins/notifications";
// import tour from "@/mixins/tour";
import global from "@/mixins/global";

const MixinsService = {
  /**
   *
   */
  init() {
    Vue.mixin(store);
    Vue.mixin(dates);
    Vue.mixin(helpers);
    Vue.mixin(constants);
    Vue.mixin(translator);
    Vue.mixin(permissions);
    Vue.mixin(notifications);
    // Vue.mixin(tour);
    Vue.mixin(global);
  }
};

export default MixinsService;
