export const GET_PROPERTY_CONTRACTS_LIST = "[PROPERTY_CONTRACTS] List Get";
export const GET_PROPERTY_CONTRACTS_LIST_SUCCESS = "[PROPERTY_CONTRACTS] List Get Success";
export const GET_PROPERTY_CONTRACTS_LIST_FAIL = "[PROPERTY_CONTRACTS] List Get Fail";

export const GET_PROPERTY_CONTRACTS_PAGINATION = "[PROPERTY_CONTRACTS] Pagination Get";
export const GET_PROPERTY_CONTRACTS_PAGINATION_SUCCESS = "[PROPERTY_CONTRACTS] Pagination Get Success";
export const GET_PROPERTY_CONTRACTS_PAGINATION_FAIL = "[PROPERTY_CONTRACTS] Pagination Get Fail";

export const GET_PROPERTY_CONTRACTS_COLLECTION = "[PROPERTY_CONTRACTS] Collection Get";
export const GET_PROPERTY_CONTRACTS_COLLECTION_SUCCESS = "[PROPERTY_CONTRACTS] Collection Get Success";
export const GET_PROPERTY_CONTRACTS_COLLECTION_FAIL = "[PROPERTY_CONTRACTS] Collection Get Fail";

export const GET_PROPERTY_CONTRACTS_SINGLE = "[PROPERTY_CONTRACTS] Single Get";
export const GET_PROPERTY_CONTRACTS_SINGLE_SUCCESS = "[PROPERTY_CONTRACTS] Single Get Success";
export const GET_PROPERTY_CONTRACTS_SINGLE_FAIL = "[PROPERTY_CONTRACTS] Single Get Fail";

export const CREATE_PROPERTY_CONTRACTS = "[PROPERTY_CONTRACTS] Create";
export const CREATE_PROPERTY_CONTRACTS_SUCCESS = "[PROPERTY_CONTRACTS] Create Success";
export const CREATE_PROPERTY_CONTRACTS_FAIL = "[PROPERTY_CONTRACTS] Create Fail";
export const CREATE_PROPERTY_CONTRACTS_RESET = "[PROPERTY_CONTRACTS] Create Reset";

export const UPDATE_PROPERTY_CONTRACTS = "[PROPERTY_CONTRACTS] Update";
export const UPDATE_PROPERTY_CONTRACTS_SUCCESS = "[PROPERTY_CONTRACTS] Update Success";
export const UPDATE_PROPERTY_CONTRACTS_FAIL = "[PROPERTY_CONTRACTS] Update Fail";
export const UPDATE_PROPERTY_CONTRACTS_RESET = "[PROPERTY_CONTRACTS] Update Reset";

export const REMOVE_PROPERTY_CONTRACTS = "[PROPERTY_CONTRACTS] Remove";
export const REMOVE_PROPERTY_CONTRACTS_SUCCESS = "[PROPERTY_CONTRACTS] Remove Success";
export const REMOVE_PROPERTY_CONTRACTS_FAIL = "[PROPERTY_CONTRACTS] Remove Fail";

