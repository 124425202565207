import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_WAREHOUSE_PRODUCT_LIST,
  GET_WAREHOUSE_PRODUCT_LIST_SUCCESS,
  GET_WAREHOUSE_PRODUCT_LIST_FAIL,
  GET_WAREHOUSE_PRODUCT_PAGINATION,
  GET_WAREHOUSE_PRODUCT_PAGINATION_SUCCESS,
  GET_WAREHOUSE_PRODUCT_PAGINATION_FAIL,
  GET_WAREHOUSE_PRODUCT_COLLECTION,
  GET_WAREHOUSE_PRODUCT_COLLECTION_SUCCESS,
  GET_WAREHOUSE_PRODUCT_COLLECTION_FAIL,
  SYNC_WAREHOUSE_PRODUCT,
  SYNC_WAREHOUSE_PRODUCT_SUCCESS,
  SYNC_WAREHOUSE_PRODUCT_FAIL,
  SYNC_WAREHOUSE_PRODUCT_RESET,
  REMOVE_WAREHOUSE_PRODUCT,
  REMOVE_WAREHOUSE_PRODUCT_SUCCESS,
  REMOVE_WAREHOUSE_PRODUCT_FAIL,
} from "./actions";

const listProcessed = list.process({
  getListRequest: {
    action: GET_WAREHOUSE_PRODUCT_LIST,
    endPoint: "warehouse-product/list",
    mutationFail: GET_WAREHOUSE_PRODUCT_LIST_FAIL,
    mutationSuccess: GET_WAREHOUSE_PRODUCT_LIST_SUCCESS
  },
});

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_WAREHOUSE_PRODUCT_PAGINATION,
    endPoint: "warehouse-product/paginated",
    mutationFail: GET_WAREHOUSE_PRODUCT_PAGINATION_FAIL,
    mutationSuccess: GET_WAREHOUSE_PRODUCT_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_WAREHOUSE_PRODUCT_COLLECTION,
    endPoint: "warehouse-product/collected",
    mutationFail: GET_WAREHOUSE_PRODUCT_COLLECTION_FAIL,
    mutationSuccess: GET_WAREHOUSE_PRODUCT_COLLECTION_SUCCESS
  },
  creationRequest: {
    action: SYNC_WAREHOUSE_PRODUCT,
    actionReset: SYNC_WAREHOUSE_PRODUCT_RESET,
    endPoint: "warehouse-product/create",
    mutationFail: SYNC_WAREHOUSE_PRODUCT_FAIL,
    mutationSuccess: SYNC_WAREHOUSE_PRODUCT_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_WAREHOUSE_PRODUCT,
    endPoint: "warehouse-product/delete",
    mutationFail: REMOVE_WAREHOUSE_PRODUCT_FAIL,
    mutationSuccess: REMOVE_WAREHOUSE_PRODUCT_SUCCESS
  }
});

const actionsProcessed = action.processMultiple([

]);

const actions = {
  ...listProcessed.actions,
  ...crudProcessed.actions,
  ...actionsProcessed.state,
};

const getters = {};

const state = {
  ...listProcessed.state,
  ...crudProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...listProcessed.mutations,
  ...crudProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
