import ApiService from "@/services/api";
import BroadcastService from "@/services/broadcast";
import SessionService from '@/libraries/sessionjs/src';

import {
  WORKING_PROCESS_INITIALIZE,
  WORKING_PROCESS_STARTED,
  WORKING_PROCESS_COMPLETED,
  WORKING_PROCESS_GET,
  WORKING_PROCESS_GET_SUCCESS,
  WORKING_PROCESS_GET_FAIL,
} from "./actions";

const getters = {};

const state = {
  processes: [],
};

const actions = {
  [WORKING_PROCESS_INITIALIZE](context) {
    context.dispatch(WORKING_PROCESS_GET);

    const userData = SessionService.get('userData');

    BroadcastService.listenPrivate(`working-processes.${userData.id}`, `.started`, (data) => {
      context.commit(WORKING_PROCESS_STARTED, data);
    });
    BroadcastService.listenPrivate(`working-processes.${userData.id}`, `.completed`, (data) => {
      context.commit(WORKING_PROCESS_COMPLETED, data);
    });
  },
  [WORKING_PROCESS_GET](context, params) {
    return new Promise(resolve => {
      ApiService.query("auth/working-processes", params)
        .then(response => {
          context.commit(WORKING_PROCESS_GET_SUCCESS, response.data.data);
          resolve(response.data.data);
        })
        .catch(response => {
          context.commit(WORKING_PROCESS_GET_FAIL, response);
        });
    });
  },
};

const mutations = {
  [WORKING_PROCESS_STARTED](state, data) {
    state.processes.push(data);
  },
  [WORKING_PROCESS_COMPLETED](state, data) {
    const index = state.processes.findIndex(process => process.uuid === data.uuid);
    state.processes.splice(index, 1);
  },
  [WORKING_PROCESS_GET_SUCCESS](state, data) {
    state.processes = data;
  },
  [WORKING_PROCESS_GET_FAIL](state, data) {
    state.processes = [];
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};
