import crud from "@/store/core/crud";
import list from "@/store/core/list";
import action from "@/store/core/action";

import {
  GET_COMPANIES_LIST,
  GET_COMPANIES_LIST_SUCCESS,
  GET_COMPANIES_LIST_FAIL,
  CREATE_COMPANIES,
  CREATE_COMPANIES_FAIL,
  CREATE_COMPANIES_RESET,
  CREATE_COMPANIES_SUCCESS,
  GET_COMPANIES_COLLECTION,
  GET_COMPANIES_COLLECTION_FAIL,
  GET_COMPANIES_COLLECTION_SUCCESS,
  GET_COMPANIES_SINGLE,
  GET_COMPANIES_SINGLE_FAIL,
  GET_COMPANIES_SINGLE_SUCCESS,
  REMOVE_COMPANIES,
  REMOVE_COMPANIES_FAIL,
  REMOVE_COMPANIES_SUCCESS,
  UPDATE_COMPANIES,
  UPDATE_COMPANIES_FAIL,
  UPDATE_COMPANIES_RESET,
  UPDATE_COMPANIES_SUCCESS,
  REPLACE_COMPANIES,
  REPLACE_COMPANIES_FAIL,
  REPLACE_COMPANIES_RESET,
  REPLACE_COMPANIES_SUCCESS,
  GET_COMPANIES_PAGINATION,
  GET_COMPANIES_PAGINATION_FAIL,
  GET_COMPANIES_PAGINATION_SUCCESS,
  GET_COMPANIES_TAX_REGIMES,
  GET_COMPANIES_TAX_REGIMES_FAIL,
  GET_COMPANIES_TAX_REGIMES_SUCCESS,
  GET_COMPANIES_ENTRATEL_ACCOUNT_TYPES,
  GET_COMPANIES_ENTRATEL_ACCOUNT_TYPES_SUCCESS,
  GET_COMPANIES_ENTRATEL_ACCOUNT_TYPES_FAIL,
  GET_COMPANIES_ENTRATEL_ACCOUNT_SUBTYPES,
  GET_COMPANIES_ENTRATEL_ACCOUNT_SUBTYPES_SUCCESS,
  GET_COMPANIES_ENTRATEL_ACCOUNT_SUBTYPES_FAIL,
  GET_COMPANY_WHITELABEL,
  GET_COMPANY_WHITELABEL_SUCCESS,
  GET_COMPANY_WHITELABEL_FAIL,
} from "./actions";

const crudProcessed = crud.process({
  getPaginationRequest: {
    action: GET_COMPANIES_PAGINATION,
    endPoint: "companies/paginated",
    mutationFail: GET_COMPANIES_PAGINATION_FAIL,
    mutationSuccess: GET_COMPANIES_PAGINATION_SUCCESS
  },
  getCollectionRequest: {
    action: GET_COMPANIES_COLLECTION,
    endPoint: "companies/collected",
    mutationFail: GET_COMPANIES_COLLECTION_FAIL,
    mutationSuccess: GET_COMPANIES_COLLECTION_SUCCESS
  },
  getSingleRequest: {
    action: GET_COMPANIES_SINGLE,
    endPoint: "companies/single",
    mutationFail: GET_COMPANIES_SINGLE_FAIL,
    mutationSuccess: GET_COMPANIES_SINGLE_SUCCESS
  },
  creationRequest: {
    action: CREATE_COMPANIES,
    actionReset: CREATE_COMPANIES_RESET,
    endPoint: "companies/create",
    mutationFail: CREATE_COMPANIES_FAIL,
    mutationSuccess: CREATE_COMPANIES_SUCCESS
  },
  editingRequest: {
    isUpload: true,
    action: UPDATE_COMPANIES,
    actionReset: UPDATE_COMPANIES_RESET,
    endPoint: "companies/update",
    mutationFail: UPDATE_COMPANIES_FAIL,
    mutationSuccess: UPDATE_COMPANIES_SUCCESS
  },
  replaceRequest: {
    action: REPLACE_COMPANIES,
    actionReset: REPLACE_COMPANIES_RESET,
    endPoint: "companies/replace",
    mutationFail: REPLACE_COMPANIES_FAIL,
    mutationSuccess: REPLACE_COMPANIES_SUCCESS
  },
  deletionRequest: {
    action: REMOVE_COMPANIES,
    endPoint: "companies/delete",
    mutationFail: REMOVE_COMPANIES_FAIL,
    mutationSuccess: REMOVE_COMPANIES_SUCCESS
  }
});

const listProcessed = list.process({
  getListRequest: {
    action: GET_COMPANIES_LIST,
    endPoint: "companies/list",
    mutationFail: GET_COMPANIES_LIST_FAIL,
    mutationSuccess: GET_COMPANIES_LIST_SUCCESS
  },
});

const actionsProcessed = action.processMultiple([
  {
    name: 'getTaxRegime',
    action: GET_COMPANIES_TAX_REGIMES,
    endPoint: "companies/tax-regimes",
    mutations: {
      fail: GET_COMPANIES_TAX_REGIMES_FAIL,
      success: GET_COMPANIES_TAX_REGIMES_SUCCESS,
    },
  },
  {
    name: 'getEntratelAccountTypes',
    action: GET_COMPANIES_ENTRATEL_ACCOUNT_TYPES,
    endPoint: "companies/entratel-account-types",
    mutations: {
      fail: GET_COMPANIES_ENTRATEL_ACCOUNT_TYPES_FAIL,
      success: GET_COMPANIES_ENTRATEL_ACCOUNT_TYPES_SUCCESS,
    },
  },
  {
    name: 'getEntratelAccountSubTypes',
    action: GET_COMPANIES_ENTRATEL_ACCOUNT_SUBTYPES,
    endPoint: "companies/entratel-account-sub-types",
    mutations: {
      fail: GET_COMPANIES_ENTRATEL_ACCOUNT_SUBTYPES_FAIL,
      success: GET_COMPANIES_ENTRATEL_ACCOUNT_SUBTYPES_SUCCESS,
    },
  },
  {
    name: 'getWhitelabelRequest',
    action: GET_COMPANY_WHITELABEL,
    endPoint: "companies/whitelabel/{key}",
    mutations: {
      fail: GET_COMPANY_WHITELABEL_FAIL,
      success: GET_COMPANY_WHITELABEL_SUCCESS,
    },
  },
]);

const getters = {};

const actions = {
  ...crudProcessed.actions,
  ...listProcessed.actions,
  ...actionsProcessed.actions,
};

const state = {
  ...crudProcessed.state,
  ...listProcessed.state,
  ...actionsProcessed.state,
};

const mutations = {
  ...crudProcessed.mutations,
  ...listProcessed.mutations,
  ...actionsProcessed.mutations,
};

export default {
  state,
  actions,
  mutations,
  getters
};
